import { useLocales } from '@sevenrooms/core/locales'
import { Input, Select } from '@sevenrooms/core/ui-kit/core'
import { VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages } from '@sevenrooms/marketing'
import { CALL_TO_ACTION_OPTIONS, EMAIL_SENDER_OPTIONS } from './MockOptions'
import type { PromptForm } from './AssistantForm'

interface PromptProps {
  formState: PromptForm
  setFormState: React.Dispatch<React.SetStateAction<PromptForm>>
}

export function Prompt({ formState, setFormState }: PromptProps) {
  const { formatMessage } = useLocales()

  const handleSelectChange = (field: string) => (value: string) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <VStack spacing="lm" width="640px" mt="m">
      <VStack backgroundColor="secondaryBackground" borderColor="margin" p="m" borderRadius="s" spacing="s">
        <VStack mb="s">
          <Text>{formatMessage(emailBuilderMessages.assistantModalGoalOfEmail)}</Text>
          <Text color="secondaryFont">{formatMessage(emailBuilderMessages.assistantModalGoalOfEmailTip)}</Text>
        </VStack>
        <Input
          multiple
          fullWidth
          name="prompt"
          data-test="prompt-input"
          value={formState.prompt}
          onChange={handleInputChange}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalInputPlaceholder)}
        />
      </VStack>

      <HStack backgroundColor="secondaryBackground" borderColor="margin" p="m" borderRadius="s" alignItems="center" spacing="sm">
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalCallToActionLabel)}</Text>
        <Select
          options={CALL_TO_ACTION_OPTIONS}
          searchable={false}
          value={formState.callToAction}
          onChange={handleSelectChange('callToAction')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalCallToActionPlaceholder)}
          data-test="call-to-action-select"
          wrapperProps={{ flex: 1 }}
        />
      </HStack>

      <VStack backgroundColor="secondaryBackground" borderColor="margin" p="m" borderRadius="s" spacing="sm">
        <HStack spacing="sm" alignItems="center">
          <Text>{formatMessage(emailBuilderMessages.assistantFormModalSenderLabel)}</Text>
          <Select
            options={[...EMAIL_SENDER_OPTIONS, { id: 'custom', label: 'custom' }]}
            searchable={false}
            value={formState.sender}
            onChange={handleSelectChange('sender')}
            placeholder={formatMessage(emailBuilderMessages.assistantFormModalSenderPlaceholder)}
            data-test="sender-select"
            alignment="top"
            wrapperProps={{ flex: 1 }}
          />
        </HStack>

        {formState.sender === 'custom' && (
          <Input
            name="customUser"
            fullWidth
            data-test="custom-user-input"
            value={formState.customUser}
            onChange={handleInputChange}
            placeholder={formatMessage(emailBuilderMessages.assistantFormModalInputPlaceholder)}
          />
        )}
      </VStack>
    </VStack>
  )
}
