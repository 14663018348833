/* eslint-disable camelcase */
import { type Action, createSlice } from '@reduxjs/toolkit'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import priceFormatter from 'currency-formatter'
import _ from 'lodash'
import moment from 'moment-timezone'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import money from 'money-math/money'
import * as ActionTypes from 'mgr/actualslideout/actions/ActionTypes'
import { getPreviouslyChargedAmount } from 'mgr/actualslideout/actions/BookAvailabilityActions'
import { currencyFormatter } from 'mgr/actualslideout/utils/transformPaymentData'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { TransactionTypes } from 'mgr/lib/utils/Constants'
import { getVenueLocalTime } from 'svr/common/TimeUtil'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import { CreditCardCollectionOptionsEnum } from '@sevenrooms/core/domain'
import type {
  BookPaymentState,
  Client,
  DeleteResCardApi,
  GlobalInit,
  LoadedTransactionsPayload,
  ResCard,
  ResCardApi,
  ToggleModalChargePayload,
  Transaction,
  NotificationType,
  TakeOrSave,
  TaxGroup,
  GratuityType,
  RefundType,
  ChangeSelectedTimeslotPayload,
  SaferpayData,
  AdyenData,
  EnterAddOrEditReservationPayload,
  GetTimesSuccessPayload,
  ChangedReservationInfo,
  ExtendedStripeInstance,
  CopyViewToBookDetailsPayload,
  ChargeData,
} from './BookPaymentSlice.types'
import type * as stripeJs from '@stripe/stripe-js'

const initialState: BookPaymentState = {
  cardEntryOption: 'manual',
  cardHolderName: '',
  cardHolderNumber: '',
  cardExpMonth: '',
  cardExpYear: '',
  cardCcv: '',
  cardToken: '',
  cardData: null,
  currencyCode: 'USD',
  formattedChargeAmount: '',
  chargeAmount: '',
  chargeAmountDiff: 0,
  chargeTotal: '',
  chargeApplyTax: false,
  chargeTax: '0',
  taxAmount: '',
  chargeDescription: '',
  taxGroupId: null,
  stripeInstance: null,
  stripeCardElement: null,
  applyServiceCharge: false,
  serviceCharge: '',
  serviceChargeAmount: '',
  applyGratuityCharge: false,
  gratuityCharge: '',
  gratuityChargeAmount: '',
  requiredGratuityCharge: false,
  paylinkGratuityType: 'gratuity_percentage',
  paymentPublicToken: '',
  selectedCardId: null,
  useGuestProfilePhoneNumber: true,
  cardPhoneCountry: '',
  cardPhoneNumber: '',
  resCardId: '',
  resCardLast4: '',
  resCardType: '',
  override: false,
  takePaymentOrSave: 'none',
  chargeType: '',
  additionalReservationCards: [],
  cardRequired: false,
  paymentRule: null,
  partySizeMinRule: 0,
  costMinRule: 0,
  requiredGratuity: 0,
  clientCards: null,
  chargeSendNotification: false,
  notificationModalOpen: false,
  notificationEmail: '',
  notificationType: '',
  notificationTransactionId: null,
  refundingId: null,
  expandedRows: [],
  refundType: 'full',
  refundAmount: '',
  refundFull: '',
  refundDescription: '',
  refundSendNotification: true,
  refundGiftCardAmount: false,
  chargeModalOpen: false,
  chargeOnlyModal: false,
  isModalSubmitting: false,
  isProcessingDelete: false,
  deletingRequest: null,
  formErrors: {},
  saferpayInitialData: {},
  saferpayNameEmpty: true,
  adyenInitialData: {},
  creditCardCollection: CreditCardCollectionOptionsEnum.BOTH_DEFAULT_MANUAL,
  incompletePaylinkAutoCancelMinutesLong: -1,
  incompletePaylinkAutoCancelMinutesShort: 60,
  paylinkRemoveModalTransactionId: null,
  outstandingPaylinkTransaction: null,
  taxGroups: null,
  outstandingPaylink: null,
  paylinkAutoCancel: null,
  isEditMode: false,
  clientSelectGratuity: {
    gratuity: null,
    gratuityClientSelect: false,
    requireGratuityCharge: false,
    applyGratuity: false,
  },
}

let globals = {}

export const bookPaymentSlice = createSlice({
  name: 'bookPaymentState',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        (action): action is { globalInit: GlobalInit } & Action => action.type === GlobalActionTypes.INITIALIZE,
        (state, { globalInit }) => {
          globals = {
            venueTimezone: globalInit.venueTimezone,
            creditCardCollection: globalInit.venueSettings.credit_card_collection,
            incompletePaylinkAutoCancelMinutesLong: globalInit.venueSettings.incomplete_paylink_auto_cancel_minutes_long ?? -1,
            incompletePaylinkAutoCancelMinutesShort: globalInit.venueSettings.incomplete_paylink_auto_cancel_minutes_short ?? -1,
            paylinkOnly: globalInit.venueSettings.paylink_only,
          }
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, globals)
        }
      )
      .addMatcher(
        (action): action is LoadedTransactionsPayload & Action => action.type === ActionTypes.LOAD_TRANSACTIONS_SUCCESS,
        (state, { transactionResult }) => {
          const transactions = transactionResult.charges || []
          const outstandingPaylinkTransaction = transactions.find(transaction => {
            if (transaction.transaction_type === TransactionTypes.REQUEST && transaction.paylink_auto_cancel_datetime) {
              return moment.utc().isBefore(moment.utc(transaction.paylink_auto_cancel_datetime))
            }
            return false
          })
          state.outstandingPaylinkTransaction = outstandingPaylinkTransaction ?? null

          if (state.actual?.total_guests != null) {
            Object.assign<BookPaymentState, Partial<BookPaymentState>>(
              state,
              getChargesData(
                state as ChargeData,
                state.actual.total_guests,
                state.actual.duration,
                state.override,
                getPreviouslyChargedAmount(transactions)
              )
            )
          }
        }
      )
      .addMatcher(
        (action): action is { formErrors: Record<string, string> } & Action => action.type === ActionTypes.CHARGE_FORM_VALIDATED,
        (state, { formErrors }) => {
          state.formErrors = formErrors
        }
      )
      .addMatcher(
        (action): action is { value: ResCardApi } & Action => action.type === ActionTypes.PAYMENT_ADD_RES_CARD,
        (state, { value }) => {
          if (!state.resCardId) {
            Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, {
              resCardId: value.card_id,
              resCardLast4: value.last_four,
              resCardType: value.card_type,
              resCardEmail: value.email,
              resCardFingerprint: value.card_fingerprint,
              notificationEmail: value.email,
            })
          } else if (value.billing_history_id) {
            state.additionalReservationCards.push({
              resCardId: value.card_id,
              resCardLast4: value.last_four,
              resCardType: value.card_type,
              resCardFingerprint: value.card_fingerprint,
              notificationEmail: value.email,
            })
          }
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_DELETE_CARD_START,
        state => {
          state.isProcessingDelete = true
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_DELETE_CARD_FAIL,
        state => {
          state.isProcessingDelete = false
        }
      )
      .addMatcher(
        (action): action is { value: DeleteResCardApi } & Action => action.type === ActionTypes.PAYMENT_DELETE_CARD_SUCCESS,
        (state, { value }) => {
          state.additionalReservationCards = value.additional_reservation_cards.map<ResCard>(reservationCard => ({
            resCardId: reservationCard.card_id,
            resCardLast4: reservationCard.last_four,
            resCardType: reservationCard.card_type,
            notificationEmail: reservationCard.email,
          }))
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, {
            resCardId: value.payments_card_id,
            resCardLast4: value.payments_last_four,
            resCardType: value.payments_card_type,
            resCardEmail: value.payments_email,
            resCardFingerprint: value.payments_fingerprint,
            notificationEmail: state?.notificationEmail,
          })
          state.isProcessingDelete = false
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_PAYLINK_DELETE_START,
        (state, { value }) => {
          state.deletingRequest = value
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_PAYLINK_DELETE_FAIL,
        state => {
          state.deletingRequest = null
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_PAYLINK_DELETE_SUCCESS,
        state => {
          state.deletingRequest = null
        }
      )
      .addMatcher(
        (action): action is { transaction: Transaction } & Action => action.type === ActionTypes.PAYMENT_SET_REFUND_ID,
        (state, { transaction }) => {
          const fullRefundAmount = money.floatToAmount(transaction?.amount_remaining_decimal)
          state.refundingId = transaction?.id
          state.refundAmount = fullRefundAmount
          state.refundFull = fullRefundAmount
          state.notificationEmail = !transaction
            ? getDefaultEmail(state)
            : transaction?.email || getNotificationEmail(state, transaction?.card_id)
        }
      )
      .addMatcher(
        (action): action is { selectedClient?: Client } & Action => action.type === ActionTypes.BOOK_CLIENT_CHANGE_SELECTED_CLIENT,
        (state, { selectedClient }) => {
          state.clientCards = selectedClient?.credit_cards ?? null
          state.useGuestProfilePhoneNumber = !!selectedClient?.phone_number_formatted
        }
      )
      .addMatcher(
        (action): action is { value?: string; field?: string } & Action => action.type === ActionTypes.BOOK_CLIENT_CHANGE_CLIENT_FIELD,
        (state, { value, field }) => {
          state.useGuestProfilePhoneNumber = !(field === 'phone_number_formatted' && !value)
        }
      )
      .addMatcher(
        (action): action is { value: boolean } & Action => action.type === ActionTypes.PAYMENT_CHANGE_OVERRIDE,
        (state, { value }) => {
          const charges = value ? getChargesFromTransaction(state.outstandingPaylinkTransaction, state.taxGroups) : {}
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, charges)
          state.override = value
          state.takePaymentOrSave = value ? 'none' : state.takePaymentOrSave
        }
      )
      .addMatcher(
        (action): action is { value: boolean } & Action => action.type === ActionTypes.PAYMENT_CHANGE_USE_GUEST_PROFILE_PHONE_NUMBER,
        (state, { value }) => {
          state.useGuestProfilePhoneNumber = value
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_PHONE_COUNTRY,
        (state, { value }) => {
          state.cardPhoneCountry = value
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_PHONE_NUMBER,
        (state, { value }) => {
          state.cardPhoneNumber = value
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CARD_ENTRY_OPTION,
        (state, { value }) => {
          state.cardEntryOption = value
          state.selectedCardId = ['paylink', 'manual'].indexOf(value) < 0 ? value : null
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_TOGGLE_DETAIL,
        (state, { value }) => {
          let expanded
          if (state.expandedRows.indexOf(value) !== -1) {
            expanded = _.without(state.expandedRows, value)
          } else {
            expanded = _.concat(state.expandedRows, value)
          }
          state.expandedRows = expanded
        }
      )
      .addMatcher(
        (action): action is ToggleModalChargePayload & Action => action.type === ActionTypes.PAYMENT_TOGGLE_MODAL_CHARGE,
        (state, action) => {
          const chargeModalOpen = action.value ?? !state.chargeModalOpen

          const chargeOnly = action.restrict === 'chargeOnly'

          let entryOption
          if (chargeOnly) {
            entryOption = action.card.resCardId
          } else if (action.paylinkOnly) {
            entryOption = 'paylink'
          } else {
            entryOption =
              state.creditCardCollection === CreditCardCollectionOptionsEnum.ONLY_PAYLINK ||
              state.creditCardCollection === CreditCardCollectionOptionsEnum.BOTH_DEFAULT_PAYLINK
                ? 'paylink'
                : 'manual'
          }

          const selectedCardId = chargeOnly ? action.card.resCardId : null
          const takePaymentOrSave = action.canCharge ? 'take' : 'save'

          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, {
            ...state,
            ...cardReset,
            paymentRule: null,
            takePaymentOrSave,
            stripeInstance: getStripeInstance(state.stripeInstance, takePaymentOrSave, action.accountId, action.connectedSetupIntents),
            cardEntryOption: entryOption,
            chargeModalOpen,
            chargeOnlyModal: chargeOnly,
            selectedCardId,
            cardRequired: false,
            notificationEmail: !chargeModalOpen ? getDefaultEmail(state) : action.card?.notificationEmail || getDefaultEmail(state),
            useGuestProfilePhoneNumber: !!state.actual?.phone_number_formatted,
          })
        }
      )
      .addMatcher(
        (action): action is { transaction?: Transaction; notificationType?: NotificationType } & Action =>
          action.type === ActionTypes.PAYMENT_TOGGLE_MODAL_NOTIFICATION,
        (state, { transaction, notificationType }) => {
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, {
            notificationTransactionId: transaction?.id,
            notificationType: notificationType ?? undefined,
            notificationModalOpen: !!transaction,
            notificationEmail: !transaction
              ? getDefaultEmail(state)
              : transaction?.email || getNotificationEmail(state, transaction?.card_id),
          })
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_NOTIFICATION_START,
        state => {
          state.isModalSubmitting = true
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_NOTIFICATION_SUCCESS,
        state => {
          state.isModalSubmitting = false
          state.notificationModalOpen = false
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_NOTIFICATION_FAIL,
        state => {
          state.isModalSubmitting = false
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_REFUND_START,
        state => {
          state.isModalSubmitting = true
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_REFUND_SUCCESS,
        state => {
          state.refundingId = null
          state.isModalSubmitting = false
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_REFUND_FAIL,
        state => {
          state.isModalSubmitting = false
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_CHARGE_START,
        state => {
          state.isModalSubmitting = true
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_CHARGE_SUCCESS,
        state => {
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, cardReset)
          state.chargeModalOpen = false
          state.isModalSubmitting = false
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.PAYMENT_CHARGE_FAIL,
        state => {
          state.isModalSubmitting = false
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CARD_HOLDER_NAME,
        (state, { value }) => {
          state.cardHolderName = value
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CARD_HOLDER_NUMBER,
        (state, { value }) => {
          state.cardHolderNumber = value
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CARD_EXP_MONTH,
        (state, { value }) => {
          state.cardExpMonth = value
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CARD_EXP_YEAR,
        (state, { value }) => {
          state.cardExpYear = value
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CARD_CCV,
        (state, { value }) => {
          state.cardCcv = value
        }
      )
      .addMatcher(
        (action): action is { value: TakeOrSave; accountId: string; connectedSetupIntents: string } & Action =>
          action.type === ActionTypes.PAYMENT_CHANGE_TAKE_PAYMENT_OR_SAVE,
        (state, { value, accountId, connectedSetupIntents }) => {
          state.takePaymentOrSave = value
          state.stripeInstance = getStripeInstance(state.stripeInstance, value, accountId, connectedSetupIntents)
        }
      )
      .addMatcher(
        (action): action is { value: number } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CHARGE_AMOUNT,
        (state, { value }) => {
          const formattedChargeAmount = currencyFormatter(value)
          state.formattedChargeAmount = formattedChargeAmount
          state.chargeAmount = money.floatToAmount(formattedChargeAmount.replace(',', '.'))
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, calculateTotal(state))
        }
      )
      .addMatcher(
        (action): action is { value: boolean; taxGroups: TaxGroup[] } & Action =>
          action.type === ActionTypes.PAYMENT_CHANGE_CHARGE_APPLY_TAX,
        (state, { value, taxGroups }) => {
          const firstTaxGroup = taxGroups[0]
          state.chargeApplyTax = value
          state.taxGroupId = value && firstTaxGroup ? firstTaxGroup.id : null
          state.chargeTax = value && firstTaxGroup ? String(firstTaxGroup.tax_rate) : null
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, calculateTotal(state))
        }
      )
      .addMatcher(
        (action): action is { value: string; taxGroups: TaxGroup[] } & Action => action.type === ActionTypes.PAYMENT_CHANGE_TAX_GROUP_ID,
        (state, { value, taxGroups }) => {
          const taxGroup = taxGroups.find(obj => obj.id === value)
          state.taxGroupId = value
          state.chargeTax = taxGroup ? String(taxGroup.tax_rate) : null
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, calculateTotal(state))
        }
      )
      .addMatcher(
        (action): action is { value: boolean } & Action => action.type === ActionTypes.PAYMENT_CHANGE_APPLY_SERVICE_CHARGE,
        (state, { value }) => {
          state.applyServiceCharge = value
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, calculateTotal(state))
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_SERVICE_CHARGE,
        (state, { value }) => {
          state.serviceCharge = value
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, calculateTotal(state))
        }
      )
      .addMatcher(
        (action): action is { value: boolean } & Action => action.type === ActionTypes.PAYMENT_CHANGE_APPLY_GRATUITY_CHARGE,
        (state, { value }) => {
          state.applyGratuityCharge = value
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, calculateTotal(state))
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_GRATUITY_CHARGE,
        (state, { value }) => {
          state.gratuityCharge = value
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, calculateTotal(state))
        }
      )
      .addMatcher(
        (action): action is { value: GratuityType } & Action => action.type === ActionTypes.PAYMENT_CHANGE_PAYLINK_GRATUITY_TYPE,
        (state, { value }) => {
          state.paylinkGratuityType = value
          if (value !== 'gratuity_percentage') {
            state.gratuityCharge = ''
            Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, calculateTotal(state))
          }
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CHARGE_DESCRIPTION,
        (state, { value }) => {
          state.chargeDescription = value
        }
      )
      .addMatcher(
        (action): action is { value: number } & Action => action.type === ActionTypes.PAYMENT_CHANGE_PAYLINK_AUTO_CANCEL,
        (state, { value }) => {
          state.paylinkAutoCancel = value
        }
      )
      .addMatcher(
        (action): action is { value: boolean } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CHARGE_SEND_NOTIFICATION,
        (state, { value }) => {
          state.chargeSendNotification = value
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.PAYMENT_CHANGE_CHARGE_NOTIFICATION_EMAIL,
        (state, { value }) => {
          state.notificationEmail = value
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.REFUND_CHANGE_CHARGE_AMOUNT,
        (state, { value }) => {
          state.refundAmount = value
        }
      )
      .addMatcher(
        (action): action is { token: string; data: string } & Action => action.type === ActionTypes.RECEIVE_TOKEN,
        (state, { token, data }) => {
          state.cardToken = token
          state.cardData = data
        }
      )
      .addMatcher(
        (action): action is { value: RefundType } & Action => action.type === ActionTypes.REFUND_CHANGE_TYPE,
        (state, { value }) => {
          state.refundType = value
          state.refundAmount = value === 'full' ? state.refundFull : state.refundAmount
        }
      )
      .addMatcher(
        (action): action is { value: string } & Action => action.type === ActionTypes.REFUND_CHANGE_DESCRIPTION,
        (state, { value }) => {
          state.refundDescription = value
        }
      )
      .addMatcher(
        (action): action is { value: boolean } & Action => action.type === ActionTypes.REFUND_TOGGLE_NOTIFICATION,
        (state, { value }) => {
          state.refundSendNotification = value
        }
      )
      .addMatcher(
        (action): action is { value: boolean } & Action => action.type === ActionTypes.REFUND_TOGGLE_REFUND_GIFT_CARD,
        (state, { value }) => {
          state.refundGiftCardAmount = value
        }
      )
      .addMatcher(
        (action): action is { element: stripeJs.StripeCardElement } & Action => action.type === ActionTypes.PASS_STRIPE_CARD_ELEMENT,
        (state, { element }) => {
          state.stripeCardElement = element
        }
      )
      .addMatcher(
        (action): action is { partySize: number } & Action => action.type === ActionTypes.BOOK_AVAILABILITY_CHANGE_PARTY_SIZE,
        (state, { partySize }) => {
          if (state.initialReservationInfo && state.changedReservationInfo) {
            state.changedReservationInfo.partySize = state.initialReservationInfo?.partySize !== partySize
          }
        }
      )
      .addMatcher(
        (action): action is ChangeSelectedTimeslotPayload & Action =>
          action.type === ActionTypes.BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT,
        (state, action) => {
          const stripeInstance =
            action.venue.paymentType === AccountTypes.STRIPE
              ? getStripeInstance(state.stripeInstance, state.takePaymentOrSave, action.accountId, action.connectedSetupIntents)
              : null
          let paylinkAutoCancel = state.outstandingPaylinkTransaction
            ? state.actual?.paylink_auto_cancel_minutes ?? -1
            : state.incompletePaylinkAutoCancelMinutesLong
          if (action.selectedTimeSlot) {
            const fromReservationToNowMinutes = moment(action.selectedTimeSlot.real_datetime_of_slot).diff(
              getVenueLocalTime(state.venueTimezone),
              'minutes'
            )
            // Use the short auto-cancel time if we're already under the long time
            paylinkAutoCancel =
              fromReservationToNowMinutes < paylinkAutoCancel ? state.incompletePaylinkAutoCancelMinutesShort : paylinkAutoCancel
            // If we're already under the short time too, disable auto-cancel by default
            paylinkAutoCancel = fromReservationToNowMinutes < paylinkAutoCancel ? -1 : paylinkAutoCancel
          }
          let update = {}
          if (action.isPreselectedTime) {
            update = {
              initialReservationInfo: {
                dateTime: action.selectedTimeSlot.real_datetime_of_slot,
                chargeAmount: state.chargeAmount,
                cardRequired: state.cardRequired,
                partySize: state.actual?.total_guests,
              },
              changedReservationInfo: {
                partySize: false,
                datetime: false,
                charges: false,
              },
            }
          } else if (state.initialReservationInfo) {
            update = {
              changedReservationInfo: {
                ...state.changedReservationInfo,
                dateTime: state.initialReservationInfo.dateTime !== action.selectedTimeSlot.real_datetime_of_slot,
              },
            }
          }
          state.stripeCardElement = stripeInstance ? state.stripeCardElement : null
          state.stripeInstance = stripeInstance
          state.paylinkAutoCancel = paylinkAutoCancel
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, update)
        }
      )
      .addMatcher(
        (action): action is { data: SaferpayData } & Action => action.type === ActionTypes.SET_SAFERPAY_INITIAL_DATA,
        (state, { data }) => {
          state.saferpayInitialData = data
        }
      )
      .addMatcher(
        (action): action is { data: AdyenData } & Action => action.type === ActionTypes.SET_ADYEN_INITIAL_DATA,
        (state, { data }) => {
          state.adyenInitialData = data
        }
      )
      .addMatcher(
        action =>
          [ActionTypes.VIEW_ACTUAL, ActionTypes.ENTER_ADD_RESERVATION, ActionTypes.BOOK_SUBMIT_RESERVATION_SUCCESS].includes(action.type),
        (state, action) => {
          // Here we clean state before slideout opens:
          // - VIEW_ACTUAL firing after view mode slideout opens
          // - BOOK_SUBMIT_RESERVATION_SUCCESS firing after add new reservation
          // - ENTER_ADD_RESERVATION firing on add new reservation
          // these types should cover all cases when we want to reset state & to not be in the middle of fetching to avoid race conditions
          // We can't use ENTER_EDIT_RESERVATION (or _CLONE_), because some data might be already fetching
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, initialState)
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, globals)
          state.isEditMode = action.type !== ActionTypes.ENTER_ADD_RESERVATION
        }
      )
      .addMatcher(
        action => action.type === ActionTypes.CLOSE_SLIDEOUT,
        state => {
          // Be careful! It won't fire after open new view with already opened one, or on save reservation
          // works only on user click close slideout button
          state.saferpayInitialData = { ...state.saferpayInitialData, needClean: true }
          state.adyenInitialData = { ...state.adyenInitialData, needClean: true }
        }
      )
      .addMatcher(
        (action): action is { isEmpty: boolean } & Action => action.type === ActionTypes.SAFERPAY_SET_EMPTY_HOLDERNAME_STATUS,
        (state, { isEmpty }) => {
          state.saferpayNameEmpty = isEmpty
        }
      )
      .addMatcher(
        (action): action is { transactionId: string } & Action => action.type === ActionTypes.SHOW_REMOVE_PAYLINK_MODAL,
        (state, { transactionId }) => {
          state.paylinkRemoveModalTransactionId = transactionId
        }
      )
      .addMatcher(
        (action): action is CopyViewToBookDetailsPayload & Action => action.type === ActionTypes.COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS,
        (state, { actual, venue, accountId, connectedSetupIntents }) => {
          const outstandingPaylink = actual?.paylink_auto_cancel_datetime_local
          let entryOption =
            state.creditCardCollection === CreditCardCollectionOptionsEnum.ONLY_PAYLINK ||
            state.creditCardCollection === CreditCardCollectionOptionsEnum.BOTH_DEFAULT_PAYLINK ||
            state.paylinkOnly ||
            (outstandingPaylink && getVenueLocalTime(state.venueTimezone).isBefore(moment(outstandingPaylink)))
              ? 'paylink'
              : 'manual'
          const override = actual ? actual.booked_with_override : false

          let savedCard = {}
          if (actual?.payments_card_id) {
            savedCard = {
              resCardId: actual.payments_card_id,
              resCardLast4: actual.payments_last_four,
              resCardType: actual.payments_card_type,
              resCardEmail: actual.payments_email,
              resCardFingerprint: actual.payments_card_fingerprint,
            }
            entryOption = actual.payments_card_id
          }

          const additionalReservationCards =
            actual?.additional_reservation_cards?.map(resCard => ({
              resCardId: resCard.card_id,
              resCardLast4: resCard.last_four,
              resCardType: resCard.card_type,
              resCardFingerprint: resCard.card_fingerprint,
              notificationEmail: resCard.email,
            })) ?? []

          const clientCards: ResCardApi[] = actual?.venue_group_client.credit_cards ?? []

          const preloadEmail = actual?.venue_group_client.email_address ?? ''

          const paylinkFields = {
            taxGroups: venue?.bookSettings.taxGroups,
            outstandingPaylink,
            paylinkAutoCancel: actual?.paylink_auto_cancel_minutes ?? state.paylinkAutoCancel,
            initialReservationInfo: {
              ...(state.initialReservationInfo || {}),
              partySize: actual?.total_guests,
            },
          }

          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, {
            ...savedCard,
            clientCards,
            additionalReservationCards,
            cardEntryOption: entryOption,
            notificationEmail: preloadEmail,
            override,
            stripeInstance: getStripeInstance(state.stripeInstance, state.takePaymentOrSave, accountId ?? null, connectedSetupIntents),
            saferpayInitialData: { ...state.saferpayInitialData, needClean: false },
            adyenInitialData: { ...state.adyenInitialData, needClean: false },
            ...paylinkFields,
            useGuestProfilePhoneNumber: !!actual?.phone_number_formatted,
            actual,
            venue,
          })
        }
      )
      .addMatcher(
        (action): action is EnterAddOrEditReservationPayload & Action => action.type === ActionTypes.ENTER_ADD_RESERVATION,
        (state, { venue, accountId }) => {
          const entryOption =
            state.creditCardCollection === CreditCardCollectionOptionsEnum.ONLY_PAYLINK ||
            state.creditCardCollection === CreditCardCollectionOptionsEnum.BOTH_DEFAULT_PAYLINK ||
            state.paylinkOnly
              ? 'paylink'
              : 'manual'

          Object.assign(state, {
            cardEntryOption: entryOption,
            stripeInstance: getStripeInstance(state.stripeInstance, state.takePaymentOrSave, accountId ?? null),
            saferpayInitialData: { ...state.saferpayInitialData, needClean: false },
            adyenInitialData: { ...state.adyenInitialData, needClean: false },
            venue,
          })
        }
      )
      .addMatcher(
        (action): action is GetTimesSuccessPayload & Action =>
          [ActionTypes.BOOK_AVAILABILITY_CHANGE_CHARGES_FROM_EXTERNAL_TIME_SLOT, ActionTypes.BOOK_AVAILABILITY_GET_TIMES_SUCCESS].includes(
            action.type
          ),
        (state, action) => {
          const charges = getChargesData(
            action.chargeData,
            action.partySize,
            action.duration,
            state.override,
            action.previouslyChargedAmount
          )
          // assign charges to state first, then calculate other fields that need updated state
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, charges)
          Object.assign<BookPaymentState, Partial<BookPaymentState>>(state, calculateTotal(state))

          if (state.initialReservationInfo) {
            const isChargesChanged =
              state.initialReservationInfo.cardRequired !== state.cardRequired ||
              (!isZeroChargeAmount(state.chargeAmount) && state.initialReservationInfo.chargeAmount !== state.chargeAmount)
            state.changedReservationInfo = {
              ...(state.changedReservationInfo as ChangedReservationInfo),
              charges: isChargesChanged,
            }
          }

          state.venue = action.venue

          const takePaymentOrSave = getTakePaymentOrSave(state)
          // Prevents awkward race condition during view mode
          if (!state.chargeModalOpen) {
            state.takePaymentOrSave = takePaymentOrSave
          }

          state.stripeInstance = getStripeInstance(state.stripeInstance, takePaymentOrSave, action.accountId, action.connectedSetupIntents)
        }
      )
  },
})

export const getDefaultEmail = (state: BookPaymentState) =>
  state.actual?.venue_group_client.email_address || state.actual?.email_address || state.notificationEmail

const getNotificationEmail = (state: BookPaymentState, cardId?: string) => {
  const additionalCard = state.additionalReservationCards.find(i => [i.resCardId, i.resCardFingerprint].includes(cardId))
  const defaultEmail = getDefaultEmail(state)
  if (additionalCard) {
    return additionalCard.notificationEmail || defaultEmail
  }
  return [state.resCardId, state.resCardFingerprint].includes(cardId) ? state.resCardEmail : defaultEmail
}

const getChargesFromTransaction = (transaction: Transaction | null, taxGroups: TaxGroup[] | null) => {
  if (!transaction) {
    return {}
  }
  const taxGroup = transaction.tax ? taxGroups?.find(taxGroup => Number(taxGroup.tax_rate) === Number(transaction.tax)) : undefined
  return {
    chargeAmount: money.floatToAmount(transaction.base_amount_decimal ?? ''),
    formattedChargeAmount: money.floatToAmount(transaction.base_amount_decimal ?? ''),
    chargeTotal: money.floatToAmount(transaction.amount_decimal ?? ''),
    chargeApplyTax: !!transaction.tax,
    taxGroupId: taxGroup?.id,
    chargeTax: transaction.tax ?? '',
    applyServiceCharge: !!transaction.service_charge,
    serviceCharge: transaction.service_charge ?? '',
    paylinkGratuityType: getGratuityTypes(transaction),
    applyGratuityCharge: !!transaction.gratuity,
    gratuityCharge: transaction.gratuity ?? '',
    chargeDescription: transaction.notes ?? '',
  }
}

const cardReset = {
  formattedChargeAmount: currencyFormatter('0.00'),
  chargeAmount: '',
  chargeAmountDiff: 0,
  chargeTotal: '',
  cardHolderName: '',
  cardHolderNumber: '',
  cardExpYear: '',
  cardExpMonth: '',
  cardCcv: '',
  chargeApplyTax: false,
  applyServiceCharge: false,
  applyGratuityCharge: false,
}

const calculateTotal = (state: BookPaymentState) => {
  const base = state.chargeAmount
  let serviceCharge = state.serviceCharge && state.serviceCharge.replace ? state.serviceCharge.replace(',', '.') : state.serviceCharge
  serviceCharge = state.applyServiceCharge ? money.floatToAmount(parseFloat(serviceCharge || '0')) : money.floatToAmount(0)
  const tax = state.chargeApplyTax ? money.floatToAmount(parseFloat(state.chargeTax || '0')) : money.floatToAmount(0)
  let gratuityCharge = state.gratuityCharge && state.gratuityCharge.replace ? state.gratuityCharge.replace(',', '.') : state.gratuityCharge
  gratuityCharge = state.applyGratuityCharge ? money.floatToAmount(parseFloat(gratuityCharge || '0')) : money.floatToAmount(0)
  const serviceChargeAmount = money.percent(serviceCharge, base)
  const subtotal = money.add(base, serviceChargeAmount)
  const taxAmount = money.percent(tax, subtotal)
  const gratuityChargeAmount = money.percent(gratuityCharge, base)
  let chargeTotal = money.add(subtotal, taxAmount)
  chargeTotal = money.add(chargeTotal, gratuityChargeAmount)
  return {
    serviceChargeAmount,
    taxAmount,
    gratuityChargeAmount,
    chargeTotal,
  }
}

const getChargesData = (
  chargeData: ChargeData,
  partySize: number,
  duration: number,
  override: boolean,
  previouslyChargedAmount?: number
) => {
  const paymentRule = chargeData.partySizeMinRule && partySize <= chargeData.partySizeMinRule ? null : chargeData.paymentRule

  let charges = {
    chargeAmount: '',
    formattedChargeAmount: priceFormatter.format('0', {
      code: chargeData.currencyCode,
      format: '%v',
    }),
    chargeAmountDiff: 0,
    chargeApplyTax: false,
    applyServiceCharge: false,
    serviceCharge: '',
    applyGratuityCharge: false,
    gratuityCharge: '',
    taxGroupId: '',
    chargeTax: '',
  }
  if (chargeData.cardRequired && paymentRule === 'advanced_payment') {
    let chargeAmount = chargeData.costMinRule
    if (chargeData.chargeType.startsWith('person')) {
      chargeAmount *= partySize
    }
    if (chargeData.chargeType.endsWith('slot')) {
      chargeAmount *= duration / 15
    }
    const chargeAmountDiff = chargeAmount - (previouslyChargedAmount ?? 0)
    chargeAmount = Math.max(chargeAmountDiff, 0)
    charges = {
      chargeAmount: money.floatToAmount(chargeAmount),
      formattedChargeAmount: priceFormatter.format(money.floatToAmount(chargeAmount), {
        code: chargeData.currencyCode,
        format: '%v',
      }),
      chargeAmountDiff,
      chargeApplyTax: chargeData.chargeApplyTax,
      applyServiceCharge: chargeData.applyServiceCharge,
      serviceCharge: String(chargeData.serviceCharge || ''),
      applyGratuityCharge: chargeData.applyGratuityCharge,
      gratuityCharge: String(chargeData.gratuityCharge || ''),
      taxGroupId: chargeData.taxGroupId,
      chargeTax: chargeData.chargeTax,
    }
  }
  if (override) {
    charges = {} as typeof charges
  }
  return {
    ...charges,
    paymentRule,
    cardRequired: !!(chargeData.cardRequired && paymentRule),
  }
}

const getTakePaymentOrSave = (state: BookPaymentState) => {
  let takePaymentOrSave: TakeOrSave = state.paymentRule === 'save_for_later' ? 'save' : 'take'
  if (
    !state.venue?.permissions?.canCharge ||
    (state.cardRequired && state.paymentRule === 'advanced_payment' && isZeroChargeAmount(state.chargeAmount))
  ) {
    takePaymentOrSave = 'save'
  }
  if (!state.override && isZeroChargeAmount(state.chargeAmount)) {
    if (!state.outstandingPaylink && (takePaymentOrSave !== 'save' || state.isEditMode)) {
      takePaymentOrSave = 'none'
    } else {
      takePaymentOrSave = 'save'
    }
  }
  if (state.override && isZeroChargeAmount(state.chargeAmount) && !state.outstandingPaylink) {
    takePaymentOrSave = 'none'
  }
  return takePaymentOrSave
}

const isZeroChargeAmount = (chargeAmount?: string) => money.isZero(chargeAmount ?? '')

const getStripeInstance = (
  currentInstance: ExtendedStripeInstance | null,
  cardAction: TakeOrSave,
  id: string | null,
  connectedSetupIntents?: string
) => {
  if (id === null || !window.Stripe) {
    return null
  }
  const action = cardAction === 'save' ? 'save' : 'take'
  const key = `${action}_${id}_${connectedSetupIntents}`
  if (currentInstance?._key === key) {
    return currentInstance
  }
  const options = action === 'save' && !connectedSetupIntents ? {} : { stripeAccount: id }
  const instance = window.Stripe(
    (window.globalInit as never as { venueSettings: { stripe_key: string } }).venueSettings.stripe_key,
    options
  ) as unknown as ExtendedStripeInstance
  instance._key = key
  return instance
}

const getGratuityTypes = ({ client_select_gratuity, require_select_gratuity }: Transaction): GratuityType => {
  if (client_select_gratuity) {
    return require_select_gratuity ? 'require_client_select_charge' : 'client_select_gratuity'
  }
  return 'gratuity_percentage'
}
