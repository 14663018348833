import _ from 'lodash'
import { combineReducers } from 'redux'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import emailWizardReducer from 'mgr/pages/single-venue/marketing/reducers/EmailWizard'
import filtersReducer from 'mgr/pages/single-venue/marketing/reducers/Filters'

const initialState = {
  list: {
    draft: [],
    active: [],
    inactive: [],
  },
  campaigns: [],
  histogram: [],
  campaign_activity: {},
  isLoading: false,
}

const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CAMPAIGN_SORT_CHANGE:
      return {
        ...state,
        list: {
          draft: _.orderBy(state.list.draft, [action.sortKey], [action.sortDirection]),
          active: _.orderBy(state.list.active, [action.sortKey], [action.sortDirection]),
          inactive: _.orderBy(state.list.inactive, [action.sortKey], [action.sortDirection]),
          oneTime: _.orderBy(state.list.oneTime, [action.sortKey], [action.sortDirection]),
        },
      }
    case ActionTypes.DEACTIVATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns.filter(c => c.id != action.campaign.id)],
      }
    case ActionTypes.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns.filter(c => c.id != action.campaign.id)],
      }
    case ActionTypes.LOAD_EMAILS_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.LOAD_EMAILS_SUCCESS:
    case ActionTypes.LOAD_EMAILS_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.LOAD_EMAIL_CAMPAIGN_ACTIVITY_SUCCESS:
      return {
        ...state,
        histogram: action.data.histogram,
        campaign_activity: action.data.campaign_activity,
        lastUpdated: moment(),
      }
    case ActionTypes.LOAD_EMAIL_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.data.results,
      }
    default:
      return state
  }
}

export default combineReducers({
  filters: filtersReducer,
  wizard: emailWizardReducer,
  campaigns: campaignsReducer,
})
