import { combineReducers } from 'redux'
import {
  GETTING_VENUE_PROFILE,
  GET_SUCCESS_VENUE_PROFILE,
  GET_FAIL_VENUE_PROFILE,
  POSTING_VENUE_PROFILE,
  POST_SUCCESS_VENUE_PROFILE,
  POST_FAIL_VENUE_PROFILE,
  UPDATE_FIELD,
} from '../actions/ActionTypes'

const initialProfileState = {
  id: '',
  name: '',
  address: '',
  crossStreet: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
  gmapsLink: '',
  phoneNumber: '',
  website: '',
  menuLink: '',
  facebookLink: '',
  instagramLink: '',
  snapchatLink: '',
  twitterLink: '',
  reservationWidgetLink: '',
  marketingSenderName: '',
  marketingEmail: '',
  marketingSenderRole: '',
  maximumDeliveryDiscount: 0,
  urlKey: '',
  currentSender: {
    marketingSenderName: '',
    marketingEmail: '',
    marketingSenderRole: '',
  },
  users: [],
  selectedCurrentSender: '',
  customDomainPrefix: '',
  customDomain: '',
}
const venueProfile = (state = initialProfileState, action) => {
  switch (action.type) {
    case GET_SUCCESS_VENUE_PROFILE:
      return { ...state, ...action.venueProfile }
    case UPDATE_FIELD:
      if (action.fieldName === 'currentSender') {
        const newSender = {
          marketingSenderName: state.marketingSenderName,
          marketingEmail: state.marketingEmail,
          marketingSenderRole: state.marketingSenderRole,
        }

        if (action.value && action.value !== 'Custom') {
          newSender.marketingSenderName = state.users[action.value].fullname
          newSender.marketingEmail = state.users[action.value].email
          newSender.marketingSenderRole = state.users[action.value].role
        }

        return {
          ...state,
          [action.fieldName]: newSender,
          selectedCurrentSender: undefined,
        }
      } else if (Object.keys(state.currentSender).includes(action.fieldName)) {
        const newCurrentSender = {
          ...state.currentSender,
          [action.fieldName]: action.value,
        }
        return { ...state, currentSender: newCurrentSender }
      }
      return { ...state, [action.fieldName]: action.value }

    default:
      return state
  }
}

const initialAppState = {
  isGetting: false,
  isPosting: false,
  isFailure: false,
  errors: {
    gmapsLink: null,
    menuLink: null,
    website: null,
    facebookLink: null,
    instagramLink: null,
    snapchatLink: null,
    twitterLink: null,
    reservationWidgetLink: null,
    contactEmail: null,
    marketingEmail: null,
    maximumDeliveryDiscount: null,
  }, // errors for form fields
}
const appState = (state = initialAppState, action) => {
  switch (action.type) {
    case UPDATE_FIELD:
      return {
        ...state,
        errors: { ...state.errors, [action.fieldName]: action.error },
      }
    case GETTING_VENUE_PROFILE:
      return { ...state, isGetting: true }
    case GET_SUCCESS_VENUE_PROFILE:
      return { ...state, isFailure: false, isGetting: false }
    case GET_FAIL_VENUE_PROFILE:
      return { ...state, isFailure: true, isGetting: false }
    case POSTING_VENUE_PROFILE:
      return { ...state, isPosting: true }
    case POST_SUCCESS_VENUE_PROFILE:
      return { ...state, isFailure: false, isPosting: false }
    case POST_FAIL_VENUE_PROFILE:
      return { ...state, isFailure: true, isPosting: false }
    default:
      return state
  }
}

const rootProfileReducer = combineReducers({
  venueProfile,
  appState,
})

export default rootProfileReducer
