import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import { getContrastingColor } from 'svr/common/Color'
import { VmsIcons, StyledVmsIconXS, StyledIcons } from 'svr/common/VmsIcons'
import ContentEditable from 'svr/lib/react-contenteditable'

const Icon = styled(StyledVmsIconXS)`
  vertical-align: middle;
  padding-right: ${props => props.theme.gutter.half};
`

const TAG_HEIGHT = '32px'

const Body = styled.div`
  ${props => (props.width ? `width: ${props.width};` : '')}
  min-width: 154px;
  float: ${props => props.float || 'left'};
  margin-right: 9px;
  ${props => props.customStyles};
`

const BorderedArea = styled.div`
  position: relative;
  cursor: pointer;
  border: 1px solid ${props => (props.isOpen ? props.theme.darkGrey : props.theme.lightGrey)};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  box-shadow: ${props => (props.isOpen ? '0 2px 4px 0 rgba(0,0,0,0.5)' : 'none')};
  padding-top: ${props => (props.useOutsideLabel ? '7px' : '0')};
  :hover {
    border: 1px solid ${props => props.theme.darkGrey};
    transition: border-color 0.2s ease-out;
  }
  ${props => props.theme.clearFix};
`

const NameOutside = styled.div`
  color: ${props => props.theme.darkGrey};
  ${props => props.theme.fontWeight300}
  font-size: ${props => props.theme.formLabelFontSize || '11px'};
  padding: 0 0 4px 10px;
`

const NameInside = styled.div`
  color: ${props => props.theme.darkGrey};
  font-weight: 500;
  font-size: 11px;
  padding: 3px 10px 5px;
`

const FilterTextInput = styled(ContentEditable)`
  font-size: 14px !important;
  color: ${props => (props.showPlaceholder ? props.theme.darkGrey : props.theme.navigationDark)};
  display: inline-block;
  border: none !important;
  margin: 0 0 ${props => (props.showPlaceholder ? '9px' : '7px')} 10px;
  padding-left: 1px; // So that contenteditable cursor appears with no text in Chrome
  line-height: ${props => (props.showPlaceholder ? '30px' : TAG_HEIGHT)};
  height: ${props => (props.showPlaceholder ? 'auto' : TAG_HEIGHT)};
  vertical-align: top;
  cursor: text;
  :focus {
    outline: none;
  }
`

const Caret = styled(StyledIcons.S)`
  float: right;
  transform: rotate(90deg);
  font-weight: 100;
  font-size: 17px;
  pointer-events: none;
  text-align: center;
  margin: ${props => (props.useOutsideLabel ? '3px' : '13px')} 6px 0;
  color: ${props => props.theme.navigationDark};
`

const DropdownRelative = styled.div`
  position: relative;
  width: 100%;
`

const DropdownAbsolute = styled.div`
  position: absolute;
  width: 100%;
`

const OptionsContainer = styled.div`
  position: relative;
  border: 1px solid ${props => (props.isOpen ? props.theme.darkGrey : props.theme.lightGrey)};
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box !important;
  top: -2px;
  left: 0;
  width: 100%;
  max-height: 332px;
  overflow-y: auto;
  z-index: 501;
  border-radius: 0 0 4px 4px;
  opacity: ${props => (props.isOpen ? '1' : '0')};
  height: ${props => (props.isOpen ? 'auto' : '0')};
  transform: ${props => (props.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top center;
  will-change: transform, opacity;
  transition: opacity 0.1s ease, transform 0.1s ease;
`

const TagGroupTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.theme.navigationDark};
  padding: 5px 10px;
  margin: 8px 0;
  text-transform: uppercase;
  ${props => props.theme.ellipsis};
`

const Tag = styled.div`
  display: inline-block;
  height: ${TAG_HEIGHT};
  min-width: 50px;
  border-radius: 16px;
  background-color: ${props => props.tagColor};
  color: ${props => props.tagTextColor};
  padding: 0 10px;
  margin: 0 -4px 5px 8px;
  cursor: pointer;
  opacity: ${props => (props.isSelectedTag ? '1' : '0.5')};
  :hover {
    opacity: 1;
  }
`

const TagNameLabel = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: ${TAG_HEIGHT};
  line-height: ${TAG_HEIGHT};
  font-size: 13px;
  font-weight: 500;
  max-width: 200px;
  margin-right: 8px;
  ${props => props.theme.ellipsis};
`

const TagCheckbox = styled(StyledIcons.S)`
  float: right;
  position: relative;
  top: 0;
  right: 0;
  font-size: 16px;
  line-height: ${TAG_HEIGHT};
`

const TagIcon = styled(StyledIcons.XS)`
  color: ${props => props.theme.navigationDark};
  margin-right: 5px;
`

export const getTagHash = (tagGroup, tagName) =>
  [tagGroup.is_private ? 'PRIVATE' : 'PUBLIC', tagGroup.id, tagGroup.name, tagName].join('##')

const isTagFilterMatch = (tagGroup, tagName, filterText) => {
  const tagNameDisplay = tagGroup.tag_name_displays[tagName] || tagName
  return filterText.length === 0 || _.includes(tagNameDisplay.toLowerCase(), filterText.toLowerCase())
}

const buildIsSelectedTagPredicate = tagHash => el => tagHash === el.tagHash

export const findTags = (tagGroups, tagHashList) => {
  if (!tagGroups || !tagHashList) {
    return []
  }
  const foundTags = []
  for (const tg of tagGroups) {
    for (const tagHash of tagHashList) {
      const values = tagHash.split('##')
      const tg_id = values[1]
      const tag = values[3]
      if (tg.id === tg_id && tg.tags.includes(tag)) {
        foundTags.push(
          genericTagToDisplayTag({
            tag_group_id: tg.id,
            tag_group_name: tg.name,
            tag_group_name_display: tg.name_display,
            tag_color: tg.color_hex,
            is_private: tg.is_private,
            entity_key: tg.entity_key,
            tag_name: tag,
            is_source: tg.is_source,
            is_autotag: tg.is_autotag,
            tag_name_display: tag,
          })
        )
      }
    }
  }
  return foundTags
}

export const genericTagToDisplayTag = ({
  tag_group_id,
  tag_group_name,
  tag_group_name_display,
  tag_color,
  is_private,
  entity_key,
  tag_name,
  is_source,
  is_autotag,
  tag_name_display,
}) => {
  const trueTagGroup = {
    id: tag_group_id,
    is_private,
    name: tag_group_name,
  }
  const tagName = tag_name_display || tag_name
  const tagHash = getTagHash(trueTagGroup, tag_name)
  const tagGroup = {
    id: tag_group_id,
    name: tag_group_name,
    color_hex: tag_color,
    is_private,
    entity_key,
    is_source,
    is_autotag,
    tag_name_display: tagName,
  }
  return { tagName, tagHash, tagGroup, tagRawOriginName: tag_name }
}

export const renderTag = (
  tagGroup,
  tagName,
  tagColor,
  tagTextColor,
  isSelectedTag,
  selectedIcon,
  handleItemClick,
  enforceIcon,
  tagRawOriginName
) => {
  const tagNameDisplay = (tagGroup.tag_name_displays && tagGroup.tag_name_displays[tagName]) || tagName

  return (
    <Tag
      key={`${tagGroup.id}_${tagName}`}
      {...{ tagColor, tagTextColor, isSelectedTag }}
      onClick={e => handleItemClick(e, tagGroup, tagRawOriginName || tagName)}
    >
      {tagGroup.is_autotag && <Icon>{VmsIcons.Refresh}</Icon>}
      <TagNameLabel>{tagNameDisplay}</TagNameLabel>
      {(!tagGroup.is_autotag || enforceIcon) && <TagCheckbox>{isSelectedTag ? selectedIcon : VmsIcons.OvalLine}</TagCheckbox>}
    </Tag>
  )
}

const renderTags = (tagGroup, selectedTags, filterText, handleItemClick, enforceIcon) => {
  const tagColor = tagGroup.color_hex
  const tagTextColor = getContrastingColor(tagColor)
  return tagGroup.tags
    .filter(tagName => isTagFilterMatch(tagGroup, tagName, filterText))
    .map(tagName => {
      const tagHash = getTagHash(tagGroup, tagName)
      const isSelectedTag = _.find(selectedTags, buildIsSelectedTagPredicate(tagHash))

      return renderTag(tagGroup, tagName, tagColor, tagTextColor, isSelectedTag, VmsIcons.Checked, handleItemClick, enforceIcon, tagName)
    })
}

const renderTagGroups = (tagGroups, selectedTags, filterText, handleItemClick, allowAutotags, enforceIcon) =>
  (tagGroups || [])
    .filter(tagGroup => {
      if (allowAutotags) {
        return true
      }

      return !tagGroup.is_autotag
    })
    .map(tagGroup => {
      const renderedTags = renderTags(tagGroup, selectedTags, filterText, handleItemClick, enforceIcon)
      if (_.isEmpty(_.compact(renderedTags))) {
        return null
      }
      return (
        <div key={tagGroup.id}>
          <TagGroupTitle>
            <TagIcon>{VmsIcons.Tag}</TagIcon>
            {tagGroup.name_display || tagGroup.name}
          </TagGroupTitle>
          {renderedTags}
        </div>
      )
    })

const renderSelectedTags = (selectedTags, handleItemClick, enforceIcon) =>
  (selectedTags || [])
    .filter(selectedTag => selectedTag.tagGroup)
    .map(selectedTag => {
      const { tagGroup, tagName, tagRawOriginName } = selectedTag
      const tagColor = tagGroup.color_hex
      const tagTextColor = getContrastingColor(tagColor)
      const isSelectedTag = true
      return renderTag(
        tagGroup,
        tagName,
        tagColor,
        tagTextColor,
        isSelectedTag,
        VmsIcons.ClearLine,
        handleItemClick,
        enforceIcon,
        tagRawOriginName
      )
    })

class GenericTagsDropDown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      filterText: '',
    }
    this.filterTextInputRef = React.createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    for (const field of ['venueId', 'name', 'width', 'tagGroups', 'selectedTags']) {
      if (this.props[field] !== nextProps[field]) {
        return true
      }
    }
    for (const field of ['isOpen', 'filterText']) {
      if (this.state[field] !== nextState[field]) {
        return true
      }
    }
    return false
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      this.filterTextInputRef.current.htmlEl.focus()
    }
  }

  toggleDropdown(isOpen) {
    this.setState({
      isOpen,
      filterText: '',
    })
    if (!isOpen) {
      this.filterTextInputRef.current.htmlEl.blur()
    }
  }

  handleClickOutside() {
    if (this.state.isOpen) {
      this.toggleDropdown(false)
    }
  }

  handleToggleDropdownClick = () => {
    this.toggleDropdown(!this.state.isOpen)
  }

  isValid = () => {
    const { customValidator, selectedTags } = this.props
    return customValidator(selectedTags)
  }

  handleItemClick = (e, tagGroup, tagName) => {
    if (!this.props.allowAutotags && tagGroup.is_autotag) {
      return
    }

    const { onChangeHandler, selectedTags, isMultiSelect = true } = this.props
    const tagHash = getTagHash(tagGroup, tagName)
    const isSelectedPredicate = buildIsSelectedTagPredicate(tagHash)
    let updatedTags

    if (_.find(selectedTags, isSelectedPredicate)) {
      updatedTags = [...(selectedTags || [])]
      _.remove(updatedTags, isSelectedPredicate)
    } else if (!isMultiSelect) {
      updatedTags = [
        {
          tagName,
          tagHash,
          tagGroup,
        },
      ]
    } else {
      updatedTags = [
        ...(selectedTags || []),
        {
          tagName,
          tagHash,
          tagGroup,
          tagRawOriginName: tagName,
        },
      ]
    }

    this.setState({ filterText: '' })
    onChangeHandler(updatedTags)
    e.stopPropagation()
  }

  onFilterTextInputChange = e => {
    const newVal = e.target.value
    if (newVal !== this.state.filterText) {
      this.setState({ filterText: newVal.replace(/(<br\s*\/?>)|(\n)/gi, '') })
    }
  }

  onFilterTextInputClick = e => {
    if (this.state.isOpen) {
      e.stopPropagation() // Prevent from closing
    }
  }

  onFilterTextInputKeyUp = e => {
    if (e.which === 13) {
      this.onFirstTagClick(e)
      e.stopPropagation()
      return false
    } else if (e.keyCode === 27) {
      this.toggleDropdown(false)
      e.stopPropagation()
      return false
    }
    return true
  }

  onFirstTagClick(e) {
    const firstTagGroupAndName = this.getFirstTag()
    if (!_.isEmpty(firstTagGroupAndName)) {
      const [tagGroup, tagName] = firstTagGroupAndName
      this.handleItemClick(e, tagGroup, tagName)
    }
  }

  getFirstTag() {
    const { tagGroups } = this.props
    const { filterText } = this.state
    for (const tagGroup of tagGroups) {
      for (const tagName of tagGroup.tags) {
        if (isTagFilterMatch(tagGroup, tagName, filterText)) {
          return [tagGroup, tagName]
        }
      }
    }
    return null
  }

  render() {
    const {
      name,
      tagGroups,
      width,
      selectedTags,
      placeholder,
      useOutsideLabel,
      float,
      customStyles,
      allowAutotags = false,
      enforceIcon = false,
    } = this.props
    const { isOpen, filterText } = this.state
    const showPlaceholder = _.isEmpty(filterText) && !isOpen && _.isEmpty(selectedTags)
    const filterTextOrEmptyDisplay = showPlaceholder ? placeholder : filterText || ''

    return (
      <Body data-test={name ? `sr-tags-${name.toLowerCase().replace(/ /g, '_')}` : null} {...{ width, float, customStyles }}>
        {useOutsideLabel && <NameOutside onClick={this.handleToggleDropdownClick}>{name}</NameOutside>}
        <BorderedArea {...{ isOpen, useOutsideLabel }} onClick={this.handleToggleDropdownClick}>
          <Caret {...{ useOutsideLabel }}>{VmsIcons.Chevron}</Caret>
          {!useOutsideLabel && <NameInside>{name}</NameInside>}
          {renderSelectedTags(selectedTags, this.handleItemClick, enforceIcon)}
          <FilterTextInput
            data-test="sr-tags-input"
            ref={this.filterTextInputRef}
            onChange={this.onFilterTextInputChange}
            onClick={this.onFilterTextInputClick}
            onKeyUp={this.onFilterTextInputKeyUp}
            onBlur={() => {}}
            html={filterTextOrEmptyDisplay}
            {...{ showPlaceholder }}
          />
        </BorderedArea>
        <DropdownRelative>
          <DropdownAbsolute>
            <OptionsContainer {...{ isOpen }}>
              {renderTagGroups(tagGroups, selectedTags, filterText, this.handleItemClick, allowAutotags, enforceIcon)}
            </OptionsContainer>
          </DropdownAbsolute>
        </DropdownRelative>
      </Body>
    )
  }
}

GenericTagsDropDown.defaultProps = {
  allowAutotags: false,
  enforceIcon: false,
  isMultiSelect: true,
  name: null,
  placeholder: '',
  useOutsideLabel: true,
  onChangeHandler: () => {},
  customValidator: () => true,
  customStyles: {},
}

GenericTagsDropDown.propTypes = {
  allowAutotags: PropTypes.bool,
  enforceIcon: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  useOutsideLabel: PropTypes.bool,
  width: PropTypes.string,
  float: PropTypes.string,
  selectedTags: PropTypes.array,
  venueId: PropTypes.string,
  tagGroups: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  customValidator: PropTypes.func.isRequired,
  customStyles: React.PropTypes.array,
}

GenericTagsDropDown = onClickOutside(GenericTagsDropDown)
export default GenericTagsDropDown
