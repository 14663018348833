/* eslint-disable camelcase */
import { createApi, fetchBaseQuery, type FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { getOrderingNotificationSettingsByVenueId, saveOrderingNotificationSettings } from '@sevenrooms/core/api'
import type { OrderingNotificationSettings, NotificationSettingsMap } from '@sevenrooms/core/domain'

export const orderingNotificationSettingsApi = createApi({
  reducerPath: 'ordering.notificationSettings',
  baseQuery: fetchBaseQuery({ baseUrl: '/api-yoa/' }),
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getOrderingNotificationSettings: builder.query<OrderingNotificationSettings, { venueId: string }>({
      async queryFn(args, _queryApi, _extraOptions, _fetchWithBQ) {
        try {
          return await getOrderingNotificationSettingsByVenueId(args.venueId)
        } catch (e) {
          return { error: e as FetchBaseQueryError }
        }
      },
    }),

    saveOrderingNotificationSettings: builder.mutation<void, { venueId: string; orderingNotificationSettings: NotificationSettingsMap }>({
      async queryFn(args, _queryApi, _extraOptions, _fetchWithBQ) {
        try {
          return await saveOrderingNotificationSettings(args.venueId, args.orderingNotificationSettings)
        } catch (e) {
          return { error: e as FetchBaseQueryError }
        }
      },
    }),
  }),
})

export const { useGetOrderingNotificationSettingsQuery, useSaveOrderingNotificationSettingsMutation } = orderingNotificationSettingsApi
