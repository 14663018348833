import { defineMessages } from '@sevenrooms/core/locales'

export const emailCenterMessages = defineMessages({
  emailCenterTitle: {
    id: 'emailCenter.title',
    defaultMessage: 'Automated Emails',
  },
  emailCenterNavTitle: {
    id: 'emailCenter.navTitle',
    defaultMessage: 'Automations',
  },
  emailCenterDescription: {
    id: 'emailCenter.description',
    defaultMessage: 'View and manage overall and individual email campaign performance and settings.',
  },
  emailCenterCampaignPerformance: {
    id: 'emailCenter.campaignPerformance',
    defaultMessage: 'Campaign Performance',
  },
  emailCenterEmailCampaigns: {
    id: 'emailCenter.emailCampaigns',
    defaultMessage: 'Email Campaigns',
  },
  emailCenterEmailCampaignsDescription: {
    id: 'emailCenter.emailCampaignsDescription',
    defaultMessage: 'Here you can manage your email campaigns, view individual campaign performance or create new email campaigns.',
  },
  campaignPerformanceChartNoData: {
    id: 'emailCenter.emailCenter.campaignPerformanceChartNoData',
    defaultMessage: 'No email campaign data for that time range.',
  },
  campaignPerformanceChartError: {
    id: 'emailCenter.emailCenter.campaignPerformanceChartError',
    defaultMessage: 'Error during fetching email campaign data.',
  },
  emailCenterNewEmail: {
    id: 'emailCenter.newEmail',
    defaultMessage: 'Create New Automated Email',
  },
  emails: {
    id: 'emailCenter.emails',
    defaultMessage: 'Emails',
  },
  campaignText: {
    id: 'campaignText',
    defaultMessage: 'Automated Emails',
  },
  emailCenterNewEmailModalTitle: {
    id: 'emailCenter.newEmailModalTitle',
    defaultMessage: 'Choose your starting point',
  },
  emailCenterNewEmailModalSubTitle: {
    id: 'emailCenter.NewEmailModalSubTitle',
    defaultMessage:
      "We've made it easy to create an Automated Email. Start with a pre-built email or take the reins and start from scratch.",
  },
  emailCenterStandardAutomatedEmails: {
    id: 'emailCenter.StandardAutomatedEmails',
    defaultMessage: 'Standard Automated Emails',
  },
  emailCenterStandardAutomatedEmailsDescription: {
    id: 'emailCenter.StandardAutomatedEmailsDescription',
    defaultMessage: 'Leverage our library of Automated Emails proven to drive incremental visits and orders.',
  },
  emailCenterUseStandardAutomatedEmailsOn: {
    id: 'emailCenter.UseStandardAutomatedEmails',
    defaultMessage: 'Use a Standard Email',
  },
  emailCenterUseStandardAutomatedEmailsOff: {
    id: 'emailCenter.UseStandardAutomatedEmails',
    defaultMessage: 'Coming Soon',
  },
  emailCenterStartFromScratchTitle: {
    id: 'emailCenter.startFromScratchTitle',
    defaultMessage: 'Start From Scratch',
  },
  emailCenterStartFromScratchDescription: {
    id: 'emailCenter.startFromScratchDescription',
    defaultMessage: 'Create a custom Automated Email by using a rich text editor or HTML.',
  },
  emailCenterCustomCampaignsTitle: {
    id: 'emailCenter.customCampaignsTitle',
    defaultMessage: 'Start From Scratch',
  },
  emailCenterCustomCampaignsDescription: {
    id: 'emailCenter.customCampaignsDescription',
    defaultMessage: 'Create a custom Automated Email by using a rich text editor or HTML.',
  },
  emailCenterCustomCampaignsDisabledTitle: {
    id: 'emailCenter.CustomCampaignsDisabledTitle',
    defaultMessage: 'Want to Customize Your Emails?',
  },
  emailCenterCustomCampaignsDisabledDescription: {
    id: 'emailCenter.CustomCampaignsDisabledDescription',
    defaultMessage:
      'Your package includes standard emails. If you would like to be able to create custom Automated Emails, please reach out to your Customer Success Manager or Support to upgrade your package.',
  },
  emailCenterCreateFromScratch: {
    id: 'emailCenter.createFromScratch',
    defaultMessage: 'Start From Scratch',
  },
  emailCenterHtmlTitle: {
    id: 'emailCenter.htmlTitle',
    defaultMessage: 'HTML',
  },
  emailCenterHtmlDescription: {
    id: 'emailCenter.htmlDescription',
    defaultMessage: 'Create or paste HTML code from existing email campaigns',
  },
  emailCenterStartWithHtml: {
    id: 'emailCenter.startWithHtml',
    defaultMessage: 'Start with HTML',
  },
  emailCenterRecommended: {
    id: 'emailCenter.recommended',
    defaultMessage: 'Recommended',
  },
  emailType: {
    id: 'emailCenter.emailType',
    defaultMessage: 'Email Type',
  },
  emailOrderedBy: {
    id: 'emailCenter.orderedBy',
    defaultMessage: 'Ordered By',
  },
  deactivateEmailCampaignModalDeactivateManyTitle: {
    id: 'emailCenter.deactivateEmailCampaignModal.deactivateManyTitle',
    defaultMessage: 'Are you sure you want to turn off all "{emailCampaignName}" emails?',
  },
  deactivateEmailCampaignModalDeactivateManyBody: {
    id: 'emailCenter.deactivateEmailCampaignModal.deactivateManyBody',
    defaultMessage: 'Turning off the "{emailCampaignName}" campaign category will turn off all emails within the category.',
  },
  deactivateEmailCampaignModalDeactivateOneTitle: {
    id: 'emailCenter.deactivateEmailCampaignModal.deactivateOneTitle',
    defaultMessage: 'Are you sure you want to turn off "{emailCampaignName}"?',
  },
  deactivateEmailCampaignModalDeactivateOneBody: {
    id: 'emailCenter.deactivateEmailCampaignModal.deactivateOneBody',
    defaultMessage: 'Turning this email off will prevent it from being sent out in the future, even when the set activity is triggered.',
  },
  deactivateEmailCampaignModalCancel: {
    id: 'emailCenter.deactivateEmailCampaignModal.cancel',
    defaultMessage: 'Keep it on',
  },
  deactivateEmailCampaignModalConfirm: {
    id: 'emailCenter.deactivateEmailCampaignModal.confirm',
    defaultMessage: 'Turn it off',
  },
  deactivateEmailCampaignModalSuccessMessage: {
    id: 'emailCenter.deactivateEmailCampaignModal.successMessage',
    defaultMessage: 'Deactivated {emailCampaignName}.',
  },
  deactivateEmailCampaignModalErrorMessage: {
    id: 'emailCenter.deactivateEmailCampaignModal.errorMessage',
    defaultMessage: 'Unable to deactivate {emailCampaignName}, please try again later.',
  },
  activateEmailCampaignErrorMessage: {
    id: 'emailCenter.activateEmailCampaignErrorMessage',
    defaultMessage: 'Unable to activate {emailCampaignName}, please try again later.',
  },
  active: {
    id: 'emailCenter.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'emailCenter.inactive',
    defaultMessage: 'Inactive',
  },
  draft: {
    id: 'emailCenter.draft',
    defaultMessage: 'Draft',
  },
  archived: {
    id: 'emailCenter.archived',
    defaultMessage: 'Archived',
  },
  sending: {
    id: 'emailCenter.sending',
    defaultMessage: 'Sending',
  },
  sent: {
    id: 'emailCenter.sent',
    defaultMessage: 'Sent',
  },
  complete: {
    id: 'emailCenter.complete',
    defaultMessage: 'Completed',
  },
  completed: {
    id: 'emailCenter.completed',
    defaultMessage: 'Completed',
  },
  confirmModalTitle: {
    id: 'emailCenter.confirmModalTitle',
    defaultMessage: 'Are you sure you want to archive this Automated Email?',
  },
  confirmModalInformation: {
    id: 'emailCenter.confirmModalInformation',
    defaultMessage: 'Archiving this Automated Email will remove it from the active email center.',
  },
  confirmModalHistoryInfo: {
    id: 'emailCenter.confirmModalHistoryInfo',
    defaultMessage: 'We will keep all of the Automated Email’s historical performance data.',
  },
  confirmModalDelete: {
    id: 'emailCenter.confirmDelete',
    defaultMessage: 'Confirm Archive',
  },
  emailCampaignTurnOff: {
    id: 'emailCenter.emailCampaignTurnOff',
    defaultMessage: 'Turn off Automated Email',
  },
  emailCampaignTurnOn: {
    id: 'emailCenter.emailCampaignTurnOff',
    defaultMessage: 'Turn on Automated Email',
  },
  emailArchiveSuccess: {
    id: 'emailCenter.emailArchiveSuccess',
    defaultMessage: 'Automated Email archived successfully',
  },
  emailArchiveError: {
    id: 'emailCenter.emailArchiveError',
    defaultMessage: 'Automated Email archive failed',
  },
  sectionNotEditable: {
    id: 'emailCenter.sectionNotEditable',
    defaultMessage: 'This section is not editable',
  },
} as const)
