import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as PaymentActions from 'mgr/actualslideout/actions/PaymentActions'
import ChargeForm from 'mgr/actualslideout/components/payment/ChargeForm'
import NotificationForm from 'mgr/actualslideout/components/payment/NotificationForm'
import Transaction from 'mgr/actualslideout/components/payment/Transaction'
import { CreditCardView, ChargeActions, BreakTitle, Transactions } from 'mgr/actualslideout/components/payment/ViewComponents'
import { ViewCardTitleSmall, ViewField, SectionedCard } from 'mgr/actualslideout/components/view/ViewCommon'
import { FlexColumnContainer } from 'mgr/actualslideout/components/view/ViewLayout'
import PaylinkRemoveModal from 'mgr/actualslideout/containers/view/PaylinkRemoveModal'

function TransactionList({
  actual,
  transactions,
  refundData,
  selectedClient,
  expandedRows,
  currencySymbol,
  urlKey,
  deletingRequest,
  actions,
  formErrors,
  validateFieldMap,
  onSubmitRefundHandler,
  onSubmitNotificationHandler,
  canCharge,
  canRefund,
  payoutProfileAccountId,
  venueTimezone,
  venueLocale,
}) {
  return (
    <div>
      {transactions.map(transaction => {
        if (!transaction || !transaction.transaction_type) {
          return null
        }
        if (!transaction.id) {
          // eslint-disable-next-line no-param-reassign
          transaction.id = new Date().getTime()
        }
        return (
          <Transaction
            key={transaction.id}
            transaction={transaction}
            refundData={refundData}
            selectedClient={selectedClient}
            expandedRows={expandedRows}
            actual={actual}
            currencySymbol={currencySymbol}
            formErrors={formErrors}
            validateFieldMap={validateFieldMap}
            urlKey={urlKey}
            deletingRequest={deletingRequest}
            actions={actions}
            onSubmitRefundHandler={onSubmitRefundHandler}
            onSubmitNotificationHandler={onSubmitNotificationHandler}
            canCharge={canCharge}
            canRefund={canRefund}
            payoutProfileAccountId={payoutProfileAccountId}
            venueTimezone={venueTimezone}
            venueLocale={venueLocale}
          />
        )
      })}
    </div>
  )
}

const SectionedCreditCard = styled(SectionedCard)`
  margin: 0;
`

const ChargeActionsContainer = styled(FlexColumnContainer)`
  margin-top: ${props => props.theme.gutter.standard};
`

const ShowMoreDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoadMoreButton = styled.button`
  padding: 5px 10px;
  color: grey;
  border: none;
  cursor: pointer;
  width: 50%;
  border-radius: 4px;
  margin: 20px 0 10px 0;
`

export function PaymentCard({
  transactions,
  isLoadingTransactions,
  transactionHasMore,
  expandedRows,
  chargeModalOpen,
  refundingId,
  refundType,
  refundAmount,
  refundDescription,
  refundSendNotification,
  refundGiftCardAmount,
  cardEntryOption,
  clientCards,
  cardHolderName,
  cardHolderNumber,
  cardExpMonth,
  cardExpYear,
  cardCcv,
  cardPhoneNumber,
  override,
  useGuestProfilePhoneNumber,
  takePaymentOrSave,
  formattedChargeAmount,
  chargeAmount,
  chargeTax,
  chargeApplyTax,
  chargeSendNotification,
  chargeTotal,
  chargeDescription,
  taxGroups,
  taxGroupId,
  applyServiceCharge,
  serviceCharge,
  applyGratuityCharge,
  gratuityType,
  gratuityCharge,
  requiredGratuityCharge,
  paylinkGratuityType,
  resCardId,
  resCardLast4,
  resCardType,
  resCardEmail,
  additionalReservationCards,
  chargeType,
  cardRequired,
  paymentRule,
  partySizeMinRule,
  costMinRule,
  chargeOnlyModal,
  requiredGratuity,
  applyTaxRate,
  currencySymbol,
  currencyCode,
  actual,
  actions,
  isProcessingDelete,
  notificationEmail,
  notificationType,
  notificationModalOpen,
  isModalSubmitting,
  urlKey,
  deletingRequest,
  formErrors,
  selectedClient,
  canSaveCard,
  isMotoEnabled,
  canCharge,
  canRefund,
  paylinkOnly,
  paymentSystem,
  stripeInstance,
  payoutProfileAccountId,
  venueTimezone,
  venueLocale,
  selectedTimeSlot,
  resActual,
  paylinkAutoCancel,
  creditCardCollection,
  incompletePaylinkAutoCancelMinutesLong,
  incompletePaylinkAutoCancelMinutesShort,
  venue,
}) {
  const cardDetails = {
    cardHolderName,
    cardHolderNumber,
    cardExpMonth,
    cardExpYear,
    cardCcv,
    cardPhoneNumber,
  }

  const resCardDetails = {
    resCardId,
    resCardLast4,
    resCardType,
    isProcessingDelete,
    notificationEmail: resCardEmail,
  }

  const chargeDetails = {
    override,
    takePaymentOrSave,
    cardEntryOption,
    clientCards,
    currencySymbol,
    currencyCode,
    formattedChargeAmount,
    chargeAmount,
    chargeTax,
    chargeApplyTax,
    chargeTotal,
    chargeDescription,
    chargeSendNotification,
    useGuestProfilePhoneNumber,
    notificationEmail,
    canCharge,
    taxGroups,
    taxGroupId,
    paymentSystem,
    applyServiceCharge,
    serviceCharge,
    applyGratuityCharge,
    gratuityType,
    gratuityCharge,
    requiredGratuityCharge,
    paylinkGratuityType,
    paylinkAutoCancel,
    creditCardCollection,
    incompletePaylinkAutoCancelMinutesLong,
    incompletePaylinkAutoCancelMinutesShort,
  }

  const paymentRules = {
    chargeType,
    cardRequired,
    paymentRule,
    partySizeMinRule,
    costMinRule,
    requiredGratuity,
    applyTaxRate,
    canSaveCard,
    isMotoEnabled,
  }

  const refundData = {
    refundingId,
    refundType,
    refundAmount,
    refundDescription,
    refundSendNotification,
    notificationEmail,
    isModalSubmitting,
    refundGiftCardAmount,
  }

  const validateFieldMap = {}

  const findInvalidFieldsInSection = validateFieldMap =>
    _.reduce(
      validateFieldMap,
      (invalidFields, input, field) => {
        const invalidText = !_.isNil(input) && ((input.props && input.props.disabled) || input.isValid())
        return _.assign(invalidFields, invalidText !== true && invalidText !== false && { [field]: invalidText })
      },
      {}
    )

  const validateFields = () => {
    const errors = findInvalidFieldsInSection(validateFieldMap)

    actions.chargeFormValidated(errors)
    return errors
  }

  const onSubmitChargeHandler = () => {
    const errors = validateFields()
    if (_.isEmpty(errors)) {
      actions.submitCharge()
    }
  }

  const onSubmitRefundHandler = () => {
    const errors = validateFields()
    if (_.isEmpty(errors)) {
      actions.submitRefund()
    }
  }

  const onSubmitNotificationHandler = isRefund => {
    const errors = validateFields()
    if (_.isEmpty(errors)) {
      actions.sendNotification(isRefund)
    }
  }
  const transactionContents = !_.isEmpty(transactions) ? (
    <TransactionList
      transactions={transactions}
      expandedRows={expandedRows}
      refundData={refundData}
      selectedClient={selectedClient}
      formErrors={formErrors}
      validateFieldMap={validateFieldMap}
      actual={actual}
      currencySymbol={currencySymbol}
      urlKey={urlKey}
      deletingRequest={deletingRequest}
      actions={actions}
      canCharge={canCharge}
      canRefund={canRefund}
      onSubmitRefundHandler={onSubmitRefundHandler}
      onSubmitNotificationHandler={onSubmitNotificationHandler}
      payoutProfileAccountId={payoutProfileAccountId}
      venueTimezone={venueTimezone}
      venueLocale={venueLocale}
    />
  ) : null

  return (
    <CreditCardView data-test="sr-section-credit_card">
      <SectionedCreditCard>
        <ViewField>
          <ViewCardTitleSmall>Credit card</ViewCardTitleSmall>
        </ViewField>
        {(canCharge || canSaveCard) && (
          <ChargeActionsContainer>
            <ChargeActions
              canCharge={canCharge}
              paylinkOnly={paylinkOnly}
              resCard={resCardDetails}
              actions={actions}
              additionalReservationCards={additionalReservationCards}
              selectedTimeSlot={selectedTimeSlot}
              resActual={resActual}
            />
          </ChargeActionsContainer>
        )}
      </SectionedCreditCard>
      {transactions.length > 0 && (
        <div data-test="sr-section-transactions">
          <BreakTitle>Transactions ({transactions.length})</BreakTitle>
          <Transactions>
            <FlexColumnContainer>{transactionContents}</FlexColumnContainer>
          </Transactions>
          <ShowMoreDisplay>
            {transactionHasMore && !isLoadingTransactions && (
              <LoadMoreButton onClick={() => actions.tryGetTransactions(actual.venue_id, actual.id)}>Load More</LoadMoreButton>
            )}
          </ShowMoreDisplay>
        </div>
      )}
      {chargeModalOpen && (
        <ChargeForm
          actual={actual}
          selectedClient={selectedClient}
          chargeDetails={chargeDetails}
          cardDetails={cardDetails}
          resCardDetails={resCardDetails}
          paymentRules={paymentRules}
          currencySymbol={currencySymbol}
          paylinkOnly={paylinkOnly}
          formErrors={formErrors}
          validateFieldMap={validateFieldMap}
          isModalSubmitting={isModalSubmitting}
          chargeOnlyModal={chargeOnlyModal}
          actions={actions}
          validateFields={validateFields}
          onSubmitChargeHandler={onSubmitChargeHandler}
          stripeInstance={stripeInstance}
          venueTimezone={venueTimezone}
          selectedTimeSlot={selectedTimeSlot}
          venue={venue}
        />
      )}
      {notificationModalOpen && (
        <NotificationForm
          notificationEmail={notificationEmail}
          notificationType={notificationType}
          selectedClient={selectedClient}
          isModalSubmitting={isModalSubmitting}
          actions={actions}
          formErrors={formErrors}
          validateFieldMap={validateFieldMap}
          onSubmitNotificationHandler={onSubmitNotificationHandler}
        />
      )}
      <PaylinkRemoveModal />
    </CreditCardView>
  )
}

const mapStateToProps = state => ({
  transactions: state.viewResState.transactions,
  isLoadingTransactions: state.viewResState.isLoadingTransactions,
  transactionHasMore: state.viewResState.transactionHasMore,
  expandedRows: state.bookPaymentState.expandedRows,
  chargeModalOpen: state.bookPaymentState.chargeModalOpen,
  refundingId: state.bookPaymentState.refundingId,
  refundType: state.bookPaymentState.refundType,
  refundAmount: state.bookPaymentState.refundAmount,
  refundDescription: state.bookPaymentState.refundDescription,
  refundSendNotification: state.bookPaymentState.refundSendNotification,
  refundGiftCardAmount: state.bookPaymentState.refundGiftCardAmount,
  cardEntryOption: state.bookPaymentState.cardEntryOption,
  clientCards: state.bookPaymentState.clientCards,
  cardHolderName: state.bookPaymentState.cardHolderName,
  cardHolderNumber: state.bookPaymentState.cardHolderNumber,
  cardExpMonth: state.bookPaymentState.cardExpMonth,
  cardExpYear: state.bookPaymentState.cardExpYear,
  cardCcv: state.bookPaymentState.cardCcv,
  cardPhoneNumber: state.bookPaymentState.cardPhoneNumber,
  override: state.bookPaymentState.override,
  useGuestProfilePhoneNumber: state.bookPaymentState.useGuestProfilePhoneNumber,
  takePaymentOrSave: state.bookPaymentState.takePaymentOrSave,
  formattedChargeAmount: state.bookPaymentState.formattedChargeAmount,
  chargeAmount: state.bookPaymentState.chargeAmount,
  chargeTax: String(state.bookState.selectedVenue.bookSettings.taxRate),
  taxGroups: state.bookState.selectedVenue.bookSettings.taxGroups,
  taxGroupId: state.bookPaymentState.taxGroupId,
  chargeApplyTax: state.bookPaymentState.chargeApplyTax,
  applyServiceCharge: state.bookPaymentState.applyServiceCharge,
  serviceCharge: state.bookPaymentState.serviceCharge,
  applyGratuityCharge: state.bookPaymentState.applyGratuityCharge,
  gratuityType: state.bookPaymentState.gratuityType,
  gratuityCharge: state.bookPaymentState.gratuityCharge,
  requiredGratuityCharge: state.bookPaymentState.requiredGratuityCharge,
  paylinkGratuityType: state.bookPaymentState.paylinkGratuityType,
  chargeSendNotification: state.bookPaymentState.chargeSendNotification,
  notificationEmail: state.bookPaymentState.notificationEmail,
  notificationModalOpen: state.bookPaymentState.notificationModalOpen,
  notificationType: state.bookPaymentState.notificationType,
  chargeTotal: state.bookPaymentState.chargeTotal,
  chargeDescription: state.bookPaymentState.chargeDescription,
  resCardId: state.bookPaymentState.resCardId,
  resCardLast4: state.bookPaymentState.resCardLast4,
  resCardType: state.bookPaymentState.resCardType,
  resCardEmail: state.bookPaymentState.resCardEmail,
  additionalReservationCards: state.bookPaymentState.additionalReservationCards,
  chargeType: state.bookPaymentState.chargeType,
  cardRequired: state.bookPaymentState.cardRequired,
  paymentRule: state.bookPaymentState.paymentRule,
  partySizeMinRule: state.bookPaymentState.partySizeMinRule || 0,
  costMinRule: state.bookPaymentState.costMinRule || 0,
  requiredGratuity: state.bookPaymentState.requiredGratuity || 0,
  applyTaxRate: state.bookPaymentState.applyTaxRate || false,
  chargeOnlyModal: state.bookPaymentState.chargeOnlyModal,
  currencySymbol: state.bookState.selectedVenue.currencySymbol,
  currencyCode: state.bookState.selectedVenue.currencyCode,
  paylinkOnly: state.bookState.selectedVenue.bookSettings.paylinkOnly,
  isModalSubmitting: state.bookPaymentState.isModalSubmitting,
  isProcessingDelete: state.bookPaymentState.isProcessingDelete,
  formErrors: state.bookPaymentState.formErrors,
  urlKey: state.bookState.selectedVenue.urlKey,
  deletingRequest: state.bookPaymentState.deletingRequest,
  selectedClient: state.bookClientState.selectedClient || {},
  canSaveCard: state.bookState.selectedVenue.bookSettings.canSaveCard,
  isMotoEnabled: state.bookState.selectedVenue.bookSettings.isMotoEnabled,
  canCharge: state.bookState.selectedVenue.permissions.canCharge,
  canRefund: state.bookState.selectedVenue.permissions.canRefund,
  paymentSystem: state.bookState.selectedVenue.bookSettings.paymentSystem,
  stripeInstance: state.bookPaymentState.stripeInstance,
  payoutProfileAccountId: state.bookState.selectedVenue.accountId,
  venueTimezone: state.bookPaymentState.venueTimezone,
  venueLocale: state.bookState.selectedVenue.locale,
  venue: state.bookState.selectedVenue,
  selectedTimeSlot: state.bookAvailabilityState.selectedTimeSlot,
  resActual: state.viewResState.actual,
  paylinkAutoCancel: state.bookPaymentState.paylinkAutoCancel,
  creditCardCollection: state.bookPaymentState.creditCardCollection,
  incompletePaylinkAutoCancelMinutesLong: state.bookPaymentState.incompletePaylinkAutoCancelMinutesLong,
  incompletePaylinkAutoCancelMinutesShort: state.bookPaymentState.incompletePaylinkAutoCancelMinutesShort,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...PaymentActions }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCard)
