// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  children?: React.Node,
  customModalScreen: Array<string | (() => mixed)>,
  customModalWrapper: Array<string | (() => mixed)>,
  onClickHandler: () => mixed,
  modalWrapperClassName: string,
} */

const ModalCover = (props /* : Props */) => (
  <ModalScreen customModalScreen={props.customModalScreen}>
    <ModalWrapper customModalWrapper={props.customModalWrapper} className={props.modalWrapperClassName} onClick={props.onClickHandler}>
      {props.children}
    </ModalWrapper>
  </ModalScreen>
)

ModalCover.defaultProps = {
  customModalScreen: [],
  customModalWrapper: [],
  onClickHandler: () => {},
}

const ModalScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  ${props => props.customModalScreen};
`

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 92%;
  overflow: auto;
  transform: translate(-50%, -50%);
  ${props => props.customModalWrapper};
`

export default ModalCover
