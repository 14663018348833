import { createApi } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { BookingAccessOverrideListService } from '../services'

export const bookingAccessOverrideListApi = createApi({
  reducerPath: 'settings.bookingAccessOverrideList',
  tagTypes: ['BookingAccessOverrideList'],
  baseQuery: RTKUtils.noBaseQuery,
  endpoints: builder => ({
    getAccessRuleOverrides: builder.query({
      providesTags: ['BookingAccessOverrideList'],
      queryFn: RTKUtils.rtkQuery(BookingAccessOverrideListService.getAccessRuleOverrides),
    }),
  }),
})

export const { useLazyGetAccessRuleOverridesQuery } = bookingAccessOverrideListApi
