import _ from 'lodash'
import { v4 } from 'uuid'
import {
  GET_LANGUAGE_STRINGS_START,
  GET_LANGUAGE_STRINGS_SUCCESS,
  GET_LANGUAGE_STRINGS_FAIL,
  SAVE_LANGUAGE_STRINGS_START,
  SAVE_LANGUAGE_STRINGS_SUCCESS,
  SAVE_LANGUAGE_STRINGS_FAIL,
  UPDATE_LANGUAGE_STRING,
  ADD_CUSTOM_POLICY,
  GET_POLICY_STRINGS_START,
  GET_POLICY_STRINGS_SUCCESS,
  GET_POLICY_STRINGS_FAIL,
  UPDATE_POLICY_STRING,
  SAVE_POLICY_STRINGS_START,
  SAVE_POLICY_STRINGS_FAIL,
  SAVE_POLICY_STRINGS_SUCCESS,
} from 'mgr/pages/single-venue/settings/actions/ActionTypes'

const getInitialLanguageStringUpdates = () => ({ existing: {}, new: {} })

const initialState = {
  languageStringData: [],
  isLoading: false,
  languageStringUpdates: getInitialLanguageStringUpdates(),
  policies: [],
  policyUpdatesIds: new Set(),
}

const languageStringsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POLICY_STRINGS_START:
    case GET_LANGUAGE_STRINGS_START:
    case SAVE_LANGUAGE_STRINGS_START:
    case SAVE_POLICY_STRINGS_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_POLICY_STRINGS_FAIL:
    case GET_LANGUAGE_STRINGS_FAIL:
    case SAVE_LANGUAGE_STRINGS_FAIL:
    case SAVE_POLICY_STRINGS_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case GET_LANGUAGE_STRINGS_SUCCESS:
    case SAVE_LANGUAGE_STRINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        languageStringData: action.data.languageStrings,
        languageStringUpdates: getInitialLanguageStringUpdates(),
      }
    case UPDATE_LANGUAGE_STRING: {
      const { languageStringUpdates } = state
      if (action.languageStringId) {
        languageStringUpdates.existing[action.languageStringId] = action.value
      } else if (action.masterStringId) {
        languageStringUpdates.new[action.masterStringId] = action.value
      }

      return {
        ...state,
        languageStringUpdates,
      }
    }
    case ADD_CUSTOM_POLICY: {
      const { policies } = state
      const translatedLanguages = action.translatedLanguages.map(language => ({
        [language.value]: '',
      }))
      const languageStringFields = {
        name: '',
        [action.defaultLanguage]: '',
        id: v4(),
        isCustom: true,
      }

      const customPolicyStrings = [
        translatedLanguages.reduce(
          (prev, current) => ({
            ...prev,
            ...current,
          }),
          languageStringFields
        ),
      ]
      return {
        ...state,
        policies: [...policies, ...customPolicyStrings],
      }
    }
    case GET_POLICY_STRINGS_SUCCESS:
    case SAVE_POLICY_STRINGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        policies: action.data,
      }
    }
    case UPDATE_POLICY_STRING:
      const newPolicies = [...state.policies]
      const policyIndex = _.findIndex(newPolicies, policy => policy.id === action.id)
      newPolicies[policyIndex] = { ...newPolicies[policyIndex], [action.newPolicyObj.cell]: action.newPolicyObj.value }
      if (_.isEmpty(action.newPolicyObj.value)) {
        state.policyUpdatesIds.delete(action.id)
        return {
          ...state,
          policies: newPolicies,
        }
      }
      const updatedPolicies = state.policyUpdatesIds.add(action.id)
      return {
        ...state,
        policies: newPolicies,
        policyUpdatesIds: updatedPolicies,
      }
    default:
      return state
  }
}

export default languageStringsReducer
