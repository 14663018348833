import { srDelete, srGet, srPut } from '@sevenrooms/core/api'

const deleteFollower = (actualId, followerId, errHandler) => {
  const path = `/api-yoa/followers/actual/${actualId}/${followerId}`
  return srDelete(
    path,
    {
      rid: Math.floor(Math.random() * 100 + 1),
    },
    true
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }

    return (response.data && response.data.followers) || {}
  })
}
const addFollower = (actualId, followerId, errHandler) => {
  const path = `/api-yoa/followers/actual/${actualId}/${followerId}`
  return srPut(
    path,
    {
      rid: Math.floor(Math.random() * 100 + 1),
    },
    { convertToGetParams: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }

    return (response.data && response.data.followers) || {}
  })
}

export const fetchFollowers = (venueGroupId, params, errHandler) =>
  srGet(`/api-yoa/followers/venuegroup/${venueGroupId}`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  })
    .then(response => {
      if (response.error && typeof errHandler === 'function') {
        errHandler(response)
      } else if (response.status !== 200) {
        throw new Error(response)
      }
      return response
    })
    .catch(e => {
      console.error('An unexpected error occurred fetching followers', e.toString())
      throw new Error(e.toString())
    })

export default {
  deleteFollower,
  addFollower,
}
