import _ from 'lodash'
import { combineReducers } from 'redux'
import { LINK_REVIEW_SUCCESS, UNLINK_REVIEW_SUCCESS } from 'mgr/actualslideout/actions/ActionTypes'
import { CHANGE_VENUE } from 'mgr/lib/actions/GlobalActionTypes'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import filtersReducer from 'mgr/pages/single-venue/marketing/reducers/Filters'
import { CLOSE_SEARCH_MODAL, OPEN_SEARCH_MODAL } from 'mgr/pages/single-venue/search/actions/ActionTypes'
import { getBrandPNGSrcMap } from 'svr/common/BrandIcon'

// TODO meta data for actiontypes
const initialState = {
  selected: null,
  // Ostensibly this is always the same as
  // selected, but abstracting for ease of
  // ui changes.
  searchingReview: null,
  isLinking: false,
  results: [],
  stats: {},
  total: 0,
  isLoading: false,
  isLoadingExport: false,
}

const updateReview = (oldReview, newReview) => {
  if (oldReview.id === newReview.id) {
    return {
      ...oldReview,
      actual_id: newReview.actual_id,
      actual_name: newReview.actual_name,
      reply: newReview.reply,
    }
  }
  return oldReview
}

const formatReviews = (reviews, mediaURL) =>
  _.map(reviews, review => {
    const brandPNGSrcMap = getBrandPNGSrcMap(mediaURL)
    const reviewSitePNGSrc = brandPNGSrcMap[review.review_site] || null
    return {
      ...review,
      review_site_png: reviewSitePNGSrc,
    }
  })

const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_REVIEWS_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.TRIGGER_GUEST_SATISFACTION_EXPORT:
      return {
        ...state,
        isLoadingExport: true,
      }
    case ActionTypes.TRIGGER_GUEST_SATISFACTION_EXPORT_SUCCESS:
    case ActionTypes.TRIGGER_GUEST_SATISFACTION_EXPORT_FAIL:
      return {
        ...state,
        isLoadingExport: false,
      }
    case ActionTypes.LOAD_REVIEWS_SUCCESS:
      return {
        ...state,
        results: formatReviews(action.results, action.appState.mediaUrl),
        stats: action.stats,
        total: action.total,
        lastUpdated: action.lastUpdated,
        isLoading: false,
      }
    case ActionTypes.LOAD_REVIEWS_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.OPEN_REVIEW:
      const isLinking = action.selected ? state.isLinking : false
      return {
        ...state,
        selected: action.selected,
        isLinking,
      }
    case ActionTypes.SET_REVIEW_REPLY:
      return {
        ...state,
        results: state.results.map(review => updateReview(review, action.review)),
        selected: action.review,
      }
    case CHANGE_VENUE:
      return {
        ...state,
        selected: null,
        isLinking: false,
      }
    case OPEN_SEARCH_MODAL:
      return {
        ...state,
        isLinking: true,
        searchingReview: action.review,
      }
    case CLOSE_SEARCH_MODAL:
      return {
        ...state,
        isLinking: false,
        searchingReview: null,
      }
    case LINK_REVIEW_SUCCESS:
      const linkUpdate = state.results.map(review => updateReview(review, action.review))
      return {
        ...state,
        selected: null,
        searchingReview: null,
        isLinking: false,
        results: linkUpdate,
      }
    case UNLINK_REVIEW_SUCCESS:
      const unlinkUpdate = state.results.map(review => updateReview(review, action.review))
      return {
        ...state,
        results: unlinkUpdate,
      }
    default:
      return state
  }
}

// TODO nest a new UI reducer
export default combineReducers({
  data: reviewsReducer,
  filters: filtersReducer,
})
