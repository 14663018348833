/*
  Inline style utils
*/

// rounded
const StyleUtils = {
  rounded: sizes => ({
    borderRadius: sizes,
    MozBorderRadius: sizes,
    WebkitBorderRadius: sizes,
  }),
  scale: scaleSize => ({
    WebkitTransition: 'all 100ms ease-in',
    WebkitTransform: `scale(${scaleSize})`,
    msTransition: 'all 100ms ease-in',
    msTransform: `scale(${scaleSize})`,
    MozTransition: 'all 100ms ease-in',
    MozTransform: `scale(${scaleSize})`,
    transition: 'all 100ms ease-in',
    transform: `scale(${scaleSize})`,
  }),
  transition: (trnsProp = 'all', trnsDuration = '100ms', trnsFn = 'ease-in', trnsDelay = '0s') => {
    const command = `${trnsProp} ${trnsDuration} ${trnsFn} ${trnsDelay}`
    return {
      WebkitTransition: command,
      msTransition: command,
      MozTransition: command,
      transition: command,
    }
  },
  shadow: (color = 'rgba(50,50,50,0.59)', distance = '2px', blur = '9px') => ({
    WebkitBoxShadow: `0px ${distance} ${blur} 0px ${color}`,
    MozBoxShadow: `0px ${distance} ${blur} 0px ${color}`,
    boxShadow: `0px ${distance} ${blur} 0px ${color}`,
  }),
  Unselectable: {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    khtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
  ClearFix: {
    ':after': {
      content: '',
      display: 'block',
      clear: 'both',
    },
  },
}

export default StyleUtils
