import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { updateSMSCampaigns } from '@sevenrooms/core/api'
import { OneTimeSMSCampaignStatusEnum, type OngoingSMSCampaignStatusEnum } from '@sevenrooms/core/domain'
import type { SMSCampaignTableRow } from './SMSCampaignTable.types'

export interface SMSCampaignTableToastConfig {
  smsCampaignName: string
  targetStatus:
    | OneTimeSMSCampaignStatusEnum.ACTIVE
    | OneTimeSMSCampaignStatusEnum.INACTIVE
    | OngoingSMSCampaignStatusEnum.ACTIVE
    | OngoingSMSCampaignStatusEnum.INACTIVE
}
export interface SMSCampaignTableState {
  rows: SMSCampaignTableRow[]
  oldRows: SMSCampaignTableRow[]
  toastConfig: SMSCampaignTableToastConfig
  showToast: boolean
}

const initialState: SMSCampaignTableState = {
  rows: [],
  oldRows: [],
  toastConfig: { smsCampaignName: '', targetStatus: OneTimeSMSCampaignStatusEnum.ACTIVE },
  showToast: false,
}

const smsCampaignTableSlice = createSlice({
  name: 'smsCampaignTable',
  initialState,
  reducers: {
    setSMSCampaignTableRows: (state, action: PayloadAction<SMSCampaignTableRow[]>) => {
      state.rows = action.payload
    },
    setSMSCampaignTableOldRows: (state, action: PayloadAction<SMSCampaignTableRow[]>) => {
      state.oldRows = action.payload
    },
    setSMSCampaignTableToastConfig: (state, action: PayloadAction<SMSCampaignTableToastConfig>) => {
      state.toastConfig = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(updateSMSCampaigns.fulfilled, state => {
      state.oldRows = state.rows
      state.showToast = false
    })

    builder.addCase(updateSMSCampaigns.rejected, state => {
      state.rows = state.oldRows
      state.showToast = true
    })
  },
})

const { actions } = smsCampaignTableSlice
export const { setSMSCampaignTableRows, setSMSCampaignTableOldRows, setSMSCampaignTableToastConfig } = actions
export const { reducer: smsCampaignTableReducer } = smsCampaignTableSlice
