import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { defaultBrandColors } from '@sevenrooms/core/ui-kit/theme'

const initialState = {
  colorPrimary: defaultBrandColors.brandColor,
  fontsColorLinks: defaultBrandColors.brandLink,
  isDirty: false,
}

const orderingBrandSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ORDERING_BRAND_SETTINGS:
      return {
        ...state,
        ...action.payload,
        isDirty: false,
      }
    case ActionTypes.UPDATE_BRAND_SETTINGS_FIELD:
      return {
        ...state,
        [action.field]: action.value,
        isDirty: true,
      }

    case ActionTypes.UPLOAD_PHOTO_PREVIEW:
      return {
        ...state,
        [`${action.imageType}ImageUrl`]: action.preview,
      }
    case ActionTypes.UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        [`${action.imageType}ImageUrl`]: `/.h/download/${action.upload.url_key}`,
        [`${action.imageType}ImageBlobKey`]: action.upload.post_key,
        colorBackgroundOpacity: Math.min(state.colorBackgroundOpacity, 0.5),
        isDirty: true,
      }
    case ActionTypes.CROP_OPEN:
      return {
        ...state,
        isCropping: action.imageType,
      }
    case ActionTypes.CROP_PHOTO:
      return {
        ...state,
        [`${action.imageType}ImageCropData`]: action.coords,
        isDirty: true,
      }
    case ActionTypes.CROP_CLOSE:
      return {
        ...state,
        isCropping: false,
      }
    case ActionTypes.CROP_DELETE:
      return {
        ...state,
        [`${action.imageType}ImageUrl`]: null,
        [`${action.imageType}ImageBlobKey`]: null,
        [`${action.imageType}ImageCropData`]: null,
        [`${action.imageType}ImagePhotoDict`]: null,
        isDirty: true,
      }
    case ActionTypes.SAVE_ORDERING_BRAND_SETTINGS_SUCCESS:
      return {
        ...state,
        isDirty: false,
      }
    default:
      return state
  }
}

export default orderingBrandSettingsReducer
