import _ from 'lodash'
import { dateToPythonWeekday, isVenueToday, getVenueLocalTimeOrder } from '../../../common/TimeUtil'

const TOTAL_MINUTES_IN_ONE_DAY = 24 * 60

export function getSortAndIntervalOrdersForShift(date, shift, padding = 0) {
  let sortOrders = []
  let intervalOrder = 1
  if (!shift) {
    return { sortOrders, intervalOrder }
  }
  sortOrders = _.range(shift.start_time_sort_order, shift.end_time_sort_order + 1 + padding)
  intervalOrder = shift.interval_minutes / 15
  return { sortOrders, intervalOrder }
}

export function isMajorOrder(order, intervalOrder) {
  return order % 4 === 0
}

export function isOrderInShift(startSortOrder, endSortOrder, shift) {
  return (
    (startSortOrder <= shift.start_time_sort_order && shift.start_time_sort_order < endSortOrder) ||
    (startSortOrder < shift.end_time_sort_order && shift.end_time_sort_order <= endSortOrder) ||
    (shift.start_time_sort_order <= startSortOrder && endSortOrder <= shift.end_time_sort_order) ||
    shift.end_time_sort_order === startSortOrder
  )
}

export function getCapacityForOrder(shift, startOfDayHour, order) {
  const minutesSinceMidnight = (order * 15 + startOfDayHour * 60) % TOTAL_MINUTES_IN_ONE_DAY
  const customCapacity = shift.custom_pacing && shift.custom_pacing[minutesSinceMidnight]
  if (_.isNumber(customCapacity)) {
    return customCapacity
  }
  return _.isNumber(shift.covers_per_seating_interval) ? shift.covers_per_seating_interval : 30
}

/* For remembering currently selected shift */

export function pickBestShift(venue, shifts, previousShift, args = {}) {
  if (_.isEmpty(shifts)) {
    return null
  }

  // Try previous shift, or previous shift's category
  let found = null
  if (_.isObject(previousShift)) {
    found = _.find(shifts, s => s.persistent_id === previousShift.persistent_id)
    if (_.isObject(found)) {
      return found
    }
    found = _.find(shifts, s => s.category === previousShift.category)
    if (_.isObject(found)) {
      return found
    }
  }

  if (
    moment.isMoment(args.loadInServiceShiftForDate) &&
    isVenueToday(venue.timezone, venue.startOfDayHour, args.loadInServiceShiftForDate)
  ) {
    // Pick whichever shift is in service or closest shift to being live
    const liveSortOrder = getVenueLocalTimeOrder(venue.timezone, venue.startOfDayHour)
    let leastDistance = Number.MAX_SAFE_INTEGER
    let bestShift
    for (const shift of shifts) {
      const shiftStartSortOrder = shift.start_time_sort_order
      const shiftEndSortOrder = shift.end_time_sort_order
      if (liveSortOrder >= shiftStartSortOrder && liveSortOrder <= shiftEndSortOrder) {
        // Winner! Shift in time range
        return shift
      }
      const startDistance = liveSortOrder - shiftStartSortOrder
      const endDistance = liveSortOrder - shiftEndSortOrder
      const shiftDistance = Math.min(Math.abs(startDistance), Math.abs(endDistance))

      if (!bestShift || shiftDistance < leastDistance) {
        bestShift = shift
        leastDistance = shiftDistance
      }
    }
    if (_.isObject(bestShift)) {
      window.svrDebug(`Closest shift is ${leastDistance} sort orders away`)
      return bestShift
    }
  }

  if (
    moment.isMoment(args.loadNextBookableShiftForDate) &&
    isVenueToday(venue.timezone, venue.startOfDayHour, args.loadNextBookableShiftForDate)
  ) {
    // Pick first shift that has not yet passed
    const liveSortOrder = getVenueLocalTimeOrder(venue.timezone, venue.startOfDayHour)
    let leastDistance = Number.MAX_SAFE_INTEGER
    let bestShift
    for (const shift of shifts) {
      const shiftEndSortOrder = shift.end_time_sort_order
      if (liveSortOrder > shiftEndSortOrder) {
        continue // Shift passed
      }
      const shiftDistance = shiftEndSortOrder - liveSortOrder
      if (!bestShift || shiftDistance < leastDistance) {
        bestShift = shift
        leastDistance = shiftDistance
      }
    }
    if (_.isObject(bestShift)) {
      window.svrDebug(`Next bookable shift is ${leastDistance} sort orders away`)
      return bestShift
    }
  }

  // try saved shift from previous visited page
  const lastShiftPersistentId = getLastShiftPersistentId(venue)
  if (lastShiftPersistentId) {
    found = _.find(shifts, s => s.persistent_id === lastShiftPersistentId)
    if (_.isObject(found)) {
      return found
    }
    const lastShiftCategory = getCategoryFromPersistentId(lastShiftPersistentId)
    found = _.find(shifts, s => s.category === lastShiftCategory)
    if (_.isObject(found)) {
      return found
    }
  }

  // now just pick the first one
  return shifts[0]
}

export function rememberShiftPersistentId(venue, shiftPersistentId) {
  if (!('localStorage' in window) || window.localStorage === null || !shiftPersistentId) {
    return
  }
  const storageKey = getShiftPersistentIdStorageKey(venue)
  localStorage.setItem(storageKey, shiftPersistentId)
}

export function getLastShiftPersistentId(venue) {
  const storageKey = getShiftPersistentIdStorageKey(venue)
  return localStorage.getItem(storageKey)
}

export function getCategoryFromPersistentId(shiftPersistentId) {
  return shiftPersistentId ? shiftPersistentId.split('-').reverse()[1] : undefined
}

function getShiftPersistentIdStorageKey(venue) {
  return `selected_shift/manager/${venue.urlKey}` // for backwards compatibilty
}
