import { combineReducers } from 'redux'
import { CHANGE_VENUE } from 'mgr/lib/actions/GlobalActionTypes'
import * as ActionTypes from 'mgr/pages/single-venue/reporting/actions/ActionTypes'
import emailFiltersReducer from 'mgr/pages/single-venue/reporting/reducers/EmailFilters'

const initialState = {
  selected: null,
  results: [],
  total: 0,
  isLoading: false,
}

const outgoingEmailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_OUTGOING_EMAILS_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.LOAD_OUTGOING_EMAILS_SUCCESS:
      return {
        ...state,
        results: action.results,
        total: action.total,
        lastUpdated: action.lastUpdated,
        isLoading: false,
      }
    case ActionTypes.LOAD_OUTGOING_EMAILS_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.OPEN_OUTGOING_EMAIL:
      return {
        ...state,
        selected: action.selected,
      }
    case CHANGE_VENUE:
      return {
        ...state,
        selected: null,
      }
    default:
      return state
  }
}

export default combineReducers({
  data: outgoingEmailsReducer,
  filters: emailFiltersReducer,
})
