import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'

const initialState = {
  isLoading: false,
  isDeleting: false,
  orderingSites: [],
}

const orderingSitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ORDERING_SITES_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GET_ORDERING_SITES_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.GET_ORDERING_SITES_SUCCESS:
      return {
        ...state,
        orderingSites: action.data.ordering_sites,
        isLoading: false,
      }
    case ActionTypes.DELETE_ORDERING_SITE_START:
      return {
        ...state,
        isDeleting: true,
      }
    case ActionTypes.DELETE_ORDERING_SITE_SUCCESS:
    case ActionTypes.DELETE_ORDERING_SITE_FAIL:
      return {
        ...state,
        isDeleting: false,
      }
    default:
      return state
  }
}

export default orderingSitesReducer
