import moment from 'moment-timezone'
import {
  MarketingCampaignScheduleType,
  MarketingCampaignType,
  EmailCampaignStatus,
  SendTimeDelayType,
} from 'mgr/pages/shared/utils/Constants'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import { ONE_TIME_SEND_TIME_TYPE } from 'mgr/pages/single-venue/marketing/components/email-center/SendTime'
import { getTimeFormat } from '../selectors/EmailCampaigns'

const timeFormat = getTimeFormat()
const currentMoment = moment()
currentMoment.set('minute', currentMoment.minute() - (currentMoment.minute() % 15) + 30)
const timeOfDayFormatted = currentMoment.format(timeFormat)

const initialState = {
  isVisible: false,
  current: 0,
  stepValidator: () => true,
  complete: false,
  testEmails: '',
  sendingTestEmail: false,
  deactivatingCampaign: false,
  isDeactivateModalVisible: false,
  deletingCampaign: false,
  isDeleteModalVisible: false,
  saving: false,
  confirmationVisible: false,
  isOneTimeCampaign: false,
  isCopy: false,
  campaign: {
    id: null,
    status: EmailCampaignStatus.DRAFT,
    campaign_type: MarketingCampaignType.ONGOING,
    campaign_name: '',
    email_sender: '',
    email_reply_to: '',
    email_subject: '',
    email_body: '',
    is_any_client_tags: true,
    recipient_client_tags: [],
    recipient_client_tags_exclude: [],
    time_of_day: timeOfDayFormatted,
    linkedExperience: null,

    // Ongoing fields
    delay_num: 1,
    delay_type: SendTimeDelayType.DAYS,
    relative_value: 1,
    scheduled_day_of_week: 0,
    schedule_type: MarketingCampaignScheduleType.ABSOLUTE,

    // One time fields
    date: currentMoment.format('YYYY-MM-DD'),
    scheduled_time_of_day: timeOfDayFormatted,
    send_type: ONE_TIME_SEND_TIME_TYPE.IMMEDIATELY,
    oneTimeCampaignParams: null,
  },
  lastCampaign: {},
  showEmojiPicker: false,
  emailSubjectCursor: {
    start: 0,
    end: 0,
  },
}

const emailWizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_EMAIL_WIZARD_STEP:
      return { ...state, current: action.index }
    case ActionTypes.TOGGLE_EMAIL_WIZARD:
      if (!state.campaign.id && !state.isVisible) {
        window.metric.track('EmailCenter.CreateNewCampaign')
      }

      const isVisible = !state.isVisible
      return isVisible
        ? {
            ...state,
            isVisible: true,
          }
        : {
            ...state,
            ...initialState,
            lastCampaign: state.campaign,
            isOneTimeCampaign: false,
          }
    case ActionTypes.UPDATE_WIZARD_CAMPAIGN_ATTRIBUTE:
      const campaignUpdate = { ...state.campaign }
      campaignUpdate.date = campaignUpdate.date || initialState.campaign.date
      campaignUpdate.scheduled_time_of_day = campaignUpdate.scheduled_time_of_day || initialState.campaign.scheduled_time_of_day
      campaignUpdate[action.attribute] = action.value
      if (action.attribute === 'recipient_client_tags' && (action.value[0] == null || action.value[0].tagName == null)) {
        campaignUpdate[action.attribute] = []
      }
      return {
        ...state,
        campaign: campaignUpdate,
      }
    case ActionTypes.SET_EMAIL_WIZARD_VALIDATOR:
      return {
        ...state,
        stepValidator: action.validator,
      }
    case ActionTypes.SET_EMAIL_WIZARD_COMPLETE:
      return {
        ...state,
        complete: true,
      }
    case ActionTypes.SET_EMAIL_WIZARD_TEST_EMAILS:
      return {
        ...state,
        testEmails: action.value,
      }
    case ActionTypes.TOGGLE_EMAIL_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationVisible: !state.confirmationVisible,
      }
    case ActionTypes.UPDATE_EMAIL_SUBJECT_CURSOR:
      return {
        ...state,
        emailSubjectCursor: {
          start: action.start,
          end: action.end,
        },
      }
    case ActionTypes.SAVE_AND_SCHEDULE_EMAIL_CAMPAIGN_START:
      return {
        ...state,
        saving: true,
        campaign: {
          ...state.campaign,
          status: EmailCampaignStatus.ACTIVE,
        },
      }
    case ActionTypes.SAVE_AND_SCHEDULE_EMAIL_CAMPAIGN_SUCCESS:
      return {
        ...initialState,
        isVisible: false,
        lastCampaign: state.campaign,
      }
    case ActionTypes.SAVE_EMAIL_CAMPAIGN_START:
      return { ...state, saving: true }
    case ActionTypes.SAVE_EMAIL_CAMPAIGN_FAIL:
      return { ...state, saving: false }
    case ActionTypes.SAVE_EMAIL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        saving: false,
        campaign: {
          ...state.campaign,
          id: action.id,
        },
      }
    case ActionTypes.SEND_TEST_EMAIL_START:
      return {
        ...state,
        sendingTestEmail: true,
      }
    case ActionTypes.SEND_TEST_EMAIL_SUCCESS:
    case ActionTypes.SEND_TEST_EMAIL_FAIL:
      return {
        ...state,
        sendingTestEmail: false,
      }
    case ActionTypes.DEACTIVATE_CAMPAIGN_START:
      return {
        ...state,
        isDeactivateModalVisible: false,
        deactivatingCampaign: true,
        campaign: {
          ...state.campaign,
          status: EmailCampaignStatus.INACTIVE,
        },
      }
    case ActionTypes.DEACTIVATE_CAMPAIGN_FAIL:
      return {
        ...state,
        deactivatingCampaign: false,
        campaign: {
          ...state.campaign,
          status: EmailCampaignStatus.ACTIVE,
        },
      }
    case ActionTypes.DEACTIVATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        ...initialState,
        lastCampaign: state.campaign,
      }
    case ActionTypes.TOGGLE_DEACTIVATE_CAMPAIGN:
      return {
        ...state,
        isDeactivateModalVisible: !state.isDeactivateModalVisible,
      }
    case ActionTypes.DELETE_CAMPAIGN_START:
      return {
        ...state,
        isDeleteModalVisible: false,
        deletingCampaign: true,
      }
    case ActionTypes.DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        deletingCampaign: false,
      }
    case ActionTypes.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        ...initialState,
      }
    case ActionTypes.TOGGLE_DELETE_CAMPAIGN:
      return {
        ...state,
        isDeleteModalVisible: !state.isDeleteModalVisible,
      }
    case ActionTypes.LOAD_CAMPAIGN_TO_WIZARD:
      return {
        ...state,
        isVisible: true,
        current: action.data.status === EmailCampaignStatus.DRAFT ? 0 : 4,
        campaign: action.data,
        numberOfClientsFound: action.numberOfClientsFound,
      }
    case ActionTypes.SELECT_EXPERIENCE_TO_LINK:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          linkedExperience: action.experienceId,
        },
      }
    case ActionTypes.TOGGLE_EMOJI_PICKER:
      return {
        ...state,
        showEmojiPicker: !state.showEmojiPicker,
      }
    case ActionTypes.SET_ONE_TIME_CAMPAIGN_PARAMS:
      return {
        ...state,
        isVisible: true,
        numberOfClientsFound: action.numberOfClientsFound,
        campaign: {
          ...state.campaign,
          campaign_type: MarketingCampaignType.ONE_TIME,
          oneTimeCampaignParams: action.params,
        },
      }
    default:
      return state
  }
}

export default emailWizardReducer
