import _ from 'lodash'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { scroller } from 'react-scroll'
import styled from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { VmsIcons, StyledVmsIconS, StyledIcons } from 'svr/common/VmsIcons'

const OuterBody = styled.div`
  min-width: ${props => props.defaultWidth};
  width: ${props => props.defaultWidth};
  min-height: ${props => props.height}px;
  margin-right: 9px;
  ${props => props.theme.clearFix};
`

const BorderedArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-height: ${props => props.height + 2}px;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  border: 1px solid ${props => props.borderColor};
  border-radius: 4px;
  background-color: ${props => (props.disabled ? props.theme.color.white : props.theme.background)};
  box-shadow: ${props => (props.isOpen ? '0 2px 4px 0 rgba(0,0,0,0.5)' : 'none')};
  :hover {
    border-color: ${props => (props.disabled ? props.borderColor : props.hoverBorderColor)};
    transition: border-color 0.2s ease-out;
  }
  ${props => props.theme.clearFix};
`

const DropdownIconArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-height: ${props => props.height + 2}px;
  position: relative;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  ${props => props.theme.clearFix};
`

// Fix for #3 here: https://github.com/philipwalton/flexbugs
// Since BorderedArea has min-height: 44px
const BorderedAreaFlexIEFix = styled.div`
  order: 0;
  width: 0;
  height: ${props => props.height + 2}px;
`

const NameOutside = styled.div`
  color: ${props => props.theme.darkGrey};
  ${props => props.theme.fontWeight300} font-size: 11px;
  padding: 0 0 4px 10px;
`

const NameInside = styled.div`
  position: absolute;
  top: 3px;
  left: 10px;
  right: 3px;
  color: ${props => props.theme.darkGrey};
  font-weight: 500;
  font-size: ${props => (props.isLightTheme ? '11px' : '12px')};
`

const DownCaret = styled(StyledVmsIconS)`
  order: 3;
  transform: rotate(90deg);
  font-weight: 100;
  font-size: 17px;
  pointer-events: none;
  margin: 0 0 0 -5px;
  text-align: center;
  width: 30px;
  color: ${props => props.theme.navigationDark};
`

const DropdownIcon = styled(StyledVmsIconS)`
  order: 1;
  font-size: 24px;
  pointer-events: none;
  margin: 0;
  text-align: center;
  width: 30px;
  color: ${props => props.theme.navigationDark};
`

const RightCaret = styled(StyledVmsIconS)`
  font-weight: 100;
  font-size: 17px;
  pointer-events: none;
  text-align: right;
  flex-grow: 55;
  width: 30px;
  color: ${props => props.theme.navigationDark};
  box-sizing: border-box;
  margin: -10px -5px 0 0;
  padding: 10px 0 10px 5px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const Icon = styled(StyledVmsIconS)`
  order: 2;
  font-size: 17px;
  width: 17px;
  height: 17px;
  pointer-events: none;
  margin: 0 10px 0 5px;
`

const DropdownRelative = styled.div`
  position: relative;
`

const DropdownAbsolute = styled.div`
  position: absolute;
  min-width: 100%;
`

const DropdownLeftOrRight = styled.div`
  position: absolute;
  ${props => (props.isRightAlignedMenu ? 'right' : 'left')}: ${props => props.dropdownIndent};
  ${props => (props.isFullSizeMenu ? 'right:0; left:0;' : '')};
`

const OptionGroupTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #848d94;
  padding: 5px 10px;
  border-top: 1px solid #848d94;
  text-transform: uppercase;
  ${props => props.theme.ellipsis};
`

const OnlyButton = styled.div`
  display: none;
  color: ${props => props.theme.darkGrey};
  box-sizing: border-box;
  margin: -10px 0; // Full height of cell
  padding: 10px 0 10px 5px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const OptionBase = styled.div`
  height: 37px;
  overflow: hidden;
  font-size: 15px;
  padding: 10px;
  box-sizing: border-box !important;
  display: flex;
  :hover ${OnlyButton} {
    display: block;
  }
`

const OptionLightTheme = styled(OptionBase)`
  color: ${props => props.theme.navigationDark};
  background-color: ${props => (props.isSelectedChoice ? props.theme.primaryRgba20 : props.theme.background)};
  :hover {
    background-color: ${props => props.theme.primaryRgba10};
  }
`

const OptionDarkTheme = styled(OptionBase)`
  color: ${props => (props.isSelectedChoice ? props.theme.white : props.theme.navigationDark)};
  background-color: ${props => (props.isSelectedChoice ? '#031927' : props.theme.background)};
  border-bottom: ${props => (props.isLast ? '0px' : '1px')} solid ${props => props.theme.navigationDark};
  :hover {
    color: #031927;
    background-color: ${props => props.theme.lightGrey};
  }
`

const OptionText = styled.span`
  flex-shrink: 50;
  ${props => props.theme.ellipsis};
`

const SelectedItemDisplay = styled.div`
  order: 1;
  flex-grow: 1;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin-left: 10px;
  margin-top: ${props => (props.useOutsideLabel ? '0' : '15px')};
  color: ${props => (props.hasSelectedValue && !props.disabled ? props.theme.navigationDark : props.theme.darkGrey)};
  ${props => props.theme.ellipsis};
`

const OptionsContainer = styled.div`
  position: relative;
  border: 1px solid ${props => props.borderColor};
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  top: -2px;
  width: 100%;
  min-width: 180px;
  max-height: 332px;
  overflow-y: auto;
  box-sizing: border-box;
  cursor: default;
  user-select: none;
  z-index: 501;
  border-radius: 0 0 4px 4px;
`

const Navigation = styled.div`
  order: 3;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${props => (props.disabled ? props.theme.darkGrey : props.theme.navigationDark)};
  border-radius: 0 4px 4px 0;
`

const NavigationCell = styled(StyledVmsIconS)`
  width: 40px;
  text-align: center;
  line-height: 44px;
  font-weight: 100;
  font-size: 20px;
  :hover {
    background-color: ${props => (props.disabled ? 'transparent' : props.theme.lightGrey)};
  }
`

export const FlexBoxContainer = styled.div`
  display: flex;
  list-style: none;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  flex-grow: 1;
  flex-basis: auto;
  height: auto;
`

export const FlexRowContainer = styled(FlexBoxContainer)`
  flex-direction: row;
  align-items: left;
  justify-content: ${props => props.justifyContent || 'flex-start'};
`

const SearchInputSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 12px;
`

const SearchIcon = styled(StyledIcons.S)`
  color: #888c92;
  padding: 0 ${props => props.theme.gutter.standard} 0 0;
`

const SearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  color: ${props => props.theme.color.secondary};
  font-size: 15px;
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.double} ${props => props.theme.gutter.standard} 0px;
`

export class DropdownMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      searchValue: '',
    }
    this.isAllSelected = false
    this.didScrollOnOpen = false
    this.handleItemClick = this.handleItemClick.bind(this)
    this.handleItemOnlyClick = this.handleItemOnlyClick.bind(this)
    this.handleToggleDropdownClick = this.handleToggleDropdownClick.bind(this)
    this.handleNested = this.handleNested.bind(this)
    this.firstSelectedElements = []
  }

  componentWillUpdate() {
    this.firstSelectedElements = []
  }

  componentDidUpdate() {
    const { isMultiSelect } = this.props
    const { isOpen } = this.state

    if (!isMultiSelect && isOpen && !this.didScrollOnOpen) {
      const animationDuration = 200
      let delay = 0
      for (const [containerId, elemId] of this.firstSelectedElements) {
        setTimeout(
          () =>
            scroller.scrollTo(elemId, {
              duration: animationDuration,
              smooth: 'easeOutCubic',
              containerId,
              ignoreCancelEvents: true,
            }),
          delay
        )
        delay += animationDuration
      }
    }
    this.didScrollOnOpen = isOpen
  }

  closeDropdown() {
    const { onCloseHandler } = this.props
    const { isOpen } = this.state
    if (isOpen) {
      onCloseHandler()
      this.setState({
        isOpen: false,
        searchValue: '',
      })
    }
  }

  handleClickOutside(e) {
    this.closeDropdown()
  }

  handleToggleDropdownClick() {
    const { disabled } = this.props
    const { isOpen } = this.state
    if (disabled) {
      return
    }
    if (isOpen) {
      this.closeDropdown()
    } else {
      this.setState({ isOpen: true })
    }
  }

  handleItemClick(e, choice, isMulti, currLevelNames) {
    const { onChangeHandler, disabled, selectedValues, onNestedChangeHandler } = this.props
    e.stopPropagation()
    if (disabled) {
      return
    }
    onNestedChangeHandler(currLevelNames)
    if (isMulti) {
      const extractedValues = selectedValues.map(chc => chc.value)

      if (_.includes(extractedValues, choice.value)) {
        onChangeHandler(_.differenceBy(selectedValues, [choice], 'value'), 'removed', choice)
      } else {
        onChangeHandler(_.unionBy(selectedValues, [choice], 'value'), 'added', choice)
      }
      return
    }
    onChangeHandler([choice])
    this.closeDropdown()
  }

  handleItemOnlyClick(e, choice, isMulti) {
    const { onChangeHandler, disabled } = this.props
    e.stopPropagation()
    if (disabled || !isMulti) {
      return
    }
    onChangeHandler([choice], 'added', choice)
  }

  handleNested(e, choice) {
    const { openPath, onChangeHandler, onNestedChangeHandler } = this.props
    e.stopPropagation()
    const newOpenPath = [...openPath, choice.value]
    onNestedChangeHandler(newOpenPath)
    onChangeHandler([])
  }

  changeSearchInputHandler(e) {
    this.setState({ searchValue: e.target.value })
  }

  renderBaseBox(useOutsideLabel) {
    if (!this.props.searchingEnabled) {
      const { dontShowSelectedItem, placeholder, disabled } = this.props
      const hasSelectedValue = !_.isEmpty(this.selectedItemNames)
      const selectedItemName = this.selectedItemNames.join(', ')
      return (
        !dontShowSelectedItem && (
          <SelectedItemDisplay
            {...{
              useOutsideLabel,
              hasSelectedValue,
              disabled,
            }}
          >
            {selectedItemName || placeholder}
          </SelectedItemDisplay>
        )
      )
    }
    let selectedValue = ''
    if (this.props.selectedValues) {
      selectedValue = _.map(this.props.selectedValues, 'name').join(', ')
    }
    return (
      <SearchInputSection>
        {this.state.isOpen && <SearchIcon>{VmsIcons.Search}</SearchIcon>}
        <SearchInput
          id={`search-ddm-filters-${this.props.id}`}
          className="selected-icon-tags-search-filter"
          value={this.state.searchValue}
          placeholder={selectedValue || this.props.placeholder}
          onChange={e => this.changeSearchInputHandler(e)}
          isVisible
        />
      </SearchInputSection>
    )
  }

  renderSingleOption(choice, isLast, isSelectedChoice, isFirstSelectedElement, isGroupMultiSelect, onClick, isNested, containerId) {
    const { isLightTheme } = this.props
    const OptionTheme = isLightTheme ? OptionLightTheme : OptionDarkTheme
    const icon = choice.icon && <StyledVmsIconS style={choice.iconStyle}>{choice.icon}</StyledVmsIconS>
    const checkbox = isGroupMultiSelect && (
      <div>
        <input type="checkbox" checked={isSelectedChoice} readOnly />
        &nbsp;&nbsp;
      </div>
    )
    const onlyButton = isGroupMultiSelect && choice.value !== 'ALL' && (
      <OnlyButton onClick={e => this.handleItemOnlyClick(e, choice, isGroupMultiSelect)}>only</OnlyButton>
    )
    const optionId = _.uniqueId('DropdownMenuOption-')
    if (isFirstSelectedElement) {
      this.firstSelectedElements.push([containerId, optionId])
    }
    return (
      <OptionTheme style={choice.style} key={choice.value} id={optionId} {...{ isLast, isSelectedChoice }} onClick={onClick}>
        {icon}
        {checkbox}
        <OptionText data-test="sr-dropdown-menu-item">{choice.name}</OptionText>
        {onlyButton}
        {isNested && <RightCaret>{VmsIcons.Chevron}</RightCaret>}
      </OptionTheme>
    )
  }

  renderCompleteItem(choice, isGroupMultiSelect, itemOpenPath, isLast, isNested, canBeSelected, containerId) {
    const { selectedValues, openPath } = this.props
    const regularItemHandler = e => this.handleItemClick(e, choice, isGroupMultiSelect, itemOpenPath)
    let isFirstSelectedElement = false

    const isItemValueMatch = canBeSelected && !!_.find(selectedValues, { value: choice.value })
    const isSelectedChoice = isItemValueMatch || (isNested && openPath.indexOf(choice.value) > -1)
    if (isSelectedChoice && _.isEmpty(this.selectedItemNames)) {
      isFirstSelectedElement = true
    }
    if (isItemValueMatch) {
      this.selectedItemNames.push(choice.name)
    }
    const itemHandler = isNested ? e => this.handleNested(e, choice) : regularItemHandler
    return this.renderSingleOption(
      choice,
      isLast,
      isSelectedChoice,
      isFirstSelectedElement,
      isGroupMultiSelect,
      itemHandler,
      isNested,
      containerId
    )
  }

  renderOptionAndGetNested(currChoiceSet, isGroupMultiSelect, itemOpenPath, canLevelHaveSelectedItems, containerId) {
    const nestedChoices = []
    const renderedChoices = []
    for (let i = 0; i < currChoiceSet.length; i++) {
      const choice = currChoiceSet[i]
      const isNested = choice.hasOwnProperty('nested')
      if (isNested) {
        nestedChoices.push({
          levelName: choice.value,
          levelItems: choice.nested,
          isMulti: choice.hasOwnProperty('isMulti'),
        })
      }
      const isLast = i === currChoiceSet.length - 1
      renderedChoices.push(
        this.renderCompleteItem(choice, isGroupMultiSelect, itemOpenPath, isLast, isNested, canLevelHaveSelectedItems, containerId)
      )
    }

    return { rendered: renderedChoices, nestedChoices }
  }

  onClickSelectedAll = () => {
    const { onChangeHandler } = this.props
    this.isAllSelected = !this.isAllSelected
    const selectedValues = []
    if (this.isAllSelected) {
      _.map(this.props.choices, item => {
        selectedValues.push(item)
      })
    }
    onChangeHandler(selectedValues)
  }

  renderSelectAllOptions() {
    const { isLightTheme } = this.props
    const OptionTheme = isLightTheme ? OptionLightTheme : OptionDarkTheme
    if (this.props.isSelectAllEnabled) {
      return (
        <OptionTheme key="all" id="all" onClick={this.onClickSelectedAll}>
          <div>
            <input type="checkbox" checked={this.isAllSelected} required />
            &nbsp;&nbsp;
          </div>
          <OptionText>Select all</OptionText>
        </OptionTheme>
      )
    }
  }

  renderNestedOptions(choiceRoot, borderColor) {
    const { isMultiSelect, openPath, optionsContainerStyle, isFullSizeMenu } = this.props
    const nestedMenues = []
    const choicesInProgress = [
      [
        {
          levelName: 'init',
          levelItems: choiceRoot,
          isMulti: isMultiSelect,
        },
      ],
    ]
    const currLevelNames = []
    while (choicesInProgress.length > 0) {
      const currChoiceLevelSet = choicesInProgress.shift()
      const currRenderedLevelSet = []
      const nextLevelSet = []

      for (const levelVals of currChoiceLevelSet) {
        const { levelName, levelItems, isMulti } = levelVals
        const currChoiceSet = levelItems
        const renderedChoices = []
        currLevelNames.push(levelName)
        const levelClone = _.clone(currLevelNames)
        const isLevelVisible = openPath.indexOf(levelName) > -1
        const canLevelHaveSelectedItems = _.last(openPath) === levelName
        const containerId = _.uniqueId('DropdownMenuOptionsContainer-')
        for (let i = 0; i < currChoiceSet.length; i++) {
          const choice = currChoiceSet[i]
          const isLast = i === currChoiceSet.length - 1

          const isNested = choice.hasOwnProperty('nested')
          if (isNested) {
            nextLevelSet.push({
              levelName: choice.value,
              levelItems: choice.nested,
              isMulti: choice.hasOwnProperty('isMulti'),
            })
          }
          if (choice.hasOwnProperty('choices')) {
            const { rendered, nestedChoices } = this.renderOptionAndGetNested(
              choice.choices,
              isMulti,
              levelClone,
              canLevelHaveSelectedItems,
              containerId
            )
            nextLevelSet.push.apply(nextLevelSet, nestedChoices)
            renderedChoices.push(
              <div key={choice.name}>
                <OptionGroupTitle>{choice.name}</OptionGroupTitle>
                {rendered}
              </div>
            )
          } else {
            if (this.props.searchingEnabled) {
              if (
                this.state.searchValue &&
                this.state.searchValue.length &&
                !choice.name.toLowerCase().includes(this.state.searchValue.toLowerCase())
              ) {
                continue
              }
            }
            renderedChoices.push(
              this.renderCompleteItem(choice, isMulti, levelClone, isLast, isNested, canLevelHaveSelectedItems, containerId)
            )
          }
        }

        currRenderedLevelSet.push(
          isLevelVisible ? (
            <OptionsContainer
              data-test="sr-dropdown-options-container"
              id={containerId}
              key={`optionsContainer${levelName}`}
              style={optionsContainerStyle}
              {...{ borderColor }}
            >
              {this.renderSelectAllOptions()}
              {renderedChoices}
            </OptionsContainer>
          ) : null
        )
        choicesInProgress.push(nextLevelSet)
      }
      if (currRenderedLevelSet.length) {
        let style = {}
        if (isFullSizeMenu) {
          style = { flexGrow: 1, width: '100%' }
        }
        nestedMenues.push(
          <div key={nestedMenues.length} style={style}>
            {currRenderedLevelSet}
          </div>
        )
      }
    }

    return <FlexRowContainer>{nestedMenues}</FlexRowContainer>
  }

  render() {
    const {
      showCaret,
      testId,
      name,
      isLightTheme,
      isMultiSelect,
      choices,
      icon,
      showNavigation,
      style,
      borderAreaStyle,
      disabled,
      height,
      isRightAlignedMenu,
      dropdownIndent,
      iconDropdown,
      isFullSizeMenu,
    } = this.props
    const { isOpen } = this.state
    const hoverBorderColor = isLightTheme ? Theme.darkGrey : Theme.black
    const borderColor = isOpen ? hoverBorderColor : isLightTheme ? Theme.lightGrey : '#031927'
    const defaultWidth = _.isNil(iconDropdown) ? '154px' : '62px'
    const useOutsideLabel = !_.isNil(this.props.useOutsideLabel) ? this.props.useOutsideLabel : isLightTheme

    this.isAllSelected = this.props.selectedValues.length === this.props.choices.length

    this.selectedItemNames = []

    const renderedOptions = this.renderNestedOptions(choices, borderColor)

    const navigation = <Navigation {...{ disabled }} />

    return (
      <OuterBody data-test={testId} {...{ defaultWidth, height, style }}>
        <DropdownRelative>
          {useOutsideLabel && <NameOutside>{name}</NameOutside>}
          {_.isNil(iconDropdown) ? (
            <BorderedArea
              {...{
                height,
                borderColor,
                hoverBorderColor,
                useOutsideLabel,
                isOpen,
                disabled,
              }}
              style={borderAreaStyle}
              onClick={this.handleToggleDropdownClick}
            >
              <BorderedAreaFlexIEFix {...{ height }} />
              {!useOutsideLabel && <NameInside {...{ isLightTheme }}>{name}</NameInside>}
              {this.renderBaseBox(useOutsideLabel)}
              {showNavigation && !isMultiSelect ? navigation : showCaret && !disabled && <DownCaret>{VmsIcons.Chevron}</DownCaret>}
              {icon && <Icon>{icon}</Icon>}
            </BorderedArea>
          ) : (
            <DropdownIconArea onClick={this.handleToggleDropdownClick}>
              <BorderedAreaFlexIEFix {...{ height }} />
              <DropdownIcon>{iconDropdown}</DropdownIcon>
              {showCaret && !disabled && <DownCaret>{VmsIcons.Chevron}</DownCaret>}
            </DropdownIconArea>
          )}
          <DropdownAbsolute>
            <DropdownLeftOrRight {...{ isRightAlignedMenu, dropdownIndent, isFullSizeMenu }}>
              {isOpen && renderedOptions}
            </DropdownLeftOrRight>
          </DropdownAbsolute>
        </DropdownRelative>
      </OuterBody>
    )
  }
}

DropdownMenu.defaultProps = {
  choices: [],
  openPath: ['init'],
  name: '',
  useOutsideLabel: null,
  placeholder: '',
  displayOverride: null,
  style: {},
  height: 42,
  optionsContainerStyle: {},
  borderAreaStyle: {},
  showNavigation: false,
  showCaret: true,
  icon: null,
  dropDown: null,
  isLightTheme: false,
  isMultiSelect: false,
  isRightAlignedMenu: false,
  isFullSizeMenu: false,
  dropdownIndent: '0',
  dontShowSelectedItem: false,
  disabled: false,
  selectedValues: [],
  searchingEnabled: false,
  onNestedChangeHandler: () => 0,
  onCloseHandler: () => 0,
}

DropdownMenu.propTypes = {
  testId: React.PropTypes.string,
  name: React.PropTypes.string.isRequired,
  useOutsideLabel: React.PropTypes.bool,
  iconDropdown: React.PropTypes.string,
  selectedValues: React.PropTypes.array, // Keys are selected values, values are downstream selected. Multi, Single select are according to the values
  placeholder: React.PropTypes.string.isRequired,
  displayOverride: React.PropTypes.string,
  openPath: React.PropTypes.array,
  choices: React.PropTypes.array.isRequired,
  style: React.PropTypes.object.isRequired,
  optionsContainerStyle: React.PropTypes.object.isRequired,
  borderAreaStyle: React.PropTypes.object.isRequired,
  showNavigation: React.PropTypes.bool.isRequired, // not applicable for multi-select
  icon: React.PropTypes.string,
  dropDown: React.PropTypes.object,
  dontShowSelectedItem: React.PropTypes.bool,
  isLightTheme: React.PropTypes.bool.isRequired,
  isRightAlignedMenu: React.PropTypes.bool.isRequired,
  isFullSizeMenu: React.PropTypes.bool.isRequired,
  dropdownIndent: React.PropTypes.string.isRequired,
  showCaret: React.PropTypes.bool,
  disabled: React.PropTypes.bool,
  searchingEnabled: React.PropTypes.bool,
  onChangeHandler: React.PropTypes.func.isRequired,
  onNestedChangeHandler: React.PropTypes.func,
  onCloseHandler: React.PropTypes.func,
  isSelectAllEnabled: React.PropTypes.bool,
}

const _DropdownMenu = onClickOutside(DropdownMenu)
export default _DropdownMenu
