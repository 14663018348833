import { srDelete, srGet, srPost, srPut } from '@sevenrooms/core/api'

export const getVenueLanguages = venueId =>
  srGet(`/api-yoa/languages/${venueId}`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true }).catch(e => {
    console.error('An unexpected error occurred fetching the languages list', e)

    return e
  })

export const getLanguageStrings = (venueId, interfaceName, defaultLanguage, selectedLanguage) =>
  srGet(
    `/api-yoa/languagestrings/${venueId}/${interfaceName}`,
    {
      languageCodes: [defaultLanguage.value, selectedLanguage.value],
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).catch(e => {
    console.error('An unexpected error occurred fetching the language strings list', e)

    return e
  })

export const saveVenueLanguageSettings = (venueId, languageSettings) =>
  srPut(`/api-yoa/languages/${venueId}`, languageSettings, {
    json: true,
  })

export const saveVenueLanguageStrings = (venueId, interfaceName, languageStringUpdateData) =>
  srPut(`/api-yoa/languagestrings/${venueId}/${interfaceName}`, languageStringUpdateData, {
    json: true,
  }).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }

    return response || {}
  })

export const getPolicyStrings = (venueId, policyCategory) =>
  srGet(
    `/api-yoa/policies/${venueId}`,
    {
      policy_categories: policyCategory,
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).catch(e => {
    console.error('An unexpected error occurred fetching the language strings list', e)

    return e
  })

export const savePolicyStrings = (venueId, interfaceName, policyStringUpdateData) =>
  srPost(
    `/api-yoa/policies/${venueId}/bulk_save`,
    undefined,
    {
      json: true,
    },
    { 'Content-Type': 'application/json' },
    { policy_updates: policyStringUpdateData, policy_category: interfaceName }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }

    return response || {}
  })
