import React from 'react'
import { connect } from 'react-redux'
import * as ViewActualActions from 'mgr/actualslideout/actions/ViewActualActions'
import Tabs from 'mgr/actualslideout/components/view/Tabs'

const mapStateToProps = state => ({
  tabs: state.viewResState.tabs,
  activeTab: state.viewResState.selectedTabAction,
})

const mapDispatchToProps = {
  onTabChange: ViewActualActions.selectTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tabs)
