import _ from 'lodash'
import BookedByServices from 'mgr/lib/services/BookedByServices'
import GenericTagsServices from 'mgr/lib/services/GenericTagsServices'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
import * as SlideoutActions from './SlideoutActions'

export const changePerksField = value => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_PERKS_FIELD,
  value,
})
export const changeReservationNotes = value => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_RESERVATION_NOTES,
  value,
})
export const changeReservationTags = value => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_RESERVATION_TAGS,
  value,
})
export const changeSelectedTables = value => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_SELECTED_TABLES,
  value,
})
export const changeIsCustomAssign = isCustomAssign => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_IS_CUSTOM_ASSIGN,
  isCustomAssign,
})
export const changeSelectedBookedBy = value => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_SELECTED_BOOKED_BY,
  value,
})
export const changeMessagingField = (field, value) => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_MESSAGING_FIELD,
  field,
  value,
})
export const changeSelectedCostOption = selectedCostOption => ({
  type: ActionTypes.BOOK_DETAILS_SET_SELECTED_COST_OPTION,
  selectedCostOption,
})
export const changeCostOptionAmount = costOptionAmount => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_SELECTED_COST_AMOUNT,
  costOptionAmount,
})
export const changeCustomIndexedField = (field, value) => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_CUSTOM_INDEXED_FIELD,
  field,
  value,
})
export const changeCustomUnindexedField = (system_name, value) => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_CUSTOM_UNINDEXED_FIELD,
  system_name,
  value,
})

export const onChangeSelectedVenue = (dispatch, getState, updatedVenueId) => {
  const store = getState()
  const venueId = updatedVenueId || store.bookState.selectedVenueId
  if (_.isEmpty(store.bookDetailsState.bookedByNamesByVenue[venueId])) {
    dispatch(tryGetBookedByNamesAction(venueId))
  }
  if (_.isEmpty(store.bookDetailsState.reservationTagGroupsByVenue[venueId])) {
    dispatch(tryGetReservationTagGroupsAction(venueId))
  }
}

/* BOOKED BY NAMES */

const getBookedByNamesBegin = () => ({
  type: ActionTypes.BOOK_DETAILS_GET_BOOKED_BY_NAMES_START,
})
const getBookedByNamesSuccess = (venueId, bookedByNames) => ({
  type: ActionTypes.BOOK_DETAILS_GET_BOOKED_BY_NAMES_SUCCESS,
  venueId,
  bookedByNames,
})
const getBookedByNamesFail = () => ({
  type: ActionTypes.BOOK_DETAILS_GET_BOOKED_BY_NAMES_FAIL,
})

const tryGetBookedByNamesAction = venueId => dispatch => {
  dispatch(getBookedByNamesBegin())
  return BookedByServices.fetchVenueBookedByNames(venueId).then(
    ({ venueId, bookedByNames }) => dispatch(getBookedByNamesSuccess(venueId, bookedByNames)),
    error =>
      dispatch(batchActions([getBookedByNamesFail(), SlideoutActions.showNotificationError(`Error getting booked by names: ${error}`)]))
  )
}

/* RESERVATION TAGS */

const getReservationTagGroupsBegin = () => ({
  type: ActionTypes.BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_START,
})
const getReservationTagGroupsSuccess = (venueId, tagGroups) => ({
  type: ActionTypes.BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_SUCCESS,
  venueId,
  tagGroups,
})
const getReservationTagGroupsFail = () => ({
  type: ActionTypes.BOOK_DETAILS_GET_RESERVATION_TAG_GROUPS_FAIL,
})

const tryGetReservationTagGroupsAction = venueId => dispatch => {
  dispatch(getReservationTagGroupsBegin())
  return GenericTagsServices.fetchVenueReservationTagGroups(venueId).then(
    ({ venueId, tagGroups }) => dispatch(getReservationTagGroupsSuccess(venueId, tagGroups)),
    error =>
      dispatch(
        batchActions([getReservationTagGroupsFail(), SlideoutActions.showNotificationError(`Error getting reservation tags: ${error}`)])
      )
  )
}

export const addAdditionalBookedBySlot = () => ({
  type: ActionTypes.BOOK_DETAILS_ADD_ADDITIONAL_BOOKED_BY_SLOT,
})
export const changeAdditionalBookedBySlot = (chosenBookedByName, idx) => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_ADDITIONAL_BOOKED_BY,
  chosenBookedByName,
  idx,
})
export const removeAdditionalBookedBySlot = () => ({
  type: ActionTypes.BOOK_DETAILS_REMOVE_UNFILLED_ADDITIONAL_BOOKED_BY,
})
export const removeAdditionalBookedBy = chosenBookedByNameId => ({
  type: ActionTypes.BOOK_DETAILS_REMOVE_ADDITIONAL_BOOKED_BY,
  chosenBookedByNameId,
})
export const setBookedByFirstTime = chosenBookedByName => ({
  type: ActionTypes.BOOK_DETAILS_ADD_ADDITIONAL_BOOKED_BY,
  chosenBookedByName,
})
export const enableCustomBookedBy = () => ({
  type: ActionTypes.BOOK_DETAILS_ENABLE_CUSTOM_BOOKED_BY_NAME,
})
export const changeCustomBookedBy = customBookedByName => ({
  type: ActionTypes.BOOK_DETAILS_CHANGE_CUSTOM_BOOKED_BY_NAME,
  customBookedByName,
})
