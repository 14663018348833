import _ from 'lodash'
import moment from 'moment-timezone'
import * as AttachmentActions from 'mgr/actualslideout/actions/AttachmentActions'
import ActivityLogServices from 'mgr/lib/services/ActivityLogServices'
import MessagingServices from 'mgr/lib/services/MessagingServices'
import { NotificationLevel } from 'mgr/lib/utils/Constants'
import { batchActions } from 'svr/common/ReduxUtils'
import { isPastTime, getVenueToday } from 'svr/common/TimeUtil'
import * as ActionTypes from './ActionTypes'
import * as SlideoutActions from './SlideoutActions'

/**
 * Activity log retrieval
 */
const getActivityLogBegin = () => ({
  type: ActionTypes.LOAD_ACTIVITY_LOG_START,
})
const getActivityLogSuccess = activityLog => ({
  type: ActionTypes.LOAD_ACTIVITY_LOG_SUCCESS,
  activityLog,
})
const getActivityLogFail = () => ({ type: ActionTypes.LOAD_ACTIVITY_LOG_FAIL })
const tryGetActivityLog = () => (dispatch, getState) => {
  dispatch(getActivityLogBegin())
  const state = getState()
  const { actual } = state.viewResState
  const venueId = actual.venue_id
  const conversationId = actual && actual.conversation
  const errHandler = error =>
    dispatch(batchActions([getActivityLogFail(), SlideoutActions.showNotificationError(`Error getting activity log: ${error}`)]))
  return ActivityLogServices.fetchActivityLog(venueId, conversationId)
    .catch(errHandler)
    .then(activityLog => {
      const cbState = getState()
      const cbActual = cbState.viewResState.actual
      if (cbActual && actual.id === cbActual.id) {
        return dispatch(getActivityLogSuccess(activityLog))
      }
      svrDebug('Ignoring slow response for fetchActivityLog')
    })
}

/**
 * External messages retrieval
 */
const getMessagesBegin = () => ({ type: ActionTypes.LOAD_MESSAGES_START })
const getMessagesSuccess = messages => ({
  type: ActionTypes.LOAD_MESSAGES_SUCCESS,
  messages,
})
const getMessagesFail = () => ({ type: ActionTypes.LOAD_MESSAGES_FAIL })
const tryGetMessages = () => (dispatch, getState) => {
  dispatch(getMessagesBegin())

  const state = getState()
  const venueId = state.viewResState.actual.venue_id
  const conversationId = state.viewResState.actual && state.viewResState.actual.conversation
  const errHandler = error =>
    dispatch(batchActions([getMessagesFail(), SlideoutActions.showNotificationError(`Error getting conversation history: ${error}`)]))
  return MessagingServices.fetchMessages(venueId, conversationId, errHandler).then(messages => dispatch(getMessagesSuccess(messages)))
}

/**
 * Sending external message and comments action template
 */
const getMessagePutBegin = () => ({ type: ActionTypes.SEND_MESSAGE_START })
const getMessagePutFail = () => ({ type: ActionTypes.SEND_MESSAGE_FAIL })
const getMessagePutSuccess = message => ({
  type: ActionTypes.SEND_MESSAGE_SUCCESS,
  message,
})
const tryPutMessage =
  (message, systemMessage, category, status, attachments, visibility, transactionType, date) => (dispatch, getState) => {
    const state = getState()
    const { actual } = state.viewResState
    const venueId = actual.venue_id
    const entityId = actual.id
    const conversationId = actual && actual.conversation

    const errHandler = error =>
      dispatch(batchActions([getMessagePutFail(), SlideoutActions.showNotificationError(`Error sending message: ${error}`)]))

    if (_.isEmpty(attachments)) {
      dispatch(getMessagePutBegin())
      return MessagingServices.createMessage(
        venueId,
        conversationId,
        message,
        systemMessage,
        category,
        status,
        attachments,
        visibility,
        transactionType,
        date,
        entityId,
        errHandler
      ).then(message => {
        const cbState = getState()
        const cbActual = cbState.viewResState.actual
        if (cbActual && actual.id === cbActual.id) {
          return dispatch(getMessagePutSuccess(message))
        }
        svrDebug('Ignoring slow response for createMessage')
      })
    }

    const isInternal = visibility === 'i'

    // FIXME: Is this accurate and correct?

    const attachmentPromise = dispatch(AttachmentActions.tryRegisteringAttachments(attachments, isInternal))

    return attachmentPromise.then(([didSucceed, attachmentData]) => {
      if (!didSucceed) {
        return dispatch(getMessagePutFail())
      }
      return dispatch(
        tryPutFileAttachmentActivityLogEntry(attachmentData, {
          message,
          visibility,
          transactionType,
          date,
        })
      )
    })
  }

const tryPutFileAttachmentActivityLogEntry =
  (attachmentData, args = {}) =>
  (dispatch, getState) => {
    const { message, visibility, transactionType, date } = args
    const state = getState()
    const { actual } = state.viewResState
    const venueId = actual.venue_id
    const entityId = actual.id
    const conversationId = actual && actual.conversation

    const errHandler = error =>
      dispatch(
        batchActions([getMessagePutFail(), SlideoutActions.showNotificationError(`Error setting attachment activity log entry: ${error}`)])
      )

    const fileNamesArr = attachmentData.map(file => file.filename)
    const fileNamesCombined = fileNamesArr.join(', ')
    const attachmentSystemMessage = `added attachment${fileNamesArr.length > 1 ? 's' : ''} ${fileNamesCombined}`

    return MessagingServices.createMessage(
      venueId,
      conversationId,
      message,
      attachmentSystemMessage,
      'ACTIVITY',
      'ATTACHMENT',
      attachmentData,
      visibility,
      transactionType,
      date,
      entityId,
      errHandler
    ).then(message => {
      const cbState = getState()
      const cbActual = cbState.viewResState.actual
      if (cbActual && actual.id === cbActual.id) {
        return dispatch(getMessagePutSuccess(message))
      }
      svrDebug('Ignoring slow response for createMessage')
    })
  }
const tryPutFileDeletedActivityLogEntry = fileName => (dispatch, getState) => {
  const state = getState()
  const { actual } = state.viewResState
  const venueId = actual.venue_id
  const entityId = actual.id
  const conversationId = actual && actual.conversation

  const errHandler = error =>
    dispatch(
      batchActions([getMessagePutFail(), SlideoutActions.showNotificationError(`Error setting attachment activity log entry: ${error}`)])
    )

  const attachmentSystemMessage = `removed attachment ${fileName}`

  // Deletion looks to be hardcoded to internal visibility in old slideout
  return MessagingServices.createMessage(
    venueId,
    conversationId,
    undefined,
    attachmentSystemMessage,
    'ACTIVITY',
    'ATTACHMENT',
    undefined,
    undefined,
    undefined,
    undefined,
    entityId,
    errHandler
  ).then(message => {
    const cbState = getState()
    const cbActual = cbState.viewResState.actual
    if (cbActual && actual.id === cbActual.id) {
    } else {
      svrDebug('Ignoring slow response for createMessage')
    }
  })
}

/**
 * Sending external messages
 * @param message
 */
const tryPutExternalMessage = message => (dispatch, getState) => {
  const { attachmentsForMessagesBuffer } = getState().viewResState
  dispatch(tryPutMessage(message, undefined, undefined, undefined, attachmentsForMessagesBuffer, 'a', 'Reservation', moment())).then(() =>
    dispatch({ type: ActionTypes.CLEAR_MESSAGE_ATTACHMENTS })
  )
}

/**
 * Sending internal comments
 * @param message
 */
const tryPutInternalMessage = message => (dispatch, getState) => {
  const { attachmentsForCommentsBuffer } = getState().viewResState
  dispatch(tryPutMessage(message, undefined, undefined, undefined, attachmentsForCommentsBuffer, 'i', 'Reservation', moment())).then(() =>
    dispatch({ type: ActionTypes.CLEAR_COMMENT_ATTACHMENTS })
  )
}

/**
 * Resending booking reminders
 */
const resendingBookingNotificationStart = () => ({ type: ActionTypes.RESEND_BOOKING_NOTIFICATION_START })
const resendingBookingNotificationSuccess = () => ({ type: ActionTypes.RESEND_BOOKING_NOTIFICATION_SUCCESS })
const resendingBookingNotificationFail = () => ({ type: ActionTypes.RESEND_BOOKING_NOTIFICATION_FAIL })

const tryResendingBookingEmail = () => (dispatch, getState) => {
  dispatch(resendingBookingNotificationStart())
  doResendBookingNotification(dispatch, getState, MessagingServices.resendBookingEmail, 'Booking email sent')
}

const tryResendingBookingSms = () => (dispatch, getState) => {
  dispatch(resendingBookingNotificationStart())
  doResendBookingNotification(dispatch, getState, MessagingServices.resendBookingSms, 'Booking SMS sent')
}

const doResendBookingNotification = (dispatch, getState, resendFunc, successMessage) => {
  const state = getState()
  const venueId = state.viewResState.actual.venue_id
  const entityId = state.viewResState.actual.id

  const errHandler = error =>
    dispatch(batchActions([SlideoutActions.showNotificationError(`Send error: ${error}`), resendingBookingNotificationFail()]))
  return resendFunc(venueId, entityId, errHandler).then(result => {
    const cbState = getState()
    const cbActual = cbState.viewResState.actual
    if (cbActual && entityId === cbActual.id && result) {
      dispatch(
        batchActions([
          SlideoutActions.showNotificationBanner({
            message: successMessage,
            level: NotificationLevel.SUCCESS,
            visible: true,
          }),
          resendingBookingNotificationSuccess(),
        ])
      )
    }
  })
}

export {
  tryResendingBookingEmail,
  tryResendingBookingSms,
  tryPutInternalMessage,
  tryPutExternalMessage,
  tryGetActivityLog,
  tryGetMessages,
  tryPutFileAttachmentActivityLogEntry,
  tryPutFileDeletedActivityLogEntry,
}
