import _ from 'lodash'
import { orderToTime, timeconfigToDatetime } from 'svr/common/TimeUtil'
import { EMPTY_ARRAY, EMPTY_DICT } from './Constants'

export function getAccessFromGridMap(gridAccessMapByShift, shiftPersistentId, tableId, sortOrder) {
  try {
    return gridAccessMapByShift[shiftPersistentId][tableId][sortOrder] || EMPTY_ARRAY
  } catch (_err) {
    return EMPTY_ARRAY
  }
}

export function getAccessByOrderFromGridMap(gridAccessMapByShift, shiftPersistentId, tableId) {
  try {
    return gridAccessMapByShift[shiftPersistentId][tableId] || EMPTY_DICT
  } catch (_err) {
    return EMPTY_DICT
  }
}

export function computeGridAccessMap(venue, access, date, shifts, seatingAreasById, tableItemsByTableId) {
  // shift -> tableId  -> sort order
  const gridAccessMap = {}
  const venueTimeNow = moment().tz(venue.timezone)

  for (const shift of shifts) {
    gridAccessMap[shift.persistent_id] = {}

    for (const rule of access) {
      // only process held access rules
      if (!rule.is_held) {
        continue
      }
      if (
        (rule.end_time_sort_order < shift.start_time_sort_order || rule.start_time_sort_order > shift.end_time_sort_order) &&
        rule.access_time_type === 'TIME_RANGE'
      ) {
        continue
      }
      // compute table ids
      const tableSet = new Set(rule.table_ids)
      for (const seatingAreaId of rule.seating_area_ids) {
        if (seatingAreaId in seatingAreasById) {
          const { tables } = seatingAreasById[seatingAreaId]
          for (const t of tables) {
            tableSet.add(t.id)
          }
        }
      }
      const tableIds = [...tableSet]
      // compute start sort order and duration
      let startSortOrder
      switch (rule.access_time_type) {
        case 'ALL':
          startSortOrder = shift.start_time_sort_order
          break
        case 'TIME_RANGE':
        case 'SPECIFIC':
          startSortOrder = rule.start_time_sort_order
          break
        default:
          continue
      }

      // skip rules not tied to the current shift (unless the rule uses a custom time range or specific time slot(s))
      if (!rule.shift_categories.includes(shift.category) && !['SPECIFIC', 'TIME_RANGE'].includes(rule.access_time_type)) {
        continue
      }

      for (const tId of tableIds) {
        const table = tableItemsByTableId[tId]
        if (!table) {
          continue
        }

        if (!(tId in gridAccessMap[shift.persistent_id])) {
          gridAccessMap[shift.persistent_id][tId] = {}
        }

        const maxPartySize = table.party_size_max
        const durationMinutes = shift.duration_minutes_by_party_size[maxPartySize] || shift.duration_minutes_by_party_size[-1]
        const tableDurationOrders = durationMinutes / 15
        const shiftEndSortOrder = shift.end_time_sort_order + 1

        let durationIntervals // each interval represents a 15 minute time slot in grid
        let endSortOrder
        switch (rule.access_time_type) {
          case 'ALL':
            endSortOrder = shiftEndSortOrder
            durationIntervals = endSortOrder - startSortOrder
            break
          case 'TIME_RANGE':
            startSortOrder = Math.max(shift.start_time_sort_order, startSortOrder)
            endSortOrder = Math.min(shiftEndSortOrder, rule.end_time_sort_order + tableDurationOrders)
            durationIntervals = Math.min(
              rule.end_time_sort_order - rule.start_time_sort_order + tableDurationOrders,
              endSortOrder - startSortOrder
            )
            break
          case 'SPECIFIC':
            const duration_minutes_by_party_size = shift.duration_ranges_by_order[startSortOrder] ?? {}
            const specificDurationMinutes =
              duration_minutes_by_party_size[maxPartySize] ?? duration_minutes_by_party_size[-1] ?? durationMinutes
            const specificDurationOrders = specificDurationMinutes / 15
            endSortOrder = Math.min(shiftEndSortOrder, startSortOrder + specificDurationOrders)
            durationIntervals = specificDurationOrders
            break
          default:
            continue
        }

        if (!_.isEmpty(rule.cutoff_type)) {
          // Handle expirations
          const startTime = orderToTime(venue.startOfDayHour, startSortOrder)
          const expiration = timeconfigToDatetime(
            venue.startOfDayHour,
            startTime,
            rule.cutoff_num,
            rule.cutoff_type,
            rule.cutoff_hour,
            date,
            venue.timezone
          )
          if (venueTimeNow.isAfter(expiration)) {
            continue
          }
        }
        const durationOrders = endSortOrder - startSortOrder
        if (!(startSortOrder in gridAccessMap[shift.persistent_id][tId])) {
          gridAccessMap[shift.persistent_id][tId][startSortOrder] = []
        }
        gridAccessMap[shift.persistent_id][tId][startSortOrder].push({
          rule,
          durationOrders,
          durationIntervals,
        })
      }
    }
  }

  return gridAccessMap
}
