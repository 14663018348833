import { useMemo, useState } from 'react'
import type { GuestFacingCategoryUpgrade } from '@sevenrooms/core/domain'
import { type Field, useController, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { CollapseButton } from '@sevenrooms/core/ui-kit/form'
import { Box, Collapsible, HStack } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import { Upgrade } from './Upgrade'
import { upgradesMessages } from './Upgrades.locales'
import type { UpgradesForm } from './Upgrades.zod'

interface CategoryProps {
  field: Field<UpgradesForm['categories'][string]>
  category: GuestFacingCategoryUpgrade
  currencyCode: string
  partySize?: number
  isOverride: boolean
}
export function Category({ field, category, currencyCode, partySize, isOverride }: CategoryProps) {
  const { formatMessage } = useLocales()
  const [collapsed, setCollapsed] = useState(false)

  const {
    field: { ref },
  } = useController(field)

  const upgradesValues = useWatch(field.prop('upgrades'))

  const { id, minQuantityType, maxQuantityType, minQuantityInt, maxQuantityInt, name } = category.category
  const isMinRequired = !isOverride && minQuantityType !== 'NONE_REQUIRED'
  const isMaxRequired = maxQuantityType !== 'UNLIMITED'

  const requiredText = useMemo(() => {
    let message: string = formatMessage(upgradesMessages.mustSelectAtLeastLabel, { quantity: minQuantityInt })
    if (minQuantityInt === maxQuantityInt) {
      message = formatMessage(upgradesMessages.mustSelectLabel, { quantity: minQuantityInt })
    }
    if (minQuantityType === 'NUMBER_OF_GUESTS' && partySize != null) {
      message = formatMessage(upgradesMessages.mustSelectLabel, { quantity: partySize })
    }
    return message
  }, [formatMessage, maxQuantityInt, minQuantityInt, minQuantityType, partySize])

  const maxRemaining = useMemo(() => {
    const upgradesSum = Object.values(upgradesValues ?? {}).reduce((acc, val) => acc + val, 0)
    if (maxQuantityType === 'NUMBER_OF_GUESTS' && partySize != null) {
      return partySize - upgradesSum
    }
    return maxQuantityInt - upgradesSum
  }, [maxQuantityInt, maxQuantityType, partySize, upgradesValues])

  return (
    <div data-test={`${id}-category`} ref={ref} tabIndex={-1}>
      <HStack alignItems="center" borderTopColor="borders" backgroundColor="secondaryBackground" height="40px" p="xs s xs m">
        <Box mr="auto">
          <Text fontWeight="bold">{name}</Text>
          {isMinRequired && <Text fontStyle="italic"> &mdash; {requiredText}</Text>}
        </Box>
        {isMinRequired ? (
          <Box pr="lm">
            <StatusLabel variant="error">{formatMessage(commonMessages.required)}</StatusLabel>
          </Box>
        ) : (
          <CollapseButton collapsed={collapsed} onToggle={setCollapsed} size="s" />
        )}
      </HStack>
      <Collapsible collapsed={collapsed}>
        {category.upgrades.map(upgrade => (
          <Upgrade
            key={upgrade.id}
            field={field.prop(`upgrades.${upgrade.id}`)}
            currencyCode={currencyCode}
            max={isMaxRequired ? maxRemaining : undefined}
            {...upgrade}
          />
        ))}
      </Collapsible>
    </div>
  )
}
