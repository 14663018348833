import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { updateEmailCampaigns } from '@sevenrooms/core/api'
import { OngoingEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import type { EmailCampaignTableRow } from '../components/EmailCampaignTable/EmailCampaignTable.types'

export interface EmailCampaignTableToastConfig {
  emailCampaignName: string
  targetStatus: OngoingEmailCampaignStatusEnum.ACTIVE | OngoingEmailCampaignStatusEnum.INACTIVE
}
export interface EmailCampaignTableState {
  rows: EmailCampaignTableRow[]
  oldRows: EmailCampaignTableRow[]
  toastConfig: EmailCampaignTableToastConfig
  showToast: boolean
}

const initialState: EmailCampaignTableState = {
  rows: [],
  oldRows: [],
  toastConfig: { emailCampaignName: '', targetStatus: OngoingEmailCampaignStatusEnum.ACTIVE },
  showToast: false,
}

const emailCampaignTableSlice = createSlice({
  name: 'emailCampaignTable',
  initialState,
  reducers: {
    setEmailCampaignTableRows: (state, action: PayloadAction<EmailCampaignTableRow[]>) => {
      state.rows = action.payload
    },
    setEmailCampaignTableOldRows: (state, action: PayloadAction<EmailCampaignTableRow[]>) => {
      state.oldRows = action.payload
    },
    setEmailCampaignTableToastConfig: (state, action: PayloadAction<EmailCampaignTableToastConfig>) => {
      state.toastConfig = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(updateEmailCampaigns.fulfilled, state => {
      state.oldRows = state.rows
      state.showToast = false
    })

    builder.addCase(updateEmailCampaigns.rejected, state => {
      state.rows = state.oldRows
      state.showToast = true
    })
  },
})

const { actions } = emailCampaignTableSlice
export const { setEmailCampaignTableRows, setEmailCampaignTableOldRows, setEmailCampaignTableToastConfig } = actions
export const { reducer: emailCampaignTableReducer } = emailCampaignTableSlice
