import { srGet, throwFetchError } from '@sevenrooms/core/api'

const fetchVenueBookedByNames = venueId =>
  srGet(`/manager/${venueId}/data/bookedbynames`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true })
    .then(throwFetchError)
    .then(response => {
      const { content } = response.payload
      const bookedByNames = content.booked_by_users
      return { venueId, bookedByNames }
    })

export default {
  fetchVenueBookedByNames,
}
