import type { EmailCampaign } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { type EmailDetailsFormField, campaignBuilderMessages, emailBuilderMessages } from '@sevenrooms/marketing'
import { shortCodes } from '../../constants'

export interface EmailDetailsProps {
  campaign?: EmailCampaign
  field: EmailDetailsFormField
  isFetching?: boolean
  autoFocus?: boolean
  disabled?: boolean
  isAutomatedEmail?: boolean
}

// github.com/colinhacks/zod/blob/master/src/types.ts#L505
const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export const getInvalidReplyToAddress = (replyToAddress: string): string | undefined =>
  replyToAddress
    .split(',')
    .map(address => address.trim())
    .find(address => !emailRegex.test(address) && address !== shortCodes.senderEmail)

export function EmailDetails({ campaign, isFetching, field, autoFocus, disabled, isAutomatedEmail }: EmailDetailsProps) {
  const { formatMessage } = useLocales()

  return (
    <BaseSection title={formatMessage(emailBuilderMessages.emailDetailsTitle)}>
      <Box p="lm">
        <HStack spacing="lm" alignItems="flex-start">
          {isFetching ? (
            <Box width="100%">
              <Loader />
            </Box>
          ) : (
            <>
              <VStack spacing="lm" width="50%">
                <Label
                  primary={
                    isAutomatedEmail
                      ? formatMessage(emailBuilderMessages.automatedEmailName)
                      : formatMessage(emailBuilderMessages.emailName)
                  }
                  secondary={formatMessage(campaignBuilderMessages.internalName)}
                >
                  <FormInput
                    autoFocus={autoFocus}
                    defaultValue={campaign?.campaignName}
                    field={field.prop('campaignName')}
                    autoComplete="none"
                    disabled={disabled}
                  />
                </Label>
              </VStack>
              <VStack spacing="lm" width="50%" justifyContent="flex-end">
                <Label
                  primary={formatMessage(emailBuilderMessages.replyToEmailAddresses)}
                  secondary={formatMessage(emailBuilderMessages.replyToEmailAddressesDescription)}
                >
                  <FormInput
                    disabled={disabled}
                    defaultValue={campaign?.emailReplyTo}
                    field={field.prop('replyToAddress')}
                    autoComplete="none"
                  />
                </Label>
              </VStack>
            </>
          )}
        </HStack>
      </Box>
    </BaseSection>
  )
}
