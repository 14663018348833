import { useLocales } from '@sevenrooms/core/locales'
import { Redirect, Route, Surface, Switch, useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, Window } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { DeactivateEmailCampaignModal } from '../../components'
import { emailCenterMessages } from '../../locales'
import { CreateNewEmail } from '../CreateNewEmail'
import { Emails } from '../Emails'

export interface EmailCenterProps {}

export function EmailCenter() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(emailCenterMessages.emailCenterTitle)} />

      <SettingsPageContent
        headerWidth="calc(100% - 274px)"
        secondHeaderMaxWidth="100%"
        title={formatMessage(emailCenterMessages.emailCenterTitle)}
        actions={
          <Button
            variant="primary"
            icon="VMSWeb-add"
            onClick={() => {
              nav.replace(routes.manager2.marketing.emailCenter.emails.createNewEmail, { params: { venueKey: venue.urlKey } })
            }}
            data-test="create-new-email-button"
          >
            {formatMessage(emailCenterMessages.emailCenterNewEmail)}
          </Button>
        }
      >
        <Box p="lm" width="100%">
          <Switch>
            <Route path={routes.manager2.marketing.emailCenter.emails.path}>
              <Emails />
            </Route>
            <Redirect to={routes.manager2.marketing.emailCenter.emails.path} />
          </Switch>
        </Box>
      </SettingsPageContent>

      <Surface destination={routes.manager2.marketing.emailCenter.emails.createNewEmail}>
        <Window>
          <CreateNewEmail
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.createNewEmail, {
              params: { venueKey: venue.urlKey },
            })}
          />
        </Window>
      </Surface>

      <Surface destination={routes.manager2.marketing.emailCenter.emails.deactivateEmailCampaignModal}>
        <Window>
          <DeactivateEmailCampaignModal
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.deactivateEmailCampaignModal, {
              params: { venueKey: venue.urlKey },
            })}
          />
        </Window>
      </Surface>
    </>
  )
}
