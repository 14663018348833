import _ from 'lodash'
import { getDurationDisplay } from '../../../common/TimeUtil'
import DropdownArrowsPicker from './DropdownArrowsPicker'

const MAX_DURATION_ORDER = 64
const INTERVAL_MINS = 15

interface DurationDropDownProps {
  name: string
  duration: number
  defaultDuration: number
  onChangeDuration: () => void
  usingDefaultDuration?: boolean
  width?: number
  style?: object
  showNavigation?: boolean
  disabled?: boolean
  useOutsideLabel?: boolean
  isLightTheme?: boolean
  noHeader?: boolean
  extra?: React.ReactNode
  bufferMins?: number
}

const DurationDropDown = ({
  duration,
  usingDefaultDuration,
  onChangeDuration,
  extra,
  bufferMins,
  name = 'DURATION',
  disabled = true,
  width = 185,
  style = {},
  showNavigation = true,
  useOutsideLabel = false,
  isLightTheme = true,
  defaultDuration = 0,
  noHeader = false,
}: DurationDropDownProps) => {
  const [shortFormat, dropMinutesUnit] = [false, false]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showBuffers = (window as any)?.globalInit.venueSettings.is_buffers_enabled && bufferMins

  const choices = disabled
    ? []
    : _.range(1, MAX_DURATION_ORDER + 1).map(i => {
        const durationDisplay = getDurationDisplay(i * INTERVAL_MINS, shortFormat, dropMinutesUnit)
        return {
          name: showBuffers ? `${durationDisplay} + ${bufferMins}m` : durationDisplay,
          value: i * INTERVAL_MINS,
        }
      })
  choices.splice(0, 0, { name: 'Default', value: 0 })

  const moveLeftValue = duration === 0 ? safeDuration(defaultDuration - INTERVAL_MINS) : undefined
  const moveRightValue = duration === 0 ? safeDuration(defaultDuration + INTERVAL_MINS) : undefined

  const getDisplayOverride = () => {
    if (duration === 0) {
      return 'Default'
    }
    let display = getDurationDisplay(duration, shortFormat, dropMinutesUnit)
    display += usingDefaultDuration ? ' (Default)' : ''
    display += showBuffers ? ` + ${bufferMins}m` : ''
    return display
  }

  return (
    <DropdownArrowsPicker
      key="DURATION"
      name={name || 'DURATION'}
      choices={choices}
      value={disabled ? 0 : duration}
      displayOverride={getDisplayOverride()}
      onChangeHandler={onChangeDuration}
      extra={extra}
      style={{
        float: 'left',
        width,
        ...style,
      }}
      {...{
        showNavigation,
        disabled,
        useOutsideLabel,
        isLightTheme,
        moveLeftValue,
        moveRightValue,
        noHeader,
      }}
    />
  )
}

const safeDuration = (duration: number) => {
  if (duration < 0) {
    return INTERVAL_MINS * MAX_DURATION_ORDER
  }
  if (duration > INTERVAL_MINS * MAX_DURATION_ORDER) {
    return 0
  }
  return duration
}

export default DurationDropDown
