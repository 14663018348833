import { srGet, srPost } from '@sevenrooms/core/api'

const fetchDailyEventsAndNotes = (venueId, date, errHandler) => {
  const dateParam = moment(date).format('MM-DD-YYYY')

  return srGet(
    `/manager/${venueId}/manage/program/json`,
    {
      date: dateParam,
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    const { events, note } = response.payload
    return { venueId, date, events, note }
  })
}

const saveDailyNote = (venueId, date, note, errHandler) => {
  const dateParam = moment(date).format('MM-DD-YYYY')

  return srPost(`/manager/${venueId}/home/dailynotes/${dateParam}/edit`, {
    note,
  }).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    return response.payload
  })
}

export default {
  fetchDailyEventsAndNotes,
  saveDailyNote,
}
