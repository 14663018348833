import type { Field } from '@sevenrooms/core/form'
import { FormatService } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Flex, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { PropsWithChildren } from 'react'

interface ChargeLineProps {
  title: string
  oldAmount?: number | null
  amount?: number
  currencyCode: string
  field?: Field<boolean>
  testId?: string
}

export function ChargeLine({ title, children, oldAmount, amount, currencyCode, field, testId }: PropsWithChildren<ChargeLineProps>) {
  const theme = useTheme()
  return (
    <HStack flex="auto" borderTopColor="dividerLines" height={theme.spacing.xxl} pr="m" ml="xxl" spacing="m">
      <Flex flex="auto" alignItems="center">
        {field ? (
          <Checkbox field={field} data-test={testId} hideErrorMessage>
            {title}
          </Checkbox>
        ) : (
          <Text>{title}</Text>
        )}
      </Flex>
      {oldAmount != null && (
        <Flex width="150px" alignItems="center" justifyContent="end" pr="s">
          <Text textDecoration="line-through" color="error">
            {oldAmount}%
          </Text>
        </Flex>
      )}
      {!!children && (
        <Flex width="190px" alignItems="center">
          {children}
        </Flex>
      )}
      <Flex width="78px" alignItems="center" justifyContent="end">
        <Text>{amount != null && FormatService.formatCurrency(amount, currencyCode)}</Text>
      </Flex>
    </HStack>
  )
}
