import React from 'react'

class LazyImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { stage: 0, currSrc: this.props.src[0] }
    this.handleLowResLoad = this.handleLowResLoad.bind(this)
  }

  handleLowResLoad() {
    const newIndex = this.state.stage + 1
    if (newIndex < this.props.src.length) {
      this.setState({
        stage: newIndex,
        currSrc: this.props.src[newIndex],
      })
    }
  }

  render() {
    return <img src={this.state.currSrc} onLoad={this.handleLowResLoad} alt={this.props.alt} className={this.props.className} />
  }
}

LazyImage.propTypes = {
  src: React.PropTypes.array,
  alt: React.PropTypes.string,
  className: React.PropTypes.string,
}

export default LazyImage
