import _ from 'lodash'
import * as ActionTypes from 'mgr/pages/venue-group/actions/ActionTypes'

const defaultTagCategoryVals = {
  startAutoValidation: false,
  selectedTagCategoryClassification: '',
  selectedTagCategoryVip: 0,
  selectedTagCategorySuperuser: 0,
  selectedTagCategoryRestricted: 0,
  selectedTagCategoryShowChit: 1,
  selectedTagCategoryResSummary: 1,
  selectedTagCategoryShowCustom: 0,
  selectedTagCategoryFollowers: [],
  selectedTagCategoryTags: [],
  selectedTagCategoryPods: [],
  formErrors: [],
}

const initialState = {
  tagKeyCounter: 0,
  followers: [],
  tagCategories: [],
  podCategoryTagsByCategory: [],
  isNewTagCategory: false,
  selectedTagCategoryId: null,
  selectedTagCategoryName: null,
  selectedTagCategoryNameDisplay: null,
  selectedTagCategoryTagNameDisplays: {},
  selectedTagCategoryHexColor: null,
  showDeleteWarning: false,
  showDisableWarning: false,
  selectedTagCategoryIsDefault: null,
  selectedTagCategoryIsAutotag: null,
  selectedTagCategoryDefaultOptionalTags: [],
  selectedTagCategoryDisableNewTags: false,
  ...defaultTagCategoryVals,
}

const getNewTag = (tagKeyOrder, hexColor, name) => ({
  name,
  updatedName: name,
  isActive: true,
  hexColor,
  tagKeyOrder,
  isNew: true,
})

const setTagsActiveStateFalse = tags => {
  _.map(tags, tag => {
    // eslint-disable-next-line no-param-reassign
    tag.isActive = false
  })
  return tags
}

const getTagCategoryIdxById = (tagCategories, tagCategoryId) => _.findIndex(tagCategories, { id: tagCategoryId })

const resetFollowerChoices = followers => _.map(followers, f => _.extend(f, { isSelected: false, hexColor: '#0A223A' }))

const selectChosenFollowers = (allFollowers, selectedFollowers) => {
  const followers = resetFollowerChoices(allFollowers)
  let followerIdx
  for (const follower of followers) {
    followerIdx = _.findIndex(selectedFollowers, { value: follower.value })
    if (followerIdx !== -1) {
      follower.isSelected = true
    }
  }
  return followers
}

const formatPodCategoryTags = podCategoryTagsByAccessor =>
  _.map(podCategoryTagsByAccessor, podCategory => ({
    header: podCategory.name,
    id: podCategory.id,
    choices: _.map(podCategory.pods, pod => ({
      name: pod.name,
      value: pod.id,
      isSelected: false,
      classifier: podCategory.id,
      hexColor: podCategory.hex_color,
    })),
  }))

const selectPodsFromChoices = (podCategories, selectedPods) => {
  const selectedPodIds = _.map(selectedPods, pod => pod.value)
  _.map(podCategories, podCategory => {
    _.map(podCategory.choices, pod => {
      // eslint-disable-next-line no-param-reassign
      pod.isSelected = _.includes(selectedPodIds, pod.value)
    })
  })
  return podCategories
}

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CLIENT_TAG_CATEGORIES_SUCCESS:
    case ActionTypes.FETCH_RESERVATION_TAG_CATEGORIES_SUCCESS:
      return {
        ...state,
        isNewTagCategory: false,
        selectedTagCategoryId: null,
        selectedTagCategoryName: null,
        selectedTagCategoryNameDisplay: null,
        selectedTagCategoryTagNameDisplays: {},
        selectedTagCategoryIsDefault: null,
        selectedTagCategoryIsAutotag: false,
        selectedTagCategoryDefaultOptionalTags: [],
        selectedTagCategoryDisableNewTags: false,
        selectedTagCategoryHexColor: null,
        ...defaultTagCategoryVals,
        tagCategories: action.tagCategories,
      }
    case ActionTypes.OPEN_TAG_SLIDEOUT: {
      const tagNameDisplays = action.selectedTagCategoryTagNameDisplays ?? {}
      const existingTags = _.map(action.selectedTagCategoryTags, (tag, idx) => ({
        tagKeyOrder: state.tagKeyCounter + idx,
        name: tag,
        updatedName: tagNameDisplays[tag] ?? tag,
        isActive: false,
      }))
      const extendedProps = action.isNewTagCategory
        ? {
            ...defaultTagCategoryVals,
            selectedTagCategoryClassification: action.selectedTagCategoryClassification,
          }
        : {
            selectedTagCategoryClassification: action.selectedTagCategoryClassification,
            selectedTagCategoryVip: action.selectedTagCategoryVip,
            selectedTagCategorySuperuser: action.selectedTagCategorySuperuser,
            selectedTagCategoryShowChit: action.selectedTagCategoryShowChit,
            selectedTagCategoryResSummary: action.selectedTagCategoryResSummary,
            selectedTagCategoryShowCustom: action.selectedTagCategoryShowCustom,
            selectedTagCategoryFollowers: action.selectedTagCategoryFollowers,
            selectedTagCategoryPods: action.selectedTagCategoryPods,
            selectedTagCategoryTags: existingTags,
          }

      const newFollowerChoices = action.isNewTagCategory
        ? resetFollowerChoices(state.followers)
        : selectChosenFollowers(state.followers, action.selectedTagCategoryFollowers)
      return {
        ...state,
        tagKeyCounter: state.tagKeyCounter + extendedProps.selectedTagCategoryTags.length,
        isNewTagCategory: action.isNewTagCategory,
        selectedTagCategoryId: action.selectedTagCategoryId,
        selectedTagCategoryName: action.selectedTagCategoryName,
        selectedTagCategoryNameDisplay: action.selectedTagCategoryNameDisplay,
        selectedTagCategoryTagNameDisplays: tagNameDisplays,
        selectedTagCategoryIsDefault: action.selectedTagCategoryIsDefault,
        selectedTagCategoryIsAutotag: action.selectedTagCategoryIsAutotag,
        selectedTagCategoryDefaultOptionalTags: action.selectedTagCategoryDefaultOptionalTags,
        selectedTagCategoryDisableNewTags: action.selectedTagCategoryDisableNewTags,
        selectedTagCategoryHexColor: action.selectedTagCategoryHexColor,
        podCategoryTagsByCategory: selectPodsFromChoices(state.podCategoryTagsByCategory, action.selectedTagCategoryPods),
        followers: newFollowerChoices,
        ...extendedProps,
      }
    }
    case ActionTypes.ENABLE_TAG_CATEGORY_SUCCESS:
      const enableIdx = getTagCategoryIdxById(state.tagCategories, action.tagCategoryId)
      const enableTagCategories = state.tagCategories.slice()
      enableTagCategories[enableIdx].disabled = 0
      return {
        ...state,
        tagCategories: enableTagCategories,
      }
    case ActionTypes.DISABLE_TAG_CATEGORY_SUCCESS:
      const disableIdx = getTagCategoryIdxById(state.tagCategories, action.tagCategoryId)
      const disableTagCategories = state.tagCategories.slice()
      disableTagCategories[disableIdx].disabled = 1
      return {
        ...state,
        tagCategories: disableTagCategories,
        showDeleteWarning: false,
        showDisableWarning: false,
        selectedTagCategoryId: null,
      }
    case ActionTypes.DELETE_TAG_CATEGORY_SUCCESS:
      const deleteIdx = getTagCategoryIdxById(state.tagCategories, action.tagCategoryId)
      const deleteTagCategories = state.tagCategories.slice()
      deleteTagCategories.splice(deleteIdx, 1)
      return {
        ...state,
        tagCategories: deleteTagCategories,
        showDeleteWarning: false,
        showDisableWarning: false,
        selectedTagCategoryId: null,
      }
    case ActionTypes.TOGGLE_DELETE_WARNING:
      return {
        ...state,
        showDeleteWarning: !state.showDeleteWarning,
        selectedTagCategoryId: action.tagCategoryId,
      }
    case ActionTypes.TOGGLE_DISABLE_WARNING:
      return {
        ...state,
        showDisableWarning: !state.showDisableWarning,
        selectedTagCategoryId: action.tagCategoryId,
      }
    case ActionTypes.FETCH_FOLLOWERS_SUCCESS:
      return {
        ...state,
        followers: resetFollowerChoices(action.followers),
      }
    case ActionTypes.CHANGE_TAG_CATEGORY_NAME:
      return {
        ...state,
        selectedTagCategoryNameDisplay: action.name,
      }
    case ActionTypes.CHANGE_POD_HEX_COLOR:
      return {
        ...state,
        selectedTagCategoryHexColor: action.hexColor,
      }
    case ActionTypes.TOGGLE_CHECKBOX:
      const checkboxState = { ...state }
      checkboxState[action.field] = checkboxState[action.field] ? 0 : 1
      return checkboxState
    case ActionTypes.SET_RADIO_BTN_VAL:
      const radioState = { ...state }
      radioState[action.field] = action.value
      return radioState
    case ActionTypes.UPDATE_TAG_CATEGORY_PODS:
      return {
        ...state,
        selectedTagCategoryPods: action.selectedPods,
        podCategoryTagsByCategory: action.podCategoryTagsByCategory,
      }
    case ActionTypes.UPDATE_TAG_CATEGORY_FOLLOWERS:
      return {
        ...state,
        selectedTagCategoryFollowers: action.selectedFollowers,
        followers: action.allFollowers,
      }
    case ActionTypes.APPEND_NEW_TAG:
      const forcedInactiveTags = setTagsActiveStateFalse(state.selectedTagCategoryTags)
      const newSelectedTags = [getNewTag(state.tagKeyCounter, state.selectedTagCategoryHexColor, action.text), ...forcedInactiveTags]
      if (state.startAutoValidation && action.callbackFunc) {
        action.callbackFunc(newSelectedTags, state.formErrors)
      }
      return {
        ...state,
        selectedTagCategoryTags: newSelectedTags,
        tagKeyCounter: state.tagKeyCounter + 1,
      }
    case ActionTypes.CHANGE_TAG_NAME:
      const inactiveTagsCopy = setTagsActiveStateFalse(state.selectedTagCategoryTags)
      const tagsCopy = inactiveTagsCopy.slice(0)
      tagsCopy[action.idx].updatedName = action.tagName
      tagsCopy[action.idx].isActive = action.isActive
      delete state.formErrors[tagsCopy[action.idx].tagKeyOrder]
      if (state.startAutoValidation && action.callbackFunc) {
        action.callbackFunc(tagsCopy, state.formErrors)
      }
      return {
        ...state,
        selectedTagCategoryTags: tagsCopy,
        formErrors: state.formErrors,
      }
    case ActionTypes.DELETE_TAG:
      const newTags = setTagsActiveStateFalse(state.selectedTagCategoryTags)
      const newTagKeyCounter = state.tagKeyCounter
      newTags[action.idx].isDeleted = !newTags[action.idx].isDeleted
      delete state.formErrors[newTags[action.idx].tagKeyOrder]
      if (state.startAutoValidation && action.callbackFunc) {
        action.callbackFunc(newTags, state.formErrors)
      }
      return {
        ...state,
        selectedTagCategoryTags: newTags,
        tagKeyCounter: newTagKeyCounter,
        formErrors: state.formErrors,
      }
    case ActionTypes.UPDATE_TAG_SLIDEOUT_ERRORS:
      return {
        ...state,
        formErrors: action.formErrors,
        selectedTagCategoryTags: setTagsActiveStateFalse(state.selectedTagCategoryTags),
        startAutoValidation: true,
      }
    case ActionTypes.ADD_TAG_CATEGORY_SUCCESS:
    case ActionTypes.EDIT_TAG_CATEGORY_SUCCESS:
      const updateIdx = getTagCategoryIdxById(state.tagCategories, action.tagCategory.id)
      if (updateIdx === -1) {
        return {
          ...state,
          isNewTagCategory: false,
          selectedTagCategoryId: null,
          selectedTagCategoryName: null,
          selectedTagCategoryNameDisplay: null,
          selectedTagCategoryIsDefault: null,
          selectedTagCategoryIsAutotag: null,
          selectedTagCategoryDefaultOptionalTags: [],
          selectedTagCategoryDisableNewTags: false,
          selectedTagCategoryHexColor: null,
          ...defaultTagCategoryVals,
          tagCategories: [action.tagCategory, ...state.tagCategories],
        }
      }
      const updatedTagCategories = state.tagCategories.splice(0)
      updatedTagCategories[updateIdx] = action.tagCategory
      return {
        ...state,
        isNewTagCategory: false,
        selectedTagCategoryId: null,
        selectedTagCategoryName: null,
        selectedTagCategoryNameDisplay: null,
        selectedTagCategoryIsDefault: null,
        selectedTagCategoryIsAutotag: null,
        selectedTagCategoryDefaultOptionalTags: [],
        selectedTagCategoryDisableNewTags: false,
        selectedTagCategoryHexColor: null,
        ...defaultTagCategoryVals,
        tagCategories: updatedTagCategories,
      }
    case ActionTypes.LOAD_VENUE_POD_CONFIGS_SUCCESS:
      return {
        ...state,
        podCategoryTagsByCategory: formatPodCategoryTags(action.podCategoriesByAccessor),
      }
    default:
      return state
  }
}

export default tagsReducer
