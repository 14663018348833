import _ from 'lodash'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import { FULFILLMENT_METHOD } from 'svr/constants'

const initialState = {
  isLoading: false,
  isTableSyncLoading: false,
  isSaving: false,
  isDirty: false,
  siteData: [],
  availableMenus: [],
  fulfillmentMethods: [],
  orderAheadTimeDisplayUnits: [],
  posTables: [],
  venueSupportsPosTableSync: false,
  editState: {
    displaySettings: {},
  },
}

const buildInitialEditState = () => ({
  displaySettings: {
    show_pickup_min: false,
    show_pickup_max: false,
    show_delivery_min: false,
    show_delivery_max: false,
    show_room_service_min: false,
    show_room_service_max: false,
    show_pickup_section: true,
    show_delivery_section: true,
    show_room_service_section: true,
    show_timeslot_settings: true,
    show_table_select: false,
  },
  allow_order_ahead: true,
})

const buildEditState = siteData => {
  const editState = _.pick(siteData, [
    'name',
    'url_key',
    'menu_ids',
    'fulfillment_method',
    'subtotal_min',
    'subtotal_max',
    'checkout_message',
    'room_service_checkout_message',
    'banner_message',
    'delivery_subtotal_min',
    'delivery_subtotal_max',
    'room_service_subtotal_min',
    'room_service_subtotal_max',
    'time_slot_width_mins',
    'time_slot_spacing_mins',
    'is_enabled',
    'max_order_ahead_time_display_unit',
    'custom_fields_enabled',
    'custom_fields_required',
    'delivery_custom_fields_enabled',
    'delivery_custom_fields_required',
    'room_service_custom_fields_enabled',
    'room_service_custom_fields_required',
    'pos_tables',
    'is_pos_table_multiselect',
    'is_guest_will_pick_up_room_number_from_list',
  ])
  const allowOrderAhead = siteData.max_order_ahead_mins > 0 || siteData.time_slot_spacing_mins > 0
  return {
    ...buildInitialEditState(),
    ...editState,
    subtotal_min: !_.isNil(siteData.subtotal_min) ? siteData.subtotal_min : '',
    subtotal_max: !_.isNil(siteData.subtotal_max) ? siteData.subtotal_max : '',
    delivery_subtotal_min: !_.isNil(siteData.delivery_subtotal_min) ? siteData.delivery_subtotal_min : '',
    delivery_subtotal_max: !_.isNil(siteData.delivery_subtotal_max) ? siteData.delivery_subtotal_max : '',
    room_service_subtotal_min: !_.isNil(siteData.room_service_subtotal_min) ? siteData.room_service_subtotal_min : '',
    room_service_subtotal_max: !_.isNil(siteData.room_service_subtotal_max) ? siteData.room_service_subtotal_max : '',
    displaySettings: {
      show_pickup_min: !_.isNil(siteData.subtotal_min),
      show_pickup_max: !_.isNil(siteData.subtotal_max),
      show_delivery_min: !_.isNil(siteData.delivery_subtotal_min),
      show_delivery_max: !_.isNil(siteData.delivery_subtotal_max),
      show_room_service_min: !_.isNil(siteData.room_service_subtotal_min),
      show_room_service_max: !_.isNil(siteData.room_service_subtotal_max),
      ...getFulfillmentSectionDisplaySettings(siteData.fulfillment_method),
    },
    allow_order_ahead: allowOrderAhead,
    max_order_ahead_time: allowOrderAhead
      ? calculateMaxOrderAheadTime(siteData.max_order_ahead_mins, siteData.max_order_ahead_time_display_unit)
      : 0,
  }
}

const getUpdatedEditState = (state, updatedFields) => ({
  ...state,
  isDirty: true,
  editState: {
    ...state.editState,
    ...updatedFields,
  },
})

const buildMenuIds = menus =>
  _.keys(menus).reduce((result, key) => {
    if (menus[key].isSelected) {
      result.push(key)
    }
    return result
  }, [])

const calculateMaxOrderAheadTime = (maxOrderAheadMins, maxOrderAheadTimeDisplayUnit) => {
  const hours = maxOrderAheadMins / 60
  if (maxOrderAheadTimeDisplayUnit === 'HOURS') {
    return hours
  }
  if (maxOrderAheadTimeDisplayUnit === 'DAYS') {
    return hours / 24
  }
  return 0
}

const getResetToDefaultFields = editState => {
  let resetToDefaultFields = {}
  if (!editState.allow_order_ahead) {
    resetToDefaultFields = {
      time_slot_spacing_mins: 0,
      max_order_ahead_time: 0,
      max_order_ahead_time_display_unit: 'DAYS',
    }
  }
  if (!editState.displaySettings.show_pickup_min) {
    resetToDefaultFields.subtotal_min = ''
  }
  if (!editState.displaySettings.show_pickup_max) {
    resetToDefaultFields.subtotal_max = ''
  }
  if (!editState.displaySettings.show_delivery_min) {
    resetToDefaultFields.delivery_subtotal_min = ''
  }
  if (!editState.displaySettings.show_delivery_max) {
    resetToDefaultFields.delivery_subtotal_max = ''
  }
  return resetToDefaultFields
}

const getFulfillmentSectionDisplaySettings = newOption => {
  if (newOption === FULFILLMENT_METHOD.ON_PREMISE) {
    return {
      show_pickup_section: true,
      show_delivery_section: false,
      show_room_service_section: false,
      show_timeslot_settings: false,
      show_table_select: true,
    }
  }
  if (newOption === FULFILLMENT_METHOD.PICKUP) {
    return {
      show_pickup_section: true,
      show_delivery_section: false,
      show_room_service_section: false,
      show_timeslot_settings: true,
      show_table_select: false,
    }
  }
  if (newOption === FULFILLMENT_METHOD.DELIVERY) {
    return {
      show_delivery_section: true,
      show_pickup_section: false,
      show_room_service_section: false,
      show_timeslot_settings: true,
      show_table_select: false,
    }
  }
  if (newOption === FULFILLMENT_METHOD.PICKUP_OR_DELIVERY) {
    return {
      show_delivery_section: true,
      show_pickup_section: true,
      show_room_service_section: false,
      show_timeslot_settings: true,
      show_table_select: false,
    }
  }
  if (newOption === FULFILLMENT_METHOD.PICKUP_OR_ROOM_SERVICE) {
    return {
      show_delivery_section: false,
      show_pickup_section: true,
      show_room_service_section: true,
      show_timeslot_settings: true,
      show_table_select: false,
    }
  }
  if (newOption === FULFILLMENT_METHOD.ROOM_SERVICE) {
    return {
      show_delivery_section: false,
      show_pickup_section: false,
      show_room_service_section: true,
      show_timeslot_settings: true,
      show_table_select: false,
    }
  }
  return {}
}

const getDefaultSelectedPosTables = (newMethod, posTables, selectedPosTables, isPosTablesMultiSelect) => {
  if (
    newMethod === FULFILLMENT_METHOD.ON_PREMISE &&
    posTables &&
    posTables.length &&
    selectedPosTables &&
    !selectedPosTables.length &&
    isPosTablesMultiSelect
  ) {
    return posTables
  }
  return []
}

const orderingSiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ORDERING_SITE_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GET_ORDERING_SITE_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.GET_ORDERING_SITE_SUCCESS:
      const siteData = action.data.ordering_site
      return {
        ...state,
        siteData,
        availableMenus: action.data.menus,
        fulfillmentMethods: action.data.fulfillment_methods,
        orderAheadTimeDisplayUnits: action.data.order_ahead_time_display_units,
        posTables: action.data.pos_tables,
        venueSupportsPosTableSync: action.data.venue_supports_pos_table_sync,
        isLoading: false,
        isDirty: false,
        editState: buildEditState(siteData),
      }
    case ActionTypes.GET_ORDERING_SITE_SYNCED_POS_TABLES_START:
      return {
        ...state,
        isTableSyncLoading: true,
      }
    case ActionTypes.GET_ORDERING_SITE_SYNCED_POS_TABLES_FAIL:
      return {
        ...state,
        isTableSyncLoading: false,
      }
    case ActionTypes.GET_ORDERING_SITE_SYNCED_POS_TABLES_SUCCESS:
      if (state.siteData.pos_tables.length) {
        const syncedPosTableIds = action.data.pos_tables.map(table => table.id)
        const sitePosTableIds = state.siteData.pos_tables.map(table => table.id)
        const invalidIds = _.difference(sitePosTableIds, syncedPosTableIds)
        if (invalidIds.length) {
          const cleanedPosTables = state.siteData.pos_tables.filter(table => !_.includes(invalidIds, table.id))
          const posTablesResolved = state.siteData.is_pos_table_multiselect ? cleanedPosTables : []
          return {
            ...getUpdatedEditState(state, { pos_tables: posTablesResolved }),
            posTables: action.data.pos_tables,
            isTableSyncLoading: false,
            siteData: {
              ...state.siteData,
              pos_tables: posTablesResolved,
            },
            isDirty: false,
          }
        }
      }
      return {
        ...state,
        ...getUpdatedEditState(state, { pos_tables: action.data.pos_tables }),
        posTables: action.data.pos_tables,
        isTableSyncLoading: false,
      }
    case ActionTypes.SAVE_ORDERING_SITE_START:
      return {
        ...state,
        isSaving: true,
      }
    case ActionTypes.SAVE_ORDERING_SITE_SUCCESS:
      const resetToDefaultFields = getResetToDefaultFields(state.editState)
      return {
        ...getUpdatedEditState(state, resetToDefaultFields),
        isSaving: false,
        isDirty: false,
      }
    case ActionTypes.SAVE_ORDERING_SITE_FAIL:
      return {
        ...state,
        isSaving: false,
      }
    case ActionTypes.UPDATE_ORDERING_FIELDS:
      return getUpdatedEditState(state, action.updatedFields)
    case ActionTypes.UPDATE_ORDERING_FIELD:
      return getUpdatedEditState(state, { [action.fieldName]: action.newValue })
    case ActionTypes.TOGGLE_DISPLAY_FIELD:
      return getUpdatedEditState(state, {
        displaySettings: {
          ...state.editState.displaySettings,
          [action.fieldName]: !state.editState.displaySettings[action.fieldName],
        },
      })
    case ActionTypes.TOGGLE_ORDERING_FIELD:
      return getUpdatedEditState(state, {
        [action.fieldName]: !state.editState[action.fieldName],
      })
    case ActionTypes.UPDATE_ORDERING_SITE_MENUS:
      return getUpdatedEditState(state, {
        menu_ids: buildMenuIds(action.menus),
      })
    case ActionTypes.UPDATE_FULFILLMENT_OPTION:
      return getUpdatedEditState(state, {
        displaySettings: {
          ...state.editState.displaySettings,
          ...getFulfillmentSectionDisplaySettings(action.option),
        },
        fulfillment_method: action.option,
        pos_tables: [
          ...getDefaultSelectedPosTables(
            action.option,
            state.posTables,
            state.editState.pos_tables,
            state.editState.is_pos_table_multiselect
          ),
        ],
      })
    case ActionTypes.UPDATE_ORDERING_SITE_POS_TABLES:
      return getUpdatedEditState(state, {
        pos_tables: action.value.map(tableId => state.posTables.find(table => table.id === tableId)).filter(obj => !!obj),
      })
    case ActionTypes.REMOVE_ORDERING_SITE_POS_TABLE:
      return getUpdatedEditState(state, {
        pos_tables: state.editState.pos_tables.filter(table => table.id !== action.value),
      })
    default:
      return state
  }
}

export default orderingSiteReducer
