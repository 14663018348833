import {
  ValidatorTypes as TextInputValidatorTypes,
  type validatorFuncs,
  InputRestrictions as TextInputInputRestrictions,
  type restrictionRegexes,
} from './TextInput'

export const ValidatorTypes = TextInputValidatorTypes as typeof validatorFuncs

export const InputRestrictions = TextInputInputRestrictions as typeof restrictionRegexes
