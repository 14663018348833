import moment from 'moment'
import type { AccessRuleApi, AvailabilityTimeslot, InternalAvailabilityTimeslot, MixedAvailabilityTimeslot } from '@sevenrooms/core/api'
import type { ShiftCategory } from '@sevenrooms/core/domain'
import { applyChargesToAvailabilityTimeslot } from './helpers'
import type { Actual, Charges, Venue } from '../../reducers/BookPaymentSlice.types'

export function mixTimeslot(
  timeslot: AvailabilityTimeslot,
  shiftCategory: ShiftCategory,
  internalTimeslot?: InternalAvailabilityTimeslot
): MixedAvailabilityTimeslot {
  return {
    ...timeslot,
    shift_category: shiftCategory,
    seating_area_id: timeslot.access_seating_area_id,
    timeMoment: moment(timeslot.time_iso),
    status: 'available',
    venue_id: internalTimeslot?.venue_id ?? null, // this will be used in getARTimeSelectionIfAvailable()
    booked_reservations: internalTimeslot?.booked_reservations ?? 0,
    booked_covers: internalTimeslot?.booked_covers ?? 0,
    booked_possible_covers: internalTimeslot?.booked_possible_covers ?? 0,
    booked_seating_area_covers: internalTimeslot?.booked_seating_area_covers ?? 0,
    booked_possible_seating_area_covers: internalTimeslot?.booked_possible_seating_area_covers ?? 0,
    max_covers: internalTimeslot?.max_covers ?? 0,
    recommended: internalTimeslot?.recommended ?? [],
    unassigned: internalTimeslot?.unassigned ?? [],
    blocked: internalTimeslot?.blocked ?? [],
    held: internalTimeslot?.held ?? [],
    table_size_too_large: internalTimeslot?.table_size_too_large ?? [],
    table_size_too_small: internalTimeslot?.table_size_too_small ?? [],
    overbook_enforced_shift_party_size: internalTimeslot?.overbook_enforced_shift_party_size ?? [],
  }
}

export function createAvailabilityTimeslotFromAccessRule(accessRule: AccessRuleApi, actual: Actual, venue: Venue): AvailabilityTimeslot {
  // this is not fully aligned with AvailabilityTimeslot model, so we have to add missed fields further, because
  // server returns us only actual+access rule, so we still need to add real datetime of slot and charges if access rule follows shift
  // see: initAvailabilityTimeslotFromAccessRule
  const taxGroup = venue.bookSettings.taxGroups.find(group => group.id === accessRule.tax_group_id)
  return {
    type: 'book',
    sort_order: actual.arrival_time_sort_order,
    time: actual.arrival_time_display,
    time_iso: actual.date_arrival_time_dt_sync_dt_formatted,
    duration: actual.duration,
    access_rule_durations_enabled: Boolean(accessRule.duration_minutes_by_party_size),
    duration_minutes_by_party_size: accessRule.duration_minutes_by_party_size ?? {},
    access_persistent_id: actual.access_persistent_id,
    shift_persistent_id: actual.shift_persistent_id,
    access_seating_area_id: actual.venue_seating_area_id,
    is_held: accessRule.is_held,
    charge_type: accessRule.cc_charge_type,
    cc_party_size_min: accessRule.cc_party_size_min,
    public_time_slot_description: accessRule.public_time_slot_description,
    public_description_title: accessRule.public_description_title,
    public_photo: accessRule.public_photo,
    public_photo_sizes: accessRule.public_photo_sizes,
    public_long_form_description: accessRule.public_long_form_description,
    policy: accessRule.policy,
    cancellation_policy: accessRule.cancellation_policy,
    upsell_categories: accessRule.upsell_categories,
    is_using_shift_upsells: accessRule.is_using_shift_upsells,
    reservation_tags: accessRule.reservation_tags,
    selected_automatic_upsells: accessRule.selected_automatic_upsells,
    experience_id: accessRule.experience_id,
    service_charge_type: accessRule.service_charge_type,
    require_credit_card: accessRule.require_credit_card,
    cc_payment_rule: accessRule.cc_payment_rule,
    cost: accessRule.cc_cost,
    apply_service_charge: accessRule.apply_service_charge,
    service_charge: accessRule.service_charge,
    gratuity_type: accessRule.gratuity_type,
    gratuity: accessRule.cc_gratuity,
    require_gratuity_charge: accessRule.require_gratuity_charge,
    apply_gratuity_charge: accessRule.apply_gratuity_charge,
    tax_rate: taxGroup ? Number(taxGroup.tax_rate) : null,
    use_shift_payment_and_policy: accessRule.use_shift_payment_and_policy,
  }
}

export function initAvailabilityTimeslotFromAccessRule(
  availabilityTimeslot: MixedAvailabilityTimeslot,
  internalTimeslot: InternalAvailabilityTimeslot,
  charges?: Charges
) {
  // why it is needed, see: createAvailabilityTimeslotFromAccessRule
  const timeslot: MixedAvailabilityTimeslot = {
    ...availabilityTimeslot,
    time: internalTimeslot.time,
    time_iso: internalTimeslot.time_iso,
    real_datetime_of_slot: internalTimeslot.real_datetime_of_slot,
    timeMoment: moment(internalTimeslot.real_datetime_of_slot),
    isAccessruleOverride: true, // === fake timeslot
  }
  return availabilityTimeslot.use_shift_payment_and_policy ? applyChargesToAvailabilityTimeslot(timeslot, charges) : timeslot
}
