import _ from 'lodash'
import moment from 'moment-timezone'
import { combineReducers } from 'redux'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import experienceWizardReducer from 'mgr/pages/single-venue/marketing/reducers/ExperienceWizard'
import filtersReducer from 'mgr/pages/single-venue/marketing/reducers/Filters'

const initialState = {
  list: {
    draft: [],
    active: [],
    inactive: [],
  },
  experiences: [],
  sizzleClientID: null,
  viewLinkModal: null,
  offersDirLinks: {},
  isLoading: false,
  activeExperience: null,
}

const experiencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_EXPERIENCES_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.LOAD_EXPERIENCES_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.LOAD_EXPERIENCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        experiences: action.data.results.map(e => ({
          ...e,
          offer_type_name: e.offer_type_name,
          offer_type: e.offer_type,
        })),
        sizzleClientID: action.data.sizzle_client_id,
        offersDirLinks: action.data.offers_dir_links,
      }
    case ActionTypes.TOGGLE_EXPERIENCE_LINK_MODAL:
      return {
        ...state,
        viewLinkModal: action.linkModalID || null,
        activeExperience: _.find(state.experiences, { id: action.linkModalID }),
      }
    default:
      return state
  }
}

export default combineReducers({
  experiences: experiencesReducer,
  wizard: experienceWizardReducer,
})
