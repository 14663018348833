import type { ReservationWidget } from '@sevenrooms/core/domain'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Flex, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { calculateTotal, collectTotalCategories, collectTotalUpgrades } from '../payment-utils'
import { paymentMessages } from '../Payment.locales'
import type { PaymentForm } from '../Payment.zod'

interface TaxesAndServicesProps {
  field: Field<PaymentForm>
  taxGroups: ReservationWidget.TaxGroup[]
  currencyCode: string
  override: boolean
}

export function TaxesAndServices({ field, taxGroups, currencyCode, override }: TaxesAndServicesProps) {
  const [amount, charges, categoriesBundled] = useWatchMany(field, ['amount', 'charges', 'categoriesBundled'])
  let total = collectTotalCategories(categoriesBundled, taxGroups, override, true)
  total += amount ? calculateTotal(amount, charges, taxGroups, false, true).total : 0
  return <TaxesAndServicesLine total={total} currencyCode={currencyCode} />
}

interface TaxesAndServicesCategoryProps {
  field: Field<PaymentForm['categories'][number]>
  taxGroups: ReservationWidget.TaxGroup[]
  currencyCode: string
  override: boolean
}

export function TaxesAndServicesCategory({ field, taxGroups, currencyCode, override }: TaxesAndServicesCategoryProps) {
  const [charges, upgrades] = useWatchMany(field, ['charges', 'upgrades'])
  const total = collectTotalUpgrades(upgrades, charges, taxGroups, override, true)
  return <TaxesAndServicesLine total={total} currencyCode={currencyCode} />
}

interface TaxesAndServicesLineProps {
  total: number
  currencyCode: string
}

function TaxesAndServicesLine({ total, currencyCode }: TaxesAndServicesLineProps) {
  const theme = useTheme()
  const { formatMessage } = useLocales()
  return total > 0 ? (
    <HStack height={theme.fieldHeight.m} p="none m" alignItems="center" borderTopColor="dividerLines">
      <Flex flex="auto">
        <Text>{formatMessage(paymentMessages.taxesAndFees)}</Text>
      </Flex>
      <Flex justifyContent="end">
        <Text>{FormatService.formatCurrency(total, currencyCode)}</Text>
      </Flex>
    </HStack>
  ) : null
}
