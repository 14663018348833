import moment from 'moment-timezone'
import React from 'react'
import styled, { css } from 'styled-components'
import DateDropDown from 'mgr/lib/components/DateDropDown'
import { TagElement } from 'mgr/lib/components/Tags'
import TimeDropDown from 'mgr/lib/components/TimeDropDown'
import { MarketingCampaignType, SendTimeDelayType, MarketingCampaignScheduleType } from 'mgr/pages/shared/utils/Constants'
import { MarketingFilter } from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/Filters'
import { formattedTimesByMinuteDuration, TIME_FORMATS } from 'svr/common/TimeUtil'
import RadioSymbol from 'svr/component-lib/Generic/RadioButtons/RadioSymbol'

const Stage = styled.div`
  flex: 1;
  margin: 0 50px;
`
const OngoingStage = styled.div`
  display: flex;
  margin: 0 50px;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: ${props => props.theme.fontSize.subheader};
  text-align: left;
`

const TimesContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: ${props => props.theme.gutter.double};
  font-size: ${props => props.theme.fontSize.medium};
`
const OngoingTimesContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 0;
  font-size: ${props => props.theme.fontSize.medium};
`
const Label = styled.div`
  padding-right: ${props => props.theme.gutter.standard};
`

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: ${props => props.theme.fontSize.medium};

  & > * {
    &:not(:last-child) {
      margin-right: ${props => props.theme.gutter.standard};
    }
  }
`

const LabelText = styled.div`
  height: 18px;
  width: 240px;
  color: ${props => props.theme.color.black};
  font-size: 15px;
  line-height: 18px;
  margin-top: 22px;
`

const OneTimeRadioCard = styled.div`
  cursor: pointer;
  width: 588px;
  border: 1px solid;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  border-color: ${props => (props.isSelected ? props.theme.color.teal : '#DBDCDE')};
`
const OngoingRadioCard = styled.div`
  cursor: pointer;
  border: 1px solid;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  border-color: ${props => (props.isSelected ? props.theme.color.teal : '#DBDCDE')};
  align-items: center;
`

const OneTimeRadioCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0px;
  flex: 1;
`
const OngoingRadioCardBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 12px 0px;
  align-items: center;
`

const OneTimRadioCardLabel = styled.div`
  height: 19px;
  color: ${props => props.theme.color.black};
  font-size: 16px;
  line-height: 19px;
`

const OneTimeRadioCardSubtext = styled.div`
  height: 15px;
  color: #565c63;
  font-size: 13px;
  line-height: 15px;
`

const RadioIconCss = `
  position: relative;
  left: auto;
  top: auto;
  align-self: center;
  margin: 22px 24px;
  width: 22px;
  height: 22px;
`

const RadioIconCssUnchecked = css`
  ${RadioIconCss};
`

const RadioIconCssChecked = css`
  ${RadioIconCss};
  box-shadow: 0 2px 8px 0 #c8ebed;
`

const RadioCheckCss = css`
  width: 14px;
  height: 14px;
  background-color: ${props => props.theme.color.teal};
`

const OneTimeRadioCardAdditional = styled.div`
  margin-left: 70px;
  margin-bottom: 12px;
  width: 100%;
`

const InvalidDateTimeMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  float: left;
`

const DAYS = Array.from({ length: 91 }, (v, k) => ({
  name: k,
  value: k,
}))

const HOURS = [
  { name: 0, value: 0 },
  { name: 0.5, value: 0.5 },
].concat(
  Array.from({ length: 23 }, (v, k) => ({
    name: k + 1,
    value: k + 1,
  }))
)

export const DAYS_OF_WEEK = [
  { name: 'Monday', value: 0 },
  { name: 'Tuesday', value: 1 },
  { name: 'Wednesday', value: 2 },
  { name: 'Thursday', value: 3 },
  { name: 'Friday', value: 4 },
  { name: 'Saturday', value: 5 },
  { name: 'Sunday', value: 6 },
]

export const ONE_TIME_SEND_TIME_TYPE = {
  IMMEDIATELY: 'IMMEDIATELY',
  SCHEDULED: 'SCHEDULED',
}

class SendTime extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      scheduledTimeInvalid: false,
      // eslint-disable-next-line react/no-unused-state
      scheduleType: 'fixed',
    }
    this.props.setStepValidation(this.validateScheduledTime)
    this.customMinDate = moment().locale(this.props.venue.locale)
    this.isMilitary = this.props.venue.locale !== 'en_US'
    this.time_format = this.isMilitary ? TIME_FORMATS.military : TIME_FORMATS.regular
    this.times = formattedTimesByMinuteDuration(15, null, null, this.time_format)
    this.timeSlots = Array.from({ length: this.times.length }, (_, i) => i)
  }

  onScheduleTypeChange = newType => {
    this.props.updateCampaign('schedule_type', newType)
    if (newType === MarketingCampaignScheduleType.ABSOLUTE) {
      this.props.updateCampaign('delay_type', SendTimeDelayType.DAYS)
    }
  }

  validateScheduledTime = () => {
    const { campaign } = this.props
    if (!(campaign.campaign_type === MarketingCampaignType.ONE_TIME && campaign.send_type === ONE_TIME_SEND_TIME_TYPE.SCHEDULED)) {
      return true
    }
    const scheduledMoment = moment(campaign.date)
    const timeMoment = moment(campaign.scheduled_time_of_day, this.time_format)
    scheduledMoment.hour(timeMoment.hour())
    scheduledMoment.minute(timeMoment.minute())
    const scheduledTimeInvalid = scheduledMoment.isBefore(moment().add(15, 'minutes'))
    this.setState({ scheduledTimeInvalid })
    return !scheduledTimeInvalid
  }

  updateOneTimeConfig = (field, value) => {
    if (this.props.campaign.send_type === ONE_TIME_SEND_TIME_TYPE.SCHEDULED) {
      this.validateScheduledTime()
    }
    if (field === 'send_type' && value === this.props.campaign.send_type) {
      return
    } else if (field === 'date') {
      return this.props.updateCampaign(field, value.format('YYYY-MM-DD'))
    }
    return this.props.updateCampaign(field, value)
  }

  inferSelectedSendTime(schedule_type, campaign) {
    if (campaign && campaign.ongoing_send_time_config && !campaign.schedule_type) {
      if (schedule_type === MarketingCampaignScheduleType.RELATIVE && campaign.ongoing_send_time_config.relative_value !== null) {
        this.props.updateCampaign('schedule_type', MarketingCampaignScheduleType.RELATIVE)
      } else if (
        schedule_type === MarketingCampaignScheduleType.ABSOLUTE &&
        campaign.ongoing_send_time_config.delay_type &&
        campaign.ongoing_send_time_config.delay_num !== null &&
        (campaign.id ? campaign.ongoing_send_time_config.time_of_day : campaign.time_of_day)
      ) {
        this.props.updateCampaign('schedule_type', MarketingCampaignScheduleType.ABSOLUTE)
      } else if (
        schedule_type === MarketingCampaignScheduleType.SCHEDULED &&
        campaign.ongoing_send_time_config.scheduled_time_of_day &&
        [0, 1, 2, 3, 4, 5, 6].includes(campaign.ongoing_send_time_config.scheduled_day_of_week)
      ) {
        this.props.updateCampaign('schedule_type', MarketingCampaignScheduleType.SCHEDULED)
      }
    }
  }

  renderOneTimeDisplay = () => {
    const { campaign, venue } = this.props
    const isImmediatelySelected = campaign.send_type === ONE_TIME_SEND_TIME_TYPE.IMMEDIATELY
    const isScheduledSelected = campaign.send_type === ONE_TIME_SEND_TIME_TYPE.SCHEDULED
    const sendTime = campaign.scheduled_time_of_day
    const pickerDate = campaign.date ? moment(campaign.date) : this.customMinDate
    return (
      <Stage>
        <LabelText>When should these emails get sent?</LabelText>
        <OneTimeRadioCard
          isSelected={isImmediatelySelected}
          onClick={this.updateOneTimeConfig.bind(null, 'send_type', ONE_TIME_SEND_TIME_TYPE.IMMEDIATELY)}
        >
          <RadioSymbol
            customRadioIcon={isImmediatelySelected ? RadioIconCssChecked : RadioIconCssUnchecked}
            customRadioCheck={RadioCheckCss}
            isSelected={isImmediatelySelected}
          />
          <OneTimeRadioCardBody>
            <OneTimRadioCardLabel>Immediately</OneTimRadioCardLabel>
            <OneTimeRadioCardSubtext>Emails will be sent out immediately after you finish editing this campaign.</OneTimeRadioCardSubtext>
          </OneTimeRadioCardBody>
        </OneTimeRadioCard>
        <OneTimeRadioCard
          isSelected={isScheduledSelected}
          onClick={this.updateOneTimeConfig.bind(null, 'send_type', ONE_TIME_SEND_TIME_TYPE.SCHEDULED)}
        >
          <RadioSymbol
            customRadioIcon={isScheduledSelected ? RadioIconCssChecked : RadioIconCssUnchecked}
            customRadioCheck={RadioCheckCss}
            isSelected={isScheduledSelected}
          />
          <OneTimeRadioCardBody>
            <OneTimRadioCardLabel>Scheduled</OneTimRadioCardLabel>
            <OneTimeRadioCardSubtext>Set up targeted email campaigns great for filling seats on rainy days.</OneTimeRadioCardSubtext>
          </OneTimeRadioCardBody>
          {isScheduledSelected && (
            <OneTimeRadioCardAdditional>
              <TimeDropDown
                startOfDayHour={0}
                noHeader
                timeSlot={this.times.indexOf(sendTime)}
                timeSlots={this.timeSlots}
                onChangeTime={val => this.updateOneTimeConfig('scheduled_time_of_day', this.times[val])}
                disabled={false}
                showNavigation={false}
                isLightTheme
                format={this.isMilitary ? 'HH:mm' : 'h:mm A'}
                name=""
              />
              <DateDropDown
                date={pickerDate}
                noHeader
                hasMinDate
                customMinDate={this.customMinDate}
                locale={venue.locale}
                onChangeDate={value => this.updateOneTimeConfig('date', value)}
                isLightTheme
              />
              {this.state.scheduledTimeInvalid && (
                <InvalidDateTimeMessage>Scheduled date time must be at least 15 minutes in the future</InvalidDateTimeMessage>
              )}
            </OneTimeRadioCardAdditional>
          )}
        </OneTimeRadioCard>
      </Stage>
    )
  }

  renderOngoingDisplay = () => {
    const { campaign, updateCampaign } = this.props
    const selectedTag = campaign.recipient_client_tags[0]
    const sendTime = campaign.ongoing_send_time_config ? campaign.ongoing_send_time_config.time_of_day : campaign.time_of_day
    const scheduledSendTime = campaign.ongoing_send_time_config
      ? campaign.ongoing_send_time_config.scheduled_time_of_day
      : campaign.scheduled_time_of_day
    const tagDisplay = selectedTag ? selectedTag.tagGroup.tag_name_displays[selectedTag.tagName] || selectedTag.tagName : ''
    const tagColor = selectedTag ? selectedTag.tagGroup.color_hex : '#0abcc2'
    const sendInfo =
      selectedTag == null ? (
        <TagContainer>No recipients have been selected. Campaigns will not send without recipients.</TagContainer>
      ) : (
        <TagContainer>
          <span>After</span>
          <TagElement
            tagObj={{
              tag_name_display: tagDisplay,
              tagColor,
              textColor: '#FFFFFF',
              is_autotag: true,
            }}
          />
          <span>is applied, send email:</span>
        </TagContainer>
      )

    return (
      <OngoingStage>
        <Title>When should these emails get sent?</Title>
        {sendInfo}
        <OngoingRadioCard
          isSelected={
            campaign.schedule_type === MarketingCampaignScheduleType.RELATIVE ||
            this.inferSelectedSendTime(MarketingCampaignScheduleType.RELATIVE, campaign)
          }
          onClick={this.onScheduleTypeChange.bind(null, MarketingCampaignScheduleType.RELATIVE)}
        >
          <RadioSymbol
            customRadioIcon={
              campaign.schedule_type === MarketingCampaignScheduleType.RELATIVE ? RadioIconCssChecked : RadioIconCssUnchecked
            }
            customRadioCheck={RadioCheckCss}
            isSelected={campaign.schedule_type === MarketingCampaignScheduleType.RELATIVE}
          />
          <OngoingRadioCardBody>
            <OngoingTimesContainer>
              <MarketingFilter
                key="RELATIVE_HOURS"
                choices={HOURS}
                value={campaign.relative_value}
                noHeader
                isMultiSelect={false}
                onChangeHandler={val => updateCampaign('relative_value', val)}
                showNavigation={false}
                style={{
                  float: 'left',
                }}
                disabled={false}
                isLightTheme
              />
              <Label>hour(s) after tag is applied</Label>
            </OngoingTimesContainer>
          </OngoingRadioCardBody>
        </OngoingRadioCard>
        <OngoingRadioCard
          isSelected={
            campaign.schedule_type === MarketingCampaignScheduleType.ABSOLUTE ||
            this.inferSelectedSendTime(MarketingCampaignScheduleType.ABSOLUTE, campaign)
          }
          onClick={this.onScheduleTypeChange.bind(null, MarketingCampaignScheduleType.ABSOLUTE)}
        >
          <RadioSymbol
            customRadioIcon={
              campaign.schedule_type === MarketingCampaignScheduleType.ABSOLUTE ? RadioIconCssChecked : RadioIconCssUnchecked
            }
            customRadioCheck={RadioCheckCss}
            isSelected={campaign.schedule_type === MarketingCampaignScheduleType.ABSOLUTE}
          />
          <OngoingRadioCardBody>
            <OngoingTimesContainer>
              <MarketingFilter
                choices={DAYS}
                value={campaign.delay_num}
                style={{ width: 'auto' }}
                onChangeHandler={val => updateCampaign('delay_num', val)}
                noHeader
                showNavigation={false}
              />
              <Label> day(s) after tag is applied, at</Label>
              <TimeDropDown
                startOfDayHour={0}
                noHeader
                timeSlot={this.times.indexOf(campaign.time_of_day)}
                timeSlots={this.timeSlots}
                onChangeTime={val => updateCampaign('time_of_day', this.times[val])}
                disabled={false}
                showNavigation={false}
                isLightTheme
                format={this.isMilitary ? 'HH:mm' : 'h:mm A'}
                name=""
              />
            </OngoingTimesContainer>
          </OngoingRadioCardBody>
        </OngoingRadioCard>
        <OngoingRadioCard
          isSelected={
            campaign.schedule_type === MarketingCampaignScheduleType.SCHEDULED ||
            this.inferSelectedSendTime(MarketingCampaignScheduleType.SCHEDULED, campaign)
          }
          onClick={this.onScheduleTypeChange.bind(null, MarketingCampaignScheduleType.SCHEDULED)}
        >
          <RadioSymbol
            customRadioIcon={
              campaign.schedule_type === MarketingCampaignScheduleType.SCHEDULED ? RadioIconCssChecked : RadioIconCssUnchecked
            }
            customRadioCheck={RadioCheckCss}
            isSelected={campaign.schedule_type === MarketingCampaignScheduleType.SCHEDULED}
          />
          <OngoingRadioCardBody>
            <OngoingTimesContainer>
              <Label>Every</Label>
              <MarketingFilter
                choices={DAYS_OF_WEEK}
                value={campaign.scheduled_day_of_week}
                style={{ width: 'auto' }}
                onChangeHandler={val => updateCampaign('scheduled_day_of_week', val)}
                noHeader
                showNavigation={false}
                isMultiSelect={false}
                disabled={false}
                isLightTheme
              />
              <Label> at </Label>
              <TimeDropDown
                startOfDayHour={0}
                noHeader
                timeSlot={this.times.indexOf(campaign.scheduled_time_of_day)}
                timeSlots={this.timeSlots}
                onChangeTime={val => updateCampaign('scheduled_time_of_day', this.times[val])}
                disabled={false}
                showNavigation={false}
                isLightTheme
                format={this.isMilitary ? 'HH:mm' : 'h:mm A'}
                name=""
              />
            </OngoingTimesContainer>
          </OngoingRadioCardBody>
        </OngoingRadioCard>
      </OngoingStage>
    )
  }

  // eslint-disable-next-line react/require-render-return
  render = () => (this.props.isOneTimeCampaign ? this.renderOneTimeDisplay() : this.renderOngoingDisplay())
}
export default SendTime
