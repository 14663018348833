import _ from 'lodash'
import type { CampaignOneTimeSendTimeConfig, CampaignOngoingSendTimeConfig } from '@sevenrooms/core/api'
import {
  type MarketingCampaignOneTimeSendTypeEnum,
  MarketingCampaignScheduleTypeEnum,
  type MarketingCampaignScheduleType,
} from '@sevenrooms/core/domain'
import type { TagOption } from '@sevenrooms/core/ui-kit/form'

export function packOneTimeSendConfig(formData: {
  scheduleType: MarketingCampaignOneTimeSendTypeEnum
  scheduledDay?: Date | null | string
  scheduledTime?: string | null
  recipientAutotags?: TagOption[]
}): CampaignOneTimeSendTimeConfig {
  return {
    sendType: formData.scheduleType,
    date: _.isDate(formData.scheduledDay) ? formData.scheduledDay?.toDateString() : formData.scheduledDay,
    timeOfDay: formData.scheduledTime,
    queryParams: { anyClientTags: true, filterClientTags: formData.recipientAutotags?.map(recipientAutotag => recipientAutotag.id) },
  }
}

export function packSendTimeConfig({
  scheduleType,
  relativeValue,
  scheduledDay,
  scheduledTime,
  absoluteTime,
  absoluteDays,
}: {
  scheduleType: MarketingCampaignScheduleType
  relativeValue?: string | null
  scheduledDay?: string | null
  scheduledTime?: string | null
  absoluteTime?: string | null
  absoluteDays?: string | null
}): CampaignOngoingSendTimeConfig {
  let config
  if (scheduleType === MarketingCampaignScheduleTypeEnum.RELATIVE) {
    config = {
      scheduleType: MarketingCampaignScheduleTypeEnum.RELATIVE,
      relativeValue: Number(relativeValue || '1'),
    }
  } else if (scheduleType === MarketingCampaignScheduleTypeEnum.SCHEDULED) {
    config = {
      scheduleType: MarketingCampaignScheduleTypeEnum.SCHEDULED,
      scheduledDayOfWeek: parseInt(scheduledDay || '0'),
      scheduledTimeOfDay: scheduledTime,
    }
  } else {
    config = {
      scheduleType: MarketingCampaignScheduleTypeEnum.ABSOLUTE,
      timeOfDay: absoluteTime,
      delayNum: parseInt(absoluteDays || '1'),
      delayType: 'DAYS',
    }
  }
  return config
}
