import React from 'react'
import ReactTooltip from 'react-tooltip'
import { FlexRowListContainer, FlexHorizontalUlElement } from 'mgr/actualslideout/components/view/ViewLayout'
import { MailLink, TelephoneLink } from 'mgr/lib/components/Link'
import { customIcon, StyledVmsIconS, VmsIcons } from 'svr/common/VmsIcons'
import { makeInitials } from './MessageCommon'
import { FollowerBadge, FollowerBookedByContainer, FollowerBookedbyNameLabel, LegacyPaddedP } from './ViewCommon'

const NonListFollowerBadge = React.forwardRef((props, ref) => <FollowerBadge {...props} as="div" ref={ref} />)
const BookedByEntry = ({ bbEntry }) => {
  const initials = makeInitials(bbEntry.full_name)
  return (
    <FlexRowListContainer>
      <NonListFollowerBadge data-tip={bbEntry.full_name}>
        {initials}
        <ReactTooltip />
      </NonListFollowerBadge>
      <LegacyPaddedP>{bbEntry.full_name}</LegacyPaddedP>
    </FlexRowListContainer>
  )
}

const BookedByArea = ({ visibleActual, canEditReservation, selectedBookedByName, altBookedByNames, actions }) => {
  const firstBbNameOfAdditional =
    altBookedByNames.length > 0 ? <BookedByEntry key={`${altBookedByNames[0].key}_extra`} bbEntry={altBookedByNames[0]} /> : null
  const extraCount = altBookedByNames.length - 1
  const extraCountString = ` ${extraCount} more`
  const extraCountElement =
    extraCount > 0 ? (
      <FlexRowListContainer key="extra_count">
        <NonListFollowerBadge data-tip={extraCountString}>
          {`+${extraCount}`}
          <ReactTooltip />
        </NonListFollowerBadge>
        <LegacyPaddedP>{extraCountString}</LegacyPaddedP>
      </FlexRowListContainer>
    ) : null
  return (
    visibleActual &&
    selectedBookedByName && (
      <FollowerBookedByContainer
        onClick={() =>
          canEditReservation &&
          actions.enterEditReservation({
            actual: visibleActual,
            editStep: 'details',
          })
        }
      >
        <FollowerBookedbyNameLabel>BOOKED BY</FollowerBookedbyNameLabel>
        <FlexHorizontalUlElement>
          <BookedByEntry key={`${selectedBookedByName.key}_default`} bbEntry={selectedBookedByName} />
          {firstBbNameOfAdditional}
          {extraCountElement}
        </FlexHorizontalUlElement>
      </FollowerBookedByContainer>
    )
  )
}

export default BookedByArea
