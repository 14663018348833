import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import type { OrderingMenusLisAction } from 'mgr/pages/single-venue/settings/actions/ordering/OrderingMenuManagement'
import type { OrderingMenu } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'

export interface OrderingMenusListState {
  isLoading: boolean
  menusList: OrderingMenu[]
}

const initialState: OrderingMenusListState = {
  isLoading: false,
  menusList: [],
}

const orderingMenusListReducer = (state = initialState, action: OrderingMenusLisAction) => {
  switch (action.type) {
    case ActionTypes.GET_ORDERING_MENUS_LIST_START:
      return {
        ...state,
        menusList: [],
        isLoading: true,
      }
    case ActionTypes.GET_ORDERING_MENUS_LIST_FAIL:
    case ActionTypes.REMOVE_MENU_SUCCESS:
    case ActionTypes.REMOVE_MENU_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.GET_ORDERING_MENUS_LIST_SUCCESS:
      return {
        ...state,
        menusList: action.data,
        isLoading: false,
      }
    case ActionTypes.REMOVE_MENU_START:
      return {
        ...state,
        isLoading: true,
      }
    default:
      return state
  }
}

export default orderingMenusListReducer
