import { SHOW_CONFLICT_DIALOG, CLOSE_CONFLICT_DIALOG } from 'mgr/pages/single-venue/dayview/actions/ActionTypes'

const initialConflictDialogState = {
  isOpen: false,
  explanation: '',
  actionButtonText: '',
  conflictActual: null,
  refreshActualIdCount: {},
  closeAction: {},
  nextAction: {},
}

export const conflictDialogReducer = (state = initialConflictDialogState, action) => {
  switch (action.type) {
    case SHOW_CONFLICT_DIALOG:
      return {
        ...state,
        isOpen: true,
        explanation: action.explanation,
        actionButtonText: action.actionButtonText,
        conflictActual: action.conflictActual,
        closeAction: action.closeAction,
        nextAction: action.nextAction,
      }
    case CLOSE_CONFLICT_DIALOG:
      const newRefreshActualIdCount = { ...state, ...state.refreshActualIdCount }
      if (action.refreshActualId) {
        if (!(action.refreshActualId in newRefreshActualIdCount)) {
          newRefreshActualIdCount[action.refreshActualId] = 0
        }
        newRefreshActualIdCount[action.refreshActualId] += 1
      }
      return {
        ...state,
        isOpen: false,
        explanation: '',
        actionButtonText: '',
        conflictActual: null,
        refreshActualIdCount: newRefreshActualIdCount,
        closeAction: {},
        nextAction: {},
      }
    default:
      return state
  }
}
