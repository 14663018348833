/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from 'react'
import { Provider } from 'react-redux'
import { FormRoot } from '@sevenrooms/core/form'
import { CurrencyLocale } from '@sevenrooms/core/locales'
import { Router } from '@sevenrooms/core/navigation'
import { TimeLocale } from '@sevenrooms/core/timepiece'
import { Root } from '@sevenrooms/core/ui-kit/layout'

export function AppRoot({ children }: React.PropsWithChildren<unknown>) {
  React.useEffect(() => {
    // group portal does not have .venueLocale
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const locale = (window as any).globalInit.venueLocale?.replace('_', '-')

    if (locale) {
      CurrencyLocale.setLocale(locale)
      TimeLocale.setLocale(locale)
    }
  }, [])

  return (
    <Router>
      <Provider store={require('../../../../application/site/static/app/manager/pages/src/reduxStore').store}>
        <Root theme="vx">
          <FormRoot>{children}</FormRoot>
        </Root>
      </Provider>
    </Router>
  )
}
