export const GC_IMPORT_LOAD_ACTIVITY_START = 'GC_IMPORT_LOAD_ACTIVITY_START'
export const GC_IMPORT_LOAD_ACTIVITY_SUCCESS = 'GC_IMPORT_LOAD_ACTIVITY_SUCCESS'
export const GC_IMPORT_LOAD_ACTIVITY_FAIL = 'GC_IMPORT_LOAD_ACTIVITY_FAIL'

export const GC_IMPORT_UPLOAD_RESERVATIONS_START = 'GC_IMPORT_UPLOAD_RESERVATIONS_START'
export const GC_IMPORT_UPLOAD_RESERVATIONS_SUCCESS = 'GC_IMPORT_UPLOAD_RESERVATIONS_SUCCESS'
export const GC_IMPORT_UPLOAD_RESERVATIONS_FAIL = 'GC_IMPORT_UPLOAD_RESERVATIONS_FAIL'

export const GC_IMPORT_CHANGE_HARD_ASSIGN_TABLES = 'GC_IMPORT_CHANGE_HARD_ASSIGN_TABLES'
export const GC_IMPORT_CHANGE_IGNORE_LAST_UPDATED = 'GC_IMPORT_CHANGE_IGNORE_LAST_UPDATED'

export const RESY_IMPORT_UPLOAD_RESERVATIONS_START = 'RESY_IMPORT_UPLOAD_RESERVATIONS_START'
export const RESY_IMPORT_UPLOAD_RESERVATIONS_SUCCESS = 'RESY_IMPORT_UPLOAD_RESERVATIONS_SUCCESS'
export const RESY_IMPORT_UPLOAD_RESERVATIONS_FAIL = 'RESY_IMPORT_UPLOAD_RESERVATIONS_FAIL'

export const LOAD_OUTGOING_EMAILS_START = 'LOAD_OUTGOING_EMAILS_START'
export const LOAD_OUTGOING_EMAILS_SUCCESS = 'LOAD_OUTGOING_EMAILS_SUCCESS'
export const LOAD_OUTGOING_EMAILS_FAIL = 'LOAD_OUTGOING_EMAILS_FAIL'
export const OPEN_OUTGOING_EMAIL = 'OPEN_OUTGOING_EMAIL'

export const OUTGOING_EMAILS_DATE_FILTER_CHANGE = 'OUTGOING_EMAILS_DATE_FILTER_CHANGE'
export const OUTGOING_EMAILS_CUSTOM_RANGE_CHANGE = 'OUTGOING_EMAILS_CUSTOM_RANGE_CHANGE'
export const OUTGOING_EMAILS_SEARCH_FILTER_CHANGE = 'OUTGOING_EMAILS_SEARCH_FILTER_CHANGE'
export const OUTGOING_EMAILS_EMAIL_TYPE_FILTER_CHANGE = 'OUTGOING_EMAILS_EMAIL_TYPE_FILTER_CHANGE'
export const OUTGOING_EMAILS_PAGINATION_CHANGE = 'OUTGOING_EMAILS_PAGINATION_CHANGE'
