import { useMemo, useState, type FormEvent } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { startOfYear } from '@sevenrooms/core/timepiece'
import { Select, type SelectOption, SelectTarget, SelectFilter } from '@sevenrooms/core/ui-kit/core'
import { MultiSelect, DateRangePicker, DatePickerUtils } from '@sevenrooms/core/ui-kit/form'
import { useScreenWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages } from '../../locales'
import type { CampaignPerformanceFiltersProps, PerformanceContextFilterPropsWithSearch } from '../../typings'

interface OneTimeCampaignPerformanceFiltersProps extends CampaignPerformanceFiltersProps {
  usePerformanceContext: () => PerformanceContextFilterPropsWithSearch
}

const LARGE_SIZES: (string | undefined)[] = ['l', 'xl']

export function OneTimeCampaignPerformanceFilters({ statuses, messages, usePerformanceContext }: OneTimeCampaignPerformanceFiltersProps) {
  const { formatMessage } = useLocales()
  const [isAllSelected, setIsAllSelected] = useState(true)
  const { breakpoint } = useScreenWidthBreakpoint()
  const isDesktop = LARGE_SIZES.includes(breakpoint)
  const {
    dateFilter,
    startDate,
    endDate,
    setDateFilter,
    setStartDate,
    setEndDate,
    dateFilters,
    setCampaignStatus,
    searchValue,
    setSearchValue,
  } = usePerformanceContext()

  const onDateFilterSelect = (value: string | null) => {
    const option = Object.values(dateFilters).find(o => o.id === value) ?? null

    if (!option) {
      return
    }

    const { startDate, endDate } = option.convert()

    if (startDate && endDate) {
      setStartDate(startDate)
      setEndDate(endDate)
    }
    setDateFilter(option)
    if (option.id === 'all') {
      setIsAllSelected(true)
      setStartDate(startOfYear(new Date(2000)))
      setEndDate(new Date())
    } else {
      setIsAllSelected(false)
    }
  }

  const onStartDateChange = (date: Date | null) => {
    if (date !== null) {
      setStartDate(date)
      setDateFilter(dateFilters.custom)
    }
  }

  const onEndDateChange = (date: Date | 'infinite' | null) => {
    if (date !== null) {
      setEndDate(date as Date)
      setDateFilter(dateFilters.custom)
    }
  }

  const statusFilters: SelectOption[] = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => statuses.map(status => ({ id: status, label: formatMessage(messages[status.toLowerCase()]) })),
    [formatMessage, statuses, messages]
  )

  const statusIds = useMemo(() => statusFilters.map(({ id }) => id), [statusFilters])

  const renderDateFilterContent = () => (
    <>
      <Select
        data-test="one-time-emails-campaign-date-filter"
        options={Object.values(dateFilters)}
        searchable={false}
        onChange={onDateFilterSelect}
        value={dateFilter?.id || dateFilters.all.id}
        scrollLock
      />

      {!isAllSelected && (
        <DateRangePicker
          data-test="one-time-emails-campaign-custom-date-filter"
          id="campaign-performance-filters"
          startDate={startDate}
          endDate={endDate}
          startDateProps={{
            isOutsideRange: endDate === 'infinite' ? undefined : DatePickerUtils.isAfterFn(endDate),
          }}
          endDateProps={{
            isOutsideRange: date => DatePickerUtils.isAfterCurrentFn()(date) || DatePickerUtils.isBeforeFn(startDate)(date),
          }}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
          showClearDate={false}
        />
      )}
    </>
  )

  return (
    <VStack spacing="m">
      <HStack spacing="s">
        <Box width="320px">
          <SelectTarget>
            <SelectFilter
              data-test="one-time-emails-campaign-filter"
              placeholder={formatMessage(campaignCenterMessages.oneTimeCampaignTableCampaignFilterPlaceholder)}
              selectedValue={searchValue || undefined}
              searchable
              onChange={(event: FormEvent<HTMLInputElement>) => {
                setSearchValue(event.currentTarget.value)
              }}
            />
          </SelectTarget>
        </Box>
        <Box width="220px">
          <MultiSelect
            data-test="one-time-emails-campaign-status-filter"
            options={statusFilters}
            useCheckboxItems
            usePills={false}
            displayAsTextWhenCollapsed
            onChange={options => setCampaignStatus(options.length > 0 ? options : statusIds)}
            scrollLock
            showMoreLabel=""
            placeholder={formatMessage(campaignCenterMessages.oneTimeCampaignTableStatusPlaceholder)}
          />
        </Box>
        {(isDesktop || isAllSelected) && renderDateFilterContent()}
      </HStack>
      {!isDesktop && !isAllSelected && <HStack spacing="s">{renderDateFilterContent()}</HStack>}
    </VStack>
  )
}
