import _ from 'lodash'
import * as ActionTypes from 'mgr/pages/venue-group/actions/ActionTypes'
import { VmsIcons } from 'svr/common/VmsIcons'

const groupPortalChoices = [
  {
    name: 'All',
    value: 'All',
  },
  {
    name: 'Yes',
    value: 'Yes',
  },
  {
    name: 'No',
    value: 'No',
  },
]

const venueGroupPodsRadioChoices = [
  {
    name: 'Any',
    value: 'Any',
  },
  {
    name: 'Primary',
    value: 'Primary',
  },
]

const defaultFilterValuesState = {
  userSearchQuery: '',
  roleFilterValues: ['All'],
  venuePodsFilterValues: [],
  groupPortalFilterValue: 'All',
  venuePodsRadioFilterValue: 'Any',
}

export const initialState = {
  users: [],
  vmsAccessUsers: [],
  venues: null,
  pods: null,
  formattedVenuePodsChoices: [],
  formattedFilterVenuePodsChoices: [],
  firstNameValid: true,
  lastNameValid: true,
  emailValid: true,
  selectedUserIsLocked: false,
  selectedUserId: '',
  selectedUserFirstName: '',
  selectedUserLastName: '',
  selectedUserEmail: '',
  selectedUserPrimaryAccessId: null,
  selectedUserPrimaryAccessRoleId: '',
  selectedUserPrimaryAccessEntities: [],
  selectedUserAdtlAccesses: [],
  f_user_admin: false,
  f_role_admin: false,
  f_administrate_pods: false,
  f_administrate_global_reservation_tags: false,
  f_administrate_global_client_tags: false,
  f_administrate_pod_reservation_tags: false,
  f_administrate_pod_client_tags: false,
  f_administrate_venue_group_user: false,
  f_administrate_venue_group_user_permission: false,
  has_access_group_reporting: false,
  isUserUpdating: false,
  totalNumUsers: 0,
  cursor: 0,
  maxUsersPerPage: 10,
  showAdtlAccessModal: false,
  selectedUserFullName: '',
  excludeUserIds: [],
  rolesToAccessList: [],
  usersListIsLoading: false,
  ...defaultFilterValuesState,
  groupPortalChoices,
  venueGroupPodsRadioChoices,
  showWarning: false,
  showAccessDefinitionModal: false,
}

const toggleSelectFromGroup = (selectedValues, val) => {
  let newSelectedValues = selectedValues
  if (!selectedValues.includes(val)) {
    newSelectedValues.push(val)
  } else {
    newSelectedValues = newSelectedValues.filter(selectedVal => selectedVal !== val)
  }
  return newSelectedValues
}

const getAccessEntities = (vmsAccessUser, venueGroupId) => {
  const accessEntities = [...vmsAccessUser.pod_ids, ...vmsAccessUser.venue_ids]
  if (vmsAccessUser.is_venue_group_level_user) {
    accessEntities.push(venueGroupId)
  }
  return accessEntities
}

const formatSearchGroup = (labelName, groupIcon, choices) => ({
  label: labelName,
  icon: groupIcon,
  choices,
})

const formatVenuePodsSearchChoices = (venues, podCategoriesMapById, venueGroup, venueGroupAccess) => {
  const venuePodsSearchChoices = []
  const podSearchGroups = []

  if (venueGroupAccess) {
    venuePodsSearchChoices.push(
      formatSearchGroup('Group', VmsIcons.Company, [
        {
          name: venueGroup.name,
          value: venueGroup.id,
          disabled: false,
        },
      ])
    )
  }

  _.forOwn(podCategoriesMapById, value => {
    podSearchGroups.push(
      formatSearchGroup(
        value.name,
        VmsIcons.SiteMap,
        _.map(value.pods, pod => ({
          name: pod.name,
          value: pod.id,
          disabled: false,
        }))
      )
    )
  })
  podSearchGroups.sort((a, b) => (a.label > b.label ? 1 : -1))
  venuePodsSearchChoices.push(...podSearchGroups)
  venuePodsSearchChoices.push(
    formatSearchGroup(
      'Venue',
      VmsIcons.StoreFront,
      _.map(venues, venue => ({
        name: venue.internal_name ?? venue.name,
        value: venue.id,
        disabled: false,
      }))
    )
  )
  return venuePodsSearchChoices
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.USER_ACCOUNTS_TEXT_FIELD_CHANGE:
      return {
        ...state,
        [action.field]: action.val,
      }
    case ActionTypes.USER_ACCOUNTS_OPTIONS_CHANGE:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          ...action.val,
        },
      }
    case ActionTypes.USER_ACCOUNTS_SET_GROUP_PERMISSION:
      return {
        ...state,
        ...action.values,
      }
    case ActionTypes.USER_ACCOUNTS_PRIMARY_ACCESS_CHANGE:
      return {
        ...state,
        selectedUserPrimaryAccessRoleId: action.val,
      }
    case ActionTypes.USER_ACCOUNTS_ADTL_ACCESS_CHANGE:
      const newSelectedAdtlAccesses = state.selectedUserAdtlAccesses
      if (newSelectedAdtlAccesses[action.idx].roleId === 'CUSTOM') {
        newSelectedAdtlAccesses[action.idx].id = null
      }
      newSelectedAdtlAccesses[action.idx].roleId = action.val
      return {
        ...state,
        selectedUserAdtlAccesses: newSelectedAdtlAccesses,
      }
    case ActionTypes.USER_ACCOUNTS_ROLE_ENTITIES_CHANGE:
      const primaryAccessEntities = toggleSelectFromGroup(state.selectedUserPrimaryAccessEntities, action.val)
      return {
        ...state,
        selectedUserPrimaryAccessEntities: primaryAccessEntities,
      }
    case ActionTypes.USER_ACCOUNTS_ADTL_ACCESS_ROLE_ENTITIES_CHANGE:
      const newSelectedAdtlAccessEntities = state.selectedUserAdtlAccesses
      const adtlAccessEntities = toggleSelectFromGroup(newSelectedAdtlAccessEntities[action.idx].accessEntities, action.val)
      newSelectedAdtlAccessEntities[action.idx].accessEntities = adtlAccessEntities
      return {
        ...state,
        selectedUserAdtlAccesses: newSelectedAdtlAccessEntities,
      }
    case ActionTypes.USER_ACCOUNTS_ADD_ADDITIONAL_ACCESS:
      return {
        ...state,
        selectedUserAdtlAccesses: [...state.selectedUserAdtlAccesses, { id: null, roleId: '', accessEntities: [] }],
      }
    case ActionTypes.USER_ACCOUNTS_DELETE_PRIMARY_ACCESS:
      return {
        ...state,
        selectedUserPrimaryAccessId: null,
        selectedUserPrimaryAccessRoleId: '',
        selectedUserPrimaryAccessEntities: [],
      }
    case ActionTypes.USER_ACCOUNTS_DELETE_ACCESS:
      const postDeleteAdtlAccesses = state.selectedUserAdtlAccesses
      postDeleteAdtlAccesses.splice(action.idx, 1)
      return {
        ...state,
        selectedUserAdtlAccesses: postDeleteAdtlAccesses,
      }
    case ActionTypes.INVALIDATE_USER_FIELDS:
      return {
        ...state,
        ...action.userFields,
      }
    case ActionTypes.LOAD_VENUE_PODS_SUCCESS:
      const venues = _.orderBy(action.venues, ['name'])
      const formattedVenuePodsChoices = formatVenuePodsSearchChoices(
        venues,
        action.podCategoriesMapById,
        action.venueGroup,
        action.venueGroupAccess
      )
      const pods = _.flatten(Object.values(action.podCategoriesMapById).map(podCategory => podCategory.pods))
      return {
        ...state,
        venues: action.venues,
        pods,
        formattedVenuePodsChoices,
        formattedFilterVenuePodsChoices: formattedVenuePodsChoices,
      }
    case ActionTypes.TRY_LOAD_USER_SUCCESS:
      let primaryRoleId = ''
      let primaryUserAccessEntities = []
      const venueRoleToVenueUsers = {}
      _.map(action.venue_users, venueUser => {
        if (!(venueUser.role_name in venueRoleToVenueUsers)) {
          venueRoleToVenueUsers[venueUser.role_name] = []
        }
        venueRoleToVenueUsers[venueUser.role_name].push(venueUser)
      })
      const adtlAccesses = _.map(Object.keys(venueRoleToVenueUsers), roleName => ({
        id: roleName,
        roleId: 'CUSTOM',
        roleName,
        accessEntities: _.map(venueRoleToVenueUsers[roleName], venueUser => venueUser.venue_id),
      }))
      _.forEach(action.vms_access_users, vmsAccessUser => {
        if (vmsAccessUser.id === action.primary_vms_access_user_id) {
          primaryRoleId = vmsAccessUser.vms_role_template_id
          primaryUserAccessEntities = getAccessEntities(vmsAccessUser, action.venue_group_id)
        } else {
          adtlAccesses.push({
            id: vmsAccessUser.id,
            roleId: vmsAccessUser.vms_role_template_id,
            accessEntities: getAccessEntities(vmsAccessUser, action.venue_group_id),
          })
        }
      })
      return {
        ...state,
        selectedUser: action.selectedUser,
        selectedUserId: action.user_id,
        selectedUserIsLocked: action.is_locked,
        selectedUserFirstName: action.first_name,
        selectedUserLastName: action.last_name,
        selectedUserEmail: action.email,
        selectedUserPrimaryAccessId: action.primary_vms_access_user_id,
        selectedUserPrimaryAccessRoleId: primaryRoleId,
        selectedUserPrimaryAccessEntities: primaryUserAccessEntities,
        selectedUserAdtlAccesses: adtlAccesses,
        f_user_admin: action.f_user_admin,
        f_role_admin: action.f_role_admin,
        f_administrate_pods: action.f_administrate_pods,
        f_administrate_global_reservation_tags: action.f_administrate_global_reservation_tags,
        f_administrate_global_client_tags: action.f_administrate_global_client_tags,
        f_administrate_pod_reservation_tags: action.f_administrate_pod_reservation_tags,
        f_administrate_pod_client_tags: action.f_administrate_pod_client_tags,
        f_administrate_venue_group_user: action.f_administrate_venue_group_user,
        f_administrate_venue_group_user_permission: action.f_administrate_venue_group_user_permission,
        has_access_group_reporting: action.has_access_group_reporting,
      }
    case ActionTypes.TRY_CREATE_USER_START:
      return {
        ...state,
        isUserUpdating: true,
      }
    case ActionTypes.TRY_CREATE_USER_FAIL:
      return {
        ...state,
        isUserUpdating: false,
      }
    case ActionTypes.TRY_FETCH_USERS_START:
      return {
        ...state,
        usersListIsLoading: true,
      }
    case ActionTypes.TRY_FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        cursor: parseInt(action.cursor, 10),
        totalNumUsers: action.totalNumUsers,
        usersListIsLoading: false,
      }
    case ActionTypes.RESET_USER_STATE:
      return {
        ...initialState,
        selectedUserPrimaryAccessEntities: [],
        selectedUserAdtlAccesses: [],
        venues: state.venues,
        pods: state.pods,
        formattedVenuePodsChoices: state.formattedVenuePodsChoices,
        formattedFilterVenuePodsChoices: state.formattedFilterVenuePodsChoices,
        selectedUser: {
          isMfaEnabled: true,
          isSuspended: false,
          emailAlertsEnabled: false,
          lastLogin: null,
          lastPwReset: null,
        },
      }
    case ActionTypes.USER_SEARCH_QUERY_CHANGE:
      return {
        ...state,
        userSearchQuery: action.value,
      }
    case ActionTypes.ROLE_FILTER_CHANGE:
      return {
        ...state,
        roleFilterValues: action.values,
      }
    case ActionTypes.VENUE_PODS_GROUP_RADIO_CHANGE:
      return {
        ...state,
        venuePodsRadioFilterValue: action.value,
      }
    case ActionTypes.VENUE_PODS_GROUP_FILTER_CHANGE:
      let newVenuePodsFilterValues = state.venuePodsFilterValues.slice(0)
      if (newVenuePodsFilterValues.includes(action.venueGroupId) && action.value !== action.venueGroupId) {
        newVenuePodsFilterValues = []
      }
      if (!newVenuePodsFilterValues.includes(action.venueGroupId) && action.value === action.venueGroupId) {
        newVenuePodsFilterValues = []
      }
      if (!newVenuePodsFilterValues.includes(action.value)) {
        newVenuePodsFilterValues.push(action.value)
      } else {
        newVenuePodsFilterValues = newVenuePodsFilterValues.filter(selectedVal => selectedVal !== action.value)
      }
      return {
        ...state,
        venuePodsFilterValues: newVenuePodsFilterValues,
      }
    case ActionTypes.GROUP_PORTAL_ACCESS_FILTER_CHANGE:
      return {
        ...state,
        groupPortalFilterValue: action.value,
      }
    case ActionTypes.DISPLAY_ADTL_ACCESS_MODAL:
      return {
        ...state,
        showAdtlAccessModal: true,
        selectedUserFullName: action.userFullName,
        rolesToAccessList: action.rolesToAccessList,
      }
    case ActionTypes.CLOSE_ADTL_ACCESS_MODAL:
      return {
        ...state,
        showAdtlAccessModal: false,
        selectedUserFullName: '',
        rolesToAccessList: [],
      }
    case ActionTypes.DISPLAY_ACCESS_DEFINITION_MODAL:
      return {
        ...state,
        showAccessDefinitionModal: true,
        roleId: action.roleId,
        vmsRoleTemplates: action.vmsRoleTemplates,
      }
    case ActionTypes.CLOSE_ACCESS_DEFINITION_MODAL:
      return {
        ...state,
        showAccessDefinitionModal: false,
      }
    case ActionTypes.TRY_CREATE_USER_SUCCESS:
    case ActionTypes.LOCK_USER_SUCCESS:
      return {
        ...state,
        ...defaultFilterValuesState,
        isUserUpdating: false,
      }
    case ActionTypes.DELETE_USER_SUCCESS:
      const newExcludeUserIds = state.excludeUserIds
      newExcludeUserIds.push(action.userId)
      return {
        ...state,
        ...defaultFilterValuesState,
        excludedUserIds: newExcludeUserIds,
      }
    case ActionTypes.TOGGLE_DELETE_WARNING:
      return {
        ...state,
        showWarning: action.showWarning,
        selectedUserId: action.selectedUserId ?? state.selectedUserId,
      }
    default:
      return state
  }
}

export default usersReducer
