import { defineMessages } from '@sevenrooms/core/locales'

export const smsBuilderMessages = defineMessages({
  campaignNamePlaceholder: {
    id: 'smsBuilder.campaignNamePlaceholder',
    defaultMessage: 'Enter name',
  },
  createSMSCampaign: {
    id: 'smsBuilderLayout.createSMSCampaign',
    defaultMessage: 'Create Text Campaign',
  },
  editSMSCampaign: {
    id: 'smsBuilderLayout.editSMSCampaign',
    defaultMessage: 'Edit Text Campaign',
  },
  viewSMSCampaign: {
    id: 'smsBuilderLayout.viewSMSCampaign',
    defaultMessage: 'View Text Campaign',
  },
  duplicate: {
    id: 'smsBuilderLayout.duplicate',
    defaultMessage: 'Duplicate',
  },
  createAutomatedSMSCampaign: {
    id: 'smsBuilderLayout.createAutomatedSMSCampaign',
    defaultMessage: 'Create Automated Text Campaign',
  },
  editAutomatedSMSCampaign: {
    id: 'smsBuilderLayout.editAutomatedSMSCampaign',
    defaultMessage: 'Edit Automated Text Campaign',
  },
  smsBuilderFromScratchTitle: {
    id: 'smsBuilderLayout.title',
    defaultMessage: 'From Scratch / HTML',
  },
  smsBuilderFromTemplateTitle: {
    id: 'smsBuilderLayout.title',
    defaultMessage: 'From Template',
  },
  smsBuilderNewFromScratchTitle: {
    id: 'smsBuilderLayout.title',
    defaultMessage: 'New Automated Text - From Scratch',
  },
  smsBuilderNewFromTemplateTitle: {
    id: 'smsBuilderLayout.title',
    defaultMessage: 'New Automated Text - From Template',
  },
  audienceDescription: {
    id: 'smsBuilderAudience.title',
    defaultMessage: 'Audience',
  },
  audienceSizeBannerDescriptionCalculated: {
    id: 'audienceSizeBannerDescriptionCalculated',
    defaultMessage: 'You are sending this text to approximately ',
  },
  smsContentTitle: {
    id: 'smsBuilderSMSContent.title',
    defaultMessage: 'Text Content',
  },
  smsDetailsTitle: {
    id: 'smsBuilderSMSContent.title',
    defaultMessage: 'Text Details',
  },
  smsName: {
    id: 'smsCenter.campaignName',
    defaultMessage: 'Name of text campaign',
  },
  campaignSentTo: {
    id: 'smsBuilderAudience.campaignSentTo',
    defaultMessage: 'Who would you like to send this campaign to?',
  },
  excludeText: {
    id: 'smsBuilderAudience.exclude',
    defaultMessage: 'Is there anyone you would like to exclude? (optional)',
  },
  sendingScheduleDescription: {
    id: 'smsBuilderSendingSchedule.description',
    defaultMessage: 'Choose when to send the text campaign.',
  },
  designYourSMSContent: {
    id: 'smsBuilder.designYourSMSContent',
    defaultMessage: 'Design Your Text Content',
  },
  smsCampaignPreviewTitle: {
    id: 'smsBuilderSMSCampaignPreview.title',
    defaultMessage: 'Preview Example',
  },
  smsCampaignPreviewDescription: {
    id: 'smsBuilder.smsCampaignPreviewDescription',
    defaultMessage: "The preview box shows how your content will look on your guest's phone.",
  },
  audienceDescriptionDetails: {
    id: 'smsBuilder.audienceDescriptionDetails',
    defaultMessage:
      'The audience will automatically be filtered so this campaign will only send to guests who have explicitly opted-in to Text Marketing.',
  },
  adminAudienceDescription: {
    id: 'smsBuilder.adminAudienceDescription',
    defaultMessage: 'Who and where should these texts be sent?',
  },
  adminCampaignSentTo: {
    id: 'smsBuilder.adminCampaignSentTo',
    defaultMessage: 'Who would you like to send this text campaign to?',
  },
  adminExcludeText: {
    id: 'smsBuilder.adminExcludeText',
    defaultMessage: 'Is there anyone you would like to exclude?',
  },
  createOneTimeSMS: {
    id: 'smsBuilder.createOneTimeSMS',
    defaultMessage: 'Create Text Campaign',
  },
  editOneTimeSMS: {
    id: 'smsBuilder.editOneTimeSMS',
    defaultMessage: 'Edit Text Campaign',
  },
  relativeScheduleText: {
    id: 'smsBuilder.relativeScheduleText',
    defaultMessage: 'This text will be sent {hours, plural, =0 {shortly} one {# hour} other {# hours}} after the tag is applied.',
  },
  scheduledScheduleText: {
    id: 'smsBuilder.scheduledScheduleText',
    defaultMessage: 'This text will be sent every {day} at {time}.',
  },
  absoluteScheduleText: {
    id: 'smsBuilder.absoluteScheduleText',
    defaultMessage:
      'This text will be sent {days, plural, =0 {the same or the following day} one {# day} other {# days}} after the tag is applied, at {time}.',
  },
  builderAdviceTitle: {
    id: 'smsBuilder.builderAdviceTitle',
    defaultMessage: 'Best Practices',
  },
  builderSMSAdviceText: {
    id: 'smsBuilder.builderSMSAdviceDescription',
    defaultMessage: `<ul>
<li>Include your venue name, in case a guest hasn’t saved you in their phone.</li>
<li>Don’t paste a full url link. Instead, use the link icon to create a short link, which is more trusted by carriers
and guests and is automatically tracked in your metrics dashboard.</li>
<li>Put a succinct CTA at the beginning or end of the message.</li>
</ul>`,
  },
  builderMMSAdviceText: {
    id: 'smsBuilder.builderMMSAdviceDescription',
    defaultMessage: `<ul>
<li>Include a greeting to humanize the message.</li>
<li>Include your venue name, in case a guest hasn’t saved you in their phone.</li>
<li>Don’t paste a full url link. Instead, use the link icon to create a short link, which is more trusted by carriers
and guests and is automatically tracked in your metrics dashboard.</li>
<li>Use paragraph breaks to make your message easier to read.</li>
<li>Put a succinct CTA at the beginning or end of the message.</li>
<li>Avoid prompting guests to respond to your text: <bold>this feature doesn’t include two-way texting.</bold></li>
<li>Be sure to stay compliant with your campaign content.
See all the rules and restrictions <link_to_twilio_compliance>here.</link_to_twilio_compliance></li>
</ul>`,
  },
  messageContentTitle: {
    id: 'smsBuilder.messageContentTitle',
    defaultMessage: 'Text Content',
  },
  messagePlaceholderText: {
    id: 'smsBuilder.messagePlaceholderText',
    defaultMessage: 'Add your text here',
  },
  mediaUploadLabel: {
    id: 'smsBuilder.mediaUploadLabel',
    defaultMessage: 'Upload your media',
  },
  mediaUploadDescription: {
    id: 'smsBuilder.mediaUploadDescription',
    defaultMessage: 'Select a .jpg, .png, or .gif. The file size must be under {limit}.',
  },
  designYourMessageContent: {
    id: 'smsBuilder.designYourMessageContent',
    defaultMessage: 'Create your one-way text campaign.',
  },
  smsMessageTooLongError: {
    id: 'smsBuilder.smsMessageTooLongError',
    defaultMessage: 'A text campaign cannot exceed {limit} characters.',
  },
  smsMessageHasNoContentError: {
    id: 'smsBuilder.smsMessageHasNoContentError',
    defaultMessage: 'A text campaign must have text (or media) in order to send.',
  },
  smsMessageHasMediaInInvalidRegion: {
    id: 'smsBuilder.smsMessageHasMediaInInvalidRegion',
    defaultMessage: 'Images are not supported in your region.',
  },
  smsSendMeATest: {
    id: 'smsBuilder.smsSendMeATest',
    defaultMessage: 'Send Test Text',
  },
  smsSendTestSuccess: {
    id: 'smsBuilder.smsSendTestSuccess',
    defaultMessage: 'A test campaign has been sent to:',
  },
  smsSendTestError: {
    id: 'smsBuilder.smsSendTestError',
    defaultMessage: "Sorry! We couldn't send your test. Please try again or contact support.",
  },
  estimateSegments: {
    id: 'smsBuilder.estimateSegments',
    defaultMessage: 'Estimate Segments',
  },
  estimateSegmentsInProgress: {
    id: 'smsBuilder.estimateSegmentsInProgress',
    defaultMessage: 'Estimating...',
  },
  estimatedSegments: {
    id: 'smsBuilder.estimatedSegments',
    defaultMessage: 'Estimated Segments: {count}',
  },
  estimateSegmentsError: {
    id: 'smsBuilder.estimateSegmentsError',
    defaultMessage: 'Oops! Looks like something went wrong on our end. Please contact support.',
  },
  untrackedLinkWarningTitle: {
    id: 'smsBuilder.untrackedLinkWarningTitle',
    defaultMessage: 'We detected a full URL.',
  },
  untrackedLinkWarningBody: {
    id: 'smsBuilder.untrackedLinkWarningBody',
    defaultMessage:
      'Replace your URL by using the link icon in the menu bar. This will help avoid spam reporting and increase trust with guests.',
  },
} as const)
