import { merge } from '../../common/SRUtils'
import StyleUtils from './StyleUtils'

export const ManagerStyles = {
  PrimaryFont: 'Roboto',
  PrimaryBackgroundColor: '#09223A',
  PrimaryStrokeColor: '#031927',
  PrimaryHeaderColor: '#3B5165',
  AxesFontColor: '#092239',
  LightBorderColor: '#ededed',
  NavigationWidth: 80,
  DayviewToolbarHeight: 64,
  WarningColor: '#ff943d',
  SuccessColor: '#06B99A',
  ErrorColor: '#f3356b',
  ActiveWarningColor: '#e87b1f',
  ActiveErrorColor: '#EE1144',
}

export const NavigationCellStyles = {
  body: {
    height: '60px',
    opacity: '0.5',
    fontFamily: ManagerStyles.PrimaryFont,
    fontSize: '12px',
    fontWeight: '300',
    textAlign: 'center',
    color: '#FFFFFF',
    WebkitFontSmoothing: 'antialiased',
    position: 'relative',
    cursor: 'pointer',
    ':hover': merge(
      {
        opacity: '1.0',
      },
      StyleUtils.scale('1.07')
    ),
  },
  active: {
    opacity: '1.0',
  },
  icon: {
    textAlign: 'center',
    fontSize: '23px',
    lineHeight: '23px',
    width: ManagerStyles.NavigationWidth,
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    left: '50%',
    right: '50%',
    margin: '-25% 0px 0px -50%',
  },
  name: {
    position: 'relative',
    bottom: '-60%',
    fontWeight: '600',
    letterSpacing: '-0.3px',
  },
  portalSwitchIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '0',
    padding: '20px 0',
  },
  portalSwitchIcon: {
    display: 'inline-block',
    lineHeight: '23px',
  },
  portalSwitchName: {
    display: 'inline-block',
    position: 'relative',
    fontWeight: '600',
    letterSpacing: '-0.3px',
    padding: '5px 0 0 3px',
  },
  link: {
    textDecoration: 'none',
  },
  newBadge: {
    position: 'relative',
    zIndex: '2',
    fontSize: '7px',
    background: 'rgb(51, 145, 215)',
    width: 19,
    left: 54,
    fontWeight: '600',
    top: 17,
    paddingTop: 1,
  },
  betaBadge: {
    letterSpacing: '-0.3px',
    background: '#FFFFFF',
    borderRadius: '100px',
    color: ManagerStyles.PrimaryBackgroundColor,
    margin: '0 10px',
    fontWeight: '400',
    position: 'relative',
    top: '42px',
  },
  newInsightBadge: {
    background: '#CD4444',
    color: '#FFFFFF',
    width: '20px',
    height: '15px',
    borderRadius: '15px',
    position: 'absolute',
    top: '10px',
    left: '55px',
    fontWeight: '600',
    letterSpacing: '-0.3px',
    textAlign: 'center',
    minWidth: '15px',
    fontFamily: 'Roboto',
    paddingTop: '3px',
    fontSize: '10px',
  },
}

export const NavigationStyles = {
  body: {
    position: 'fixed',
    width: ManagerStyles.NavigationWidth,
    backgroundColor: ManagerStyles.PrimaryBackgroundColor,
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: '398',
    paddingTop: 0,
    overflow: 'hidden',
  },
  scrollableNavContainer: {
    overflow: 'hidden',
    width: ManagerStyles.NavigationWidth,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  scrollableNav: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
  },
  logo: {
    height: '60px',
    width: ManagerStyles.NavigationWidth,
    backgroundColor: 'rgba(255,255,255,0)',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    color: 'white',
    textAlign: 'center',
    lineHeight: '60px',
    fontSize: '39px',
    cursor: 'pointer',
  },
  search: {
    height: ManagerStyles.DayviewToolbarHeight,
    width: ManagerStyles.NavigationWidth,
    lineHeight: '64px',
    backgroundColor: '#3B5165',
    textAlign: 'center',
    color: '#FFFFFF',
    opacity: '0.5',
    cursor: 'pointer',
    boxShadow: 'inset 0 -1px 3px 0 rgba(0,0,0,0.5)',
    ':hover': {
      opacity: '1.0',
      cursor: 'pointer',
    },
  },
  navButtons: {
    marginTop: 10,
    marginBottom: 20,
    width: ManagerStyles.NavigationWidth,
  },
  profileButtons: {
    // position:'absolute',
    bottom: '0px',
    marginTop: 10,
    paddingBottom: 15,
    width: ManagerStyles.NavigationWidth,
    backgroundColor: ManagerStyles.PrimaryBackgroundColor,
    borderTop: '2px solid rgba(255,255,255,0.5)',
    height: 125,
  },
  smallLink: {
    display: 'block',
    width: ManagerStyles.NavigationWidth,
    paddingTop: 10,
    paddingBottom: 5,
    opacity: '0.5',
    fontFamily: ManagerStyles.PrimaryFont,
    fontSize: '12px',
    textAlign: 'center',
    color: '#FFFFFF',
    WebkitFontSmoothing: 'antialiased',
    position: 'relative',
    cursor: 'pointer',
    ':hover': merge(
      {
        opacity: '1.0',
      },
      StyleUtils.scale('1.07')
    ),
    textDecoration: 'none',
    fontWeight: '600',
    letterSpacing: '-0.3px',
  },
}

export default ManagerStyles
