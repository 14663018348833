import _ from 'lodash'
import * as ActionTypes from './ActionTypes'
import * as BookActions from './BookActions'
import * as BookAvailabilityActions from './BookAvailabilityActions'
import * as BookClientActions from './BookClientActions'
import * as BookDetailsActions from './BookDetailsActions'
import * as BookSourceActions from './BookSourceActions'
import * as ViewActualActions from './ViewActualActions'

const debouncedOnChangeVenueOrDate = _.debounce(BookAvailabilityActions.onChangeVenuesOrDate, 500, { leading: true, trailing: true })
export function handleAction(action, dispatch, getState) {
  switch (action.type) {
    case ActionTypes.ENTER_ADD_RESERVATION:
      debouncedOnChangeVenueOrDate(dispatch, getState)
      if (action.clientId) {
        dispatch(BookClientActions.selectExistingClient(action.clientId))
      }
      break
    case ActionTypes.BOOK_CHANGE_SEARCH_VENUES: {
      const venueIds = action.searchVenues.map(v => v.id)
      debouncedOnChangeVenueOrDate(dispatch, getState, venueIds)
      break
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_DATE: {
      const { date, venue } = action
      debouncedOnChangeVenueOrDate(dispatch, getState, undefined, date)
      BookAvailabilityActions.tryGetOrReleaseReservationHold(dispatch, getState, venue, { date: date?.format('MM-DD-Y') })
      break
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SHIFT:
      BookAvailabilityActions.onChangeShift(dispatch, getState)
      BookAvailabilityActions.releaseReservationHold(dispatch, getState, action.venue)
      break
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_PARTY_SIZE:
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SEARCH_TIME_SLOT:
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_DURATION:
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SEATING_AREA:
    case ActionTypes.TOGGLE_BUFFER:
      if ([ActionTypes.BOOK_AVAILABILITY_CHANGE_PARTY_SIZE, ActionTypes.BOOK_AVAILABILITY_CHANGE_DURATION].includes(action.type)) {
        const { venue, partySize, duration } = action
        BookAvailabilityActions.tryGetOrReleaseReservationHold(dispatch, getState, venue, { partySize, duration })
      }
      BookAvailabilityActions.onChangePartySizeTimeDurationSeatingArea(dispatch, getState)
      break
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SEARCH_RESULTS_PAGE:
      BookAvailabilityActions.onChangeSearchResultsPage(dispatch, getState)
      break
    case ActionTypes.BOOK_CLIENT_CHANGE_SEARCH_TERMS:
      BookClientActions.onChangeClientSearch(dispatch, getState)
      break
    case ActionTypes.BOOK_CLIENT_CHANGE_SELECTED_HOTEL_ID: {
      const isHotelOnlyChange = true
      BookClientActions.onChangeClientSearch(dispatch, getState, isHotelOnlyChange)
      break
    }
    case ActionTypes.BOOK_SOURCE_CHANGE_SEARCH_TERMS:
      BookSourceActions.onChangeSourceSearchTerms(dispatch, getState)
      break
    case ActionTypes.BOOK_CLIENT_SELECT_EXISTING_CLIENT:
      BookClientActions.onSelectExistingClient(dispatch, getState, action.clientId, action.hotelClient)
      break
    case ActionTypes.BOOK_CLIENT_DESELECT_CLIENT:
      BookClientActions.onDeselectClient(dispatch, getState, action.previouslySelectedClient)
      break
    case ActionTypes.BOOK_CLIENT_SELECT_HOTEL_CLIENT:
      BookClientActions.onSelectHotelClient(dispatch, getState, action.venue, action.hotelClient, action.selectedHotelId)
      break
    case ActionTypes.BOOK_SOURCE_SELECT_EXISTING_SOURCE:
      BookSourceActions.onSelectExistingSource(dispatch, getState, action.sourceId)
      break
    case ActionTypes.BOOK_CLIENT_DESELECT_SOURCE_CLIENT:
      BookSourceActions.onDeselectSourceClient(dispatch, getState, action.previouslySelectedClient)
      break
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT: {
      // This is to support multi-venue results, where you select the timeslot of one venue
      // This is also invoked upon BookReservation componentDidMount
      const { venue, selectedTimeSlot, isPreselectedTime } = action
      const venueId = venue.id
      BookDetailsActions.onChangeSelectedVenue(dispatch, getState, venueId)
      BookClientActions.onChangeSelectedVenue(dispatch, getState, venueId)
      BookAvailabilityActions.tryGetOrReleaseReservationHold(dispatch, getState, venue, {
        accessPersistentId: selectedTimeSlot.access_persistent_id,
        shiftPersistentId: selectedTimeSlot.shift_persistent_id,
        time: selectedTimeSlot.time,
        isPreselectedTime,
      })
      break
    }
    case ActionTypes.IGNORE_UNSAVED_WARNING:
    case ActionTypes.CLOSE_SLIDEOUT: {
      const { venue } = action
      BookAvailabilityActions.tryGetOrReleaseReservationHold(dispatch, getState, venue, {}, true)
      break
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_EXTERNAL_AVAILABILITY_TIME_SLOT: {
      const { venue, selectedTimeSlot, isPreselectedTime, isPreviousTime, lockToAvoidGettingResHoldWhileLoading, isDirectlySelected } =
        action
      dispatch(
        BookAvailabilityActions.changeSelectedTimeSlot(
          venue,
          selectedTimeSlot,
          isPreselectedTime,
          isPreviousTime,
          lockToAvoidGettingResHoldWhileLoading,
          null,
          isDirectlySelected
        )
      )
      BookAvailabilityActions.changeChargesFromExternalTimeSlot(dispatch, getState, venue, selectedTimeSlot)
      break
    }
    case ActionTypes.BOOK_CLICK_BOOK_RESERVATION:
      BookActions.onClickBookReservation(dispatch, getState, action.isChargeWarningApproved, action.override, action.overlappingResIds)
      break
    case ActionTypes.BOOK_SUBMIT_RESERVATION_SUCCESS: {
      const { actual, venue } = action
      ViewActualActions.onBookReservationSuccess(dispatch, getState, actual, venue)
      break
    }
    default:
      break
  }
}
