import { connect } from 'react-redux'
import ClientLookup from 'mgr/actualslideout/components/client/ClientLookup'

const ClientLabels = {
  addAsNew: 'Add as New Client',
  searchPlaceholder: 'Enter client name, phone, or email to search or add as a new client',
  errorMessage: 'Darn. Something went wrong.',
  thirdPartyError: 'External search unsuccessful.',
  noResults: 'No results',
}

const mapStateToProps = state => ({
  isLookupLoading: state.bookClientState.isClientLookupLoading,
  isLookupLoadError: state.bookClientState.isClientLookupLoadError,
  isHydrateLoading: state.bookClientState.isClientHydrateLoading,
  isHydrateLoadError: state.bookClientState.isClientHydrateLoadError,
  searchTerms: state.bookClientState.searchTerms,
  clientResults: state.bookClientState.clientLookupResults,
  hotelLookupOptions: state.bookClientState.hotelLookupOptions,
  selectedHotelId: state.bookClientState.selectedHotelId,
  isHotelLookupLoading: state.bookClientState.isClientHotelLookupLoading,
  isHotelLookupLoadError: state.bookClientState.isClientHotelLookupLoadError,
  clientHotelResults: state.bookClientState.clientHotelLookupResults,
  labels: ClientLabels,
  clientTags: state.bookClientState.clientTagGroupsByVenue,
  reservationTags: state.bookDetailsState.reservationTagGroupsByVenue,
  areThirdPartyErrors: state.bookClientState.areThirdPartyErrors,
})

export default connect(mapStateToProps)(ClientLookup)
