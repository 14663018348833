import { connect } from 'react-redux'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import { LoadingSpinner } from 'mgr/layout/StyledComponentUtils'
import BookStepComponent from '../../components/BookStepComponent'
import CardInputForm from '../../components/payment/CardInputForm'
import { PaymentSectionForm } from '../../components/payment/PaymentSectionForm'

const PaymentStep = ({
  actions,
  isCollapsed,
  scrollIntoView,
  currencySymbol,
  currencyCode,
  validateFieldMaps,
  cardEntryOption,
  cardHolderName,
  cardHolderNumber,
  cardExpMonth,
  cardExpYear,
  cardCcv,
  cardPhoneNumber,
  override,
  useGuestProfilePhoneNumber,
  takePaymentOrSave,
  clientCards,
  formattedChargeAmount,
  chargeAmount,
  chargeApplyTax,
  chargeTotal,
  chargeDescription,
  chargeSendNotification,
  taxGroups,
  taxGroupId,
  applyServiceCharge,
  serviceCharge,
  applyGratuityCharge,
  gratuityType,
  gratuityCharge,
  requiredGratuityCharge,
  paylinkGratuityType,
  resCardId,
  resCardLast4,
  resCardType,
  formErrors,
  chargeType,
  cardRequired,
  upsellAmount,
  paymentRule,
  partySizeMinRule,
  costMinRule,
  requiredGratuity,
  applyTaxRate,
  canOverridePayment,
  selectedClient,
  canCharge,
  canSaveCard,
  isMotoEnabled,
  paymentSystem,
  paylinkOnly,
  stripeInstance,
  cardData,
  venueTimezone,
  selectedTimeSlot,
  paylinkAutoCancel,
  creditCardCollection,
  isEditMode,
  outstandingPaylink,
  changedReservationInfo,
  venue,
  hasPayments,
  internalUpsellsEnabled,
  isLoading,
  upgradesCharges,
}) => {
  const headerRight = !hasPayments && (
    <div style={{ height: '52px', display: 'flex', alignItems: 'center', margin: '4px 0' }}>
      <div style={{ padding: '8px 12px', borderRadius: '5px', backgroundColor: '#F6F6F6' }}>
        <span style={{ paddingRight: '12px' }}>
          <StyledVmsIconXS>{VmsIcons.Info}</StyledVmsIconXS>
        </span>
        <span style={{ paddingRight: '12px' }}>Start accepting online payments.</span>
        <a
          href={`/manager2/${venue.urlKey}/settings/payment-integration/view`}
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: '500' }}
        >
          Set Up Later
        </a>
      </div>
    </div>
  )

  const isCollapsible = hasPayments && (canCharge || canSaveCard)

  const validateFieldMap = validateFieldMaps.payment
  const message = 'Invalid credit card details'
  if (paymentSystem === AccountTypes.ADYEN) {
    if (cardEntryOption !== 'paylink') {
      validateFieldMap.credit_card_token = {
        isValid: () => (!cardData ? message : true),
      }
    } else {
      validateFieldMap.credit_card_token = {
        isValid: () => true,
      }
    }
  }

  const onToggle = () => {
    actions.toggleStep('payment')
  }
  const onCompleteScrollIntoView = () => {
    actions.clearScrollIntoView()
  }

  const [title, subTitle] = ['Payment', null]

  const cardDetails = {
    cardHolderName,
    cardHolderNumber,
    cardExpMonth,
    cardExpYear,
    cardCcv,
    cardPhoneNumber,
  }

  const resCardDetails = {
    resCardId,
    resCardLast4,
    resCardType,
  }

  const chargeDetails = {
    override,
    useGuestProfilePhoneNumber,
    takePaymentOrSave,
    cardEntryOption,
    clientCards,
    currencySymbol,
    currencyCode,
    formattedChargeAmount,
    chargeAmount,
    chargeApplyTax,
    chargeTotal,
    chargeDescription,
    chargeSendNotification,
    canCharge,
    taxGroups,
    taxGroupId,
    paymentSystem,
    applyServiceCharge,
    serviceCharge,
    applyGratuityCharge,
    gratuityType,
    gratuityCharge,
    requiredGratuityCharge,
    paylinkGratuityType,
    paylinkAutoCancel,
    creditCardCollection,
    cardRequired,
    outstandingPaylink,
    changedReservationInfo,
  }

  const paymentRules = {
    chargeType,
    cardRequired,
    paymentRule,
    partySizeMinRule,
    costMinRule,
    requiredGratuity,
    applyTaxRate,
    canOverridePayment,
    canSaveCard,
    isMotoEnabled,
  }

  const paymentForm = internalUpsellsEnabled ? (
    <PaymentSectionForm
      {...{
        actions,
        currencySymbol,
        validateFieldMap,
        cardDetails,
        resCardDetails,
        chargeDetails,
        upgradesCharges,
        selectedClient,
        venueTimezone,
        selectedTimeSlot,
        formErrors,
        paymentRules,
        stripeInstance,
        isEditMode,
        paylinkOnly,
        venue,
      }}
    />
  ) : (
    <CardInputForm
      {...{
        actions,
        currencySymbol,
        validateFieldMap,
        cardDetails,
        resCardDetails,
        chargeDetails,
        selectedClient,
        venueTimezone,
        selectedTimeSlot,
        formErrors,
        paymentRules,
        stripeInstance,
        isEditMode,
        paylinkOnly,
        venue,
      }}
    />
  )

  return (
    <BookStepComponent
      {...{
        title,
        subTitle,
        onToggle,
        onCompleteScrollIntoView,
        isCollapsed: cardRequired || !!upsellAmount ? false : isCollapsed,
        isCollapsible: cardRequired || !!upsellAmount ? false : isCollapsible,
        scrollIntoView,
        headerRight,
      }}
      testId="sr-section-payment"
      icon={VmsIcons.PaymentAdd}
      iconLine={VmsIcons.PaymentAddLine}
      isEmpty={!cardHolderName}
    >
      {isLoading ? <LoadingSpinner /> : paymentForm}
    </BookStepComponent>
  )
}

PaymentStep.propTypes = {
  actions: React.PropTypes.object,
  isCollapsed: React.PropTypes.bool,
  scrollIntoView: React.PropTypes.bool,
  cardEntryOption: React.PropTypes.string,
  clientCards: React.PropTypes.array,
  cardHolderName: React.PropTypes.string,
  cardHolderNumber: React.PropTypes.string,
  cardExpMonth: React.PropTypes.number,
  cardExpYear: React.PropTypes.number,
  cardCcv: React.PropTypes.string,
  override: React.PropTypes.bool,
  takePaymentOrSave: React.PropTypes.string,
  currencySymbol: React.PropTypes.string,
  currencyCode: React.PropTypes.string,
  formattedChargeAmount: React.PropTypes.string,
  chargeAmount: React.PropTypes.string,
  chargeApplyTax: React.PropTypes.bool,
  chargeSendNotification: React.PropTypes.bool,
  chargeTotal: React.PropTypes.string,
  chargeDescription: React.PropTypes.string,
  taxGroups: React.PropTypes.array,
  taxGroupId: React.PropTypes.string,
  applyServiceCharge: React.PropTypes.bool,
  serviceCharge: React.PropTypes.string,
  applyGratuityCharge: React.PropTypes.bool,
  gratuityType: React.PropTypes.string,
  gratuityCharge: React.PropTypes.string,
  requiredGratuityCharge: React.PropTypes.bool,
  paylinkGratuityType: React.PropTypes.string,
  resCardId: React.PropTypes.string,
  resCardLast4: React.PropTypes.string,
  resCardType: React.PropTypes.string,
  formErrors: React.PropTypes.object.isRequired,
  chargeType: React.PropTypes.string,
  cardRequired: React.PropTypes.bool,
  upsellAmount: React.PropTypes.number,
  paymentRule: React.PropTypes.string,
  partySizeMinRule: React.PropTypes.number,
  costMinRule: React.PropTypes.number,
  requiredGratuity: React.PropTypes.number,
  applyTaxRate: React.PropTypes.bool,
  canOverridePayment: React.PropTypes.bool.isRequired,
  canSaveCard: React.PropTypes.bool,
  isMotoEnabled: React.PropTypes.bool,
  selectedClient: React.PropTypes.object,
  venueTimezone: React.PropTypes.string,
  selectedTimeSlot: React.PropTypes.object,
  paylinkAutoCancel: React.PropTypes.number,
  creditCardCollection: React.PropTypes.string,
  isEditMode: React.PropTypes.bool,
  outstandingPaylink: React.PropTypes.string,
  changedReservationInfo: React.PropTypes.object,
  paylinkOnly: React.PropTypes.bool,
  internalUpsellsEnabled: React.PropTypes.bool,
  isLoading: React.PropTypes.bool,
  upgradesCharges: React.PropTypes.object,
}

PaymentStep.defaultProps = {
  actions: {},
}

const mapStateToProps = state => ({
  isCollapsed: state.bookState.isStepCollapsed.payment,
  stepHeightChangeTimestamp: state.bookState.stepHeightChangeTimestamps.payment,
  scrollIntoView: state.bookState.scrollIntoViewStep === 'payment',
  cardEntryOption: state.bookPaymentState.cardEntryOption,
  clientCards: state.bookPaymentState.clientCards,
  cardHolderName: state.bookPaymentState.cardHolderName,
  cardHolderNumber: state.bookPaymentState.cardHolderNumber,
  cardExpMonth: state.bookPaymentState.cardExpMonth,
  cardExpYear: state.bookPaymentState.cardExpYear,
  cardPhoneNumber: state.bookPaymentState.cardPhoneNumber,
  cardCcv: state.bookPaymentState.cardCcv,
  override: state.bookPaymentState.override,
  useGuestProfilePhoneNumber: state.bookPaymentState.useGuestProfilePhoneNumber,
  takePaymentOrSave: state.bookPaymentState.takePaymentOrSave,
  currencySymbol: state.bookState.selectedVenue.currencySymbol,
  currencyCode: state.bookState.selectedVenue.currencyCode,
  formattedChargeAmount: state.bookPaymentState.formattedChargeAmount,
  chargeAmount: state.bookPaymentState.chargeAmount,
  chargeApplyTax: state.bookPaymentState.chargeApplyTax,
  applyServiceCharge: state.bookPaymentState.applyServiceCharge,
  serviceCharge: state.bookPaymentState.serviceCharge,
  applyGratuityCharge: state.bookPaymentState.applyGratuityCharge,
  gratuityType: state.bookPaymentState.gratuityType,
  gratuityCharge: state.bookPaymentState.gratuityCharge,
  requiredGratuityCharge: state.bookPaymentState.requiredGratuityCharge,
  paylinkGratuityType: state.bookPaymentState.paylinkGratuityType,
  chargeSendNotification: state.bookPaymentState.chargeSendNotification,
  chargeTotal: state.bookPaymentState.chargeTotal,
  chargeDescription: state.bookPaymentState.chargeDescription,
  taxGroups: state.bookState.selectedVenue.bookSettings.taxGroups,
  taxGroupId: state.bookPaymentState.taxGroupId,
  resCardId: state.bookPaymentState.resCardId,
  resCardLast4: state.bookPaymentState.resCardLast4,
  resCardType: state.bookPaymentState.resCardType,
  formErrors: state.bookState.firstInvalidStep === 'payment' ? state.bookState.formErrors : {},
  chargeType: state.bookPaymentState.chargeType,
  cardRequired: state.bookPaymentState.cardRequired,
  upsellAmount: state.bookPaymentState.upgrades?.upsellAmount,
  paymentRule: state.bookPaymentState.paymentRule,
  partySizeMinRule: state.bookPaymentState.partySizeMinRule || 0,
  costMinRule: state.bookPaymentState.costMinRule || 0,
  applyTaxRate: state.bookPaymentState.applyTaxRate || false,
  canOverridePayment: state.appState.userDomain.can_override_payment_requirement || false,
  selectedClient: state.bookClientState.selectedClient || {},
  canCharge: state.bookState.selectedVenue.permissions.canCharge,
  canSaveCard: state.bookState.selectedVenue.bookSettings.canSaveCard,
  isMotoEnabled: state.bookState.selectedVenue.bookSettings.isMotoEnabled,
  paymentSystem: state.bookState.selectedVenue.bookSettings.paymentSystem,
  paylinkOnly: state.bookState.selectedVenue.bookSettings.paylinkOnly,
  stripeInstance: state.bookPaymentState.stripeInstance,
  cardData: state.bookPaymentState.cardData,
  venueTimezone: state.bookPaymentState.venueTimezone,
  selectedTimeSlot: state.bookAvailabilityState.selectedTimeSlot,
  paylinkAutoCancel: state.bookPaymentState.paylinkAutoCancel,
  creditCardCollection: state.bookPaymentState.creditCardCollection,
  isEditMode: !!state.slideoutState.actualId,
  isPreselectedTime: state.bookState.isPreselectedTime,
  outstandingPaylink: state.bookPaymentState.outstandingPaylink,
  changedReservationInfo: state.bookPaymentState.changedReservationInfo,
  venue: state.bookState.selectedVenue,
  hasPayments: state.bookState.selectedVenue.bookSettings.paymentsEnabled,
  internalUpsellsEnabled: state.bookPaymentState.internalUpsellsEnabled,
  isLoading: state.bookPaymentState.initDataCache?.isLoading,
  upgradesCharges: state.bookPaymentState.upgrades,
})

export default connect(mapStateToProps)(PaymentStep)
