import moment from 'moment-timezone'

import { srGet, srPost } from '@sevenrooms/core/api'

const fetchMessages = (venueId, conversationId, errHandler) =>
  srGet(
    `/manager/${venueId}/message/${conversationId}`,
    {
      rid: Math.floor(Math.random() * 100 + 1),
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }

    return response.payload && response.payload.messages
  })

const createMessage = (
  venueId,
  conversationId,
  message,
  systemMessage,
  category,
  status,
  attachments,
  visibility,
  transactionType,
  date,
  entityId,
  errHandler
) =>
  srPost(
    `/manager/${venueId}/message/create`,
    {
      conversation_id: conversationId,
      message,
      category,
      status,
      visibility,
      attachments: JSON.stringify(attachments),
      transaction_type: transactionType,
      date_formatted: moment(date).format('MM/DD/YY'),
      entity_id: entityId,
      system_message: systemMessage,
      rid: Math.floor(Math.random() * 100) + 1,
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    return response.payload && response.payload.message[0]
  })

const resendBookingEmail = (venueId, actualId, errHandler) =>
  srPost(
    `/manager/${venueId}/actual/${actualId}/resend-email`,
    { send_client_email: true },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }

    return response.payload && response.payload.success
  })

const resendBookingSms = (venueId, actualId, errHandler) =>
  srPost(
    `/manager/${venueId}/actual/${actualId}/resend-sms`,
    { send_client_sms: true },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }

    return response.payload && response.payload.success
  })

export default {
  fetchMessages,
  createMessage,
  resendBookingEmail,
  resendBookingSms,
}
