import React from 'react'
import styled from 'styled-components'
import Media from './Media'

// See https://github.com/styled-components/styled-components/issues/439
// for why we can't just do styled.div
const Container = styled('div')`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${props => (props.sidePadding ? props.sidePadding : '15px')};
  padding-right: ${props => (props.sidePadding ? props.sidePadding : '15px')};

  ${Media.sm`
    padding-left: ${props => (props.sidePadding ? props.sidePadding : '15px')};
    padding-right: ${props => (props.sidePadding ? props.sidePadding : '15px')};
    width: 540px;
    max-width: 100%;
  `}

  ${Media.md`
    padding-left: ${props => (props.sidePadding ? props.sidePadding : '15px')};
    padding-right: ${props => (props.sidePadding ? props.sidePadding : '15px')};
    width: 720px;
    max-width: 100%;
  `}

  ${Media.lg`
    padding-left: ${props => (props.sidePadding ? props.sidePadding : '15px')};
    padding-right: ${props => (props.sidePadding ? props.sidePadding : '15px')};
    width: 960px;
    max-width: 100%;
  `}

  ${Media.xl`
    padding-left: ${props => (props.sidePadding ? props.sidePadding : '15px')};
    padding-right: ${props => (props.sidePadding ? props.sidePadding : '15px')};
    width: 1140px;
    max-width: 100%;
  `}
`

export default Container
