import { defineMessages } from '@sevenrooms/core/locales'

export const PaylinkRemoveModalLocales = defineMessages({
  title: {
    id: 'actual.paylinkRemoveModal.title',
    defaultMessage: 'Are you sure you want to delete this paylink?',
  },
  body: {
    id: 'actual.paylinkRemoveModal.body',
    defaultMessage: 'This paylink needs to be completed by the guest before the reservation is finalized.',
  },
  nevermind: {
    id: 'actual.paylinkRemoveModal.nevermind',
    defaultMessage: 'Nevermind',
  },
  deleteAndCancel: {
    id: 'actual.paylinkRemoveModal.deleteAndCancel',
    defaultMessage: 'Delete and Cancel Reservation',
  },
  deleteAndBook: {
    id: 'actual.paylinkRemoveModal.deleteAndBook',
    defaultMessage: 'Delete Paylink and Send Booking Confirmation',
  },
} as const)
