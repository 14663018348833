import { showErrorMessage, showSuccessMessage } from 'mgr/lib/actions/GlobalActions'
import { getVenueLanguages, saveVenueLanguageSettings } from 'mgr/lib/services/LanguageServices'
import {
  GET_VENUE_LANGUAGES_START,
  GET_VENUE_LANGUAGES_SUCCESS,
  GET_VENUE_LANGUAGES_FAIL,
  CHANGE_VENUE_LANGUAGE,
  SET_DEFAULT_LANGUAGE,
  TOGGLE_LANGUAGE_ENABLED,
  SAVE_VENUE_LANGUAGE_SETTINGS_START,
  SAVE_VENUE_LANGUAGE_SETTINGS_SUCCESS,
  SAVE_VENUE_LANGUAGE_SETTINGS_FAIL,
  RESET_VENUE_LANGUAGE_TO_INITIAL,
  RESET_LANGUAGE_SETTINGS,
} from 'mgr/pages/single-venue/settings/actions/ActionTypes'

export const tryGetVenueLanguages = venueId => dispatch => {
  dispatch({
    type: GET_VENUE_LANGUAGES_START,
  })

  return getVenueLanguages(venueId)
    .then(response => {
      dispatch({
        type: GET_VENUE_LANGUAGES_SUCCESS,
        data: response.data,
      })
      return response
    })
    .catch(e => {
      dispatch(showErrorMessage('Unable to fetch languages list, please try again later or contact customer support.'))

      dispatch({
        type: GET_VENUE_LANGUAGES_FAIL,
      })

      return e
    })
}

export const trySaveVenueLanguageSettings = () => (dispatch, getState) => {
  const state = getState()
  const venueId = state.appState.venue.id

  dispatch({
    type: SAVE_VENUE_LANGUAGE_SETTINGS_START,
  })

  return saveVenueLanguageSettings(venueId, state.languages.editLanguageSettingsObj)
    .then(response => {
      if (response.error) {
        throw response.error
      }

      dispatch(showSuccessMessage('Successfully saved language settings.'))

      dispatch({
        type: SAVE_VENUE_LANGUAGE_SETTINGS_SUCCESS,
      })

      return response
    })
    .catch(e => {
      console.error(e)

      dispatch(showErrorMessage('Unable to save language settings, please try again later or contact customer support.'))

      dispatch({
        type: SAVE_VENUE_LANGUAGE_SETTINGS_FAIL,
      })

      return e
    })
}

export const venueLanguageChanged = languageCode => (dispatch, getState) => {
  dispatch({
    type: CHANGE_VENUE_LANGUAGE,
    languageCode,
  })
}

export const onDefaultCellClick = language => dispatch => {
  if (!language.is_enabled) {
    dispatch({
      type: TOGGLE_LANGUAGE_ENABLED,
      language,
    })
  }

  return dispatch({
    type: SET_DEFAULT_LANGUAGE,
    language,
  })
}

export const onIsEnabledCellClick = language => dispatch => {
  if (!language.is_default) {
    return dispatch({
      type: TOGGLE_LANGUAGE_ENABLED,
      language,
    })
  }
}

export const resetVenueLanguageToInitial = () => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: RESET_VENUE_LANGUAGE_TO_INITIAL,
    languages: state.languages.venueLanguages,
    defaultLanguage: state.languages.defaultLanguage,
  })
}

export const resetLanguageSettings = () => dispatch =>
  dispatch({
    type: RESET_LANGUAGE_SETTINGS,
  })
