import moment from 'moment-timezone'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ThemeProvider } from 'styled-components'
import * as SlideoutActions from 'mgr/actualslideout/actions/SlideoutActions'
import GlobalNotificationBanner from 'mgr/layout/GlobalNotificationBanner'
import Theme from 'mgr/layout/Theme'
import { tryGetVenueLanguages } from 'mgr/pages/single-venue/settings/actions/LanguageActions'
import { Root } from '@sevenrooms/core/ui-kit/layout'
import BookReservation from './BookReservation'
import ViewReservation from './ViewReservation'

/*
import Perf from 'react-addons-perf'
window.Perf = Perf
*/

class App extends PureComponent {
  constructor(props) {
    super(props)

    // setup locale for date time rendering
    moment.locale(this.props.globalInit.venueLocale)
    window.moment && window.moment.locale(this.props.globalInit.venueLocale)
  }

  componentWillMount() {
    const { actions, globalInit } = this.props
    actions.initialize(globalInit)
  }

  componentDidMount() {
    const { actions, languages } = this.props

    if (languages.length === 0) {
      actions.tryGetVenueLanguages(this.props.globalInit.venueId)
    }
  }

  render() {
    const { actions, globalInit, isViewMode, notificationBannerState } = this.props
    if (!globalInit.venueSettings.use_supafly) {
      return null
    }
    const handleClose = () => {
      actions.closeNotificationBanner()
    }
    const handleAction = () => {
      actions.clickNotificationBannerAction()
    }
    const activeContents = isViewMode ? <ViewReservation /> : <BookReservation />
    return (
      <Root theme="vx">
        <ThemeProvider theme={Theme}>
          <div>
            {activeContents}
            <GlobalNotificationBanner {...notificationBannerState} {...{ handleClose, handleAction }} />
          </div>
        </ThemeProvider>
      </Root>
    )
  }
}

const mapStateToProps = state => ({
  isViewMode: state.slideoutState.isViewMode,
  notificationBannerState: state.slideoutState.notificationBannerState,
  languages: state.languages.venueLanguages,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      tryGetVenueLanguages,
      initialize: SlideoutActions.initialize,
      closeNotificationBanner: SlideoutActions.closeNotificationBanner,
      clickNotificationBannerAction: SlideoutActions.clickNotificationBannerAction,
    },
    dispatch
  ),
})

App = connect(mapStateToProps, mapDispatchToProps)(App)

export default App
