import _ from 'lodash'
import moment from 'moment-timezone'
import { formatDateOnly } from 'mgr/lib/utils/MomentUtils'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import type { MenuAction } from 'mgr/pages/single-venue/settings/actions/ordering/OrderingMenuManagement'
import type { MenuCategory, OrderingMenu, ExcludedDateRange } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { MenuItem } from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'

export interface OrderingMenuState {
  orderingMenu: OrderingMenu
  menuItems: MenuItem[]
  selectedMenuCategory?: MenuCategory
  isCategoryModalOpen: boolean
  isConfirmDeleteModalOpen: boolean
  isAddMenuItemModalOpen: boolean
  isDirty: boolean
}

const initialState: OrderingMenuState = {
  isCategoryModalOpen: false,
  isConfirmDeleteModalOpen: false,
  isAddMenuItemModalOpen: false,
  menuItems: [],
  orderingMenu: {
    availableHours: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    },
    hierarchy: {
      categories: [],
    },
    id: '',
    name: undefined,
    prepTimeMins: 25,
    sortOrder: 0,
    updated: undefined,
    sourceIntegrationNameDisplay: undefined,
    dateRangeFrom: formatDateOnly(moment()),
    dateRangeTo: null,
    excludedDateRanges: [],
  },
  isDirty: false,
}

const orderingMenuReducer = (state: OrderingMenuState = initialState, action: MenuAction) => {
  const { categories } = state.orderingMenu.hierarchy
  switch (action.type) {
    case ActionTypes.GET_ORDERING_MENU_SUCCESS:
      return {
        ...state,
        orderingMenu: action.menu,
        isDirty: false,
      }
    case ActionTypes.GET_ORDERING_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        menuItems: action.menuItems,
        isDirty: false,
      }
    case ActionTypes.CLEAR_ORDERING_MENU:
      return {
        ...initialState,
        isDirty: true,
      }
    case ActionTypes.UPDATE_ORDERING_MENU_NAME:
      return {
        ...state,
        orderingMenu: {
          ...state.orderingMenu,
          name: action.value,
        },
        isDirty: true,
      }
    case ActionTypes.UPDATE_ORDERING_MENU_PREP_TIME_MINS:
      return {
        ...state,
        orderingMenu: {
          ...state.orderingMenu,
          prepTimeMins: action.value,
        },
        isDirty: true,
      }
    case ActionTypes.CREATE_ORDERING_MENU_SUCCESS:
    case ActionTypes.UPDATE_ORDERING_MENU_SUCCESS:
      return {
        ...state,
        isDirty: false,
      }
    case ActionTypes.UPDATE_ORDERING_MENU_DATE_RANGE_FROM: {
      const dateRangeFrom = action.value && formatDateOnly(moment(action.value))
      let { dateRangeTo } = state.orderingMenu
      if (dateRangeTo && dateRangeTo.isBefore(dateRangeFrom)) {
        dateRangeTo = dateRangeFrom
      }
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          dateRangeFrom,
          dateRangeTo,
        },
      }
    }
    case ActionTypes.UPDATE_ORDERING_MENU_DATE_RANGE_TO: {
      const dateRangeTo = action.value && formatDateOnly(moment(action.value))
      let { dateRangeFrom } = state.orderingMenu
      if (dateRangeTo && dateRangeTo.isBefore(dateRangeFrom)) {
        dateRangeFrom = dateRangeTo
      }
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          dateRangeFrom,
          dateRangeTo,
        },
      }
    }
    case ActionTypes.CREATE_ORDERING_MENU_CATEGORY:
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          hierarchy: {
            ...state.orderingMenu.hierarchy,
            categories: [...categories, action.category],
          },
        },
      }
    case ActionTypes.UPDATE_ORDERING_MENU_CATEGORY:
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          hierarchy: {
            ...state.orderingMenu.hierarchy,
            categories: categories.map(category => (category.name === action.categoryName ? action.category : category)),
          },
        },
      }
    case ActionTypes.DELETE_ORDERING_MENU_CATEGORY:
      return {
        ...state,
        isDirty: true,
        isConfirmDeleteModalOpen: false,
        orderingMenu: {
          ...state.orderingMenu,
          hierarchy: {
            ...state.orderingMenu.hierarchy,
            categories: _.filter(categories, category => category.name !== action.categoryName),
          },
        },
      }
    case ActionTypes.DELETE_ORDERING_MENU_CATEGORY_ITEM:
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          hierarchy: {
            ...state.orderingMenu.hierarchy,
            categories: categories.map(category =>
              category.name === action.category.name
                ? {
                    ...category,
                    items: category.items.filter(item => item.id !== action.menuItem.id),
                  }
                : category
            ),
          },
        },
      }
    case ActionTypes.UPDATE_ORDERING_MENU_CATEGORY_ITEMS:
      return {
        ...state,
        isAddMenuItemModalOpen: false,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          hierarchy: {
            ...state.orderingMenu.hierarchy,
            categories: categories.map(category =>
              category.name === action.category.name
                ? {
                    ...category,
                    items: action.menuItems,
                  }
                : category
            ),
          },
        },
      }
    case ActionTypes.UPDATE_ORDERING_MENU_CATEGORY_ORDER:
      /* eslint-disable no-case-declarations */
      const { categoryName, position } = action
      const sourceCategoryIndex = _.findIndex(categories, category => category.name === categoryName)
      const source = categories[sourceCategoryIndex]
      const destination = categories[position - 1]
      if (source && destination) {
        categories[sourceCategoryIndex] = destination
        categories[position - 1] = source
      }
      /* eslint-enable no-case-declarations */
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          hierarchy: {
            ...state.orderingMenu.hierarchy,
            categories,
          },
        },
      }
    case ActionTypes.OPEN_CONFIRM_DELETE_MODAL:
      return {
        ...state,
        isConfirmDeleteModalOpen: true,
        selectedMenuCategory: _.find(categories, category => category.name === action.categoryName),
      }
    case ActionTypes.CLOSE_CONFIRM_DELETE_MODAL:
      return { ...state, isConfirmDeleteModalOpen: false }
    case ActionTypes.OPEN_CATEGORY_MODAL:
      return {
        ...state,
        isCategoryModalOpen: true,
        selectedMenuCategory: action.category,
      }
    case ActionTypes.CLOSE_CATEGORY_MODAL:
      return { ...state, isCategoryModalOpen: false }
    case ActionTypes.OPEN_ADD_MENU_ITEM_MODAL:
      return {
        ...state,
        isAddMenuItemModalOpen: true,
        selectedMenuCategory: action.category,
      }
    case ActionTypes.CLOSE_ADD_MENU_ITEM_MODAL:
      return { ...state, isAddMenuItemModalOpen: false }
    case ActionTypes.ADD_MENU_AVAILABILITY:
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          availableHours: {
            ...state.orderingMenu.availableHours,
            [action.dayOfWeekIndex]: state.orderingMenu.availableHours[action.dayOfWeekIndex]?.concat({
              start: undefined,
              end: undefined,
            }),
          },
        },
      }
    case ActionTypes.REMOVE_MENU_AVAILABILITY:
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          availableHours: {
            ...state.orderingMenu.availableHours,
            [action.dayOfWeekIndex]: state.orderingMenu.availableHours[action.dayOfWeekIndex]?.filter(
              (_dateRange, index) => index !== action.dateRangeIndex
            ),
          },
        },
      }
    case ActionTypes.COPY_MENU_AVAILABILITY_TO_ALL:
      /* eslint-disable no-case-declarations */
      const availableHours = { ...state.orderingMenu.availableHours }
      const timeRangeToCopy = availableHours[action.dayOfWeekIndex]?.[action.dateRangeIndex]
      /* eslint-enable no-case-declarations */
      if (timeRangeToCopy) {
        Object.entries(availableHours).forEach(([index, timeRanges]) => {
          const dayOfWeekIndex = parseInt(index)
          if (
            dayOfWeekIndex !== action.dayOfWeekIndex &&
            !_.some(timeRanges, timeRange => timeRange.start === timeRangeToCopy.start && timeRange.end === timeRangeToCopy.end)
          ) {
            availableHours[dayOfWeekIndex] = timeRanges.concat(timeRangeToCopy)
          }
        })
      }
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          availableHours,
        },
      }
    case ActionTypes.UPDATE_MENU_AVAILABILITY:
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          availableHours: {
            ...state.orderingMenu.availableHours,
            [action.dayOfWeekIndex]: state.orderingMenu.availableHours[action.dayOfWeekIndex]?.map((range, index) => {
              if (index === action.dateRangeIndex) {
                return {
                  start: action.start,
                  end: action.end,
                }
              }
              return range
            }),
          },
        },
      }
    case ActionTypes.ADD_MENU_EXCLUDED_DATE_RANGE:
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          excludedDateRanges: [...state.orderingMenu.excludedDateRanges, action.excludedDateRange],
        },
      }
    case ActionTypes.UPDATE_MENU_EXCLUDED_DATE_RANGE:
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          excludedDateRanges: state.orderingMenu.excludedDateRanges.map((excludedDateRange, index) =>
            index === action.index ? action.excludedDateRange : excludedDateRange
          ),
        },
      }
    case ActionTypes.REMOVE_MENU_EXCLUDED_DATE_RANGE:
      return {
        ...state,
        isDirty: true,
        orderingMenu: {
          ...state.orderingMenu,
          excludedDateRanges: state.orderingMenu.excludedDateRanges.reduce((accum, excludedDateRange, index) => {
            if (index !== action.index) {
              accum.push(excludedDateRange)
            }
            return accum
          }, [] as ExcludedDateRange[]),
        },
      }
    default:
      return state
  }
}

export default orderingMenuReducer
