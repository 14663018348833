export function merge() {
  const res = {}

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arguments.length; ++i) {
    // eslint-disable-next-line prefer-rest-params
    if (arguments[i]) {
      // eslint-disable-next-line prefer-rest-params
      Object.assign(res, arguments[i])
    }
  }

  return res
}
