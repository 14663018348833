import { type Action, createSlice } from '@reduxjs/toolkit'
import type { UpgradesForm } from '@sevenrooms/mgr-reservation-slideout'
import * as ActionTypes from '../actions/ActionTypes'

export interface UpgradesState {
  categories: UpgradesForm['categories']
  isDirty: boolean
  override: boolean
}

const initialState: UpgradesState = {
  categories: {},
  isDirty: false,
  override: false,
}

export const upgradesState = createSlice({
  name: 'upgradesState',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is UpgradesState & Action => action.type === ActionTypes.UPGRADES_CHANGE_FORM,
      (state, { categories, isDirty, override }) => {
        state.categories = categories
        state.isDirty = isDirty
        state.override = override
      }
    )
  },
})
