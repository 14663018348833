import type { AudienceSize } from '@sevenrooms/core/domain'
import { type InternationalizationText, useLocales } from '@sevenrooms/core/locales'
import { Banner } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { campaignBuilderMessages } from '../../../locales'

export interface AudienceSizeBannerProps {
  audienceSize: AudienceSize | null
  isLoading: boolean
  isActionDisabled: boolean
  onAction: () => void
  messages: {
    audienceSizeBannerDescriptionCalculated: InternationalizationText
  }
}

export function AudienceSizeBanner({ audienceSize, isLoading, isActionDisabled, onAction, messages }: AudienceSizeBannerProps) {
  const { formatMessage } = useLocales()

  return (
    <Banner
      type="info"
      title={formatMessage(campaignBuilderMessages.audienceSizeBannerTitle)}
      description={
        audienceSize ? (
          <>
            <Text color="darkFont">{formatMessage(messages.audienceSizeBannerDescriptionCalculated)}</Text>
            <Text fontWeight="bold">
              {formatMessage(campaignBuilderMessages.audienceSizeBannerDescriptionCalculatedValues, {
                matchingClients: audienceSize.matchingClients,
                totalClients: audienceSize.totalClients,
                percentage: Math.round((audienceSize.matchingClients / audienceSize.totalClients) * 100),
              })}
            </Text>
          </>
        ) : (
          formatMessage(campaignBuilderMessages.audienceSizeBannerDescription)
        )
      }
      action={formatMessage(campaignBuilderMessages.audienceSizeBannerAction)}
      onAction={onAction}
      isActionDisabled={isActionDisabled}
      isLoading={isLoading}
    />
  )
}
