export const setClipboard = replacementText => {
  let sel
  let range
  if (window.getSelection) {
    sel = window.getSelection()
    if (sel.rangeCount) {
      range = sel.getRangeAt(0)
      range.deleteContents()
      range.insertNode(document.createTextNode(replacementText))
      document.execCommand('copy')
      range.deleteContents()
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange()
    range.text = replacementText
    document.execCommand('copy')
    range.text = ''
  }
}
