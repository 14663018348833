import { defineMessages } from '@sevenrooms/core/locales'

export const BufferMenuLocales = defineMessages({
  addBuffer: {
    id: 'actual.availability.addBuffer',
    defaultMessage: 'Add buffer',
  },
  removeBuffer: {
    id: 'actual.availability.removeBuffer',
    defaultMessage: 'Remove buffer',
  },
} as const)
