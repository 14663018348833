import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import LazyImage from 'mgr/lib/components/LazyImage'
import SourceBadge from 'mgr/lib/components/SourceBadge'
import * as ImageUtils from 'mgr/lib/utils/Image'

const ThemedLazyImage = styled(LazyImage)`
  margin: ${props => props.margin};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
  text-transform: uppercase;
`

const ClientInitials = styled.div`
  margin: ${props => props.margin};
  color: ${props => props.textColor};
  text-align: center;
  background-color: ${props => props.theme.navigationDark};
  font-size: ${props => props.size / 2}px;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  max-width: ${props => props.size}px;
  max-height: ${props => props.size}px;
  line-height: ${props => props.size}px;
  border-radius: ${props => props.size / 2}px;
`
const BadgedThumbnailContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: ${props => props.margin};
  max-width: ${props => props.size}px;
  max-height: ${props => props.size}px;
`

const ClientThumbnail = ({ photoObj, nameDisplay, initials, margin, size, textColor, showSourceBadge }) => {
  let clientImageContents

  if (!_.isEmpty(photoObj)) {
    clientImageContents = (
      <ThemedLazyImage size={size} margin={margin} src={ImageUtils.getImagePathsInOrderOfSize(photoObj)} alt={nameDisplay} />
    )
  } else {
    clientImageContents = (
      <ClientInitials size={size} textColor={textColor} margin={margin}>
        {initials}
      </ClientInitials>
    )
  }

  if (showSourceBadge) {
    return (
      <BadgedThumbnailContainer size={size} margin={margin}>
        <SourceBadge badgeSize={Math.floor(size / 2)} />
        {clientImageContents}
      </BadgedThumbnailContainer>
    )
  }
  return clientImageContents
}

ClientThumbnail.defaultProps = {
  size: 46,
  margin: '0 auto',
  textColor: '#7E98B1',
}
ClientThumbnail.props = {
  size: React.PropTypes.number.isRequired,
  margin: React.PropTypes.string.isRequired,
  textColor: React.PropTypes.string.isRequired,
  nameDisplay: React.PropTypes.string.isRequired,
  initials: React.PropTypes.string.isRequired,
  photoObj: React.PropTypes.object,
  showSourceBadge: React.PropTypes.bool,
}

export default ClientThumbnail
