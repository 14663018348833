// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in MA-857
import { useLocales } from '@sevenrooms/core/locales'
import { Box, HStack, Modal, ModalHeader, ModalTitle, ModalBody, type ModalHeaderProps, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { StartOngoingFromScratchCard, StartOngoingFromTemplateCard } from '../../components'
import { emailCenterMessages } from '../../locales'

export interface CreateNewEmailProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function CreateNewEmail({ closeHref }: CreateNewEmailProps) {
  const { formatMessage } = useLocales()

  return (
    <Modal ariaLabel={formatMessage(emailCenterMessages.emailCenterNewEmailModalTitle)}>
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <ModalTitle title={formatMessage(emailCenterMessages.emailCenterNewEmailModalTitle)} />
          <SubTitle>{formatMessage(emailCenterMessages.emailCenterNewEmailModalSubTitle)}</SubTitle>
        </VStack>
      </ModalHeader>
      <ModalBody>
        <HStack pb="lm" pt="lm" spacing="lm">
          <StartOngoingFromTemplateCard />
          <StartOngoingFromScratchCard />
        </HStack>
      </ModalBody>
      <ModalFooter p="xs" />
    </Modal>
  )
}

const SubTitle = styled(Text)`
  width: 455px;
`

const ModalFooter = styled(Box)`
  flex-shrink: 0;
  border-bottom-left-radius: ${props => props.theme.modal.borderRadius};
  border-bottom-right-radius: ${props => props.theme.modal.borderRadius};
  background: ${props => props.theme.colors.primaryBackground};
`
