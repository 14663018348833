import { useState } from 'react'
import { useGenerateConversationResponseMutation, useGenerateReviewResponseMutation } from '@sevenrooms/core/api'
import type { BackwriterConversationRequest, BackwriterReviewRequest } from '@sevenrooms/core/domain'
import { useVenueSettingsContext } from './useVenueSettingsContext'

const ERROR_MESSAGE = 'We’ve hit an error generating an AI response - please try again and contact support if your issue continues'

interface BackwriterState {
  isEnabled: boolean
  isLoading: boolean
  generatedText: string
  errorMessage: string
  setGeneratedText: Function | null
  generateResponse: Function | null
}

export function useBackwriter<T>(generateResponseFunction: (request: T) => Promise<{ generatedText: string }>): BackwriterState {
  const [isLoading, setIsLoading] = useState(false)
  const [generatedText, setGeneratedText] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const { venueSettings } = useVenueSettingsContext()

  if (!venueSettings?.ai_backwriter_enabled || !venueSettings?.backwriter_enabled) {
    return {
      isEnabled: false,
      isLoading: false,
      generatedText: '',
      errorMessage: '',
      setGeneratedText: null,
      generateResponse: null,
    }
  }

  async function generateResponse(request: T) {
    setIsLoading(true)
    setErrorMessage('')
    setGeneratedText('')

    try {
      const { generatedText } = await generateResponseFunction(request)
      setGeneratedText(generatedText)
    } catch {
      setErrorMessage(ERROR_MESSAGE)
    }

    setIsLoading(false)
  }

  return { isEnabled: true, isLoading, generatedText, errorMessage, setGeneratedText, generateResponse }
}

export function useConversationBackwriter(): BackwriterState {
  const [generateConversationResponseMutation] = useGenerateConversationResponseMutation()
  const generateConversationResponse = (request: BackwriterConversationRequest) => generateConversationResponseMutation(request).unwrap()

  return useBackwriter<BackwriterConversationRequest>(generateConversationResponse)
}

export function useReviewBackwriter(): BackwriterState {
  const [generateReviewResponseMutation] = useGenerateReviewResponseMutation()
  const generateReviewResponse = (request: BackwriterReviewRequest) => generateReviewResponseMutation(request).unwrap()

  return useBackwriter<BackwriterReviewRequest>(generateReviewResponse)
}
