/* eslint-disable no-param-reassign */
import _ from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { ClientStatsDisplay } from 'mgr/actualslideout/components/client/ClientLookupResult'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import GenericTagsDropDown from 'mgr/lib/components/GenericTagsDropDown'
import SegmentedControl from 'mgr/lib/components/SegmentedControl'
import TextInput, { InputRestrictions, PhoneTextInput, ValidatorTypes } from 'mgr/lib/forms/TextInput'
import { Col, ContainerFluid, Row } from 'svr/lib/styled-bootstrap-grid'

const SelectedClientHeader = styled.div`
  margin: 20px;
`

const ClientHeaderTitle = styled.div`
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  color: ${props => props.theme.navigationDark};
  display: inline-block;
`

const DeselectClientButton = styled.button`
  ${props => props.theme.deleteButton} height: 30px;
  line-height: 30px;
  width: 98px;
  float: right;
`

const HorizontalLine = styled.div`
  border-bottom: 1px solid ${props => props.theme.margin};
  margin: 10px 0;
`

const FieldGroupTitle = styled.div`
  margin: 15px 0 5px 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.navigationDark};
`

const ClientStatsDisplayWrapper = styled.div`
  border-radius: 6px;
  background: #f5f6f7;
  padding: 10px;
  height: 100%;
`

const ShowMoreLessLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.primary};
`

const MoreFieldsRow = styled(Row)`
  overflow-y: ${props => (props.showMoreClientFields ? 'visible' : 'hidden')};
  max-height: ${props => (props.showMoreClientFields ? 'auto' : '0')}; // max height for mobile width layout
  will-change: max-height;
  transition: max-height 0.2s ease;
`

const FormContainer = styled(ContainerFluid).attrs({ sidePadding: '20px' })``

const FieldCol = styled(Col).attrs({ sidePadding: '5px' })`
  padding-top: 5px;
  padding-bottom: 5px;
`

const genderOptions = Object.freeze(['FEMALE', 'MALE'].map(o => ({ name: o, value: o.toUpperCase() })))
const monthOptions = Object.freeze(moment.monthsShort().map((m, i) => ({ name: m, value: i + 1 })))
const dayOptions = Object.freeze(_.range(1, 32).map(i => ({ name: `${i}`, value: i })))

const SelectedClientForm = ({
  actions,
  venue,
  selectedClient,
  venueClientTagGroups,
  showMoreClientFields,
  validateFieldMap,
  formErrors,
  tagValidator,
  isSourceMode,
  bypassRequiredFields,
  isDisabled,
  lockableClientFields,
  alwaysLockedClientFields,
  canViewClientSpendData,
  salutationOptions = [],
}) => {
  if (!selectedClient) {
    return null
  }
  const onTransitionEnd = () => {
    actions.changeBookStepHeight(isSourceMode ? 'source' : 'client')
  }
  const phoneRequired = venue.bookSettings.requirePhone || (venue.bookSettings.requireEitherPhoneOrEmail && !selectedClient.email_address)
  const emailRequired =
    venue.bookSettings.requireEmail || (venue.bookSettings.requireEitherPhoneOrEmail && !selectedClient.phone_number_formatted)
  const { salutation } = selectedClient
  const salutationOptionsWithCustomChoices = salutationOptions.map(salutationOption => ({
    name: salutationOption,
    value: salutationOption,
  }))
  if (!salutationOptions.includes(salutation)) {
    salutationOptionsWithCustomChoices.push({
      name: salutation,
      value: salutation,
    })
  }
  const disabledCheck = fieldName =>
    alwaysLockedClientFields.indexOf(fieldName) > -1 || (isDisabled && lockableClientFields.indexOf(fieldName) > -1)
  const firstNameField = (
    <TextInput
      label="FIRST NAME"
      placeholder="REQUIRED"
      charLimit={150}
      validator={ValidatorTypes.nameRequired}
      ref={e => (validateFieldMap.first_name = e)}
      isValid={!formErrors.first_name}
      value={selectedClient.first_name}
      onChange={v => actions.changeClientField('first_name', v)}
      disabled={disabledCheck('first_name')}
    />
  )
  const lastNameField = (
    <TextInput
      label="LAST NAME"
      placeholder={venue.bookSettings.requireLastName ? 'REQUIRED' : ''}
      charLimit={150}
      validator={venue.bookSettings.requireLastName ? ValidatorTypes.nameRequired : null}
      ref={e => (validateFieldMap.last_name = e)}
      isValid={!formErrors.last_name}
      value={selectedClient.last_name}
      onChange={v => actions.changeClientField('last_name', v)}
      disabled={disabledCheck('last_name')}
    />
  )
  const salutationField = (
    <DropdownArrowsPicker
      name={`SALUTATION${venue.bookSettings.requireSalutation && !bypassRequiredFields ? '*' : ''}`}
      data-test={`sr-picker-salutation${venue.bookSettings.requireSalutation && '*'}`}
      choices={salutationOptionsWithCustomChoices}
      value={salutation}
      isLightTheme
      useOutsideLabel
      disabled={disabledCheck('salutation')}
      onChangeHandler={v => actions.changeClientField('salutation', v)}
      validator={venue.bookSettings.requireSalutation && !bypassRequiredFields ? ValidatorTypes.notEmpty : null}
      ref={e => (validateFieldMap.salutation = e?.instanceRef)}
      isValid={!formErrors.salutation}
      optionsContainerStyle={{
        width: 160,
        minWidth: 160,
      }}
      style={{
        minWidth: 80,
        width: '100%',
      }}
    />
  )

  const phoneField = (
    <PhoneTextInput
      disabled={!selectedClient.is_phone_editable || disabledCheck('phone_number')}
      label="MOBILE NUMBER"
      dataTest="sr-actual-slideout-mobile-number"
      placeholder={!bypassRequiredFields && phoneRequired ? 'REQUIRED' : ''}
      country={selectedClient.phone_number_locale !== 'INTL' ? selectedClient.phone_number_locale : venue.countryCode}
      value={selectedClient.phone_number_formatted}
      displayInitialValueAsLocalNumber={venue.countryCode === selectedClient.phone_number_locale}
      onChange={v => actions.changeClientField('phone_number_formatted', v)}
      onCountryChange={v => actions.changeClientField('phone_number_locale', v)}
      validator={!bypassRequiredFields && phoneRequired ? ValidatorTypes.phoneRequired : ValidatorTypes.phoneOptional}
      ref={e => (validateFieldMap.phone_number_formatted = e)}
      isValid={!formErrors.phone_number_formatted}
    />
  )

  const altPhoneField = (
    <PhoneTextInput
      disabled={!selectedClient.is_phone_editable || disabledCheck('phone_number_alt')}
      label="SECONDARY MOBILE NUMBER"
      dataTest="sr-actual-slideout-secondary-number"
      country={selectedClient.phone_number_alt_locale !== 'INTL' ? selectedClient.phone_number_alt_locale : venue.countryCode}
      placeholder=""
      value={selectedClient.phone_number_alt_formatted}
      displayInitialValueAsLocalNumber={venue.countryCode === selectedClient.phone_number_alt_locale}
      onChange={v => actions.changeClientField('phone_number_alt_formatted', v)}
      onCountryChange={v => actions.changeClientField('phone_number_alt_locale', v)}
      validator={ValidatorTypes.phoneOptional}
      ref={e => (validateFieldMap.phone_number_alt_formatted = e)}
      isValid={!formErrors.phone_number_alt_formatted}
    />
  )
  const emailField = (
    <TextInput
      disabled={!selectedClient.is_email_address_editable || disabledCheck('email')}
      label="EMAIL ADDRESS"
      placeholder={!bypassRequiredFields && emailRequired ? 'REQUIRED' : ''}
      charLimit={200}
      inputRestriction={InputRestrictions.email}
      validator={!bypassRequiredFields && emailRequired ? ValidatorTypes.emailRequired : ValidatorTypes.emailOptional}
      ref={e => (validateFieldMap.email_address = e)}
      isValid={!formErrors.email_address}
      value={selectedClient.email_address}
      onChange={v => actions.changeClientField('email_address', v)}
    />
  )
  const altEmailField = (
    <TextInput
      disabled={!selectedClient.is_email_address_editable || disabledCheck('email_alt')}
      label="ALT EMAIL"
      charLimit={200}
      inputRestriction={InputRestrictions.email}
      validator={ValidatorTypes.emailOptional}
      ref={e => (validateFieldMap.email_address_alt = e)}
      isValid={!formErrors.email_address_alt}
      value={selectedClient.email_address_alt}
      onChange={v => actions.changeClientField('email_address_alt', v)}
    />
  )

  const clientTagsField = (
    <GenericTagsDropDown
      name="CLIENT TAGS"
      tagGroups={venueClientTagGroups}
      venueId={venue.id}
      selectedTags={selectedClient.client_tags}
      onChangeHandler={actions.changeClientTags}
      ref={e => (validateFieldMap.client_tags = e && e.getInstance())}
      isValid={!formErrors.client_tags}
      customValidator={tagValidator}
      height="44px"
      width="100%"
    />
  )
  const notesField = (
    <TextInput
      label="PROFILE NOTES"
      charLimit={5000}
      isMultiLine
      forceIndent
      minRows={1}
      maxRows={3}
      value={selectedClient.notes}
      onChange={v => actions.changeClientField('notes', v)}
    />
  )
  const companyField = (
    <TextInput
      label="COMPANY"
      charLimit={200}
      inputRestriction={InputRestrictions.name}
      value={selectedClient.company}
      onChange={v => actions.changeClientField('company', v)}
    />
  )
  const titleField = (
    <TextInput
      label="TITLE (POSITION)"
      charLimit={200}
      inputRestriction={InputRestrictions.name}
      value={selectedClient.title}
      onChange={v => actions.changeClientField('title', v)}
    />
  )
  const genderField = (
    <SegmentedControl
      options={genderOptions}
      value={selectedClient.gender}
      width={245}
      height={44}
      theme="light"
      onChangeHandler={v => actions.changeClientField('gender', v)}
    />
  )
  const anniversaryMonthField = (
    <DropdownArrowsPicker
      name="ANNIVERSARY (MO)"
      choices={monthOptions}
      value={selectedClient.anniversary_month}
      isLightTheme
      useOutsideLabel
      onChangeHandler={v => actions.changeClientField('anniversary_month', v)}
      style={{
        minWidth: 80,
        width: '48%',
        float: 'left',
      }}
    />
  )
  const anniversaryDayField = (
    <DropdownArrowsPicker
      name="ANNIVERSARY (DAY)"
      choices={dayOptions}
      value={selectedClient.anniversary_day}
      isLightTheme
      useOutsideLabel
      onChangeHandler={v => actions.changeClientField('anniversary_day', v)}
      style={{
        minWidth: 80,
        width: '48%',
        float: 'left',
        marginRight: 0,
      }}
    />
  )
  const birthdayMonthField = (
    <DropdownArrowsPicker
      name="BIRTHDAY (MO)"
      choices={monthOptions}
      value={selectedClient.birthday_month}
      isLightTheme
      useOutsideLabel
      onChangeHandler={v => actions.changeClientField('birthday_month', v)}
      style={{
        minWidth: 80,
        width: '48%',
        float: 'left',
      }}
    />
  )
  const birthdayDayField = (
    <DropdownArrowsPicker
      name="BIRTHDAY (DAY)"
      choices={dayOptions}
      value={selectedClient.birthday_day}
      isLightTheme
      useOutsideLabel
      onChangeHandler={v => actions.changeClientField('birthday_day', v)}
      style={{
        minWidth: 80,
        width: '48%',
        float: 'left',
        marginRight: 0,
      }}
    />
  )
  const addressField = (
    <TextInput
      label="STREET"
      charLimit={200}
      value={selectedClient.address}
      onChange={v => actions.changeClientField('address', v)}
      disabled={disabledCheck('address')}
    />
  )
  const address2Field = (
    <TextInput
      label="ADDRESS LINE 2"
      charLimit={200}
      value={selectedClient.address_2}
      onChange={v => actions.changeClientField('address_2', v)}
      disabled={disabledCheck('address_2')}
    />
  )
  const cityField = (
    <TextInput
      label="CITY"
      charLimit={200}
      inputRestriction={InputRestrictions.name}
      value={selectedClient.city}
      onChange={v => actions.changeClientField('city', v)}
      disabled={disabledCheck('city')}
    />
  )
  const stateField = (
    <TextInput
      label="STATE"
      charLimit={200}
      inputRestriction={InputRestrictions.name}
      value={selectedClient.state}
      onChange={v => actions.changeClientField('state', v)}
      disabled={disabledCheck('state')}
    />
  )
  const zipField = (
    <TextInput
      label="ZIP"
      charLimit={200}
      value={selectedClient.postal_code}
      onChange={v => actions.changeClientField('postal_code', v)}
      disabled={disabledCheck('postal_code')}
    />
  )
  const countryField = (
    <TextInput
      label="COUNTRY"
      charLimit={200}
      inputRestriction={InputRestrictions.name}
      value={selectedClient.country}
      onChange={v => actions.changeClientField('country', v)}
      disabled={disabledCheck('country')}
    />
  )

  const loyaltyIdField = (
    <TextInput
      label="LOYALTY ID"
      value={selectedClient.loyalty_id}
      onChange={v => actions.changeClientField('loyalty_id', v)}
      disabled={disabledCheck('loyalty_id')}
    />
  )

  const loyaltyTierField = (
    <TextInput
      label="LOYALTY TIER"
      value={selectedClient.loyalty_tier}
      onChange={v => actions.changeClientField('loyalty_tier', v)}
      disabled={disabledCheck('loyalty_tier')}
    />
  )

  const loyaltyRankField = (
    <TextInput
      label="LOYALTY RANK"
      charLimit={64}
      inputRestriction={InputRestrictions.integer}
      value={selectedClient.loyalty_rank}
      onChange={v => actions.changeClientField('loyalty_rank', v)}
      disabled={disabledCheck('loyalty_rank')}
    />
  )

  const customFieldInputs = venue.customFieldsConfig.client.reduce((result, customField) => {
    if (!customField.enabled) {
      return result
    }
    const isIndexedField = Boolean(customField.db_name)
    const value =
      (isIndexedField
        ? selectedClient[customField.db_name]
        : selectedClient.custom_unindexed && selectedClient.custom_unindexed[customField.system_name]) || ''
    if (value === '' && !customField.editable) {
      return result
    }
    const onChangeCustomField = isIndexedField
      ? v => actions.changeClientField(customField.db_name, v)
      : v => actions.changeClientCustomUnindexedField(customField.system_name, v)
    result.push(
      <TextInput
        label={customField.name.toUpperCase()}
        charLimit={1000}
        value={value}
        onChange={onChangeCustomField}
        disabled={!customField.editable}
      />
    )
    return result
  }, [])

  return (
    <div>
      <SelectedClientHeader>
        <ClientHeaderTitle>{selectedClient.name_display}</ClientHeaderTitle>
        <DeselectClientButton onClick={() => actions.deselectClient(selectedClient)}>Deselect</DeselectClientButton>
      </SelectedClientHeader>
      <HorizontalLine />
      <FormContainer>
        <Row>
          <Col xs={12} md={9}>
            <Row>
              <FieldCol xs={12} md={5}>
                {firstNameField}
              </FieldCol>
              <FieldCol xs={12} md={5}>
                {lastNameField}
              </FieldCol>
              <FieldCol xs={12} md={2}>
                {salutationField}
              </FieldCol>
            </Row>
            <Row>
              <Col xs={12} md={7}>
                <Row>
                  <FieldCol xs={12}>{phoneField}</FieldCol>
                </Row>
                <Row>
                  <FieldCol xs={12}>{altPhoneField}</FieldCol>
                </Row>
              </Col>
              <Col xs={12} md={5}>
                <Row>
                  <FieldCol xs={12} md={12}>
                    {emailField}
                  </FieldCol>
                </Row>
                <Row>
                  <FieldCol xs={12} md={12}>
                    {altEmailField}
                  </FieldCol>
                </Row>
              </Col>
            </Row>
          </Col>
          <FieldCol xs={12} md={3}>
            <ClientStatsDisplayWrapper>
              <ClientStatsDisplay client={selectedClient} isNarrowFormat canViewClientSpendData={canViewClientSpendData} />
            </ClientStatsDisplayWrapper>
          </FieldCol>
        </Row>
        <Row>
          <FieldCol xs={12} md={12}>
            {clientTagsField}
          </FieldCol>
        </Row>
        <Row>
          <FieldCol xs={12} md={12}>
            {notesField}
          </FieldCol>
        </Row>
        <MoreFieldsRow {...{ showMoreClientFields, onTransitionEnd }}>
          <Col>
            <Row>
              <FieldCol xs={12}>
                <FieldGroupTitle>PERSONAL</FieldGroupTitle>
              </FieldCol>
            </Row>
            <Row>
              <FieldCol xs={12} md={4}>
                {companyField}
              </FieldCol>
              <FieldCol xs={12} md={4}>
                {titleField}
              </FieldCol>
              <FieldCol xs={12} md={4} style={{ paddingTop: 20 }}>
                {genderField}
              </FieldCol>
            </Row>
            <Row>
              <FieldCol xs={12} md={4}>
                {anniversaryMonthField}
                {anniversaryDayField}
              </FieldCol>
              <FieldCol xs={12} md={4}>
                {birthdayMonthField}
                {birthdayDayField}
              </FieldCol>
            </Row>
            <Row>
              <FieldCol xs={12}>
                <FieldGroupTitle>ADDRESS</FieldGroupTitle>
              </FieldCol>
            </Row>
            <Row>
              <FieldCol xs={12} md={4}>
                {addressField}
              </FieldCol>
              <FieldCol xs={12} md={4}>
                {address2Field}
              </FieldCol>
              <FieldCol xs={12} md={4}>
                {cityField}
              </FieldCol>
            </Row>
            <Row>
              <FieldCol xs={12} md={4}>
                {stateField}
              </FieldCol>
              <FieldCol xs={12} md={4}>
                {zipField}
              </FieldCol>
              <FieldCol xs={12} md={4}>
                {countryField}
              </FieldCol>
            </Row>
            <Row>
              <FieldCol xs={12}>
                <FieldGroupTitle>LOYALTY</FieldGroupTitle>
              </FieldCol>
            </Row>
            <Row>
              <FieldCol xs={12} md={4}>
                {loyaltyIdField}
              </FieldCol>
              <FieldCol xs={12} md={4}>
                {loyaltyTierField}
              </FieldCol>
              <FieldCol xs={12} md={4}>
                {loyaltyRankField}
              </FieldCol>
            </Row>
            {!_.isEmpty(customFieldInputs) && (
              <Row>
                <FieldCol xs={12}>
                  <FieldGroupTitle>OTHER</FieldGroupTitle>
                </FieldCol>
              </Row>
            )}
            {customFieldInputs
              .reduce((rowResults, customField, index) => {
                const rowIndex = Math.floor(index / 3)
                if (!rowResults[rowIndex]) {
                  rowResults[rowIndex] = []
                }
                rowResults[rowIndex].push(customField)
                return rowResults
              }, [])
              .map((rowItems, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={idx}>
                  {rowItems.map((customFieldInput, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <FieldCol xs={12} md={4} key={idx}>
                      {customFieldInput}
                    </FieldCol>
                  ))}
                </Row>
              ))}
          </Col>
        </MoreFieldsRow>
        <Row>
          <FieldCol xs={12}>
            <FieldGroupTitle>
              <ShowMoreLessLink data-test="sr-button-show_more" onClick={actions.toggleShowMoreClientFields}>
                {showMoreClientFields ? 'SHOW LESS' : 'SHOW MORE'}
              </ShowMoreLessLink>
            </FieldGroupTitle>
          </FieldCol>
        </Row>
      </FormContainer>
    </div>
  )
}

SelectedClientForm.propTypes = {
  actions: PropTypes.object.isRequired,
  venue: PropTypes.object,
  selectedClient: PropTypes.object,
  venueClientTagGroups: PropTypes.array.isRequired,
  showMoreClientFields: PropTypes.bool,
  validateFieldMap: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    client_tags: PropTypes.string,
    email_address_alt: PropTypes.string,
    email_address: PropTypes.string,
  }).isRequired,
  formErrors: PropTypes.object.isRequired,
  tagValidator: PropTypes.func,
  isSourceMode: PropTypes.bool.isRequired,
  bypassRequiredFields: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  canViewClientSpendData: PropTypes.bool,
  salutationOptions: PropTypes.arrayOf(PropTypes.string),
}

SelectedClientForm.defaultProps = {
  actions: {},
  isSourceMode: false,
  bypassRequiredFields: false,
  isDisabled: false,
  salutationOptions: [],
}

export default SelectedClientForm
