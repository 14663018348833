import { defineMessages } from '@sevenrooms/core/locales'

export const AvailabilityLocales = defineMessages({
  noTimeslotDescriptionTypeName: {
    id: 'availability.noTimeslotDescriptionTypeName',
    defaultMessage: 'No Time Slot Description',
  },
  noTimeslotDescriptionTitle: {
    id: 'availability.noTimeslotDescriptionTitle',
    defaultMessage: 'Access Rule',
  },
  allTypesName: {
    id: 'availability.allTypesName',
    defaultMessage: 'All Access Rules',
  },
  byPublicDescription: {
    id: 'availability.byPublicDescription',
    defaultMessage: 'BY PUBLIC DESCRIPTION',
  },
  byOffer: {
    id: 'availability.byOffer',
    defaultMessage: 'BY OFFER',
  },
} as const)
