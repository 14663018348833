import React from 'react'
import styled from 'styled-components'
import { FlexRowListContainer } from 'mgr/actualslideout/components/view/ViewLayout'

export const LogEntryText = styled.span`
  color: ${props => props.theme.darkGrey};
  font-size: 13px;
  white-space: pre-line;
`
export const AuthorBold = styled(LogEntryText)`
  font-size: 11px;
  font-weight: 600;
`
export const LogTime = styled.span`
  color: ${props => props.theme.mediumSilver};
  font-size: 11px;
  font-weight: 500;
  padding-left: 5px;
`
export const LogDevice = styled(LogTime)`
  font-weight: 900;
`
export const LogEntryWrapper = styled(FlexRowListContainer)`
  margin-bottom: 20px !important;
`

export const LogEntryMessageWrapper = styled(LogEntryWrapper)`
  padding-left: 4px !important;
  padding-right: 4px !important;
`

const UNKNOWN_INITIALS = '??'
export const makeInitials = nameString => {
  if (!nameString) {
    return UNKNOWN_INITIALS
  }

  const nameSplit = nameString.split(' ')
  let initials = UNKNOWN_INITIALS
  if (nameSplit.length >= 2) {
    initials = nameSplit[0][0] + nameSplit[1][0]
  } else {
    initials = nameSplit[0][0]
  }

  return initials.toUpperCase()
}
