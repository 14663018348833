import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as AttachmentActions from 'mgr/actualslideout/actions/AttachmentActions'
import * as MessagingAndActivityLogActions from 'mgr/actualslideout/actions/MessagingAndActivityLogActions'
import ActivityLogComponent from 'mgr/actualslideout/components/view/ActivityLogComponent'
import MessageDisplay, { NotesArea } from 'mgr/actualslideout/components/view/MessageDisplay'
import { FULL_HEIGHT } from 'mgr/actualslideout/components/view/ViewCommon'
import { isPastActualTime } from 'mgr/actualslideout/actions/ViewActualActions'
import { ChatMessageBox } from 'mgr/actualslideout/components/ViewComponents'
import { TAB_ACTIONS } from 'mgr/actualslideout/reducers/ViewResReducer'
import { FlexColumnContainer } from '../../components/view/ViewLayout'
import MessagingTabs from './MessagingTabs'
import { useConversationBackwriter } from '@sevenrooms/mgr-core/hooks/useBackwriter'
import { ReservationStatuses } from 'svr/manager/lib/utils/Statuses'
import { showNotificationError } from 'mgr/actualslideout/actions/SlideoutActions'

const ResendNotificationButton = styled.button`
  fontsize: 12px;
  background-color: white;
  border: 1px solid #858e95;
  border-radius: 3px;
  margin-right: 3px;
  margin-left: 3px;
`

const ResendNotificationWrapper = styled.div`
  box-shadow: 0 1px 0 0 rgba(133, 142, 149, 0.2);
  text-align: center;
  width: 100%;
  padding: 16px 0;
  margin-bottom: 4px;
`

const Messaging = ({
  externalMessages,
  internalComments,
  logEntries,
  selectedTabAction,
  attachmentsForCommentsBuffer,
  attachmentsForMessagesBuffer,
  isPastActualTime,
  hasEmail,
  actions,
  venueId,
  actual,
  isResendingBookingNotification,
}) => {
  const [chatBoxHeight, setChatBoxHeight] = useState()
  const [message, setMessage] = useState('')
  const [showSendButton, setShowSendButton] = useState(false)
  useEffect(() => {
    setMessage('')
    setShowSendButton(false)
  }, [selectedTabAction])

  const { isEnabled, isLoading, generatedText, errorMessage, generateResponse } = useConversationBackwriter()

  const conversationId = externalMessages?.length > 0 && externalMessages[0].conversation_id

  const generateConversationResponse = promptType => generateResponse({ promptType, currentMessage: message, venueId, conversationId })

  useEffect(() => {
    if (errorMessage) {
      actions.showErrorMessage(errorMessage)
    }
  }, [actions, errorMessage])

  let messageArea = null
  let messageEntryBox = null
  let areaAboveMessageArea = null
  switch (selectedTabAction) {
    case TAB_ACTIONS.MESSAGING:
      messageArea = (
        <MessageDisplay
          messages={externalMessages}
          venueId={venueId}
          generateResponse={generateConversationResponse}
          isBackwriterEnabled={isEnabled}
          messageEntryBoxHeight={chatBoxHeight}
          showSendButton={showSendButton}
          currentMessage={message}
        />
      )
      messageEntryBox = (
        <ChatMessageBox
          hasEmail={hasEmail}
          placeholder="Send an email message..."
          sendMessage={actions.sendExternalMessage}
          addAttachment={actions.saveAttachment}
          deleteAttachment={actions.deleteExternalMessageAttachment}
          bufferedAttachments={attachmentsForMessagesBuffer}
          isBackwriterEnabled={isEnabled}
          showBackwriterLoader={isLoading}
          backwriterMessage={generatedText}
          height={chatBoxHeight}
          setHeight={setChatBoxHeight}
          message={message}
          setMessage={setMessage}
          showSendButton={showSendButton}
          setShowSendButton={setShowSendButton}
          generateResponse={generateConversationResponse}
        />
      )
      areaAboveMessageArea =
        !isPastActualTime && actual?.status !== ReservationStatuses.CANCELED && (actual?.email_address || actual?.phone_number) ? (
          <ResendNotificationWrapper>
            Resend Booking Notification:
            {actual?.email_address && (
              <ResendNotificationButton
                onClick={actions.tryResendingBookingEmail}
                data-test="sr-resend-email"
                disabled={isResendingBookingNotification}
              >
                Send email
              </ResendNotificationButton>
            )}
            {window.globalInit.venueSettings.sms_booking_notification_enabled &&
            actual?.phone_number &&
            actual?.reservation_sms_opt_in !== false ? (
              <ResendNotificationButton
                onClick={actions.tryResendingBookingSms}
                data-test="sr-resend-sms"
                disabled={isResendingBookingNotification}
              >
                Send SMS
              </ResendNotificationButton>
            ) : null}
          </ResendNotificationWrapper>
        ) : null
      break
    case TAB_ACTIONS.COMMENTS:
      messageArea = <NotesArea notes={internalComments} />
      messageEntryBox = (
        <ChatMessageBox
          hasEmail
          placeholder="Add internal comment..."
          sendMessage={actions.sendInternalMessage}
          addAttachment={actions.saveAttachment}
          deleteAttachment={actions.deleteInternalCommentAttachment}
          bufferedAttachments={attachmentsForCommentsBuffer}
          height={chatBoxHeight}
          setHeight={setChatBoxHeight}
          message={message}
          setMessage={setMessage}
          showSendButton={showSendButton}
          setShowSendButton={setShowSendButton}
        />
      )
      break
    case TAB_ACTIONS.ACTIVITY:
      messageArea = <ActivityLogComponent logEntries={logEntries} />
      break
    default:
      messageArea = null
      break
  }

  return (
    <FlexColumnContainer style={FULL_HEIGHT}>
      <MessagingTabs />
      {areaAboveMessageArea}
      {messageArea}
      {messageEntryBox}
    </FlexColumnContainer>
  )
}

const mapStateToProps = state => ({
  internalComments: state.viewResState.internalComments,
  externalMessages: state.viewResState.externalMessages,
  venueId: !_.isNil(state.viewResState.viewVenue) ? state.viewResState.viewVenue.id : '',
  attachmentsForCommentsBuffer: state.viewResState.attachmentsForCommentsBuffer,
  hasEmail: (state.viewResState.actual || {}).has_chat_recipient_email,
  attachmentsForMessagesBuffer: state.viewResState.attachmentsForMessagesBuffer,
  logEntries: state.viewResState.activityLog,
  selectedTabAction: state.viewResState.selectedTabAction,
  actual: state.viewResState.actual,
  isResendingBookingNotification: state.viewResState.isResendingBookingNotification,
  isPastActualTime:
    state.viewResState.actual && state.viewResState.viewVenue && isPastActualTime(state.viewResState.actual, state.viewResState.viewVenue),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendExternalMessage: MessagingAndActivityLogActions.tryPutExternalMessage,
      sendInternalMessage: MessagingAndActivityLogActions.tryPutInternalMessage,
      deleteExternalMessageAttachment: AttachmentActions.tryDeleteMessageAttachment,
      deleteInternalCommentAttachment: AttachmentActions.tryDeleteCommentAttachment,
      tryResendingBookingEmail: MessagingAndActivityLogActions.tryResendingBookingEmail,
      tryResendingBookingSms: MessagingAndActivityLogActions.tryResendingBookingSms,
      saveAttachment: AttachmentActions.trySaveAttachment,
      showErrorMessage: showNotificationError,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Messaging)
