import _ from 'lodash'
import {
  GET_VENUE_LANGUAGES_START,
  GET_VENUE_LANGUAGES_SUCCESS,
  GET_VENUE_LANGUAGES_FAIL,
  CHANGE_VENUE_LANGUAGE,
  SET_DEFAULT_LANGUAGE,
  TOGGLE_LANGUAGE_ENABLED,
  SAVE_VENUE_LANGUAGE_SETTINGS_START,
  SAVE_VENUE_LANGUAGE_SETTINGS_FAIL,
  SAVE_VENUE_LANGUAGE_SETTINGS_SUCCESS,
  RESET_VENUE_LANGUAGE_TO_INITIAL,
  RESET_LANGUAGE_SETTINGS,
} from 'mgr/pages/single-venue/settings/actions/ActionTypes'

const initial = {
  venueLanguages: [],
  defaultLanguage: null,
  selectedLanguage: {
    name: '',
    is_default: false,
    value: '',
    is_enabled: true,
  },
}
const initialState = {
  ...initial,
  isLoading: false,
  editLanguageSettingsObj: {
    ...initial,
  },
}

const getInitialSelectedLanguage = (languages, defaultLanguage) => {
  const initialSelectedLanguage = _.find(languages, language => language.is_enabled && language.value !== defaultLanguage.value)
  return initialSelectedLanguage || {}
}

const languagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENUE_LANGUAGES_START:
    case SAVE_VENUE_LANGUAGE_SETTINGS_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_VENUE_LANGUAGES_FAIL:
    case SAVE_VENUE_LANGUAGE_SETTINGS_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case SAVE_VENUE_LANGUAGE_SETTINGS_SUCCESS:
      return {
        ...state,
        venueLanguages: state.editLanguageSettingsObj.venueLanguages,
        defaultLanguage: state.editLanguageSettingsObj.defaultLanguage,
        isLoading: false,
      }
    case RESET_LANGUAGE_SETTINGS:
      return {
        ...state,
        editLanguageSettingsObj: {
          venueLanguages: state.venueLanguages,
          defaultLanguage: state.defaultLanguage,
          selectedLanguage: state.selectedLanguage,
        },
      }
    case TOGGLE_LANGUAGE_ENABLED: {
      const updatedLanguage = action.language
      const { editLanguageSettingsObj } = state
      const updatedLanguages = state.editLanguageSettingsObj.venueLanguages.map(language => ({
        ...language,
        is_enabled: updatedLanguage.value === language.value ? !updatedLanguage.is_enabled : language.is_enabled,
      }))

      return {
        ...state,
        editLanguageSettingsObj: {
          ...editLanguageSettingsObj,
          venueLanguages: updatedLanguages,
        },
      }
    }
    case SET_DEFAULT_LANGUAGE: {
      const updatedLanguage = action.language
      const { editLanguageSettingsObj } = state
      const updatedLanguages = state.editLanguageSettingsObj.venueLanguages.map(language => ({
        ...language,
        is_default: updatedLanguage.value === language.value,
      }))

      return {
        ...state,
        editLanguageSettingsObj: {
          ...editLanguageSettingsObj,
          venueLanguages: updatedLanguages,
          defaultLanguage: updatedLanguage,
        },
      }
    }
    case GET_VENUE_LANGUAGES_SUCCESS:
      const initialLanguageSettings = {
        venueLanguages: action.data.venueLanguages,
        defaultLanguage: action.data.defaultLanguage,
        selectedLanguage: getInitialSelectedLanguage(action.data.venueLanguages, action.data.defaultLanguage),
      }

      return {
        ...state,
        isLoading: false,
        ...initialLanguageSettings,
        editLanguageSettingsObj: {
          ...initialLanguageSettings,
        },
      }
    case CHANGE_VENUE_LANGUAGE:
      const selectedLanguage = state.venueLanguages.find(language => language.value === action.languageCode)
      return {
        ...state,
        selectedLanguage,
      }
    case RESET_VENUE_LANGUAGE_TO_INITIAL:
      return {
        ...state,
        selectedLanguage: getInitialSelectedLanguage(action.languages, action.defaultLanguage),
      }
    default:
      return state
  }
}

export default languagesReducer
