import React from 'react'
import styled from 'styled-components'
import { makeOptionalAttr } from 'mgr/layout/StyledComponentUtils'

export const FlexBoxContainer = styled.div`
  display: flex;
  list-style: none;
  ${props => makeOptionalAttr('margin', props.margin)} ${props => makeOptionalAttr('padding', props.padding)}
    flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  height: auto;
`

export const FlexRowContainer = styled(FlexBoxContainer)`
  flex-direction: row;
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  ${props => makeOptionalAttr('flex-wrap', props.flexWrap)};
`
export const FlexRowListContainer = React.forwardRef((props, ref) => <FlexRowContainer {...props} as="li" ref={ref} />)

export const FlexHorizontalUlElement = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  ${props => makeOptionalAttr('align-items', props.alignItems)} ${props => makeOptionalAttr('justify-content', props.justifyContent)};
`

export const FlexColumnContainer = styled(FlexBoxContainer)`
  flex-direction: column;
  ${props => makeOptionalAttr('align-items', props.alignItems)} ${props => makeOptionalAttr('justify-content', props.justifyContent)};
`

export const ScrollableList = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
`
