/* eslint-disable no-param-reassign, consistent-return, no-unused-expressions, no-console */
import _ from 'lodash'
import money from 'money-math'
import { makeServiceTimeout } from 'mgr/lib/utils/DebugUtils'
import { getMessageDestinationString } from '../utils/Constants'
import { srGet, srPost, srPut } from '@sevenrooms/core/api'
import { transformUpgrades } from '@sevenrooms/mgr-reservation-slideout/Payment/payment-utils'

const hydrateActual = actual => {
  if (_.isNil(actual)) {
    return actual
  }
  const actualObj = { ...actual }
  actualObj.date_moment = moment(actual.date_urlparam, 'MM-DD-YYYY')
  return actualObj
}

const adaptSpecialPriceOverrideOption = (formParams, adjustedSelectedCostOption, option) => {
  const formParamsObj = { ...formParams }

  if (adjustedSelectedCostOption === option) {
    formParamsObj[option] = 1
    delete formParamsObj.min_price_override
    delete formParamsObj.min_price_override_type
  }
  return formParamsObj
}

const fetchActual = (venueId, actualId, errHandler) =>
  srGet(
    `/manager/${venueId}/actual/${actualId}/view`,
    {
      rid: Math.floor(Math.random() * 100 + 1),
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }

    const isResponseComplete = response.payload && response.payload.content && response.payload.content.actual

    if (isResponseComplete) {
      return hydrateActual(response.payload.content.actual)
    }
    return {}
  })

const makeIndexedFieldsDict = (customFields, prefix = '') =>
  _.mapKeys(
    {
      custom_group_0: customFields.custom_group_0,
      custom_group_1: customFields.custom_group_1,
      custom_group_2: customFields.custom_group_2,
      custom_group_3: customFields.custom_group_3,
      custom_group_4: customFields.custom_group_4,
      custom_venue_0: customFields.custom_venue_0,
      custom_venue_1: customFields.custom_venue_1,
      custom_venue_2: customFields.custom_venue_2,
      custom_venue_3: customFields.custom_venue_3,
      custom_venue_4: customFields.custom_venue_4,
    },
    (v, k) => `${prefix}${k}`
  )

const makeUnindexedFieldsDict = (unindexedFields, prefix = '') =>
  _.mapKeys(unindexedFields || {}, (v, k) => `${prefix}custom_unindexed_${k}`)

const makeSourceDict = (selectedSource, languages) =>
  _.mapKeys(makeSelectedClientDict(selectedSource, languages), (v, k) => `source_${k.replace(/^(client_)/, '')}`)

const makeSelectedClientDict = (selectedClient, languages) => {
  const result = {}
  if (!_.isObject(selectedClient)) {
    return result
  }

  result.venue_group_client_id = selectedClient.id

  // if you cant edit phone and/or email, don't submit them. This prevents frontend validation errors
  // actual validation handled on backend
  if (selectedClient.is_phone_editable) {
    result.phone_number = selectedClient.phone_number_formatted
    result.phone_number_alt = selectedClient.phone_number_alt_formatted
  }
  if (selectedClient.is_email_address_editable) {
    result.email = selectedClient.email_address
    result.email_alt = selectedClient.email_address_alt
  }

  if (languages.length === 1 && !selectedClient.preferred_language_code) {
    selectedClient.preferred_language_code = languages[0].value
  }

  result.salutation_custom = ''
  result.one_liner = selectedClient.notes
  result.client_tag_full_hash = (selectedClient.client_tags || []).map(t => t.tagHash)
  // current supported logic for internal bookings, could change in the future.
  result.supports_view_only_timeslots = !!result.client_tag_full_hash.length
  for (const k of [
    'first_name',
    'last_name',
    'company',
    'phone_number_locale',
    'salutation',
    'title',
    'phone_number_alt_locale',
    'gender',
    'birthday_month',
    'birthday_day',
    'anniversary_month',
    'anniversary_day',
    'address',
    'address_2',
    'city',
    'state',
    'postal_code',
    'country',
    'external_user_id',
    'is_status_active',
    'loyalty_id',
    'loyalty_tier',
    'loyalty_rank',
    'preferred_language_code',
  ]) {
    result[k] = selectedClient[k]
  }
  Object.assign(result, makeIndexedFieldsDict(selectedClient, 'client_'))
  Object.assign(result, makeUnindexedFieldsDict(selectedClient.custom_unindexed, 'client_'))
  return result
}

const makeGratuityDict = ({ applyGratuityCharge, paylinkGratuityType, cardEntryOption, gratuityCharge, gratuityChargeAmount }) => {
  const charge = gratuityCharge?.replace ? money.floatToAmount(gratuityCharge.replace(',', '.')) : gratuityCharge
  const clientSelectGratuity = applyGratuityCharge && paylinkGratuityType !== 'gratuity_percentage'

  return {
    apply_gratuity_charge: applyGratuityCharge,
    client_select_gratuity: clientSelectGratuity,
    require_select_gratuity: applyGratuityCharge && paylinkGratuityType === 'require_client_select_charge',
    gratuity_charge: applyGratuityCharge && !(clientSelectGratuity && cardEntryOption === 'paylink') ? charge : null,
    tip_percent: applyGratuityCharge && !(clientSelectGratuity && cardEntryOption === 'paylink') ? charge : null, // legacy unused field?
    gratuity_amount: applyGratuityCharge ? gratuityChargeAmount : null,
  }
}

const makeUpgradesDict = (paymentData, isNoPayment, isNoGratuity) => {
  if (_.isEmpty(paymentData.upgrades)) {
    return {}
  }
  const upgrades = transformUpgrades(paymentData.upgrades, isNoPayment, isNoGratuity)
  return {
    upsell_amount: upgrades.upsellAmount,
    automatically_included_upsells: JSON.stringify(upgrades.automaticallyIncludedUpsells),
    selected_upsells: JSON.stringify(upgrades.selectedUpsells),
    charge_upsell_snapshot: upgrades.chargeUpsellSnapshot ? JSON.stringify(upgrades.chargeUpsellSnapshot) : undefined,
  }
}

const bookReservation = (
  {
    venueId,
    date,
    partySize,
    duration,
    usingDefaultDuration,
    selectedTimeSlot,
    selectedClient,
    selectedSource,
    reservationNotes,
    reservationTags,
    selectedTableIds,
    isAutoAssign,
    isCustomAssign,
    overbookDirective,
    selectedBookedBy,
    additionalBookedBy,
    newPromoterName,
    messagingFields,
    paymentData,
    isConciergeReservation,
    perks,
    costOptionAmount,
    adjustedSelectedCostOption,
    customFields,
    override,
    overrideUpgrades,
    cancelDuplicate,
    overlappingResIds,
    audienceId,
    venueLocale,
    bufferMins,
    excludeFromShiftPacing,
    languages,
    reservationHoldId,
    experienceId,
    reservationRequestId,
  },
  errHandler
  // eslint-disable-next-line consistent-return
) => {
  try {
    const table_ids = _.map(selectedTableIds, 'value')
    if (isAutoAssign) {
      table_ids.push('auto')
    }
    if (isCustomAssign) {
      table_ids.push('custom')
    }
    const serviceCharge =
      paymentData.serviceCharge && paymentData.serviceCharge.replace
        ? money.floatToAmount(paymentData.serviceCharge.replace(',', '.'))
        : paymentData.serviceCharge

    const allPayments =
      paymentData.takePaymentOrSave === 'none'
        ? {
            payment_amount: '',
            base_amount: '',
            tax_amount: 0,
            apply_service_charge: false,
            service_charge_amount: 0,
            apply_gratuity_charge: false,
            ...makeUpgradesDict(paymentData, true, false),
          }
        : {
            payment_amount: paymentData.chargeTotal,
            base_amount: paymentData.chargeAmount,
            apply_tax: paymentData.chargeApplyTax ? paymentData.chargeTax : null,
            tax_group_id: paymentData.taxGroupId,
            tax_amount: paymentData.chargeApplyTax ? paymentData.taxAmount : 0,
            apply_service_charge: paymentData.applyServiceCharge,
            service_charge: paymentData.applyServiceCharge ? serviceCharge : null,
            service_charge_amount: paymentData.applyServiceCharge ? paymentData.serviceChargeAmount : 0,
            ...makeGratuityDict(paymentData),
            ...makeUpgradesDict(
              paymentData,
              false,
              paymentData.paylinkGratuityType === 'gratuity_percentage' && paymentData.cardEntryOption === 'paylink'
            ),
          }

    let formParams = _.reduce(
      {
        ...makeSelectedClientDict(selectedClient, languages),
        ...makeSourceDict(selectedSource, languages),
        venue_id: venueId,
        date_selected: date.format('MM-DD-YYYY'),
        reservation_request_id: reservationRequestId,
        res_type: 'TABLE',
        party_size_override: partySize,
        duration,
        using_default_duration: usingDefaultDuration ? 1 : 0,
        access_persistent_id: selectedTimeSlot.access_persistent_id,
        booking_channel: audienceId,
        shift_persistent_id: selectedTimeSlot.shift_persistent_id,
        venue_seating_area_id: selectedTimeSlot.seating_area_id,
        est_arrival_time: selectedTimeSlot.time,
        min_price_override: costOptionAmount,
        min_price_override_type: adjustedSelectedCostOption,
        venue_notes: reservationNotes,
        perks_freeform: perks,
        overbook_directive: overbookDirective,
        booked_by: selectedBookedBy && selectedBookedBy.id,
        new_promoter_name: _.isNil(newPromoterName) ? '' : newPromoterName,
        send_client_email_destination: getMessageDestinationString(
          messagingFields.sendClientEmailToClient,
          messagingFields.sendClientEmailToSource
        ),
        send_client_sms_destination: getMessageDestinationString(
          messagingFields.sendClientSMSToClient,
          messagingFields.sendClientSMSToSource
        ),
        hotel_confirmation: selectedClient.hotel_client?.confirmation_num,
        // 'MM/DD/YYYY' - is the format on the create, 'YYYY-MM-DD' is the format on the copy
        hotel_check_in_date: selectedClient.hotel_client?.check_in_date_display
          ? moment(selectedClient.hotel_client?.check_in_date_display, ['MM/DD/YYYY', 'YYYY-MM-DD']).format('MM-DD-YYYY')
          : '',
        hotel_check_out_date: selectedClient.hotel_client?.check_out_date_display
          ? moment(selectedClient.hotel_client?.check_out_date_display, ['MM/DD/YYYY', 'YYYY-MM-DD']).format('MM-DD-YYYY')
          : '',
        hotel_id: selectedClient.hotel_client?.selectedHotelId,
        hotel_room: selectedClient.hotel_client?.room_num,
        public_notes: messagingFields.personalMessage,
        update_message: messagingFields.personalMessage,
        send_reminder_email: messagingFields.sendReminderEmail ? 1 : 0,
        send_reminder_email_destination: getMessageDestinationString(
          messagingFields.sendReminderEmailToClient,
          messagingFields.sendReminderEmailToSource
        ),
        send_reminder_sms: messagingFields.sendReminderSms ? 1 : 0,
        send_reminder_sms_destination: getMessageDestinationString(
          messagingFields.sendReminderSmsToClient,
          messagingFields.sendReminderSmsToSource
        ),
        send_feedback_email: messagingFields.sendFeedbackEmail ? 1 : 0,
        send_feedback_email_destination: getMessageDestinationString(
          messagingFields.sendFeedbackEmailToClient,
          messagingFields.sendFeedbackEmailToSource
        ),
        send_marketing_opt_in_email: messagingFields.sendMarketingOptInEmail,
        force_request: '',
        reservation_tag_full_hash: reservationTags.map(t => t.tagHash),
        card_token: paymentData.cardToken,
        card_id: paymentData.selectedCardId,
        card_data: JSON.stringify(paymentData.cardData),
        cardholder_name: paymentData.cardHolderName,
        payment_action: paymentData.takePaymentOrSave === 'take' ? 'advanced_payment' : 'save_for_later',
        charge_description: paymentData.chargeDescription,
        ...allPayments,
        override_payment_requirement: paymentData.override ? true : '',
        override_existing_booking: override,
        override_upgrade_requirement: overrideUpgrades,
        cardholder_phone: !paymentData.useGuestProfilePhoneNumber ? paymentData.cardPhoneNumber : '',
        cardholder_phone_locale: !paymentData.useGuestProfilePhoneNumber ? paymentData?.cardPhoneCountry || venueLocale : '',
        overlapping_res_ids: overlappingResIds,
        table_ids,
        reservation_hold_id: reservationHoldId,
        ...makeIndexedFieldsDict(customFields),
        ...makeUnindexedFieldsDict(customFields.custom_unindexed),
        buffer_mins: bufferMins,
        exclude_from_shift_pacing: excludeFromShiftPacing,
        experience_id: experienceId,
      },
      (result, v, k) => {
        const resultObj = { ...result }
        resultObj[k] = _.isNil(v) ? '' : v
        return resultObj
      },
      {}
    )

    if (paymentData.takePaymentOrSave !== 'none') {
      formParams.is_paylink_selected = paymentData.cardEntryOption === 'paylink'
      if (paymentData.cardEntryOption === 'paylink') {
        formParams.paylink_auto_cancel = paymentData.paylinkAutoCancel === -1 ? null : paymentData.paylinkAutoCancel
        formParams.is_payment_shift_required = paymentData.cardRequired && !paymentData.override
      }
    }

    if (messagingFields.sendClientEmail) {
      formParams.send_client_email = 1
    }

    if (messagingFields.sendClientSMS) {
      formParams.send_client_sms = 1
    }

    formParams = adaptSpecialPriceOverrideOption(formParams, adjustedSelectedCostOption, 'comp_table')
    formParams = adaptSpecialPriceOverrideOption(formParams, adjustedSelectedCostOption, 'nomin_table')
    if (messagingFields.sendFeedbackEmail) {
      formParams.send_feedback_email = 1
    }

    if (!_.isEmpty(additionalBookedBy)) {
      formParams.booked_by_alt = additionalBookedBy.map(bbName => bbName.id)
    }

    const slowRequestTimer = makeServiceTimeout('Save booking timeout', 'ActualServices.jsx', 10 * 1000)
    // eslint-disable-next-line consistent-return
    return srPost(`/api-yoa/reservation/${venueId}/book`, formParams, { skipInterfaceError: true }).then(response => {
      clearTimeout(slowRequestTimer)
      if (response.error && errHandler) {
        errHandler(response.errorMsg)
      } else if (response.data && response.data.actual) {
        return [hydrateActual(response.data.actual), response]
      } else {
        errHandler('Procedural error encountered')
      }
      return []
    })
  } catch (e) {
    if (errHandler) {
      errHandler(null)
    }
    return []
  }
}

const saveEditReservation = (
  {
    actualId,
    venueId,
    date,
    partySize,
    duration,
    usingDefaultDuration,
    selectedTimeSlot,
    selectedClient,
    selectedSource,
    reservationNotes,
    reservationTags,
    selectedTableIds,
    isAutoAssign,
    isCustomAssign,
    overbookDirective,
    selectedBookedBy,
    additionalBookedBy,
    newPromoterName,
    messagingFields,
    paymentData,
    isConciergeReservation,
    perks,
    costOptionAmount,
    adjustedSelectedCostOption,
    customFields,
    override,
    overrideUpgrades,
    overlappingResIds,
    audienceId,
    venueLocale,
    bufferMins,
    excludeFromShiftPacing,
    languages,
    reservationHoldId,
    experienceId,
  },
  errHandler
  // eslint-disable-next-line consistent-return
) => {
  try {
    const table_ids = _.map(selectedTableIds, 'value')
    if (isAutoAssign) {
      table_ids.push('auto')
    }
    if (isCustomAssign) {
      table_ids.push('custom')
    }
    const serviceCharge =
      paymentData.serviceCharge && paymentData.serviceCharge.replace
        ? money.floatToAmount(paymentData.serviceCharge.replace(',', '.'))
        : paymentData.serviceCharge

    const allPayments =
      paymentData.takePaymentOrSave === 'none'
        ? {
            payment_amount: '',
            base_amount: '',
            apply_service_charge: false,
            apply_gratuity_charge: false,
            ...makeUpgradesDict(paymentData, true),
          }
        : {
            payment_amount: paymentData.chargeTotal,
            base_amount: paymentData.chargeAmount,
            apply_service_charge: paymentData.applyServiceCharge,
            service_charge: paymentData.applyServiceCharge ? serviceCharge : null,
            apply_tax: paymentData.chargeApplyTax ? paymentData.chargeTax : null,
            tax_group_id: paymentData.taxGroupId,
            ...makeGratuityDict(paymentData),
            ...makeUpgradesDict(paymentData),
          }

    let formParams = _.reduce(
      {
        ...makeSelectedClientDict(selectedClient, languages),
        ...makeSourceDict(selectedSource, languages),
        venue_id: venueId,
        date_selected: date.format('MM-DD-YYYY'),
        reservation_request_id: '',
        res_type: 'TABLE',
        party_size_override: partySize,
        duration,
        using_default_duration: usingDefaultDuration ? 1 : 0,
        shift_persistent_id: selectedTimeSlot.shift_persistent_id,
        access_persistent_id: selectedTimeSlot.access_persistent_id,
        booking_channel: audienceId,
        venue_seating_area_id: selectedTimeSlot.seating_area_id,
        est_arrival_time: selectedTimeSlot.time,
        is_accessrule_override: selectedTimeSlot.isAccessruleOverride,
        min_price_override: costOptionAmount,
        min_price_override_type: adjustedSelectedCostOption,
        venue_notes: reservationNotes,
        perks_freeform: perks,
        overbook_directive: overbookDirective,
        new_promoter_name: _.isNil(newPromoterName) ? '' : newPromoterName,
        send_update_email_destination: getMessageDestinationString(
          messagingFields.sendClientEmailToClient,
          messagingFields.sendClientEmailToSource
        ),
        send_client_sms_destination: getMessageDestinationString(
          messagingFields.sendClientSMSToClient,
          messagingFields.sendClientSMSToSource
        ),
        hotel_confirmation: selectedClient.hotel_client?.confirmation_num,
        hotel_check_in_date:
          selectedClient.hotel_client?.hotel_check_in_date || selectedClient.hotel_client?.check_in_date_display
            ? moment(selectedClient.hotel_client?.check_in_date_display, 'MM/DD/YYYY').format('MM-DD-YYYY')
            : '',
        hotel_check_out_date:
          selectedClient.hotel_client?.hotel_check_out_date || selectedClient.hotel_client?.check_out_date_display
            ? moment(selectedClient.hotel_client?.check_out_date_display, 'MM/DD/YYYY').format('MM-DD-YYYY')
            : '',
        hotel_id: selectedClient.hotel_client?.selectedHotelId,
        hotel_room: selectedClient.hotel_client?.room_num,
        public_notes: messagingFields.personalMessage,
        update_message: messagingFields.personalMessage,
        send_reminder_email: messagingFields.sendReminderEmail ? 1 : 0,
        send_reminder_email_destination: getMessageDestinationString(
          messagingFields.sendReminderEmailToClient,
          messagingFields.sendReminderEmailToSource
        ),
        send_reminder_sms: messagingFields.sendReminderSms ? 1 : 0,
        send_reminder_sms_destination: 'CLIENT',
        send_feedback_email: messagingFields.sendFeedbackEmail ? 1 : 0,
        send_feedback_email_destination: getMessageDestinationString(
          messagingFields.sendFeedbackEmailToClient,
          messagingFields.sendFeedbackEmailToSource
        ),
        send_marketing_opt_in_email: messagingFields.sendMarketingOptInEmail,
        force_request: '',
        reservation_tag_full_hash: reservationTags.map(t => t.tagHash),
        card_token: paymentData.cardToken,
        card_id: paymentData.resCardId,
        payment_action: paymentData.takePaymentOrSave === 'take' ? 'advanced_payment' : 'save_for_later',
        cardholder_phone: !paymentData.useGuestProfilePhoneNumber ? paymentData.cardPhoneNumber : '',
        cardholder_phone_locale: !paymentData.useGuestProfilePhoneNumber ? paymentData?.cardPhoneCountry || venueLocale : '',
        charge_description: paymentData.chargeDescription,
        ...allPayments,
        override_payment_requirement: paymentData.override ? true : '',
        override_existing_booking: override,
        override_upgrade_requirement: overrideUpgrades,
        overlapping_res_ids: overlappingResIds,
        table_ids,
        reservation_hold_id: reservationHoldId,
        ...makeIndexedFieldsDict(customFields),
        ...makeUnindexedFieldsDict(customFields.custom_unindexed),
        buffer_mins: bufferMins,
        exclude_from_shift_pacing: excludeFromShiftPacing,
        experience_id: experienceId,
      },
      (result, v, k) => {
        const resultObj = { ...result }
        resultObj[k] = _.isNil(v) ? '' : v
        return resultObj
      },
      {}
    )

    if (messagingFields.sendClientEmail) {
      formParams.send_update_email = 1
    }

    if (messagingFields.sendClientSMS) {
      formParams.send_client_sms = 1
    }

    formParams = adaptSpecialPriceOverrideOption(formParams, adjustedSelectedCostOption, 'comp_table')
    formParams = adaptSpecialPriceOverrideOption(formParams, adjustedSelectedCostOption, 'nomin_table')

    if (paymentData.takePaymentOrSave !== 'none') {
      formParams.is_paylink_selected = paymentData.cardEntryOption === 'paylink'
      if (paymentData.cardEntryOption === 'paylink') {
        formParams.paylink_auto_cancel = paymentData.paylinkAutoCancel === -1 ? null : paymentData.paylinkAutoCancel
        formParams.is_payment_shift_required = paymentData.cardRequired && !paymentData.override
      }
    }

    if (!isConciergeReservation) {
      formParams.booked_by = selectedBookedBy && selectedBookedBy.id
    }

    if (!_.isEmpty(additionalBookedBy)) {
      formParams.booked_by_alt = additionalBookedBy.map(bbName => bbName.id)
    }
    const slowRequestTimer = makeServiceTimeout('Edit booking timeout', 'ActualServices.jsx', 10 * 1000)
    // eslint-disable-next-line consistent-return
    return srPut(`/api-yoa/reservation/${venueId}/actual/${actualId}`, formParams, { skipInterfaceError: true }).then(response => {
      clearTimeout(slowRequestTimer)
      if (response.error && errHandler) {
        errHandler(response.errorMsg)
      } else if (response.data && response.data.actual) {
        return [hydrateActual(response.data.actual), response]
      } else {
        errHandler('Procedural error encountered')
      }
      return []
    })
  } catch (e) {
    if (errHandler) {
      errHandler(null)
    }
    return []
  }
}

const postResStatusChange = (venueId, actualId, status, errHandler) => {
  const url = `/api-yoa/actual/${actualId}/status`
  const isCancelAndNotify = status === 'CANCEL_AND_NOTIFY'
  const statusObj = isCancelAndNotify ? 'CANCELED' : status
  return srPost(
    url,
    {
      venue: venueId,
      status: statusObj,
      send_client_notification: isCancelAndNotify,
    },
    { skipInterfaceError: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    return response.data
  })
}

const postResTableAssignment = ({ venueId, actualId, tableIds, isAutoAssign, isCustomAssign, errHandler }) => {
  const tableIdsObj = tableIds || []
  if (isAutoAssign) {
    tableIdsObj.push('auto')
  }
  if (isCustomAssign) {
    tableIdsObj.push('custom')
  }

  const url = `/api-yoa/actual/${actualId}/tables`
  return srPost(
    url,
    {
      venue: venueId,
      table_ids: tableIdsObj,
    },
    { skipInterfaceError: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg, response.error)
    }
    return response.data
  })
}

export default {
  fetchActual,
  bookReservation,
  saveEditReservation,
  postResStatusChange,
  postResTableAssignment,
}
