import _ from 'lodash'
import { getIconForStatus, getIconColor } from 'mgr/lib/utils/Statuses'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

const computeViewActualShiftPersistentId = state => ({
  shiftPersistentId: state.viewResState.actual && state.viewResState.actual.shift_persistent_id,
  shifts: state.bookAvailabilityState.shifts || [],
})

const computeShiftHumanName = ({ shiftPersistentId, shifts }) => {
  for (let i = 0; i < shifts.length; i++) {
    const shift = shifts[i]
    if (shift.persistent_id === shiftPersistentId) {
      return shift.name
    }
  }
  return ''
}

export const selectShiftHumanName = createShallowEqualSelector(computeViewActualShiftPersistentId, computeShiftHumanName)

const computeAvailableStatus = state => {
  const viewVenue = state.viewResState.viewVenue || {}
  const { isInService } = state.viewResState
  const serviceStatuses = viewVenue.serviceStatuses || {}
  return {
    statuses: isInService ? serviceStatuses['in-service'] || [] : serviceStatuses['pre-service'] || [],
    isInService,
  }
}

const formatStatuses = (globalStatuses, isInService) => {
  if (_.isEmpty(globalStatuses)) {
    return []
  }
  const statusesByDb = _.keyBy(globalStatuses, 'db_name')

  const venueDependentStatuses = globalStatuses
    .filter(status => status && status.enabled && !status.deleted)
    .map(status => ({
      name: status.display,
      value: status.db_name,
      icon: getIconForStatus(status.db_name),
      iconStyle: {
        ...getIconColor(statusesByDb, status.db_name),
        borderRadius: 10,
        marginRight: 3,
      },
    }))

  const isNotInService = !isInService
  const canceledColor = isNotInService && getIconColor(statusesByDb, 'CANCELED')
  const orderedStatuses = []
  for (let i = 0; i < venueDependentStatuses.length; i++) {
    const status = venueDependentStatuses[i]
    orderedStatuses.push(status)
    if (status.value === 'CANCELED' && isNotInService) {
      orderedStatuses.push({
        name: `${status.name} and notify`,
        value: 'CANCEL_AND_NOTIFY',
        icon: getIconForStatus(status.value),
        iconStyle: { ...canceledColor, borderRadius: 10, marginRight: 3 },
      })
    }
  }

  return orderedStatuses
}

const computeAdditionalStatuses = ({ statuses, isInService }) => formatStatuses(statuses)

export const selectStatusWithSpecialEntries = createShallowEqualSelector(computeAvailableStatus, computeAdditionalStatuses)

const computeAvailableBookedByNames = state => ({
  venueId: (state.viewResState.actual || {}).venue_id,
  bookedByNamesByVenue: state.bookDetailsState.bookedByNamesByVenue,
})

const venueScopedShiftsFilter = ({ venueId, bookedByNamesByVenue }) =>
  (bookedByNamesByVenue[venueId] || []).filter(bbName => bbName.is_user)

export const venueScopedBookedByNames = createShallowEqualSelector(computeAvailableBookedByNames, venueScopedShiftsFilter)
