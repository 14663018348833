import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Link, Text } from '@sevenrooms/core/ui-kit/typography'
import type { ReactChild } from 'react'

export interface CampaignPerformanceCardProps {
  value: ReactChild
  name: string
  linkConfig?: {
    link: string
    isExternal?: boolean
  }
}

export function CampaignPerformanceCard({ name, value, linkConfig }: CampaignPerformanceCardProps) {
  return (
    <VStack alignItems="center" justifyContent="center" spacing="s">
      <Header type="h3" whiteSpace="nowrap">
        {value}
      </Header>

      <HStack spacing="xxs" alignItems="center">
        <Text whiteSpace="nowrap" color="secondaryFont">
          {name}
        </Text>
        {linkConfig && (
          <Link isExternal={linkConfig.isExternal} to={linkConfig.link}>
            <Icon name="VMSWeb-open-in-new" color="primarySelected" />
          </Link>
        )}
      </HStack>
    </VStack>
  )
}
