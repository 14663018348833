import _ from 'lodash'
import { isOrderInShift } from 'mgr/lib/utils/ShiftUtils'
import { EMPTY_ARRAY, EMPTY_DICT } from './Constants'

export function getBlocksFromGridMap(gridBlocksMapByShift, shiftPersistentId, tableId, sortOrder) {
  try {
    return gridBlocksMapByShift[shiftPersistentId][tableId][sortOrder] || EMPTY_ARRAY
  } catch (err) {}
  return EMPTY_ARRAY
}

export function getBlocksByOrderFromGridMap(gridBlocksMapByShift, shiftPersistentId, tableId) {
  try {
    return gridBlocksMapByShift[shiftPersistentId][tableId] || EMPTY_DICT
  } catch (err) {}
  return EMPTY_DICT
}

export function computeGridBlocksMap(blocks, date, shifts, seatingAreasById, allTableItems) {
  // shift -> tableId  -> sort order
  const gridBlocksMap = {}

  for (const shift of shifts) {
    gridBlocksMap[shift.persistent_id] = {}

    let startSortOrders = []
    for (const block of blocks) {
      // compute start sort order and duration
      let startSortOrder = 0
      let endSortOrder = 0
      let duration = 1
      switch (block.block_time_type) {
        case 'ALL':
          startSortOrder = shift.start_time_sort_order
          endSortOrder = shift.end_time_sort_order + 1
          startSortOrders = [startSortOrder]
          break
        case 'SHIFT':
          startSortOrder = shift.start_time_sort_order
          endSortOrder = shift.end_time_sort_order + 1
          startSortOrders = [startSortOrder]
          break
        case 'TIME_RANGE':
          startSortOrder = block.start_time_sort_order
          endSortOrder = block.end_time_sort_order
          startSortOrders = [Math.max(startSortOrder, shift.start_time_sort_order)]
          break
        case 'START_TIME_ONLY':
          startSortOrder = Math.min(...block.block_start_sort_orders)
          endSortOrder = Math.max(...block.block_start_sort_orders)
          startSortOrders = block.block_start_sort_orders
          break
        default:
          break
      }

      // skip shift blocks that don't belong to this shift
      if (block.block_time_type === 'SHIFT' && block.shift_persistent_id !== shift.persistent_id) {
        continue
      }

      // skip if range doesn't fall within shift window
      if (!isOrderInShift(startSortOrder, endSortOrder, shift)) {
        continue
      }

      startSortOrder = Math.max(startSortOrder, shift.start_time_sort_order)
      endSortOrder = Math.min(endSortOrder, shift.end_time_sort_order + 1)
      duration = endSortOrder - startSortOrder

      // compute table ids
      let tableIds = []
      switch (block.block_type) {
        case 'LOCATION':
          if (_.isEmpty(block.table_ids) && _.isEmpty(block.table_combo_ids) && _.isEmpty(block.seating_area_ids)) {
            tableIds = allTableItems.map(t => t.id)
            break
          }
          const tableSet = new Set(block.table_ids)
          for (const seatingAreaId of block.seating_area_ids) {
            if (seatingAreaId in seatingAreasById) {
              const { tables } = seatingAreasById[seatingAreaId]
              for (const t of tables) {
                tableSet.add(t.id)
              }
            }
          }
          tableIds = [...tableSet]
          break
        default:
          break
      }

      const startOrderTuples = {}
      for (const order of startSortOrders) {
        if (!(order in startOrderTuples)) {
          startOrderTuples[order] = []
        }
        startOrderTuples[order].push(block.block_time_type === 'START_TIME_ONLY' ? 1 : duration)
      }

      for (const tId of tableIds) {
        if (!(tId in gridBlocksMap[shift.persistent_id])) {
          gridBlocksMap[shift.persistent_id][tId] = {}
        }
        for (const order in startOrderTuples) {
          if (!(order in gridBlocksMap[shift.persistent_id][tId])) {
            gridBlocksMap[shift.persistent_id][tId][order] = []
          }
          for (const durationOrder of startOrderTuples[order]) {
            gridBlocksMap[shift.persistent_id][tId][order].push({
              block,
              durationOrders: durationOrder,
            })
          }
        }
      }
    }
  }
  return gridBlocksMap
}

export function getBookingBlocksInitData(allTableInventoryItems, seatingAreas, tableComboItems) {
  const tableCodeToIdMap = {}
  const idToTableCodeMap = {}
  let tablesMaxPartySize = {}
  const seatingAreaNameToIdMap = {}
  const idToSeatingAreaNameMap = {}
  for (let i = 0; i < allTableInventoryItems.length; ++i) {
    const table = allTableInventoryItems[i]
    tableCodeToIdMap[table.item_code] = table.id
    idToTableCodeMap[table.id] = table.item_code
    tablesMaxPartySize[table.id] = table.party_size_max
    if (table.party_size_max > tablesMaxPartySize) {
      tablesMaxPartySize = table.party_size_max
    }
  }
  for (let i = 0; i < seatingAreas.length; ++i) {
    const seatingArea = seatingAreas[i]
    seatingAreaNameToIdMap[seatingArea.name] = seatingArea.id
    idToSeatingAreaNameMap[seatingArea.id] = seatingArea.name
  }

  for (let i = 0; i < tableComboItems.length; i++) {
    const tableCombo = tableComboItems[i]
    tableCodeToIdMap[tableCombo.label] = tableCombo.id
    idToTableCodeMap[tableCombo.id] = tableCombo.label
    if (tableCombo.party_size_max > tablesMaxPartySize) {
      tablesMaxPartySize = tableCombo.party_size_max
    }
  }
  return {
    tableCodeToIdMap,
    idToTableCodeMap,
    tablesMaxPartySize,
    seatingAreaNameToIdMap,
    idToSeatingAreaNameMap,
  }
}
