import React from 'react'

export const MailLink = ({ emailAddress, children, style }) => (
  <a style={style} href={`mailto:${emailAddress}`}>
    {children}
  </a>
)
export const TelephoneLink = ({ phone, children, style }) => (
  <a style={style} href={`tel:${phone}`}>
    {children}
  </a>
)
export const CreditCardLink = ({ creditCard, children, style }) => (
  <a style={style} href={`tel:${creditCard}`}>
    {children}
  </a>
)
