// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import {
  type SelectOption,
  StyledTarget,
  StyledToggleButton,
  StyledValue,
  StyledInput,
  StyledInputHolder,
} from '@sevenrooms/core/ui-kit/core'
import { FormSelect, type FormSelectProps } from '@sevenrooms/core/ui-kit/form'

export function CustomFormSelect<T extends SelectOption<string | number>, E>(props: FormSelectProps<T, E>) {
  return (
    <StyledWrapper>
      <FormSelect {...props} />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  ${StyledTarget} {
    min-height: 0;
    height: ${props => props.theme.fieldHeight.s};
  }
  ${StyledToggleButton},
  ${StyledValue},
  ${StyledInput} {
    padding-top: 0;
    padding-bottom: 0;
  }
  ${StyledInputHolder} {
    display: flex;
    height: 100%;
    align-items: center;
  }
`
