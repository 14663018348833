import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { merge } from './SRUtils'
import { VmsIcons, VmsIconSizes } from './VmsIcons'
import PropTypes from 'prop-types'

class Button extends Component {
  constructor(props) {
    super(props)

    // instead of getInitialState
    this.state = { hover: false }

    // Need to bind this for non-react methods
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
  }

  onMouseOver() {
    this.setState({ hover: true })
  }

  onMouseOut() {
    this.setState({ hover: false })
  }

  render() {
    let { onClick } = this.props
    const { onClickDropDown } = this.props
    let computed_style = _.extend({}, this.props.style)

    if (this.props.disabled) {
      computed_style = _.extend(computed_style, this.props.disabled_style)
      onClick = null
    } else if (this.props.selected) {
      computed_style = _.extend(computed_style, this.props.selected_style)
      onClick = null
    } else if (this.state.hover) {
      computed_style = _.extend(computed_style, this.props.hover_style)
    }

    const dropDown = (
      <span data-test="sr-button_dropdown" style={this.props.dropdown_style} onClick={onClickDropDown}>
        {this.props.dropdownlabel}
      </span>
    )

    return (
      <div
        data-test={this.props.testId}
        className="button"
        style={this.props.buttonStyle}
        onClick={onClick}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,no-script-url */}
        <a href="javascript:void(0)" style={computed_style}>
          <span style={this.props.prelabel_style}>{this.props.prelabel}</span>
          {this.props.label}
          {this.props.includeDropDown ? dropDown : ''}
        </a>
      </div>
    )
  }
}

Button.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  disabled_style: PropTypes.object,
  selected: PropTypes.bool,
  selected_style: PropTypes.object,
  hover_style: PropTypes.object,
  prelabel: PropTypes.string,
  prelabel_style: PropTypes.object,
}

Button.defaultProps = {
  disabled_style: { cursor: 'default', opacity: 0.5 },
  selected_style: { cursor: 'default' },
  hover_style: { cursor: 'pointer' },
}

export default Button
