import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { tryLinkReview } from 'mgr/actualslideout/actions/ReviewActions'
import * as SlideoutActions from 'mgr/actualslideout/actions/SlideoutActions'
import { FULL_HEIGHT } from 'mgr/actualslideout/components/view/ViewCommon'
import ViewReservationHeader from 'mgr/actualslideout/components/view/ViewReservationHeader'
import { ProblemResBanner } from 'mgr/actualslideout/components/ViewComponents'
import BookedByNames from 'mgr/actualslideout/containers/view/BookedByNames'
import CardDisplay from 'mgr/actualslideout/containers/view/CardDisplay'
import Followers from 'mgr/actualslideout/containers/view/Followers'
import Messaging from 'mgr/actualslideout/containers/view/Messaging'
import { PulsatingDots } from 'mgr/layout/StyledComponentUtils'
import { Col, Container, Row } from 'svr/lib/styled-bootstrap-grid'
import ReservationSlideout from './ReservationSlideout'

const RightColWithBorder = styled(Col)`
  border-left: 2px orange;
`

const LinkFooter = styled.div`
  background-color: ${props => props.theme.mediumGrey};
  flex: 1;
  height: 67px;
  margin-left: 15px;
  padding: 7px 7px;
  z-index: 10;
`

const LinkButton = styled.div`
  ${props => props.theme.primaryButton};
  font-size: ${props => props.theme.fontSize.button};
  font-weight: 600;
  text-transform: uppercase;
  height: 54px;
  line-height: 54px;
  width: 100%;
`

const ViewReservation = ({
  visibleActual,
  clientInitials,
  clientTags,
  resProblem,
  canEditReservation,
  isLinking,
  linkingReview,
  actions,
}) => {
  if (!visibleActual || Object.keys(visibleActual).length === 0) {
    return null
  }

  let resProblemElements = null
  if (resProblem) {
    resProblemElements = <ProblemResBanner resProblem={resProblem} />
  }

  const title = `Reservation ${visibleActual.reference_code}`

  return (
    <ReservationSlideout title={title}>
      <Container
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}
      >
        {resProblemElements}
        <ViewReservationHeader
          canEditReservation={canEditReservation}
          onClick={() =>
            canEditReservation &&
            actions.enterEditReservation({
              actual: visibleActual,
              editStep: 'client',
            })
          }
          name={visibleActual.client_display_name}
          clientTags={clientTags}
          isVip={visibleActual.is_client_vip}
          photoObj={visibleActual.client_photo}
          initials={clientInitials}
          clientId={visibleActual.venue_group_client.id}
          venueId={visibleActual.venue_id}
          phone={
            (visibleActual.source_client && visibleActual.source_client.phone_number_formatted) ||
            visibleActual.phone_number_formatted ||
            (visibleActual.venue_group_client && visibleActual.venue_group_client.phone_number_formatted)
          }
          sourceName={
            visibleActual.source_client &&
            visibleActual.source_client.phone_number_formatted &&
            visibleActual.source_client.name_only_display
          }
          loyaltyId={visibleActual.venue_group_client.loyalty_id}
          loyaltyRank={visibleActual.venue_group_client.loyalty_rank}
          loyaltyTier={visibleActual.venue_group_client.loyalty_tier}
          sourceCompany={visibleActual.source_client && visibleActual.source_client.company}
        />

        <Row style={{ flex: 1, overflow: 'hidden' }}>
          <Col lg={6} style={{ height: '100%', paddingRight: 0 }}>
            <CardDisplay />
          </Col>
          <Col
            lg={6}
            style={{
              height: '100%',
              paddingLeft: 0,
              borderLeft: '1px solid #d1d2d3',
            }}
          >
            <Messaging />
          </Col>
        </Row>
        <Row>
          <Col xs={6} style={{ height: '100%', paddingRight: 0 }}>
            <BookedByNames />
          </Col>
          <RightColWithBorder xs={6} style={{ height: '100%', paddingLeft: 0 }}>
            <Followers />
          </RightColWithBorder>
        </Row>
        {linkingReview ? (
          <Row style={{ marginRight: 0 }}>
            <LinkFooter>
              <LinkButton onClick={actions.tryLinkReview} disabled={isLinking}>
                {isLinking ? <PulsatingDots /> : 'Link to Review'}
              </LinkButton>
            </LinkFooter>
          </Row>
        ) : null}
      </Container>
    </ReservationSlideout>
  )
}

const mapStateToProps = state => ({
  resProblem: state.viewResState.resProblem,
  visibleActual: state.viewResState.actual,
  clientInitials: state.viewResState.clientInitials,
  clientTags: state.viewResState.clientTags,
  canEditReservation: state.viewResState.canEditReservation,
  isLinking: state.viewResState.isLinking,
  linkingReview: state.viewResState.linkingReview,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      enterEditReservation: SlideoutActions.enterEditReservation,
      tryLinkReview,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewReservation)
