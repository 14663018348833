import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo } from 'react'
import { LoadingSpinner } from 'mgr/layout/StyledComponentUtils'
import { type MixedAvailabilityTimeslot, useGetUpgradesQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { Box, Modal, ModalBody, ModalHeader, ModalTitle, VStack, Window, Image } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AvailabilityLocales } from '../../../components/availability/Availability.locales'
import { defaultUpgrades } from '../../../components/availability/defaults'
import { getCostFromTimeslot } from '../../../components/availability/helpers'

interface AvailabilityTimeslotExperienceProps {
  currencyCode?: string
  venueId?: string
  timeslot: MixedAvailabilityTimeslot
  isActive: boolean
  setIsActive: (isActive: boolean) => void
}

export function AvailabilityTimeslotExperiencePopup({
  currencyCode,
  venueId,
  timeslot,
  isActive,
  setIsActive,
}: AvailabilityTimeslotExperienceProps) {
  const { formatMessage } = useLocales()
  const { data: upgrades = defaultUpgrades, isLoading } = useGetUpgradesQuery(venueId ?? skipToken)

  const cost = getCostFromTimeslot(currencyCode, timeslot)

  const upgradesNames = useMemo(() => Object.fromEntries(upgrades.inventories.map(i => [i.id, i.name])), [upgrades.inventories])

  if (!timeslot) {
    return null
  }

  return (
    <Window active={isActive} setIsActive={setIsActive}>
      <Modal ariaLabel="Experience" data-test="availability-timeslot-experience-dialog">
        <ModalHeader onClose={() => setIsActive(false)}>
          <VStack spacing="s">
            <ModalTitle title={timeslot.public_description_title || formatMessage(AvailabilityLocales.noTimeslotDescriptionTitle)} />
            {cost && (
              <Text>
                {cost}/{timeslot.charge_type}
              </Text>
            )}
          </VStack>
        </ModalHeader>
        <ModalBody>
          <VStack spacing="m" mb="lm">
            {timeslot.public_long_form_description && (
              <Text>
                <HTMLContent content={timeslot.public_long_form_description} />
              </Text>
            )}
            {timeslot.selected_automatic_upsells && timeslot.selected_automatic_upsells?.length > 0 && (
              <Box>
                <Text fontWeight="bold">Included:</Text>
                <VStack>
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    timeslot.selected_automatic_upsells.map(upgrade => (
                      <Text key={upgrade.id}>
                        {upgrade.quantity_num}x {upgradesNames[upgrade.id]}
                      </Text>
                    ))
                  )}
                </VStack>
              </Box>
            )}
            {timeslot.public_photo_sizes?.medium ? (
              <Image
                src={`/.h/download/${timeslot.public_photo_sizes.medium}`}
                width={timeslot.public_photo_sizes.medium_width}
                height={timeslot.public_photo_sizes.medium_height}
                alt=""
              />
            ) : (
              timeslot.public_photo && <Image src={timeslot.public_photo} alt="" />
            )}
            {timeslot.policy && (
              <VStack>
                <Text fontWeight="bold">Booking Policy</Text>
                <Text>
                  <HTMLContent content={timeslot.policy} />
                </Text>
              </VStack>
            )}
            {timeslot.cancellation_policy && (
              <VStack>
                <Text fontWeight="bold">Cancellation Policy</Text>
                <Text>
                  <HTMLContent content={timeslot.cancellation_policy} />
                </Text>
              </VStack>
            )}
          </VStack>
        </ModalBody>
      </Modal>
    </Window>
  )
}
