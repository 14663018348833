import { srGet } from '@sevenrooms/core/api'

const fetchActivityLog = async (venueId, conversationId) => {
  const response = await srGet(
    `/manager/${venueId}/activity/${conversationId}`,
    {
      rid: Math.floor(Math.random() * 100 + 1),
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  )
  if (response.error) {
    throw new Error(response.errorMsg)
  }

  return response.payload && response.payload.activity
}

export default {
  fetchActivityLog,
}
