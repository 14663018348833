import _ from 'lodash'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PhoneInputCustom from 'react-phone-number-input-custom'
import AutosizingTextarea from 'react-textarea-autosize'
import styled from 'styled-components'
import * as Validators from 'svr/common/Validators'
import VmsIcons, { IconFontCss } from 'svr/common/VmsIcons'
import 'react-phone-number-input-custom/rrui.css'
import 'react-phone-number-input-custom/style.css'

const PhoneInput = PhoneInputCustom['react-phone-number-input'].default
// These regexes limit input as it's typed.  Use svr/common/Validators to validate completed input
/* eslint-disable max-len */
const restrictionRegexes = {
  none: /.*/g, // all characters
  // Need to support unicode chars (not just A-Za-z) and /p does not work in JS regex, so went with exclusive rather than inclusive
  // Source: https://stackoverflow.com/questions/2385701/regular-expression-for-first-and-last-name
  name: /(^$)|(^([^!#$%&()*,./:;?@[\\\]_{|}¨ˇ“”€+<=>§°\s¤®™©]| )+$)/g,
  email: /[@.a-z0-9!#$%&'*+/=?^_`{|}~-]+/gi,
  phone: /[0-9!#$%&'*+/=?^_{}()x.~ -]+/gi,
  integer: /[0-9]+/g, // digits
  number: /[0-9,.-]+/g, // digits and commas/periods
  birthday: /\d{0,2}(\/\d{0,2})?/, // birthday - mm/dd
}
/* eslint-enable max-len */

const buildInputRestrictions = {}
Object.keys(restrictionRegexes).map(k => (buildInputRestrictions[k] = k))
export const InputRestrictions = Object.freeze(buildInputRestrictions)

const restrictInput = (value, rtype) => value.match(restrictionRegexes[rtype])

export const validatorFuncs = Object.freeze({
  phoneNumber: Validators.validatePhoneNumber,
  notEmpty: Validators.validateNotEmpty,
  nameRequired: Validators.validateNotEmpty,
  phoneRequired: Validators.validatePhoneNumber,
  emailRequired: Validators.validateEmail,
  emailOptional: Validators.validateEmailOptional,
  emailOrShortcode: Validators.validateEmailOrShortcode,
  multiEmail: Validators.validateMultiEmail,
  birthday: Validators.validateBirthday,
  cardNumber: Validators.validateCardNumber,
  cardMonthExp: Validators.validateCardMonthExp,
  cardYearExp: Validators.validateCardYearExp,
  cardCvv: Validators.validateCardCvv,
  amountRequired: Validators.validatePositiveAmount,
  gratuityChargeRequired: Validators.validatePositiveOrZeroAmount,
})

const buildValidatorTypes = {}
Object.keys(validatorFuncs).map(k => (buildValidatorTypes[k] = k))
export const ValidatorTypes = Object.freeze(buildValidatorTypes)

const requiredValidators = Object.freeze([
  ValidatorTypes.notEmpty,
  ValidatorTypes.nameRequired,
  ValidatorTypes.phoneNumber,
  ValidatorTypes.phoneRequired,
  ValidatorTypes.emailRequired,
])

const callValidator = (value, vtype) => validatorFuncs[vtype](value)

const StyledInputWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
`

const BorderedArea = styled.div`
  min-height: 42px;
  position: relative;
  padding: 0 2px 0 10px;
  box-sizing: content-box !important;
  border-radius: 4px;
  border-width: ${props => (props.hideBorder ? '0' : '1px')};
  border-style: solid;
  border-color: ${props => (props.isValid ? props.theme.lightGrey : props.theme.error)};
  background-color: ${props => (props.disabled ? props.theme.lighterSilver : props.theme.background)};
  overflow: ${props => (props.showOverflow ? 'visible' : 'hidden')};
  :hover {
    border-color: ${props => (props.disabled ? props.theme.lightGrey : props.theme.darkGrey)};
    transition: border-color 0.2s ease-out;
  }
`

const NameOutside = styled.label`
  color: ${props => (props.isValid === false ? props.theme.error : props.theme.darkGrey)};
  ${props => props.theme.fontWeight300} font-size: 11px;
  padding: 0 0 4px 10px;
  display: block;
  text-transform: uppercase;
  ${props => props.labelStyle};
`

export const TextInputLabel = NameOutside

const NameInside = styled.label`
  position: absolute;
  top: 1px;
  color: ${props => (props.isValid ? props.theme.darkGrey : props.theme.error)};
  font-weight: 500;
  font-size: 11px;
`

// Ugly to have all these !important decorators, but needed for production.css overrides on input fields
const StyledInput = styled.input.attrs({
  type: 'text',
})`
  font-size: 14px !important;
  line-height: 19px;
  width: 100% !important;
  min-width: 60px;
  ${props => (props.indentField ? '' : 'min-height: 42px;')} border: 0 !important;
  position: relative;
  outline: none;
  overflow: hidden;
  background-color: ${props => (props.disabled ? props.theme.lighterSilver : props.theme.white)} !important;
  padding: ${props => {
      if (props.indentField) {
        return '11px'
      } else if (props.useOutsideLabel) {
        return '0'
      }
      return '2px'
    }}
    0 0 0 !important;
  cursor: ${props => (props.disabled ? 'default' : 'auto')};
  color: ${props => (props.disabled ? props.theme.darkGrey : props.theme.navigationDark)} !important;
  :focus: {
  }
  ::-webkit-input-placeholder {
    color: ${props => (props.isValid ? props.theme.darkGrey : props.theme.error)};
    font-family: inherit;
  }
  ::-moz-placeholder {
    color: ${props => (props.isValid ? props.theme.darkGrey : props.theme.error)};
    font-family: inherit;
  }
  :-ms-input-placeholder {
    color: ${props => (props.isValid ? props.theme.darkGrey : props.theme.error)};
    font-family: inherit;
  }
  ${props => props.inputStyles};
`

// See https://github.com/styled-components/styled-components/issues/439
// for why we can't just do
// const StyledTextArea = styled(AutosizingTextarea)`
const StyledTextArea = styled(AutosizingTextarea)`
  font-size: 14px !important;
  line-height: 14px;
  box-sizing: border-box;
  width: 100% !important;
  min-height: 39px;
  border: 0 !important;
  outline: none;
  overflow-x: hidden;
  height: 100%;
  overflow-y: auto;
  background-color: transparent !important;
  resize: ${props => (props.resizable ? 'both' : 'none')};
  padding: ${props => {
      if (props.indentField) {
        return '11px'
      } else if (props.useOutsideLabel && props.placeholder) {
        return '15px'
      }
      return '2px'
    }}
    0 4px 0;
  cursor: ${props => (props.disabled ? 'default' : 'auto')};
  color: ${props => props.theme.navigationDark} !important;
  :focus: {
  }
  ::-webkit-input-placeholder {
    color: ${props => (props.isValid ? props.theme.darkGrey : props.theme.error)};
    font-family: inherit;
  }
  ::-moz-placeholder {
    color: ${props => (props.isValid ? props.theme.darkGrey : props.theme.error)};
    font-family: inherit;
  }
  :-ms-input-placeholder {
    color: ${props => (props.isValid ? props.theme.darkGrey : props.theme.error)};
    font-family: inherit;
  }
  ${props => props.inputStyles};
`

class TextInput extends Component {
  constructor(props) {
    super(props)
    const { label, placeholder, value } = this.props
    this.state = { focused: false, value: value || '' }
    const formatPlaceholder = _.toLower(label || placeholder).replace(/[^\w]+/g, '-')
    this.inputId = this.props.inputId || `sr-${formatPlaceholder}`
    this.limitInput = this.limitInput.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onInputKeyDown = this.onInputKeyDown.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.isValid = this.isValid.bind(this)
    this.isDebouncing = false
  }

  componentWillMount() {
    this.createNotifier(this.props.debounceTimeout)
  }

  componentWillReceiveProps({ value, debounceTimeout }) {
    if (this.isDebouncing) {
      return
    }
    if (typeof value !== 'undefined' && this.state.value !== value) {
      this.setState({ value })
    }
    if (debounceTimeout !== this.props.debounceTimeout) {
      this.createNotifier(debounceTimeout)
    }
  }

  componentWillUnmount() {
    if (this.flush) {
      this.flush()
    }
  }

  onInputChange(e) {
    e.persist()
    const { prefixSymbol, postfixSymbol, value, disabled } = this.props
    if (disabled) {
      return
    }
    let initVal = e.target.value
    if (prefixSymbol) {
      initVal = initVal.replace(prefixSymbol, '')
    }

    if (postfixSymbol) {
      initVal = initVal.replace(postfixSymbol, '')
    }
    const newVal = this.limitInput(initVal)
    if (newVal === value) {
      return
    }
    this.setState({ value: newVal }, () => {
      this.notify(e)
    })
  }

  onInputKeyDown(e) {
    const { onKeyDown, postfixSymbol, value, isMultiLine } = this.props
    if (!isMultiLine && e.key === 'Enter') {
      this.forceNotify(e)
    }

    let initVal = e.target.value
    if (postfixSymbol) {
      if (initVal.length === e.target.selectionStart && e.key === 'Backspace') {
        initVal = initVal.replace(postfixSymbol, '')
        initVal = initVal.slice(0, -1)
        const newVal = this.limitInput(initVal)

        if (newVal !== value) {
          this.setState({ value: newVal }, () => {
            this.notify(e)
          })
        }
      }
    }
    onKeyDown(e)
  }

  limitInput(newValue) {
    const { inputRestriction, charLimit, isMultiLine, value } = this.props
    if (newValue === '') {
      return ''
    }
    const match = restrictInput(newValue, inputRestriction)
    if (!match) {
      return value
    } else if (isMultiLine) {
      return newValue
    } else if (charLimit) {
      return match[0].substring(0, charLimit)
    }
    return match[0]
  }

  createNotifier(debounceTimeout) {
    if (debounceTimeout < 0) {
      this.notify = () => null
    } else if (debounceTimeout === 0) {
      this.notify = this.doNotify
    } else {
      const debouncedChangeFunc = _.debounce(() => {
        this.isDebouncing = false
        this.doNotify()
      }, debounceTimeout)

      this.notify = e => {
        this.isDebouncing = true
        debouncedChangeFunc(e)
      }

      this.flush = () => debouncedChangeFunc.flush()

      this.cancel = () => {
        this.isDebouncing = false
        debouncedChangeFunc.cancel()
      }
    }
  }

  doNotify() {
    const { onChange } = this.props
    const { value } = this.state
    onChange(value)
  }

  forceNotify() {
    if (!this.isDebouncing) {
      return
    }

    if (this.cancel) {
      this.cancel()
    }

    this.doNotify()
  }

  /**
   * Returns true if valid, otherwise invalid text
   */
  isValid() {
    const { validator, customValidator, overrideDefaultError } = this.props
    const customIsValid = customValidator(this.state.value)
    if (customIsValid !== true) {
      return customIsValid
    }
    if (_.isEmpty(validator)) {
      return true
    }
    if (overrideDefaultError) {
      return callValidator(this.state.value || '', validator)
    }
    return callValidator(this.state.value || '', validator) || this.buildInvalidDisplayText()
  }

  buildInvalidDisplayText() {
    const { label, placeholder, invalidDisplayText, validator, value } = this.props
    if (!_.isEmpty(invalidDisplayText)) {
      return invalidDisplayText
    }
    const isRequiredValidator = _.includes(requiredValidators, validator)
    const showRequiredMsg = (isRequiredValidator && !value) || _.isEmpty(value.trim())
    return _.startCase(_.toLower(label || placeholder)) + (showRequiredMsg ? ' is required' : '  is not valid')
  }

  focus() {
    // eslint-disable-next-line react/no-string-refs
    ReactDOM.findDOMNode(this.refs.input).focus()
    this.setState({ focused: true })
  }

  blur() {
    // eslint-disable-next-line react/no-string-refs
    ReactDOM.findDOMNode(this.refs.input).blur()
    this.setState({ focused: false })
  }

  onFocus() {
    this.setState({ focused: true })
  }

  onBlur(e) {
    const { onBlur } = this.props
    this.setState({ focused: false })

    this.forceNotify()
    onBlur(e)
  }

  isInFocus() {
    return this.state.focused
  }

  render() {
    const {
      testId,
      isValid,
      placeholder,
      label,
      disabled,
      showLabel,
      hideBorder,
      charLimit,
      isMultiLine,
      minRows,
      maxRows,
      useOutsideLabel,
      prefixSymbol,
      postfixSymbol,
      style,
      labelStyle,
      forceIndent,
      resizable,
      inputStyles,
      inputRef,
    } = this.props
    const { value } = this.state
    const isFieldFocused = this.isInFocus() || value
    const InputOrTextArea = isMultiLine ? StyledTextArea : StyledInput
    const labelShown = showLabel && (label || isFieldFocused)
    const indentField = (!isFieldFocused && !useOutsideLabel && _.isEmpty(label)) || forceIndent

    return (
      <StyledInputWrapper {...{ style }}>
        {useOutsideLabel && labelShown && (
          <NameOutside {...{ isValid, labelStyle }} htmlFor={this.inputId}>
            {label || placeholder}
          </NameOutside>
        )}
        <BorderedArea className="bordered-area" {...{ isValid, disabled, hideBorder }}>
          {!useOutsideLabel && labelShown && (
            <NameInside {...{ isValid }} htmlFor={this.inputId}>
              {label || placeholder}
            </NameInside>
          )}
          <InputOrTextArea
            data-test={testId}
            ref={inputRef}
            id={this.inputId}
            key={this.inputId}
            value={(prefixSymbol || '') + (value || '') + (postfixSymbol || '')}
            onChange={this.onInputChange}
            onKeyDown={this.onInputKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            maxLength={charLimit}
            minRows={3}
            inputStyles={inputStyles}
            placeholder={isFieldFocused ? '' : placeholder}
            {...{
              isValid,
              disabled,
              minRows,
              maxRows,
              indentField,
              resizable,
              useOutsideLabel,
            }}
          />
        </BorderedArea>
      </StyledInputWrapper>
    )
  }
}

TextInput.propTypes = {
  testId: React.PropTypes.string,
  value: React.PropTypes.string,
  placeholder: React.PropTypes.string.isRequired,
  label: React.PropTypes.string,
  useOutsideLabel: React.PropTypes.bool,
  invalidDisplayText: React.PropTypes.string,
  onChange: React.PropTypes.func.isRequired,
  onKeyDown: React.PropTypes.func.isRequired,
  onBlur: React.PropTypes.func.isRequired,
  validator: React.PropTypes.oneOf(_.values(ValidatorTypes)),
  customValidator: React.PropTypes.func.isRequired,
  isValid: React.PropTypes.bool.isRequired,
  disabled: React.PropTypes.bool.isRequired,
  hideBorder: React.PropTypes.bool.isRequired,
  inputRestriction: React.PropTypes.oneOf(_.values(InputRestrictions)),
  charLimit: React.PropTypes.number,
  debounceTimeout: React.PropTypes.number,
  isMultiLine: React.PropTypes.bool.isRequired,
  minRows: React.PropTypes.number, // Multi-line only
  style: React.PropTypes.object,
  labelStyle: React.PropTypes.object,
  forceIndent: React.PropTypes.bool.isRequired,
  showLabel: React.PropTypes.bool.isRequired,
  resizable: React.PropTypes.bool.isRequired,
  inputRef: React.PropTypes.func,
}

TextInput.defaultProps = {
  label: '',
  placeholder: '',
  useOutsideLabel: true,
  isValid: true,
  disabled: false,
  hideBorder: false,
  inputRestriction: 'none',
  onChange: () => {},
  onKeyDown: () => {},
  onBlur: () => {},
  validator: null,
  customValidator: () => true,
  charLimit: null,
  isMultiLine: false,
  minRows: null,
  maxRows: null,
  debounceTimeout: 150,
  forceIndent: false,
  showLabel: true,
  resizable: true,
}

export default TextInput

// FIXME: Indent field. [addomg fox]
const PhoneFieldInputStyleWrapper = styled.div`
  & input {
    border: none;
    padding: 0;
    margin: 0;

    font-size: 14px !important;
    line-height: 19px;
    width: 100% !important;
    min-width: 60px;
    outline: none;
    overflow: hidden;
    background-color: transparent !important;

    color: ${props => (props.disabled ? props.theme.darkGrey : props.theme.navigationDark)} !important;
  }

  & .rrui__input-element {
    border: none;
  }

  & .rrui__input {
    margin-right: 20px;
  }

  & .react-phone-number-input__icon--international {
  }

  & .react-phone-number-input__icon,
  .react-phone-number-input__icon-image {
    height: 20px;
    width: 27px;
  }

  & .react-phone-number-input__icon > svg {
    max-height: 100%;
    max-width: 100%;
  }

  & .rrui__select__selected-label {
    margin-right: 10px;
  }
  & .react-phone-number-input .rrui__select__arrow {
    border: none;
    visibility: hidden;
    margin: 0 0 20px 0;
  }
  & .react-phone-number-input .rrui__select__arrow::after {
    ${IconFontCss}
    font-size: 17px;
    height: 20px;
    min-height: 20px;
    visibility: visible;
    color: ${props => (props.disabled ? props.theme.darkGrey : props.theme.navigationDark)} !important;
    content: '${props => props.displayIcon}';
    transform: rotate(90deg);
  }

  & .react-phone-number-input__row {
    height: 100%;
    min-height: 42px;
  }

  & .rrui__input-field--disabled {
    color: #9a9b9c !important;
  }

  & .rrui__select--disabled.react-phone-number-input__country {
    display: none;
  }
`

class DebounceableComponent extends Component {
  constructor(props) {
    super(props)
    this.onInputChange = this.onInputChange.bind(this)
    this.state = { value: props.value }
    this.isDebouncing = false
  }

  componentWillMount() {
    this.createNotifier(this.props.debounceTimeout)
  }

  componentWillReceiveProps({ value, debounceTimeout }) {
    if (this.isDebouncing) {
      return
    }
    if (typeof value !== 'undefined' && this.state.value !== value) {
      this.setState({ value })
    }
    if (debounceTimeout !== this.props.debounceTimeout) {
      this.createNotifier(10000)
    }
  }

  componentWillUnmount() {
    if (this.flush) {
      this.flush()
    }
  }

  onInputChange(initVal) {
    const { value, disabled } = this.props
    if (disabled) {
      return
    }
    if (initVal === value) {
      return
    }
    this.setState({ value: initVal }, () => {
      this.notify()
    })
  }

  createNotifier(debounceTimeout) {
    if (debounceTimeout < 0) {
      this.notify = () => null
    } else if (debounceTimeout === 0) {
      this.notify = this.doNotify
    } else {
      const debouncedChangeFunc = _.debounce(() => {
        this.isDebouncing = false
        this.doNotify()
      }, debounceTimeout)

      this.notify = () => {
        this.isDebouncing = true
        debouncedChangeFunc()
      }

      this.flush = () => debouncedChangeFunc.flush()

      this.cancel = () => {
        this.isDebouncing = false
        debouncedChangeFunc.cancel()
      }
    }
  }

  doNotify() {
    const { onChange } = this.props
    const { value } = this.state
    onChange(value)
  }

  forceNotify() {
    if (!this.isDebouncing) {
      return
    }

    if (this.cancel) {
      this.cancel()
    }

    this.doNotify()
  }
}

export class PhoneTextInput extends DebounceableComponent {
  constructor(props) {
    super(props)
    this.isValid = this.isValid.bind(this)
  }

  /**
   * Returns true if valid, otherwise invalid text
   */
  isValid() {
    const { validator, customValidator, value, overrideDefaultError } = this.props
    const customIsValid = customValidator(value)
    if (customIsValid !== true) {
      return customIsValid
    }
    if (_.isEmpty(validator)) {
      return true
    }
    if (overrideDefaultError) {
      return callValidator(value || '', validator)
    }
    return callValidator(value || '', validator) || this.buildInvalidDisplayText()
  }

  buildInvalidDisplayText() {
    const { label, placeholder, invalidDisplayText, validator, value } = this.props
    if (!_.isEmpty(invalidDisplayText)) {
      return invalidDisplayText
    }
    const isRequiredValidator = _.includes(requiredValidators, validator)
    const showRequiredMsg = (isRequiredValidator && !value) || _.isEmpty(value.trim())
    return _.startCase(_.toLower(label || placeholder)) + (showRequiredMsg ? ' is required' : ' is not valid')
  }

  render() {
    const {
      style,
      isValid,
      label,
      placeholder,
      disabled,
      hideBorder,
      useOutsideLabel,
      showLabel,
      inputClassName,
      isFieldFocused,
      displayInitialValueAsLocalNumber,
      onChange,
      onCountryChange,
      country,
      textStyle,
      displayIcon,
      dataTest,
    } = this.props
    const { value } = this.state
    const labelShown = showLabel && (label || isFieldFocused)

    return (
      <StyledInputWrapper {...{ style }}>
        {useOutsideLabel && labelShown ? (
          <NameOutside {...{ isValid }} htmlFor={this.inputId}>
            {label || placeholder}
          </NameOutside>
        ) : null}
        <BorderedArea showOverflow {...{ isValid, disabled, hideBorder }}>
          {!useOutsideLabel && labelShown && (
            <NameInside {...{ isValid }} htmlFor={this.inputId}>
              {label || placeholder}
            </NameInside>
          )}
          <PhoneFieldInputStyleWrapper displayIcon={displayIcon}>
            <PhoneInput
              lenientInput
              data-test={dataTest}
              country={country}
              value={value}
              displayInitialValueAsLocalNumber={displayInitialValueAsLocalNumber}
              disabled={disabled}
              onChange={onChange}
              onCountryChange={onCountryChange}
              inputClassName={inputClassName}
              autoComplete="off"
              style={{
                ...textStyle,
                border: 'none !important',
                lineHeight: '14px',
                height: '100%',
                minHeight: '42px',
                fontSize: '14px',
                fontFamily: 'Roboto, sans-serif',
              }}
              placeholder={placeholder}
            />
          </PhoneFieldInputStyleWrapper>
        </BorderedArea>
      </StyledInputWrapper>
    )
  }
}

PhoneTextInput.propTypes = {
  value: React.PropTypes.string,
  country: React.PropTypes.string,
  placeholder: React.PropTypes.string.isRequired,
  label: React.PropTypes.string,
  inputClassName: React.PropTypes.string,
  useOutsideLabel: React.PropTypes.bool,
  invalidDisplayText: React.PropTypes.string,
  onChange: React.PropTypes.func.isRequired,
  onCountryChange: React.PropTypes.func.isRequired,
  isValid: React.PropTypes.bool.isRequired,
  customValidator: React.PropTypes.func.isRequired,
  disabled: React.PropTypes.bool.isRequired,
  debounceTimeout: React.PropTypes.number,
  style: React.PropTypes.object,
  forceIndent: React.PropTypes.bool.isRequired,
  showLabel: React.PropTypes.bool.isRequired,
  displayIcon: React.PropTypes.string,
}

PhoneTextInput.defaultProps = {
  label: '',
  placeholder: '',
  useOutsideLabel: true,
  isValid: true,
  validator: null,
  customValidator: () => true,
  disabled: false,
  hideBorder: false,
  onChange: () => {},
  debounceTimeout: 100,
  forceIndent: false,
  showLabel: true,
  displayIcon: VmsIcons.Chevron,
}
