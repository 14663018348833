import { useState, useCallback, useMemo, useRef } from 'react'
import {
  type NewEmailCampaignFields,
  useCreateEmailCampaignMutation,
  useUpdateEmailCampaignsMutation,
  type OngoingEmailCampaignType,
  type OngoingEmailCampaignTemplate,
  type EmailCampaignResult,
} from '@sevenrooms/core/api'
import {
  OngoingEmailCampaignStatusEnum,
  MarketingCampaignTypeEnum,
  type ExperiencesData,
  type VenueProfile,
  type GenericTagGroup,
} from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { ActionsButton, Button, Form } from '@sevenrooms/core/ui-kit/form'
import { useBeforeUnload } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, notify, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import {
  Audience,
  emailBuilderMessages,
  generateEmailCampaignPreviewTemplate,
  SendTestEmailModal,
  extractAutoTagIds,
  getPlainTextFromHtml,
  packSendTimeConfig,
  campaignBuilderMessages,
  EmailDetails,
  CancelEmailEditModal,
  useOngoingEmailCampaignFromTemplateForm,
  getEmailBuilderFormDefaultValues,
  type OngoingEmailCampaignFromTemplateFormData,
  type EmailDetailsFormField,
  type AudienceFormField,
} from '@sevenrooms/marketing'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { OngoingSendingSchedule, type OngoingSendingScheduleFormField } from '@sevenrooms/mgr-marketing-ongoing-email-center/components'
import { routes } from '@sevenrooms/routes'
import { EmailContent, type EmailContentFormField } from '../EmailContent'
import { EmailUpdatesBanner } from '../EmailUpdatesBanner'
import { EmailUpdatesModal } from '../EmailUpdatesModal'

export interface OngoingEmailCampaignFromTemplateFormProps {
  emailCampaign?: OngoingEmailCampaignType
  refetchEmailCampaign: () => void
  venueProfile: VenueProfile
  experiencesData: ExperiencesData
  parentTemplate?: OngoingEmailCampaignTemplate
  autoTags: GenericTagGroup[]
}

export function OngoingEmailCampaignFromTemplateForm({
  emailCampaign,
  refetchEmailCampaign,
  venueProfile,
  experiencesData,
  parentTemplate,
  autoTags,
}: OngoingEmailCampaignFromTemplateFormProps) {
  const [isCancelled, setIsCancelled] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)
  const [updatesBannerVisible, setUpdatesBannerVisible] = useState<boolean>(!!emailCampaign?.hasParentTemplateUpdates)

  const { formatMessage } = useLocales()
  const nav = useNavigation()

  const campaignId = emailCampaign?.id

  const { venue, venueId } = useVenueContext()
  const updatesType = emailCampaign?.hasBeenManuallyModified ? 'manual' : 'auto'

  const [createEmailCampaign, { isLoading: isCreating }] = useCreateEmailCampaignMutation()
  const [updateEmailCampaignsQuery, { isLoading: isUpdating }] = useUpdateEmailCampaignsMutation()
  const isActionDisabled = isCreating || isUpdating

  const defaultValues = useMemo(
    () => getEmailBuilderFormDefaultValues(parentTemplate ?? emailCampaign ?? undefined, autoTags),
    [emailCampaign, parentTemplate, autoTags]
  )
  const form = useForm(useOngoingEmailCampaignFromTemplateForm(), {
    defaultValues,
  })

  const {
    field,
    formState: { isDirty },
    setValue,
    getValues,
    trigger,
    reset,
  } = form

  const formRef = useRef<HTMLFormElement>(null)
  const shouldExit = useRef<boolean>(false)
  const newCampaignId = useRef<string>('')

  const setCampaignStateActive = () => {
    setValue('campaignState', OngoingEmailCampaignStatusEnum.ACTIVE)
  }
  useBeforeUnload(isDirty && !isCancelled && !isSaved)

  const onUpdatesConfirm = useCallback(() => {
    setUpdatesBannerVisible(false)

    if (updatesType === 'manual') {
      refetchEmailCampaign()
    }
  }, [refetchEmailCampaign, updatesType])

  const trySaveEmailCampaign = async (formData: OngoingEmailCampaignFromTemplateFormData) => {
    const { bodySections, bodySectionsText } = packEmailBodySections(formData)
    const campaignData: NewEmailCampaignFields = {
      ongoingSendTimeConfig: packSendTimeConfig(formData),
      campaignType: MarketingCampaignTypeEnum.ONGOING,
      isAnyClientTags: true,
      status: formData.campaignState,
      campaignName: formData.campaignName,
      emailReplyTo: formData.replyToAddress,
      emailSender: getPlainTextFromHtml(formData.emailSender.value ?? ''),
      emailSubject: formData.subjectLine.value ?? '',
      emailBody: bodySectionsText,
      emailBodyParts: bodySections.map(part => part ?? undefined),
      previewText: formData.emailPreview.value ?? '',
      greeting: formData.emailGreeting?.value ?? '',
      signature: formData.signature?.value ?? '',
      signoff: formData.signoff?.value ?? '',
      footer: formData.footer?.value ?? '',
      recipientClientTags: extractAutoTagIds(formData.recipientAutotags),
      recipientClientTagsExclude: extractAutoTagIds(formData.excludedAutotags),
      parentTemplateId: parentTemplate?.id ?? emailCampaign?.parentTemplateId,
      emailBodyPartsConfig: formData.emailBodySections.map(emailBodySection => ({
        maxLen: emailBodySection.maxLen ?? undefined,
        isEditable: emailBodySection.isEditable,
      })),
      emailSenderMaxLen: formData.emailSender.maxLen ?? undefined,
      subjectMaxLen: formData.subjectLine.maxLen ?? undefined,
      previewMaxLen: formData.emailPreview.maxLen ?? undefined,
      greetingMaxLen: formData.emailGreeting?.maxLen ?? undefined,
      signatureMaxLen: formData.signature?.maxLen ?? undefined,
      signoffMaxLen: formData.signoff?.maxLen ?? undefined,
      footerMaxLen: formData.footer?.maxLen ?? undefined,
      isEmailSenderUserEditable: formData.emailSender.isEditable,
      isSubjectUserEditable: formData.subjectLine.isEditable,
      isPreviewUserEditable: formData.emailPreview.isEditable,
      isGreetingUserEditable: formData.emailGreeting?.isEditable,
      isSignatureUserEditable: formData.signature?.isEditable,
      isSignoffUserEditable: formData.signoff?.isEditable,
      isFooterUserEditable: formData.footer?.isEditable,
    }

    const createUpdatePromise =
      campaignId || newCampaignId.current
        ? updateEmailCampaignsQuery({ emailCampaignIds: [campaignId || newCampaignId.current], emailCampaignUpdate: campaignData, venueId })
        : createEmailCampaign({ emailCampaign: campaignData, venueId })

    try {
      setIsSaved(true)
      const updateResponse = await createUpdatePromise.unwrap()
      notify({
        content: formatMessage(campaignBuilderMessages.saveCampaignSuccessMessage),
        type: 'success',
      })
      if (shouldExit.current) {
        nav.push(routes.manager2.marketing.emailCenter.emails, { params: { venueKey: venue.urlKey }, queryMode: 'clear' })
      }
      if (!campaignId && !newCampaignId.current) {
        const response = updateResponse as EmailCampaignResult
        newCampaignId.current = response.emailCampaign?.id ?? ''
      }
      reset({}, { keepValues: true })
    } catch {
      notify({
        content: formatMessage(campaignBuilderMessages.saveCampaignErrorMessage),
        type: 'error',
      })
    }
    setIsSaved(false)
  }

  return (
    <>
      <SettingsPageMeta
        venue={venue.name}
        title={formatMessage(
          campaignId ? emailBuilderMessages.editAutomatedEmailCampaign : emailBuilderMessages.createAutomatedEmailCampaign
        )}
      />
      <Form {...form} onSubmit={trySaveEmailCampaign} onInvalid={() => {}} innerRef={formRef}>
        <SettingsPageContent
          headerWidth="calc(100% - 274px)"
          secondHeaderMaxWidth="968px"
          title={formatMessage(
            campaignId ? emailBuilderMessages.emailBuilderFromTemplateTitle : emailBuilderMessages.emailBuilderNewFromTemplateTitle
          )}
          actions={
            <HStack spacing="s">
              <Button
                data-test="sr-email-builder-cancel-button"
                variant="tertiary"
                onClick={() => {
                  if (isDirty) {
                    nav.push(routes.manager2.marketing.emailCenter.emails.emailBuilder.cancelEmailEditModal, {
                      params: { venueKey: venue.urlKey },
                    })
                  } else {
                    nav.push(routes.manager2.marketing.emailCenter.emails, { params: { venueKey: venue.urlKey } })
                  }
                }}
                disabled={isActionDisabled}
              >
                {formatMessage(commonMessages.cancel)}
              </Button>
              {campaignId && emailCampaign?.status !== OngoingEmailCampaignStatusEnum.DRAFT ? (
                <ActionsButton
                  data-test="sr-email-builder-save-changes-button"
                  variant="primary"
                  type="submit"
                  disabled={isActionDisabled}
                  actions={[
                    {
                      id: 'saveChanges',
                      title: formatMessage(commonMessages.saveChanges),
                      onClick: () => {
                        shouldExit.current = false
                      },
                    },
                    {
                      id: 'saveAndExit',
                      title: formatMessage(commonMessages.saveAndExit),
                      onClick: () => {
                        shouldExit.current = true
                        formRef?.current?.requestSubmit()
                      },
                    },
                  ]}
                />
              ) : (
                <>
                  <ActionsButton
                    data-test="sr-email-builder-save-as-draft-button"
                    variant="secondary"
                    type="submit"
                    disabled={isActionDisabled}
                    actions={[
                      {
                        id: 'saveDraft',
                        title: formatMessage(commonMessages.saveDraft),
                        onClick: () => {
                          shouldExit.current = false
                          setValue('campaignState', OngoingEmailCampaignStatusEnum.DRAFT, { shouldDirty: false })
                        },
                      },
                      {
                        id: 'saveDraftAndExit',
                        title: formatMessage(commonMessages.saveAndExit),
                        onClick: () => {
                          shouldExit.current = true
                          setValue('campaignState', OngoingEmailCampaignStatusEnum.DRAFT, { shouldDirty: false })
                          formRef?.current?.requestSubmit()
                        },
                      },
                    ]}
                  />
                  <Button
                    data-test="sr-email-builder-save-and-publish-button"
                    variant="primary"
                    type="submit"
                    onClick={() => {
                      shouldExit.current = true
                      setValue('campaignState', OngoingEmailCampaignStatusEnum.ACTIVE)
                    }}
                    disabled={isActionDisabled}
                  >
                    {formatMessage(commonMessages.saveAndPublish)}
                  </Button>
                </>
              )}
            </HStack>
          }
        >
          <Box p="lm" width="100%">
            <VStack spacing="lm">
              {campaignId && updatesBannerVisible && (
                <VStack spacing="lm" maxWidth="968px">
                  <EmailUpdatesBanner type={updatesType} campaignId={campaignId} updatedAt={emailCampaign?.updated?.toDateOnly()} />
                </VStack>
              )}
              <EmailDetails autoFocus field={field as unknown as EmailDetailsFormField} isAutomatedEmail />
              <Audience
                campaignContent={emailCampaign}
                field={field as unknown as AudienceFormField}
                venue={venue}
                venueProfile={venueProfile}
                isFromTemplate
                messages={emailBuilderMessages}
              />
              <OngoingSendingSchedule field={field as unknown as OngoingSendingScheduleFormField} isFromTemplate />
              <EmailContent
                field={field as unknown as EmailContentFormField}
                venueProfile={venueProfile}
                experiencesData={experiencesData}
              />
            </VStack>
          </Box>
        </SettingsPageContent>
      </Form>

      <Surface destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal}>
        <Window>
          <SendTestEmailModal
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal, {
              params: { venueKey: venue.urlKey },
            })}
            subjectLine={getValues().subjectLine?.value ?? ''}
            senderAddress={getPlainTextFromHtml(getValues().emailSender?.value ?? '')}
            replyToAddress={getValues().replyToAddress}
            previewText={getValues().emailPreview?.value ?? ''}
            template={generateEmailCampaignPreviewTemplate(
              getValues().emailGreeting?.value ?? '',
              packEmailBodySections(getValues() as OngoingEmailCampaignFromTemplateFormData).bodySectionsText,
              getValues().signature?.value ?? '',
              getValues().signoff?.value ?? '',
              getValues().footer?.value ?? ''
            )}
            emailBody={packEmailBodySections(getValues() as OngoingEmailCampaignFromTemplateFormData).bodySectionsText}
            signoff={getValues().signoff?.value ?? ''}
            emailGreeting={getValues().emailGreeting?.value ?? ''}
            footer={getValues().footer?.value ?? ''}
            signature={getValues().signature?.value ?? ''}
            venueId={venueId}
            onConfirm={setCampaignStateActive}
            onValidate={() =>
              trigger(['campaignName', 'replyToAddress', 'emailSender', 'subjectLine.value', 'emailBodySections'], {
                shouldFocus: true,
              })
            }
          />
        </Window>
      </Surface>

      <Surface destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.cancelEmailEditModal}>
        <Window>
          <CancelEmailEditModal
            onCancel={() => setIsCancelled(false)}
            onConfirm={() => setIsCancelled(true)}
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.emailBuilder.cancelEmailEditModal, {
              params: { venueKey: venue.urlKey },
            })}
          />
        </Window>
      </Surface>

      {campaignId && (
        <Surface destination={routes.manager2.marketing.emailCenter.emails.editEmail.updatesModal}>
          <Window>
            <EmailUpdatesModal
              type={updatesType}
              campaignId={campaignId}
              updatedAt={emailCampaign?.updated?.toDateOnly()}
              updates={emailCampaign?.parentTemplateUpdatedFields}
              onConfirm={onUpdatesConfirm}
            />
          </Window>
        </Surface>
      )}
    </>
  )
}

const packEmailBodySections = (formData: OngoingEmailCampaignFromTemplateFormData) => {
  const bodySections = formData.emailBodySections ? formData.emailBodySections.map(section => section.value) : []
  const bodySectionsText = bodySections.join('<br/><br/>')
  return { bodySections, bodySectionsText }
}
