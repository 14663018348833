import { LINK_REVIEW_SUCCESS } from 'mgr/actualslideout/actions/ActionTypes'
import { DayRanges } from 'mgr/lib/utils/Constants'
import { OPEN_REVIEW } from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import {
  OPEN_SEARCH_MODAL,
  CLOSE_SEARCH_MODAL,
  BEGIN_BOOKING_SEARCH_RESULT_FETCH,
  END_BOOKING_SEARCH_RESULT_FETCH,
  BEGIN_CLIENT_SEARCH_RESULT_FETCH,
  DISPATCH_MULTI_SEARCH,
  INIT,
  END_CLIENT_SEARCH_RESULT_FETCH,
  CHOOSE_TAB,
  UPDATE_SEARCH_SHIFT,
  UPDATE_SEARCH_TIME,
} from '../actions/ActionTypes'

const initialSearchState = {
  searchQuery: '',
  searchShift: 'ALL',
  searchTime: DayRanges.L30DAY,
  clients: [],
  isFetchingClientResults: false,
  isFetchingBookingResults: false,
  isModalOpen: false,
  futureBookings: [],
  pastBookings: [],
  numFound: 0,
  numberOfClientsFound: 0,
  venueKey: '',
  activeTab: 'upcoming',
  isBlank: true,
  isSingleVenue: true,
  reviewId: null,

  // These do change for link searching
  isReservationSlideoutAvailable: true,
  isRequestSlideoutAvailable: false,

  reservationRequestId: 0,
  clientRequestId: 0,
  tabs: [
    { action: 'upcoming', text: 'Upcoming' },
    { action: 'past', text: 'Past' },
    {
      action: 'clients',
      text: 'Clients',
    },
  ],
}

const searchReducer = (state = initialSearchState, action) => {
  switch (action.type) {
    case DISPATCH_MULTI_SEARCH: {
      const { searchQuery, isBlank } = action
      if (isBlank === true) {
        return {
          ...state,
          isBlank,
          searchQuery,
          futureBookings: [],
          pastBookings: [],
          numFound: 0,
          numberOfClientsFound: 0,
          clients: [],
        }
      }
      return { ...state, searchQuery, isBlank }
    }
    case OPEN_SEARCH_MODAL:
      return {
        ...state,
        isModalOpen: true,
        searchQuery: '',
        futureBookings: [],
        pastBookings: [],
      }
    case OPEN_REVIEW:
      if (!action.selected) {
        return {
          ...state,
          isModalOpen: false,
          reviewId: null,
        }
      }
      return {
        ...state,
        reviewId: action.selected.id,
      }
    case CLOSE_SEARCH_MODAL:
      // Naughty, Naughty...
      window.unsetBodyOverflow()
      return {
        ...state,
        isModalOpen: false,
      }
    case BEGIN_BOOKING_SEARCH_RESULT_FETCH:
      const { reservationRequestId } = action
      return { ...state, isFetchingBookingResults: true, reservationRequestId }
    case BEGIN_CLIENT_SEARCH_RESULT_FETCH:
      const { clientRequestId } = action
      return { ...state, isFetchingClientResults: true, clientRequestId }
    case INIT:
      const { venueKey } = action
      return { ...state, venueKey }
    case END_BOOKING_SEARCH_RESULT_FETCH: {
      const { pastBookings, futureBookings, isSingleVenue, numFound, reservationRequestId } = action
      if (state.reservationRequestId === reservationRequestId) {
        return {
          ...state,
          pastBookings,
          isSingleVenue,
          futureBookings,
          numFound,
          isBlank: false,
          isFetchingBookingResults: false,
        }
      }
      return state
    }
    case END_CLIENT_SEARCH_RESULT_FETCH: {
      const { clients, numberOfClientsFound, clientRequestId } = action
      if (state.clientRequestId === clientRequestId) {
        return {
          ...state,
          clients,
          numberOfClientsFound,
          isBlank: false,
          isFetchingClientResults: false,
        }
      }
      return state
    }
    case CHOOSE_TAB: {
      const { activeTab } = action
      return { ...state, activeTab }
    }
    case UPDATE_SEARCH_SHIFT:
      return { ...state, searchShift: action.shift }
    case UPDATE_SEARCH_TIME:
      return { ...state, searchTime: action.time }
    case LINK_REVIEW_SUCCESS:
      return {
        ...state,
        searchQuery: '',
        searchShift: 'ALL',
        searchTime: DayRanges.L30DAY,
        isModalOpen: false,
        pastBookings: [],
      }
    default:
      return state
  }
}

export default searchReducer
