import React from 'react'
import styled from 'styled-components'
import { ModalContainer, ModalShadow, ModalHeader, ModalClose, PulsatingDots, ActionButton } from 'mgr/layout/StyledComponentUtils'
import TextInput, { InputRestrictions, ValidatorTypes } from 'mgr/lib/forms/TextInput'

const FormWrap = styled.div`
  padding: 20px;
  max-height: 500px;
  overflow: scroll;
`

const NotificationForm = ({
  notificationEmail,
  isModalSubmitting,
  notificationType,
  selectedClient,
  actions,
  formErrors,
  validateFieldMap,
  onSubmitNotificationHandler,
}) => {
  let title
  switch (notificationType) {
    case 'refund':
      title = 'Refund notification'
      break
    case 'paylink':
      title = 'Send paylink'
      break
    default:
      title = 'Charge notification'
  }
  return (
    <div>
      <ModalContainer data-test="sr-notification-form">
        <ModalHeader>
          {title}
          <ModalClose onClick={() => actions.toggleNotificationModal(null, null)} />
        </ModalHeader>
        <FormWrap>
          <TextInput
            disabled={!selectedClient.is_email_address_editable}
            label="EMAIL"
            placeholder=""
            inputRestriction={InputRestrictions.email}
            value={notificationEmail}
            onChange={actions.changeNotificationEmail}
            validator={ValidatorTypes.emailRequired}
            ref={e => (validateFieldMap.email = e)}
            isValid={!formErrors.email}
          />
          <br />
          <ActionButton
            data-test="sr-notification-form-submit"
            onClick={() => onSubmitNotificationHandler(notificationType == 'refund', null)}
            disabled={isModalSubmitting}
          >
            {isModalSubmitting ? <PulsatingDots /> : 'Submit'}
          </ActionButton>
        </FormWrap>
      </ModalContainer>
      <ModalShadow onClick={() => actions.toggleNotificationModal(null, null)} />
    </div>
  )
}

export default NotificationForm
