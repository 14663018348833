import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { BadgedVmsIconXS } from 'svr/common/BadgedIcon'
import { StyledVmsIconS, StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import { FlexColumnContainer, FlexRowContainer } from './ViewLayout'

export const ViewCardTitle = styled.div`
  color: ${props => props.theme.navigationDark};
  font-size: 20px;
  line-height: 20px;
`

export const LegacyPaddedP = styled.p`
  padding: 0;
  margin: 0;
`
export const StandardFieldText = styled(LegacyPaddedP)`
  color: ${props => props.theme.navigationDark};
  font-family: ${props => props.theme.fontFamily};
  font-size: 15px;
  font-weight: 300;
  white-space: pre-line;
`

const HorizontalFlexListContainer = styled.ul`
  display: flex;
  justify-content: ${props => props.justifyContent};
`

export const ViewCardTitleSmall = styled(ViewCardTitle)`
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 600;
`

export const CardEntryLabel = styled.span`
  color: ${props => props.theme.darkGrey};
  text-transform: uppercase;
  font-family: ${props => props.theme.fontFamily};
  font-size: 11px;
`

export const CardBase = styled.div`
  background-color: ${props => props.theme.background};
  ${props => (props.canEditReservation ? 'cursor: pointer;' : '')} &:hover {
    background-color: ${props => (props.canEditReservation ? props.theme.color.primary : props.theme.background)};
    ${props => (props.canEditReservation ? 'box-shadow: 0 0 6px 0 rgba(0,0,0,0.18);' : '')};
  }
`

export const SpecialCardSection = styled(CardBase)`
  ${props => (props.isTopCard || props.hasTopMargin ? 'margin-top: -8px;' : '')}
  ${props => (props.isTopCard || props.hasTopMargin ? 'padding-top: 8px;' : '')}
  ${props => (props.isBottomCard ? 'margin-bottom: -8px;' : '')}
  ${props => (props.isBottomCard ? 'padding-bottom: 8px;' : '')}
  margin-left: -16px;
  padding-left: 16px;
  margin-right: -16px;
  padding-right: 16px;
  ${props => (props.isTopCard ? 'border-top-right-radius: 6px;' : '')}
  ${props => (props.isTopCard ? 'border-top-left-radius: 6px;' : '')}
  ${props => (props.isBottomCard ? 'border-bottom-right-radius: 6px;' : '')}
  ${props => (props.isBottomCard ? 'border-bottom-left-radius: 6px;' : '')}
`

export const ViewField = styled.div`
  padding: 8px 0px;
`

export const LastCardSectionInner = styled.div`
  padding: ${props => (props.noHeader ? '8px 16px' : '16px')} !important;
  border-top: ${props => (props.showBorder ? `1px solid ${props.theme.lightGrey}` : 'none')};
`

export const LastCardSection = ({ children, showBorder, style, noHeader }) => (
  <LastCardSectionInner showBorder={showBorder} style={style} noHeader={noHeader}>
    {children}
  </LastCardSectionInner>
)
export const SectionedCard = styled(CardBase)`
  border-radius: 6px;
  padding: 8px 16px !important;
  margin: 4px;
`

export const Card = styled(SectionedCard)`
  padding: 16px;
`

export const FollowerBookedByContainer = styled(FlexRowContainer)`
  background-color: ${props => props.theme.color.default};
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.darkGrey};
  cursor: pointer;
  font-size: 12px;
`
export const FollowerBookedbyNameLabel = styled.div`
  text-transform: uppercase;
  margin: 0 8px;
  white-space: nowrap;
`

export const CommonBadge = styled.li`
  height: 28px !important;
  width: 28px !important;
  min-width: 28px;
  min-height: 28px;
  margin: 8px 2px !important;
  list-style-type: none;
  border-radius: 14px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  padding: 0 !important;
`
const CommonBadgeDiv = React.forwardRef((props, ref) => <CommonBadge {...props} as="div" ref={ref} />)

export const FollowerBadge = styled(CommonBadge)`
  background-color: ${props => props.theme.mediumSilver};
  position: relative;
  color: ${props => props.theme.lightGrey};
  font-family: ${props => props.theme.fontFamily};
  font-size: 12px;
`

export const EdgeToEdgeCard = styled(CardBase)`
  margin-bottom: 4px;
`

export const CardDivider = styled.hr`
  width: 381px;
  display: block;
  height: 1px;
  border: 0;
  margin: ${props => props.margin || '8px 0 8px -16px !important'};
  padding: 0 !important;
  border-top: 1px solid ${props => props.theme.lighterSilver};
`

export const NoDecorationListItem = styled.li`
  list-style-type: none;
`
export const CardDetail = ({ icon, label, addNewLabel, children }) => {
  let labelContents
  let displayIcon

  if (!children) {
    displayIcon = <BadgedVmsIconXS badgeIcon={VmsIcons.Add} displayIcon={icon} />
    labelContents = addNewLabel && <CardEntryLabel>{addNewLabel}</CardEntryLabel>
  } else {
    displayIcon = <StyledVmsIconXS>{icon}</StyledVmsIconXS>
    labelContents = (
      <CardEntryLabel>
        {icon}
        {label}
      </CardEntryLabel>
    )
  }

  return (
    <FlexRowContainer alignItems="flex-start">
      {displayIcon}
      <FlexColumnContainer>
        {labelContents}
        {children}
      </FlexColumnContainer>
    </FlexRowContainer>
  )
}

const AddText = styled.span`
  font-size: 15px;
  line-height: 15px;
`

const BoldAddText = styled(AddText)`
  font-weight: 600;
`

export const AddTextWithBoldPart = ({ testId, boldText, regularText }) => {
  let boldTextElements
  if (boldText) {
    boldTextElements = <BoldAddText>{boldText}</BoldAddText>
  }
  return (
    <AddText data-test={testId}>
      {boldTextElements}
      <i> {regularText}</i>
    </AddText>
  )
}

export const CardDetailRowContainer = styled(FlexRowContainer)`
  color: ${props => props.theme.darkGrey};
  cursor: pointer;
  line-height: 20px;
`

export const IconArea = styled.div`
  margin-right: 8px;
`
// The idea behind this is to encapsulate the behavior of an editable detail field so that
// all editable fields behave the same way regardless of what kind of component is actually
// used in the view or edit states.

// Instead of having a container component for each variation of default/view/edit component for
// things like tags, text, phones, etc, this factory (aka higher-order-component)
// produces the required container component.

// EDIT: In hindsight, this is a very roundabout way of doing this.
// There does not need to be a HOC  for this to work
export const CardDetailFactory =
  (DefaultComponent, ViewComponent, EditComponent) =>
  // The "Add" state is based on getting
  // an empty value. Empty being null, undefined, zero-length array or zero-size object.

  // FIXME: It would not be a bad idea to make this
  // a class based component since it won't be thrown away
  // each time that way.
  ({ icon, label, addNewLabel, value }) => {
    let labelContents
    let displayIcon
    let contents

    const hasNoValue = _.isNil(value) || _.isEmpty(value)
    if (hasNoValue) {
      displayIcon = <BadgedVmsIconXS badgeIcon={VmsIcons.Add} displayIcon={icon} />
      labelContents = addNewLabel && <CardEntryLabel>{addNewLabel}</CardEntryLabel>
      contents = <DefaultComponent />
    } else {
      displayIcon = <StyledVmsIconXS>{icon}</StyledVmsIconXS>
      labelContents = label && <CardEntryLabel>{label}</CardEntryLabel>
      contents = <ViewComponent value={value} />
    }

    return (
      <CardDetailRowContainer alignItems={hasNoValue || label == null ? 'center' : 'flex-start'}>
        <IconArea>{displayIcon}</IconArea>
        <FlexColumnContainer style={{ width: 'auto' }}>
          {labelContents}
          {contents}
        </FlexColumnContainer>
      </CardDetailRowContainer>
    )
  }

export const LegacyPaddedUl = styled.ul`
  padding: 0;
  margin: 0;
`

export const FULL_HEIGHT = { height: '100%' }

const AddIcon = styled(StyledVmsIconS)`
  font-size: 20px;
  text-align: center;
  color: ${props => props.theme.mediumSilver};
  line-height: 28px;
  cursor: pointer;
`

export const AddButton = ({ onClick, style, children }) => (
  <AddIcon onClick={onClick} style={style}>
    {VmsIcons.AddLine}
    {children}
  </AddIcon>
)

const RedCloseButtonS = styled(StyledVmsIconS)`
  color: ${props => props.theme.error};
  cursor: pointer;
`
export const CloseButton = ({ onClick, style }) => (
  <RedCloseButtonS onClick={onClick} style={style}>
    {VmsIcons.Clear}
  </RedCloseButtonS>
)
