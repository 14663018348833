export const shortCodes = {
  clientFirstName: '<client_first_name>',
  clientLastName: '<client_last_name>',
  clientSalutation: '<client_salutation>',
  firstName: '<first_name>',
  lastName: '<last_name>',
  menuLink: '<menu_link>',
  reservationWidgetLink: '<reservation_widget_link>',
  senderEmail: '<sender_email>',
  senderFirstName: '<sender_first_name>',
  senderLastName: '<sender_last_name>',
  senderRole: '<sender_role>',
  venueAddress: '<venue_address>',
  venueCity: '<venue_city>',
  venueCountry: '<venue_country>',
  venueName: '<venue_name>',
  venuePhone: '<venue_phone>',
  venuePostalCode: '<venue_postal_code>',
  venueState: '<venue_state>',
  venueWebsite: '<venue_website>',
}
