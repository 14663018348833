import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as PaymentActions from 'mgr/actualslideout/actions/PaymentActions'

const CardField = styled.div`
  height: 40px;
  width: 355px;
  margin-bottom: 5px;
`

const CardRow = styled.div`
  margin: 10px 0 10px 5px;
`

class SaferpayFormRender extends Component {
  componentDidMount() {
    this.initSaferpay()
  }

  initSaferpay() {
    SaferpayFields.init({
      accessToken: this.props.saferpayInitialData.motoAccessToken,
      url: this.props.saferpayInitialData.motoUrlFields,
      style: {
        '.input-large': 'background-color: rgba(255, 255, 255, 0.5);',
      },
      paymentMethods: this.props.saferpayInitialData.paymentMethods.split(','),
      onError(evt) {
        console.error(evt)
      },
      onValidated: this.props.onValidate,
      placeholders: {
        holdername: 'Card holder',
        cardnumber: '0000 0000 0000 0000',
        expiration: 'MM/YY',
        cvc: 'CVC',
      },
    })
  }

  render() {
    return (
      <div>
        <CardRow className="row">
          <div className="col-md-12 field">
            <CardField id="fields-holder-name" />
          </div>
        </CardRow>
        <CardRow className="row">
          <div className="col-md-12 field">
            <CardField id="fields-card-number" />
          </div>
        </CardRow>
        <CardRow className="row">
          <div className="col-md-7 field">
            <CardField id="fields-expiration" />
          </div>
        </CardRow>
        <CardRow>
          <div className="col-md-5 field">
            <CardField id="fields-cvc" />
          </div>
        </CardRow>
      </div>
    )
  }
}

class SaferpayFormFetch extends Component {
  componentDidMount() {
    this.props.fetchSaferpayInitialData(this.props.venueId)
  }

  onValidate = event => {
    if (this.props.onValidate instanceof Function) {
      this.props.onValidate(event)
    }
  }

  render() {
    if (!this.props.saferpayInitialData.motoAccessToken || this.props.saferpayInitialData.needClean) {
      return null
    }

    return <SaferpayFormRender onValidate={this.onValidate} key={this.props.venueId} saferpayInitialData={this.props.saferpayInitialData} />
  }
}

const mapStateToProps = state => ({
  venueId: state.appState.venue.id,
  saferpayInitialData: state.bookPaymentState.saferpayInitialData,
})

const mapDispatchToProps = {
  fetchSaferpayInitialData: PaymentActions.fetchSaferpayInitialData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SaferpayFormFetch)
