import * as React from 'react'
import { DividerLine, VStack } from '@sevenrooms/core/ui-kit/layout'
import { EmailCampaignPerformanceContext, EmailCampaignPerformance } from '@sevenrooms/marketing'
import { EmailCampaigns } from '../../components'
import { emailCenterMessages } from '../../locales'

export interface EmailsProps {}

export function Emails() {
  const [campaignPerformanceCollapsed, setCampaignPerformanceCollapsed] = React.useState<boolean>(true)

  return (
    <VStack backgroundColor="primaryBackground" borderColor="borders" borderRadius="s">
      <EmailCampaignPerformanceContext>
        <EmailCampaignPerformance
          collapsed={campaignPerformanceCollapsed}
          onCollapsedChange={setCampaignPerformanceCollapsed}
          messages={emailCenterMessages}
        />

        <DividerLine margin="none" />

        <EmailCampaigns />
      </EmailCampaignPerformanceContext>
    </VStack>
  )
}
