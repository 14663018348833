import * as ActionTypes from '../actions/ActionTypes'

const initialState = {
  perks: [],
}

const perksReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_VENUE_PERKS_SUCCESS:
      return {
        ...state,
        perks: action.perks,
      }
    default:
      return state
  }
}

export default perksReducer
