import { type ChangeEvent, useCallback } from 'react'
import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { excludeFromShiftPacingLocales } from './ExcludeFromShiftPacing.locales'

interface ExcludeFromShiftPacingProps {
  excludeFromShiftPacing: boolean
  disabled: boolean
  onChange: (value: boolean) => void
}

export function ExcludeFromShiftPacingField({ excludeFromShiftPacing, disabled, onChange }: ExcludeFromShiftPacingProps) {
  const { formatMessage } = useLocales()

  const onCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.checked)
    },
    [onChange]
  )

  return (
    <Box>
      <Checkbox
        checked={excludeFromShiftPacing}
        disabled={disabled}
        onChange={onCheckboxChange}
        info={
          <VStack spacing="s">
            <Text color="lightFont" fontWeight="bold">
              {formatMessage(excludeFromShiftPacingLocales.tooltipTitle)}
            </Text>
            <Text color="lightFont">{formatMessage(excludeFromShiftPacingLocales.tooltipBody)}</Text>
          </VStack>
        }
      >
        {formatMessage(excludeFromShiftPacingLocales.editLabel)}
      </Checkbox>
    </Box>
  )
}

const StyledExcludeFromShiftPacingBanner = styled.div`
  text-align: center;
  padding: 7px 15px;
  font-size: 14px;
`

export function ExcludeFromShiftPacingBanner() {
  const { formatMessage } = useLocales()
  return <StyledExcludeFromShiftPacingBanner>{formatMessage(excludeFromShiftPacingLocales.viewLabel)}</StyledExcludeFromShiftPacingBanner>
}
