import * as ActionTypes from 'mgr/actualslideout/actions/ActionTypes'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'

const initialSlideoutState = {
  isSlideoutOpen: false,
  isViewMode: true,
  actualId: null,
  venueId: null,
  notificationBannerState: {
    message: '',
    level: 'INFO', // INFO, WARN, ERROR, SUCCESS
    visible: false,
    actionText: null,
    actionType: null,
  },
  salutationOptions: [],
  registeredHandlers: [],
}

const slideoutReducer = (state = initialSlideoutState, action) => {
  switch (action.type) {
    case ActionTypes.REGISTER_CALLBACK_HANDLERS:
      return {
        ...state,
        registeredHandlers: [...state.registeredHandlers, action.handlers],
      }
    case GlobalActionTypes.INITIALIZE:
      return { ...state, salutationOptions: action.globalInit.venueSettings.salutation_options }
    case ActionTypes.VIEW_ACTUAL: {
      const { actualId, venueId, venueSettings } = action
      return {
        ...state,
        isSlideoutOpen: true,
        actualId,
        venueId,
        isViewMode: true,
      }
    }
    case ActionTypes.ENTER_ADD_RESERVATION:
      return {
        ...state,
        isSlideoutOpen: true,
        actualId: null,
        venueId: null,
        isViewMode: false,
      }
    case ActionTypes.ENTER_EDIT_RESERVATION: {
      const { actual } = action
      return {
        ...state,
        isSlideoutOpen: true,
        actualId: actual.id,
        venueId: actual.venue_id,
        isViewMode: false,
      }
    }
    case ActionTypes.ENTER_CLONE_RESERVATION: {
      const { actual } = action
      return {
        ...state,
        isSlideoutOpen: true,
        actualId: null,
        venueId: actual.venue_id,
        isViewMode: false,
      }
    }
    case ActionTypes.IGNORE_UNSAVED_WARNING:
    case ActionTypes.CLOSE_SLIDEOUT:
      // Hack to clear selected row on res list until we reactify it
      // eslint-disable-next-line no-unused-expressions
      $ && $('.standard-row').removeClass('selected')
      return { ...state, isSlideoutOpen: false }
    case ActionTypes.SHOW_NOTIFICATION_BANNER:
      return {
        ...state,
        notificationBannerState: {
          ...action.notificationBannerState,
          visible: true,
        },
      }
    case ActionTypes.CLOSE_NOTIFICATION_BANNER:
    case ActionTypes.CLICK_NOTIFICATION_BANNER_ACTION:
      return {
        ...state,
        notificationBannerState: {
          ...state.notificationBannerState,
          visible: false,
        },
      }
    case ActionTypes.BOOK_SUBMIT_RESERVATION_SUCCESS: {
      const { actual } = action
      return {
        ...state,
        isViewMode: true,
        actualId: actual.id,
        venueId: actual.venue_id,
      }
    }
    default:
      return state
  }
}

export default slideoutReducer
