import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { State } from '../../../../application/site/static/app/manager/pages/src/reducers/CombineReducer'

export interface EmailBuilderState {
  campaignInfo: EmailCampaignInterface
  destinationFromBuilder?: string
}

interface EmailCampaignInterface {
  currentCampaignId: string
  isArchiveModalShowing: boolean
}

const initialState: EmailBuilderState = {
  campaignInfo: {
    currentCampaignId: '',
    isArchiveModalShowing: false,
  },
  destinationFromBuilder: undefined,
}

const emailBuilderSlice = createSlice({
  name: 'emailBuilder',
  initialState,
  reducers: {
    setCurrentCampaignId: (state, action: PayloadAction<string>) => {
      state.campaignInfo.currentCampaignId = action.payload
    },
    setArchiveModalShowing: (state, action: PayloadAction<boolean>) => {
      state.campaignInfo.isArchiveModalShowing = action.payload
    },
  },
})

export const getCampaignInfo = (state: State) => state.emailBuilder.campaignInfo

export const { setArchiveModalShowing, setCurrentCampaignId } = emailBuilderSlice.actions
export const { reducer: emailBuilderReducer } = emailBuilderSlice
