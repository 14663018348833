import AutoFixIcon from '@mui/icons-material/AutoFixHigh'
import SendIcon from '@mui/icons-material/Send'
import { useState } from 'react'
import { useUpdateRegeneratedTextMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { FloatingBarButton } from '@sevenrooms/core/ui-kit/core'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { emailBuilderMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'

export function EmailBodyContentItem({ text, index, updateField }: { text: string; index: number; updateField: Function }) {
  const { venueId } = useVenueContext()
  const [regenerateText] = useUpdateRegeneratedTextMutation()
  const { formatMessage } = useLocales()
  const [displayedText, setDisplayedText] = useState(text)
  const [isHovering, setIsHovering] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [isTyping, setIsTyping] = useState(false)

  const startTypingEffect = async (action: string) => {
    setDisplayedText(formatMessage(emailBuilderMessages.assistantModalThinking))
    setIsTyping(true)
    const response = await regenerateText({
      params: {
        textToRegenerate: text,
        regenerationAction: action,
      },
      venueId,
    })
    if ('data' in response) {
      const regeneratedText = response.data.regeneratedText ?? ''
      updateField(regeneratedText, index)

      let currentText = ''
      let charIndex = 0

      const intervalId = setInterval(() => {
        currentText += regeneratedText[charIndex]
        charIndex += 1

        setDisplayedText(currentText)

        if (charIndex === regeneratedText.length) {
          clearInterval(intervalId)
          setIsTyping(false)
          setIsHovering(false)
          setIsSelected(false)
        }
      }, 30)
    }
  }

  return (
    <Flex
      width="100%"
      backgroundColor={!isTyping && (isHovering || isSelected) ? 'highlight' : undefined}
      onClick={() => !isTyping && setIsSelected(true)}
      tabIndex={0}
      cursor="pointer"
      position="relative"
      onMouseOver={() => !isTyping && setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
      p="s"
      borderRadius="s"
      onBlur={() => {
        setIsHovering(false)
        setIsSelected(false)
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: displayedText }} />
      {!isTyping && isSelected && (
        <Flex position="absolute" top="none" justifyContent="center" alignItems="center" width="100%" height="100%" zIndex="overlay">
          <FloatingBarButton onSelect={startTypingEffect} prefixIcon={<AutoFixIcon />} sendIcon={<SendIcon />} />
        </Flex>
      )}
    </Flex>
  )
}
