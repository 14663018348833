import _ from 'lodash'
import { limitValue } from '../Input/numberInputUtils'
import { inputTypes } from './inputTypes'

const toNumber = value => {
  if (Number.isNaN(value)) {
    return ''
  }

  return value
}

export const castValueByType = (value, type, max) => {
  if (_.isNil(value)) {
    return value
  }

  let result = ''
  switch (type) {
    // float, currency
    case inputTypes.CURRENCY:
    case inputTypes.POSITIVE_NUMBER:
      result = toNumber(Math.abs(Number(value)))
      break
    // integer
    case inputTypes.POSITIVE_INT:
      result = toNumber(Math.abs(Math.floor(Number(value))))
      break
    // string
    default:
      result = String(value)
      break
  }

  if (typeof result === 'number') {
    return limitValue(result, undefined, undefined, max)
  }

  return result
}
