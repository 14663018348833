export const PROMPT_OPTIONS = [
  { id: 'thank-visit', label: 'thank a diner for a recent visit' },
  { id: 'cross-promote', label: 'cross-promote another venue in your group' },
  { id: 'share-details', label: 'share details with guests about an upcoming reservation' },
  { id: 'invite-guest', label: 'invite a guest to come back in' },
  { id: 'promote-online', label: 'promote Online Ordering to diners' },
  { id: 'special-promo', label: 'offer a special promotion to guests' },
]

export const CALL_TO_ACTION_OPTIONS = [
  { id: 'book-reservation', label: 'book a reservation' },
  { id: 'order-online', label: 'order online' },
  { id: 'acknowledgement', label: 'Acknowledgement (no CTA)' },
  { id: 'informational', label: 'informational (no CTA)' },
]

export const TONE_OPTIONS = [
  {
    id: 'concise',
    label: 'concise: short; direct and to the point',
  },
  {
    id: 'detailed',
    label: 'detailed: thorough; explains message in-depth',
  },
  {
    id: 'informative',
    label: 'informative: educational; brings awareness to message',
  },
  {
    id: 'persuasive',
    label: 'persuasive: convincing; aims to influence behaviors',
  },
  {
    id: 'urgent',
    label: 'urgent: creates sense of immediacy and encourages prompt action',
  },
]

export const STYLE_OPTIONS = [
  {
    id: 'casual',
    label: 'casual: relaxed, informal',
  },
  {
    id: 'witty',
    label: 'witty: playful, lighthearted',
  },
  {
    id: 'eloquent',
    label: 'eloquent: fluent, articulate',
  },
  {
    id: 'formal',
    label: 'formal: classic, professional',
  },
]

export const EMAIL_SENDER_OPTIONS = [
  { id: 'tiffany', label: 'Tiffany Lieu' },
  { id: 'mariesa', label: 'Mariesa Portmann' },
  { id: 'alana', label: 'Alana Steinberg' },
  { id: 'venue', label: 'The venue' },
]

export const PROMPT_MAPPING = {
  'thank-visit': {
    prompt: 'thank-visit',
    callToAction: 'book-reservation',
    style: 'formal',
    tone: 'concise',
    sender: 'venue',
  },
  'cross-promote': {
    prompt: 'cross-promote',
    callToAction: 'book-reservation',
    style: 'formal',
    tone: 'persuasive',
    sender: 'venue',
  },
  'share-details': {
    prompt: 'share-details',
    callToAction: 'informational',
    style: 'eloquent',
    tone: 'informative',
    sender: 'venue',
  },
  'invite-guest': {
    prompt: 'invite-guest',
    callToAction: 'book-reservation',
    style: 'casual',
    tone: 'persuasive',
    sender: 'venue',
  },
  'promote-online': {
    prompt: 'promote-online',
    callToAction: 'order-online',
    style: 'casual',
    tone: 'persuasive',
    sender: 'venue',
  },
  'special-promo': {
    prompt: 'special-promo',
    callToAction: 'book-reservation',
    style: 'witty',
    tone: 'urgent',
    sender: 'venue',
  },
}
