import { ConfigTierMap, AutotagTierType } from 'mgr/pages/shared/utils/Constants'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'

const getTierAttributes = (tierType, sampleTier) => {
  switch (tierType) {
    case AutotagTierType.SPEND_GLOBAL_TIER:
      return {
        MIN_VISITS: 3,
        SPEND_AMOUNT: '',
        SPEND_TYPE: 'TOTAL',
        SPEND_CURRENCY: sampleTier.attributes.SPEND_CURRENCY,
      }
    case AutotagTierType.SPEND_LOCAL_TIER:
      return {
        MIN_VISITS: 3,
        SPEND_AMOUNT: '',
        SPEND_TYPE: 'TOTAL',
      }
    case AutotagTierType.VISITS_GLOBAL_TIER:
      return {
        MIN_VISITS: 3,
        PAST_MONTHS_MAX: 3,
        MIN_VENUES: 1,
      }
    case AutotagTierType.VISITS_LOCAL_TIER:
      return {
        MIN_VISITS: 3,
        PAST_MONTHS_MAX: 3,
      }
    default:
      return {}
  }
}

const defaultTier = {
  tier_type: '',
  name_display: '',
  attributes: {},
  is_active: true,
}

const initialState = {
  tag: null,
  editingTier: null,
  initialTier: null,
  saving: false,
  warning: false,
  tierError: null,
  isCustomAutotagEditorVisible: false,
  confirmDeactivate: false,
  perksByTagName: null,
  notesByTagName: null,
}

const tagEditReducer = (state = initialState, action) => {
  const { tag } = state
  switch (action.type) {
    case ActionTypes.OPEN_AUTOTAG:
      let clicked = action.tag ? action.tag.tier_configs.findIndex(tier => tier.name_display === action.tierName) : null
      if (clicked < 0) {
        clicked = null
      }
      let customTier = action.tag ? action.tag.tier_configs.findIndex(tier => tier.custom_tier_id === action.tagName) : null
      if (customTier < 0) {
        customTier = null
      }
      return {
        ...state,
        tag: action.tag,
        saving: false,
        initialTier: clicked,
        editingTier: clicked,
        customTier,
        tierError: null,
        isCustomAutotagEditorVisible: false,
        perksByTagName: action.perksByTagName,
        notesByTagName: action.notesByTagName,
      }
    case ActionTypes.REMOVE_INITIAL_TIER:
      return {
        ...state,
        initialTier: null,
      }
    case ActionTypes.SELECT_TIER:
      if (!tag.is_active) {
        return state
      }
      return {
        ...state,
        editingTier: state.tierError !== null ? state.tierError : action.tier,
      }
    case ActionTypes.SAVE_AUTOTAG_START:
      return { ...state, saving: true }
    case ActionTypes.DELETE_AUTOTAG_START:
      return { ...state, deleting: true }
    case ActionTypes.SAVE_AUTOTAG_SUCCESS:
      return {
        ...state,
        saving: false,
        editingTier: null,
        customTier: null,
        tag: null,
        isCustomAutotagEditorVisible: false,
        confirmDeactivate: false,
      }
    case ActionTypes.DELETE_AUTOTAG_SUCCESS:
      return {
        ...state,
        deleting: false,
        editingTier: null,
        customTier: null,
        tag: null,
        isCustomAutotagEditorVisible: false,
      }
    case ActionTypes.SAVE_AUTOTAG_FAIL:
      return { ...state, saving: false, confirmDeactivate: false }
    case ActionTypes.TOGGLE_AUTOTAG_DEACTIVATE_WARNING:
      return {
        ...state,
        confirmDeactivate: !state.confirmDeactivate,
      }
    case ActionTypes.DELETE_AUTOTAG_FAIL:
      return { ...state, deleting: false }
    case ActionTypes.UPDATE_AUTOTAG_DEACTIVATE_FUNCTION:
      return { ...state, deactivateFunction: action.deactivateFunction }
    case ActionTypes.UPDATE_AUTO_TAG_CAMPAIGNS:
      return {
        ...state,
        associatedCampaignsAndPerks: action.associatedCampaignsAndPerks,
        currentTagID: action.currentTagID,
      }
    case ActionTypes.UPDATE_AUTOTAG_CONFIG:
      return {
        ...state,
        tag: {
          ...tag,
          [action.name]: action.value,
        },
      }
    case ActionTypes.TOGGLE_TAG_ACTIVE:
      // eslint-disable-next-line no-unused-expressions
      tag.is_active ? window.metric.track('AutoTagging.CategoryDeactivated') : window.metric.track('AutoTagging.CategoryActivated')
      return {
        ...state,
        editingTier: null,
        tag: {
          ...tag,
          is_active: !tag.is_active,
        },
      }
    case ActionTypes.TOGGLE_TIER_ACTIVE:
      return {
        ...state,
        tag: {
          ...tag,
          tier_configs: tag.tier_configs.map((_tier, index) => {
            const tier = { ..._tier }
            if (index === action.index) {
              tier.is_active = !tier.is_active
            }
            return tier
          }),
        },
      }
    case ActionTypes.UPDATE_TIER:
      return {
        ...state,
        tag: {
          ...tag,
          tier_configs: tag.tier_configs.map((_tier, index) => {
            const tier = { ..._tier }
            if (index === action.tier) {
              tier[action.name] = action.value
            }
            return tier
          }),
        },
      }
    case ActionTypes.UPDATE_TIER_ATTRIBUTE:
      return {
        ...state,
        tag: {
          ...tag,
          tier_configs: tag.tier_configs.map((_tier, index) => {
            const tier = { ..._tier }
            if (index === action.tier) {
              tier.attributes[action.name] = action.value
            }
            return tier
          }),
        },
      }
    case ActionTypes.UPDATE_LOCKED_TIER_ATTRIBUTES:
      return {
        ...state,
        tag: {
          ...tag,
          tier_configs: tag.tier_configs.map((_tier, index) => {
            const tier = { ..._tier }
            tier.attributes[action.name] = action.value
            return tier
          }),
        },
      }
    case ActionTypes.UPDATE_AUTOTAG_PERKS_BY_TAG:
      return {
        ...state,
        perksByTagName: { ...state.perksByTagName, [action.tierNameDisplay]: action.value },
      }
    case ActionTypes.UPDATE_AUTOTAG_NOTES_BY_TAG:
      return {
        ...state,
        notesByTagName: { ...state.notesByTagName, [action.tierNameDisplay]: [action.value] },
      }
    case ActionTypes.SET_TIER_ERROR:
      return {
        ...state,
        tierError: action.tier,
        editingTier: action.tier ? action.tier : state.editingTier,
      }
    case ActionTypes.TOGGLE_DELETE_WARNING:
      return { ...state, warning: !state.warning }
    case ActionTypes.REMOVE_TIER:
      return {
        ...state,
        tag: {
          ...tag,
          tier_configs: tag.tier_configs.filter((tier, index) => index !== state.editingTier),
        },
        editingTier: null,
        warning: false,
      }
    case ActionTypes.ADD_TIER:
      if (state.tierError !== null) {
        return state
      }
      const configType = ConfigTierMap[tag.config_type][0]
      const newTier = {
        ...defaultTier,
        attributes: getTierAttributes(configType, tag.tier_configs[0]),
        tier_type: configType,
      }
      return {
        ...state,
        editingTier: tag.tier_configs.length,
        tag: { ...tag, tier_configs: tag.tier_configs.concat([newTier]) },
      }
    case ActionTypes.TOGGLE_CUSTOM_AUTOTAG_SLIDEOUT:
      return {
        ...initialState,
        isCustomAutotagEditorVisible: !state.isCustomAutotagEditorVisible,
      }
    default:
      return state
  }
}

export default tagEditReducer
