import * as ActionTypes from 'mgr/pages/single-venue/dayview/actions/ActionTypes'

const initialAddDropDownState = {
  isOpen: false,
  isBookable: false,
  rowEntity: null,
  order: null,
  isUnassigned: false,
  boundingRect: null,
  dropDownType: null,
  scrollY: 0,
}

export const addDropDownReducer = (state = initialAddDropDownState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_DROPDOWN_OPENED:
      return {
        ...state,
        isOpen: true,
        isBookable: action.isBookable,
        rowEntity: action.rowEntity,
        order: action.order,
        isUnassigned: action.isUnassigned,
        boundingRect: action.boundingRect,
        dropDownType: action.dropDownType,
        blockOrAccessRule: action.blockOrAccessRule,
        scrollY: window.scrollY,
      }
    case ActionTypes.ADD_DROPDOWN_CLOSED:
      return {
        ...state,
        isOpen: false,
        isBookable: false,
        rowEntity: null,
        order: null,
        isUnassigned: false,
        boundingRect: null,
        scrollY: window.scrollY,
      }
    default:
      return state
  }
}

const initialStatusDropDownState = {
  isOpen: false,
  boundingRect: null,
  scrollY: 0,
  actual: null,
}

export const statusDropDownReducer = (state = initialStatusDropDownState, action) => {
  switch (action.type) {
    case ActionTypes.STATUS_DROPDOWN_OPENED:
      return {
        ...state,
        isOpen: true,
        boundingRect: action.boundingRect,
        actual: action.actual,
        scrollY: window.scrollY,
      }
    case ActionTypes.STATUS_DROPDOWN_CLOSED:
      return {
        ...state,
        ...initialStatusDropDownState,
        scrollY: window.scrollY,
      }
    default:
      return state
  }
}
