import { createNewClient } from 'mgr/actualslideout/actions/BookClientActions'
import ClientServices from 'mgr/lib/services/SourceServices'
import { splitIntoContactInfo } from 'mgr/lib/utils/String'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
import * as SlideoutActions from './SlideoutActions'

export const changeSearchTerms = searchTerms => ({
  type: ActionTypes.BOOK_SOURCE_CHANGE_SEARCH_TERMS,
  searchTerms,
})

export const selectExistingSource = sourceId => ({
  type: ActionTypes.BOOK_SOURCE_SELECT_EXISTING_SOURCE,
  sourceId,
})

export const changeSelectedSource = (selectedSource, clientHistory) => ({
  type: ActionTypes.BOOK_SOURCE_CHANGE_SELECTED_SOURCE,
  selectedSource,
  clientHistory,
})

export const deselectSourceClient = previouslySelectedClient => ({
  type: ActionTypes.BOOK_CLIENT_DESELECT_SOURCE_CLIENT,
  previouslySelectedClient,
})

export const onDeselectSourceClient = dispatch => {
  dispatch(changeSelectedSource(null))
}

export const changeSourceField = (field, value) => ({
  type: ActionTypes.BOOK_SOURCE_CHANGE_SOURCE_FIELD,
  field,
  value,
})

export const changeSourceCustomUnindexedField = (system_name, value) => ({
  type: ActionTypes.BOOK_SOURCE_CHANGE_CUSTOM_UNINDEXED_FIELD,
  system_name,
  value,
})

export const changeSourceTags = value => ({
  type: ActionTypes.BOOK_SOURCE_CHANGE_SOURCE_TAGS,
  value,
})

export const toggleShowMoreSourceFields = () => ({
  type: ActionTypes.BOOK_SOURCE_TOGGLE_SHOW_MORE_SOURCE_FIELDS,
})

export const onSelectExistingSource = (dispatch, getState, sourceId) => {
  const store = getState()
  const venueId = store.bookState.selectedVenue.id
  dispatch(tryGetSourceHydratedAction(venueId, sourceId))
}

export const clickAddAsNewSource = (venue, searchTerms) => {
  const { firstName, lastName, phoneNumberFormatted, emailAddress } = splitIntoContactInfo(searchTerms)

  const newSource = createNewClient(venue, firstName, lastName, phoneNumberFormatted, emailAddress)
  return changeSelectedSource(newSource)
}

/* workflow to initialize a client search keywords change */
export const onChangeSourceSearchTerms = (dispatch, getState) => {
  const store = getState()
  const venueId = store.bookState.selectedVenue.id
  const { searchTerms } = store.bookSourceState

  const promises = [dispatch(tryGetSourceLookupAction(venueId, searchTerms))]

  return Promise.all(promises)
}

/* SOURCE LOOKUP */

const getSourceLookupBegin = () => ({
  type: ActionTypes.BOOK_SOURCE_GET_SOURCE_LOOKUP_START,
})
const getSourceLookupSuccess = clientResults => ({
  type: ActionTypes.BOOK_SOURCE_GET_SOURCE_LOOKUP_SUCCESS,
  clientResults,
})
const getSourceLookupFail = () => ({
  type: ActionTypes.BOOK_SOURCE_GET_SOURCE_LOOKUP_FAIL,
})

const tryGetSourceLookupAction = (venueId, searchTerms) => (dispatch, getState) => {
  dispatch(getSourceLookupBegin())
  const errHandler = error =>
    dispatch(batchActions([getSourceLookupFail(), SlideoutActions.showNotificationError(`Error looking up client: ${error}`)]))
  const isSource = true
  return ClientServices.fetchClientLookup(venueId, searchTerms, isSource, errHandler).then(clientResults => {
    const latestStore = getState()
    if (searchTerms !== latestStore.bookSourceState.searchTerms) {
      window.svrDebug('Ignoring slow request response for source fetchClientLookup')
      return
    }
    return dispatch(getSourceLookupSuccess(clientResults))
  })
}

/* SOURCE HYDRATE */

const getSourceHydratedBegin = () => ({
  type: ActionTypes.BOOK_SOURCE_GET_SOURCE_HYDRATED_START,
})

const getSourceHydratedSuccess = () => ({
  type: ActionTypes.BOOK_SOURCE_GET_SOURCE_HYDRATED_SUCCESS,
})

const getSourceHydratedFail = () => ({
  type: ActionTypes.BOOK_SOURCE_GET_SOURCE_HYDRATED_FAIL,
})

const tryGetSourceHydratedAction = (venueId, clientId) => dispatch => {
  dispatch(getSourceHydratedBegin())
  const errHandler = error =>
    dispatch(batchActions([getSourceHydratedFail(), SlideoutActions.showNotificationError(`Error looking up source: ${error}`)]))
  return ClientServices.fetchClientHydrated(venueId, clientId, errHandler).then(({ venueId, client, actuals }) =>
    dispatch(batchActions([getSourceHydratedSuccess(), changeSelectedSource(client, actuals)]))
  )
}
