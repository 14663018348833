import React from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { ActualSlideoutLocales } from './ActualSlideout.locales'
import { ActualSlideoutTestId } from './ActualSlideout.testIds'

interface MissingEmailConfirmationModalProps {
  onClose: () => void
  onSubmit: () => void
}

function MissingEmailConfirmationModal({ onClose, onSubmit }: MissingEmailConfirmationModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Window active data-test={ActualSlideoutTestId.missingEmailConfirmationModal}>
      <Modal ariaLabel="Modal">
        <ModalHeader onClose={onClose}>
          <VStack spacing="s">
            <Status kind="warning">{formatMessage(commonMessages.warning)}</Status>
            <ModalTitle title={formatMessage(ActualSlideoutLocales.missingEmailConfirmationTitle)} />
          </VStack>
        </ModalHeader>
        <ModalBody>
          <Text>{formatMessage(ActualSlideoutLocales.missingEmailConfirmationBody)}</Text>
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button variant="tertiary" onClick={onClose} data-test={ActualSlideoutTestId.missingEmailConfirmationCancel}>
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onClose()
                onSubmit()
              }}
              data-test={ActualSlideoutTestId.missingEmailConfirmationSubmit}
            >
              {formatMessage(ActualSlideoutLocales.missingEmailConfirmationSubmit)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}

export default MissingEmailConfirmationModal
