import { srGet } from '@sevenrooms/core/api'

const fetchClientLookup = (venueId, searchTerms, isSource, errHandler) =>
  srGet(
    `/api-yoa/clients`,
    {
      venue: venueId,
      npeq: searchTerms,
      is_source: isSource ? '1' : '0',
      rid: Math.floor(Math.random() * 100 + 1), // TODO: respect this
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response.error)
    }
    return response.data.clients.map(c => {
      // Juggle those API models
      c.tags_display = c.client_tags_display
      c.email_address = c.email
      c.name_display = c.name_first_first_display
      return c
    })
  })

const fetchClientHydrated = (venueId, clientId, errHandler) =>
  srGet(
    `/manager/${venueId}/clients/matched/${clientId}`,
    {},
    {
      headers: { Accept: 'application/json' },
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }
  ).then(response => {
    if (response.statusCode !== 200 && errHandler) {
      errHandler(response.error)
    }
    const client = response.payload.venue_group_client
    const { actuals } = response.payload
    return { venueId, client, actuals }
  })

export default {
  fetchClientLookup,
  fetchClientHydrated,
}
