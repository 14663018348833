import { useMemo } from 'react'
import type { EmailCampaign, EmailCampaignTemplate } from '@sevenrooms/core/api'
import { MarketingCampaignScheduleTypeEnum } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type OngoingSendingScheduleFormData = ZodSchema<typeof useOngoingSendingScheduleForm>
export type OngoingSendingScheduleFormField = Field<OngoingSendingScheduleFormData>

export function useOngoingSendingScheduleForm() {
  return useMemo(
    () =>
      z.object({
        scheduleType: z.nativeEnum(MarketingCampaignScheduleTypeEnum),
        scheduledDay: z.string(),
        scheduledTime: z.string(),
        relativeValue: z.string(),
        absoluteDays: z.string(),
        absoluteTime: z.string(),
      }),
    []
  )
}

export const getOngoingSendingScheduleDefaultValues = (source?: EmailCampaign | EmailCampaignTemplate): OngoingSendingScheduleFormData => ({
  relativeValue: source?.ongoingSendTimeConfig?.relativeValue?.toString() ?? '1',
  absoluteDays: source?.ongoingSendTimeConfig?.delayNum?.toString() ?? '1',
  absoluteTime: source?.ongoingSendTimeConfig?.timeOfDay ?? '00:00:00',
  scheduledDay: source?.ongoingSendTimeConfig?.scheduledDayOfWeek?.toString() ?? '0',
  scheduledTime: source?.ongoingSendTimeConfig?.scheduledTimeOfDay ?? '00:00:00',
  scheduleType: (source?.ongoingSendTimeConfig?.scheduleType ??
    MarketingCampaignScheduleTypeEnum.RELATIVE) as MarketingCampaignScheduleTypeEnum,
})
