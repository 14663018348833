import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { BufferMenuLocales } from './BufferMenu.locales'

interface BufferMenuProps {
  showAdd: boolean
  onClick: () => void
}

export function BufferMenu({ showAdd, onClick }: BufferMenuProps) {
  const { formatMessage } = useLocales()

  return (
    <Menu>
      {showAdd ? (
        <MenuItem onClick={onClick}>
          <Icon name="VMSWeb-add" />
          {formatMessage(BufferMenuLocales.addBuffer)}
        </MenuItem>
      ) : (
        <MenuItem onClick={onClick}>
          <Icon name="VMSWeb-minus" />
          {formatMessage(BufferMenuLocales.removeBuffer)}
        </MenuItem>
      )}
    </Menu>
  )
}
