import _ from 'lodash'
import moment from 'moment-timezone'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'

const initialState = {
  isInitialized: false,
  isLoading: true,
  activeChannelKey: null,
  editChannelSettingsBackup: null,
  channelSettings: {},
  channelFormOptions: {},
  formErrors: {},
  didDismissErrorDisplay: false,
  photoIdx: -1,
  cropAspect: null,
}

const bookingChannelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_BOOKING_CHANNEL_SETTINGS_START:
      return {
        ...state,
        isLoading: true,
        isInitialized: true,
      }
    case ActionTypes.BOOKING_CHANNEL_IMAGE_UPLOAD_START:
    case ActionTypes.DEACTIVATE_BOOKING_CHANNEL_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.SAVE_BOOKING_CHANNEL_SETTINGS_FAIL:
    case ActionTypes.BOOKING_CHANNEL_IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.LOAD_BOOKING_CHANNEL_SETTINGS_SUCCESS:
      return {
        ...state,
        channelSettings: {
          ...state.channelSettings,
          ...action.channelSettings,
        },
        channelFormOptions: {
          ...state.channelFormOptions,
          ...action.channelFormOptions,
        },
        isLoading: false,
      }
    case ActionTypes.LOAD_BOOKING_CHANNEL_SETTINGS_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.SELECT_BOOKING_CHANNEL_EDIT: {
      const { channelKey } = action
      return {
        ...state,
        activeChannelKey: channelKey,
        editChannelSettingsBackup: { ...state.channelSettings[channelKey] },
      }
    }
    case ActionTypes.CANCEL_BOOKING_CHANNEL_EDIT:
      return {
        ...state,
        activeChannelKey: null,
        channelSettings: {
          ...state.channelSettings,
          [state.activeChannelKey]: state.editChannelSettingsBackup,
        },
      }
    case ActionTypes.DEACTIVATE_BOOKING_CHANNEL_SUCCESS: {
      return {
        ...state,
        channelSettings: {
          ...state.channelSettings,
          [action.channelKey]: {
            ...state.channelSettings[action.channelKey],
            is_active: false,
          },
        },
        isLoading: false,
      }
    }
    case ActionTypes.DEACTIVATE_BOOKING_CHANNEL_FAIL:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.CHANGE_BOOKING_CHANNEL_FIELD: {
      const { channelKey, field, value } = action
      const formErrors = { ...state.formErrors, [action.field]: false }
      return {
        ...state,
        channelSettings: {
          ...state.channelSettings,
          [channelKey]: {
            ...state.channelSettings[channelKey],
            [field]: value,
          },
        },
        formErrors,
        didDismissErrorDisplay: true,
      }
    }
    case ActionTypes.BOOKING_CHANNEL_FORM_VALIDATED: {
      const { formErrors } = action
      return {
        ...state,
        formErrors,
        didDismissErrorDisplay: false,
      }
    }
    case ActionTypes.BOOKING_CHANNEL_DISMISS_ERROR_DISPLAY: {
      return { ...state, didDismissErrorDisplay: true }
    }
    case ActionTypes.SAVE_BOOKING_CHANNEL_SETTINGS_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.SAVE_BOOKING_CHANNEL_SETTINGS_SUCCESS:
      return {
        ...state,
        channelSettings: {
          ...state.channelSettings,
          [state.activeChannelKey]: {
            ...state.channelSettings[state.activeChannelKey],
            is_active: action.is_active,
            updated: moment().toISOString().slice(0, -1),
          },
        },
        isLoading: false,
        activeChannelKey: null,
      }
    case ActionTypes.BOOKING_CHANNEL_IMAGE_UPLOAD_SUCCESS: {
      const photosListUpdate = [
        ...state.channelSettings[state.activeChannelKey].images,
        {
          crop_info: null,
          raw_url_key: action.uploadData.url_key,
          aspect: action.aspect,
        },
      ]

      return {
        ...state,
        cropAspect: action.aspect,
        channelSettings: {
          ...state.channelSettings,
          [state.activeChannelKey]: {
            ...state.channelSettings[state.activeChannelKey],
            images: photosListUpdate,
          },
        },
        isLoading: false,
      }
    }
    case ActionTypes.BOOKING_CHANNEL_IMAGE_CROPPING:
      return {
        ...state,
        photoIdx: action.photoIdx,
      }
    case ActionTypes.BOOKING_CHANNEL_IMAGE_CROP_PHOTO:
      const photosCropUpdate = [...state.channelSettings[state.activeChannelKey].images]
      photosCropUpdate[action.photoIdx] = {
        ...photosCropUpdate[action.photoIdx],
        crop_info: action.coords,
      }

      return {
        ...state,
        channelSettings: {
          ...state.channelSettings,
          [state.activeChannelKey]: {
            ...state.channelSettings[state.activeChannelKey],
            images: photosCropUpdate,
          },
        },
      }
    case ActionTypes.BOOKING_CHANNEL_IMAGE_EDIT_PHOTO:
      return {
        ...state,
        photoIdx: action.photoIdx,
        cropAspect: action.aspect,
      }
    case ActionTypes.BOOKING_CHANNEL_IMAGE_DELETE_PHOTO:
      const deletePhotoUpdate = [...state.channelSettings[state.activeChannelKey].images]
      _.pullAt(deletePhotoUpdate, action.photoIdx)

      return {
        ...state,
        channelSettings: {
          ...state.channelSettings,
          [state.activeChannelKey]: {
            ...state.channelSettings[state.activeChannelKey],
            images: deletePhotoUpdate,
          },
        },
      }
    default:
      return state
  }
}

export default bookingChannelsReducer
