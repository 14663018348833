// Pods
export const LOAD_VENUE_POD_CONFIGS_START = 'LOAD_VENUE_POD_CONFIGS_START'
export const LOAD_VENUE_POD_CONFIGS_SUCCESS = 'LOAD_VENUE_POD_CONFIGS_SUCCESS'
export const LOAD_VENUE_POD_CONFIGS_FAIL = 'LOAD_VENUE_POD_CONFIGS_FAIL'
export const OPEN_POD_SLIDEOUT = 'OPEN_POD_SLIDEOUT'
export const OPEN_TAG_SLIDEOUT = 'OPEN_TAG_SLIDEOUT'
export const OPEN_EDIT_TAG_SLIDEOUT = 'OPEN_EDIT_TAG_SLIDEOUT'
export const CHANGE_POD_CATEGORY_NAME = 'CHANGE_POD_CATEGORY_NAME'
export const CHANGE_POD_HEX_COLOR = 'CHANGE_POD_HEX_COLOR'
export const APPEND_NEW_POD = 'APPEND_NEW_POD'
export const CHANGE_POD_NAME = 'CHANGE_POD_NAME'
export const DELETE_POD = 'DELETE_POD'
export const ADD_POD_CATEGORY_START = 'ADD_POD_CATEGORY_START'
export const ADD_POD_CATEGORY_SUCCESS = 'ADD_POD_CATEGORY_SUCCESS'
export const ADD_POD_CATEGORY_FAIL = 'ADD_POD_CATEGORY_FAIL'
export const EDIT_POD_CATEGORY_START = 'EDIT_POD_CATEGORY_START'
export const EDIT_POD_CATEGORY_SUCCESS = 'EDIT_POD_CATEGORY_SUCCESS'
export const EDIT_POD_CATEGORY_FAIL = 'EDIT_POD_CATEGORY_FAIL'
export const DELETE_POD_CATEGORY_START = 'DELETE_POD_CATEGORY_START'
export const DELETE_POD_CATEGORY_SUCCESS = 'DELETE_POD_CATEGORY_SUCCESS'
export const DELETE_POD_CATEGORY_FAIL = 'DELETE_POD_CATEGORY_FAIL'
export const REMOVE_POD_FROM_VENUE_START = 'REMOVE_POD_FROM_VENUE_START'
export const REMOVE_POD_FROM_VENUE_SUCCESS = 'REMOVE_POD_FROM_VENUE_SUCCESS'
export const REMOVE_POD_FROM_VENUE_FAIL = 'REMOVE_POD_FROM_VENUE_FAIL'
export const REMOVE_POD_FROM_VENUE_OPTIMISTIC_UPDATE = 'REMOVE_POD_FROM_VENUE_OPTIMISTIC_UPDATE'
export const REMOVE_POD_FROM_VENUE_OPTIMISTIC_ROLLBACK = 'REMOVE_POD_FROM_VENUE_OPTIMISTIC_ROLLBACK'
export const ADD_POD_TO_VENUE_START = 'ADD_POD_TO_VENUE_START'
export const ADD_POD_TO_VENUE_SUCCESS = 'ADD_POD_TO_VENUE_SUCCESS'
export const ADD_POD_TO_VENUE_FAIL = 'ADD_POD_TO_VENUE_FAIL'
export const ADD_POD_TO_VENUE_OPTIMISTIC_UPDATE = 'ADD_POD_TO_VENUE_OPTIMISTIC_UPDATE'
export const ADD_POD_TO_VENUE_OPTIMISTIC_ROLLBACK = 'ADD_POD_TO_VENUE_OPTIMISTIC_ROLLBACK'
export const ADD_PENDING_OPTIMISTIC_OPERATION = 'ADD_PENDING_OPTIMISTIC_OPERATION'
export const REMOVE_PENDING_OPTIMISTIC_OPERATION = 'REMOVE_PENDING_OPTIMISTIC_OPERATION'
export const TOGGLE_DELETE_WARNING = 'TOGGLE_DELETE_WARNING'
export const TOGGLE_REPLACE_SEARCH_GROUP_WARNING = 'TOGGLE_REPLACE_SEARCH_GROUP_WARNING'
export const TOGGLE_DISABLE_WARNING = 'TOGGLE_DISABLE_WARNING'
export const FOCUS_POD_INPUT = 'FOCUS_POD_INPUT'
export const BLUR_POD_INPUT = 'BLUR_POD_INPUT'
export const UPDATE_POD_SLIDEOUT_ERRORS = 'UPDATE_POD_SLIDEOUT_ERRORS'
export const TOGGLE_IS_SEARCH_GROUP = 'TOGGLE_IS_SEARCH_GROUP'

// Pod Tags
export const FETCH_CLIENT_TAG_CATEGORIES_START = 'FETCH_CLIENT_TAG_CATEGORIES_START'
export const FETCH_CLIENT_TAG_CATEGORIES_SUCCESS = 'FETCH_CLIENT_TAG_CATEGORIES_SUCCESS'
export const FETCH_CLIENT_TAG_CATEGORIES_FAIL = 'FETCH_CLIENT_TAG_CATEGORIES_FAIL'
export const FETCH_RESERVATION_TAG_CATEGORIES_START = 'FETCH_RESERVATION_TAGS_START'
export const FETCH_RESERVATION_TAG_CATEGORIES_SUCCESS = 'FETCH_RESERVATION_TAGS_SUCCESS'
export const FETCH_RESERVATION_TAG_CATEGORIES_FAIL = 'FETCH_RESERVATION_TAGS_FAIL'
export const ENABLE_TAG_CATEGORY_START = 'ENABLE_TAG_CATEGORY_START'
export const ENABLE_TAG_CATEGORY_SUCCESS = 'ENABLE_TAG_CATEGORY_SUCCESS'
export const ENABLE_TAG_CATEGORY_FAIL = 'ENABLE_TAG_CATEGORY_FAIL'
export const DISABLE_TAG_CATEGORY_START = 'DISABLE_TAG_CATEGORY_START'
export const DISABLE_TAG_CATEGORY_SUCCESS = 'DISABLE_TAG_CATEGORY_SUCCESS'
export const DISABLE_TAG_CATEGORY_FAIL = 'DISABLE_TAG_CATEGORY_FAIL'
export const DELETE_TAG_CATEGORY_START = 'DELETE_TAG_CATEGORY_START'
export const DELETE_TAG_CATEGORY_SUCCESS = 'DELETE_TAG_CATEGORY_SUCCESS'
export const DELETE_TAG_CATEGORY_FAIL = 'DELETE_TAG_CATEGORY_FAIL'
export const FETCH_FOLLOWERS_START = 'FETCH_FOLLOWERS_START'
export const FETCH_FOLLOWERS_SUCCESS = 'FETCH_FOLLOWERS_SUCCESS'
export const FETCH_FOLLOWERS_FAIL = 'FETCH_FOLLOWERS_FAIL'
export const UPDATE_TAG_CATEGORY_PODS = 'UPDATE_TAG_CATEGORY_PODS'
export const UPDATE_TAG_CATEGORY_FOLLOWERS = 'UPDATE_TAG_CATEGORY_FOLLOWERS'
export const CHANGE_TAG_CATEGORY_NAME = 'CHANGE_TAG_CATEGORY_NAME'
export const TOGGLE_CHECKBOX = 'TOGGLE_CHECKBOX'
export const SET_RADIO_BTN_VAL = 'SET_RADIO_BTN_VAL'
export const APPEND_NEW_TAG = 'APPEND_NEW_TAG'
export const CHANGE_TAG_NAME = 'CHANGE_TAG_NAME'
export const DELETE_TAG = 'DELETE_TAG'
export const UPDATE_TAG_SLIDEOUT_ERRORS = 'UPDATE_TAG_SLIDEOUT_ERRORS'
export const ADD_TAG_CATEGORY_START = 'ADD_TAG_CATEGORY_START'
export const ADD_TAG_CATEGORY_SUCCESS = 'ADD_TAG_CATEGORY_SUCCESS'
export const ADD_TAG_CATEGORY_FAIL = 'ADD_TAG_CATEGORY_FAIL'
export const EDIT_TAG_CATEGORY_START = 'EDIT_TAG_CATEGORY_START'
export const EDIT_TAG_CATEGORY_SUCCESS = 'EDIT_TAG_CATEGORY_SUCCESS'
export const EDIT_TAG_CATEGORY_FAIL = 'EDIT_TAG_CATEGORY_FAIL'
export const LOAD_VMS_ROLE_TEMPLATES_START = 'LOAD_VMS_ROLE_TEMPLATES_START'
export const LOAD_VMS_ROLE_TEMPLATES_SUCCESS = 'LOAD_VMS_ROLE_TEMPLATES_SUCCESS'
export const LOAD_VMS_ROLE_TEMPLATES_FAIL = 'LOAD_VMS_ROLE_TEMPLATES_FAIL'
export const LOAD_VMS_ROLE_TEMPLATE_START = 'LOAD_VMS_ROLE_TEMPLATE_START'
export const LOAD_VMS_ROLE_TEMPLATE_SUCCESS = 'LOAD_VMS_ROLE_TEMPLATE_SUCCESS'
export const LOAD_VMS_ROLE_TEMPLATE_FAIL = 'LOAD_VMS_ROLE_TEMPLATE_FAIL'
export const PRESET_ROLE_TEMPLATE_CHANGE = 'PRESET_ROLE_TEMPLATE_CHANGE'
export const VMS_ROLE_TEMPLATE_NAME_CHANGE = 'VMS_ROLE_TEMPLATE_NAME_CHANGE'
export const TOGGLE_VMS_ROLE_TEMPLATE_PERMISSION = 'TOGGLE_VMS_ROLE_TEMPLATE_PERMISSION'
export const SET_GROUP_VMS_ROLE_TEMPLATE_PERMISSIONS = 'SET_GROUP_VMS_ROLE_TEMPLATE_PERMISSIONS'
export const SET_ALLOWED_PERMISSIONS = 'SET_ALLOWED_PERMISSIONS'
export const UPDATE_EDITABLE_PERMISSIONS = 'UPDATE_EDITABLE_PERMISSIONS'
export const TRY_CREATE_VMS_ROLE_TEMPLATE_START = 'TRY_CREATE_VMS_ROLE_TEMPLATE_START'
export const TRY_CREATE_VMS_ROLE_TEMPLATE_SUCCESS = 'TRY_CREATE_VMS_ROLE_TEMPLATE_SUCCESS'
export const TRY_CREATE_VMS_ROLE_TEMPLATE_FAIL = 'TRY_CREATE_VMS_ROLE_TEMPLATE_FAIL'
export const TRY_SAVE_VMS_ROLE_TEMPLATE_START = 'TRY_SAVE_VMS_ROLE_TEMPLATE_START'
export const TRY_SAVE_VMS_ROLE_TEMPLATE_SUCCESS = 'TRY_SAVE_VMS_ROLE_TEMPLATE_SUCCESS'
export const TRY_SAVE_VMS_ROLE_TEMPLATE_FAIL = 'TRY_SAVE_VMS_ROLE_TEMPLATE_FAIL'
export const RESET_VMS_ROLE_TEMPLATE_STATE = 'RESET_VMS_ROLE_TEMPLATE_STATE'
export const INVALIDATE_ROLE = 'INVALIDATE_ROLE'
export const DELETE_VMS_ROLE_TEMPLATE_START = 'DELETE_VMS_ROLE_TEMPLATE_START'
export const DELETE_VMS_ROLE_TEMPLATE_SUCCESS = 'DELETE_VMS_ROLE_TEMPLATE_SUCCESS'
export const DELETE_VMS_ROLE_TEMPLATE_FAIL = 'DELETE_VMS_ROLE_TEMPLATE_FAIL'
export const DUPLICATE_VMS_ROLE_TEMPLATE_START = 'DUPLICATE_VMS_ROLE_TEMPLATE_START'
export const DUPLICATE_VMS_ROLE_TEMPLATE_SUCCESS = 'DUPLICATE_VMS_ROLE_TEMPLATE_SUCCESS'
export const DUPLICATE_VMS_ROLE_TEMPLATE_FAIL = 'DUPLICATE_VMS_ROLE_TEMPLATE_FAIL'
export const USER_ACCOUNTS_TEXT_FIELD_CHANGE = 'USER_ACCOUNTS_TEXT_FIELD_CHANGE'
export const USER_ACCOUNTS_OPTIONS_CHANGE = 'USER_ACCOUNTS_OPTIONS_CHANGE'
export const USER_ACCOUNTS_SET_GROUP_PERMISSION = 'USER_ACCOUNTS_SET_GROUP_PERMISSION'
export const USER_ACCOUNTS_PRIMARY_ACCESS_CHANGE = 'USER_ACCOUNTS_PRIMARY_ACCESS_CHANGE'
export const USER_ACCOUNTS_ADTL_ACCESS_CHANGE = 'USER_ACCOUNTS_ADTL_ACCESS_CHANGE'
export const USER_ACCOUNTS_ROLE_ENTITIES_CHANGE = 'USER_ACCOUNTS_ROLE_ENTITIES_CHANGE'
export const USER_ACCOUNTS_ADTL_ACCESS_ROLE_ENTITIES_CHANGE = 'USER_ACCOUNTS_ADTL_ACCESS_ROLE_ENTITIES_CHANGE'
export const USER_ACCOUNTS_ADD_ADDITIONAL_ACCESS = 'USER_ACCOUNTS_ADD_ADDITIONAL_ACCESS'
export const USER_ACCOUNTS_DELETE_PRIMARY_ACCESS = 'USER_ACCOUNTS_DELETE_PRIMARY_ACCESS'
export const USER_ACCOUNTS_DELETE_ACCESS = 'USER_ACCOUNTS_DELETE_ACCESS'
export const INVALIDATE_USER_FIELDS = 'INVALIDATE_USER_FIELDS'
export const RESET_USER_STATE = 'RESET_USER_STATE'
export const LOAD_VENUE_PODS_START = 'LOAD_VENUE_PODS_START'
export const LOAD_VENUE_PODS_SUCCESS = 'LOAD_VENUE_PODS_SUCCESS'
export const LOAD_VENUE_PODS_FAIL = 'LOAD_VENUE_PODS_FAIL'
export const TRY_CREATE_USER_START = 'TRY_CREATE_USER_START'
export const TRY_CREATE_USER_SUCCESS = 'TRY_CREATE_USER_SUCCESS'
export const TRY_CREATE_USER_FAIL = 'TRY_CREATE_USER_FAIL'
export const TRY_LOAD_USER_START = 'TRY_LOAD_USER_START'
export const TRY_LOAD_USER_SUCCESS = 'TRY_LOAD_USER_SUCCESS'
export const TRY_LOAD_USER_FAIL = 'TRY_LOAD_USER_FAIL'
export const TRY_FETCH_USERS_START = 'TRY_FETCH_USERS_START'
export const TRY_FETCH_USERS_SUCCESS = 'TRY_FETCH_USERS_SUCCESS'
export const TRY_FETCH_USERS_FAIL = 'TRY_FETCH_USERS_FAIL'
export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'
export const LOCK_USER_START = 'LOCK_USER_START'
export const LOCK_USER_SUCCESS = 'LOCK_USER_SUCCESS'
export const LOCK_USER_FAIL = 'LOCK_USER_FAIL'
export const USER_SEARCH_QUERY_CHANGE = 'USER_SEARCH_QUERY_CHANGE'
export const ROLE_FILTER_CHANGE = 'ROLE_FILTER_CHANGE'
export const VENUE_PODS_GROUP_RADIO_CHANGE = 'VENUE_PODS_GROUP_RADIO_CHANGE'
export const VENUE_PODS_GROUP_FILTER_CHANGE = 'VENUE_PODS_GROUP_FILTER_CHANGE'
export const GROUP_PORTAL_ACCESS_FILTER_CHANGE = 'GROUP_PORTAL_ACCESS_FILTER_CHANGE'
export const DISPLAY_ADTL_ACCESS_MODAL = 'DISPLAY_ADTL_ACCESS_MODAL'
export const CLOSE_ADTL_ACCESS_MODAL = 'CLOSE_ADTL_ACCESS_MODAL'
export const DISPLAY_ACCESS_DEFINITION_MODAL = 'DISPLAY_ACCESS_DEFINITION_MODAL'
export const CLOSE_ACCESS_DEFINITION_MODAL = 'CLOSE_ACCESS_DEFINITION_MODAL'
