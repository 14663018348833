import { useMemo } from 'react'
import type { EmailCampaign, EmailCampaignTemplate } from '@sevenrooms/core/api'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type EmailDetailsFormData = ZodSchema<typeof useEmailDetailsForm>
export type EmailDetailsFormField = Field<EmailDetailsFormData>

export function useEmailDetailsForm() {
  return useMemo(
    () =>
      z.object({
        campaignName: z.string().trim().min(1),
        replyToAddress: z.string().or(z.literal('')),
      }),
    []
  )
}

export const getEmailDetailsDefaultValues = (source?: EmailCampaign | EmailCampaignTemplate): EmailDetailsFormData => ({
  campaignName: source?.campaignName ?? '',
  replyToAddress: (source as EmailCampaign)?.emailReplyTo ?? '',
})
