import { useMemo } from 'react'
import type {
  EmailCampaign,
  EmailCampaignTemplate,
  OneTimeSMSCampaignTemplate,
  OneTimeSMSCampaignType,
  OngoingEmailCampaignTemplate,
} from '@sevenrooms/core/api'
import type { GenericTagGroup } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { getAdminAutoTagOptionsFromIds, getAutoTagOptionsFromIds } from '@sevenrooms/marketing'

export type AudienceFormData = ZodSchema<typeof useAudienceForm>
export type AudienceFormField = Field<AudienceFormData>

const tagOptionSchema = z.object({ id: z.string(), label: z.string(), categoryId: z.string(), disabled: z.boolean().optional() })

export function useAudienceForm() {
  return useMemo(
    () =>
      z.object({
        useRecipientAutotags: z.boolean(),
        recipientAutotags: z.array(tagOptionSchema),
        excludedAutotags: z.array(tagOptionSchema),
        excludeBlacklist: z.boolean().optional(),
        expressConsentOverride: z.boolean().optional(),
      }),
    []
  )
}

export const getAudienceDefaultValues = (
  source?: EmailCampaign | EmailCampaignTemplate | OneTimeSMSCampaignType | OneTimeSMSCampaignTemplate,
  clientTagGroups?: GenericTagGroup[],
  excludeBlacklist?: boolean
): AudienceFormData => {
  const excludedAutotags = getAutoTagOptionsFromIds(source?.recipientClientTagsExclude, clientTagGroups)
  const recipientAutotags = getAutoTagOptionsFromIds(source?.recipientClientTags, clientTagGroups)

  if (clientTagGroups && excludeBlacklist) {
    const blacklistClientTagGroup = clientTagGroups.find(tagGroup => tagGroup.name === 'Blacklist')
    const clientTypeClientTagGroup = clientTagGroups.find(tagGroup => tagGroup.name === 'Client Type')
    if (blacklistClientTagGroup) {
      excludedAutotags.push({
        id: [blacklistClientTagGroup.privacy, blacklistClientTagGroup.id, 'Blacklist', 'Blacklist'].join('##'),
        label: 'Blacklist',
        categoryId: blacklistClientTagGroup.id,
      })
    }
    if (clientTypeClientTagGroup) {
      excludedAutotags.push({
        id: [clientTypeClientTagGroup.privacy, clientTypeClientTagGroup.id, 'Client Type', 'Blacklist'].join('##'),
        label: 'Blacklist',
        categoryId: clientTypeClientTagGroup.id,
      })
    }
  }

  return {
    useRecipientAutotags: !!recipientAutotags.length,
    excludedAutotags,
    recipientAutotags,
  }
}

export const getAdminAudienceDefaultValues = (source?: OngoingEmailCampaignTemplate): AudienceFormData => ({
  useRecipientAutotags: false,
  recipientAutotags: getAdminAutoTagOptionsFromIds(source?.recipientClientTags),
  excludedAutotags: getAdminAutoTagOptionsFromIds(source?.recipientClientTagsExclude),
  excludeBlacklist: source?.excludeBlacklist ?? false, // BLACKLIST_HACK
})
