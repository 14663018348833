import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as BookSourceActions from 'mgr/actualslideout/actions/BookSourceActions'
import ClientLookup from 'mgr/actualslideout/components/client/ClientLookup'
import { LoadingSpinner } from 'mgr/layout/StyledComponentUtils'

const SourceLabels = {
  addAsNew: 'Add as New Source',
  searchPlaceholder: 'Enter source name, phone, or email to search or add as a new source',
  errorMessage: 'Darn. Something went wrong.',
  noResults: 'No results',
}

const mapStateToProps = state => {
  const venue = state.bookState.selectedVenue
  return {
    venue,
    isLookupLoading: state.bookSourceState.isSourceLookupLoading,
    isLookupLoadError: state.bookSourceState.isSourceLookupLoadError,
    isHydrateLoading: state.bookSourceState.isSourceHydrateLoading,
    isHydrateLoadError: state.bookSourceState.isSourceHydrateLoadError,
    searchTerms: state.bookSourceState.searchTerms,
    clientResults: state.bookSourceState.sourceLookupResults,
    labels: SourceLabels,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changeClientSearchTerms: BookSourceActions.changeSearchTerms,
      selectExistingClient: BookSourceActions.selectExistingSource,
      changeSelectedClient: BookSourceActions.changeSelectedSource,
      changeClientField: BookSourceActions.changeSourceField,
      toggleShowMoreClientFields: BookSourceActions.toggleShowMoreSourceFields,
      clickAddAsNewClient: BookSourceActions.clickAddAsNewSource,
      changeClientTags: BookSourceActions.changeSourceTags,
    },
    dispatch
  ),
})
export default connect(mapStateToProps, mapDispatchToProps)(ClientLookup)
