import { defineMessages } from '@sevenrooms/core/locales'

export const useGetItemFromTimeslotMessages = defineMessages({
  timeslotPerPersonLabel: {
    id: 'timeslotPerPersonLabel',
    defaultMessage: 'person',
  },
  timeslotPerReservationLabel: {
    id: 'timeslotPerReservationLabel',
    defaultMessage: 'reservation',
  },
} as const)
