import _ from 'lodash'
import money from 'money-math'
import React from 'react'
import styled from 'styled-components'
import { ModalContainer, ModalShadow, ModalHeader, ModalClose, PulsatingDots, ActionButton } from 'mgr/layout/StyledComponentUtils'
import SegmentedControl from 'mgr/lib/components/SegmentedControl'
import Checkbox from 'mgr/lib/forms/Checkbox'
import TextInput, { InputRestrictions, ValidatorTypes } from 'mgr/lib/forms/TextInput'

const HorizSeparator = styled.div`
  border-bottom: 1px dashed ${props => props.theme.lightGrey};
`

const refundOptions = [
  { name: 'Full', value: 'full', testId: 'full-refund' },
  { name: 'Partial', value: 'partial', testId: 'partial-refund' },
]

const FormWrap = styled.div`
  padding: 20px;
`

const RefundForm = ({
  transaction,
  selectedClient,
  currencySymbol,
  refundData,
  actions,
  onSubmitRefundHandler,
  formErrors,
  validateFieldMap,
}) => {
  const {
    refundType,
    refundAmount,
    refundDescription,
    refundSendNotification,
    notificationEmail,
    isModalSubmitting,
    refundGiftCardAmount,
  } = refundData
  const amountValue = refundType === 'full' ? money.floatToAmount(transaction.amount_remaining_decimal) : refundAmount
  const isGiftCard = !_.isEmpty(transaction.redemption_raw_json_data)
  const valuTecLabel =
    'I understand that I need to refund this transaction in the ValuTec Terminal and that this refund only impacts SevenRooms reporting.'
  const invalidGiftCardToggle = isGiftCard ? !refundGiftCardAmount : false
  return (
    <div>
      <ModalContainer>
        <ModalHeader>
          Refund <ModalClose onClick={() => actions.setRefundId(null)} />
        </ModalHeader>
        <FormWrap>
          <SegmentedControl
            label="TYPE OF REFUND"
            options={refundOptions}
            value={refundType}
            width={262}
            height={37}
            onChangeHandler={actions.changeRefundType}
          />
          <br />
          <HorizSeparator />
          <br />
          <TextInput
            label="AMOUNT"
            placeholder={currencySymbol}
            inputRestriction={InputRestrictions.number}
            value={String(amountValue)}
            onChange={actions.changeRefundAmount}
            disabled={refundType === 'full'}
            prefixSymbol={currencySymbol}
            validator={ValidatorTypes.notEmpty}
            // eslint-disable-next-line no-param-reassign
            ref={e => (validateFieldMap.amount = e)}
            isValid={!formErrors.amount}
          />
          <br />
          <TextInput
            label="DESCRIPTION (INTERNAL USE)"
            placeholder=""
            inputRestriction={InputRestrictions.none}
            value={refundDescription}
            onChange={actions.changeRefundDescription}
          />
          {!isGiftCard ? (
            <div>
              <br />
              <Checkbox
                name="send_notification"
                label="Send Refund Notification"
                value={refundSendNotification}
                on={refundSendNotification}
                enabled
                onChange={actions.changeRefundNotification}
              />
            </div>
          ) : null}
          {refundSendNotification && !isGiftCard ? (
            <div>
              <br />
              <TextInput
                disabled={!selectedClient.is_email_address_editable}
                label="EMAIL"
                placeholder=""
                inputRestriction={InputRestrictions.email}
                value={notificationEmail}
                onChange={actions.changeNotificationEmail}
                validator={ValidatorTypes.emailRequired}
                // eslint-disable-next-line no-param-reassign
                ref={e => (validateFieldMap.email = e)}
                isValid={!formErrors.email}
              />
            </div>
          ) : null}
          {isGiftCard ? (
            <div>
              <br />
              <Checkbox
                name="send_notification"
                label={valuTecLabel}
                value={refundGiftCardAmount}
                on={refundGiftCardAmount}
                enabled
                onChange={actions.changeRefundGiftCard}
              />
            </div>
          ) : null}
          <br />
          <ActionButton
            data-test="sr-button-refund-submit"
            onClick={onSubmitRefundHandler}
            disabled={isModalSubmitting || invalidGiftCardToggle}
          >
            {isModalSubmitting ? <PulsatingDots /> : 'Submit'}
          </ActionButton>
        </FormWrap>
      </ModalContainer>
      <ModalShadow onClick={() => actions.setRefundId(null)} />
    </div>
  )
}

RefundForm.propTypes = {
  actions: React.PropTypes.object.isRequired,
}

RefundForm.defaultProps = {
  actions: {},
}

export default RefundForm
