import { createSlice } from '@reduxjs/toolkit'

export interface DeactivateSMSCampaignModalState {
  smsCampaignTableRowIndex: number
}

const deactivateSMSCampaignModalSlice = createSlice({
  name: 'deactivateSMSCampaignModal',
  initialState: null,
  reducers: {
    setDeactivateSMSCampaignModalState: (_, param) => ({ ...param.payload }),
    clearDeactivateSMSCampaignModalState: () => null,
  },
})

const { actions } = deactivateSMSCampaignModalSlice
export const { setDeactivateSMSCampaignModalState, clearDeactivateSMSCampaignModalState } = actions
export const { reducer: deactivateSMSCampaignModalReducer } = deactivateSMSCampaignModalSlice
