import type { CampaignActivityItem, CampaignActivityData } from '@sevenrooms/core/api'
import { EmailCampaignStatusEnum, MarketingCampaignTypeEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { DividerLine, Flex, Box, Collapsible, HStack, Loader } from '@sevenrooms/core/ui-kit/layout'
import {
  CampaignPerformanceChart,
  CampaignPerformanceStatistics,
  CampaignPerformanceFilters,
  OneTimeCampaignPerformanceFilters,
  type GraphableActivity,
  type CampaignPerformanceProps,
  useEmailCampaignPerformanceContext,
} from '@sevenrooms/marketing'
import { campaignCenterMessages } from '@sevenrooms/marketing/locales/campaignCenterMessages'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { addUpActivities, getPercentageOfTotal, getReservationSearchURL } from '@sevenrooms/mgr-marketing/utils'

const EMAIL_STATS_WITH_ORDERS: GraphableActivity[] = ['reservations', 'orders', 'clicks', 'opens']
const EMAIL_STATS_WITHOUT_ORDERS: GraphableActivity[] = ['reservations', 'clicks', 'opens']
const ONE_TIME_STATUSES = [EmailCampaignStatusEnum.SENT, EmailCampaignStatusEnum.ACTIVE, EmailCampaignStatusEnum.DRAFT]
const ONGOING_STATUSES = [EmailCampaignStatusEnum.ACTIVE, EmailCampaignStatusEnum.INACTIVE, EmailCampaignStatusEnum.DRAFT]
export function EmailCampaignPerformance({ collapsed, onCollapsedChange, isOneTimeCampaignList, messages }: CampaignPerformanceProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const {
    isCampaignQueryFetching,
    campaignActivityData,
    isActivityQueryFetching,
    activityQueryError,
    startDate,
    endDate,
    dateFilter,
    isPOSActivated,
    averageSpendPerCover,
    searchValue,
  } = useEmailCampaignPerformanceContext()

  const cards = venue
    ? getActivityDifferences(
        formatMessage,
        nav,
        getReservationSearchURL(
          venue.urlKey,
          startDate,
          endDate,
          dateFilter,
          searchValue,
          isOneTimeCampaignList ? MarketingCampaignTypeEnum.ONE_TIME : MarketingCampaignTypeEnum.ONGOING,
          'email'
        ),
        venue.currencySymbol,
        venue.locale.replace('_', '-'),
        campaignActivityData,
        isOneTimeCampaignList,
        isPOSActivated,
        averageSpendPerCover
      )
    : []

  return (
    <>
      <Box pl="m" pr="m" pt="m" mb="m">
        {isOneTimeCampaignList ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <OneTimeCampaignPerformanceFilters
            statuses={ONE_TIME_STATUSES}
            messages={messages}
            usePerformanceContext={useEmailCampaignPerformanceContext}
          />
        ) : (
          <CampaignPerformanceFilters
            statuses={ONGOING_STATUSES}
            messages={messages}
            usePerformanceContext={useEmailCampaignPerformanceContext}
          />
        )}
      </Box>

      <DividerLine margin="none" />

      <Box maxWidth="100%" pl="m" pr="m">
        <Flex width="100%" minHeight="85px" alignItems="center" justifyContent="center">
          {isActivityQueryFetching || isCampaignQueryFetching ? (
            <Loader />
          ) : (
            <HStack justifyContent="space-between" flexBasis="100%">
              <CampaignPerformanceStatistics cards={cards} />
              <Button
                variant="tertiary"
                data-test="sr-button-expand-chart"
                icon={collapsed ? 'VMSWeb-chevron-down' : 'VMSWeb-chevron-up'}
                onClick={() => {
                  onCollapsedChange(!collapsed)
                }}
                noPadding
              />
            </HStack>
          )}
        </Flex>
        <Collapsible collapsed={collapsed}>
          <Box width="100%" mt="m">
            <CampaignPerformanceChart
              histogram={campaignActivityData?.histogram || []}
              isFetching={isActivityQueryFetching}
              error={activityQueryError}
              messages={messages}
              usePerformanceContext={useEmailCampaignPerformanceContext}
              availableStatistics={venue.isSevenRoomsOrderingEnabled ? EMAIL_STATS_WITH_ORDERS : EMAIL_STATS_WITHOUT_ORDERS}
            />
          </Box>
        </Collapsible>
      </Box>
    </>
  )
}

function buildCards(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  this: any,
  currentActivities: CampaignActivityItem,
  reservationsLink: string,
  currencySymbol: string,
  locale: string,
  isOneTimeCampaignList?: boolean,
  isPOSActivated?: boolean,
  averageSpendPerCover?: number
) {
  const totalRevenue =
    !isPOSActivated && averageSpendPerCover ? currentActivities.covers * averageSpendPerCover : currentActivities.totalRevenue

  return isOneTimeCampaignList
    ? [
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceRecipients),
          value: currentActivities.recipients.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceOpens),
          value: getPercentageOfTotal(currentActivities.opens, currentActivities.recipients),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceClicked),
          value: getPercentageOfTotal(currentActivities.clicks, currentActivities.recipients),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceTotalRevenue),
          value: `${currencySymbol}${Math.round(totalRevenue).toLocaleString(locale)}`,
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceReservations),
          value: currentActivities.reservations.toLocaleString(locale),
          linkConfig: reservationsLink
            ? {
                link: reservationsLink,
                isExternal: true,
              }
            : undefined,
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceCrossPromos),
          value: currentActivities.crossSelling.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceCovers),
          value: currentActivities.covers.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceOrders),
          value: currentActivities.orders.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceUnsubscribes),
          value: currentActivities.unsubscribed.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceBounceRate),
          value: getPercentageOfTotal(currentActivities.bounces, currentActivities.recipients),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceComplaintRate),
          value: getPercentageOfTotal(currentActivities.complaints, currentActivities.recipients),
        },
      ]
    : [
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceRecipients),
          value: currentActivities.recipients.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceOpens),
          value: getPercentageOfTotal(currentActivities.opens, currentActivities.recipients),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceReservations),
          value: currentActivities.reservations.toLocaleString(locale),
          linkConfig: reservationsLink
            ? {
                link: reservationsLink,
                isExternal: true,
              }
            : undefined,
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceCovers),
          value: currentActivities.covers.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceOnlineOrders),
          value: currentActivities.orders.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceRevenue),
          value: `${currencySymbol}${Math.round(totalRevenue).toLocaleString(locale)}`,
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceCrossPromos),
          value: currentActivities.crossSelling.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.campaignPerformanceUnsubscribes),
          value: currentActivities.unsubscribed.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.bounce),
          value: currentActivities.bounces.toLocaleString(locale),
        },
        {
          name: this.formatMessage(campaignCenterMessages.complaints),
          value: currentActivities.complaints.toLocaleString(locale),
        },
      ]
}

export function getActivityDifferences(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatMessage: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nav: any,
  reservationsLink: string,
  currencySymbol: string,
  locale: string,
  data?: CampaignActivityData,
  isOneTimeCampaignList?: boolean,
  isPOSActivated?: boolean,
  averageSpendPerCover?: number
) {
  if (data) {
    const { campaignActivity: currentAct } = data
    const currentActTotal = addUpActivities(currentAct)
    return buildCards.call(
      { formatMessage, nav },
      currentActTotal,
      reservationsLink,
      currencySymbol,
      locale,
      isOneTimeCampaignList,
      isPOSActivated,
      averageSpendPerCover
    )
  }
  return []
}
