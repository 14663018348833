import { EmailBodyContentItem } from './EmailBodyContentItem'

export function EmailBodyContent({ emailBody, onActionSelect }: { emailBody: string; onActionSelect: Function }) {
  const paragraphs = getParagraphs(emailBody)

  const changeParagraph = (html: string, index: number) => {
    const paragraph = paragraphs[index]

    if (paragraph) {
      paragraph.innerHTML = html
    }

    const updatedHTML = Array.from(paragraphs)
      .map(({ outerHTML }) => outerHTML)
      .join('')

    onActionSelect(updatedHTML)
  }

  return (
    <>
      {Array.from(paragraphs).map(({ innerHTML: text }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <EmailBodyContentItem key={index} index={index} text={text} updateField={changeParagraph} />
      ))}
    </>
  )
}

function getParagraphs(htmlString: string): NodeListOf<HTMLParagraphElement> {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlString

  const firstChild = tempDiv.firstChild as Element | null

  if (firstChild && firstChild.tagName === 'DIV') {
    tempDiv.innerHTML = firstChild.innerHTML
  }

  const content = tempDiv.innerHTML

  const parser = new DOMParser()
  const doc = parser.parseFromString(content, 'text/html')

  return doc.querySelectorAll('p')
}
