import { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import type { BookingAccessOverrideList } from '../../api'
import type { BookingAccessOverrideListItem } from '../../domain'

export namespace BookingAccessOverrideListItemAdapter {
  export function toClient(data: BookingAccessOverrideList): BookingAccessOverrideListItem[] {
    return data.overrides.map(item => ({
      id: item.id,
      persistentId: item.persistent_id,
      name: item.name,
      date: DateOnly.from(item.date),
      accessTimeType: item.access_time_type,
      specificTimes: item.specific_times,
      shiftCategories: item.shift_categories,
      restrictToShifts: item.restrict_to_shifts,
      partySizeMax: item.party_size_max,
      partySizeMin: item.party_size_min,
      endTime: TimeOnly.fromSafe(item.end_time),
      startTime: TimeOnly.fromSafe(item.start_time),
      isIndefinite: item.is_indefinite,
      endDate: DateOnly.fromSafe(item.end_date),
    }))
  }
}
