import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import type { CheckoutSettingsAction } from 'mgr/pages/single-venue/settings/actions/ordering/CheckoutActions'
import type { CheckoutSettings, PosFields } from 'mgr/pages/single-venue/settings/types/ordering/Checkout.types'

export interface OrderingCheckoutState {
  isLoading: boolean
  isDirty: boolean
  isInitialized: boolean
  checkoutSettings: CheckoutSettings
  enforceDoordashRules: boolean
  posFields: PosFields
}
const initialState: OrderingCheckoutState = {
  isLoading: true,
  isInitialized: false,
  isDirty: false,
  checkoutSettings: {} as CheckoutSettings,
  enforceDoordashRules: false,
  posFields: [],
}

const reducer = (state: OrderingCheckoutState = initialState, action: CheckoutSettingsAction) => {
  switch (action.type) {
    case ActionTypes.LOAD_ORDERING_CHECKOUT_SETTINGS_SUCCESS:
      return {
        ...state,
        checkoutSettings: {
          ...state.checkoutSettings,
          ...action.checkoutSettings,
        },
        enforceDoordashRules: action.enforceDoordashRules,
        posFields: action.posFields,
        isLoading: false,
        isDirty: false,
      }
    case ActionTypes.CHANGE_ORDERING_CHECKOUT_SETTINGS_FIELD: {
      const { field, value } = action
      return {
        ...state,
        checkoutSettings: {
          ...state.checkoutSettings,
          [field]: value,
        },
        isDirty: true,
      }
    }
    case ActionTypes.CHANGE_ORDERING_CHECKOUT_POS_FIELD: {
      const { id, value } = action
      return {
        ...state,
        isDirty: true,
        posFields: state.posFields.map(posField => ({
          ...posField,
          value: id === posField.id ? value : posField.value,
        })),
      }
    }
    case ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_SUCCESS:
      return {
        ...state,
        checkoutSettings: {
          ...state.checkoutSettings,
          updated: action.updated,
        },
        isLoading: false,
        isDirty: false,
      }
    case ActionTypes.LOAD_ORDERING_CHECKOUT_SETTINGS_START:
    case ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_START:
      return { ...state, isLoading: true, isInitialized: true }
    case ActionTypes.LOAD_ORDERING_CHECKOUT_SETTINGS_FAIL:
    case ActionTypes.SAVE_ORDERING_CHECKOUT_SETTINGS_FAIL:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

export default reducer
