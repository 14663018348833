import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'
import VmsIcons, { StyledIcons } from 'svr/common/VmsIcons'

const BorderedArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 26px;
  border: 1px solid ${props => props.theme.lightGrey};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  :hover {
    border-color: ${props => props.theme.darkGrey};
    border: 1px solid ${props => props.hoverBorderColor};
    transition: border-color 0.2s ease-out;
  }
  ${props => props.theme.clearFix};
`

const RightArrow = styled(StyledIcons.S)`
  border-radius: 0 4px 4px 0;
  line-height: 26px;
  font-size: 18px;
  fontweight: 100;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => (props.disabled ? props.theme.lightGrey : props.theme.navigationDark)};
  :hover {
    background-color: ${props => (props.disabled ? props.theme.background : props.theme.lightGrey)};
  }
`

const LeftArrow = styled(RightArrow)`
  transform: rotate(180deg);
  border-left: 1px solid ${props => props.theme.lightGrey};
`

const PaginationArrows = ({ page, numPages, onChange, disabled }) => {
  const leftDisabled = disabled || page === 0
  const rightDisabled = disabled || page >= numPages - 1
  const onClickLeft = e => {
    e.preventDefault()
    if (!leftDisabled) {
      onChange(page - 1)
    }
  }
  const onClickRight = e => {
    e.preventDefault()
    if (!rightDisabled) {
      onChange(page + 1)
    }
  }

  return (
    <BorderedArea>
      <LeftArrow disabled={leftDisabled} onClick={onClickLeft}>
        {VmsIcons.Chevron}
      </LeftArrow>
      <RightArrow disabled={rightDisabled} onClick={onClickRight}>
        {VmsIcons.Chevron}
      </RightArrow>
    </BorderedArea>
  )
}

PaginationArrows.propTypes = {
  page: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

PaginationArrows.defaultProps = {
  onChange: () => {},
  disabled: false,
}

export default PaginationArrows
