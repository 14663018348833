import { useMemo } from 'react'
import { EmailCampaignTemplateCategoryEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { marketingCommonMessages } from '../locales'

export function useOneTimeEmailCampaignTemplateCategories() {
  const { formatMessage } = useLocales()

  return useMemo<Array<SelectOption<EmailCampaignTemplateCategoryEnum>>>(
    () => [
      {
        id: EmailCampaignTemplateCategoryEnum.NEWSLETTER,
        label: formatMessage(marketingCommonMessages.newsletter),
      },
      {
        id: EmailCampaignTemplateCategoryEnum.PROMOTION,
        label: formatMessage(marketingCommonMessages.promotion),
      },
      {
        id: EmailCampaignTemplateCategoryEnum.ANNOUNCEMENT,
        label: formatMessage(marketingCommonMessages.announcement),
      },
      {
        id: EmailCampaignTemplateCategoryEnum.LOYALTY,
        label: formatMessage(marketingCommonMessages.loyalty),
      },
      {
        id: EmailCampaignTemplateCategoryEnum.HOLIDAY_AND_SEASONAL,
        label: formatMessage(marketingCommonMessages.holidaySeasonal),
      },
      {
        id: EmailCampaignTemplateCategoryEnum.POST_CARD,
        label: formatMessage(marketingCommonMessages.postCard),
      },
      {
        id: EmailCampaignTemplateCategoryEnum.SINGLE_COLUMN_LAYOUT,
        label: formatMessage(marketingCommonMessages.singleColumnLayout),
      },
      {
        id: EmailCampaignTemplateCategoryEnum.DOUBLE_COLUMN_LAYOUT,
        label: formatMessage(marketingCommonMessages.doubleColumnLayout),
      },
      {
        id: EmailCampaignTemplateCategoryEnum.NAVIGATION_BAR,
        label: formatMessage(marketingCommonMessages.navigationBar),
      },
    ],
    [formatMessage]
  )
}
