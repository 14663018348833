import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { FormPercentageInput } from '@sevenrooms/core/ui-kit/form'
import { Flex, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
// eslint-disable-next-line import/no-relative-packages
import { useStoreSelector } from '../../../../../application/site/static/app/manager/actualslideout/src/selectors/useStoreSelector'
import { paymentMessages } from '../Payment.locales'
import type { ClientSelectGratuityForm } from '../Payment.zod'

interface TotalClientGratuityProps {
  amount: number | null
  field: Field<ClientSelectGratuityForm>
  oldValue?: number | null
  override: boolean
  selectable?: boolean
  currencyCode: string
}

export function TotalClientGratuity({ amount, field, oldValue, selectable, currencyCode, override }: TotalClientGratuityProps) {
  const { formatMessage } = useLocales()
  const bookPaymentState = useStoreSelector(state => state.bookPaymentState)
  const { cardEntryOption } = bookPaymentState

  const [gratuityValue, gratuityClientSelect] = useWatchMany(field, ['gratuity', 'gratuityClientSelect'])

  const gratuity = gratuityValue ?? 0
  const oldGratuity = oldValue != null && Number(oldValue) !== gratuity ? oldValue : null

  return cardEntryOption !== 'paylink' ? (
    <HStack>
      <Flex flex="auto">
        <Text fontSize="m">{formatMessage(paymentMessages.clientSelectableGratuity)}</Text>
      </Flex>
      {oldGratuity != null && (
        <Flex width="150px" alignItems="center" justifyContent="end" pr="s">
          <Text textDecoration="line-through" color="error">
            {oldGratuity}%
          </Text>
        </Flex>
      )}
      <Flex width="190px" alignItems="center">
        <FormPercentageInput
          data-test="sr-supafly-gratuity-charge"
          field={field.prop('gratuity')}
          fieldHeight="s"
          hideErrorMessage
          disabled={!override && !selectable && !gratuityClientSelect}
        />
      </Flex>
      <Flex width="78px" alignItems="center" justifyContent="end">
        <Text>{amount != null && FormatService.formatCurrency(amount, currencyCode)}</Text>
      </Flex>
    </HStack>
  ) : null
}
