/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import { VmsIcons } from '../../../common/VmsIcons'
import DropdownArrowsPicker from './DropdownArrowsPicker'

class DatePickerWrapper extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.getMinDate = this.getMinDate.bind(this)
  }

  handleChange(date) {
    if (typeof this.props.onChangeHandler !== 'function') {
      return
    }
    this.props.onChangeHandler(date)
  }

  getMinDate() {
    if (this.props.hasMinDate === false) {
      return false
    } else if (this.props.customMinDate) {
      return moment(this.props.customMinDate, 'MM-DD-YYYY')
    }
    return moment(this.props.date).subtract(60, 'days')
  }

  render() {
    return (
      <DatePicker
        inline
        selected={this.props.date}
        // minEndDate sets min Date for "End Date" only
        minDate={this.props.minEndDate || this.getMinDate()}
        locale={this.props.locale}
        highlightDates={this.props.bookedDates}
        onChange={this.handleChange}
        maxDate={this.props.maxDate}
      />
    )
  }
}

DatePickerWrapper.defaultProps = {
  bookedDates: [],
  date: moment(),
}

class DateDropDown extends Component {
  constructor(props) {
    super(props)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleMoveLeft = this.handleMoveLeft.bind(this)
    this.handleMoveRight = this.handleMoveRight.bind(this)
    this.onChangeDate = this.props.onChangeDate.bind(this)
  }

  handleDateChange(date) {
    if (this.props.date.isSame(date)) {
      return
    }
    // eslint-disable-next-line react/no-string-refs
    this.refs.dropdownArrowsPicker.getInstance().closeDropdown()
    this.onChangeDate(date)
  }

  handleMoveLeft() {
    const newDate = moment(this.props.date).subtract(1, 'days')
    // eslint-disable-next-line react/no-string-refs
    this.refs.dropdownArrowsPicker.getInstance().closeDropdown()
    this.onChangeDate(newDate)
  }

  handleMoveRight() {
    const newDate = moment(this.props.date).add(1, 'days')
    // eslint-disable-next-line react/no-string-refs
    this.refs.dropdownArrowsPicker.getInstance().closeDropdown()
    this.onChangeDate(newDate)
  }

  render() {
    const {
      locale,
      bookedDates,
      openedDates,
      isLightTheme,
      date,
      name,
      useOutsideLabel,
      style,
      hasMinDate,
      customMinDate,
      noHeader,
      maxDate,
      minEndDate,
    } = this.props
    const displayName = name || 'DATE'

    const passStyle = style || { float: 'left', width: 250 }

    return (
      <div className="svr-datepicker">
        <DropdownArrowsPicker
          key="DATE"
          name={displayName}
          noHeader={noHeader}
          // eslint-disable-next-line react/no-string-refs
          ref="dropdownArrowsPicker"
          choices={[{ name: date.format('LL'), value: 'date' }]}
          value="date"
          onMoveLeft={this.handleMoveLeft}
          onMoveRight={this.handleMoveRight}
          style={passStyle}
          showNavigation
          isLightTheme={isLightTheme}
          useOutsideLabel={useOutsideLabel}
          icon={VmsIcons.CalendarLine}
          dropDown={
            <DatePickerWrapper
              date={date}
              locale={locale}
              hasMinDate={hasMinDate}
              customMinDate={customMinDate}
              bookedDates={bookedDates}
              openedDates={openedDates}
              onChangeHandler={this.handleDateChange}
              maxDate={maxDate}
              minEndDate={minEndDate}
            />
          }
        />
      </div>
    )
  }
}

DateDropDown.defaultProps = {
  isLightTheme: false,
  useOutsideLabel: false,
}

DateDropDown.propTypes = {
  locale: React.PropTypes.string,
  name: React.PropTypes.string,
  noHeader: React.PropTypes.bool,
  isLightTheme: React.PropTypes.bool.isRequired,
  useOutsideLabel: React.PropTypes.bool,
  style: React.PropTypes.object,
  bookedDates: React.PropTypes.array,
  openedDates: React.PropTypes.array,
  onChangeDate: React.PropTypes.func,
  maxDate: React.PropTypes.string,
  minEndDate: React.PropTypes.string,
}

export default DateDropDown
