import { useState, type FormEvent, useCallback } from 'react'
import type { GroupVenuesDict } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Select, Input } from '@sevenrooms/core/ui-kit/core'
import { Button, Label } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalActions,
  ModalTitle,
  type ModalHeaderProps,
} from '@sevenrooms/core/ui-kit/layout'
import { campaignBuilderMessages, emailBuilderMessages } from '@sevenrooms/marketing'
import type { Venue } from '@sevenrooms/mgr-core'
import type { EmailEditorDestinations, VMSEmailEditorDestinations } from '../types'

interface CrossPromotionProps {
  venue?: Venue
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  destination: EmailEditorDestinations
  insertLink: (link: { text: string; link: string; id: string }) => void
  crossPromotionChoices?: { id: string; label: string }[]
  groupVenuesDict?: GroupVenuesDict
}

export function CrossPromotion({ venue, closeHref, destination, insertLink, crossPromotionChoices, groupVenuesDict }: CrossPromotionProps) {
  const { formatMessage } = useLocales()
  const [text, setText] = useState('')
  const [selectedVenue, setSelectedVenue] = useState<string>('')
  const [link, setLink] = useState('')
  const nav = useNavigation()

  const href = venue
    ? nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).crossPromotionModal, {
        params: { venueKey: venue.urlKey },
      })
    : nav.closeSurfaceHref(destination.crossPromotionModal)
  const doLinkInsertion = () => {
    if (groupVenuesDict) {
      const id = `##${[groupVenuesDict[selectedVenue]?.urlkey, 'CTA', selectedVenue].join('-')}`
      insertLink({ text, link, id })
    }
  }

  const onChange = useCallback(
    (value: string) => {
      if (value && groupVenuesDict) {
        setSelectedVenue(value)
        setLink(groupVenuesDict[value]?.reservationLink || '')
      } else {
        setSelectedVenue('')
        setLink('')
      }
    },
    [setSelectedVenue, groupVenuesDict]
  )

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle
          title={formatMessage(emailBuilderMessages.crossPromotionModalTitle)}
          subTitle={formatMessage(emailBuilderMessages.crossPromotionModalSubTitle)}
        />
      </ModalHeader>
      <ModalBody>
        <Box p="s">
          <Label primary={formatMessage(emailBuilderMessages.venue)}>
            <Select
              options={crossPromotionChoices || []}
              searchable={false}
              onChange={onChange}
              value={selectedVenue}
              placeholder={formatMessage(emailBuilderMessages.chooseVenue)}
            />
          </Label>
        </Box>
        <Box p="s">
          <Label primary={formatMessage(emailBuilderMessages.reservationTextModalLabel)}>
            <Input
              placeholder={formatMessage(emailBuilderMessages.reservationModalTextInputPlaceholder)}
              onChange={(evnt: FormEvent<HTMLInputElement>) => {
                setText(evnt.currentTarget.value)
              }}
              value={text}
              autoFocus
            />
          </Label>
        </Box>
        <Box p="s">
          <Label primary={formatMessage(emailBuilderMessages.reservationLinkModalLabel)}>
            <Input placeholder={formatMessage(emailBuilderMessages.reservationModalInputLinkPlaceholder)} value={link} disabled readOnly />
          </Label>
        </Box>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={href} data-test="cross-promotion-cancel">
            {formatMessage(campaignBuilderMessages.modalCancelButton)}
          </Button>
          <Button
            href={href}
            onClick={doLinkInsertion}
            variant="primary"
            disabled={text.length === 0 || selectedVenue.length === 0}
            data-test="cross-promotion-add-link"
          >
            {formatMessage(campaignBuilderMessages.modalPrimaryButton)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
