import _ from 'lodash'
import { Helmet } from 'react-helmet'

export interface SettingsPageMetaProps {
  title?: string
  venue?: string
}

export function SettingsPageMeta({ title, venue }: SettingsPageMetaProps) {
  if (!title && !venue) {
    return null
  }

  const resultVenue = _.unescape(venue)

  return (
    <Helmet>
      <title>{title && resultVenue ? `${resultVenue} - ${title}` : resultVenue || title}</title>
    </Helmet>
  )
}
