import { useState, type FormEvent } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, Label } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalActions,
  ModalTitle,
  type ModalHeaderProps,
} from '@sevenrooms/core/ui-kit/layout'
import { campaignBuilderMessages, emailBuilderMessages } from '@sevenrooms/marketing'
import type { Venue } from '@sevenrooms/mgr-core'
import type { EmailEditorDestinations, VMSEmailEditorDestinations } from '../types'

interface UnsubscribeProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  insertLink: (link: { text: string; link: string }) => void
  destination: EmailEditorDestinations
  venue?: Venue
}

export function Unsubscribe({ closeHref, insertLink, destination, venue }: UnsubscribeProps) {
  const { formatMessage } = useLocales()
  const [link, setLink] = useState({
    text: '',
    link: `https://sevenrooms.com/unsubscribe_link`,
  })
  const nav = useNavigation()

  const doLinkInsertion = () => {
    insertLink(link)
  }

  const href = venue
    ? nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).unsubscribeModal, {
        params: { venueKey: venue.urlKey },
      })
    : nav.closeSurfaceHref(destination.unsubscribeModal)

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle
          title={formatMessage(emailBuilderMessages.unsubscribeModalTitle)}
          subTitle={formatMessage(emailBuilderMessages.unsubscribeModalSubTitle)}
        />
      </ModalHeader>
      <ModalBody>
        <Box p="s">
          <Label primary={formatMessage(emailBuilderMessages.reservationTextModalLabel)}>
            <Input
              placeholder={formatMessage(emailBuilderMessages.reservationModalTextInputPlaceholder)}
              onChange={(evnt: FormEvent<HTMLInputElement>) => {
                const update = { ...link, text: evnt.currentTarget.value }
                setLink(update)
              }}
              value={link.text}
              autoFocus
            />
          </Label>
        </Box>
        <Box p="s">
          <Label primary={formatMessage(emailBuilderMessages.reservationLinkModalLabel)}>
            <Input
              placeholder={formatMessage(emailBuilderMessages.reservationModalInputLinkPlaceholder)}
              value={link.link}
              disabled
              readOnly
            />
          </Label>
        </Box>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={href} data-test="cancel-button">
            {formatMessage(campaignBuilderMessages.modalCancelButton)}
          </Button>
          <Button variant="primary" onClick={doLinkInsertion} href={href} disabled={link.text.length === 0} data-test="add-link-button">
            {formatMessage(campaignBuilderMessages.modalPrimaryButton)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
