import type { ReservationWidget } from '@sevenrooms/core/domain'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Status } from '@sevenrooms/core/ui-kit/typography'
import { calculateTotal, collectClientGratuityAmountCategories, collectTotalCategories } from '../payment-utils'
import { paymentMessages } from '../Payment.locales'
import { TotalClientGratuity } from './TotalClientGratuity'
import type { PaymentProps } from '../Payment'
import type { PaymentForm } from '../Payment.zod'

interface TotalsProps {
  field: Field<PaymentForm>
  oldValues?: PaymentProps['oldValues']
  oldFulfilledValues?: PaymentProps['oldFulfilledValues']
  taxGroups: ReservationWidget.TaxGroup[]
  currencyCode: string
  override: boolean
}

export function Totals({ field, oldValues, oldFulfilledValues, taxGroups, currencyCode, override }: TotalsProps) {
  const { formatMessage } = useLocales()
  const [amount, charges, categoriesBundled, categories, clientSelectGratuity] = useWatchMany(field, [
    'amount',
    'charges',
    'categoriesBundled',
    'categories',
    'clientSelectGratuity',
  ])
  const { gratuityClientSelect } = clientSelectGratuity

  const { total: initialTotal, gratuityAmount } = calculateTotal(amount ?? 0, charges, taxGroups, false, false)
  let total = initialTotal
  total += collectTotalCategories(categoriesBundled, taxGroups, override, false)
  total += collectTotalCategories(categories, taxGroups, override, false)

  let clientGratuityAmount = charges.gratuityClientSelect ? gratuityAmount : 0
  clientGratuityAmount += collectClientGratuityAmountCategories(categoriesBundled, taxGroups, override, false)
  clientGratuityAmount += collectClientGratuityAmountCategories(categories, taxGroups, override, false)

  let prevPayment = 0
  let resultPayment = 0

  if (oldFulfilledValues) {
    prevPayment += calculateTotal(oldFulfilledValues.amount ?? 0, oldFulfilledValues.charges, taxGroups, false, false).total
    prevPayment += collectTotalCategories(oldFulfilledValues.categories, taxGroups, false, false)
    prevPayment += collectTotalCategories(oldFulfilledValues.categoriesBundled, taxGroups, false, false)
    resultPayment = total - prevPayment
  }

  return (
    <VStack>
      <VStack borderTopColor="borders" spacing="s" p="m xs">
        {gratuityClientSelect && (
          <TotalClientGratuity
            amount={clientGratuityAmount}
            field={field.prop('clientSelectGratuity')}
            oldValue={oldValues?.clientSelectGratuity.gratuity}
            currencyCode={currencyCode}
            override={override}
          />
        )}
        <HStack>
          <Flex flex="auto">
            <Text fontSize="xl">{formatMessage(paymentMessages.total)}</Text>
          </Flex>
          <Flex justifyContent="end">
            <Text fontSize="xl">{FormatService.formatCurrency(total, currencyCode)}</Text>
          </Flex>
        </HStack>
        {!!oldFulfilledValues && (
          <HStack>
            <Flex flex="auto">
              <Text fontSize="xl">{formatMessage(paymentMessages.previousPayment)}</Text>
            </Flex>
            <Flex justifyContent="end">
              <Text fontSize="xl">({FormatService.formatCurrency(prevPayment, currencyCode)})</Text>
            </Flex>
          </HStack>
        )}
      </VStack>
      {!!oldFulfilledValues && (
        <VStack borderTopColor="borders" spacing="s" p="m xs">
          <HStack>
            <Flex flex="auto">
              <Text fontWeight="bold" fontSize="xl">
                {resultPayment < 0 ? formatMessage(paymentMessages.amountRefund) : formatMessage(paymentMessages.amountCharge)}
              </Text>
            </Flex>
            <Flex justifyContent="end">
              <Text fontWeight="bold" fontSize="xl" color={resultPayment < 0 ? 'error' : 'primaryFont'}>
                {FormatService.formatCurrency(Math.abs(resultPayment), currencyCode)}
              </Text>
            </Flex>
          </HStack>
          {resultPayment < 0 && <Status kind="warning">{formatMessage(paymentMessages.amountRefundWarning)}</Status>}
        </VStack>
      )}
    </VStack>
  )
}
