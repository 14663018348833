import { srGet, throwFetchError } from '@sevenrooms/core/api'

export const fetchShifts = (venueId, date) => {
  const dateParam = date.format('MM/DD/YYYY')
  return srGet(
    `/api-yoa/shifts/schedule`,
    {
      venue: venueId,
      start_date: dateParam,
      end_date: dateParam,
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  )
    .then(throwFetchError)
    .then(response => response.data.shifts[dateParam])
}

export default {
  fetchShifts,
}
