import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { ViewCardTitleSmall, ViewField } from 'mgr/actualslideout/components/view/ViewCommon'
import { FlexColumnContainer } from './ViewLayout'

const RatingRow = styled.div`
  display: block;
  clear: both;
  padding-top: 13px;
  font-size: 13px;
`
const MessageRatingRow = styled(RatingRow)`
  padding-bottom: 10px;
  overflow: hidden;
`
const RecommendedRatingRow = styled(RatingRow)`
  padding-bottom: 13px;
`
const OverallRatingRow = styled(RatingRow)`
  padding-top: 5px !important;
`
const OverallRatingHeader = styled.div`
  display: block;
  font-size: 20px;
  margin-top: 5px;
  float: left;
`
const OverallRatingRight = styled.span`
  font-size: 15px;
  color: ${props => props.theme.navigationDark};
  float: right;
  margin-top: 13px;
`
const FeedbackTopCard = styled(FlexColumnContainer)`
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid ${props => props.theme.margin};
`
const FeedbackMiddleCard = styled(FlexColumnContainer)`
  border-radius: 0px;
  border-bottom: 1px solid ${props => props.theme.margin};
`
const FeedbackBottomCard = styled(FlexColumnContainer)`
  border-radius: 0 0 6px 6px;
  padding-top: 15px;
  padding-bottom: 5px;
  color: #347baf;
  font-weight: 900;
  cursor: pointer;
`
const SubRatingLabel = styled.span`
  color: #858e95;
`

const SubRatingRight = styled.span`
  color: #858e95;
  float: right;
`
const getFormattedFeedbackRating = feedbackRating => {
  const feedbackFormattedOne = feedbackRating !== 0 ? Array(feedbackRating + 1).join('★') : 'not filled in'
  const feedbackFormattedTwo = feedbackRating !== 0 ? Array(6 - feedbackRating).join('☆') : ''
  return feedbackFormattedOne + feedbackFormattedTwo
}
const truncateFeedbackNotes = feedbackNotes => {
  let lines = 1
  let letterCountPerRow = 0
  const preview = []
  const splitNotes = feedbackNotes.split(' ')
  for (let i = 0; i < splitNotes.length; i += 1) {
    letterCountPerRow += splitNotes[i].length
    preview.push(splitNotes[i])
    if (letterCountPerRow > 40) {
      if (lines === 3) {
        break
      }
      preview.push('\n')
      letterCountPerRow = 0
      lines += 1
    }
  }
  let feedbackNotesPreview = preview.join(' ')
  if (lines === 3) {
    feedbackNotesPreview = feedbackNotesPreview.substring(0, feedbackNotesPreview.length - 4)
    feedbackNotesPreview += '...'
  }
  return feedbackNotesPreview
}

export const FeedbackRatingCard = ({
  feedbackDetailsOpen,
  toggleFeedbackDetails,
  feedbackRatings,
  feedbackNotes,
  feedbackRecommend,
  ...rest
}) => {
  const feedbackNotesPreview = feedbackNotes !== null ? truncateFeedbackNotes(feedbackNotes) : ''
  const overallRating = _.isNil(feedbackRatings.overall) ? 0 : parseInt(feedbackRatings.overall, 10)
  const foodRating = _.isNil(feedbackRatings.food) ? 0 : parseInt(feedbackRatings.food, 10)
  const drinksRating = _.isNil(feedbackRatings.drinks) ? 0 : parseInt(feedbackRatings.drinks, 10)
  const serviceRating = _.isNil(feedbackRatings.service) ? 0 : parseInt(feedbackRatings.service, 10)
  const ambienceRating = _.isNil(feedbackRatings.ambience) ? 0 : parseInt(feedbackRatings.ambience, 10)
  return (
    <div>
      <ViewField>
        <ViewCardTitleSmall>feedback</ViewCardTitleSmall>
      </ViewField>
      <FeedbackTopCard>
        <OverallRatingRow>
          <OverallRatingHeader>Overall</OverallRatingHeader>
          <OverallRatingRight>
            {overallRating} of 5 {getFormattedFeedbackRating(overallRating)}
          </OverallRatingRight>
        </OverallRatingRow>
        <MessageRatingRow>{feedbackDetailsOpen ? feedbackNotes : feedbackNotesPreview}</MessageRatingRow>
      </FeedbackTopCard>
      {feedbackDetailsOpen ? (
        <FeedbackMiddleCard>
          {!_.isNil(feedbackRatings.food) ? (
            <RatingRow>
              <SubRatingLabel>Food</SubRatingLabel>
              <SubRatingRight>{getFormattedFeedbackRating(foodRating)}</SubRatingRight>
            </RatingRow>
          ) : null}
          {!_.isNil(feedbackRatings.drinks) ? (
            <RatingRow>
              <SubRatingLabel>Drinks</SubRatingLabel>
              <SubRatingRight>{getFormattedFeedbackRating(drinksRating)}</SubRatingRight>
            </RatingRow>
          ) : null}
          <RatingRow>
            <SubRatingLabel>Service</SubRatingLabel>
            <SubRatingRight>{getFormattedFeedbackRating(serviceRating)}</SubRatingRight>
          </RatingRow>
          <RatingRow>
            <SubRatingLabel>Ambience</SubRatingLabel>
            <SubRatingRight>{getFormattedFeedbackRating(ambienceRating)}</SubRatingRight>
          </RatingRow>
          <RecommendedRatingRow>
            <SubRatingLabel>Recommended</SubRatingLabel>
            <SubRatingRight>{feedbackRecommend}</SubRatingRight>
          </RecommendedRatingRow>
        </FeedbackMiddleCard>
      ) : null}
      <FeedbackBottomCard onClick={toggleFeedbackDetails}>{feedbackDetailsOpen ? 'HIDE DETAILS' : 'SHOW DETAILS'}</FeedbackBottomCard>
    </div>
  )
}
