/* eslint-disable camelcase */
import type { AvailabilityTimeslot, MixedAvailabilityTimeslot } from '@sevenrooms/core/api'
import type { ShiftCategory } from '@sevenrooms/core/domain'
import { FormatService } from '@sevenrooms/core/locales'
import type { GratuityType, Venue, Charges } from '../../reducers/BookPaymentSlice.types'
import type { Moment } from 'moment-timezone'

export function getPaylinkGratuityType({ gratuity_type, require_gratuity_charge }: Partial<AvailabilityTimeslot>): GratuityType {
  if (gratuity_type === 'CLIENT_GRATUITY') {
    return require_gratuity_charge ? 'require_client_select_charge' : 'client_select_gratuity'
  }
  return 'gratuity_percentage'
}

export function getCostFromTimeslot(currencyCode?: string, timeslot?: MixedAvailabilityTimeslot): string | undefined {
  return (currencyCode != null && timeslot?.cost && FormatService.formatCurrency(timeslot.cost, currencyCode)) || undefined
}

export function getChargesFromAvailabilityTimeslot(timeSlot: AvailabilityTimeslot, venue: Venue): Charges {
  const taxGroup = venue.bookSettings.taxGroups.find(group => Number(group.tax_rate) === timeSlot.tax_rate)
  return {
    currencyCode: venue.currencyCode,
    chargeType: timeSlot.charge_type,
    cardRequired: !!timeSlot.require_credit_card,
    paymentRule: timeSlot.cc_payment_rule,
    partySizeMinRule: timeSlot.cc_party_size_min,
    costMinRule: timeSlot.cost,
    requiredGratuity: timeSlot.gratuity,
    chargeApplyTax: timeSlot.tax_rate != null,
    taxGroupId: taxGroup?.id,
    chargeTax: timeSlot.tax_rate ?? 0,
    applyServiceCharge: !!timeSlot.apply_service_charge,
    serviceCharge: timeSlot.service_charge ?? 0,
    applyGratuityCharge: !!timeSlot.apply_gratuity_charge,
    gratuityType: timeSlot.gratuity_type,
    requiredGratuityCharge: !!timeSlot.require_gratuity_charge,
    gratuityCharge: timeSlot.gratuity ?? 0,
    paylinkGratuityType: getPaylinkGratuityType(timeSlot),
  }
}

export function applyChargesToAvailabilityTimeslot(timeslot: MixedAvailabilityTimeslot, charges?: Charges): MixedAvailabilityTimeslot {
  return charges
    ? {
        ...timeslot,
        charge_type: charges.chargeType,
        require_credit_card: charges.cardRequired,
        cc_payment_rule: charges.paymentRule,
        cc_party_size_min: charges.partySizeMinRule,
        cost: charges.costMinRule,
        gratuity: charges.gratuityCharge,
        tax_rate: charges.chargeTax,
        apply_service_charge: charges.applyServiceCharge,
        service_charge: charges.serviceCharge,
        apply_gratuity_charge: charges.applyGratuityCharge,
        gratuity_type: charges.gratuityType,
        require_gratuity_charge: charges.requiredGratuityCharge,
      }
    : timeslot
}

export function hasChangedCriteriaFromLastSave(
  partySize: number,
  duration: number,
  date: Moment,
  previousPartySize: number,
  previousDuration: number,
  previousDate: Moment
) {
  return previousPartySize !== partySize || previousDuration !== duration || !previousDate.isSame(date)
}

export function getShiftTimeslots(
  timeslots?: { timeslot: AvailabilityTimeslot; category: ShiftCategory }[],
  shiftPersistentId?: string | null
) {
  const shiftTimeslots =
    shiftPersistentId != null ? timeslots?.filter(({ timeslot }) => timeslot.shift_persistent_id === shiftPersistentId) : timeslots
  const category = shiftTimeslots?.[0]?.category
  if (category == null || shiftTimeslots == null || shiftTimeslots.length === 0) {
    return {
      timeslots: null,
      category: null,
    }
  }

  return {
    timeslots: shiftTimeslots.map(({ timeslot }) => timeslot),
    category,
  }
}
