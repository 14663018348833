import React, { Component } from 'react'
import { orderToTime } from '../../../common/TimeUtil'
import DropdownArrowsPicker from './DropdownArrowsPicker'

const TimeDropDown = ({
  startOfDayHour,
  timeSlot,
  timeSlots,
  onChangeTime,
  width,
  style,
  showNavigation,
  isLightTheme,
  isMultiSelect,
  noHeader,
  values,
  disabled,
  useOutsideLabel,
  name,
  format = 'LT',
}) => {
  const choices = timeSlots.map(ts => ({ name: orderToTime(startOfDayHour, ts).format(format), value: ts }))

  return (
    <DropdownArrowsPicker
      key="TIME"
      name={name}
      choices={choices}
      noHeader={noHeader}
      value={timeSlot}
      values={values}
      isMultiSelect={isMultiSelect}
      onChangeHandler={onChangeTime}
      style={{
        float: 'left',
        width,
        ...style,
      }}
      disabled={disabled}
      useOutsideLabel={useOutsideLabel}
      showNavigation={showNavigation}
      isLightTheme={isLightTheme}
    />
  )
}

TimeDropDown.defaultProps = {
  showNavigation: false,
  isLightTheme: true,
  width: 160,
  style: {},
  onChangeTime: () => {},
  isMultiSelect: false,
  noHeader: false,
  disabled: false,
  useOutsideLabel: false,
  name: 'TIME',
}

TimeDropDown.propTypes = {
  startOfDayHour: React.PropTypes.number.isRequired,
  timeSlots: React.PropTypes.array.isRequired,
  showNavigation: React.PropTypes.bool.isRequired,
  isLightTheme: React.PropTypes.bool.isRequired,
  width: React.PropTypes.number.isRequired,
  style: React.PropTypes.object.isRequired,
  onChangeTime: React.PropTypes.func.isRequired,
  isMultiSelect: React.PropTypes.bool.isRequired,
  noHeader: React.PropTypes.bool.isRequired,
  timeSlot: React.PropTypes.number,
  values: React.PropTypes.array,
  disabled: React.PropTypes.bool,
  useOutsideLabel: React.PropTypes.bool,
  name: React.PropTypes.string,
}

export default TimeDropDown
