import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { isTimeSlotBookable } from 'mgr/actualslideout/selectors/BookSelectors'
import { StyledIcons } from 'svr/common/VmsIcons'
import { Icon } from '@sevenrooms/core/ui-kit/icons'

const SlotWrapper = styled.div`
  height: 44px;
  margin: 3px 5px;
  border-radius: 4px;
  color: ${props => (props.isAvailable ? props.theme.black : props.theme.darkGrey)};
  font-weight: 500;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: ${props => (props.isCondensedFormat ? 'stretch' : 'center')};
  justify-content: space-between;
  :hover: {
    color: ${props => (props.isAvailable || props.isBookable ? props.theme.black : props.theme.darkGrey)};
  }
`

const StatusBackgroundBase = styled.div`
  border-radius: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const AvailableBackground = styled(StatusBackgroundBase)`
  background: ${props => props.theme.primary};
  opacity: 0.6;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`

const SelectedBackground = styled(StatusBackgroundBase)`
  background: ${props => props.theme.black};
  opacity: 0.5;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`

const OverbookedBackground = styled(StatusBackgroundBase)`
  background: ${props => props.theme.darkGrey};
  cursor: ${props => (props.isBookable ? 'pointer' : 'default')};
  opacity: 0.2;
  ${props => (props.isBookable ? ':hover {opacity: 0.4;}' : '')};
`

const BlockedBackground = styled(StatusBackgroundBase)`
  border: 1px solid ${props => props.theme.error};
  background: repeating-linear-gradient(
    -45deg,
    ${props => props.theme.error} 1px,
    ${props => props.theme.white} 1px,
    ${props => props.theme.error} 5px
  );
  cursor: ${props => (props.isBookable ? 'pointer' : 'default')};
  opacity: 0.2;
  ${props => (props.isBookable ? ':hover {opacity: 0.4;}' : '')};
`

const GrayBlockedBackground = styled(StatusBackgroundBase)`
  border: 1px solid ${props => props.theme.darkGrey};
  background: repeating-linear-gradient(
    -45deg,
    ${props => props.theme.darkGrey} 1px,
    ${props => props.theme.white} 1px,
    ${props => props.theme.darkGrey} 5px
  );
  cursor: ${props => (props.isBookable ? 'pointer' : 'default')};
  opacity: 0.2;
  ${props => (props.isBookable ? ':hover {opacity: 0.4;}' : '')};
`

const SelectedIcon = styled(StyledIcons.S)`
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -10px;
  font-size: 22px;
  line-height: 44px;
  color: ${props => props.theme.white};
  user-select: none;
  cursor: pointer;
`

const SlotTextLabel = styled.div`
  position: relative;
  pointer-events: none;
  ${props => props.theme.ellipsis};
`

const TimeLabel = styled(SlotTextLabel)`
  order: 1;
  flex-shrink: 0;
  font-size: 14px;
  margin: 0 5px 0 20px;
  text-align: left;
`

const CenterLabel = styled(SlotTextLabel)`
  order: 2;
  flex-shrink: 50;
  flex-grow: 1;
  font-size: 14px;
  width: 170px;
  margin: 0 5px;
  text-align: center;
`

const PossibleLabel = styled(SlotTextLabel)`
  order: 3;
  flex-shrink: 1;
  font-size: 12px;
  min-width: 35px;
  margin: 0 5px;
  text-align: right;
`

const PacingLabel = styled(SlotTextLabel)`
  order: 4;
  flex-shrink: 0;
  font-size: 12px;
  min-width: 35px;
  margin: 0 20px 0 5px;
  text-align: right;
`

const CondensedLeft = styled(SlotTextLabel)`
  order: 1;
  flex-shrink: 1;
  flex-grow: 1;
  padding: 0 9px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: left;
  justify-content: space-evenly;
`

const CondensedRight = styled(SlotTextLabel)`
  order: 2;
  flex-shrink: 0;
  padding-right: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: right;
  justify-content: space-evenly;
`

const TimeLabelCondensed = styled(SlotTextLabel)`
  order: 1;
  flex-shrink: 0;
  font-size: ${props => (props.hasCenterText ? '12px' : '14px')};
`

const CenterLabelCondensed = styled(SlotTextLabel)`
  order: 2;
  flex-shrink: 3;
  font-size: 12px;
  max-width: 100%;
`

const PossibleLabelCondensed = styled(SlotTextLabel)`
  font-size: 10px;
  font-weight: 900;
`

const PacingLabelCondensed = styled(SlotTextLabel)`
  font-size: 10px;
  font-width: 900;
`

const AvailabilityTimeSlot = ({
  timeSlot,
  partySize,
  showSeatingAreaCoversVar,
  venue,
  isCondensedFormat,
  onSelect,
  isSelected,
  isMultiVenue,
}) => {
  const { timeMoment, status } = timeSlot
  const subStatus = timeSlot.sub_status
  const bookedCovers = parseInt(timeSlot.booked_covers)
  const bookedPossibleCovers = parseInt(timeSlot.booked_possible_covers)
  const bookedSeatingAreaCovers = parseInt(timeSlot.booked_seating_area_covers)
  const bookedPossibleSeatingAreaCovers = parseInt(timeSlot.booked_possible_seating_area_covers)
  const maxCovers = parseInt(timeSlot.max_covers)
  const showPossible = bookedPossibleCovers > bookedCovers
  const showSeatingAreaCovers = showSeatingAreaCoversVar && !_.isEmpty(timeSlot.seating_area_id) && !isCondensedFormat

  const isAvailable = status === 'available'
  const timeFormatted = timeMoment.format('LT')
  const isBookable = isTimeSlotBookable(timeSlot, venue)

  const get_status_background = () => {
    if (isAvailable) {
      return AvailableBackground
    }
    switch (status) {
      case 'max_total_covers':
        return GrayBlockedBackground
      case 'overbooked':
        return OverbookedBackground
      case 'overbooking':
        return OverbookedBackground
      case 'blocked':
        return GrayBlockedBackground
      case 'overbook_enforced_shift_party_size':
        return GrayBlockedBackground
      case 'no_tables':
        switch (subStatus) {
          case 'only_larger_blocked_tables':
            return GrayBlockedBackground
          case 'only_smaller_blocked_tables':
            return GrayBlockedBackground
          default:
            return OverbookedBackground
        }
      default:
        return OverbookedBackground
    }
  }
  const StatusBackground = get_status_background()

  const get_center_text = () => {
    switch (status) {
      case 'max_total_covers':
        return 'Max Total Covers'
      case 'overbooked':
        return 'Overbooked'
      case 'overbooking':
        return 'Overbooking'
      case 'blocked':
        return ''
      case 'overbook_enforced_shift_party_size':
        return 'Overbooking shift party size'
      case 'no_tables':
        switch (subStatus) {
          case 'only_larger_tables':
            return 'Larger Tables Available'
          case 'only_larger_blocked_tables':
            return 'Larger Blocked Tables Avail'
          case 'only_smaller_tables':
            return 'Smaller Tables Available'
          case 'only_smaller_blocked_tables':
            return 'Smaller Blocked Tables Avail'
          default:
            return 'No Tables Available'
        }
      default:
        return ''
    }
  }
  let centerText = get_center_text()

  const pacingSeatingAreaPrefix = showSeatingAreaCovers ? `(${bookedSeatingAreaCovers}) ` : ''
  const pacingText = `${pacingSeatingAreaPrefix + bookedCovers}/${maxCovers}`
  const possibleSeatingAreaPrefix = showSeatingAreaCovers ? `(${bookedPossibleSeatingAreaCovers}) ` : ''
  const possibleText = showPossible ? `${possibleSeatingAreaPrefix + bookedPossibleCovers}/${maxCovers}` : ''

  if (status === 'blocked') {
    centerText = timeSlot.override_block_name || ''
  }

  const hasCenterText = !_.isEmpty(centerText)
  const hasPossibleText = !_.isEmpty(possibleText)
  const onClick = isBookable
    ? () => {
        onSelect()
      }
    : undefined
  return isCondensedFormat ? (
    <SlotWrapper {...{ isCondensedFormat, isAvailable, isBookable }} onClick={onClick}>
      <StatusBackground {...{ isBookable }} />
      {isSelected && <SelectedBackground />}
      <CondensedLeft>
        <TimeLabelCondensed {...{ hasCenterText }}>{timeFormatted}</TimeLabelCondensed>
        {hasCenterText && <CenterLabelCondensed data-test="sr-timeslot-center">{centerText}</CenterLabelCondensed>}
      </CondensedLeft>
      <CondensedRight>
        {hasPossibleText && <PossibleLabelCondensed data-test="sr-timeslot-possible">{possibleText}</PossibleLabelCondensed>}
        <PacingLabelCondensed>{pacingText}</PacingLabelCondensed>
      </CondensedRight>
      {isSelected && <CheckIcon />}
    </SlotWrapper>
  ) : (
    <SlotWrapper
      data-test="sr-timeslot"
      className={isBookable ? 'sr-timeslot-bookable' : 'sr-timeslot-unbookable'}
      {...{ isCondensedFormat, isAvailable, isBookable }}
      onClick={onClick}
    >
      <StatusBackground {...{ isBookable }} />
      {isSelected && <SelectedBackground />}
      <TimeLabel data-test="sr-timeslot_time">{timeFormatted}</TimeLabel>
      {(hasCenterText || !isMultiVenue) && <CenterLabel data-test="sr-timeslot-center">{centerText}</CenterLabel>}
      {hasPossibleText && <PossibleLabel data-test="sr-timeslot-possible">{possibleText}</PossibleLabel>}
      <PacingLabel data-test="sr-timeslot_pacing">{pacingText}</PacingLabel>
      {isSelected && <CheckIcon />}
    </SlotWrapper>
  )
}

const CheckIcon = () => (
  <SelectedIcon>
    <Icon color="lightFont" name="VMSWeb-checkbox-marked-circle" />
  </SelectedIcon>
)

AvailabilityTimeSlot.defaultProps = {
  isCondensedFormat: false,
  isSelected: false,
  onSelect: () => {},
}

AvailabilityTimeSlot.propTypes = {
  timeSlot: React.PropTypes.object.isRequired,
  partySize: React.PropTypes.number.isRequired,
  showSeatingAreaCoversVar: React.PropTypes.bool,
  venue: React.PropTypes.object.isRequired,
  isCondensedFormat: React.PropTypes.bool.isRequired,
  onSelect: React.PropTypes.func.isRequired,
  isSelected: React.PropTypes.bool.isRequired,
  isMultiVenue: React.PropTypes.bool.isRequired,
}

export default AvailabilityTimeSlot
