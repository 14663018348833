import { defineMessages } from '@sevenrooms/core/locales'

export const submitButtonLocales = defineMessages({
  saveReservation: {
    id: 'actual.submitButton.saveReservation',
    defaultMessage: 'Save Reservation',
  },
  bookReservation: {
    id: 'actual.submitButton.bookReservation',
    defaultMessage: 'Book Reservation',
  },
  saveReservationWithPaylink: {
    id: 'actual.submitButton.saveReservationWithPaylink',
    defaultMessage: 'Save Reservation and Send Paylink',
  },
  bookReservationWithPaylink: {
    id: 'actual.submitButton.bookReservationWithPaylink',
    defaultMessage: 'Book Reservation and Send Paylink',
  },
} as const)
