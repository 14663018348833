import moment from 'moment-timezone'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import queryString from 'query-string'
import { BRAND_MAPPER, BRAND_MAPPER_NAMES } from 'svr/common/BrandIcon'

const dateFormat = 'YYYY-MM-DD'

// TODO maybe these should live in a util
export const dateFilters = [
  {
    name: 'Custom',
    value: 'custom',
    convert: (startDate, endDate) => ({
      start_date: startDate.format(dateFormat),
      end_date: endDate.format(dateFormat),
    }),
  },
  {
    name: 'Yesterday',
    value: 'yesterday',
    convert: () => ({
      start_date: moment().add(-1, 'days').format(dateFormat),
      end_date: moment().add(-1, 'days').format(dateFormat),
    }),
  },
  {
    name: 'Last 3 days',
    value: 'last3Days',
    convert: () => ({
      start_date: moment().add(-2, 'days').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last 7 days',
    value: 'last7Days',
    convert: () => ({
      start_date: moment().add(-6, 'days').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last 30 days',
    value: 'last30Days',
    convert: () => ({
      start_date: moment().add(-29, 'days').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last 60 days',
    value: 'last60Days',
    convert: () => ({
      start_date: moment().add(-59, 'days').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'This month',
    value: 'thisMonth',
    convert: () => ({
      start_date: moment().startOf('month').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last month',
    value: 'lastMonth',
    convert: () => ({
      start_date: moment().add(-1, 'months').startOf('month').format(dateFormat),
      end_date: moment().add(-1, 'months').endOf('month').format(dateFormat),
    }),
  },
  {
    name: 'This year',
    value: 'thisYear',
    convert: () => ({
      start_date: moment().startOf('year').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last year',
    value: 'lastYear',
    convert: () => ({
      start_date: moment().add(-1, 'year').startOf('year').format(dateFormat),
      end_date: moment().add(-1, 'year').endOf('year').format(dateFormat),
    }),
  },
  {
    name: 'All time',
    value: 'all',
    convert: () => ({}),
  },
]

export const platformFilters = configuredPlatforms => {
  const brands = Object.keys(BRAND_MAPPER).filter(e => e === 'sevenrooms' || (configuredPlatforms || []).includes(e))

  return [{ name: 'All', value: 'all' }].concat(
    brands.map(brand => ({
      name: BRAND_MAPPER_NAMES[brand] || BRAND_MAPPER[brand],
      value: brand,
    }))
  )
}

export const ratingFilters = [
  { name: 'All', value: 'all' },
  { name: '1 star', value: '1' },
  { name: '2 stars', value: '2' },
  { name: '3 stars', value: '3' },
  { name: '4 stars', value: '4' },
  { name: '5 stars', value: '5' },
]

export const feedbackTypeFilters = [
  { name: 'All', value: 'all' },
  { name: 'On Premise', value: 'VISIT' },
  { name: 'Pickup Order', value: 'ORDER_PICKUP' },
  { name: 'Delivery Order', value: 'ORDER_DELIVERY' },
  { name: 'Other Order', value: 'OTHER' },
]

export const getInitialFiltersState = (history, configuredPlatforms) => {
  const parsedParams = queryString.parse(history.location.search)
  const defaultPageSize = 20
  const date = parsedParams.date || dateFilters.find(filter => filter.value === 'last30Days').value
  let startDate
  let endDate

  if (parsedParams.startDate && parsedParams.endDate) {
    startDate = moment(parsedParams.startDate, dateFormat)
    endDate = moment(parsedParams.endDate, dateFormat)
  } else {
    let selectedFilter = dateFilters.find(filter => filter.value === date)
    if (!selectedFilter) {
      selectedFilter = dateFilters.find(filter => filter.value === 'last30Days')
    }
    const dateObj = selectedFilter.convert()
    startDate = moment(dateObj.start_date)
    endDate = moment(dateObj.end_date)
  }

  return {
    date,
    startDate,
    endDate,
    platform: parsedParams.platform || platformFilters(configuredPlatforms).find(filter => filter.value === 'all').value,
    rating: parsedParams.rating || ratingFilters.find(filter => filter.value === 'all').value,
    feedbackType: parsedParams.feedbackType || feedbackTypeFilters.find(filter => filter.value === 'all').value,
    search: parsedParams.search || '',
    page: parseInt(parsedParams.page, 10) || 0,
    pageSize: parseInt(parsedParams.pageSize, 10) || defaultPageSize,
    sortKey: parsedParams.sortKey || 'date',
    sortDirection: parsedParams.sortDirection || 'desc',
    campaignIds: parsedParams.campaignIds || [],
  }
}

const filtersReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.DATE_FILTER_CHANGE:
      if (action.value !== 'custom' && action.value !== 'all') {
        const dateObj = dateFilters.find(filter => filter.value === action.value).convert()
        const startDate = moment(dateObj.start_date)
        const endDate = moment(dateObj.end_date)

        return {
          ...state,
          date: action.value,
          startDate,
          endDate,
        }
      }
      return {
        ...state,
        date: action.value,
      }

    case ActionTypes.CUSTOM_RANGE_CHANGE:
      return {
        ...state,
        date: 'custom',
        [action.dateType]: action.dateVal,
      }
    case ActionTypes.PLATFORM_FILTER_CHANGE:
      return {
        ...state,
        platform: action.value,
      }
    case ActionTypes.RATING_FILTER_CHANGE:
      return {
        ...state,
        rating: action.value,
      }
    case ActionTypes.FEEDBACK_TYPE_FILTER_CHANGE:
      return {
        ...state,
        feedbackType: action.value,
      }
    case ActionTypes.SEARCH_FILTER_CHANGE:
      return {
        ...state,
        search: action.value,
      }
    case ActionTypes.REVIEWS_PAGINATION_CHANGE:
      return {
        ...state,
        page: action.page,
        pageSize: action.pageSize,
      }
    case ActionTypes.REVIEWS_SORT_CHANGE:
      return {
        ...state,
        sortKey: action.sortKey,
        sortDirection: action.sortDirection,
      }
    case ActionTypes.CAMPAIGN_IDS_FILTER_CHANGE:
      return {
        ...state,
        campaignIds: action.value,
      }

    default:
      return state
  }
}

export default filtersReducer
