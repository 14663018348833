/* eslint-disable no-param-reassign */
import { CardElement, Elements } from '@stripe/react-stripe-js'
import _ from 'lodash'
import styled from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import Checkbox from 'mgr/lib/forms/Checkbox'
import TextInput from 'mgr/lib/forms/TextInput'
import { ValidatorTypes, InputRestrictions } from 'mgr/lib/forms/TextInput.typed'
import { PhoneTextInput } from 'svr/component-lib/Generic/TextInputs/PhoneNumberInput'
import Shift4Form from 'svr/component-lib/Widget/Payments/Shift4/Shift4Form'
import { useLocales } from '@sevenrooms/core/locales'
import { isFreedomPayHpc } from '../../utils/payments'
import { ActualPaymentLocales } from './ActualPayment.locales'
import AdyenForm from './AdyenForm'
import SaferpayForm from './SaferpayForm'
import type * as PaymentActions from '../../actions/PaymentActions'
import type { ValidateFieldMaps } from '../../containers/BookReservation.types'
import type { CardDetails, Client, ExtendedStripeInstance, Venue } from '../../reducers/BookPaymentSlice.types'

const thisYear = new Date().getFullYear()
const expMonthOptions = _.range(1, 13).map(i => ({ name: `${i}`, value: i }))
// Turns out cards that expire in 50 years are a real thing
const expYearOptions = _.range(thisYear, thisYear + 50).map(i => ({ name: `${i}`, value: i }))

interface CardFormProps {
  actions: typeof PaymentActions
  selectedClient: Client
  validateFieldMap: ValidateFieldMaps['payment']
  formErrors: Record<string, boolean>
  cardDetails: CardDetails
  useGuestProfilePhoneNumber?: boolean
  paymentSystem?: Venue['paymentType']
  stripeInstance: ExtendedStripeInstance
  venue: Venue
  isChargeModal?: boolean
}

export function CardForm({
  actions,
  selectedClient,
  validateFieldMap,
  formErrors,
  cardDetails,
  useGuestProfilePhoneNumber,
  stripeInstance,
  paymentSystem,
  venue,
  isChargeModal,
}: CardFormProps) {
  const { formatMessage } = useLocales()

  const cardHolderNameField = (
    <TextInput
      label={formatMessage(ActualPaymentLocales.cardHolderLabel)}
      placeholder={formErrors.card_holder_name ? formatMessage(ActualPaymentLocales.cardRequiredFieldLong) : ''}
      value={cardDetails.cardHolderName}
      onChange={actions.changeCardHolderName}
      validator={ValidatorTypes.nameRequired}
      ref={e => (validateFieldMap.card_holder_name = e)}
      isValid={!formErrors.card_holder_name}
    />
  )

  const cardNumberField = (
    <TextInput
      label={formatMessage(ActualPaymentLocales.cardNumberLabel)}
      placeholder={formErrors.card_number ? formatMessage(ActualPaymentLocales.cardRequiredFieldLong) : ''}
      inputRestriction={InputRestrictions.integer}
      value={cardDetails.cardHolderNumber}
      onChange={actions.changeCardNumber}
      validator={ValidatorTypes.cardNumber}
      ref={e => (validateFieldMap.card_number = e)}
      isValid={!formErrors.card_number}
    />
  )

  const cardExpMonthField = (
    <DropdownArrowsPicker
      name={formatMessage(ActualPaymentLocales.cardExpMonthLabel)}
      placeholder=""
      choices={expMonthOptions}
      value={cardDetails.cardExpMonth}
      onChangeHandler={actions.changeCardExpMonth}
      useOutsideLabel
      isLightTheme
      validator={ValidatorTypes.cardMonthExp}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={e => (validateFieldMap.exp_month = (e as any)?.instanceRef ?? null)}
      isValid={!formErrors.exp_month}
      style={{
        width: '100%',
        minWidth: '100%',
      }}
    />
  )

  const cardExpYearField = (
    <DropdownArrowsPicker
      name={formatMessage(ActualPaymentLocales.cardExpYearLabel)}
      placeholder=""
      choices={expYearOptions}
      value={cardDetails.cardExpYear}
      onChangeHandler={actions.changeCardExpYear}
      useOutsideLabel
      isLightTheme
      validator={ValidatorTypes.cardYearExp}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={e => (validateFieldMap.exp_year = (e as any)?.instanceRef ?? null)}
      isValid={!formErrors.exp_year}
      style={{
        width: '100%',
        minWidth: '100%',
      }}
    />
  )

  const cardCvvField = (
    <TextInput
      label={formatMessage(ActualPaymentLocales.cardCvvLabel)}
      placeholder={formErrors.card_cvv ? formatMessage(ActualPaymentLocales.cardRequiredFieldShort) : ''}
      inputRestriction={InputRestrictions.integer}
      value={cardDetails.cardCcv}
      onChange={actions.changeCardCcv}
      validator={ValidatorTypes.cardCvv}
      ref={e => (validateFieldMap.card_cvv = e)}
      isValid={!formErrors.card_cvv}
      style={{
        width: '100%',
        minWidth: '100%',
      }}
    />
  )

  const clientsPhone = selectedClient?.phone_number_formatted
  const phoneField = (
    <FieldGroup key="FieldGroup_CardPhoneField">
      <Checkbox
        name="override_payment_phone"
        label={formatMessage(ActualPaymentLocales.cardCvvLabel)}
        on={useGuestProfilePhoneNumber}
        onChange={actions.changeUseGuestProfilePhoneNumber}
      />

      <PhoneTextInput
        disabled={useGuestProfilePhoneNumber}
        label={formatMessage(ActualPaymentLocales.cardCvvLabel)}
        dataTest="sr-slideout-cc-mobile-number"
        country={cardDetails.cardPhoneCountry ?? venue.countryCode}
        displayInitialValueAsLocalNumber={venue.countryCode === selectedClient?.phone_number_locale}
        value={!useGuestProfilePhoneNumber ? cardDetails.cardPhoneNumber : ''}
        onChange={actions.changeCardPhoneNumber}
        onCountryChange={actions.changeCardCountryPhoneNumber}
        placeholder={!useGuestProfilePhoneNumber ? '' : clientsPhone}
        validator={ValidatorTypes.phoneRequired}
        ref={e => (validateFieldMap.cardholder_phone = e)}
        isValid={!formErrors.cardholder_phone}
      />
    </FieldGroup>
  )
  const needPhone = isFreedomPayHpc(venue)

  switch (paymentSystem) {
    case 'STRIPE': {
      return (
        <Elements stripe={stripeInstance} key={stripeInstance._key}>
          <div>
            <div style={{ margin: '15px 12px' }}>{cardHolderNameField}</div>
            <div
              style={{
                padding: '15px 12px',
                border: '1px solid #dedede',
                borderRadius: '4px',
                margin: '15px 12px',
              }}
            >
              <CardElement
                onReady={actions.passStripeElement}
                options={{
                  style: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    base: { paddingLeft: '10px' },
                  },
                }}
              />
            </div>
          </div>
        </Elements>
      )
    }
    case 'SAFERPAY': {
      return <SaferpayForm onValidate={actions.saferpayOnValidate} />
    }
    case 'ADYEN': {
      return <AdyenForm />
    }
    case 'SHIFT_4': {
      return <Shift4Form isSlideOut />
    }
    default: {
      return (
        <>
          <FieldGroup key="FieldGroup_CardHolder">
            <CardInputHolder $isChargeModal={isChargeModal}>{cardHolderNameField}</CardInputHolder>
            <CardInputNumber $isChargeModal={isChargeModal}>{cardNumberField}</CardInputNumber>
            <CardInputMonth $isChargeModal={isChargeModal}>{cardExpMonthField}</CardInputMonth>
            <CardInputYear $isChargeModal={isChargeModal}>{cardExpYearField}</CardInputYear>
            <CardInputCvv $isChargeModal={isChargeModal}>{cardCvvField}</CardInputCvv>
          </FieldGroup>
          {needPhone && phoneField}
        </>
      )
    }
  }
}

const CardInputHolder = styled.div<{ $isChargeModal?: boolean }>`
  float: left;
  margin-right: 2%;
  width: ${props => (props.$isChargeModal ? '49%' : '27%')};
  min-width: ${props => (props.$isChargeModal ? '49%' : '27%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    width: 100%;
    min-width: 100%;
    margin-bottom: 10px;
  }
`

const CardInputNumber = styled.div<{ $isChargeModal?: boolean }>`
  float: left;
  margin-bottom: 12px;
  margin-right: ${props => (props.$isChargeModal ? '0' : '2%')};
  width: ${props => (props.$isChargeModal ? '49%' : '29%')};
  min-width: ${props => (props.$isChargeModal ? '49%' : '29%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    width: 100%;
    min-width: 100%;
    margin-bottom: 10px;
  }
`

const CardInputMonth = styled.div<{ $isChargeModal?: boolean }>`
  height: 40px;
  float: left;
  margin-right: 2%;
  margin-top: 2px;
  min-width: ${props => (props.$isChargeModal ? '22%' : '13%')};
  width: ${props => (props.$isChargeModal ? '22%' : '13%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    min-width: 30%;
    width: 30%;
  }
`

const CardInputYear = styled.div<{ $isChargeModal?: boolean }>`
  height: 40px;
  float: left;
  margin-right: 2%;
  margin-top: 2px;
  min-width: ${props => (props.$isChargeModal ? '22%' : '14%')};
  width: ${props => (props.$isChargeModal ? '22%' : '14%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    min-width: 30%;
    width: 30%;
  }
`

const CardInputCvv = styled.div<{ $isChargeModal?: boolean }>`
  height: 40px;
  float: left;
  margin-top: 2px;
  min-width: ${props => (props.$isChargeModal ? '15%' : '9%')};
  width: ${props => (props.$isChargeModal ? '15%' : '9%')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    min-width: 30%;
    width: 30%;
  }
`

const FieldGroup = styled.div`
  margin: 15px 12px;
  ${props => props.theme.clearFix};
`
