import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import VmsIcons, { StyledIcons } from 'svr/common/VmsIcons'

const Body = styled.div`
  position: fixed;
  left: 120px;
  bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  font: 12px Roboto;
  background-color: ${props =>
    props.level === 'SUCCESS'
      ? props.theme.success
      : props.level === 'WARN'
      ? props.theme.warning
      : props.level === 'ERROR'
      ? props.theme.error
      : '#555'};
  color: ${props => props.theme.white};
  z-index: 2147483640;
  min-height: 54px;
  width: 442px;
  padding: 10px 0;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: ${props => (props.visible ? 'opacity 0.5s ease-in' : 'opacity 0.75s ease-out')};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
`

const Message = styled.span`
  order: 1;
  flex-grow: 2;
  margin: 0 0 0 15px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  text-align: left;
`

const ActionText = styled.span`
  order: 2;
  margin: 0 0 0 15px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: underline;
  cursor: pointer;
`

const CloseButton = styled(StyledIcons.S)`
  order: 3;
  text-align: center;
  margin: 0 15px;
  cursor: pointer;
`

const GlobalNotificationBanner = ({ message, actionText, visible, level, handleAction, handleClose, closeDelayMs }) => {
  if (visible && handleClose && _.isNumber(closeDelayMs)) {
    setTimeout(handleClose, closeDelayMs)
  }

  return (
    <Body {...{ visible, level }} data-test="sr-global-notification-banner">
      <Message data-test="notification-message">{message}</Message>
      {!_.isEmpty(actionText) && (
        <ActionText onClick={handleAction} data-test="sr-global-notification-banner-action-text">
          {actionText}
        </ActionText>
      )}
      <CloseButton onClick={handleClose} data-test="sr-global-notification-close">
        {VmsIcons.Close}
      </CloseButton>
    </Body>
  )
}

GlobalNotificationBanner.propTypes = {
  message: React.PropTypes.string,
  actionText: React.PropTypes.string,
  visible: React.PropTypes.bool,
  handleClose: React.PropTypes.func.isRequired,
  handleAction: React.PropTypes.func.isRequired,
  closeDelayMs: React.PropTypes.number,
  level: React.PropTypes.oneOf(['INFO', 'SUCCESS', 'WARN', 'ERROR']),
}

GlobalNotificationBanner.defaultProps = {
  visible: true,
  handleClose: () => {},
  handleAction: () => {},
  closeDelayMs: 5000,
}

export default GlobalNotificationBanner
