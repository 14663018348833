import { srGet, throwFetchError } from '@sevenrooms/core/api'

const fetchVenueReservationTagGroups = venueId =>
  srGet(`/manager/${venueId}/data/reservationtags`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true })
    .then(throwFetchError)
    .then(response => {
      const { content } = response.payload
      const optionalTags = content.optional_tags
      const tagGroups = content.tag_groups
      return { venueId, optionalTags, tagGroups }
    })

const fetchVenueClientTagGroups = venueId =>
  srGet(`/manager/${venueId}/data/clienttags`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true })
    .then(throwFetchError)
    .then(response => {
      const { content } = response.payload
      const optionalTags = content.optional_tags
      const tagGroups = content.tag_groups
      return { venueId, optionalTags, tagGroups }
    })

const fetchVenueSourceTagGroups = venueId =>
  srGet(`/manager/${venueId}/data/sourcetags`, {}, { skipInterfaceError: true, skipLoadClickBlocker: true })
    .then(throwFetchError)
    .then(response => {
      const { content } = response.payload
      const optionalTags = content.optional_tags
      const tagGroups = content.tag_groups
      return { venueId, optionalTags, tagGroups }
    })

export default {
  fetchVenueReservationTagGroups,
  fetchVenueClientTagGroups,
  fetchVenueSourceTagGroups,
}
