import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'

const initialState = {
  isLoading: false,
  isSlideoutOpen: false,
  isHeaderTextSlideoutOpen: false,
  isHeaderTextSlideoutOpenFromRadioButton: false,
  editButtonId: undefined,
  data: { buttons: { primary: [], secondary: [] } },
}

const landingPageSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_LANDING_PAGE_DATA_START:
      return { ...state, isLoading: true }
    case ActionTypes.LOAD_LANDING_PAGE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.landingPageSettingsData,
        venueProfile: action.venueProfile,
      }
    case ActionTypes.SAVE_LANDING_PAGE_DATA_START:
      return { ...state, isLoading: true }
    case ActionTypes.SAVE_LANDING_PAGE_DATA_SUCCESS:
      return { ...state, ...action.nextState, isLoading: false }
    case ActionTypes.SAVE_LANDING_PAGE_DATA_FAIL:
      return state
    case ActionTypes.REMOVE_LANDING_PAGE_BUTTON:
      return {
        ...state,
        data: {
          ...state.data,
          buttons: {
            primary: state.data.buttons.primary.filter(b => b.id !== action.id),
            secondary: state.data.buttons.secondary.filter(b => b.id !== action.id),
          },
        },
      }
    case ActionTypes.EDIT_LANDING_PAGE_BUTTON:
      return {
        ...state,
        isSlideoutOpen: false,
        editButtonId: undefined,
        data: {
          ...state.data,
          buttons: {
            primary: state.data.buttons.primary.map(b => (b.id === action.button.id ? action.button : b)),
            secondary: state.data.buttons.secondary.map(b => (b.id === action.button.id ? action.button : b)),
          },
        },
      }
    case ActionTypes.LANDING_PAGE_ADD_BUTTON:
      return {
        ...state,
        isSlideoutOpen: false,
        editButtonId: undefined,
        data: {
          ...state.data,
          buttons: {
            primary: action.button.primary ? state.data.buttons.primary.concat([action.button]) : state.data.buttons.primary,
            secondary: action.button.primary ? state.data.buttons.secondary : state.data.buttons.secondary.concat([action.button]),
          },
        },
      }
    case ActionTypes.TOGGLE_SETTING:
      return {
        ...state,
        data: {
          ...state.data,
          [action.settingName]: !state.data[action.settingName],
        },
      }
    case ActionTypes.UPDATE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT_AND_TOGGLE:
      return {
        ...state,
        isHeaderTextSlideoutOpen: false,
        isHeaderTextSlideoutOpenFromRadioButton: false,
        data: {
          ...state.data,
          show_header_text: true,
          header_text: action.newText,
        },
      }
    case ActionTypes.UPDATE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT:
      return {
        ...state,
        isHeaderTextSlideoutOpen: false,
        data: {
          ...state.data,
          show_header_text: action.newText ? state.data.show_header_text : false,
          header_text: action.newText,
        },
      }
    case ActionTypes.TOGGLE_LANDING_PAGE_SLIDEOUT:
      return {
        ...state,
        editButtonId: state.isSlideoutOpen ? undefined : state.editButtonId,
        isHeaderTextSlideoutOpenFromRadioButton: false,
        isSlideoutOpen: !state.isSlideoutOpen,
      }
    case ActionTypes.TOGGLE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT:
      return {
        ...state,
        isHeaderTextSlideoutOpen: !state.isHeaderTextSlideoutOpen,
      }
    case ActionTypes.TOGGLE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT_FROM_RADIO_BUTTON:
      return {
        ...state,
        isHeaderTextSlideoutOpen: !state.isHeaderTextSlideoutOpen,
        isHeaderTextSlideoutOpenFromRadioButton: true,
      }
    case ActionTypes.TOGGLE_LANDING_PAGE_SLIDEOUT_EDIT_BUTTON:
      return {
        ...state,
        isSlideoutOpen: !state.isSlideoutOpen,
        editButtonId: action.id,
      }
    case ActionTypes.REORDER_LANDING_PAGE_BUTTONS:
      if (!action.reorder.destination) {
        return state
      }

      const buttons = {
        primary: state.data.buttons.primary.slice(),
        secondary: state.data.buttons.secondary.slice(),
      }

      if (action.reorder.destination.droppableId === action.reorder.source.droppableId) {
        const toChange = buttons[action.reorder.destination.droppableId]
        const toMove = toChange.splice(action.reorder.source.index, 1)[0]

        toChange.splice(action.reorder.destination.index, 0, toMove)
      } else {
        let toMove = buttons[action.reorder.source.droppableId].splice(action.reorder.source.index, 1)[0]

        toMove = {
          ...toMove,
          primary: action.reorder.destination.droppableId === 'primary',
        }

        buttons[action.reorder.destination.droppableId].splice(action.reorder.destination.index, 0, toMove)
      }

      return {
        ...state,
        data: {
          ...state.data,
          buttons,
        },
      }
    default:
      return state
  }
}

export default landingPageSettingsReducer
