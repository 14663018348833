import React from 'react'
import DropdownArrowsPicker from './DropdownArrowsPicker'
import _ from 'lodash'

const ShiftDropDown = ({
  shiftCategory,
  shiftPersistentId,
  placeholder,
  shifts,
  onChangeShift,
  width,
  style,
  showNavigation,
  isLightTheme,
  isDisabled,
}) => {
  const handleShiftChange = value => {
    const shift = _.find(shifts, { persistent_id: value })
    onChangeShift(shift)
  }
  const choices = !_.isEmpty(shifts) ? shifts.map(shift => ({ name: shift.name, value: shift.persistent_id })) : []

  let value = shiftPersistentId
  if (!value) {
    const shift = _.find(shifts, { category: shiftCategory })
    value = shift ? shift.persistent_id : ''
  }

  return (
    <DropdownArrowsPicker
      name="SHIFT"
      choices={choices}
      value={value}
      placeholder={placeholder}
      onChangeHandler={handleShiftChange}
      style={{
        float: 'left',
        width,
        ...style,
      }}
      disabled={isDisabled}
      showNavigation={showNavigation}
      isLightTheme={isLightTheme}
    />
  )
}

ShiftDropDown.defaultProps = {
  showNavigation: true,
  isLightTheme: false,
  width: 230,
  style: {},
  onChangeShift: () => {},
  isDisabled: false,
}

ShiftDropDown.propTypes = {
  shiftCategory: React.PropTypes.string,
  shiftPersistentId: React.PropTypes.string,
  shifts: React.PropTypes.array.isRequired,
  showNavigation: React.PropTypes.bool.isRequired,
  placeholder: React.PropTypes.string,
  isLightTheme: React.PropTypes.bool.isRequired,
  width: React.PropTypes.number.isRequired,
  style: React.PropTypes.object.isRequired,
  onChangeShift: React.PropTypes.func.isRequired,
  isDisabled: React.PropTypes.bool.isRequired,
}

export default ShiftDropDown
