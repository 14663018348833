import { defineMessages } from '@sevenrooms/core/locales'

export const excludeFromShiftPacingLocales = defineMessages({
  editLabel: {
    id: 'actual.excludeFromShiftPacing.editLabel',
    defaultMessage: 'Do not count this reservation towards Shift Pacing',
  },
  viewLabel: {
    id: 'actual.excludeFromShiftPacing.viewLabel',
    defaultMessage: 'This reservation does not count towards Shift Pacing',
  },
  tooltipTitle: {
    id: 'actual.excludeFromShiftPacing.tooltipTitle',
    defaultMessage: 'Do not count this reservation towards Shift Pacing',
  },
  tooltipBody: {
    id: 'actual.excludeFromShiftPacing.tooltipBody',
    defaultMessage:
      'This setting prevents large groups from limiting your availability. For instance, if your Shift Pacing is 30 covers per seating interval and you accept a reservation for 30 but wish to take more bookings for that seating interval, apply this setting. This excludes the reservation from Shift Pacing but doesn’t affect Access Rule Pacing.',
  },
} as const)
