import React from 'react'
import styled from 'styled-components'
import Media from './Media'

const css = {
  auto: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  `,
  0: `
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  `,
  1: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
        -ms-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  `,
  2: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
        -ms-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  `,
  3: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  `,
  4: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
        -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `,
  5: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
        -ms-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  `,
  6: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  `,
  7: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
        -ms-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  `,
  8: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
        -ms-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  `,
  9: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  `,
  10: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
        -ms-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  `,
  11: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
        -ms-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  `,
  12: `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  `,
  offset: {
    0: 'margin-left: 0%;',
    1: 'margin-left: 8.333333%;',
    2: 'margin-left: 16.666667%;',
    3: 'margin-left: 25%;',
    4: 'margin-left: 33.333333%;',
    5: 'margin-left: 41.666667%;',
    6: 'margin-left: 50%;',
    7: 'margin-left: 58.333333%;',
    8: 'margin-left: 66.666667%;',
    9: 'margin-left: 75%;',
    10: 'margin-left: 83.333333%;',
    11: 'margin-left: 91.666667%;',
  },
  push: {
    0: 'left: auto;',
    1: 'left: 8.333333%;',
    2: 'left: 16.666667%;',
    3: 'left: 25%;',
    4: 'left: 33.333333%;',
    5: 'left: 41.666667%;',
    6: 'left: 50%;',
    7: 'left: 58.333333%;',
    8: 'left: 66.666667%;',
    9: 'left: 75%;',
    10: 'left: 83.333333%;',
    11: 'left: 91.666667%;',
    12: 'left: 100%;',
  },
  pull: {
    0: 'right: 0%;',
    1: 'right: 8.333333%;',
    2: 'right: 16.666667%;',
    3: 'right: 25%;',
    4: 'right: 33.333333%;',
    5: 'right: 41.666667%;',
    6: 'right: 50%;',
    7: 'right: 58.333333%;',
    8: 'right: 66.666667%;',
    9: 'right: 75%;',
    10: 'right: 83.333333%;',
    11: 'right: 91.666667%;',
    12: 'right: 100%;',
  },
  hidden: 'display: none !important;',
}

const buildCSS = (sidePadding, colVal, offsetVal, pushVal, pullVal) => {
  let buildCss = `
    padding-right: ${props => (props.sidePadding ? props.sidePadding : '15px')};
    padding-left: ${props => (props.sidePadding ? props.sidePadding : '15px')};
  `
  if (colVal) {
    buildCss = `
      ${buildCss}
      ${css[colVal]}
    `
  }
  if (offsetVal) {
    buildCss = `
      ${buildCss}
      ${css.offset[offsetVal]}
    `
  }
  if (pushVal) {
    buildCss = `
      ${buildCss}
      ${css.push[pushVal]}
    `
  }
  if (pullVal) {
    buildCss = `
      ${buildCss}
      ${css.pull[pullVal]}
      `
  }
  return buildCss
}

// See https://github.com/styled-components/styled-components/issues/439
// for why we can't just do styled.div
const Col = styled(
  ({
    children,
    sidePadding,
    xs,
    xsOffset,
    xsPush,
    sm,
    smOffset,
    smPush,
    md,
    mdOffset,
    mdPush,
    lg,
    lgOffset,
    lgPush,
    xl,
    xlOffset,
    xlPush,
    ...rest
  }) => <div {...rest}>{children}</div>
)`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: ${props => (props.sidePadding ? props.sidePadding : '15px')};
  padding-left: ${props => (props.sidePadding ? props.sidePadding : '15px')};
  ${props => (props.xs ? Media.xs`${buildCSS(props.sidePadding, props.xs, props.xsOffset, props.xsPush)}` : null)}
  ${props => (props.sm ? Media.sm`${buildCSS(props.sidePadding, props.sm, props.smOffset, props.smPush)}` : null)}
  ${props => (props.md ? Media.md`${buildCSS(props.sidePadding, props.md, props.mdOffset, props.mdPush)}` : null)}
  ${props => (props.lg ? Media.lg`${buildCSS(props.sidePadding, props.lg, props.lgOffset, props.lgPush)}` : null)}
  ${props => (props.xl ? Media.xl`${buildCSS(props.sidePadding, props.xl, props.xlOffset, props.xlPush)}` : null)}
  ${props => (props.xsHidden ? Media.xsMax`${css.hidden}` : null)}
  ${props => (props.smHidden ? Media.sm`${css.hidden}` : null)}
  ${props => (props.mdHidden ? Media.md`${css.hidden}` : null)}
  ${props => (props.lgHidden ? Media.lg`${css.hidden}` : null)}
  ${props => (props.xlHidden ? Media.xl`${css.hidden}` : null)}
`

export default Col
