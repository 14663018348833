// Formats string into a proper current format
// 1) Allows only 1 `,` or `.` at a time
// 2) Allows only 2 decimal places after `,` or `.`
// 3) If you type a `,` or `.` when one exist already, it will delete the previous one and write one at the end `200,0` + `,` = `2000,`
// 4) Only allow numeric, ',' or '.' characters in string
export function currencyFormatter(value) {
  // both commas and periods can be used as valid decimal separators
  const currentValue = value ? value.replace(/[^0-9.,]/g, '') : ''
  const separatorIndex = Math.max(currentValue.lastIndexOf('.'), currentValue.lastIndexOf(','))
  return separatorIndex >= 0
    ? currentValue.substr(0, separatorIndex).replace(/[^0-9]/g, '') + currentValue.substr(separatorIndex, 3)
    : currentValue
}
