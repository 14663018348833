/* eslint-disable camelcase */
import _ from 'lodash'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import type { ProductInventoryAction } from 'mgr/pages/single-venue/settings/actions/ordering/ProductInventoryActions'
import type {
  ModifierGroups,
  Modifiers,
  PRODUCT_TYPES,
  PRODUCT_TYPES_ALL,
  ProductTags,
} from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type {
  MenuItem,
  ModifiersGroupItem,
  ProductInventoryItem,
} from 'mgr/pages/single-venue/settings/types/ordering/ProductInventory.types'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

export interface ProductInventoryState {
  isLoading: boolean
  isDirty: boolean
  isCropImageModalOpen: boolean
  isCreateProductModalOpen: boolean
  list: ProductInventoryItem[]
  product: ProductInventoryItem
  productFilterSearchValue: string
  productStateId: PRODUCT_TYPES_ALL
  modifiers: Modifiers
  modifierGroups: ModifierGroups
  productTags: ProductTags
  sorted: SortColumn[]
}

const productInitialState: Record<PRODUCT_TYPES, ProductInventoryItem> = {
  MENU_ITEM: {
    id: '',
    type: 'MENU_ITEM',
    price: 0,
    taxRate: 0,
  },
  MODIFIER_GROUP: {
    id: '',
    type: 'MODIFIER_GROUP',
    minAmount: 1,
    maxAmount: 1,
    allowOrderSameModifiers: false,
    maxSameModifiersAmount: 1,
    modifierItemIds: [],
  },
  MODIFIER: {
    id: '',
    type: 'MODIFIER',
    price: 0,
    taxRate: 0,
  },
}

const initialState: ProductInventoryState = {
  isLoading: false,
  isCreateProductModalOpen: false,
  isCropImageModalOpen: false,
  isDirty: false,
  list: [],
  product: {} as ProductInventoryItem,
  productFilterSearchValue: '',
  productStateId: 'ALL',
  modifiers: {},
  modifierGroups: {},
  productTags: {
    allergen: [],
    non_allergen: [],
  },
  sorted: [
    {
      id: 'name',
      desc: false,
    },
    {
      id: 'displayId',
      desc: false,
    },
  ],
}

const processAllowOrderSameModifiers = (allowOrderSameModifiers: boolean, maxAmount: number | null, minAmount: number | null): boolean =>
  allowOrderSameModifiers ? (maxAmount !== 0 && maxAmount !== 1) || (minAmount !== 0 && minAmount !== 1) : allowOrderSameModifiers

const processMaxSameModifiersAmount = (maxAmount: number | null, maxSameModifiersAmount: number | null): number | null =>
  maxAmount && (!maxSameModifiersAmount || maxAmount < maxSameModifiersAmount) ? maxAmount : maxSameModifiersAmount

const orderingProductInventory = (state: ProductInventoryState = initialState, action: ProductInventoryAction) => {
  const modifiersGroup = state.product as ModifiersGroupItem
  switch (action.type) {
    case ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_START:
      return {
        ...state,
        list: [],
        isLoading: true,
      }
    case ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_SUCCESS:
      return {
        ...state,
        list: action.products,
        isLoading: false,
        isDirty: false,
      }
    case ActionTypes.SELECT_PRODUCT_INVENTORY_STATE_TAB:
      return {
        ...state,
        productStateId: action.productStateId,
      }
    case ActionTypes.SET_PRODUCT_INVENTORY_FILTER_SEARCH_VALUE:
      return {
        ...state,
        productFilterSearchValue: action.value,
      }
    case ActionTypes.SET_PRODUCT_INVENTORY_SORTED_COLUMNS:
      return {
        ...state,
        sorted: action.data,
      }
    case ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS:
      return {
        ...state,
        isDirty: false,
        product: action.product,
      }
    case ActionTypes.GET_ORDERING_PRODUCT_INVENTORY_RESOURCES_SUCCESS:
      return {
        ...state,
        modifiers: action.modifiers,
        modifierGroups: action.modifierGroups,
        productTags: action.productTags,
      }
    case ActionTypes.CLEAR_PRODUCT_INVENTORY_DATA:
      return {
        ...state,
        isCreateProductModalOpen: false,
        isDirty: true,
        product: {
          ...initialState.product,
          ...productInitialState[action.productType],
          type: action.productType,
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_NAME:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          name: action.value,
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_DESCRIPTION:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          description: action.value,
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_PRICE:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          price: action.value,
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_PRODUCT_TAGS:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          productTags: action.value,
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          modifierItemIds: action.value,
        },
      }
    case ActionTypes.REMOVE_PRODUCT_INVENTORY_SELECTED_MODIFIER:
      return {
        ...state,
        product: {
          ...state.product,
          modifierItemIds: modifiersGroup.modifierItemIds.filter(modifier => modifier !== action.value),
        },
        isDirty: true,
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS_GROUPS:
      return {
        ...state,
        product: {
          ...state.product,
          modifierGroupsToModifiers: action.groups,
        },
        isDirty: true,
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_SELECTED_MODIFIERS_GROUP:
      /* eslint-disable no-case-declarations */
      const groups = [...((state.product as MenuItem).modifierGroupsToModifiers || [])]
      const groupIndex = _.findIndex(groups, { modifierGroupId: action.groupId })
      const selectedGroup = groups[groupIndex]
      if (selectedGroup) {
        if (_.isNil(action.modifiers)) {
          groups.splice(groupIndex, 1)
        } else {
          selectedGroup.modifierIds = action.modifiers
        }
      } else if (action.modifiers) {
        groups.push({
          modifierGroupId: action.groupId,
          modifierIds: action.modifiers,
        })
      }
      /* eslint-enable no-case-declarations */
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          modifierGroupsToModifiers: groups,
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_TAX_RATE:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          taxRate: action.value,
          taxGroupId: null,
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_TAX_GROUP_ID:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          taxGroupId: action.value,
          taxRate: null,
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_MIN_AMOUNT:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          minAmount: action.value,
          allowOrderSameModifiers: processAllowOrderSameModifiers(
            modifiersGroup.allowOrderSameModifiers,
            modifiersGroup.maxAmount,
            action.value
          ),
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_MAX_AMOUNT:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          maxAmount: action.value,
          allowOrderSameModifiers: processAllowOrderSameModifiers(
            modifiersGroup.allowOrderSameModifiers,
            action.value,
            modifiersGroup.minAmount
          ),
          maxSameModifiersAmount: processMaxSameModifiersAmount(action.value, modifiersGroup.maxSameModifiersAmount),
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_MAX_SAME_MODIFIERS_AMOUNT:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          maxSameModifiersAmount: processMaxSameModifiersAmount(modifiersGroup.maxAmount, action.value),
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_ALLOW_ORDER_SAME_MODIFIERS:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          allowOrderSameModifiers: action.value,
          maxSameModifiersAmount: modifiersGroup.maxAmount,
        },
      }
    case ActionTypes.UPDATE_PRODUCT_INVENTORY_VERIFY_AGE:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          verifyAge: action.value,
        },
      }
    case ActionTypes.UPLOAD_PHOTO_PREVIEW:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          uploadedImageData: {
            ...(state.product as MenuItem).uploadedImageData,
            [`${action.imageType}ImgUrl`]: action.preview,
          },
        },
      }
    case ActionTypes.UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          uploadedImageData: {
            ...(state.product as MenuItem).uploadedImageData,
            [`${action.imageType}ImgUrl`]: `/.h/download/${action.upload.url_key}`,
            [`${action.imageType}ImgBlobKey`]: action.upload.post_key,
          },
        },
      }
    case ActionTypes.CROP_OPEN:
      return {
        ...state,
        isCropImageModalOpen: true,
      }
    case ActionTypes.CROP_CLOSE:
      return {
        ...state,
        isCropImageModalOpen: false,
      }
    case ActionTypes.CROP_PHOTO:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          uploadedImageData: {
            ...(state.product as MenuItem).uploadedImageData,
            [`${action.imageType}ImgCropData`]: action.coords,
          },
        },
      }
    case ActionTypes.CROP_DELETE:
      return {
        ...state,
        isDirty: true,
        product: {
          ...state.product,
          uploadedImageData: {
            ...(state.product as MenuItem).uploadedImageData,
            [`${action.imageType}ImgUrl`]: null,
            [`${action.imageType}ImgBlobKey`]: null,
            [`${action.imageType}ImgCropData`]: null,
            [`${action.imageType}ImgPhotoDict`]: null,
          },
        },
      }
    case ActionTypes.OPEN_CREATE_PRODUCT_MODAL:
      return {
        ...state,
        isCreateProductModalOpen: true,
      }
    case ActionTypes.CLOSE_CREATE_PRODUCT_MODAL:
      return {
        ...state,
        isCreateProductModalOpen: false,
      }
    case ActionTypes.SAVE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS:
      return {
        ...state,
        isDirty: false,
      }
    case ActionTypes.CREATE_ORDERING_PRODUCT_INVENTORY_ITEM_SUCCESS:
      return {
        ...state,
        isDirty: false,
      }
    default:
      return state
  }
}

export default orderingProductInventory
