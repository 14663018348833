import { useMemo } from 'react'
import { SMSCampaignTemplateCategoryEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { marketingCommonMessages } from '../locales'

export function useOneTimeSMSCampaignTemplateCategories() {
  const { formatMessage } = useLocales()

  return useMemo<Array<SelectOption<SMSCampaignTemplateCategoryEnum>>>(
    () => [
      {
        id: SMSCampaignTemplateCategoryEnum.SPECIAL_OCCASION,
        label: formatMessage(marketingCommonMessages.specialOccasion),
      },
      {
        id: SMSCampaignTemplateCategoryEnum.EVENT,
        label: formatMessage(marketingCommonMessages.event),
      },
      {
        id: SMSCampaignTemplateCategoryEnum.ANNOUNCEMENT,
        label: formatMessage(marketingCommonMessages.announcement),
      },
      {
        id: SMSCampaignTemplateCategoryEnum.STAFF_HIGHLIGHT,
        label: formatMessage(marketingCommonMessages.staffHighlight),
      },
      {
        id: SMSCampaignTemplateCategoryEnum.SURPRISE_DELIGHT,
        label: formatMessage(marketingCommonMessages.surpriseDelight),
      },
      {
        id: SMSCampaignTemplateCategoryEnum.MENU,
        label: formatMessage(marketingCommonMessages.menu),
      },
      {
        id: SMSCampaignTemplateCategoryEnum.ANTICIPATION,
        label: formatMessage(marketingCommonMessages.anticipation),
      },
      {
        id: SMSCampaignTemplateCategoryEnum.LAST_MINUTE,
        label: formatMessage(marketingCommonMessages.lastMinute),
      },
    ],
    [formatMessage]
  )
}
