export const OPEN_SEARCH_MODAL = 'OPEN_SEARCH_MODAL'
export const CLOSE_SEARCH_MODAL = 'CLOSE_SEARCH_MODAL'
export const BEGIN_CLIENT_SEARCH_RESULT_FETCH = 'BEGIN_CLIENT_SEARCH_RESULT_FETCH'
export const END_CLIENT_SEARCH_RESULT_FETCH = 'END_CLIENT_SEARCH_RESULT_FETCH'
export const CLIENT_SEARCH_RESULT_FETCH_ERROR = 'CLIENT_SEARCH_RESULT_FETCH_ERROR'
export const BEGIN_BOOKING_SEARCH_RESULT_FETCH = 'BEGIN_BOOKING_SEARCH_RESULT_FETCH'
export const END_BOOKING_SEARCH_RESULT_FETCH = 'END_BOOKING_SEARCH_RESULT_FETCH'
export const BOOKING_SEARCH_RESULT_FETCH_ERROR = 'BOOKING_SEARCH_RESULT_FETCH_ERROR'
export const DISPATCH_MULTI_SEARCH = 'DISPATCH_MULTI_SEARCH'
export const INIT = 'INIT'
export const CHOOSE_TAB = 'CHOOSE_TAB'
export const UPDATE_SEARCH_SHIFT = 'UPDATE_SEARCH_SHIFT'
export const UPDATE_SEARCH_TIME = 'UPDATE_SEARCH_TIME'
