import _ from 'lodash'
import React, { useCallback } from 'react'
import FroalaReactEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import {
  type ParentTemplateUpdate,
  type ParentTemplateUpdatedFields,
  useAcceptUpdatesFromParentTemplateMutation,
  useDismissUpdatesFromParentTemplateMutation,
} from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  DividerLine,
  Flex,
  HStack,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  notify,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import type { EmailUpdateTypes } from '../../typings'

export interface EmailUpdatesModalProps {
  type: EmailUpdateTypes
  updatedAt: DateOnly | undefined
  campaignId: string
  updates: ParentTemplateUpdatedFields | undefined
  onConfirm: () => void
}

export function EmailUpdatesModal({ type, updatedAt, updates, campaignId, onConfirm }: EmailUpdatesModalProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueKey, venueId } = useVenueContext()
  const [acceptUpdates, { isLoading: isAcceptUpdatesLoading }] = useAcceptUpdatesFromParentTemplateMutation()
  const [dismissUpdates, { isLoading: isDismissUpdatesLoading }] = useDismissUpdatesFromParentTemplateMutation()

  const disableActions: boolean = isAcceptUpdatesLoading || (type === 'manual' && isDismissUpdatesLoading)

  const onClose = useCallback(() => {
    nav.closeSurface(
      nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.editEmail.updatesModal, {
        params: { venueKey, campaignId },
      })
    )

    if (type === 'auto') {
      onConfirm()
    }
  }, [nav, campaignId, venueKey, onConfirm, type])

  const handleAcceptChanges = useCallback(async () => {
    try {
      await acceptUpdates({ venueId, campaignId })
      notify({
        content: formatMessage(emailBuilderMessages.acceptUpdatesFromTemplateSuccess),
        type: 'success',
      })
      onClose()
      onConfirm()
    } catch {
      notify({
        content: formatMessage(emailBuilderMessages.acceptUpdatesFromTemplateError),
        type: 'error',
      })
    }
  }, [acceptUpdates, campaignId, formatMessage, onClose, venueId, onConfirm])

  const handleDismissUpdates = useCallback(async () => {
    try {
      await dismissUpdates({ venueId, campaignId })
      notify({
        content: formatMessage(emailBuilderMessages.dismissUpdatesFromTemplateSuccess),
        type: 'success',
      })
      onClose()
      onConfirm()
    } catch {
      notify({
        content: formatMessage(emailBuilderMessages.dismissUpdatesFromTemplateError),
        type: 'error',
      })
    }
  }, [campaignId, dismissUpdates, formatMessage, onClose, venueId, onConfirm])

  const renderUpdate = useCallback(
    (update: ParentTemplateUpdate, isLast: boolean, key: string, listIndex?: number) => {
      const updateKey = listIndex === undefined ? key : [key, `${listIndex + 1}`].join(' ')
      return (
        update && (
          <Box key={updateKey} backgroundColor="secondaryBackground" mb={isLast ? 'none' : 'lm'}>
            <Box backgroundColor="margin" pl="m" pr="m" pt="s" pb="s" borderTopLeftRadius="s" borderTopRightRadius="s">
              <Text fontWeight="bold">{_.startCase(updateKey)}</Text>
            </Box>

            <HStack pl="m" pr="m" pt="m" pb="m">
              <Flex flexBasis="140px" flexShrink={0}>
                <Text fontWeight="bold">
                  {formatMessage(type === 'manual' ? emailBuilderMessages.currentCopy : emailBuilderMessages.previousCopy)}
                </Text>
              </Flex>

              <Box>
                <Text fontSize="m">
                  <FroalaReactEditorView model={_.toString(update.oldValue) || ''} />
                </Text>
              </Box>
            </HStack>

            <DividerLine margin="none" />

            <HStack pl="m" pr="m" pt="m" pb="m" borderBottomLeftRadius="s" borderBottomRightRadius="s">
              <Flex flexBasis="140px" flexShrink={0}>
                <Text fontWeight="bold">
                  {formatMessage(type === 'manual' ? emailBuilderMessages.proposedChanges : emailBuilderMessages.currentCopy)}
                </Text>
              </Flex>

              <Box>
                <Text fontSize="m">
                  <FroalaReactEditorView model={_.toString(update.newValue) || ''} />
                </Text>
              </Box>
            </HStack>
          </Box>
        )
      )
    },
    [formatMessage, type]
  )

  const fieldUpdatesToDisplay = [
    'campaignName',
    'emailReplyTo',
    'emailSender',
    'emailSubject',
    'previewText',
    'greeting',
    'emailBodyParts',
    'signature',
    'signoff',
    'footer',
  ]
  const fieldUpdatesToDisplaySet = new Set(fieldUpdatesToDisplay)

  const updatesMap: Array<[string, ParentTemplateUpdatedFields]> = _.toPairs(_.omitBy(updates, _.isNil)).filter(([key]) =>
    fieldUpdatesToDisplaySet.has(key)
  )

  // eslint-disable-next-line newline-per-chained-call
  const updatesCount = _.chain(updates).omitBy(_.isNil).pick(fieldUpdatesToDisplay).values().flatten().size().value()

  return (
    <Modal ariaLabel={formatMessage(emailBuilderMessages.updatesModalTitle)}>
      <ModalHeader onClose={onClose}>
        <VStack spacing="s">
          <ModalTitle
            title={formatMessage(emailBuilderMessages.updatesModalTitle)}
            subTitle={`${formatMessage(emailBuilderMessages.updatesModalDescription)} ${updatedAt?.formatNYearNMonthNDay()}`}
          />
        </VStack>
      </ModalHeader>

      <ModalBody>
        <DividerLine margin="none" />

        <Box pt="m" pb="m">
          <Text fontWeight="bold">{formatMessage(emailBuilderMessages.totalUpdates)}</Text>
          &nbsp;
          <Text>{updatesCount}</Text>
        </Box>
        {updatesMap.map(([key, update], index) => {
          const isLast = index === updatesCount - 1
          if (_.isArray(update)) {
            return (
              <React.Fragment key={key}>
                {update.map(listUpdate => renderUpdate(listUpdate, isLast, key, listUpdate.bodyPartIndex))}
              </React.Fragment>
            )
          }
          return renderUpdate(update as ParentTemplateUpdate, isLast, key)
        })}
      </ModalBody>

      {type === 'manual' ? (
        <ModalFooter>
          <ModalActions>
            <Button variant="tertiary" onClick={onClose} disabled={disableActions} data-test="cancel-button">
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button variant="secondary-warning" onClick={handleDismissUpdates} disabled={disableActions} data-test="dismiss-button">
              {formatMessage(emailBuilderMessages.doNotUpdate)}
            </Button>
            <Button variant="primary" onClick={handleAcceptChanges} disabled={disableActions} data-test="accept-button">
              {_.size(updates) > 1 ? formatMessage(commonMessages.updateAll) : formatMessage(commonMessages.update)}
            </Button>
          </ModalActions>
        </ModalFooter>
      ) : (
        <Box mt="lm" />
      )}
    </Modal>
  )
}
