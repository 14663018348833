import { css } from 'styled-components'

// Modeled off of bootstrap v4
const sizes = {
  xl: 1200, // Extra large devices (large desktops, 1200px and up)
  lg: 992, // Large devices (desktops, 992px and up)
  md: 768, // Medium devices (tablets, 768px and up)
  sm: 576, // Small devices (landscape phones, 576px and up)
  // Extra small devices (portrait phones, less than 576px)
}

const media = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

// xs is default
media.xs = (...args) => css`
  ${css(...args)};
`

media.xsMax = (...args) => css`
  @media (max-width: ${sizes.sm - 1}px) {
    ${css(...args)};
  }
`

export default media
