import { srGet, srPost, srUpload } from '@sevenrooms/core/api'
import { CROP_CLOSE, CROP_DELETE, CROP_OPEN, CROP_PHOTO, UPLOAD_PHOTO_PREVIEW, UPLOAD_PHOTO_SUCCESS } from './ActionTypes'

export const requestLightSpeedConnection = venueId =>
  srGet(`/manager/${venueId}/lightspeed/is_connected`).then(response => response.lightspeed_is_connected)

export const requestTestLightSpeedConnection = venueId =>
  srGet(`/admin/venue/${venueId}/joint-test-connection`, {
    integration_type: 'lightspeed',
  }).then(response => {
    if (response.statusCode === 200) {
      return response
    }
    if (response.errorMsg.payload === undefined) {
      const objectError = {
        message: 'Failed to initiate test connection',
        status: 'failure',
      }
      throw objectError
    }
    throw response.errorMsg.payload
  })

export const requestKountaConnection = venueId =>
  srGet(`/manager/${venueId}/kounta/is_connected`).then(response => response.kounta_is_connected)

export const requestTestKountaConnection = venueId =>
  srGet(`/admin/venue/${venueId}/joint-test-connection`, {
    integration_type: 'kounta',
  }).then(response => {
    if (response.statusCode === 200) {
      return response
    }
    if (response.errorMsg.payload === undefined) {
      const objectError = {
        message: 'Failed to initiate test connection',
        status: 'failure',
      }
      throw objectError
    }
    throw response.errorMsg.payload
  })

const getUrlForImport = (venueId, importType, flatFileVersion = '') =>
  importType === 'client'
    ? `/api-yoa/client-import/${venueId}/${flatFileVersion}`
    : `/api-yoa/reservation-import/${venueId}/${flatFileVersion}`

export const requestInitialData = (venueId, importType, flatFileVersion) => srGet(getUrlForImport(venueId, importType, flatFileVersion))

export const submitImportFile = (
  venueId,
  importType,
  blobstoreUploadKey,
  customTagFields,
  mappedTags,
  newTagGroupsToSubmit,
  dateFormat,
  futureTableAndSeatingAreaOverride
) =>
  srPost(getUrlForImport(venueId, importType), {
    blobstore_upload_key: blobstoreUploadKey,
    custom_tag_fields: JSON.stringify(customTagFields),
    mapped_tags: JSON.stringify(mappedTags),
    new_tag_groups: JSON.stringify(newTagGroupsToSubmit),
    date_format: dateFormat,
    future_table_and_seating_area_override: futureTableAndSeatingAreaOverride,
  })

export const uploadFile = file =>
  srPost('/upload-url', { rurl: Math.random().toString() }, { convertToGetParams: true }).then(response =>
    response.error ? response : srUpload(response.upload_url, file, { file_param: 'import_file' })
  )

export const uploadPhotoPreview = (imageType, preview) => ({
  type: UPLOAD_PHOTO_PREVIEW,
  imageType,
  preview,
})

export const uploadPhotoSuccess = (imageType, upload) => ({
  type: UPLOAD_PHOTO_SUCCESS,
  imageType,
  upload,
})

export const uploadPhoto = (imageType, file) => dispatch => {
  if (!['image/jpeg', 'image/png', 'image/gif', 'image/tiff'].includes(file.type)) {
    window.Interface._alert('Image type not acceptable')
    return undefined
  }
  dispatch(uploadPhotoPreview(imageType, file.preview))
  dispatch(cropOpen(imageType))

  return srPost('/upload-url', { rurl: Math.random().toString() }, { convertToGetParams: true })
    .then(response => (response.error ? response : srUpload(response.upload_url, file)))
    .then(uploadData => {
      dispatch(uploadPhotoSuccess(imageType, uploadData))
    })
}

export const cropOpen = imageType => ({ type: CROP_OPEN, imageType })

export const cropImage = (imageType, coords) => ({
  type: CROP_PHOTO,
  imageType,
  coords,
})

export const closeCrop = imageType => ({ type: CROP_CLOSE, imageType })

export const cropDelete = imageType => ({ type: CROP_DELETE, imageType })
