import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import BookStepComponent from 'mgr/actualslideout/components/BookStepComponent'
import { SourceBodyDisplay } from 'mgr/actualslideout/components/source/SourceLookupResult'
import { ClearFix } from 'mgr/layout/StyledComponentUtils'
import { VmsIcons } from 'svr/common/VmsIcons'
import SelectedSourceForm from './source/SelectedSourceForm'
import SourceLookup from './source/SourceLookup'

const SourceStep = ({ actions, isCollapsed, scrollIntoView, selectedSource, validateFieldMaps }) => {
  const hasSelection = _.isObject(selectedSource)
  const validateFieldMap = validateFieldMaps.source

  validateFieldMap.selected_source = {
    isValid: () => true,
  }

  const onToggle = () => {
    actions.toggleStep('source')
  }
  let searchInputRef
  const returnSearchInputRef = e => (searchInputRef = e)
  const onCompleteScrollIntoView = () => {
    actions.clearScrollIntoView()
  }
  const onCompleteToggleAnimation = () => {
    if (!searchInputRef) {
      return
    }
    if (isCollapsed) {
      searchInputRef.blur()
    } else {
      searchInputRef.focus()
    }
  }
  const [title, subTitle] = ['Source', null]
  let headerLeft
  let headerRight
  if (isCollapsed && hasSelection) {
    headerLeft = <SourceBodyDisplay client={selectedSource} />
  }

  const renderClientLookup = () => <SourceLookup {...{ actions, returnSearchInputRef }} />
  const renderSelectedClient = () => <SelectedSourceForm {...{ actions, validateFieldMap }} />
  return (
    <BookStepComponent
      {...{
        title,
        subTitle,
        onToggle,
        onCompleteScrollIntoView,
        onCompleteToggleAnimation,
        isCollapsed,
        scrollIntoView,
        headerLeft,
        headerRight,
      }}
      testId="sr-section-source"
      icon={VmsIcons.Source}
      iconLine={VmsIcons.SourceLine}
      isEmpty={!hasSelection}
    >
      <ClearFix>{_.isObject(selectedSource) ? renderSelectedClient() : renderClientLookup()}</ClearFix>
    </BookStepComponent>
  )
}

SourceStep.propTypes = {
  actions: React.PropTypes.object,
  isCollapsed: React.PropTypes.bool,
  scrollIntoView: React.PropTypes.bool,
  selectedSource: React.PropTypes.object,
  validateFieldMaps: React.PropTypes.object.isRequired,
}

SourceStep.defaultProps = {
  actions: {},
}

const mapStateToProps = state => ({
  isCollapsed: state.bookState.isStepCollapsed.source,
  stepHeightChangeTimestamp: state.bookState.stepHeightChangeTimestamps.source,
  scrollIntoView: state.bookState.scrollIntoViewStep === 'source',
  selectedSource: state.bookSourceState.selectedSource,
})

export default connect(mapStateToProps)(SourceStep)
