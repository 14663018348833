import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import BookStepComponent from 'mgr/actualslideout/components/BookStepComponent'
import { ClientCheckinDisplay } from 'mgr/actualslideout/components/client/ClientHotelLookupResult'
import { ClientBodyDisplay, ClientStatsDisplay } from 'mgr/actualslideout/components/client/ClientLookupResult'
import { ClearFix } from 'mgr/layout/StyledComponentUtils'
import { VmsIcons } from 'svr/common/VmsIcons'
import ClientLookup from './client/ClientLookup'
import SelectedClientForm from './client/SelectedClientForm'

const ClientStep = ({ venue, actions, isCollapsed, scrollIntoView, selectedClient, validateFieldMaps, canViewClientSpendData }) => {
  const validateFieldMap = validateFieldMaps.client
  validateFieldMap.selected_client = {
    isValid: () => (_.isNil(selectedClient) ? 'Please select a client' : true),
  }
  const hasSelection = _.isObject(selectedClient)
  const onToggle = () => {
    actions.toggleStep('client')
  }
  let searchInputRef
  const returnSearchInputRef = e => (searchInputRef = e)
  const onCompleteScrollIntoView = () => {
    actions.clearScrollIntoView()
  }
  const onCompleteToggleAnimation = () => {
    if (!searchInputRef) {
      return
    }
    if (isCollapsed) {
      searchInputRef.blur()
    } else {
      searchInputRef.focus()
    }
  }
  const [title, subTitle] = ['Client', null]
  let headerLeft
  let headerRight
  if (isCollapsed && hasSelection) {
    headerLeft = <ClientBodyDisplay client={selectedClient} />
    headerRight = _.isNil(selectedClient.hotel_client) ? (
      <ClientStatsDisplay client={selectedClient} canViewClientSpendData={canViewClientSpendData} />
    ) : (
      <ClientCheckinDisplay client={selectedClient.hotel_client} />
    )
  }
  const renderClientLookup = () => <ClientLookup {...{ venue, actions, returnSearchInputRef, canViewClientSpendData }} />
  const renderSelectedClient = () => <SelectedClientForm {...{ actions, validateFieldMap }} />
  return (
    <BookStepComponent
      {...{
        title,
        subTitle,
        onToggle,
        onCompleteScrollIntoView,
        onCompleteToggleAnimation,
        isCollapsed,
        scrollIntoView,
        headerLeft,
        headerRight,
      }}
      testId="sr-section-client"
      icon={VmsIcons.Client}
      iconLine={VmsIcons.ClientLine}
      isEmpty={!hasSelection}
    >
      <ClearFix>{_.isObject(selectedClient) ? renderSelectedClient() : renderClientLookup()}</ClearFix>
    </BookStepComponent>
  )
}

ClientStep.propTypes = {
  venue: React.PropTypes.object,
  actions: React.PropTypes.object,
  isCollapsed: React.PropTypes.bool,
  scrollIntoView: React.PropTypes.bool,
  selectedClient: React.PropTypes.object,
  validateFieldMaps: React.PropTypes.object.isRequired,
  canViewClientSpendData: React.PropTypes.bool,
}

ClientStep.defaultProps = {
  actions: {},
}

const mapStateToProps = state => ({
  venue: state.bookState.selectedVenue,
  isCollapsed: state.bookState.isStepCollapsed.client,
  stepHeightChangeTimestamp: state.bookState.stepHeightChangeTimestamps.client,
  scrollIntoView: state.bookState.scrollIntoViewStep === 'client',
  selectedClient: state.bookClientState.selectedClient,
  clientResults: state.bookClientState.clientLookupResults,
  canViewClientSpendData: state.bookClientState.initVenue.features.can_view_client_spend_data,
})

export default connect(mapStateToProps)(ClientStep)
