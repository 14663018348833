import React, { PureComponent } from 'react'
import DatePicker from 'react-datepicker'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { VmsIcons } from 'svr/common/VmsIcons'

const DatePickerWrapper = ({ date, today, locale, bookedDates, onChangeHandler }) => (
  <DatePicker inline selected={date} minDate={today} locale={locale} highlightDates={bookedDates} onChange={onChangeHandler} />
)

DatePickerWrapper.defaultProps = {
  date: moment(),
  onChangeHandler: () => {},
}

DatePickerWrapper.propTypes = {
  onChangeHandler: React.PropTypes.func,
}

class AvailabilityDateSelectBar extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleMoveLeft = this.handleMoveLeft.bind(this)
    this.handleMoveRight = this.handleMoveRight.bind(this)
  }

  handleDateChange(newDate) {
    const { date, handleDateChange } = this.props
    if (newDate.isSame(date)) {
      return
    }
    this.refs.dropdownArrowsPicker.getInstance().closeDropdown()
    handleDateChange(newDate)
  }

  handleMoveLeft() {
    const { date, today, handleDateChange } = this.props
    const newDate = moment(date).subtract(1, 'days')
    if (newDate.isBefore(today)) {
      return
    }
    this.refs.dropdownArrowsPicker.getInstance().closeDropdown()
    handleDateChange(newDate)
  }

  handleMoveRight() {
    const { date, handleDateChange } = this.props
    const newDate = moment(date).add(1, 'days')
    this.refs.dropdownArrowsPicker.getInstance().closeDropdown()
    handleDateChange(newDate)
  }

  render() {
    const { date, today, locale, bookedDates } = this.props
    const dateFormat = locale === 'en-US' ? 'dddd, MMMM D, YYYY' : 'dddd, D MMMM YYYY'
    const { isOpen } = this.state
    return (
      <div className="svr-datepicker">
        <DropdownArrowsPicker
          key="DATE"
          name="DATE"
          ref="dropdownArrowsPicker"
          choices={[{ name: date.format(dateFormat), value: 'date' }]}
          value="date"
          onMoveLeft={this.handleMoveLeft}
          onMoveRight={this.handleMoveRight}
          style={{
            float: 'left',
            width: 350,
          }}
          showNavigation
          isLightTheme
          icon={VmsIcons.CalendarLine}
          isOpen={isOpen}
          dropDown={
            <DatePickerWrapper
              date={date}
              today={today}
              locale={locale}
              bookedDates={bookedDates}
              onChangeHandler={this.handleDateChange}
            />
          }
        />
      </div>
    )
  }
}

AvailabilityDateSelectBar.defaultProps = {
  handleDateChange: () => {},
}

export default AvailabilityDateSelectBar
