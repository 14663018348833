import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  align-items: center;
  height: 42px;
  border-radius: 6px;
  max-width: ${props => props.maxWidth}px;
  width: ${props => props.maxWidth}px;
  border: 1px solid ${props => props.theme.color.grey};
  background-color: ${props => props.theme.background};
  overflow: hidden;
  font-family: ${props => props.theme.fontFamily};
`

const HiddenRadioButton = styled.input`
  display: none;
`

const RadioButtonElement = styled.input`
  display: none;

  &:checked + label {
    background-color: ${props => props.theme.primary};
    color: white;
  }

  &:disabled + label {
    background-color: ${props => props.theme.color.grey};
  }
`

const RadioItemLabel = styled.label`
  height: 100%;
  display: block;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  border-right: 1px solid ${props => props.theme.color.grey};
`

const RadioItemContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  max-width: ${props => props.maxItemWidth}%;
`

const RadioGroupStrip = ({ options, selected, groupName, maxWidth, onChange }) => (
  <RadioGroupContainer maxWidth={maxWidth}>
    {options.map(item => {
      const entryId = _.snakeCase(`${groupName}_${item.label}_${item.value}`)
      return (
        <RadioItemContainer key={entryId} maxItemWidth={100 / options.length}>
          <RadioButtonElement
            disabled={item.disabled}
            onChange={() => onChange(item.value)}
            checked={selected === item.value}
            type="radio"
            id={entryId}
            name={groupName}
            value={item.value}
          />
          <RadioItemLabel htmlFor={entryId}>{item.label}</RadioItemLabel>
        </RadioItemContainer>
      )
    })}
  </RadioGroupContainer>
)

RadioGroupStrip.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  groupName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}
export default RadioGroupStrip
