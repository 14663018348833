import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

const SwitchLabel = styled.label`
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
  line-height: 28px;
  vertical-align: top;
  font-size: 15px;
  color: ${props => (props.enabled ? props.theme.navigationDark : props.theme.darkGrey)};
`

const Checkbox = styled.input`
  -webkit-appearance: none;
  background-color: ${props => props.theme.background};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.mediumGrey};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  height: 15px;
  width: 15px;
  font-size: 15px;
  padding: 9px;
  margin: 2px 10px 2px 2px;
  display: inline-block;
  position: relative;

  &:checked {
    background-color: ${props => props.theme.background};
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  &:checked:after {
    content: '\\2714';
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 3px;
    color: ${props => (props.enabled ? props.theme.primary : props.theme.lightGrey)};
  }
`

const RegularCheckbox = ({ testId, name, label, on, enabled, onChange, style }) => {
  // This doesn't need to be perfectly unique, just unique enough for the label.

  const onClick = e => {
    if (enabled) {
      onChange(!on)
    }
  }

  return (
    <div {...{ style }}>
      <SwitchLabel data-test={testId} enabled={enabled}>
        <Checkbox onChange={onClick} enabled={enabled} type="checkbox" name={name} defaultChecked={!!on} />
        {label}
      </SwitchLabel>
    </div>
  )
}

RegularCheckbox.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  on: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  style: PropTypes.object,
}

RegularCheckbox.defaultProps = {
  on: false,
  onChange: () => {},
  enabled: true,
}

export default RegularCheckbox
