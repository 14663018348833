import React, { Component } from 'react'
import styled, { css } from 'styled-components'

const OptionsContainer = styled.div`
  border-radius: ${props => (props.hasBorder ? '3px' : '0')};
  overflow: hidden;
  width: ${props => props.width}px;
  ${props => props.theme.clearFix} ${props => props.styleds};
  border: 1px solid ${props => props.theme.lightGrey};
`

const OptionCellBase = styled.div`
  width: ${props => props.optionWidth}%;
  height: ${props => props.optionHeight}px;
  line-height: ${props => props.optionHeight}px;
  box-sizing: border-box;
  float: left;
  text-align: center;
  cursor: pointer;
  ${props => props.theme.ellipsis};
`

const OptionCellDarkTheme = styled(OptionCellBase)`
  font-size: 12px;
  font-weight: 500;
  border-right: ${props => (props.isLast ? '0' : '1px')} solid ${props => props.theme.lightGrey};
  border-top-right-radius: ${props => (props.isLast ? '3px' : '0')};
  border-bottom-right-radius: ${props => (props.isLast ? '3px' : '0')};
  background-color: ${props => (props.isSelected ? props.theme.primarySelected : props.theme.background)};
  color: ${props => (props.isSelected ? props.theme.white : props.theme.primary)};

  ${props =>
    props.disabled &&
    css`
      background-color: rgb(236, 236, 236);
      color: #999;
      :hover {
        background-color: rgb(236, 236, 236);
        cursor: not-allowed;
      }
    `}
`

const OptionCellLightTheme = styled(OptionCellBase)`
  font-size: 12px;
  font-weight: 500;
  border-right: ${props => (props.isLast ? '0' : '1px')} solid ${props => props.theme.lightGrey};
  background-color: ${props => (props.isSelected ? props.theme.primaryRgba20 : props.theme.background)};
  color: ${props => (props.isSelected ? props.theme.primary : props.theme.darkGrey)};
  :hover {
    background-color: ${props => (props.isSelected ? props.theme.primaryRgba20 : props.theme.primaryRgba10)};
  }
`

const OptionCellNavTheme = styled(OptionCellBase)`
  font-size: 13px;
  font-weight: 900;
  line-height: ${props => 3 + props.optionHeight}px;
  background-color: ${props => props.theme.background};
  border-bottom: ${props => (props.isSelected ? '3px' : '0')} solid ${props => props.theme.navigationDark};
  color: ${props => props.theme.navigationDark};
  opacity: ${props => (props.isSelected ? 1 : 0.4)};
  :hover {
    opacity: ${props => (props.isSelected ? 1 : 0.6)};
  }
`

class SegmentedControl extends Component {
  handleItemClick = (e, value) => {
    this.props.onChangeHandler(value)
  }

  render() {
    const { options, width, height, theme, styleds, value } = this.props
    let OptionCell
    let hasBorder = true
    switch (theme) {
      case 'dark':
        OptionCell = OptionCellDarkTheme
        break
      case 'light':
        OptionCell = OptionCellLightTheme
        break
      case 'nav':
        OptionCell = OptionCellNavTheme
        hasBorder = false
        break
    }
    const optionWidth = 100 / options.length
    const optionHeight = height - (hasBorder ? 2 : 0)
    const renderOptions = () =>
      options.map((option, idx) => {
        const isLast = idx == options.length - 1
        const isSelected = option.value == value
        return (
          <OptionCell
            data-test={option.testId}
            key={option.value}
            {...{ isLast, isSelected, optionWidth, optionHeight }}
            disabled={option.disabled}
            onClick={e => !option.disabled && this.handleItemClick(e, option.value)}
          >
            {option.name}
          </OptionCell>
        )
      })
    return <OptionsContainer {...{ width, hasBorder, styleds }}>{renderOptions()}</OptionsContainer>
  }
}

SegmentedControl.propTypes = {
  options: React.PropTypes.array.isRequired,
  width: React.PropTypes.number.isRequired,
  height: React.PropTypes.number.isRequired,
  theme: React.PropTypes.string.isRequired,
  styleds: React.PropTypes.string,
  onChangeHandler: React.PropTypes.func.isRequired,
}

SegmentedControl.defaultProps = {
  height: 26,
  theme: 'dark',
  onChangeHandler: () => {},
}

export default SegmentedControl
