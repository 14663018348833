import { srGet, throwFetchError } from '@sevenrooms/core/api'
import { BookingAccessOverrideListItemAdapter, OverrideListPayloadAdapter } from '../adapters'
import type { BookingAccessOverrideList } from '../api'
import type { OverrideListPayload } from '../domain'

export namespace BookingAccessOverrideListService {
  export async function getAccessRuleOverrides(args: OverrideListPayload) {
    const { data } = await srGet<BookingAccessOverrideList>(
      `/api-yoa/booking_access/overrides/list`,
      OverrideListPayloadAdapter.toServer(args)
    ).then(throwFetchError)
    return BookingAccessOverrideListItemAdapter.toClient(data)
  }
}
