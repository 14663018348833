import moment from 'moment-timezone'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'

const initialState = {
  isInitialized: false,
  isLoading: true,
  activeIntegrationKey: null,
  editIntegrationSettingsBackup: null,
  integrationSettings: {},
  formErrors: {},
  didDismissErrorDisplay: false,
  supportedIntegrations: [],
}

const deliveryIntegrationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_DELIVERY_INTEGRATION_SETTINGS_START:
    case ActionTypes.SAVE_DELIVERY_INTEGRATION_SETTINGS_START:
    case ActionTypes.DEACTIVATE_DELIVERY_INTEGRATION_START:
      return {
        ...state,
        isLoading: true,
        isInitialized: true,
      }
    case ActionTypes.LOAD_DELIVERY_INTEGRATION_SETTINGS_FAIL:
    case ActionTypes.SAVE_DELIVERY_INTEGRATION_SETTINGS_FAIL:
    case ActionTypes.DEACTIVATE_DELIVERY_INTEGRATION_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.LOAD_DELIVERY_INTEGRATION_SETTINGS_SUCCESS:
      return {
        ...state,
        integrationSettings: {
          ...state.integrationSettings,
          ...action.integrationSettings,
        },
        supportedIntegrations: action.supportedIntegrations,
        isLoading: false,
      }
    case ActionTypes.SELECT_DELIVERY_INTEGRATION_EDIT: {
      const { integrationKey } = action
      return {
        ...state,
        activeIntegrationKey: integrationKey,
        editIntegrationSettingsBackup: {
          ...state.integrationSettings[integrationKey],
        },
      }
    }
    case ActionTypes.CANCEL_DELIVERY_INTEGRATION_EDIT:
      return {
        ...state,
        activeIntegrationKey: null,
        integrationSettings: {
          ...state.integrationSettings,
          [state.activeIntegrationKey]: state.editIntegrationSettingsBackup,
        },
      }
    case ActionTypes.DEACTIVATE_DELIVERY_INTEGRATION_SUCCESS: {
      return {
        ...state,
        integrationSettings: {
          ...state.integrationSettings,
          [action.integrationKey]: {
            ...state.integrationSettings[action.integrationKey],
            enabled: false,
            settings: {},
          },
        },
        isLoading: false,
      }
    }
    case ActionTypes.CHANGE_DELIVERY_INTEGRATION_FIELD: {
      const { integrationKey, field, value } = action
      const formErrors = { ...state.formErrors, [action.field]: false }
      return {
        ...state,
        integrationSettings: {
          ...state.integrationSettings,
          [integrationKey]: {
            ...state.integrationSettings[integrationKey],
            settings: {
              ...state.integrationSettings[integrationKey].settings,
              [field]: value,
            },
          },
        },
        formErrors,
        didDismissErrorDisplay: true,
      }
    }
    case ActionTypes.DELIVERY_INTEGRATION_FORM_VALIDATED: {
      const { formErrors } = action
      return {
        ...state,
        formErrors,
        didDismissErrorDisplay: false,
      }
    }
    case ActionTypes.DELIVERY_INTEGRATION_DISMISS_ERROR_DISPLAY: {
      return { ...state, didDismissErrorDisplay: true }
    }
    case ActionTypes.SAVE_DELIVERY_INTEGRATION_SETTINGS_SUCCESS:
      return {
        ...state,
        integrationSettings: {
          ...state.integrationSettings,
          [state.activeIntegrationKey]: {
            ...state.integrationSettings[state.activeIntegrationKey],
            enabled: action.enabled,
            updated: moment().toISOString().slice(0, -1),
          },
        },
        isLoading: false,
        activeIntegrationKey: null,
      }
    default:
      return state
  }
}

export default deliveryIntegrationsReducer
