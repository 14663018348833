import _ from 'lodash'

const BLANK_STRING = ' '
export const toSentenceCase = word =>
  word
    .replace('_', ' ')
    .toLowerCase()
    .split(' ')
    .filter(word => !_.isEmpty(word))
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ')

export const isBlank = string => _.isEmpty(string) || _.isEmpty(string.trim())

/*
  Contact info extraction algorithm
  1. First extract anything that looks like an email and remove it from the string.
  2. Next extract digits anchored by words (in the RegEx sense boundaries). Look at a RegEx manual
     for more information about word boundary anchors. The short version is that this stage
     extracts digits bounded by things that are not digits. So this expression does not capture
     things like word123word or word123 or 123word since the first is bounded by words on both ends
     and the other two are only bounded at one end which is still enough to not trigger the regex.
  4. Remove the digits, replacing with whitespace.
  5. Remove stray hyphens, + signs and parentheses (in Germany, these are thing for phones)
  6. Normalize everything to be one space apart.
  7. Split everything on whitespaces.
  8. Grab the first word and call it the first name. Concatenate everything else to be one word
     that is assumed to be the last name.
  NOTE: This strips formatting on numbers.
  NOTE: The right RegEx wizard could probably simplify steps 4 through 8

  One advantage of the RegEx based approach is that the order of terms does not matter.
  e.g howard grimberg 913 593 1817 howard@sevenrooms.com should yield the
  same result as 913 593 1817 howard grimberg howard@sevenrooms.com. In fact, it can almost
  parse mixed things like  913 howard 593 grimberg 1817 howard@sevenrooms.co.

  TODO: This could use a good automated fuzz tester.

 */
const EMAIL_REGEX =
  /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g
const PHONE_REGEX = /\b\d+\b/g
const BAD_CHAR_REGEX = /\s+[-()|]/g
const NO_EXTRA_SPACES = /\s+/g

export const splitIntoContactInfo = contactString => {
  if (isBlank(contactString)) {
    return {}
  }

  let contactStringFormatted = contactString.trim()

  // Obtained from the W3C spec somwhere for validation on input="email" textbox
  const extractEmailsMatch = EMAIL_REGEX.exec(contactStringFormatted)
  const result = {}
  if (extractEmailsMatch) {
    const emailAddress = extractEmailsMatch[0]
    result.emailAddress = emailAddress
    contactStringFormatted = contactStringFormatted.replace(EMAIL_REGEX, '')
  }
  const phone = contactStringFormatted.match(PHONE_REGEX)
  if (phone) {
    result.phoneNumberFormatted = phone.join('')
    /*
      The usage of the blank string vs the empty string is intentional
      This makes it easier to pluck out orphaned hyphens and leave those
      in names alone.
    */

    contactStringFormatted = contactStringFormatted.replace(PHONE_REGEX, BLANK_STRING)
    contactStringFormatted = contactStringFormatted.replace('+', '')
    contactStringFormatted = contactStringFormatted.replace(BAD_CHAR_REGEX, '')
  }

  const words = _.compact(contactStringFormatted.split(NO_EXTRA_SPACES))
  if (!_.isEmpty(words)) {
    const [firstName, ...remaining] = words
    const lastName = remaining.join(' ')
    if (!_.isNil(firstName)) {
      result.firstName = firstName
    }
    if (!_.isNil(lastName)) {
      result.lastName = lastName
    }
  }

  return result
}
