import _ from 'lodash'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import * as ActionTypes from 'mgr/pages/single-venue/dayview/actions/ActionTypes'

const resetAssignModeState = {
  isInTableAssignMode: false,
  assignModeActual: null,
  selectedTablePositions: [],
  isFirstSelection: true,
}

const resetDisplayTableState = {
  selectedDisplayTablePosition: null,
}

const resetDailyFloorplanState = {
  selectedFloorplanRoom: null,
  ...resetAssignModeState,
}

const initialFloorplanState = {
  ...resetDailyFloorplanState,
}

const floorplanReducer = (state = initialFloorplanState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_DATE:
    case GlobalActionTypes.CHANGE_VENUE:
      return { ...state, ...resetDailyFloorplanState }
    case ActionTypes.GET_SEATINGAREA_TABLES_SUCCESS: {
      const dayFloorPlan = action.seatingAreaTables
      const { floorplanRooms } = dayFloorPlan
      const selectedFloorplanRoom = floorplanRooms && floorplanRooms.length ? floorplanRooms[0] : null
      return { ...state, selectedFloorplanRoom }
    }
    case ActionTypes.FLOORPLAN_CHANGE_FLOORPLAN_ROOM: {
      const { selectedFloorplanRoom } = action
      return { ...state, ...resetDisplayTableState, selectedFloorplanRoom }
    }
    case ActionTypes.FLOORPLAN_CLICK_TABLE_POSITION: {
      const { tablePosition } = action
      if (state.isInTableAssignMode) {
        const selectedTablePositions = state.isFirstSelection ? [] : [...state.selectedTablePositions]
        const isFirstSelection = false
        if (_.includes(selectedTablePositions, tablePosition)) {
          selectedTablePositions.splice(_.indexOf(selectedTablePositions, tablePosition), 1)
        } else {
          selectedTablePositions.push(tablePosition)
        }
        return { ...state, selectedTablePositions, isFirstSelection }
      }
      const selectedDisplayTablePosition = state.selectedDisplayTablePosition === tablePosition ? null : tablePosition
      return { ...state, selectedDisplayTablePosition }
    }
    case ActionTypes.FLOORPLAN_ENTER_TABLE_ASSIGN_MODE: {
      const { actual, selectedTablePositions } = action
      const isInTableAssignMode = true
      const assignModeActual = actual
      return {
        ...state,
        ...resetDisplayTableState,
        isInTableAssignMode,
        assignModeActual,
        selectedTablePositions,
      }
    }
    case ActionTypes.FLOORPLAN_CLOSE_TABLE_CARD: {
      return { ...state, ...resetAssignModeState, ...resetDisplayTableState }
    }
    case ActionTypes.SAVE_RES_TABLE_ASSIGNMENT_SUCCESS: {
      return { ...state, ...resetAssignModeState }
    }
    case ActionTypes.SHOW_ACTUAL: {
      return { ...state, ...resetAssignModeState, ...resetDisplayTableState }
    }
    default:
      return state
  }
}

export default floorplanReducer
