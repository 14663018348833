import _ from 'lodash'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import AvailabilityDayEvents from 'mgr/actualslideout/components/availability/AvailabilityDayEvents'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'
import { changeSearchVenues } from '../../actions/BookActions'
import { useStoreSelector, type State } from '../../selectors/useStoreSelector'

export interface MultiVenueAvailabilityDayEventsProps {
  searchVenue: State['bookAvailabilityState']['searchVenues'][number]
  isSelectedVenue: boolean
}

export function MultiVenueAvailabilityDayEvents({ searchVenue, isSelectedVenue }: MultiVenueAvailabilityDayEventsProps) {
  const dispatch = useDispatch()
  const { availabilityByVenue, dailyEventsByVenueDate, date, searchVenues } = useStoreSelector(state => state.bookAvailabilityState)

  const venueAvailability = availabilityByVenue[searchVenue.id]
  const availableTimes = venueAvailability?.availableTimes ?? []
  const dailyEvents = (dailyEventsByVenueDate[searchVenue.id] || {})[date as unknown as string]
  const hasIconOffset = dailyEvents && (!_.isEmpty(dailyEvents.events) || !_.isEmpty(dailyEvents.note))
  const firstShiftPersistentId = availableTimes.length === 0 ? null : availableTimes[0]?.shift_persistent_id

  const onClickVenueHeader = useCallback(
    () => dispatch(changeSearchVenues([searchVenue], firstShiftPersistentId)),
    [searchVenue, firstShiftPersistentId, dispatch]
  )
  const onClickRemoveVenue = useCallback(
    () => dispatch(changeSearchVenues(searchVenues.filter(v => v.id !== searchVenue.id))),
    [searchVenue, searchVenues, dispatch]
  )

  return (
    <ResultColumnHeader>
      <AvailabilityDayEvents {...{ dailyEvents }} venue={searchVenue} isCondensed showAdditionalSidePaneWindow={isSelectedVenue} />
      <ResultColumnHeaderTitle hasIconOffset={hasIconOffset} onClick={onClickVenueHeader}>
        {searchVenue.internalDisplayName}
      </ResultColumnHeaderTitle>
      <ResultColumnHeaderRemoveIcon onClick={onClickRemoveVenue}>{VmsIcons.Close}</ResultColumnHeaderRemoveIcon>
    </ResultColumnHeader>
  )
}

const ResultColumnHeaderRemoveIcon = styled(StyledIcons.XXS)`
  border-radius: 50%;
  background: ${props => props.theme.lightGrey};
  color: ${props => props.theme.white};
  font-weight: 600;
  padding: 3px;
  text-align: center;
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
  display: none;
  :hover {
    background: ${props => props.theme.darkGrey};
  }
`

const ResultColumnHeader = styled.div`
  margin-top: 15px;
  position: relative;
  ${props => props.theme.clearFix}
  :hover ${ResultColumnHeaderRemoveIcon} {
    display: block;
  }
`

const ResultColumnHeaderTitle = styled.div<{ hasIconOffset?: boolean }>`
  padding-right: ${props => (props.hasIconOffset ? '24px' : 0)};
  min-height: 19px;
  line-height: 19px;
  color: ${props => props.theme.navigationDark};
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  ${props => props.theme.fontWeight300} ${props => props.theme.ellipsis};
`
