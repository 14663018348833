import { useMemo } from 'react'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'

export const useSelectDaysOptions = (max: number, dir: 'before' | 'after'): SelectOption[] => {
  const { formatMessage } = useLocales()
  return useMemo(() => {
    const result = []
    for (let i = 0; i <= max; i += 1) {
      result.push({
        id: String(dir === 'before' ? -i : i),
        label: formatMessage(dir === 'before' ? commonMessages.daysBefore : commonMessages.daysAfter, { count: i }),
      })
    }
    return result
  }, [formatMessage, dir, max])
}
