import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import { PerksService } from '@sevenrooms/core/api'

export const getPerksList = venueId => dispatch => {
  PerksService.list({ venueId }).then(
    response =>
      dispatch({
        type: ActionTypes.LOAD_VENUE_PERKS_SUCCESS,
        perks: response,
      }),
    () => dispatch(GlobalActions.showErrorMessage('An error occurred, please try again or contact support'))
  )
}
