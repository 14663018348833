import _ from 'lodash'
import moment from 'moment-timezone'
import * as MessagingAndActivityLogActions from 'mgr/actualslideout/actions/MessagingAndActivityLogActions'
import AttachmentServices from 'mgr/lib/services/AttachmentServices'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
import * as SlideoutActions from './SlideoutActions'

const getSaveAttachmentStart = () => ({
  type: ActionTypes.SAVE_ATTACHMENT_START,
})
const getSaveAttachmentFail = () => ({ type: ActionTypes.SAVE_ATTACHMENT_FAIL })
const getSaveAttachmentSuccess = fileInfo => ({
  type: ActionTypes.SAVE_ATTACHMENT_SUCCESS,
  fileInfo,
})

const trySaveAttachment = fileList => (dispatch, getState) => {
  // Added to make sure that the attachment displays in the tab it was added from
  const activeTab = getState().viewResState.selectedTabAction
  dispatch(getSaveAttachmentStart())

  const errHandler = error =>
    dispatch(batchActions([getSaveAttachmentFail(), SlideoutActions.showNotificationError(`Error saving attachment: ${error}`)]))

  if (fileList.length !== 1) {
    errHandler('Incorrect number of files')
    return
  }
  const file = fileList.item(0)
  return AttachmentServices.uploadFile(file).then(
    response =>
      dispatch(
        getSaveAttachmentSuccess({
          fileName: file.name,
          fileType: file.type,
          blobKey: response.post_key,
          urlKey: response.url_key,
          activeTab,
        })
      ),
    errHandler
  )
}

const getRegisterAttachmentsStart = () => ({
  type: ActionTypes.REGISTER_ATTACHMENTS_START,
})
const getRegisterAttachmentsFail = () => ({
  type: ActionTypes.REGISTER_ATTACHMENTS_FAIL,
})
const getRegisterAttachmentsSuccess = () => ({
  type: ActionTypes.REGISTER_ATTACHMENTS_SUCCESS,
})

const tryRegisteringAttachments = (attachments, isInternal) => (dispatch, getState) => {
  dispatch(getRegisterAttachmentsStart())
  const errHandler = error =>
    dispatch(
      batchActions([getRegisterAttachmentsFail(), SlideoutActions.showNotificationError(`Error attaching files to message: ${error}`)])
    )

  if (_.isEmpty(attachments)) {
    dispatch(getRegisterAttachmentsSuccess())
    return
  }

  const state = getState()
  const { user } = state.appState
  const authorName = user.full_name
  const authorId = user.id
  const { actual } = state.viewResState
  const attachmentGroupKey = actual.attachments
  // FIXME: Is this accurate and correct?
  const createdDate = moment().format('DD/MM/YY')
  const attachmentData = attachments.map(file => ({
    attachment_group_key: attachmentGroupKey,
    content_type: file.fileType,
    blob_key: file.urlKey, // blob_key is a misnomer
    is_internal: isInternal,
    author_name: authorName,
    author_id: authorId,
    filename: file.fileName,
    created: createdDate,
  }))

  if (_.isEmpty(attachmentData)) {
    dispatch(getRegisterAttachmentsSuccess())
    return
  }
  return AttachmentServices.registerAttachmentsToAttachmentGroup(attachmentGroupKey, isInternal, attachmentData, errHandler).then(
    didSucceed => {
      dispatch(didSucceed ? getRegisterAttachmentsSuccess() : getRegisterAttachmentsFail())
      return [didSucceed, attachmentData]
    }
  )
}

const getDeleteAttachmentStart = () => ({
  type: ActionTypes.DELETE_ATTACHMENT_START,
})
const getDeleteAttachmentFail = () => ({
  type: ActionTypes.DELETE_ATTACHMENT_FAIL,
})
const getDeleteAttachmentSuccess = blobKey => ({
  type: ActionTypes.DELETE_ATTACHMENT_SUCCESS,
  blobKey,
})
const getDeleteBufferedCommentAttachment = blobKey => ({
  type: ActionTypes.REMOVE_BUFFERED_COMMENT_ATTACHMENT,
  blobKey,
})
const getDeleteBufferedMessageAttachment = blobKey => ({
  type: ActionTypes.REMOVE_BUFFERED_MESSAGE_ATTACHMENT,
  blobKey,
})
const getDeleteSavedAttachment = blobKey => ({
  type: ActionTypes.DELETE_SAVED_ATTACHMENT,
  blobKey,
})

const tryDeleteSavedAttachment = attachment => (dispatch, getState) => {
  const deletePromise = dispatch(tryDeleteMessageAttachment(attachment.blob_key))
  return deletePromise.then()
}
const tryDeleteAttachment = blobKey => (dispatch, getState) => {
  dispatch(getDeleteAttachmentStart())
  const attachmentGroupKey = getState().viewResState.actual.attachments
  const errHandler = error =>
    dispatch(batchActions([getDeleteAttachmentFail(), SlideoutActions.showNotificationError(`Error deleting attachment: ${error}`)]))

  return AttachmentServices.deleteAttachment(attachmentGroupKey, blobKey, errHandler).then(resp => getDeleteAttachmentSuccess(blobKey))
}

const tryDeleteAlreadySavedAttachment = attachment => (dispatch, getState) => {
  dispatch(
    batchActions([
      dispatch(tryDeleteAttachment(attachment.blob_key)),
      dispatch(getDeleteSavedAttachment(attachment.blob_key)),
      dispatch(MessagingAndActivityLogActions.tryPutFileDeletedActivityLogEntry(attachment.filename)),
    ])
  )
}

// These are deleting staged attachments and don't need to post things
// to the activity log.
const tryDeleteCommentAttachment = blobKey => (dispatch, getState) => {
  dispatch(tryDeleteAttachment(blobKey)).then(dispatch(getDeleteBufferedCommentAttachment(blobKey)))
}
const tryDeleteMessageAttachment = blobKey => (dispatch, getState) => {
  dispatch(tryDeleteAttachment(blobKey)).then(dispatch(getDeleteBufferedMessageAttachment(blobKey)))
}

const getSinglePassStageAndRegisterAttachmentStart = () => ({
  type: ActionTypes.SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_START,
})
const getSinglePassStageAndRegisterAttachmentFail = () => ({
  type: ActionTypes.SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_FAIL,
})
const getSinglePassStageAndRegisterAttachmentSuccess = fileInfo => ({
  type: ActionTypes.SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_SUCCESS,
  fileInfo,
})

const trySinglePassStageAndRegisterAttachment = fileList => (dispatch, getState) => {
  dispatch(getSinglePassStageAndRegisterAttachmentStart())

  const errHandler = error =>
    dispatch(
      batchActions([
        getSinglePassStageAndRegisterAttachmentFail(),
        SlideoutActions.showNotificationError(`Error adding attachment: ${error}`),
      ])
    )

  if (fileList.length !== 1) {
    errHandler('Incorrect number of files')
    return
  }

  const file = fileList.item(0)
  return AttachmentServices.uploadFile(file).then(response => {
    const stagedFileInfo = {
      fileName: file.name,
      fileType: file.type,
      blobKey: response.post_key,
      urlKey: response.url_key,
    }
    const registerFuture = dispatch(tryRegisteringAttachments([stagedFileInfo], true))

    return registerFuture.then(([didSucceed, attachmentData]) => {
      if (!didSucceed) {
        return dispatch(getSinglePassStageAndRegisterAttachmentFail())
      }
      dispatch(MessagingAndActivityLogActions.tryPutFileAttachmentActivityLogEntry(attachmentData))
      return dispatch(getSinglePassStageAndRegisterAttachmentSuccess(stagedFileInfo))
    })
  }, errHandler)
}

const getAttachmentsBegin = () => ({ type: ActionTypes.LOAD_ATTACHMENTS_START })
const getAttachmentsSuccess = attachments => ({
  type: ActionTypes.LOAD_ATTACHMENTS_SUCCESS,
  attachments,
})
const getAttachmentsFail = () => ({ type: ActionTypes.LOAD_ATTACHMENTS_FAIL })
const tryGetAttachments = venueId => (dispatch, getState) => {
  dispatch(getAttachmentsBegin())

  const state = getState()
  const { actual } = state.viewResState
  const venueId = actual.venue_id
  const conversationId = actual && actual.attachments

  return AttachmentServices.fetchAttachments(venueId, conversationId).then(
    attachments => {
      const cbState = getState()
      const cbActual = cbState.viewResState.actual
      if (cbActual && actual.id === cbActual.id) {
        return dispatch(getAttachmentsSuccess(attachments))
      }
      svrDebug('Ignoring slow response for fetchAttachments')
    },
    error => dispatch(batchActions([getAttachmentsFail(), SlideoutActions.showNotificationError(`Error getting attachments: ${error}`)]))
  )
}

export {
  trySaveAttachment,
  tryGetAttachments,
  tryDeleteAlreadySavedAttachment,
  tryDeleteCommentAttachment,
  tryDeleteMessageAttachment,
  tryRegisteringAttachments,
  trySinglePassStageAndRegisterAttachment,
}
