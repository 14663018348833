// /* @flow */

export const inputTypes = {
  POSITIVE_INT: 'POSITIVE_INT',
  POSITIVE_NUMBER: 'POSITIVE_NUMBER',
  CURRENCY: 'CURRENCY',
  DEFAULT: 'DEFAULT',
}

export const inputTypeProps = {
  [inputTypes.POSITIVE_INT]: {
    inputProps: {
      type: 'number',
      min: '0',
    },
  },
  [inputTypes.POSITIVE_NUMBER]: {
    inputProps: {
      type: 'number',
      min: '0',
      step: 'any',
    },
  },
  [inputTypes.CURRENCY]: {
    inputProps: {
      type: 'number',
      min: '0',
      step: '0.01',
    },
  },
  [inputTypes.DEFAULT]: {
    inputProps: {
      type: 'text',
    },
  },
}

// BUTTON: 'button',
// CHECKBOX: 'checkbox',
// COLOR: 'color',
// DATE: 'date',
// DATETIME: 'datetime',
// EMAIL: 'email',
// FILE: 'file',
// HIDDEN: 'hidden',
// IMAGE: 'image',
// MONTH: 'month',
// NUMBER: 'number',
// PASSWORD: 'password',
// RADIO: 'radio',
// RANGE: 'range',
// RESET: 'reset',
// SEARCH: 'search',
// SUBMIT: 'submit',
// TEL: 'tel',
// TEXT: 'text',
// TIME: 'time',
// URL: 'url',
// WEEK: 'week',
