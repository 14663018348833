import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormPercentageInput } from '@sevenrooms/core/ui-kit/form'
import { paymentMessages } from '../Payment.locales'
import { ChargeLine } from './ChargeLine'
import type { ChargesForm } from '../Payment.zod'

interface ServiceProps {
  amount: number | null
  field: Field<ChargesForm>
  oldValue?: number | null
  override: boolean
  currencyCode: string
  selectable?: boolean
  forceDisableEdit?: boolean // need for beta
}

export function Service({ amount, field, oldValue, selectable, currencyCode, override, forceDisableEdit }: ServiceProps) {
  const { formatMessage } = useLocales()

  const [serviceValue, applyService] = useWatchMany(field, ['service', 'applyService'])
  const isShowInput = !forceDisableEdit && ((override && !selectable) || applyService)

  const service = serviceValue ?? 0
  const chargeAmount = amount ? amount * (service / 100) : 0

  return (
    <ChargeLine
      title={formatMessage(paymentMessages.service)}
      field={selectable ? field.prop('applyService') : undefined}
      testId="sr-supafly-apply-service-charge"
      oldAmount={oldValue != null && oldValue !== service ? oldValue : null}
      amount={chargeAmount}
      currencyCode={currencyCode}
    >
      {isShowInput && (
        <FormPercentageInput
          data-test="sr-supafly-service-charge"
          field={field.prop('service')}
          fieldHeight="s"
          hideErrorMessage
          disabled={!override && !selectable}
        />
      )}
    </ChargeLine>
  )
}
