/* eslint-disable camelcase */
import _ from 'lodash'
import moment from 'moment-timezone'
import { formatDateOnly } from 'mgr/lib/utils/MomentUtils'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import type { OrderPacingAction } from 'mgr/pages/single-venue/settings/actions/ordering/OrderPacingActions'
import type { TimeRange, OrderingMenu } from 'mgr/pages/single-venue/settings/types/ordering/MenuManagement.types'
import type { PacingRule, OrderingSiteOption } from 'mgr/pages/single-venue/settings/types/ordering/OrderPacing.types'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

export interface OrderPacingState {
  menus: OrderingMenu[]
  defaultMenuHours: TimeRange
  isLoading: boolean
  list: PacingRule[]
  orderingSites: OrderingSiteOption[]
  orderingSitesById: Record<string, OrderingSiteOption>
  pacingRule: PacingRule
  pacingRuleFilterSearchValue: string
  resourcesLoadedForVenueId?: string
  sorted: SortColumn[]
}

const initialState: OrderPacingState = {
  menus: [],
  defaultMenuHours: {} as TimeRange,
  isLoading: false,
  list: [],
  orderingSites: [],
  orderingSitesById: {},
  pacingRule: {} as PacingRule,
  pacingRuleFilterSearchValue: '',
  resourcesLoadedForVenueId: undefined,
  sorted: [
    {
      id: 'name',
      desc: false,
    },
  ],
}

const orderingOrderPacing = (state: OrderPacingState = initialState, action: OrderPacingAction) => {
  switch (action.type) {
    case ActionTypes.GET_ORDERING_PACING_RULE_LIST_START:
    case ActionTypes.REMOVE_PACING_RULE_START:
      return {
        ...state,
        list: [],
        isLoading: true,
      }
    case ActionTypes.GET_ORDERING_PACING_RULE_LIST_FAIL:
    case ActionTypes.REMOVE_PACING_RULE_SUCCESS:
    case ActionTypes.REMOVE_PACING_RULE_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.GET_ORDERING_PACING_RULE_LIST_SUCCESS:
      return {
        ...state,
        list: action.pacingRules,
        isLoading: false,
      }
    case ActionTypes.SET_PACING_RULE_LIST_FILTER_SEARCH_VALUE:
      return {
        ...state,
        pacingRuleFilterSearchValue: action.value,
      }
    case ActionTypes.SET_PACING_RULE_LIST_SORTED_COLUMNS:
      return {
        ...state,
        sorted: action.data,
      }
    case ActionTypes.GET_ORDERING_PACING_RULE_SUCCESS:
      return {
        ...state,
        pacingRule: action.pacingRule,
      }
    case ActionTypes.GET_ORDERING_PACING_RULE_RESOURCES_SUCCESS:
      return {
        ...state,
        menus: action.menus,
        defaultMenuHours: action.defaultMenuHours,
        orderingSites: action.orderingSites,
        orderingSitesById: _.keyBy(action.orderingSites, 'id'),
        resourcesLoadedForVenueId: action.venueId,
      }
    case ActionTypes.CLEAR_PACING_RULE_DATA:
      return {
        ...initialState,
        pacingRule: {
          ...initialState.pacingRule,
        },
      }
    case ActionTypes.UPDATE_PACING_RULE_NAME:
      return {
        ...state,
        pacingRule: {
          ...state.pacingRule,
          name: action.value,
        },
      }
    case ActionTypes.UPDATE_PACING_RULE_DATE_RANGE_FROM: {
      const dateRangeFrom = action.value && formatDateOnly(moment(action.value))
      let { dateRangeTo } = state.pacingRule
      if (dateRangeTo && dateRangeTo.isBefore(dateRangeFrom)) {
        dateRangeTo = dateRangeFrom
      }
      return {
        ...state,
        pacingRule: {
          ...state.pacingRule,
          dateRangeFrom,
          dateRangeTo,
        },
      }
    }
    case ActionTypes.UPDATE_PACING_RULE_DATE_RANGE_TO: {
      const dateRangeTo = action.value && formatDateOnly(moment(action.value))
      let { dateRangeFrom } = state.pacingRule
      if (dateRangeTo && dateRangeTo.isBefore(dateRangeFrom)) {
        dateRangeFrom = dateRangeTo
      }
      return {
        ...state,
        pacingRule: {
          ...state.pacingRule,
          dateRangeFrom,
          dateRangeTo,
        },
      }
    }
    case ActionTypes.UPDATE_PACING_ORDER_METHODS:
      return {
        ...state,
        pacingRule: {
          ...state.pacingRule,
          orderMethods: action.value,
        },
      }
    case ActionTypes.UPDATE_PACING_RULE_ORDERING_SITE_IDS:
      return {
        ...state,
        pacingRule: {
          ...state.pacingRule,
          orderingSiteIds: action.value,
        },
      }
    case ActionTypes.UPDATE_PACING_RULE_TIME_RANGE_FROM:
      return {
        ...state,
        pacingRule: {
          ...state.pacingRule,
          timeRangeFrom: action.value,
        },
      }
    case ActionTypes.UPDATE_PACING_RULE_TIME_RANGE_TO:
      return {
        ...state,
        pacingRule: {
          ...state.pacingRule,
          timeRangeTo: action.value,
        },
      }
    case ActionTypes.UPDATE_PACING_RULE_TIME_INTERVAL_MINS:
      return {
        ...state,
        pacingRule: {
          ...state.pacingRule,
          timeIntervalMins: action.value,
        },
      }
    case ActionTypes.UPDATE_PACING_RULE_DAY_TIME_LIMIT_MATRIX:
      return {
        ...state,
        pacingRule: {
          ...state.pacingRule,
          dayTimeLimitMatrix: action.value,
        },
      }
    default:
      return state
  }
}

export default orderingOrderPacing
