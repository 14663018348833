import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import ClientThumbnail from 'mgr/lib/components/ClientThumbnail'
import { AuthorBold, LogDevice, LogEntryText, LogEntryWrapper, LogEntryMessageWrapper, LogTime, makeInitials } from './MessageCommon'
import { FlexColumnContainer, FlexRowContainer, ScrollableList } from './ViewLayout'
import { QuickButton } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { BackwriterPromptType } from '@sevenrooms/core/domain'

const MessageBox = styled.p`
  font-size: 14px;
  color: ${props => props.theme.background};
  border-radius: 10px;
  white-space: pre-line;
  padding: 10px 16px 10px 16px !important;
`

const OwnMessageBox = styled(MessageBox)`
  color: ${props => props.theme.background};
  background-color: ${props => props.theme.primary};
`

const OtherMessageBox = styled(MessageBox)`
  background-color: ${props => props.theme.mediumSilver};
`

const FeedbackMessageBox = styled(MessageBox)`
  background-color: ${props => props.theme.mediumSilver};
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid ${props => props.theme.margin};
`

const RatingRow = styled.div`
  display: block;
  clear: both;
  padding-top: 5px;
`

const RatingMessageBox = styled(MessageBox)`
  background-color: ${props => props.theme.mediumSilver};
  border-radius: 0 0 10px 10px;
`

const RatingHeaderLabel = styled.span`
  font-size: 14px;
  color: ${props => props.theme.background};
  float: left;
  margin-top: 2px;
`

const RatingHeaderRight = styled.span`
  font-size: 14px;
  color: ${props => props.theme.background};
  float: right;
`

const SubRatingLabel = styled.span`
  font-size: 12px;
  color: ${props => props.theme.background};
`

const SubRatingRight = styled.span`
  font-size: 12px;
  color: ${props => props.theme.background};
  float: right;
`

const MessageEntryElement = styled.li`
  width: 68% !important;
  list-style-type: none;
  margin-bottom: 30px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  padding: 0 !important;
`

const OwnMessageEntryElement = styled(MessageEntryElement)`
  align-self: flex-end;
`

const OtherMessageEntryElement = styled(MessageEntryElement)`
  align-self: flex-start;
`

const FlexMessageDisplay = styled(ScrollableList)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: ${props => props.theme.padding.small} !important;
  margin: 0;
  width: 100% !important;

  transition: margin-bottom 0.5s ease;
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px !important;`}
`

const Author = styled.span`
  color: ${props => props.theme.charcoal};
  text-align: left;
  font-size: 11px;
  font-weight: 900;
  align-self: flex-start;
  margin-left: 16px;
`
const HumanDateTime = styled.span`
  color: ${props => props.theme.darkGrey};
  text-align: right;
  font-size: 11px;
  margin-left: 4px;
  align-self: flex-end;
`

const makePhotoObj = photoKey => ({ small: photoKey })
const getClientThumbnailOrInitialsFromMessage = (message, isRightSide, isAltColorScheme) => {
  const margin = isRightSide ? '0 0 0 10px' : '0 10px 0 0'

  if (message.author_photo_key) {
    return <ClientThumbnail photoObj={makePhotoObj(message.author_photo_key)} nameDisplay={message.author_name} size={28} margin={margin} />
  }
  const initials = makeInitials(message.author_name)

  return <ClientThumbnail initials={initials} size={28} margin={margin} />
}
const getFormattedFeedbackRating = feedbackRating => {
  const rating = feedbackRating != null ? parseInt(feedbackRating, 10) : 0
  const feedbackFormattedOne = rating !== 0 ? Array(rating + 1).join('★') : 'not filled in'
  const feedbackFormattedTwo = rating !== 0 ? Array(6 - rating).join('☆') : ''
  return feedbackFormattedOne + feedbackFormattedTwo
}

const BackwriterButton = ({ label, promptType, generateResponse }) => (
  <Box ml="xs" mr="xs">
    <QuickButton icon="VMSWeb-action-stars" onClick={() => generateResponse(promptType)} data-test={`backwriter-button-${promptType}`}>
      {label}
    </QuickButton>
  </Box>
)

const OwnMessageEntry = ({ message, showBackwriterButton, generateResponse }) => (
  <OwnMessageEntryElement>
    <FlexRowContainer>
      <FlexColumnContainer>
        <FlexRowContainer>
          <Author>{message.author_name}</Author>
          <HumanDateTime>{message.created_humanized}</HumanDateTime>
        </FlexRowContainer>
        <OwnMessageBox>{message.text}</OwnMessageBox>
      </FlexColumnContainer>
      {getClientThumbnailOrInitialsFromMessage(message, true)}
    </FlexRowContainer>
    {showBackwriterButton && (
      <FlexRowContainer margin="15px 0 0 0" justifyContent="end">
        <BackwriterButton label="Follow Up" promptType={BackwriterPromptType.FollowUp} generateResponse={generateResponse} />
      </FlexRowContainer>
    )}
  </OwnMessageEntryElement>
)

const OtherMessageEntry = ({ message, showBackwriterButton, generateResponse }) => (
  <OtherMessageEntryElement>
    <FlexRowContainer>
      {getClientThumbnailOrInitialsFromMessage(message, false)}
      <FlexColumnContainer>
        <FlexRowContainer>
          <Author>{message.author_name}</Author>
          <HumanDateTime>{message.created_humanized}</HumanDateTime>
        </FlexRowContainer>
        <OtherMessageBox>{message.text}</OtherMessageBox>
      </FlexColumnContainer>
    </FlexRowContainer>
    {showBackwriterButton && (
      <FlexRowContainer margin="15px 0 0 0">
        <BackwriterButton label="Yes..." promptType={BackwriterPromptType.Yes} generateResponse={generateResponse} />
        <BackwriterButton label="No..." promptType={BackwriterPromptType.No} generateResponse={generateResponse} />
        <BackwriterButton label="General / Not Sure" promptType={BackwriterPromptType.Default} generateResponse={generateResponse} />
      </FlexRowContainer>
    )}
  </OtherMessageEntryElement>
)

const FeedbackMessageEntry = ({ message, isOrderSlideout, showBackwriterButton, generateResponse }) => (
  <OtherMessageEntryElement>
    <FlexRowContainer>
      {getClientThumbnailOrInitialsFromMessage(message, false)}
      <FlexColumnContainer>
        <FlexRowContainer>
          <Author>{message.author_name}</Author>
          <HumanDateTime>{message.created_humanized}</HumanDateTime>
        </FlexRowContainer>
        <FeedbackMessageBox>{message.text}</FeedbackMessageBox>
        <RatingMessageBox>
          <RatingRow>
            <RatingHeaderLabel>Overall</RatingHeaderLabel>
            <RatingHeaderRight>
              {message.feedback_ratings.overall} of 5 {Array(parseInt(message.feedback_ratings.overall, 10) + 1).join('★')}
              {Array(6 - parseInt(message.feedback_ratings.overall, 10)).join('☆')}
            </RatingHeaderRight>
          </RatingRow>
          <RatingRow>
            <SubRatingLabel>Food</SubRatingLabel>
            <SubRatingRight>{getFormattedFeedbackRating(message.feedback_ratings.food)}</SubRatingRight>
          </RatingRow>
          {isOrderSlideout ? (
            <RatingRow>
              <SubRatingLabel>On time</SubRatingLabel>
              <SubRatingRight>{message.feedback_ratings.arrived_on_time}</SubRatingRight>
            </RatingRow>
          ) : (
            <RatingRow>
              <SubRatingLabel>Service</SubRatingLabel>
              <SubRatingRight>{getFormattedFeedbackRating(message.feedback_ratings.service)}</SubRatingRight>
            </RatingRow>
          )}
          {isOrderSlideout ? (
            <RatingRow>
              <SubRatingLabel>Accurate</SubRatingLabel>
              <SubRatingRight>{message.feedback_ratings.was_order_accurate}</SubRatingRight>
            </RatingRow>
          ) : (
            <RatingRow>
              <SubRatingLabel>Ambience</SubRatingLabel>
              <SubRatingRight>{getFormattedFeedbackRating(message.feedback_ratings.ambience)}</SubRatingRight>
            </RatingRow>
          )}
          <RatingRow>
            <SubRatingLabel>Recommended</SubRatingLabel>
            <SubRatingRight>{message.feedback_ratings.recommend}</SubRatingRight>
          </RatingRow>
        </RatingMessageBox>
      </FlexColumnContainer>
    </FlexRowContainer>
    {showBackwriterButton && (
      <FlexRowContainer margin="15px 0 0 0">
        <BackwriterButton
          label="Respond to Feedback"
          promptType={BackwriterPromptType.RespondToFeedback}
          generateResponse={generateResponse}
        />
      </FlexRowContainer>
    )}
  </OtherMessageEntryElement>
)

const MessageDisplay = ({
  messages,
  venueId,
  isOrderSlideout,
  messageEntryBoxHeight,
  generateResponse,
  showSendButton,
  isBackwriterEnabled = false,
}) => {
  let messageButtonDisplayed = false
  let feedbackButtonDisplayed = false
  const entries = []
  for (let i = messages.length - 1; i >= 0; i -= 1) {
    const message = messages[i]

    if (!_.isEmpty(message.feedback_ratings)) {
      entries.unshift(
        <FeedbackMessageEntry
          key={message.id}
          message={message}
          isOrderSlideout={isOrderSlideout}
          showBackwriterButton={isBackwriterEnabled && !feedbackButtonDisplayed}
          generateResponse={generateResponse}
        />
      )
      feedbackButtonDisplayed = true
    } else if (message.author_org_id === venueId) {
      entries.unshift(
        <OwnMessageEntry
          key={message.id}
          message={message}
          showBackwriterButton={isBackwriterEnabled && !messageButtonDisplayed}
          generateResponse={generateResponse}
        />
      )
      messageButtonDisplayed = true
    } else {
      entries.unshift(
        <OtherMessageEntry
          key={message.id}
          message={message}
          showBackwriterButton={isBackwriterEnabled && !messageButtonDisplayed}
          generateResponse={generateResponse}
        />
      )
      messageButtonDisplayed = true
    }
  }
  return <FlexMessageDisplay marginBottom={showSendButton ? messageEntryBoxHeight : 0}>{entries}</FlexMessageDisplay>
}

const NoteEntry = ({ note }) => (
  <LogEntryMessageWrapper alignItems="flex-start" justifyContent="flex-start">
    {getClientThumbnailOrInitialsFromMessage(note, false)}
    <FlexColumnContainer>
      <FlexRowContainer>
        <AuthorBold>{note.author_name}</AuthorBold>
        <LogDevice>{note.device}</LogDevice>
        <LogTime>{note.created_humanized}</LogTime>
      </FlexRowContainer>
      <LogEntryText>{note.text}</LogEntryText>
    </FlexColumnContainer>
  </LogEntryMessageWrapper>
)

export const NotesArea = ({ notes }) => {
  const noteElements = notes.map(note => <NoteEntry key={note.id} note={note} />)
  return <FlexMessageDisplay>{noteElements}</FlexMessageDisplay>
}

export default MessageDisplay
