import { useState, useEffect } from 'react'
import { adminEmailCampaignTemplateApi, emailCampaignsApi } from '@sevenrooms/core/api'
import { MarketingCampaignTypeEnum } from '@sevenrooms/core/domain'
import { z, useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useHistory, useNavigation } from '@sevenrooms/core/navigation'
import { Button, LoaderButton, Form, FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  type ModalHeaderProps,
  ModalFooter,
  ModalActions,
  notify,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { emailBuilderMessages } from '../../locales'
import { EmailCampaignPreview } from '../EmailCampaignPreview'

export interface SendTestEmailModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  subjectLine: string
  senderAddress?: string
  replyToAddress?: string
  previewText?: string
  template: string
  emailBody: string
  signoff: string
  emailGreeting: string
  footer: string
  signature: string
  venueId?: string
  campaignType?: string
  withPreview?: boolean
  onConfirm?(): void
  onValidate?: () => Promise<boolean>
}

export function SendTestEmailModal({
  closeHref,
  subjectLine,
  senderAddress = '',
  replyToAddress = '',
  previewText = '',
  template,
  emailBody,
  signoff,
  emailGreeting,
  footer,
  signature,
  venueId,
  campaignType,
  withPreview = true,
  onConfirm,
  onValidate,
}: SendTestEmailModalProps) {
  const [recipientEmailAddresses, setRecipientEmailAddresses] = useState<string>('')

  const history = useHistory()
  const { formatMessage } = useLocales()

  const [sendEmailCampaignTestQuery, { isSuccess, isError, isLoading }] = venueId
    ? emailCampaignsApi.useLazySendEmailCampaignTestQuery()
    : adminEmailCampaignTemplateApi.useLazySendAdminEmailCampaignTemplateTestQuery()
  const nav = useNavigation()
  const formValues = z.object({
    emailAddresses: z
      .string()
      .nonempty({ message: formatMessage(emailBuilderMessages.sendTestEmailModalEmailAddressesInputNoEmailAddressesError) })
      .superRefine((val: string, ctx) => {
        const emailAddresses = val.split(',').map((item: string) => item.trim())
        const emailSchema = z.string().email()
        emailAddresses.forEach(emailAddress => {
          try {
            emailSchema.parse(emailAddress)
          } catch {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(emailBuilderMessages.sendTestEmailModalEmailAddressesInputInvalidEmailAddressError, {
                emailAddress,
              }),
            })
          }
        })
      }),
  })

  const form = useForm(formValues)
  const { field } = form

  useEffect(() => {
    const showToastNotification = () => {
      if (isSuccess) {
        notify({
          content: formatMessage(emailBuilderMessages.sendTestEmailModalSuccessMessage, { emailAddresses: recipientEmailAddresses }),
          type: 'success',
        })
        history.push(closeHref)
      } else if (isError) {
        notify({
          content: formatMessage(emailBuilderMessages.sendTestEmailModalErrorMessage),
          type: 'error',
        })
      }
    }
    showToastNotification()
  }, [closeHref, recipientEmailAddresses, formatMessage, history, isError, isSuccess])

  const trySendTestEmail = async (emailAddresses: string) => {
    if (isLoading) {
      return
    }
    setRecipientEmailAddresses(emailAddresses)

    const isValid = onValidate ? await onValidate?.() : true

    if (isValid) {
      sendEmailCampaignTestQuery({
        emailCampaignTestData: {
          campaignType: campaignType ? MarketingCampaignTypeEnum.ONE_TIME : MarketingCampaignTypeEnum.ONGOING,
          emailSubject: subjectLine,
          emailBody,
          signoff,
          emailGreeting,
          footer,
          signature,
          emailSender: senderAddress,
          emailReplyTo: replyToAddress,
          previewText,
        },
        emailAddresses,
        venueId: venueId ?? '',
      })
    } else {
      nav.closeSurface(closeHref)
    }
  }

  return (
    <Modal ariaLabel={formatMessage(emailBuilderMessages.sendTestEmailModalTitle)}>
      <Form {...form} onSubmit={({ emailAddresses }) => trySendTestEmail(emailAddresses)} onInvalid={() => {}}>
        <ModalHeader closeHref={closeHref} disabled={isLoading}>
          <VStack spacing="s">
            <ModalTitle title={formatMessage(emailBuilderMessages.sendTestEmailModalTitle)} />
            <Label secondary={formatMessage(emailBuilderMessages.sendTestEmailModalSubTitle)} primary={undefined} />
          </VStack>
        </ModalHeader>
        <ModalBody>
          <VStack spacing="lm">
            {withPreview && <EmailCampaignPreview template={template} isModal isLoading={isLoading} />}
            <Label
              primary={formatMessage(emailBuilderMessages.sendTestEmailModalEmailAddressesLabelPrimary)}
              secondary={formatMessage(emailBuilderMessages.sendTestEmailModalEmailAddressesLabelSecondary)}
            >
              <FormInput
                data-test="send-test-email-modal-email-addresses-input"
                field={field.prop('emailAddresses')}
                disabled={isLoading}
              />
            </Label>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button data-test="send-test-email-modal-cancel-button" variant="secondary" href={closeHref} disabled={isLoading}>
              {formatMessage(emailBuilderMessages.sendTestEmailModalCancelButton)}
            </Button>
            <LoaderButton data-test="send-test-email-modal-confirm-button" type="submit" loading={isLoading} onClick={onConfirm}>
              {formatMessage(emailBuilderMessages.sendTestEmailModalConfirmButton)}
            </LoaderButton>
          </ModalActions>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
