import React, { PureComponent } from 'react'
import TextInput, { InputRestrictions } from 'mgr/lib/forms/TextInput'

const ENTER_KEY = 13

class ClientSearchInput extends PureComponent {
  constructor(props) {
    super(props)

    this.value = ''
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.triggerChange = this.triggerChange.bind(this)
  }

  handleChange(value) {
    this.value = value
    this.triggerChange()
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange()
    }
  }

  triggerChange() {
    this.props.onChange(this.value)
  }

  render() {
    const { testId, returnSearchInputRef, placeholder, debounceTimeout } = this.props

    return (
      <TextInput
        testId={testId}
        ref={e => returnSearchInputRef(e)}
        placeholder={placeholder}
        useOutsideLabel
        inputRestriction={InputRestrictions.none}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        debounceTimeout={debounceTimeout}
        inputStyles={{
          paddingLeft: '10px !important',
        }}
      />
    )
  }
}

ClientSearchInput.propTypes = {
  testId: React.PropTypes.string,
  returnSearchInputRef: React.PropTypes.func.isRequired,
  placeholder: React.PropTypes.string.isRequired,
  debounceTimeout: React.PropTypes.number,
}

ClientSearchInput.defaultProps = {
  returnSearchInputRef: () => {},
  placeholder: 'Name, Phone, Email',
  debounceTimeout: 500,
}

export default ClientSearchInput
