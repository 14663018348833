// /* @flow */
import * as React from 'react'
import styled from 'styled-components'

/* type Props = {
  radioId: string,
  onClick: boolean | (() => mixed),
  isSelected: boolean,
  customRadioIcon: Array<string | (() => mixed)>,
  customRadioCheck: Array<string | (() => mixed)>,
} */

const RadioSymbol = (props /* : Props */) => (
  <RadioIcon
    id={props.radioId}
    onClick={props.onClick}
    role="radio"
    aria-checked={props.isSelected}
    customRadioIcon={props.customRadioIcon}
    data-test={`radio-button-${props.radioId}`}
  >
    {props.isSelected && <RadioCheck customRadioCheck={props.customRadioCheck} />}
  </RadioIcon>
)

RadioSymbol.defaultProps = {
  isSelected: false,
  customRadioIcon: [],
  customRadioCheck: [],
}

const RadioIcon = styled.button`
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  left: 0;
  top: 7px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(204, 204, 204, 1);
  border-radius: 50%;
  cursor: pointer;
  padding: 1px;
  outline: none;
  :focus {
    border: 2px solid rgba(95, 193, 250, 1);
    padding: 0px;
  }
  ${props => props.customRadioIcon};
`

const RadioCheck = styled.div`
  margin: 2px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgba(118, 154, 242, 1);
  box-sizing: inherit;
  ${props => props.customRadioCheck};
`

export default RadioSymbol
