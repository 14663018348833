import { IS_LIGHTSPEED_CONNECTED } from 'mgr/pages/single-venue/settings/actions/LightspeedActions'

const initialState = {
  isConnected: false,
}

const lightspeedReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_LIGHTSPEED_CONNECTED:
      return {
        ...state,
        isConnected: action.isConnected,
      }
    default:
      return state
  }
}

export default lightspeedReducer
