import React from 'react'
import styled from 'styled-components'

const FlexTabContainer = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.background};
  padding: unset !important;
  margin: 0;
  height: 64px;
  box-shadow: 0 1px 0 0 rgba(172, 181, 189, 0.5);
`

const Tab = styled.li`
  list-style-type: none;
  margin: 0;
  text-transform: uppercase;
  padding: 0;
  padding-bottom: 12px !important;
  font-size: 13px;
  height: 100%;
  width: 33.33333333%;
  font-weight: 900;
  text-align: center;
  color: rgba(9, 34, 56, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.primaryRgba10};
  }
`

const Text = styled.div`
  width: 100%;
`
const ActiveTab = styled(Tab)`
  color: ${props => props.theme.navigationDark};
  padding-bottom: 10px !important;
  border-bottom: 2px solid ${props => props.theme.navigationDark};
`

const TabRow = ({ tabs, activeTab, onTabChange }) => {
  const tabElements = tabs.map(elem => {
    const tabChangeAdapter = () => onTabChange(elem.action)
    if (activeTab === elem.action) {
      return (
        <ActiveTab key={elem.action} onClick={tabChangeAdapter}>
          <Text>{elem.text}</Text>
        </ActiveTab>
      )
    }
    return (
      <Tab key={elem.action} onClick={tabChangeAdapter} data-test={`actualslideout-tab-${elem.action}`}>
        {elem.text}
      </Tab>
    )
  })
  return <FlexTabContainer>{tabElements}</FlexTabContainer>
}

export default TabRow
