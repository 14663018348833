import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

export const SourceBadge = styled.div`
  font-size: ${props => props.badgeSize - 7}px;
  line-height: ${props => props.badgeSize + 1}px;
  max-height: ${props => props.badgeSize}px;
  max-width: ${props => props.badgeSize}px;
  border-radius: ${props => props.badgeSize / 2}px;
  text-align: center;
  font-weight: 500 !important;
  font-family: ${props => props.theme.fontFamily};
  height: ${props => props.badgeSize}px;
  width: ${props => props.badgeSize}px;
  top: ${props => props.top}px;
  right: ${props => (_.isNil(props.right) ? -1 * (props.badgeSize / 3) : props.right)}px;
  color ${props => props.theme.white};
  background-color: ${props => props.theme.color.successAccent};
  position: absolute;
  
`

const DefaultSourceBadge = ({ badgeSize, margin, top, right }) => (
  <SourceBadge top={top} right={right} margin={margin} badgeSize={badgeSize}>
    S
  </SourceBadge>
)

DefaultSourceBadge.defaultProps = {
  badgeSize: 18,
  top: 0,
}

DefaultSourceBadge.props = {
  badgeSize: React.PropTypes.number.isRequired,
  margin: React.PropTypes.string,
  top: React.PropTypes.number,
  right: React.PropTypes.number,
}

export default DefaultSourceBadge
