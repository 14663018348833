import { UploadField } from '@navjobs/upload'
import _ from 'lodash'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled, { css } from 'styled-components'
import * as BlobUtils from 'mgr/lib/utils/Blob'
import { BadgedVmsIconS, BadgedVmsIconXS } from 'svr/common/BadgedIcon'
import { StyledVmsIconS, StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import { AddTextWithBoldPart, CardDetailTrial, CardDetailRowContainer, LegacyPaddedUl, IconArea, CardEntryLabel } from './ViewCommon'
import { FlexColumnContainer, FlexRowContainer } from './ViewLayout'

const AttachmentListEntry = styled.li`
  position: relative;
  color: ${props => props.theme.primary};
  font-size: 14px;
  line-height: 14px;
  font-family: ${props => props.theme.fontFamily};
  padding: 8px 0 8px 0 !important;
  width: 100%;
  height: auto;
  list-style: none;
  border-radius: 3px;
  ${props => props.theme.ellipsis};

  &:hover {
    background-color: ${props => props.theme.lighterSilver};
  }
`
const SubIcon = styled(StyledVmsIconXS)`
  color: ${props => props.theme.mediumSilver};
  display: none;

  &:hover {
    color: ${props => props.theme.primary};
  }

  ${AttachmentListEntry}:hover & {
    display: block;
  }
`

const FileName = styled.div`
  margin-left: 10px;
  width: 290px;
  ${props => props.theme.ellipsis};
`

const AddAttachment = styled.span`
  font-size: 11px;
`

const AttachmentCardDetailRowContainer = styled(CardDetailRowContainer)`
  position: relative;
  margin: -8px -16px;
  padding: 8px 16px;
  width: auto;
`

const uploadFieldContainerProps = {
  style: {
    position: 'static',
  },
}

const AttachmentValueArea = ({ attachments, onAddAttachment, onRemoveAttachment }) => (
  <LegacyPaddedUl>
    {attachments.map(attachment => (
      <AttachmentListEntry key={attachment.blob_key}>
        <FlexRowContainer justifyContent="space-between">
          <a target="_blank" type={attachment.type} href={BlobUtils.getBlobSrc(attachment.blob_key)} download rel="noreferrer">
            <FlexRowContainer>
              <StyledVmsIconXS>{VmsIcons.Attachment}</StyledVmsIconXS>
              <FileName>{attachment.filename}</FileName>
            </FlexRowContainer>
          </a>
          <a style={{ marginRight: '4px' }} onClick={() => onRemoveAttachment(attachment)}>
            <SubIcon data-tip="Delete">
              {VmsIcons.Trash}
              <ReactTooltip />
            </SubIcon>
          </a>
        </FlexRowContainer>
      </AttachmentListEntry>
    ))}
    <AttachmentListEntry key="newAttachment">
      <UploadField onFiles={onAddAttachment} containerProps={uploadFieldContainerProps}>
        <StyledVmsIconXS style={{ paddingRight: '3px' }}>{VmsIcons.AddLine}</StyledVmsIconXS>
        <AddAttachment>Add Attachment</AddAttachment>
      </UploadField>
    </AttachmentListEntry>
  </LegacyPaddedUl>
)

const CardAttachmentArea = ({ attachments, onAddAttachment, onRemoveAttachment }) => {
  const hasNoValue = _.isEmpty(attachments)
  if (hasNoValue) {
    return (
      <AttachmentCardDetailRowContainer enableHover alignItems>
        <IconArea>
          <BadgedVmsIconXS badgeIcon={VmsIcons.Add} displayIcon={VmsIcons.Attachment} />
        </IconArea>
        <FlexColumnContainer style={{ width: 'auto' }}>
          <UploadField onFiles={onAddAttachment} containerProps={uploadFieldContainerProps}>
            <AddTextWithBoldPart boldText="Add" regularText="attachment" />
          </UploadField>
        </FlexColumnContainer>
      </AttachmentCardDetailRowContainer>
    )
  }
  return (
    <AttachmentCardDetailRowContainer alignItems="flex-start">
      <FlexColumnContainer style={{ width: 'auto' }}>
        <AttachmentValueArea attachments={attachments} onAddAttachment={onAddAttachment} onRemoveAttachment={onRemoveAttachment} />
      </FlexColumnContainer>
    </AttachmentCardDetailRowContainer>
  )
}

export default CardAttachmentArea
