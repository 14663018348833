import moment from 'moment-timezone'
import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'
import type { Product86Action } from 'mgr/pages/single-venue/settings/actions/ordering/EightySixProductActions'
import type { EightySixProduct, SnoozeOption } from 'mgr/pages/single-venue/settings/types/ordering/EightySixProduct.types'
import type { EIGHTY_SIX_PRODUCT_TYPES, PRODUCT_STATES } from 'mgr/pages/single-venue/settings/types/ordering/Product.types'
import type { Moment } from 'moment'
import type { SortColumn } from 'svr/component-lib/Generic/Tables/BaseTable/table.types'

export const SNOOZE_OPTIONS: SnoozeOption[] = [
  {
    id: 'one-hour',
    display: '1 Hour',
    calcSnoozeTimes: (start?: Moment) => ({
      snoozeStartTime: start,
      snoozeEndTime: start?.clone().add(1, 'hour'),
    }),
  },
  {
    id: 'two-hour',
    display: '2 Hours',
    calcSnoozeTimes: (start?: Moment) => ({
      snoozeStartTime: start,
      snoozeEndTime: start?.clone().add(2, 'hour'),
    }),
  },
  {
    id: 'six-hour',
    display: '6 Hours',
    calcSnoozeTimes: (start?: Moment) => ({
      snoozeStartTime: start,
      snoozeEndTime: start?.clone().add(6, 'hour'),
    }),
  },
  {
    id: 'rest-of-day',
    display: 'Until rest of day',
    calcSnoozeTimes: (start?: Moment, startOfDayHour = 0) => ({
      snoozeStartTime: start,
      snoozeEndTime: start
        ?.clone()
        .add(1, 'day')
        .set('hour', startOfDayHour - 1)
        .set('minute', 59),
    }),
  },
  {
    id: 'indefinitely',
    display: 'Indefinitely',
    calcSnoozeTimes: (start?: Moment) => ({
      snoozeStartTime: start,
      snoozeEndTime: null,
    }),
  },
  {
    id: 'until_specific_date',
    display: 'Until Specific Date',
    calcSnoozeTimes: (start?: Moment, startOfDayHour = 0, selectedDate?: Moment) => ({
      snoozeStartTime: start,
      snoozeEndTime: selectedDate?.clone().set('hour', startOfDayHour).set('minute', 0).set('second', 0),
    }),
  },
  {
    id: 'make_available',
    display: 'Make Available',
    calcSnoozeTimes: () => ({
      snoozeStartTime: null,
      snoozeEndTime: null,
    }),
  },
]

export interface Product86State {
  isLoading: boolean
  list: EightySixProduct[]
  selectedSnoozeOptionId: string
  selectedItem: EightySixProduct | null
  productStateId: PRODUCT_STATES
  productFilterSearchValue: string
  productTypeFilter: EIGHTY_SIX_PRODUCT_TYPES
  selectedSpecificSnoozeDate: Moment
  sorted: SortColumn[]
}

const initialState: Product86State = {
  isLoading: false,
  list: [],
  selectedSnoozeOptionId: SNOOZE_OPTIONS[0]?.id as string,
  selectedItem: null,
  productStateId: 'available',
  productFilterSearchValue: '',
  productTypeFilter: 'ALL',
  selectedSpecificSnoozeDate: moment().add(1, 'day'),
  sorted: [
    {
      id: 'name',
      desc: false,
    },
  ],
}

export const orderingProductsReducer = (state = initialState, action: Product86Action) => {
  switch (action.type) {
    case ActionTypes.GET_ORDERING_PRODUCTS_LIST_START:
      return {
        ...state,
        list: [],
        isLoading: true,
      }
    case ActionTypes.GET_ORDERING_PRODUCTS_LIST_FAIL:
    case ActionTypes.SUBMIT_SNOOZE_PRODUCT_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.SUBMIT_SNOOZE_PRODUCT_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GET_ORDERING_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        list: action.data,
        isLoading: false,
      }
    case ActionTypes.SET_ORDERING_PRODUCT_SORTED_COLUMNS:
      return {
        ...state,
        sorted: action.data,
      }
    case ActionTypes.SELECT_SNOOZE_OPTION:
      return {
        ...state,
        selectedSnoozeOptionId: action.snoozeOptionId,
      }
    case ActionTypes.SELECT_PRODUCT_ITEM:
      return {
        ...state,
        selectedItem: action.product,
      }
    case ActionTypes.SELECT_PRODUCT_STATE_TAB:
      return {
        ...state,
        productStateId: action.productStateId,
      }
    case ActionTypes.SET_PRODUCT_FILTER_SEARCH_VALUE:
      return {
        ...state,
        productFilterSearchValue: action.value,
      }
    case ActionTypes.SET_PRODUCT_TYPE_FILTER:
      return {
        ...state,
        productTypeFilter: action.value,
      }
    case ActionTypes.SET_SPECIFIC_SNOOZE_DATE:
      return {
        ...state,
        selectedSpecificSnoozeDate: action.date,
      }
    default:
      return state
  }
}
