import { useState } from 'react'
import { useCreateEmailCampaignTextMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useHistory } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalActions,
  ModalTitle,
  type ModalHeaderProps,
  Tabs,
  TabPanel,
  Tab,
  TabList,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { Prompt } from './PromptForm'
import { Template } from './TemplateForm'

export interface TemplateForm {
  prompt: string
  callToAction: string
  tone: string
  style: string
  sender: string
}
export interface PromptForm {
  prompt: string
  callToAction: string
  sender: string
  customUser: string
}
interface AssistantFormModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onGenerateClick: Function
}

export function AssistantFormModal({ closeHref, onGenerateClick }: AssistantFormModalProps) {
  const { formatMessage } = useLocales()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const history = useHistory()
  const { venueId } = useVenueContext()
  const [generateEmailCampaign, { isLoading }] = useCreateEmailCampaignTextMutation()
  const tabs: { key: string; title: string }[] = [
    {
      key: 'template',
      title: formatMessage(emailBuilderMessages.assistantModalTemplateTab),
    },
    {
      key: 'prompt',
      title: formatMessage(emailBuilderMessages.assistantModalWritePromptTab),
    },
  ]

  const [templateForm, setTemplateForm] = useState<TemplateForm>({
    prompt: '',
    callToAction: '',
    tone: '',
    style: '',
    sender: '',
  })

  const [promptForm, setPromptForm] = useState<PromptForm>({
    prompt: '',
    callToAction: 'book-reservation',
    sender: '',
    customUser: '',
  })
  const isTemplateTab = selectedIndex === 0
  const disabled = isLoading || (isTemplateTab && Object.values(templateForm).every(field => !field))

  const generateCampaignTexts = async () => {
    const form = isTemplateTab ? templateForm : promptForm

    const response = await generateEmailCampaign({
      prompt: form,
      venueId,
    })

    if ('data' in response) {
      onGenerateClick(response.data)
      history.push(closeHref)
    }
  }

  return (
    <Modal ariaLabel="marketing assistant form">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle
          title={formatMessage(emailBuilderMessages.assistantModalTitle)}
          subTitle={<Text color="secondaryFont">{formatMessage(emailBuilderMessages.assistantFormModalSubTitle)}</Text>}
        />
      </ModalHeader>
      <ModalBody>
        <Tabs onSelect={index => setSelectedIndex(index)} selectedIndex={selectedIndex}>
          <TabList>
            {tabs.map(tab => (
              <Tab key={tab.key} data-test={`tab-${tab.key}`}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          <TabPanel>
            <Template formState={templateForm} setFormState={setTemplateForm} />
          </TabPanel>
          <TabPanel>
            <Prompt formState={promptForm} setFormState={setPromptForm} />
          </TabPanel>
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="generate-text-cancel">
            {formatMessage(emailBuilderMessages.sendTestEmailModalCancelButton)}
          </Button>
          <Button variant="primary" onClick={generateCampaignTexts} data-test="generate-text" disabled={disabled}>
            {formatMessage(emailBuilderMessages.assistantFormModalGenerateButton)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
