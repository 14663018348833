// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { FormCurrencyInput, type FormCurrencyInputProps } from '@sevenrooms/core/ui-kit/form'

export function CustomFormCurrencyInput(props: FormCurrencyInputProps) {
  return (
    <StyledWrapper>
      <FormCurrencyInput {...props} />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  input {
    text-align: right;
  }
`
