import { createSlice } from '@reduxjs/toolkit'

declare const window: Window

const initialState = {
  newInsightCount: window?.globalInit?.newInsightCount ?? 0,
}

const insightsNewInsightCountSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    incrementNewInsightCount: state => {
      state.newInsightCount += 1
    },
    decrementNewInsightCount: state => {
      state.newInsightCount -= 1
    },
  },
})

const { actions } = insightsNewInsightCountSlice
export const { incrementNewInsightCount, decrementNewInsightCount } = actions
export const { reducer: insightsNewInsightCountSliceReducer, name: insightsNewInsightCountSliceName } = insightsNewInsightCountSlice
