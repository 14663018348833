import React from 'react'
import { connect } from 'react-redux'
import * as AttachmentActions from 'mgr/actualslideout/actions/AttachmentActions'
import CardAttachmentArea from 'mgr/actualslideout/components/view/CardAttachmentArea'
import { VmsIcons } from 'svr/common/VmsIcons'

const mapStateToProps = state => ({
  attachments: state.viewResState.attachments || [],
})

const mapDispatchToProps = {
  onAddAttachment: AttachmentActions.trySinglePassStageAndRegisterAttachment,
  onRemoveAttachment: AttachmentActions.tryDeleteAlreadySavedAttachment,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardAttachmentArea)
