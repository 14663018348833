import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  useGetEmailCampaignByIdQuery,
  useGetExperiencesQuery,
  useGetClientTagGroupsQuery,
  useGetVenueProfileQuery,
  useGetByTemplateIdQuery,
  type OngoingEmailCampaignType,
  type OngoingEmailCampaignTemplate,
} from '@sevenrooms/core/api'
import { ExperienceStatusEnum } from '@sevenrooms/core/domain'
import { useDestination } from '@sevenrooms/core/navigation'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { OngoingEmailCampaignFromTemplateForm } from '../../components'

export function OngoingEmailCampaignFromTemplate() {
  const { params } = useDestination(routes.manager2.marketing.emailCenter.emails.editEmail)
  const { query } = useDestination(routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromTemplate)
  const campaignId = params ? params.campaignId : null
  const { parentTemplateId } = query

  const { venueId } = useVenueContext()
  const { data: parentTemplate, isFetching: isParentTemplateFetching } = useGetByTemplateIdQuery(
    parentTemplateId && venueId ? { campaignId: parentTemplateId, venueId } : skipToken
  )
  const { data: venueProfile, isFetching: isVenueProfileFetching } = useGetVenueProfileQuery({ venueId })
  const { data: experiencesData, isFetching: isExperiencesDataFetching } = useGetExperiencesQuery({
    venueId,
    status: ExperienceStatusEnum.ACTIVE,
  })
  const { data: autoTags, isFetching: isVenueClientTagsFetching } = useGetClientTagGroupsQuery(venueId ? { venueKey: venueId } : skipToken)
  const {
    data,
    isFetching: isEmailCampaignFetching,
    refetch: refetchGetEmailCampaign,
  } = useGetEmailCampaignByIdQuery(campaignId && venueId ? { campaignId, venueId } : skipToken)
  const isFetching =
    isVenueProfileFetching || isExperiencesDataFetching || isEmailCampaignFetching || isParentTemplateFetching || isVenueClientTagsFetching
  return isFetching || !venueProfile || !experiencesData || !autoTags ? (
    <Loader />
  ) : (
    <OngoingEmailCampaignFromTemplateForm
      emailCampaign={data?.emailCampaign as OngoingEmailCampaignType}
      refetchEmailCampaign={refetchGetEmailCampaign}
      venueProfile={venueProfile}
      experiencesData={experiencesData}
      parentTemplate={parentTemplate as OngoingEmailCampaignTemplate}
      autoTags={autoTags}
    />
  )
}
