import _ from 'lodash'
import * as ActionTypes from 'mgr/pages/venue-group/actions/ActionTypes'

const initialState = {
  podsConfigData: [],
  podAccessors: [],
  podCategoriesByAccessor: {},
  optimisticPending: 0,
  podKeyCounter: 0,
  totalVenues: 0,
  podCategorySearchGroupId: null,
  selectedPodCategoryId: null,
  selectedPodCategoryName: null,
  selectedPodCategoryHexColor: null,
  selectedPodCategoryIsSearchGroup: false,
  selectedPods: [],
  startAutoValidation: false,
  formErrors: [],
  isNewPodCategory: false,
  isLoading: true,
  showWarning: false,
  showReplaceSearchGroupWarning: false,
}

const getNewPod = (pod_key_order, name) => ({
  pod_key_order,
  name,
  num_venues: 0,
  is_active: true,
})

const setPodsActiveStateFalse = pods => {
  _.map(pods, pod => {
    pod.is_active = false
  })
  return pods
}

const setInitialPodsDeletedFalse = pods => {
  _.map(pods, pod => {
    pod.is_deleted = false
  })
  return pods
}

const getAddPodNextState = (state, venueId, pod) => {
  const { podsConfigData } = state
  const index = _.findIndex(podsConfigData, it => it.venue_id === venueId)
  const target = podsConfigData[index]
  return {
    ...state,
    podsConfigData: [
      ...podsConfigData.slice(0, index),
      { ...target, [pod.accessor_name]: sortPodsByName([...(target[pod.accessor_name] || []), pod]) },
      ...podsConfigData.slice(index + 1),
    ],
  }
}

const getRemovePodNextState = (state, venueId, pod) => {
  const { podsConfigData } = state
  const index = _.findIndex(podsConfigData, it => it.venue_id === venueId)
  const target = podsConfigData[index]
  return {
    ...state,
    podsConfigData: [
      ...podsConfigData.slice(0, index),
      { ...target, [pod.accessor_name]: _.filter(target[pod.accessor_name], it => it.id !== pod.id) },
      ...podsConfigData.slice(index + 1),
    ],
  }
}

const sortPodsByName = (pods = []) => _.sortBy(pods, 'name')

const sortPodsConfigData = (podsConfigData = {}, podAccessors = []) =>
  podsConfigData.map(it => podAccessors.reduce((acc, curr) => ({ ...acc, [curr]: sortPodsByName(acc[curr]) }), it))

const podsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_VENUE_POD_CONFIGS_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.OPEN_POD_SLIDEOUT: {
      const activeStateFalsePods = setPodsActiveStateFalse(action.selectedPods)
      let selectedPods = setInitialPodsDeletedFalse(activeStateFalsePods)
      if (selectedPods.length === 0) {
        selectedPods = []
      }
      return {
        ...state,
        isNewPodCategory: action.isNewPodCategory,
        selectedPodCategoryId: action.selectedPodCategoryId,
        selectedPodCategoryName: action.selectedPodCategoryName,
        selectedPodCategoryHexColor: action.selectedPodCategoryHexColor,
        selectedPodCategoryIsSearchGroup: action.selectedPodCategoryId === state.podCategorySearchGroupId,
        selectedPods,
        podKeyCounter: selectedPods.length,
        startAutoValidation: false,
        formErrors: {},
      }
    }
    case ActionTypes.CHANGE_POD_CATEGORY_NAME:
      return {
        ...state,
        selectedPodCategoryName: action.podCategoryName,
      }
    case ActionTypes.CHANGE_POD_HEX_COLOR:
      return {
        ...state,
        selectedPodCategoryHexColor: action.hexColor,
      }
    case ActionTypes.APPEND_NEW_POD: {
      const forcedInactivePods = setPodsActiveStateFalse(state.selectedPods)
      const newSelectedPods = [getNewPod(state.podKeyCounter, action.text), ...forcedInactivePods]
      if (state.startAutoValidation && action.callbackFunc) {
        action.callbackFunc(newSelectedPods, state.formErrors)
      }
      return {
        ...state,
        selectedPods: newSelectedPods,
        podKeyCounter: state.podKeyCounter + 1,
      }
    }
    case ActionTypes.CHANGE_POD_NAME: {
      const inactivePodsCopy = setPodsActiveStateFalse(state.selectedPods)
      const podsCopy = inactivePodsCopy.slice(0)
      podsCopy[action.idx].name = action.podName
      podsCopy[action.idx].is_active = true
      delete state.formErrors[podsCopy[action.idx].pod_key_order]
      if (state.startAutoValidation && action.callbackFunc) {
        action.callbackFunc(podsCopy, state.formErrors)
      }
      return {
        ...state,
        selectedPods: podsCopy,
        formErrors: state.formErrors,
      }
    }
    case ActionTypes.DELETE_POD: {
      const newPods = state.selectedPods.slice(0)
      const newPodKeyCounter = state.podKeyCounter
      newPods[action.idx].is_deleted = !newPods[action.idx].is_deleted
      delete state.formErrors[newPods[action.idx].pod_key_order]
      if (state.startAutoValidation && action.callbackFunc) {
        action.callbackFunc(newPods, state.formErrors)
      }
      return {
        ...state,
        selectedPods: newPods,
        podKeyCounter: newPodKeyCounter,
        formErrors: state.formErrors,
      }
    }
    case ActionTypes.LOAD_VENUE_POD_CONFIGS_SUCCESS:
    case ActionTypes.ADD_POD_CATEGORY_SUCCESS:
    case ActionTypes.EDIT_POD_CATEGORY_SUCCESS:
    case ActionTypes.DELETE_POD_CATEGORY_SUCCESS: {
      const hasPending = state.optimisticPending > 0
      return {
        ...state,
        podsConfigData: hasPending ? state.podsConfigData : sortPodsConfigData(action.podsConfigData, action.podAccessors),
        podAccessors: action.podAccessors,
        podCategoriesByAccessor: action.podCategoriesByAccessor,
        totalVenues: action.totalVenues,
        podCategorySearchGroupId: action.podCategorySearchGroupId,
        selectedPodCategoryId: null,
        selectedPodCategoryName: null,
        selectedPodCategoryHexColor: null,
        selectedPodCategoryIsSearchGroup: false,
        selectedPods: [],
        showWarning: false,
        showReplaceSearchGroupWarning: false,
        isLoading: false,
      }
    }
    case ActionTypes.TOGGLE_DELETE_WARNING:
      return {
        ...state,
        showWarning: !state.showWarning,
      }
    case ActionTypes.TOGGLE_REPLACE_SEARCH_GROUP_WARNING:
      return {
        ...state,
        showReplaceSearchGroupWarning: !state.showReplaceSearchGroupWarning,
      }
    case ActionTypes.FOCUS_POD_INPUT: {
      const podsForFocus = state.selectedPods.slice(0)
      podsForFocus[action.pod_idx].is_active = true
      return {
        ...state,
        selectedPods: podsForFocus,
      }
    }
    case ActionTypes.BLUR_POD_INPUT: {
      const podsForBlur = state.selectedPods.slice(0)
      podsForBlur[action.pod_idx].is_active = false
      return {
        ...state,
        selectedPods: podsForBlur,
      }
    }
    case ActionTypes.UPDATE_POD_SLIDEOUT_ERRORS:
      return {
        ...state,
        formErrors: action.formErrors,
        startAutoValidation: true,
      }
    case ActionTypes.TOGGLE_IS_SEARCH_GROUP:
      return {
        ...state,
        selectedPodCategoryIsSearchGroup: !state.selectedPodCategoryIsSearchGroup,
      }
    case ActionTypes.ADD_POD_TO_VENUE_OPTIMISTIC_UPDATE:
    case ActionTypes.REMOVE_POD_FROM_VENUE_OPTIMISTIC_ROLLBACK: {
      const { venueId, pod } = action
      return getAddPodNextState(state, venueId, pod)
    }
    case ActionTypes.ADD_POD_TO_VENUE_OPTIMISTIC_ROLLBACK:
    case ActionTypes.REMOVE_POD_FROM_VENUE_OPTIMISTIC_UPDATE: {
      const { venueId, pod } = action
      return getRemovePodNextState(state, venueId, pod)
    }
    case ActionTypes.ADD_PENDING_OPTIMISTIC_OPERATION: {
      return { ...state, optimisticPending: state.optimisticPending + 1 }
    }
    case ActionTypes.REMOVE_PENDING_OPTIMISTIC_OPERATION: {
      return { ...state, optimisticPending: state.optimisticPending - 1 }
    }
    default:
      return state
  }
}

export default podsReducer
