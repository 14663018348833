import _ from 'lodash'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

const computeDoBlockSlideoutClose = state => ({
  isViewMode: state.slideoutState.isViewMode,
  isSlideoutOpen: state.slideoutState.isSlideoutOpen,
  doBlockSlideoutClose: state.slideoutState.bookState.isDirty,
})

const computeSlideoutState = ({ isViewMode, isSlideoutOpen, doBlockSlideoutClose }) =>
  isViewMode ? isSlideoutOpen : !doBlockSlideoutClose && isSlideoutOpen
/** *************** */
/** DiningVenues * */
/** *************** */
// Dining venues that are in the same venue group as your current page's venue

const computeDiningVenuesParams = state => ({
  userDomain: state.appState.userDomain,
  venueGroupId: state.appState.venue.venueGroupId,
})

const computeDiningVenues = ({ userDomain, venueGroupId }) => {
  if (_.isEmpty(userDomain) || _.isEmpty(userDomain.venues)) {
    return []
  }
  return userDomain.venues.filter(v => v.isDiningClass && v.venueGroupId === venueGroupId)
}

export const selectDiningVenues = createShallowEqualSelector(computeDiningVenuesParams, computeDiningVenues)

/** ******************************** */
/** TimeSlotAvailableTableOptions * */
/** ******************************** */

const computeCommonTimeSlotAvailableTableOptionsParams = state => {
  const { bookState, bookDetailsState, bookAvailabilityState } = state
  const venue = state.bookState.selectedVenue
  if (_.isEmpty(venue)) {
    return {}
  }
  const { isAutoAssignEnabled, permissions } = venue
  const {
    canOverbook,
    canOverbookPacing,
    canOverbookLargerTables,
    canOverbookSmallerTables,
    canOverbookNoTables,
    canOverbookAccessBlocks,
    canOverrideTableCombos,
  } = permissions
  const { isAutoAssign, isCustomAssign, selectedTableIds } = bookDetailsState
  const { selectedTimeSlot, floorPlan } = bookAvailabilityState
  return {
    selectedTimeSlot,
    floorPlan,
    isAutoAssign,
    isCustomAssign,
    selectedTableIds,
    isAutoAssignEnabled,
    canOverbook,
    canOverbookPacing,
    canOverbookLargerTables,
    canOverbookSmallerTables,
    canOverbookNoTables,
    canOverbookAccessBlocks,
    canOverrideTableCombos,
  }
}

const computeBookModeTimeSlotAvailableTableOptionsParams = state => {
  const params = computeCommonTimeSlotAvailableTableOptionsParams(state)
  const { isAutoAssign, isCustomAssign, selectedTableIds } = state.bookDetailsState
  return {
    ...params,
    isAutoAssign,
    isCustomAssign,
    selectedTableIds,
  }
}

const computeViewModeTimeSlotAvailableTableOptionsParams = state => {
  const params = computeCommonTimeSlotAvailableTableOptionsParams(state)
  const { isAutoAssign, isCustomAssign, selectedTableIds } = state.viewResState
  return {
    ...params,
    isAutoAssign,
    isCustomAssign,
    selectedTableIds,
  }
}

const computeTimeSlotAvailableTableOptions = ({
  selectedTimeSlot,
  floorPlan,
  isAutoAssign,
  isCustomAssign,
  selectedTableIds,
  isAutoAssignEnabled,
  canOverbook,
  canOverbookPacing,
  canOverbookLargerTables,
  canOverbookSmallerTables,
  canOverbookNoTables,
  canOverbookAccessBlocks,
  canOverrideTableCombos,
}) => {
  if (_.isEmpty(selectedTimeSlot)) {
    return { availableTableOptions: [], isCustomAssign }
  }

  const unassignedGroupName = isAutoAssignEnabled ? 'Currently Auto-Assigned' : 'Not Recommended'
  const recommendedGroup = { name: 'Recommended', choices: [] }
  const unassignedGroup = { name: unassignedGroupName, choices: [] }
  const blockedGroup = { name: 'Blocked', choices: [] }
  const largerTableSizeGroup = { name: 'Larger Table Size', choices: [] }
  const smallerTableSizeGroup = { name: 'Smaller Table Size', choices: [] }
  const largerAndBlockedGroup = {
    name: 'Larger Table Size & Blocked',
    choices: [],
  }
  const smallerAndBlockedGroup = {
    name: 'Smaller Table Size & Blocked',
    choices: [],
  }
  const selectedTableId = _.map(selectedTableIds, v => v.value)
    .sort()
    .join(',')
  const isSelectedTable = table => selectedTableId === table.id
  let isSelectedTablesInTopLevel = false

  const _extractSlotTableFields = table => {
    let isBlocked = false
    let blockName = null
    if (table.is_hold) {
      isBlocked = true
      blockName = table.hold_name
    } else if (table.is_blocked) {
      isBlocked = true
      blockName = table.blocks[0].name
    }

    let name = table.name.concat(' (', table.party_size_range, ')')
    if (blockName && blockName.length > 0) {
      name = name.concat(' ', blockName)
    }
    const tableId = table.id
    const tableIds = tableId.split(',')
    const value = tableId
    const selectValue = { name, tableId, tableIds, isBlocked, blockName }
    if (!isSelectedTablesInTopLevel && isSelectedTable(table)) {
      isSelectedTablesInTopLevel = true
    }
    return { value, name, selectValue }
  }

  for (const table of selectedTimeSlot.recommended) {
    recommendedGroup.choices.push(_extractSlotTableFields(table))
  }

  for (const table of selectedTimeSlot.unassigned) {
    unassignedGroup.choices.push(_extractSlotTableFields(table))
  }

  let hasLargerAndBlockedTable = false
  let hasSmallerAndBlockedTable = false
  let hasBookableBlocks = false

  for (const table of selectedTimeSlot.blocked) {
    const isSelected = isSelectedTable(table)
    if (canOverbook || table.has_permissions || isSelected) {
      if (table.is_table_size_too_large) {
        largerAndBlockedGroup.choices.push(_extractSlotTableFields(table))
        hasLargerAndBlockedTable = true
      } else if (table.is_table_size_too_small) {
        smallerAndBlockedGroup.choices.push(_extractSlotTableFields(table))
        hasSmallerAndBlockedTable = true
      } else {
        blockedGroup.choices.push(_extractSlotTableFields(table))
        hasBookableBlocks = true
      }
    }
  }

  for (const table of selectedTimeSlot.held) {
    const isSelected = isSelectedTable(table)
    if (canOverbookAccessBlocks || isSelected) {
      if (table.is_table_size_too_large) {
        largerAndBlockedGroup.choices.push(_extractSlotTableFields(table))
        hasLargerAndBlockedTable = true
      } else if (table.is_table_size_too_small) {
        smallerAndBlockedGroup.choices.push(_extractSlotTableFields(table))
        hasSmallerAndBlockedTable = true
      } else {
        blockedGroup.choices.push(_extractSlotTableFields(table))
        hasBookableBlocks = true
      }
    }
  }

  let hasLargerTableSize = false
  let hasSmallerTableSize = false
  for (const table of selectedTimeSlot.table_size_too_large) {
    const isSelected = isSelectedTable(table)
    if (canOverbookLargerTables || isSelected) {
      largerTableSizeGroup.choices.push(_extractSlotTableFields(table))
      hasLargerTableSize = true
    }
  }
  for (const table of selectedTimeSlot.table_size_too_small) {
    const isSelected = isSelectedTable(table)
    if (canOverbookSmallerTables || isSelected) {
      smallerTableSizeGroup.choices.push(_extractSlotTableFields(table))
      hasSmallerTableSize = true
    }
  }

  const availableTableOptions = []
  if (selectedTimeSlot.recommended.length > 0) {
    availableTableOptions.push(recommendedGroup)
  }

  if (selectedTimeSlot.unassigned.length > 0) {
    availableTableOptions.push(unassignedGroup)
  }

  if (hasBookableBlocks === true) {
    availableTableOptions.push(blockedGroup)
  }

  if (hasLargerTableSize === true) {
    availableTableOptions.push(largerTableSizeGroup)
  }

  if (hasSmallerTableSize === true) {
    availableTableOptions.push(smallerTableSizeGroup)
  }

  if (hasLargerAndBlockedTable === true) {
    availableTableOptions.push(largerAndBlockedGroup)
  }

  if (hasSmallerAndBlockedTable === true) {
    availableTableOptions.push(smallerAndBlockedGroup)
  }

  const noTables = !selectedTimeSlot.unassigned.length && !selectedTimeSlot.recommended.length && hasBookableBlocks === false

  // show "Auto Assign" if auto assign is turned on
  // If there are no tables, we show the 'no tables' option, and disable the select menu.
  if (isAutoAssignEnabled) {
    availableTableOptions.unshift({ value: 'auto', name: 'Auto Assign' })
  }

  if (!_.isEmpty(selectedTableIds) && !isSelectedTablesInTopLevel) {
    // Table dropdown needs to go into Custom section - typically caused by mobile assigning to a table that isn't recommended (since mobile doesn't set the is_custom_assign flag)
    // Also happens is combo assignment is no longer a combo, or comes in via tripleseat and was never a combo
    // If so, break the comma-separated combo values into separate objects with individual table id values
    isCustomAssign = true
    selectedTableIds = selectedTableIds.reduce(
      (accum, selectedTableId) => accum.concat(selectedTableId.value?.split(',').map(v => ({ value: v })) ?? []),
      []
    )
  }

  if (canOverrideTableCombos || isCustomAssign) {
    const customTables = ((floorPlan || {}).tableItems || []).map(table => {
      const tableId = table.id
      const tableIds = [tableId]
      const value = tableId
      const name = table.item_code.concat(' (', table.party_size_range_display, ')')
      const isBlocked = false
      const blockName = null
      const selectValue = { name, tableId, tableIds, isBlocked, blockName }
      return { value, name, selectValue }
    })
    availableTableOptions.unshift({
      value: 'custom',
      name: 'Custom',
      nested: customTables,
      isMulti: true,
    })
  }

  if (noTables) {
    availableTableOptions.unshift({ value: '', name: 'No Tables' })
  } else {
    availableTableOptions.unshift({ value: '', name: 'Unassigned' })
  }

  return { availableTableOptions, isCustomAssign, selectedTableIds }
}

export const isTimeSlotBookable = (timeSlot, venue, tableId = null) => {
  const {
    canOverbookPacing,
    canOverbookLargerTables,
    canOverbookSmallerTables,
    canOverbookNoTables,
    canOverbookTotalShiftCovers,
    canOverbookAccessBlocks,
  } = venue.permissions
  const { timeMoment, status } = timeSlot
  const subStatus = timeSlot.sub_status
  const isTableMatch = !_.isNil(tableId) // tableId specifically must be bookable by the current user for this time slot

  if (status === 'blocked') {
    // check if there are any blocked tables the user can book
    const blockedTables = timeSlot.blocked
    for (const blockedTable of blockedTables) {
      if (isTableMatch && blockedTable.id !== tableId) {
        continue
      }
      if (blockedTable.has_permissions) {
        return true
      }
    }

    if (!canOverbookAccessBlocks) {
      return false
    }

    const heldTables = timeSlot.held
    if (isTableMatch) {
      return isTableIdInTableList(tableId, heldTables)
    } else if (heldTables.length) {
      return true
    }

    return false
  }

  if (
    status !== 'available' &&
    !(canOverbookTotalShiftCovers && status === 'max_total_covers') &&
    !(canOverbookPacing && _.includes(['overbooked', 'overbooking'], status)) &&
    !(canOverbookLargerTables && status === 'no_tables' && subStatus === 'only_larger_tables') &&
    !(canOverbookSmallerTables && status === 'no_tables' && subStatus === 'only_smaller_tables') &&
    !(canOverbookNoTables && status === 'no_tables')
  ) {
    return false
  }

  if (!isTableMatch) {
    return true
  }

  if (isTableIdInTableList(tableId, timeSlot.recommended)) {
    return true
  }

  if (isTableIdInTableList(tableId, timeSlot.unassigned)) {
    return true
  }

  if (canOverbookLargerTables && isTableIdInTableList(tableId, timeSlot.table_size_too_large)) {
    return true
  }

  if (canOverbookSmallerTables && isTableIdInTableList(tableId, timeSlot.table_size_too_small)) {
    return true
  }

  if (canOverbookAccessBlocks && isTableIdInTableList(tableId, timeSlot.held)) {
    return true
  }

  const blockedTableMatch = findTableInTableList(tableId, timeSlot.blocked)
  if (!_.isNil(blockedTableMatch) && blockedTableMatch.has_permissions) {
    return true
  }

  return false
}

const isTableIdInTableList = (tableId, tableList) => !_.isNil(findTableInTableList(tableId, tableList))

const findTableInTableList = (tableId, tableList) => _.find(tableList, { id: tableId })

export const selectBookModeTimeSlotAvailableTableOptions = createShallowEqualSelector(
  computeBookModeTimeSlotAvailableTableOptionsParams,
  computeTimeSlotAvailableTableOptions
)
export const selectViewModeTimeSlotAvailableTableOptions = createShallowEqualSelector(
  computeViewModeTimeSlotAvailableTableOptionsParams,
  computeTimeSlotAvailableTableOptions
)
