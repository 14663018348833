import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { TagElement } from 'mgr/lib/components/Tags'
import { getContrastingColor } from 'svr/common/Color'
import { Row, Col } from 'svr/lib/styled-bootstrap-grid'

const ResultTextCol = styled(Col)`
  font-weight: 500;
  height: 22px;
  line-height: 22px;
  color: ${props => props.theme.navigationDark};
  margin-top: 2px;
  margin-bottom: 2px;
  ${props => props.theme.ellipsis};
`

const NameCol = styled(ResultTextCol)`
  font-size: 20px;
`

const ContactCol = styled(ResultTextCol)`
  color: ${props => props.theme.navigationDark};
  font-size: 13px;
  font-weight: 500;
`

const SourceTagContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
`

export const SourceBodyDisplay = ({ client }) => {
  const contactContents = _.compact([client.phone_number_formatted, client.email_address, client.company]).join(' · ')
  const tagsContents = (client.tags_display || [])
    .filter(tag => tag.is_source)
    .map((elem, idx) => {
      const key = `${elem.tag_group_id}##${elem.tag_name}`
      return <TagElement key={key} tagObj={elem} />
    })[0]

  return (
    <Row>
      <Col xs={12}>
        {client && client.name_display && (
          <Row>
            <NameCol>{client.name_display}</NameCol>
          </Row>
        )}

        {contactContents && (
          <Row>
            <ContactCol>{contactContents}</ContactCol>
          </Row>
        )}
      </Col>

      <SourceTagContainer> {tagsContents} </SourceTagContainer>
    </Row>
  )
}
