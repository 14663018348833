import { Box, Flex, Loader } from '@sevenrooms/core/ui-kit/layout'
import { CampaignPerformanceCard, type CampaignPerformanceCardProps } from '../CampaignPerformanceCard'

interface CampaignPerformanceStatisticsProps {
  cards: CampaignPerformanceCardProps[]
  secondary?: boolean
}

export function CampaignPerformanceStatistics({ cards }: CampaignPerformanceStatisticsProps) {
  if (!cards.length) {
    return <Loader />
  }

  return (
    <Box width="100%" mr="l" data-test="campaign-performance-statistics-cards">
      <Flex justifyContent="space-between" flexBasis="100%">
        {cards.map(card => (
          <CampaignPerformanceCard key={card.name} {...card} />
        ))}
      </Flex>
    </Box>
  )
}
