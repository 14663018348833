import React, { useState, useEffect } from 'react'
import Textarea from 'react-textarea-autosize'
import styled from 'styled-components'
import AttachmentArea from 'mgr/actualslideout/components/view/AttachmentArea'
import { BackwriterMessageLoader } from 'mgr/lib/components/BackwriterMessageLoader'
import Button from 'svr/common/Button'
import { Col, Row } from 'svr/lib/styled-bootstrap-grid'
import { FlexColumnContainer, FlexRowContainer } from './view/ViewLayout'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { QuickButton } from '@sevenrooms/core/ui-kit/form'
import { BackwriterPromptType } from '@sevenrooms/core/domain'

const MatchLegacyStyledButton = styled.div`
  & a {
    border-radius: 3px;
    height: 40px;
    line-height: 40px !important;
    background: #347baf;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 14px;
    min-width: 70px;
    padding: 0 30px;
    text-align: center;
  }
`
const ProblemResBannerOuter = styled.div`
  width: 100%;
  height: 57px;
  text-align: left;
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.background};
  font-size: 14px;
  padding-left: 21px;
  line-height: 54px;
  background-color: ${props => (props.isMajorProblem ? props.theme.error : props.theme.warning)};
  ${props => props.theme.fontWeight300};
`
export const ProblemResBanner = ({ resProblem }) => (
  <Row>
    <Col xs={12}>
      <ProblemResBannerOuter isMajorProblem={resProblem.is_major}>{resProblem.problem_name}</ProblemResBannerOuter>
    </Col>
  </Row>
)

const TranslateWrapper = styled.div`
  overflow-y: visible;
  height: ${props => props.height}px;
  width: 100%;
`

const MessageEntryWrapper = styled.div`
  width: 100%;
  border-radius: 3px;
  padding: 0;
  background-color: ${props => props.theme.white};
  border: 1px solid #e3e3e3;

  &:focus-within {
    outline-style: auto;
    outline-width: 1px;
    outline-color: currentColor;
    outline-color: -webkit-focus-ring-color;
  }
`

const MessageEntryTextArea = styled(Textarea)`
  width: 100%;
  height: 100%;
  padding: 16px;
  resize: none;
  border: none;
  border-radius: 3px;
  outline: none;
  font-family: ${props => props.theme.fontFamily};
  font-size: 15px;
  color: ${props => props.theme.darkGrey};
`

const HEIGHT_ADJUSTMENT = -5
const BACKWRITER_CONTROL_HEIGHT = 40
const TEXTAREA_MIN_HEIGHT = 64
const MESSAGE_BOX_MIN_HEIGHT = TEXTAREA_MIN_HEIGHT + BACKWRITER_CONTROL_HEIGHT - HEIGHT_ADJUSTMENT
const ATTACHMENT_HEIGHT_DELTA = 19

export function ChatMessageBox({
  hasEmail,
  placeholder,
  sendMessage,
  addAttachment,
  deleteAttachment,
  bufferedAttachments,
  removeAttachmentArea,
  showBackwriterLoader,
  backwriterMessage,
  height,
  setHeight,
  message,
  setMessage,
  showSendButton,
  setShowSendButton,
  generateResponse,
  isBackwriterEnabled = false,
}) {
  const [attachmentCount, setAttachmentCount] = useState(0)

  useEffect(() => {
    if (isBackwriterEnabled && !showBackwriterLoader) {
      setMessage(backwriterMessage)
    }
  }, [backwriterMessage, setMessage, showBackwriterLoader, isBackwriterEnabled])

  function submitMessage() {
    sendMessage(message)
    setShowSendButton(false)
    setAttachmentCount(0)
    setHeight(MESSAGE_BOX_MIN_HEIGHT)
    setMessage('')
  }

  function changeMessage(event) {
    const message = event.target.value
    setShowSendButton(!!message)
    setMessage(message)
  }

  function makeActive() {
    setShowSendButton(true)
  }

  function updateHeight(height) {
    const newHeight = height + HEIGHT_ADJUSTMENT
    setHeight(newHeight)
  }

  function onAddAttachment(files) {
    addAttachment(files)
    setAttachmentCount(attachmentCount => attachmentCount + 1)
    const newHeight = attachmentCount > 1 ? height + ATTACHMENT_HEIGHT_DELTA : height
    setHeight(newHeight)
  }

  function onDeleteAttachment(blobKey) {
    deleteAttachment(blobKey)
    setAttachmentCount(attachmentCount => attachmentCount - 1)
    const newHeight = height - ATTACHMENT_HEIGHT_DELTA
    const clampedHeight = newHeight < 60 ? 60 : newHeight
    const finalHeight = attachmentCount <= 1 ? 60 : clampedHeight
    setHeight(finalHeight)
  }

  const buttonStyle = { height: '41px', width: '123px', marginTop: '10px' }
  const translateHeight = Math.max(TEXTAREA_MIN_HEIGHT - 10, height)
  const translate = showSendButton ? `translate3d(0,-${translateHeight}px,0)` : 'translate3d(0,0px,0)'
  const loaderHeight = `${Math.max(MESSAGE_BOX_MIN_HEIGHT, height + BACKWRITER_CONTROL_HEIGHT - HEIGHT_ADJUSTMENT)}px`

  return (
    <TranslateWrapper height={90 + (isBackwriterEnabled ? BACKWRITER_CONTROL_HEIGHT : 0)}>
      <FlexColumnContainer
        alignItems="flex-end"
        style={{
          width: '100%',
          transition: 'transform 0.5s ease',
          transform: translate,
          borderTop: '1px solid grey',
          backgroundColor: '#EBEDF0',
          padding: '15px',
        }}
      >
        <FlexRowContainer justifyContent="flex-end">
          {showBackwriterLoader ? (
            <Box borderRadius="s" borderColor="borders" overflow="hidden" height={loaderHeight} width="100%">
              <BackwriterMessageLoader />
            </Box>
          ) : (
            <MessageEntryWrapper
              style={{
                minHeight: `${isBackwriterEnabled ? MESSAGE_BOX_MIN_HEIGHT : TEXTAREA_MIN_HEIGHT}px`,
              }}
            >
              <MessageEntryTextArea
                onClick={makeActive}
                maxRows={6}
                onHeightChange={updateHeight}
                value={message}
                style={{
                  minHeight: `${TEXTAREA_MIN_HEIGHT}px`,
                }}
                placeholder={placeholder}
                onChange={changeMessage}
                data-test="actualslideout-messaging-message-entry-text-area"
              />
              {isBackwriterEnabled && (
                <HStack width="100%" alignItems="end" justifyContent="flex-end">
                  <Box m="s">
                    <QuickButton
                      disabled={!message}
                      icon="VMSWeb-action-stars"
                      onClick={() => generateResponse(BackwriterPromptType.Rephrase)}
                      data-test="backwriter-button-rephrase"
                    >
                      Rephrase
                    </QuickButton>
                  </Box>
                </HStack>
              )}
            </MessageEntryWrapper>
          )}
        </FlexRowContainer>
        <FlexRowContainer justifyContent="space-between" alignItems="flex-start">
          <div style={{ maxWidth: '75%', marginTop: '10px' }}>
            {!removeAttachmentArea && (
              <AttachmentArea attachments={bufferedAttachments} onAddAttachment={onAddAttachment} onDeleteAttachment={onDeleteAttachment} />
            )}
          </div>
          <MatchLegacyStyledButton data-test="actualslideout-messaging-send-button">
            <Button label="Send" disabled={!hasEmail} onClick={submitMessage} style={buttonStyle} />
          </MatchLegacyStyledButton>
        </FlexRowContainer>
      </FlexColumnContainer>
    </TranslateWrapper>
  )
}
