import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import { DetailRow, DetailList } from 'mgr/actualslideout/components/payment/ViewComponents'
import {
  AddTextWithBoldPart,
  Card,
  CardBase,
  CardDetailFactory,
  CardSection,
  ViewCardTitleSmall,
  CardDivider,
  CardDetailRowContainer,
  IconArea,
} from 'mgr/actualslideout/components/view/ViewCommon'
import { VmsIcons, StyledVmsIconXS } from 'svr/common/VmsIcons'
import { FlexColumnContainer, FlexRowContainer } from './ViewLayout'

const TotalRow = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
  color: ${props => props.theme.color.black};
`

const Block = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${props => props.width};
`

const BlockLeft = styled(Block)`
  text-align: left;
`

const BlockRight = styled(Block)`
  text-align: right;
`

const BlockContainer = styled.div`
  display: flex;
  width: 100%;
`

const BlockTwoSet = ({ one, two, testIdOne, testIdTwo }) => (
  <BlockContainer>
    <BlockLeft data-test={testIdOne} width="50%">
      {one}
    </BlockLeft>
    <BlockRight data-test={testIdTwo} width="50%">
      {two}
    </BlockRight>
  </BlockContainer>
)

const BlockTwoLeftSet = ({ one, two }) => (
  <BlockContainer>
    <BlockLeft width="30%">{one}</BlockLeft>
    <BlockLeft width="70%">{two}</BlockLeft>
  </BlockContainer>
)

const BlockThreeSet = ({ one, two, three }) => (
  <BlockContainer>
    <BlockLeft width="10%">{one}</BlockLeft>
    <BlockLeft width="70%">{two}</BlockLeft>
    <BlockRight width="20%">{three}</BlockRight>
  </BlockContainer>
)

const SubtotalRow = styled.div`
  color: ${props => props.theme.navigationDark};
  font-family: ${props => props.theme.fontFamily};
  font-size: 15px;
  font-weight: 300;
  white-space: pre-line;
  margin: 0 0 10px;
`

const ItemizedHeader = styled.div`
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  color: #858e95;
  font-size: 11px;
  padding: 8px 16px 5px;
  text-transform: uppercase;
`

const DetailSet = styled.div`
  color: #858e95;
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;
`

const InfoToggle = styled.div`
  color: #347baf;
  cursor: pointer;
  font-size: 12px;
  font-weight: 900;
  paddind-top: 10px;
  text-transform: uppercase;
`

export const SpendCardTotals = ({
  spendDetailsOpen,
  toggleSpendDetails,
  totalNet,
  onsiteNet,
  onsiteTaxPercentage,
  onsiteTaxAmount,
  onsiteTipPercentage,
  onsiteTipAmount,
  onsiteAdminFeePercentage,
  onsiteAdminFeeAmount,
  onsiteGross,
  prepaymentNet,
  prepaymentServiceCharge,
  prepaymentTaxAmount,
  prepaymentTipAmount,
  prepaymentGross,
}) => (
  <FlexRowContainer alignItems="flex-start">
    <FlexColumnContainer>
      <TotalRow>
        <BlockTwoSet one="Total Net Spend" two={totalNet} testIdTwo="sr-label-net_spend" />
      </TotalRow>
      {spendDetailsOpen ? (
        <div>
          {onsiteNet ? (
            <SubtotalRow>
              <BlockTwoSet one="Onsite Net Spend" two={onsiteNet} />
            </SubtotalRow>
          ) : null}
          {onsiteGross ? (
            <DetailList>
              <DetailRow>
                <BlockTwoSet one="Subtotal" two={onsiteNet} />
              </DetailRow>
              {onsiteTaxAmount ? (
                <DetailRow>
                  <BlockTwoSet one={`Tax (${onsiteTaxPercentage})`} two={onsiteTaxAmount} />
                </DetailRow>
              ) : null}
              {onsiteTipAmount ? (
                <DetailRow>
                  <BlockTwoSet one={`Tip (${onsiteTipPercentage})`} two={onsiteTipAmount} />
                </DetailRow>
              ) : null}
              {onsiteAdminFeeAmount ? (
                <DetailRow>
                  <BlockTwoSet one={`Admin Fee (${onsiteAdminFeePercentage})`} two={onsiteAdminFeeAmount} />
                </DetailRow>
              ) : null}
              {onsiteGross ? (
                <DetailRow borderTop>
                  <BlockTwoSet one="Onsite Gross" two={onsiteGross} />
                </DetailRow>
              ) : null}
            </DetailList>
          ) : null}
          {prepaymentNet ? (
            <SubtotalRow>
              <BlockTwoSet one="Prepayment Net Spend" two={prepaymentNet} testIdTwo="sr-prepayment_net_spend" />
            </SubtotalRow>
          ) : prepaymentGross ? (
            <SubtotalRow>
              <BlockTwoSet one="Prepayment Gross Spend" two={prepaymentGross} />
            </SubtotalRow>
          ) : null}
          {prepaymentNet ? (
            <DetailList>
              {prepaymentNet ? (
                <DetailRow>
                  <BlockTwoSet one="Subtotal" two={prepaymentNet} testIdTwo="sr-label-subtotal" />
                </DetailRow>
              ) : null}
              {prepaymentServiceCharge ? (
                <DetailRow>
                  <BlockTwoSet one="Service Charge" two={prepaymentServiceCharge} />
                </DetailRow>
              ) : null}
              {prepaymentTaxAmount ? (
                <DetailRow>
                  <BlockTwoSet one="Tax" two={prepaymentTaxAmount} />
                </DetailRow>
              ) : null}
              {prepaymentTipAmount ? (
                <DetailRow>
                  <BlockTwoSet one="Tip" two={prepaymentTipAmount} />
                </DetailRow>
              ) : null}
              {prepaymentGross ? (
                <DetailRow borderTop>
                  <BlockTwoSet one="Prepayment Gross" two={prepaymentGross} testIdTwo="sr-label-prepayment_gross" />
                </DetailRow>
              ) : null}
            </DetailList>
          ) : null}
        </div>
      ) : null}
      <InfoToggle data-test="sr-button-show_details" onClick={toggleSpendDetails}>
        {spendDetailsOpen ? 'Hide' : 'Show'} details
      </InfoToggle>
    </FlexColumnContainer>
  </FlexRowContainer>
)

export const SpendCardDetails = ({ server, checkNumbers, paidBy }) => (
  <FlexRowContainer alignItems="flex-start">
    <FlexColumnContainer>
      <DetailSet>
        <BlockTwoLeftSet one="Server" two={server} />
        <BlockTwoLeftSet one="Check No." two={checkNumbers} />
        {paidBy && <BlockTwoLeftSet one="Paid By" two={paidBy} />}
      </DetailSet>
    </FlexColumnContainer>
  </FlexRowContainer>
)

export const SpendCardItemHeader = ({ itemCount }) => (
  <ItemizedHeader>
    <BlockTwoSet one={`Items Ordered (${itemCount})`} two="↑ Price" />
  </ItemizedHeader>
)

export const SpendCardItemList = ({ spendListOpen, toggleSpendList, items }) => (
  <FlexRowContainer alignItems="flex-start">
    <FlexColumnContainer>
      <DetailSet>
        {_.map(items, (item, i) => {
          if (i > 2 && !spendListOpen) {
            return null
          }
          return <BlockThreeSet key={`checkCharge${i}`} one={item.quantity} two={item.name} three={item.price} />
        })}
      </DetailSet>
      <InfoToggle onClick={toggleSpendList}>Show {spendListOpen ? 'less' : 'all'}</InfoToggle>
    </FlexColumnContainer>
  </FlexRowContainer>
)
