import { EmailCampaignCategoryEnum } from '@sevenrooms/core/domain'
import { defineMessages } from '@sevenrooms/core/locales'

export const emailTemplateMessages = defineMessages({
  emailTemplateTitle: {
    id: 'emailTemplate.title',
    defaultMessage: 'SevenRooms Standard Automated Emails',
  },
  emailTemplateDescription: {
    id: 'emailTemplate.description',
    defaultMessage:
      'These Automated Emails have been created and tested by our experts and have been proven to drive incremental reservations, visits, and revenue for restaurants',
  },
  templateModalButton: {
    id: 'emailTemplate.templateModalButton',
    defaultMessage: 'Use this template',
  },
  templateCardText: {
    id: 'emailTemplate.templateCardText',
    defaultMessage: 'Try this Template',
  },
  inUseTemplateCardText: {
    id: 'emailTemplate.templateCardText',
    defaultMessage: 'Currently in use',
  },
  emptyTemplateText: {
    id: 'emailTemplate.emptyTemplateText',
    defaultMessage: `But don’t forget to check back regularly as our team of experts constantly works on creating new best-in-class
    campaigns that drive incremental reservations, visits, and revenue. You can fine-tune campaigns and see their
    performance by going to the Email Center page.`,
  },
  emptyTemplateButtonText: {
    id: 'emailTemplate.emptyTemplateButtonText',
    defaultMessage: 'Email Center',
  },
  emptyTemplateHeaderText: {
    id: 'emailTemplate.emptyTemplateHeaderText',
    defaultMessage: 'Amazing work, you are using our Standard campaigns to the max!',
  },
  emailCampaignCategoryTHANK_YOUText: {
    id: 'emailTemplate.THANK_YOU',
    defaultMessage: EmailCampaignCategoryEnum.THANK_YOU,
  },
  emailCampaignCategoryWINBACKText: {
    id: 'emailTemplate.WINBACK',
    defaultMessage: EmailCampaignCategoryEnum.WINBACK,
  },
  emailCampaignCategoryFEEDBACKText: {
    id: 'emailTemplate.FEEDBACK',
    defaultMessage: EmailCampaignCategoryEnum.FEEDBACK,
  },
  emailCampaignCategoryABANDONText: {
    id: 'emailTemplate.ABANDON',
    defaultMessage: EmailCampaignCategoryEnum.ABANDON,
  },
  emailCampaignCategoryOTHERText: {
    id: 'emailTemplate.OTHER',
    defaultMessage: EmailCampaignCategoryEnum.OTHER,
  },
} as const)
