import { showSuccessMessage, showErrorMessage } from 'mgr/lib/actions/GlobalActions'
import { requestLightSpeedConnection, requestTestLightSpeedConnection } from './services'

export const IS_LIGHTSPEED_CONNECTED = 'IS_LIGHTSPEED_CONNECTED'
export const setLightspeedIsConnected = isConnected => ({
  type: IS_LIGHTSPEED_CONNECTED,
  isConnected,
})

export const checkLightspeedConnection = venue_id => dispatch => {
  requestLightSpeedConnection(venue_id).then(r => dispatch(setLightspeedIsConnected(r)))
}

export const testLightspeedConnection = venue_id => dispatch => {
  requestTestLightSpeedConnection(venue_id).then(
    r => {
      dispatch(showSuccessMessage(r.payload.message))
    },
    error => {
      dispatch(showErrorMessage(error.message))
    }
  )
}
