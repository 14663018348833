import { showSuccessMessage, showErrorMessage } from 'mgr/lib/actions/GlobalActions'
import { requestKountaConnection, requestTestKountaConnection } from './services'

export const IS_KOUNTA_CONNECTED = 'IS_KOUNTA_CONNECTED'
export const setKountaIsConnected = isConnected => ({
  type: IS_KOUNTA_CONNECTED,
  isConnected,
})

export const checkKountaConnection = venue_id => dispatch => {
  requestKountaConnection(venue_id).then(r => dispatch(setKountaIsConnected(r)))
}

export const testKountaConnection = venue_id => dispatch => {
  requestTestKountaConnection(venue_id).then(
    r => {
      dispatch(showSuccessMessage(r.payload.message))
    },
    error => {
      dispatch(showErrorMessage(error.message))
    }
  )
}
