const PASSING_NAMES = ['data', 'test', 'id']

const isIncluded = fullPropName => PASSING_NAMES.some(passName => fullPropName.indexOf(passName) === 0)

export const passDataAttrs = props =>
  Object.keys(props).reduce((acc, key) => {
    if (!isIncluded(key)) {
      return acc
    }

    return Object.assign(acc, {
      [key]: props[key],
    })
  }, {})
