import _ from 'lodash'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { customIcon, VmsIcons } from 'svr/common/VmsIcons'
import { CommonBadge, FollowerBadge, FollowerBookedByContainer, FollowerBookedbyNameLabel, AddButton } from './ViewCommon'
import { ScrollableList } from './ViewLayout'

const FollowerDisplay = styled(ScrollableList)`
  display: flex;
  flex-direction: row;
  width: 100% !important;
`
const AddBadgeHack = styled(CommonBadge)`
  position: relative;
`

const CornerIcon = styled(customIcon('17px'))`
  position: absolute;
  color: ${props => props.theme.primary};
  line-height: 17px;
  height: 17px;
  border-radius: 9px;
  letter-spacing: 0;
  top: -5px;
  right: -5px;
  display: none;

  ${FollowerBadge}:hover & {
    display: block;
  }
`

// Stopgap until the whole workflow is done.
const InterimFollowerSelector = ({ potentialFollowers, onAddFollower, ref }) => {
  const options = potentialFollowers.map(follower => (
    <option key={follower.id} onClick={() => onAddFollower(follower.id)} value={follower.id}>
      {follower.name}
    </option>
  ))

  return (
    <select
      ref={ref}
      onChange={onAddFollower}
      style={{
        opacity: 0,
        height: '28px',
        width: '28px',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      {options}
    </select>
  )
}
const Follower = ({ follower, onRemove }) => (
  <FollowerBadge data-tip={follower.full_name} onClick={() => onRemove(follower.id)}>
    <CornerIcon>{VmsIcons.Clear}</CornerIcon>
    {follower.initials}
    <ReactTooltip />
  </FollowerBadge>
)

class FollowerArea extends React.PureComponent {
  constructor(props) {
    super(props)
    this.triggerFollowerAddFinish = this.triggerFollowerAddFinish.bind(this)
    this.forwardClick = this.forwardClick.bind(this)
  }

  triggerFollowerAddFinish(event) {
    this.props.actions.onAddFollower(event.target.value)
  }

  forwardClick() {
    this.chooseRef.click()
  }

  render() {
    const followerElements = (
      <InterimFollowerSelector onAddFollower={this.triggerFollowerAddFinish} potentialFollowers={this.props.bookedByNames} />
    )
    return (
      <FollowerBookedByContainer>
        <FollowerBookedbyNameLabel> followers </FollowerBookedbyNameLabel>
        <FollowerDisplay>
          {_.map(this.props.followers, (elem, i) => (
            <Follower key={elem.id} follower={elem} onRemove={this.props.actions.onDeleteFollower} />
          ))}
          <AddBadgeHack>
            <AddButton />
            {followerElements}
          </AddBadgeHack>
        </FollowerDisplay>
      </FollowerBookedByContainer>
    )
  }
}

export default FollowerArea
