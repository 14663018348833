import { useLocales } from '@sevenrooms/core/locales'
import { Select } from '@sevenrooms/core/ui-kit/core'
import { VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages } from '@sevenrooms/marketing'
import { CALL_TO_ACTION_OPTIONS, EMAIL_SENDER_OPTIONS, PROMPT_OPTIONS, STYLE_OPTIONS, TONE_OPTIONS, PROMPT_MAPPING } from './MockOptions'
import type { TemplateForm } from './AssistantForm'

interface TemplateProps {
  formState: TemplateForm
  setFormState: React.Dispatch<React.SetStateAction<TemplateForm>>
}

type PromptKeys = 'thank-visit' | 'cross-promote' | 'share-details' | 'invite-guest' | 'promote-online' | 'special-promo'

export function Template({ formState, setFormState }: TemplateProps) {
  const { formatMessage } = useLocales()

  const handleChange = (field: string) => (value: string) => {
    if (field === 'prompt' && value in PROMPT_MAPPING) {
      const values = PROMPT_MAPPING[value as PromptKeys]
      setFormState(values)
    } else {
      setFormState(prevState => ({
        ...prevState,
        [field]: value,
      }))
    }
  }

  return (
    <VStack spacing="sm" width="640px" backgroundColor="secondaryBackground" borderColor="margin" p="m" borderRadius="s" mt="m">
      <HStack spacing="sm" alignItems="center">
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalWriteEmail)}</Text>
        <Select
          options={PROMPT_OPTIONS}
          searchable={false}
          value={formState.prompt}
          onChange={handleChange('prompt')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalPromptPlaceholder)}
          data-test="prompt-select"
          wrapperProps={{ flex: 1 }}
        />
      </HStack>

      <HStack spacing="sm" alignItems="center">
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalCallToActionLabel)}</Text>
        <Select
          options={CALL_TO_ACTION_OPTIONS}
          searchable={false}
          value={formState.callToAction}
          onChange={handleChange('callToAction')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalCallToActionPlaceholder)}
          data-test="call-to-action-select"
          wrapperProps={{ flex: 1 }}
        />
      </HStack>

      <HStack spacing="sm" alignItems="center">
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalToneLabel)}</Text>
        <Select
          options={TONE_OPTIONS}
          displayValue={formState.tone || undefined}
          searchable={false}
          value={formState.tone}
          onChange={handleChange('tone')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalTonePlaceholder)}
          data-test="tone-select"
          alignment="top"
        />
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalStyleLabel)}</Text>
        <Select
          options={STYLE_OPTIONS}
          searchable={false}
          displayValue={formState.style || undefined}
          value={formState.style}
          onChange={handleChange('style')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalStylePlaceholder)}
          data-test="style-select"
          wrapperProps={{ flex: 1 }}
          alignment="top"
        />
      </HStack>

      <HStack spacing="sm" alignItems="center">
        <Text>{formatMessage(emailBuilderMessages.assistantFormModalSenderLabel)}</Text>
        <Select
          options={EMAIL_SENDER_OPTIONS}
          searchable={false}
          value={formState.sender}
          onChange={handleChange('sender')}
          placeholder={formatMessage(emailBuilderMessages.assistantFormModalSenderPlaceholder)}
          data-test="sender-select"
          wrapperProps={{ flex: 1 }}
          alignment="top"
        />
      </HStack>
    </VStack>
  )
}
