import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import BookStepComponent from 'mgr/actualslideout/components/BookStepComponent'
import CheckboxToggle from 'mgr/lib/forms/CheckboxToggle'
import RegularCheckbox from 'mgr/lib/forms/RegularCheckbox'
import TextInput from 'mgr/lib/forms/TextInput'
import { selectEnabledLanguages, selectClientPreferredLanguage } from 'mgr/pages/single-venue/settings/selectors/language'
import VenueSupportedLanguageDropDown from 'svr/component-lib/Manager/MultiLanguage/VenueSupportedLanguageDropDown'

const FieldGroup = styled.div`
  margin: ${props => (props.hide ? '-8px' : '16px')} 12px;
  overflow-y: hidden;
  max-height: ${props => (props.hide ? '0' : '210px')};
  will-change: max-height;
  transition: max-height 0.05s ease;
  ${props => props.theme.clearFix};
  ${props => props.showOverflow && 'overflow-y: visible;'};
`

const SubFieldGroup = styled.div`
  margin-left: 53px;
`
const CheckboxSubGroup = styled(SubFieldGroup)`
  margin-top: 5px;
`
const MessagingStep = ({
  actions,
  isCollapsed,
  scrollIntoView,
  isEditMode,
  showMessagingStep,
  messagingFields,
  showReminderToggles,
  showFeedbackToggles,
  isBookingSMSEnabled,
  isSourceEnabled,
  isSmsEnabled,
  languages = [],
  clientPreferredLanguage = '',
  cardEntryOption,
  paylinkAutoCancel,
  takePaymentOrSave,
  client,
}) => {
  const hasSelection = false
  const isCollapsible = false
  const onToggle = () => {
    /* actions.toggleStep('messaging') */
  }
  const onTransitionEnd = () => {
    actions.changeBookStepHeight('messaging')
  }
  const onCompleteScrollIntoView = () => {
    actions.clearScrollIntoView()
  }
  const [title, subTitle] = ['Messaging', null]
  const isPaylinkBooking = cardEntryOption === 'paylink' && paylinkAutoCancel !== -1 && takePaymentOrSave !== 'none'

  const bookingEmailNotificationLabel = `
    ${isEditMode ? 'Send updated booking notification email' : 'Send booking notification email'}
    ${isPaylinkBooking ? 'when paylink completed' : 'now'}`
  const bookingSMSNotificationLabel = `
    ${isEditMode ? 'Send updated booking notification SMS' : 'Send booking notification SMS'}
    ${isPaylinkBooking ? 'when paylink completed' : 'now'}`
  const showSendMarketingOptInEmail =
    client && !client?.has_venue_in_marketing_opt_in_list && !client?.has_venue_in_sizzle_unsubscribed_venues_list

  return (
    showMessagingStep && (
      <BookStepComponent
        {...{
          title,
          subTitle,
          onToggle,
          onCompleteScrollIntoView,
          isCollapsed,
          isCollapsible,
          scrollIntoView,
        }}
        testId="sr-section-messaging"
        isEmpty={!hasSelection}
      >
        <div>
          {languages.length > 1 && (
            <FieldGroup showOverflow>
              <VenueSupportedLanguageDropDown
                id="supported-languages-picker"
                languages={languages}
                selectedLanguage={clientPreferredLanguage}
                onChange={language => actions.changeClientField('preferred_language_code', language)}
                label="Guest receives communication in"
                style={{
                  width: '170px',
                }}
                borderAreaStyle={{
                  borderColor: '#dedede',
                }}
                name="guest_communication_language"
                noHeader
              />
            </FieldGroup>
          )}
          <FieldGroup>
            <CheckboxToggle
              testId="sr-checkbox-notification_email"
              label={bookingEmailNotificationLabel}
              on={messagingFields.sendClientEmail}
              onChange={v => actions.changeMessagingField('sendClientEmail', v)}
            />
          </FieldGroup>
          <FieldGroup hide={!messagingFields.sendClientEmail} {...{ onTransitionEnd }}>
            <SubFieldGroup>
              <TextInput
                placeholder="Add a personal message to booking notification (optional)"
                useOutsideLabel
                charLimit={1000}
                isMultiLine
                forceIndent
                minRows={1}
                maxRows={3}
                value={messagingFields.personalMessage}
                onChange={v => actions.changeMessagingField('personalMessage', v)}
              />
            </SubFieldGroup>
            <CheckboxSubGroup>
              {isSourceEnabled && messagingFields.sendClientEmail && (
                <RegularCheckbox
                  testId="sr-checkbox-notification_email_source"
                  label="Source (Default)"
                  on={messagingFields.sendClientEmailToSource}
                  onChange={v => actions.changeMessagingField('sendClientEmailToSource', v)}
                />
              )}
              {isSourceEnabled && messagingFields.sendClientEmail && (
                <RegularCheckbox
                  testId="sr-checkbox-notification_email_client"
                  label="Client"
                  on={messagingFields.sendClientEmailToClient}
                  onChange={v => actions.changeMessagingField('sendClientEmailToClient', v)}
                />
              )}
            </CheckboxSubGroup>
          </FieldGroup>
          {isBookingSMSEnabled && (
            <FieldGroup>
              <CheckboxToggle
                testId="sr-checkbox-notification_sms"
                label={bookingSMSNotificationLabel}
                on={messagingFields.sendClientSMS}
                enabled={messagingFields.resSMSOptIn !== false}
                onChange={v => actions.changeMessagingField('sendClientSMS', v)}
              />
              <CheckboxSubGroup>
                {isSourceEnabled && messagingFields.sendClientSMS && (
                  <RegularCheckbox
                    testId="sr-checkbox-notification_sms_source"
                    label="Source (Default)"
                    on={messagingFields.sendClientSMSToSource}
                    onChange={v => actions.changeMessagingField('sendClientSMSToSource', v)}
                  />
                )}
                {isSourceEnabled && messagingFields.sendClientSMS && (
                  <RegularCheckbox
                    testId="sr-checkbox-notification_sms_client"
                    label="Client"
                    on={messagingFields.sendClientSMSToClient}
                    onChange={v => actions.changeMessagingField('sendClientSMSToClient', v)}
                  />
                )}
              </CheckboxSubGroup>
            </FieldGroup>
          )}
          {showReminderToggles && (
            <FieldGroup>
              <CheckboxToggle
                testId="sr-checkbox-reminder_email"
                label="Send reminder email"
                on={messagingFields.sendReminderEmail}
                onChange={v => actions.changeMessagingField('sendReminderEmail', v)}
              />
              <CheckboxSubGroup>
                {isSourceEnabled && messagingFields.sendReminderEmail && (
                  <RegularCheckbox
                    testId="sr-checkbox-reminder_email_source"
                    label="Source (Default)"
                    on={messagingFields.sendReminderEmailToSource}
                    onChange={v => actions.changeMessagingField('sendReminderEmailToSource', v)}
                  />
                )}
                {isSourceEnabled && messagingFields.sendReminderEmail && (
                  <RegularCheckbox
                    testId="sr-checkbox-reminder_email_client"
                    label="Client"
                    on={messagingFields.sendReminderEmailToClient}
                    onChange={v => actions.changeMessagingField('sendReminderEmailToClient', v)}
                  />
                )}
              </CheckboxSubGroup>
              {isSmsEnabled && (
                <div>
                  <CheckboxToggle
                    testId="sr-checkbox-sms"
                    label="Send reminder SMS"
                    on={messagingFields.sendReminderSms}
                    enabled={messagingFields.resSMSOptIn !== false}
                    onChange={v => actions.changeMessagingField('sendReminderSms', v)}
                  />
                  <CheckboxSubGroup>
                    {isSourceEnabled && messagingFields.sendReminderSms && (
                      <RegularCheckbox
                        testId="sr-checkbox-sms_source"
                        label="Source (Default)"
                        on={messagingFields.sendReminderSmsToSource}
                        onChange={v => actions.changeMessagingField('sendReminderSmsToSource', v)}
                      />
                    )}
                    {isSourceEnabled && messagingFields.sendReminderSms && (
                      <RegularCheckbox
                        testId="sr-checkbox-sms_client"
                        label="Client"
                        on={messagingFields.sendReminderSmsToClient}
                        onChange={v => actions.changeMessagingField('sendReminderSmsToClient', v)}
                      />
                    )}
                  </CheckboxSubGroup>
                </div>
              )}
            </FieldGroup>
          )}
          {showFeedbackToggles && (
            <FieldGroup>
              <CheckboxToggle
                testId="sr-checkbox-feedback_email"
                label="Send feedback email"
                on={messagingFields.sendFeedbackEmail}
                onChange={v => actions.changeMessagingField('sendFeedbackEmail', v)}
              />
              <CheckboxSubGroup>
                {isSourceEnabled && messagingFields.sendFeedbackEmail && (
                  <RegularCheckbox
                    testId="sr-checkbox-feedback_email_source"
                    label="Source"
                    on={messagingFields.sendFeedbackEmailToSource}
                    onChange={v => actions.changeMessagingField('sendFeedbackEmailToSource', v)}
                  />
                )}
                {isSourceEnabled && messagingFields.sendFeedbackEmail && (
                  <RegularCheckbox
                    testId="sr-checkbox-feedback_email_client"
                    label="Client (Default)"
                    on={messagingFields.sendFeedbackEmailToClient}
                    onChange={v => actions.changeMessagingField('sendFeedbackEmailToClient', v)}
                  />
                )}
              </CheckboxSubGroup>
            </FieldGroup>
          )}
          {showSendMarketingOptInEmail && (
            <FieldGroup>
              <CheckboxToggle
                testId="sr-checkbox-marketing_opt_in_email"
                label="Send Email marketing opt-in email"
                on={messagingFields.sendMarketingOptInEmail}
                onChange={v => actions.changeMessagingField('sendMarketingOptInEmail', v)}
              />
            </FieldGroup>
          )}
        </div>
      </BookStepComponent>
    )
  )
}

MessagingStep.propTypes = {
  actions: React.PropTypes.object,
  isCollapsed: React.PropTypes.bool,
  isEditMode: React.PropTypes.bool,
  scrollIntoView: React.PropTypes.bool,
  showMessagingStep: React.PropTypes.bool.isRequired,
  showMarketingSendOptInEmail: React.PropTypes.bool.isRequired,
  messagingFields: React.PropTypes.object.isRequired,
  showReminderToggles: React.PropTypes.bool,
  isBookingSMSEnabled: React.PropTypes.bool,
  isSmsEnabled: React.PropTypes.bool,
  showFeedbackToggles: React.PropTypes.bool,
}

MessagingStep.defaultProps = {
  actions: {},
}

const mapStateToProps = state => ({
  languages: selectEnabledLanguages(state),
  isCollapsed: state.bookState.isStepCollapsed.messaging,
  stepHeightChangeTimestamp: state.bookState.stepHeightChangeTimestamps.messaging,
  scrollIntoView: state.bookState.scrollIntoViewStep === 'messaging',
  isEditMode: !_.isEmpty(state.slideoutState.actualId),
  showMessagingStep: !_.isNil(state.bookAvailabilityState.selectedTimeSlot), // Hide if venue not determined yet (no timeslot selected)
  messagingFields: state.bookDetailsState.messagingFields,
  showReminderToggles: state.bookDetailsState.showReminderToggles,
  isBookingSMSEnabled: state.bookDetailsState.isBookingSMSEnabled,
  isSourceEnabled: !_.isNil(state.bookSourceState.selectedSource),
  isSmsEnabled: state.bookDetailsState.isSmsEnabled,
  showFeedbackToggles: state.bookDetailsState.showFeedbackToggles,
  clientPreferredLanguage: selectClientPreferredLanguage(state),
  cardEntryOption: state.bookPaymentState.cardEntryOption,
  paylinkAutoCancel: state.bookPaymentState.paylinkAutoCancel,
  takePaymentOrSave: state.bookPaymentState.takePaymentOrSave,
  client: state.bookClientState.selectedClient,
})

export default connect(mapStateToProps)(MessagingStep)
