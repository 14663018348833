import { computeGridAccessMap } from 'mgr/pages/shared/utils/Access'
import { computeGridActualsMap, computeGridStats } from 'mgr/pages/shared/utils/Actuals'
import { getGridAvailabilityMap } from 'mgr/pages/shared/utils/Availability'
import { computeGridBlocksMap } from 'mgr/pages/shared/utils/Blocks'
import * as ActionTypes from 'mgr/pages/single-venue/dayview/actions/ActionTypes'

const resetDailyGridState = {
  actualsByShift: {},
  waitlistEntriesByShift: {},
  gridActualsMapByShift: {}, // stored by shift
  gridStatsByShift: {}, // stored by shift
  gridBlocksMapByShift: {}, // stored by shift
  gridAccessMapByShift: {}, // stored by shift

  // move mode
  isActualMoveMode: false,
  movingActual: null,
  gridAvailabilityMap: {}, // by table id
  boundingRect: null,
  scrollY: 0,
}

const initialGridState = {
  tableSortIsAscending: true,
  shouldUpdate: false,
  ...resetDailyGridState,
}

const gridReducer = (state = initialGridState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_DATE:
      return { ...state, ...resetDailyGridState }
    case ActionTypes.SUSPEND_GRID: {
      return {
        ...state,
        shouldUpdate: false,
      }
    }
    case ActionTypes.GRID_DATA_READY: {
      const actualsByShift = action.dayviewState.actuals
      const waitlistEntriesByShift = action.dayviewState.waitlistEntries
      const autoAssignmentsByShift = action.dayviewState.autoAssignments
      const problemReservationsByShift = action.dayviewState.problemReservations
      const gridActualsMapByShift = computeGridActualsMap(
        actualsByShift,
        waitlistEntriesByShift,
        autoAssignmentsByShift,
        problemReservationsByShift,
        action.appState.venueSettings,
        action.appState.floorPlan.tableItemsByTableId
      )
      const gridBlocksMapByShift = computeGridBlocksMap(
        action.dayviewState.blocks,
        action.dayviewState.date,
        action.dayviewState.shifts,
        action.dayviewState.dayFloorPlan.seatingAreasToTablesBySeatingAreaId,
        action.dayviewState.dayFloorPlan.tableItems
      )
      const gridAccessMapByShift = computeGridAccessMap(
        action.appState.venue,
        action.dayviewState.access,
        action.dayviewState.date,
        action.dayviewState.shifts,
        action.dayviewState.dayFloorPlan.seatingAreasToTablesBySeatingAreaId,
        action.appState.floorPlan.tableItemsByTableId
      )
      const gridStatsByShift = computeGridStats(
        action.appState.venueSettings.statusesByDb,
        action.appState.venue.startOfDayHour,
        actualsByShift,
        waitlistEntriesByShift,
        action.dayviewState.shiftsByPersistentId,
        action.dayviewState.date,
        action.dayviewState.dayFloorPlan.seatingAreasByTableId,
        autoAssignmentsByShift,
        action.appState.venueSettings
      )
      return {
        ...state,
        actualsByShift,
        waitlistEntriesByShift,
        gridActualsMapByShift,
        gridStatsByShift,
        gridBlocksMapByShift,
        gridAccessMapByShift,
        shouldUpdate: true,
      }
    }
    case ActionTypes.CHANGE_GRID_TABLE_SORT: {
      return {
        ...state,
        tableSortIsAscending: action.isAscending,
      }
    }
    case ActionTypes.START_ACTUAL_MOVE_MODE: {
      return {
        ...state,
        gridAvailabilityMap: getGridAvailabilityMap(
          action.dayviewState.dayFloorPlan.tableItems,
          state.gridActualsMapByShift,
          action.availability,
          action.actual
        ),
        isActualMoveMode: true,
        movingActual: action.actual,
        boundingRect: action.boundingRect,
        scrollY: window.scrollY,
      }
    }
    case ActionTypes.CANCEL_ACTUAL_MOVE_MODE: {
      return {
        ...state,
        isActualMoveMode: false,
        movingActual: null,
        availabilityByTableId: null,
        boundingRect: null,
        scrollY: window.scrollY,
      }
    }
    default:
      return state
  }
}

export default gridReducer
