import React from 'react'
import styled from 'styled-components'
import { BrandVmsIconM, BrandMap, BRAND_MAPPER, BRAND_MAPPER_NAMES, getReviewSitePNGWithoutMediaURL } from 'svr/common/BrandIcon'

export const RatingBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  line-height: 1em;
  padding: ${props => props.theme.gutter.double};
`

export const ReviewInfo = styled.div`
  color: ${props => props.theme.color.grey};
  font-size: ${props => props.theme.fontSize.body};
  padding: 0 ${props => props.theme.gutter.double} 7px;
`

export const ReviewContent = styled.div`
  color: ${props => props.theme.color.black};
  font-size: ${props => props.theme.fontSize.body};
  padding: 0 ${props => props.theme.gutter.double} 20px;
`

export const RatingTitle = styled.div`
  font-size: ${props => props.theme.fontSize.subheader};
  font-weight: bold;
`

export const NumRating = styled.div`
  font-size: ${props => props.theme.fontSize.body};
  margin-right: 8px;
`

export const StarSlide = styled.div`
  display: flex;
`

const SourceBarBox = styled.div`
  background-color: ${props => props.theme.color.lightGrey};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  height: 54px;
  justify-content: space-between;
  line-height: 54px;
  margin: 16px;
  padding: 0 16px;
`

const SourceName = styled.span`
  font-size: ${props => props.theme.fontSize.subheader};
  font-weight: bold;
  margin-left: 10px;
`

const Source = styled.div`
  display: flex;
`

const Menu = styled.div`
  color: #9c9c9d !important;
  cursor: pointer;
  position: relative;
`

const HoverMenu = styled.div`
  top: 50px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  right: -8px;
  width: 200px;
  z-index: 101;
`

const InnerMenu = styled.div`
  background: #ffffff;
  border-radius: 4px;
  font-size: ${props => props.theme.fontSize.body};
  line-height: 1em;
  padding: 16px;
`

const ArrowDown = styled.div`
  top: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${props => props.theme.color.white};
  height: 0;
  position: absolute;
  right: 10px;
  width: 0;
`

const Unlink = styled.div`
  color: ${props => props.theme.color.errorAccent};
`

const Search = styled.div`
  color: ${props => props.theme.color.black};
`

export class SourceBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { menuOpen: false }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }))
  }

  renderButton() {
    const { isLinked, unlink, openSearch, ignoreLinkButton } = this.props
    if (!this.state.menuOpen || ignoreLinkButton) {
      return
    }
    let button
    if (isLinked) {
      button = <Unlink onClick={unlink}>Unlink</Unlink>
    } else {
      button = <Search onClick={openSearch}>Search for matches</Search>
    }
    return (
      <HoverMenu>
        <InnerMenu>{button}</InnerMenu>
        <ArrowDown />
      </HoverMenu>
    )
  }

  render() {
    const { reviewSite } = this.props

    const name = BRAND_MAPPER_NAMES[reviewSite] || BRAND_MAPPER[reviewSite]
    const company = BrandMap[BRAND_MAPPER[reviewSite]] || {}
    const pngSrc = getReviewSitePNGWithoutMediaURL(reviewSite)

    return (
      <SourceBarBox>
        <Source>
          <BrandVmsIconM company={company} pngSrc={pngSrc} />
          <SourceName>{name}</SourceName>
        </Source>
        <Menu onClick={this.toggleMenu}>
          &bull; &bull; &bull;
          {this.renderButton()}
        </Menu>
      </SourceBarBox>
    )
  }
}
