import { srGet } from '@sevenrooms/core/api'

export const getSaferpayInitialData = venueId => {
  const url = `/api-yoa/saferpay/${venueId}/get_initial_data`
  return srGet(url).then(response => response.data)
}

export const getAdyenInitialData = venueId => {
  const url = `/api-yoa/adyen/${venueId}/get_initial_data`
  return srGet(url).then(response => response.data)
}
