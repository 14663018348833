import React from 'react'
import styled from 'styled-components'
import { CardEntryLabel, ViewCardTitleSmall } from 'mgr/actualslideout/components/view/ViewCommon'

const TotalRow = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  padding-top: 5px;
  color: ${props => props.theme.color.disclaimer};
`
interface BlockProps {
  width: string
}

const Block = styled.div<BlockProps>`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${props => props.width};
`

const BlockLeft = styled(Block)`
  text-align: left;
`

const BlockRight = styled(Block)`
  text-align: right;
`

const BlockContainer = styled.div`
  display: flex;
  width: 100%;
`

export interface BlockThreeSetProps {
  one: string
  two: string
  three: string
}

function BlockThreeSet({ one, two, three }: BlockThreeSetProps) {
  return (
    <BlockContainer>
      <BlockLeft width="10%">{one}</BlockLeft>
      <BlockLeft width="70%">{two}</BlockLeft>
      <BlockRight width="20%">{three}</BlockRight>
    </BlockContainer>
  )
}

export function UpgradesHeaderSubCard() {
  return <ViewCardTitleSmall>upgrades</ViewCardTitleSmall>
}

export function UpgradesSubheader({ text }: { text: string }) {
  return <CardEntryLabel>{text}</CardEntryLabel>
}

export interface ItemUpgradeRowProps {
  upgradeItemCount: string
  upgradeItem: string
  upgradePrice: string
}

export function ItemUpgradeRow({ upgradeItemCount, upgradeItem, upgradePrice }: ItemUpgradeRowProps) {
  return (
    <TotalRow data-test="sr-upgrade-row">
      <BlockThreeSet one={upgradeItemCount} two={upgradeItem} three={upgradePrice} />
    </TotalRow>
  )
}
