import { OrderedMap } from 'immutable'
import _ from 'lodash'
import { genericTagToDisplayTag } from 'mgr/lib/components/GenericTagsDropDown'
import { getVenueToday } from 'svr/common/TimeUtil'
import * as ActionTypes from '../actions/ActionTypes'

export const TAB_ACTIONS = {
  COMMENTS: 'COMMENTS',
  MESSAGING: 'MESSAGING',
  ACTIVITY: 'ACTIVITY',
}

const ALL_ACTIVITY_TAB = { text: 'All Activity', action: TAB_ACTIONS.ACTIVITY }
const TODAY = 'Today'
const YESTERDAY = 'Yesterday'

const getTabs = (numberOfComments, numberOfMessages) => {
  const commentsTab = {
    text: `Comments (${numberOfComments || 0})`,
    action: TAB_ACTIONS.COMMENTS,
  }
  const messageTab = {
    text: `Messaging (${numberOfMessages || 0})`,
    action: TAB_ACTIONS.MESSAGING,
  }
  return [ALL_ACTIVITY_TAB, commentsTab, messageTab]
}

const INITIAL_INITIALS = '??'

const getClientInitials = client => {
  const firstInitial = (client.first_name && client.first_name[0]) ?? ''
  const lastInitial = (client.last_name && client.last_name[0]) ?? ''
  return (firstInitial + lastInitial).toUpperCase()
}

const initialViewResState = {
  actual: null,
  selectedResTagsDisplay: [],
  clientTags: [],
  clientInitials: INITIAL_INITIALS,
  sourceInitials: INITIAL_INITIALS,
  sourceTags: [],
  activityLog: [],
  isLoadingActual: false,
  selectedTableIds: [],
  isAutoAssign: false,
  isCustomAssign: false,
  hasTableDropdownChange: false,
  isLoadingActivityLog: false,
  isLoadingMessages: false,
  internalComments: [],
  internalCommentCount: 0,
  externalMessages: [],
  externalMessageCount: 0,
  isLoadingTransactions: false,
  transactions: [],
  transactionsCount: 0,
  transactionHasMore: false,
  transactionCursor: null,
  tabs: getTabs(),
  selectedTabAction: ALL_ACTIVITY_TAB.action,
  isLoadingAttachments: false,
  attachments: [],
  attachmentsForCommentsBuffer: [],
  attachmentsForMessagesBuffer: [],
  shiftHumanName: '',
  resProblem: null,
  isSendingMessage: false,
  viewVenue: null,
  canEditReservation: false,
  isInService: false,
  isLoadingAutoAssignmentsAndProblems: false,
  spendDetailsOpen: false,
  spendListOpen: false,
  feedbackDetailsOpen: false,
  isLinking: false,
  linkingReview: null,
  reviews: [],
  scrollOnLoad: false,
  isResendingBookingNotification: false,
}

const makeServerSideAttachment = ({ blobKey, urlKey, fileName, fileType }) => ({
  filename: fileName,
  blob_key: urlKey,
  content_type: fileType,
})

const viewResReducer = (state = initialViewResState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_ACTUAL_START: {
      return { ...state, ...initialViewResState, isLoadingActual: true }
    }
    case ActionTypes.ENTER_ADD_RESERVATION:
    case ActionTypes.ENTER_CLONE_RESERVATION: {
      return { ...state, ...initialViewResState }
    }
    case ActionTypes.SET_VIEW_VENUE: {
      const { viewVenue, canEditReservation, isInService } = action
      return { ...state, viewVenue, canEditReservation, isInService }
    }
    case ActionTypes.SEND_MESSAGE_START: {
      return { ...state, isSendingMessage: true }
    }
    case ActionTypes.LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_START: {
      return { ...state, isLoadingAutoAssignmentsAndProblems: true }
    }
    case ActionTypes.LOAD_ACTIVITY_LOG_START: {
      return { ...state, isLoadingActivityLog: true }
    }
    case ActionTypes.DELETE_FOLLOWER_SUCCESS: {
      const { followers } = action
      const actual = { ...state.actual, followers }
      return { ...state, actual }
    }
    case ActionTypes.LOAD_ACTIVITY_LOG_SUCCESS: {
      // FIXME: Move this to the server side
      const todayStart = getVenueToday(state.viewVenue.timezone, state.viewVenue.startOfDayHour)
      const todayEnd = todayStart.endOf('day')
      const yesterdayStart = todayStart.subtract(1, 'days')
      const { activityLog } = action

      let byDayMap = new OrderedMap()
      for (let i = 0; i < activityLog.length; i += 1) {
        const logEntry = activityLog[i]
        const parsedDatetime = moment(logEntry.created)

        if (parsedDatetime >= todayStart && parsedDatetime <= todayEnd) {
          if (!byDayMap.has(TODAY)) {
            byDayMap = byDayMap.set(TODAY, [])
          }
          byDayMap.get(TODAY).push(logEntry)
        } else if (parsedDatetime >= yesterdayStart && parsedDatetime < todayStart) {
          if (!byDayMap.has(YESTERDAY)) {
            byDayMap = byDayMap.set(YESTERDAY, [])
          }
          byDayMap.get(YESTERDAY).push(logEntry)
        } else {
          const dayEntry = parsedDatetime.startOf('day').format('MMMM DD, YYYY')
          if (!byDayMap.has(dayEntry)) {
            byDayMap = byDayMap.set(dayEntry, [])
          }
          byDayMap.get(dayEntry).push(logEntry)
        }
      }

      return {
        ...state,
        isLoadingActivityLog: false,
        activityLog: Array.from(byDayMap.entries()),
      }
    }
    case ActionTypes.LOAD_MESSAGES_START: {
      return { ...state, isLoadingMessages: true }
    }

    case ActionTypes.SEND_MESSAGE_SUCCESS: {
      const { message } = action

      let newMessageList
      let tabs
      if (message.visibility.indexOf('ALL') > -1) {
        newMessageList = [...state.externalMessages]
        newMessageList.push(message)
        const len = newMessageList.length
        tabs = getTabs(state.internalCommentCount, len)
        return {
          ...state,
          externalMessages: newMessageList,
          externalMessageCount: len,
          tabs,
        }
      } else if (message.visibility.indexOf('INTERNAL') > -1) {
        newMessageList = [...state.internalComments]
        newMessageList.push(message)
        const len = newMessageList.length
        tabs = getTabs(len, state.externalMessageCount)
        return {
          ...state,
          internalComments: newMessageList,
          internalCommentCount: newMessageList.length,
          tabs,
        }
      }

      return state
    }
    case ActionTypes.LOAD_ATTACHMENTS_START: {
      return { ...state, isLoadingAttachments: true }
    }

    case ActionTypes.LOAD_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        attachments: action.attachments || [],
        isLoadingAttachments: false,
      }
    }

    case ActionTypes.LOAD_ATTACHMENTS_FAIL: {
      return {
        ...state,
        attachments: [],
        isLoadingAttachments: false,
      }
    }

    case ActionTypes.LOAD_TRANSACTIONS_START: {
      return { ...state, isLoadingTransactions: true }
    }

    case ActionTypes.LOAD_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactions: [...(action.transactionResult.isFirstPage ? state.transactions : []), ...(action.transactionResult.charges || [])],
        transactionCursor: action.transactionResult.cursor || null,
        transactionHasMore: action.transactionResult.more || false,
        isLoadingTransactions: false,
      }
    }

    case ActionTypes.PAYMENT_PAYLINK_DELETE_SUCCESS: {
      const _filtered = state.transactions.filter(item => item.id !== action.value)
      return { ...state, transactions: _filtered }
    }

    case ActionTypes.PAYMENT_REFUND_SUCCESS: {
      const _update = state.transactions.map(item => {
        if (item.transaction_id !== action.refundData.charge) {
          return item
        }
        // eslint-disable-next-line no-param-reassign
        item.amount_remaining -= action.refundData.amount
        // eslint-disable-next-line no-param-reassign
        item.amount_remaining_decimal -= action.refundData.displayData.amount_decimal
        return item
      })
      return { ...state, transactions: _update }
    }

    case ActionTypes.PUSH_PAYMENT_TRANSACTION: {
      const _transactions = state.transactions
      _transactions.unshift(action.transaction)
      return { ...state, transactions: _transactions }
    }

    case ActionTypes.LOAD_MESSAGES_SUCCESS: {
      const { messages } = action

      const internal = []
      const external = []

      for (let i = messages.length - 1; i >= 0; i -= 1) {
        const message = messages[i]

        // Filter out activities that are not attachments
        // and filter out empty messages (no user entered text)
        // Message structure looks like

        if ((message.category === 'ACTIVITY' && message.status !== 'ATTACHMENT') || _.isEmpty(message.text)) {
          continue
        }
        if (message.visibility.indexOf('ALL') > -1) {
          external.push(message)
        } else if (message.visibility.indexOf('INTERNAL') > -1) {
          internal.push(message)
        }
      }
      return {
        ...state,
        internalCommentCount: internal.length,
        internalComments: internal,
        externalMessageCount: external.length,
        externalMessages: external,
        tabs: getTabs(internal.length, external.length),
        isLoadingMessages: false,
      }
    }

    case ActionTypes.LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_SUCCESS: {
      const { problems } = action
      const actualId = state.actual.id
      const resProblem = Object.prototype.hasOwnProperty.call(problems, actualId) ? problems[actualId] : null
      return {
        ...state,
        isLoadingAutoAssignmentsAndProblems: false,
        resProblem,
      }
    }

    case ActionTypes.SEND_STATUS_ASSIGNMENT_SUCCESS: {
      const actual = { ...state.actual }
      actual.status = action.status.status
      actual.status_formatted = action.status.status_formatted
      actual.is_canceled = actual.status === 'CANCELED'
      return { ...state, actual }
    }

    case ActionTypes.VIEW_ACTUAL_CHANGE_SELECTED_TABLES: {
      const { isCustomAssign } = state
      const { selectedTableIds } = action
      const isAutoAssign = selectedTableIds.value === 'auto' && !isCustomAssign
      const hasTableDropdownChange = true
      return {
        ...state,
        selectedTableIds,
        isAutoAssign,
        hasTableDropdownChange,
      }
    }

    case ActionTypes.VIEW_ACTUAL_CHANGE_IS_CUSTOM_ASSIGN: {
      const { isCustomAssign } = action
      const hasTableDropdownChange = true
      return { ...state, isCustomAssign, hasTableDropdownChange }
    }

    case ActionTypes.VIEW_ACTUAL_TABLE_CHANGE_SAVED: {
      const hasTableDropdownChange = false
      return { ...state, hasTableDropdownChange }
    }

    case ActionTypes.SEND_TABLE_ASSIGNMENT_SUCCESS: {
      const actual = { ...state.actual }
      const { actualSeating } = action

      actual.table_ids_list = actualSeating.tableids
      // eslint-disable-next-line prefer-destructuring
      actual.table_id = actualSeating.table_ids[0]
      actual.is_auto_assign = actualSeating.is_auto_assign
      actual.table_codes_list = actualSeating.table_codes_list
      actual.table_codes_display = actualSeating.table_codes_display
      actual.disable_auto_assign = actualSeating.disable_aa

      return { ...state, actual }
    }

    case ActionTypes.REFRESH_ACTUAL_SUCCESS: {
      return {
        ...state,
        actual: action.actual,
      }
    }

    case ActionTypes.LOAD_ACTUAL_SUCCESS: {
      const tags = (action.actual.tags_display || []).map(genericTagToDisplayTag)
      const { actual } = action

      let clientInitials = INITIAL_INITIALS
      let clientTags = []
      if (!_.isNil(actual.venue_group_client)) {
        const vgc = actual.venue_group_client

        // Put the phone locale from the actual into the client and let the server deal with it later
        actual.venue_group_client.phone_number_locale = actual.phone_number_locale
        clientInitials = getClientInitials(vgc)
        clientTags = (vgc.tags_display || []).map(genericTagToDisplayTag)
      }

      let sourceInitials = INITIAL_INITIALS
      let sourceTags = []
      if (!_.isNil(actual.source_client)) {
        const sourceClient = actual.source_client
        sourceInitials = getClientInitials(sourceClient)
        sourceTags = (sourceClient.tags_display || []).filter(tag => tag.is_source).map(genericTagToDisplayTag)
      }

      const isCustomAssign = actual.is_custom_assign
      const isAutoAssign = actual.is_auto_assign
      const tableIds = actual.table_ids_list
      // eslint-disable-next-line no-nested-ternary
      const selectedTableIds = _.isEmpty(tableIds)
        ? []
        : isCustomAssign
        ? _.map(tableIds.split(','), v => ({ value: v }))
        : [{ value: tableIds }]
      const hasTableDropdownChange = false
      return {
        ...state,
        actual,
        isLoadingActual: false,
        selectedResTagsDisplay: tags,
        clientInitials,
        clientTags,
        sourceInitials,
        sourceTags,
        selectedTableIds,
        isAutoAssign,
        isCustomAssign,
        hasTableDropdownChange,
      }
    }

    case ActionTypes.SAVE_ATTACHMENT_SUCCESS: {
      const { fileInfo } = action

      switch (fileInfo.activeTab) {
        case TAB_ACTIONS.COMMENTS: {
          const attachmentsForCommentsBufferOld = state.attachmentsForCommentsBuffer
          const attachmentsForCommentsBuffer = _.concat(attachmentsForCommentsBufferOld, fileInfo)
          return { ...state, attachmentsForCommentsBuffer }
        }
        case TAB_ACTIONS.MESSAGING: {
          const attachmentsForMessagesBufferOld = state.attachmentsForMessagesBuffer
          const attachmentsForMessagesBuffer = _.concat(attachmentsForMessagesBufferOld, fileInfo)
          return { ...state, attachmentsForMessagesBuffer }
        }
        default: {
          return state
        }
      }
    }
    case ActionTypes.CLEAR_COMMENT_ATTACHMENTS: {
      const { attachmentsForCommentsBuffer, attachments } = state
      const adaptedAttachments = attachmentsForCommentsBuffer.map(makeServerSideAttachment)
      const attachmentsCombined = _.concat(attachments, adaptedAttachments)
      return {
        ...state,
        attachmentsForCommentsBuffer: [],
        attachments: attachmentsCombined,
      }
    }
    case ActionTypes.CLEAR_MESSAGE_ATTACHMENTS: {
      const { attachmentsForMessagesBuffer, attachments } = state
      const adaptedAttachments = attachmentsForMessagesBuffer.map(makeServerSideAttachment)
      const attachmentsCombined = _.concat(attachments, adaptedAttachments)
      return {
        ...state,
        attachmentsForMessagesBuffer: [],
        attachments: attachmentsCombined,
      }
    }
    case ActionTypes.SELECT_ACTIVITY_LOG_COMMENTS_MESSAGING_TAB: {
      return { ...state, selectedTabAction: action.tabAction }
    }
    case ActionTypes.REMOVE_BUFFERED_MESSAGE_ATTACHMENT: {
      const attachmentsForMessagesBuffer = state.attachmentsForMessagesBuffer.filter(attachment => attachment.blobKey !== action.blobKey)
      return { ...state, attachmentsForMessagesBuffer }
    }
    case ActionTypes.REMOVE_BUFFERED_COMMENT_ATTACHMENT: {
      const attachmentsForCommentsBuffer = state.attachmentsForCommentsBuffer.filter(attachment => attachment.blobKey !== action.blobKey)
      return { ...state, attachmentsForCommentsBuffer }
    }
    case ActionTypes.SINGLE_PASS_STAGE_AND_REGISTER_ATTACHMENT_SUCCESS: {
      const { fileInfo } = action
      return {
        ...state,
        attachments: _.concat(state.attachments, [makeServerSideAttachment(fileInfo)]),
      }
    }
    case ActionTypes.DELETE_SAVED_ATTACHMENT: {
      const attachments = state.attachments.filter(attachment => attachment.blob_key !== action.blobKey)
      return { ...state, attachments }
    }
    case ActionTypes.SPEND_TOGGLE_DETAILS: {
      return { ...state, spendDetailsOpen: !state.spendDetailsOpen }
    }
    case ActionTypes.SPEND_TOGGLE_LIST: {
      return { ...state, spendListOpen: !state.spendListOpen }
    }
    case ActionTypes.FEEDBACK_TOGGLE_DETAILS: {
      return { ...state, feedbackDetailsOpen: !state.feedbackDetailsOpen }
    }
    case ActionTypes.ENABLE_LINKING: {
      return { ...state, linkingReview: action.id }
    }
    case ActionTypes.LINK_REVIEW_START: {
      return { ...state, isLinking: true }
    }
    case ActionTypes.LINK_REVIEW_FAIL: {
      return { ...state, isLinking: false }
    }
    case ActionTypes.LINK_REVIEW_SUCCESS: {
      return {
        ...state,
        isLinking: false,
        linkingReview: null,
        reviews: [action.review].concat(state.reviews),
      }
    }
    case ActionTypes.GET_REVIEWS_SUCCESS: {
      return { ...state, reviews: action.reviews }
    }
    case ActionTypes.UNLINK_REVIEW_SUCCESS: {
      return {
        ...state,
        isLinking: false,
        reviews: state.reviews.filter(review => review.id !== action.review.id),
      }
    }
    case ActionTypes.SCROLL_ON_LOAD: {
      return { ...state, scrollOnLoad: true }
    }
    case ActionTypes.RESEND_BOOKING_NOTIFICATION_START: {
      return { ...state, isResendingBookingNotification: true }
    }
    case ActionTypes.RESEND_BOOKING_NOTIFICATION_FAIL:
    case ActionTypes.RESEND_BOOKING_NOTIFICATION_SUCCESS: {
      return { ...state, isResendingBookingNotification: false }
    }
    default:
      return state
  }
}
export default viewResReducer
