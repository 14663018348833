import * as ActionTypes from 'mgr/pages/single-venue/settings/actions/ActionTypes'

const initialState = {
  checkoutFees: [],
  orderingSites: [],
}

const chargesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ORDERING_CHECKOUT_FEE_LIST_SUCCESS:
      return {
        ...state,
        checkoutFees: action.data.serviceFees,
        orderingSites: action.data.orderingSites,
      }
    default:
      return state
  }
}

export default chargesReducer
