import { skipToken } from '@reduxjs/toolkit/query'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import {
  type AdminAutoTagConfig,
  type CampaignContent,
  useGetAdminAutoTagConfigsQuery,
  useGetClientTagGroupsQuery,
  useLazyGetAudienceSizeQuery,
} from '@sevenrooms/core/api'
import { isTagGlobal, type AudienceSize, type GenericTagGroup, type VenueProfile } from '@sevenrooms/core/domain'
import { useFormContext, useWatch } from '@sevenrooms/core/form'
import { commonMessages, type InternationalizationText, useLocales } from '@sevenrooms/core/locales'
import { Link, useNavigation } from '@sevenrooms/core/navigation'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { RadioGroupToggle, Checkbox, MultiSelectTag, type Category, type TagOption } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, notify, Box, HStack, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { Tag, Text } from '@sevenrooms/core/ui-kit/typography'
import { AudienceSizeBanner, type AudienceSizeBannerProps } from '@sevenrooms/marketing'
import type { Venue } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import { campaignBuilderMessages } from '../../locales'
import type { AudienceFormField } from './AudienceForm.zod'

interface AudienceProps {
  field: AudienceFormField
  campaignContent?: CampaignContent
  isAdmin?: boolean
  isOneTimeCampaign?: boolean
  isFromTemplate?: boolean
  venue: Venue | null
  venueProfile?: VenueProfile | null
  disabled?: boolean
  showAudienceSizeBanner?: boolean
  showInputSecondaryText?: boolean
  messages: AudienceSizeBannerProps['messages'] & {
    aboutGDPR?: InternationalizationText
    emailConsentOverride?: InternationalizationText
    audienceDescriptionDetails?: InternationalizationText
    adminAudienceDescription: InternationalizationText
    adminCampaignSentTo: InternationalizationText
    campaignSentTo: InternationalizationText
    adminExcludeText: InternationalizationText
    excludeText: InternationalizationText
  }
}

export function Audience({
  campaignContent,
  field,
  isAdmin = false,
  isOneTimeCampaign = false,
  isFromTemplate = false,
  venue,
  venueProfile,
  disabled,
  showAudienceSizeBanner = false,
  showInputSecondaryText = false,
  messages,
}: AudienceProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const venueKey = venue?.urlKey
  const venueKeyParams = { params: { venueKey } }
  const canManageEmailsWithGlobalTags = useAppContext().userDomain?.canManageEmailsWithGlobalTags

  const { data: adminAutotags, isFetching: isAdminAutotagsFetching } = useGetAdminAutoTagConfigsQuery(isAdmin ? undefined : skipToken)
  const { data: clientAutotags, isFetching: isClientAutotagsFetching } = useGetClientTagGroupsQuery(
    venueKey && !isAdmin ? { venueKey, includeRebuildState: isOneTimeCampaign } : skipToken
  )

  const useRecipientAutotags = useWatch(field.prop('useRecipientAutotags'))

  const isFetching = isAdminAutotagsFetching || isClientAutotagsFetching
  const { getValues, setValue } = useFormContext()

  const [selectedRecipientTagIds, setSelectedRecipientTagIds] = useState<Set<string>>(
    new Set(getValues('recipientAutotags').map((tag: { id: string }) => tag.id))
  )
  const [selectedExcludedTagIds, setSelectedExcludedTagIds] = useState<Set<string>>(
    new Set(getValues('excludedAutotags').map((tag: { id: string }) => tag.id))
  )

  const {
    recipientTagCategories,
    recipientTagOptions,
    recipientTagsInitialSelection,
    excludedTagCategories,
    excludedTagOptions,
    excludedTagsInitialSelection,
    recipientTagDeleted,
    excludeTagDeleted,
    rebuildingTagOptions,
  } = useMemo(() => {
    if (isAdmin) {
      return generateAdminTagsData(adminAutotags, campaignContent?.recipientClientTags, campaignContent?.recipientClientTagsExclude)
    }
    return generateTagsData(
      isFromTemplate,
      canManageEmailsWithGlobalTags,
      clientAutotags,
      campaignContent?.recipientClientTags,
      campaignContent?.recipientClientTagsExclude,
      isOneTimeCampaign
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canManageEmailsWithGlobalTags,
    isAdmin,
    isOneTimeCampaign,
    clientAutotags,
    campaignContent?.recipientClientTags,
    campaignContent?.recipientClientTagsExclude,
    adminAutotags,
  ])

  const getSectionDescription = (isAdmin?: boolean) => {
    if (isAdmin) {
      return formatMessage(messages.adminAudienceDescription)
    }

    if (messages.aboutGDPR && venueProfile && venueProfile.isEUVenue) {
      return (
        <Link to="https://help.sevenrooms.com/hc/en-us/articles/6819369614491" isExternal>
          {formatMessage(messages.aboutGDPR)}
        </Link>
      )
    }

    if (messages.audienceDescriptionDetails) {
      return formatMessage(messages.audienceDescriptionDetails)
    }
    return ''
  }

  const handleRecipientSelectTagOnChange = (selectedTags: TagOption[]) => {
    setSelectedRecipientTagIds(new Set(selectedTags.map(selectedTag => selectedTag.id)))
    setAudienceSize(null)
  }

  const handleExcludedSelectTagOnChange = (selectedTags: TagOption[]) => {
    setSelectedExcludedTagIds(new Set(selectedTags.map(selectedTag => selectedTag.id)))
    setAudienceSize(null)
  }

  useEffect(() => {
    if (!isFromTemplate) {
      setValue('recipientAutotags', recipientTagsInitialSelection)
      setValue('excludedAutotags', excludedTagsInitialSelection)
    }
  }, [excludedTagsInitialSelection, recipientTagsInitialSelection, setValue, isFromTemplate])

  const renderRebuildingTagsWarning = (selectedTagIds: Set<string>) => {
    if (isOneTimeCampaign) {
      const selectedRebuildingTags = rebuildingTagOptions.filter(tag => selectedTagIds.has(tag.id)).map(tag => tag.label)
      if (selectedRebuildingTags.length === 1) {
        return (
          <Text textStyle="error" color="error">
            {formatMessage(campaignBuilderMessages.buildingAutotagError, { tagName: selectedRebuildingTags[0] ?? '' })}
          </Text>
        )
      } else if (selectedRebuildingTags.length > 1) {
        return (
          <Text textStyle="error" color="error">
            {formatMessage(campaignBuilderMessages.buildingMultipleAutotagsError, { tagNames: selectedRebuildingTags.join(', ') })}
          </Text>
        )
      }
    }
    return ''
  }

  const renderRecipientAutoTags = () => {
    if (recipientTagDeleted && disabled) {
      return <Input disabled value={formatMessage(campaignBuilderMessages.recipientTagDeleted)} autoComplete="none" />
    } else if (isFromTemplate) {
      return (
        <Box mt="sm">
          <Tag backgroundColor={theme.colors.defaultTagColor}>{getValues('recipientAutotags')?.[0]?.label ?? ''}</Tag>
        </Box>
      )
    }
    return (
      <MultiSelectTag
        single={!isOneTimeCampaign}
        searchable
        field={field.prop('recipientAutotags')}
        categories={recipientTagCategories}
        options={recipientTagOptions.filter(option => !selectedExcludedTagIds.has(option.id))}
        initialSelectedItems={recipientTagsInitialSelection}
        disabled={disabled}
        onChange={handleRecipientSelectTagOnChange}
        id="recipient-autotags"
      />
    )
  }

  const [getAudienceSize, { isLoading: isAudienceSizeLoading }] = useLazyGetAudienceSizeQuery()
  const [audienceSize, setAudienceSize] = useState<AudienceSize | null>(null)
  const calculateAudienceSize = async () => {
    if (venue) {
      try {
        setAudienceSize(
          await getAudienceSize({
            venueId: venue.id,
            recipientAutoTagIds: getValues('recipientAutotags').map((tag: { id: string }) => tag.id),
            excludedAutoTagIds: getValues('excludedAutotags').map((tag: { id: string }) => tag.id),
            marketingOptIn: getValues('expressConsentOverride'),
          }).unwrap()
        )
      } catch {
        notify({
          content: formatMessage(campaignBuilderMessages.audienceSizeFetchError),
          type: 'error',
        })
      }
    }
  }

  return (
    <BaseSection
      title={formatMessage(isAdmin ? campaignBuilderMessages.sendingConfigurations : campaignBuilderMessages.audienceTitle)}
      description={getSectionDescription(isAdmin)}
    >
      <Box p="lm">
        {isFetching ? (
          <Loader />
        ) : (
          <VStack spacing="lm">
            <HStack spacing="lm">
              <VStack width="50%" justifyContent="flex-start">
                <Box mb={showInputSecondaryText ? 'xs' : 's'}>
                  <Text fontWeight="bold">
                    {isAdmin ? (
                      <>
                        {formatMessage(messages.adminCampaignSentTo)}
                        <Text color="error">&nbsp;*</Text>
                      </>
                    ) : (
                      formatMessage(messages.campaignSentTo)
                    )}
                  </Text>
                </Box>
                {showInputSecondaryText && (
                  <Box mb="s">
                    <Text>
                      (isAdmin ? ( formatMessage(campaignBuilderMessages.settingUnchangeable) ) : (
                      <>
                        {formatMessage(campaignBuilderMessages.recipientDescription)}&nbsp;
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        <Link to={nav.href(routes.manager2.marketing.autotags, venueKeyParams as any)}>
                          {_.toLower(formatMessage(commonMessages.here))}
                        </Link>
                      </>
                      ))
                    </Text>
                  </Box>
                )}
                <RadioGroupToggle
                  field={field.prop('useRecipientAutotags')}
                  choices={[
                    { value: false, label: formatMessage(campaignBuilderMessages.recipientSelectorAll) },
                    { value: true, label: formatMessage(campaignBuilderMessages.recipientSelectorFiltered) },
                  ]}
                />
                {useRecipientAutotags && (
                  <Box ml="xs">
                    <Box ml="lm" mt="s">
                      {renderRecipientAutoTags()}
                    </Box>
                    <Box>{renderRebuildingTagsWarning(selectedRecipientTagIds)}</Box>
                  </Box>
                )}
                {messages.emailConsentOverride && isOneTimeCampaign && !venueProfile?.sizzleExpressConsentEnabled && (
                  <Checkbox
                    disabled={venueProfile?.marketingDoubleOptInEnabled || disabled}
                    field={field.prop('expressConsentOverride')}
                    onChange={() => setAudienceSize(null)}
                    data-test="express-consent-override"
                  >
                    {formatMessage(messages.emailConsentOverride)}
                  </Checkbox>
                )}
              </VStack>
              <VStack display="flex" backgroundColor="borders" width="1px" justifySelf="stretch" />
              <VStack width="50%" justifyContent="flex-start">
                <Box mb={showInputSecondaryText ? 'xs' : 's'}>
                  <Text fontWeight="bold">{formatMessage(isAdmin ? messages.adminExcludeText : messages.excludeText)}</Text>
                </Box>
                {showInputSecondaryText && (
                  <Box mb="s">
                    <Text>
                      (isAdmin ? ( formatMessage(campaignBuilderMessages.settingChangeable) ) : (
                      <>
                        {formatMessage(campaignBuilderMessages.recipientDescription)}&nbsp;
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        <Link to={nav.href(routes.manager2.marketing.autotags, venueKeyParams as any)}>
                          {_.toLower(formatMessage(commonMessages.here))}
                        </Link>
                      </>
                      ))
                    </Text>
                  </Box>
                )}

                {excludeTagDeleted && disabled ? (
                  <Input disabled value={formatMessage(campaignBuilderMessages.excludeTagDeleted)} autoComplete="none" />
                ) : (
                  <>
                    <MultiSelectTag
                      searchable
                      field={field.prop('excludedAutotags')}
                      categories={excludedTagCategories}
                      options={excludedTagOptions.filter(option => !selectedRecipientTagIds.has(option.id))}
                      initialSelectedItems={excludedTagsInitialSelection}
                      disabled={disabled}
                      onChange={handleExcludedSelectTagOnChange}
                    />
                    <Box>{renderRebuildingTagsWarning(selectedExcludedTagIds)}</Box>
                  </>
                )}
              </VStack>
            </HStack>
            {isAdmin && ( // BLACKLIST_HACK
              <Checkbox field={field.prop('excludeBlacklist')}>{formatMessage(campaignBuilderMessages.excludeBlacklist)}</Checkbox>
            )}
            {showAudienceSizeBanner && (
              <AudienceSizeBanner
                audienceSize={audienceSize}
                isLoading={isAudienceSizeLoading}
                isActionDisabled={getValues('recipientAutotags').length < 1 || !!audienceSize}
                onAction={calculateAudienceSize}
                messages={messages}
              />
            )}
          </VStack>
        )}
      </Box>
    </BaseSection>
  )
}

const generateTagsData = (
  isFromTemplate: boolean,
  canManageEmailsWithGlobalTags: boolean | undefined,
  clientTagGroups?: GenericTagGroup[],
  recipientTags?: string[],
  excludedTags?: string[],
  isOneTimeEmail?: boolean
) => {
  const excludedTagCategories: Category[] = []
  const excludedTagOptions: TagOption[] = []
  const recipientTagCategories: Category[] = []
  const recipientTagOptions: TagOption[] = []
  const recipientTagsInitialSelection: TagOption[] = []
  const excludedTagsInitialSelection: TagOption[] = []
  const rebuildingTagOptions: TagOption[] = []

  if (clientTagGroups) {
    const filteredClientTagGroups = clientTagGroups.filter(clientTagGroup => !clientTagGroup.deleted && clientTagGroup.tags.length > 0)
    const recipientTagsSet = new Set(recipientTags)
    const excludedTagsSet = new Set(excludedTags)
    filteredClientTagGroups.forEach(clientTagGroup => {
      if (!isOneTimeEmail && clientTagGroup.name === 'Referrer') {
        return
      }

      clientTagGroup.tags.forEach(tag => {
        const tagOption: TagOption = {
          id: [clientTagGroup.privacy, clientTagGroup.id, clientTagGroup.name, tag].join('##'),
          label: clientTagGroup.tagNameDisplays[tag] ?? tag,
          categoryId: clientTagGroup.id,
        }

        // Even within a local GenericTagGroup, a specific tag can be global, so we need to check each one
        const tagIsGlobal = isTagGlobal([tagOption.id], clientTagGroups)

        if (isOneTimeEmail && clientTagGroup.isTagRebuilding[tag]) {
          tagOption.disabled = true
          rebuildingTagOptions.push(tagOption)
        }

        if (excludedTagsSet.has(tagOption.id)) {
          excludedTagsInitialSelection.push(tagOption)
        }

        if ((isOneTimeEmail || clientTagGroup.isAutotag) && (!tagIsGlobal || canManageEmailsWithGlobalTags)) {
          recipientTagOptions.push(tagOption)
          if (recipientTagsSet.has(tagOption.id)) {
            recipientTagsInitialSelection.push(tagOption)
          }
        }
        excludedTagOptions.push(tagOption)
      })
      const tagCategory: Category = {
        id: clientTagGroup.id,
        name: clientTagGroup.nameDisplay ?? clientTagGroup.name,
        color: clientTagGroup.colorHex,
      }
      excludedTagCategories.push(tagCategory)
      if ((isOneTimeEmail || clientTagGroup.isAutotag) && (!clientTagGroup.isGlobal || canManageEmailsWithGlobalTags)) {
        recipientTagCategories.push(tagCategory)
      }
    })
  }

  const recipientTagDeleted = recipientTagsInitialSelection.length !== recipientTags?.length
  const excludeTagDeleted = excludedTagsInitialSelection.length !== excludedTags?.length

  if (isFromTemplate) {
    if (excludeTagDeleted && excludedTags) {
      for (const tag of excludedTags) {
        const [, clientTagGroupId, clientTagGroupName, tagName] = tag.split('##')
        const tagOption = {
          id: tag,
          label: tagName as string,
          categoryId: clientTagGroupId as string,
        }
        if (!excludedTagsInitialSelection.some(selectedTag => selectedTag.id === tagOption.id)) {
          excludedTagsInitialSelection.push(tagOption)
          excludedTagOptions.push(tagOption)
        }
        if (!excludedTagCategories.some(tagCategory => tagCategory.id === clientTagGroupId)) {
          excludedTagCategories.push({
            id: clientTagGroupId as string,
            name: clientTagGroupName as string,
            color: theme.colors.defaultTagColor,
          })
        }
      }
    }
  }

  return {
    excludedTagCategories,
    excludedTagOptions,
    excludedTagsInitialSelection,
    recipientTagCategories,
    recipientTagOptions,
    recipientTagsInitialSelection,
    recipientTagDeleted,
    excludeTagDeleted,
    rebuildingTagOptions,
  }
}

const generateAdminTagsData = (autotagConfigs?: AdminAutoTagConfig[], recipientTags?: string[], excludedTags?: string[]) => {
  const excludedTagCategories: Category[] = []
  const excludedTagOptions: TagOption[] = []
  const recipientTagCategories: Category[] = []
  const recipientTagOptions: TagOption[] = []
  const recipientTagsInitialSelection: TagOption[] = []
  const excludedTagsInitialSelection: TagOption[] = []
  const rebuildingTagOptions: TagOption[] = []

  if (autotagConfigs) {
    const filteredAutotagConfigs = autotagConfigs.filter(
      autotagConfig => !autotagConfig.deleted && autotagConfig.tierConfigs.length > 0 && autotagConfig.isActive
    )
    const recipientTagsSet = new Set(recipientTags)
    const excludedTagsSet = new Set(excludedTags)
    filteredAutotagConfigs.forEach(config => {
      config.tierConfigs.forEach(tag => {
        const tagOption = {
          id: [config.id, config.configType, tag.tierType].join('##'),
          label: tag.nameDisplay,
          categoryId: config.id,
          disabled: false,
        }
        const recipientTagOption = { ...tagOption }
        const excludedTagOption = { ...tagOption }

        if (excludedTagsSet.has(tagOption.id)) {
          excludedTagsInitialSelection.push(tagOption)
        }
        recipientTagOptions.push(recipientTagOption)
        if (recipientTagsSet.has(tagOption.id)) {
          recipientTagsInitialSelection.push(tagOption)
        }
        excludedTagOptions.push(excludedTagOption)
      })
      const tagCategory: Category = {
        id: config.id,
        name: config.nameDisplay,
        color: theme.colors.defaultTagColor,
      }
      excludedTagCategories.push(tagCategory)
      recipientTagCategories.push(tagCategory)
    })
  }

  const recipientTagDeleted = recipientTagsInitialSelection.length !== recipientTags?.length
  const excludeTagDeleted = excludedTagsInitialSelection.length !== excludedTags?.length

  return {
    excludedTagCategories,
    excludedTagOptions,
    excludedTagsInitialSelection,
    recipientTagCategories,
    recipientTagOptions,
    recipientTagsInitialSelection,
    recipientTagDeleted,
    excludeTagDeleted,
    rebuildingTagOptions,
  }
}
