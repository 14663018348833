import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { findTags } from 'mgr/lib/components/GenericTagsDropDown'
import LazyImage from 'mgr/lib/components/LazyImage'
import { TagElement } from 'mgr/lib/components/Tags'
import { getContrastingColor } from 'svr/common/Color'
import { VmsIcons, StyledVmsIconS } from 'svr/common/VmsIcons'
import { Row, Col } from 'svr/lib/styled-bootstrap-grid'

const ClientRow = styled(Row)`
  padding: 15px 0;
  border-top: 1px solid ${props => props.theme.mediumGrey};
  border-bottom: 1px solid ${props => props.theme.mediumGrey};
  margin-bottom: -1px;
  ${props => props.theme.whiteClickableRow};
`

const ThemedLazyImage = styled(LazyImage)`
  margin: 0 auto;
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-color: #051729;
  text-transform: uppercase;
`

const HotelIconAvatar = styled(StyledVmsIconS)`
  margin: 0 auto;
  color: #7e98b1;
  text-align: center;
  background-color: ${props => (props.isStatusActive ? props.theme.color.black : props.theme.color.white)};
  color: ${props => (props.isStatusActive ? props.theme.color.white : props.theme.color.black)};
  border: 1px solid ${props => props.theme.color.black};
  font-size: 22px;
  text-transform: uppercase;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 23px;
`

const ResultTextCol = styled(Col)`
  font-weight: 500;
  height: 22px;
  line-height: 22px;
  color: ${props => props.theme.color.black};
  margin-top: 2px;
  margin-bottom: 2px;
  ${props => props.theme.ellipsis};
`

const maxDisplayWidth = '400px'

const TagCol = styled.div`
  max-width: ${maxDisplayWidth};
`

const NameCol = styled(ResultTextCol)`
  font-size: 20px;
`

const ContactCol = styled(ResultTextCol)`
  color: ${props => props.theme.color.black};
  font-size: 13px;
  font-weight: 500;
`

const DetailsCol = styled(ResultTextCol)`
  font-size: 14px;
  font-weight: 400;
  font-style: oblique;
  color: ${props => props.theme.darkGrey};
`

const CheckInColumn = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
`

const CheckInItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  flex-direction: row;
`

const CheckInValue = styled.div`
  order: 1;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  height: 24px;
  line-height: 24px;
  margin-right: 9px;
  color: ${props => props.theme.charcoal};
`

const CheckInLabel = styled.div`
  order: 2;
  font-size: 11px;
  font-weight: 500;
  height: 24px;
  width: 70px;
  line-height: 24px;
  color: ${props => props.theme.darkGrey};
  text-transform: uppercase;
  ${props => props.theme.ellipsis};
`

export const ClientHotelBodyDisplay = ({ client }) => {
  const contactContents = _.compact([client.phone_number, client.email]).join(' · ')
  const detailsContents = _.compact([client.confirmation_num, client.room_num && `Room ${client.room_num}`, client.status_display]).join(
    ' · '
  )

  return (
    <div>
      <Row>
        <NameCol>{client.name_display}</NameCol>
      </Row>
      {!_.isEmpty(contactContents) && (
        <Row>
          <ContactCol>{contactContents}</ContactCol>
        </Row>
      )}
      {!_.isEmpty(detailsContents) && (
        <Row>
          <DetailsCol>{detailsContents}</DetailsCol>
        </Row>
      )}
    </div>
  )
}

export const ClientCheckinDisplay = ({ client }) => (
  <CheckInColumn data-test="client-checkin-display">
    <CheckInItem>
      <CheckInValue>{client.check_in_date_display}</CheckInValue>
      <CheckInLabel>CHECK IN</CheckInLabel>
    </CheckInItem>
    <CheckInItem>
      <CheckInValue>{client.check_out_date_display}</CheckInValue>
      <CheckInLabel>CHECK OUT</CheckInLabel>
    </CheckInItem>
  </CheckInColumn>
)

ClientCheckinDisplay.propTypes = {
  client: React.PropTypes.object.isRequired,
}

const ClientHotelLookupResult = ({ client, onClick, reservationTags, clientTags }) => {
  const isStatusActive = client.is_status_active
  const foundReservationTags = findTags(reservationTags, client.reservation_tags)
  const foundClientTags = findTags(clientTags, client.client_tags)
  const allTags = foundClientTags.concat(foundReservationTags)
  const tagsContents = (allTags || []).map((elem, idx) => {
    const key = `${elem.tagGroup.id}##${elem.tagName}`
    return <TagElement key={key} tagObj={elem} />
  })
  return (
    <ClientRow onClick={onClick}>
      <Col xs={1}>
        <HotelIconAvatar {...{ isStatusActive }}>{VmsIcons.Company}</HotelIconAvatar>
      </Col>
      <Col xs={11}>
        <Row>
          <Col xs={7} style={{ paddingRight: 0 }}>
            <ClientHotelBodyDisplay {...{ client }} />
            {!_.isEmpty(tagsContents) && <TagCol>{tagsContents}</TagCol>}
          </Col>
          <Col xs={5}>
            <ClientCheckinDisplay {...{ client }} />
          </Col>
        </Row>
      </Col>
    </ClientRow>
  )
}

ClientHotelLookupResult.defaultProps = {
  onClick: () => {},
}

ClientHotelLookupResult.propTypes = {
  client: React.PropTypes.object.isRequired,
  onClick: React.PropTypes.func.isRequired,
}

export default ClientHotelLookupResult
