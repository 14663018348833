// /* @flow */
import _ from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { processDisplayValue } from '../Input/numberInputUtils'
import { castValueByType } from './castValueByType'
import { inputTypes, inputTypeProps } from './inputTypes'

/* type Props = {
  onChange: (value: string, field: string) => mixed,
  onFocus?: () => mixed,
  onBlur?: () => mixed,
  value: string,
  field: string,
  inputType: $Keys<typeof inputTypes>,
  inputId?: string,
  label?: string,
  charLimit?: number,
  isValid?: boolean,
  inputPadding?: number,
  fontSize?: number,
  validator?: string => boolean,
  disableAutoComplete?: boolean,
  disabled?: boolean,
  placeholder?: string,
  dataTest?: string,
  customInput: Array<string | (() => mixed)>,
  customInputLabel: Array<string | (() => mixed)>,
  customInputWrapper: Array<string | (() => mixed)>,
  allowEmpty: boolean,
  max?: string
} */
class TextInput extends React.Component /* <Props> */ {
  static inputTypes = inputTypes

  inputId /* : string */ = this.props.inputId || `sr-textinput-${this.props.field}`

  handleInputChange = (event /* : SyntheticEvent<HTMLInputElement> */) => {
    const { allowEmpty, inputType, value, max } = this.props

    const valueTmp = this.limitInput(event.currentTarget.value)

    if (inputType === TextInput.inputTypes.POSITIVE_INT && valueTmp === '' && allowEmpty) {
      this.props.onChange(null, event.currentTarget.name)
      return
    }

    const newValue = castValueByType(valueTmp, inputType, max)
    const oldValue = castValueByType(value, inputType, max)

    if (newValue === oldValue) {
      return
    }

    this.props.onChange(newValue, event.currentTarget.name)
  }

  limitInput = (inputVal /* : string */) => {
    if (inputVal === '') {
      return ''
    }
    const { inputType, charLimit } = this.props

    if (!charLimit) {
      return inputVal
    }

    // newVal should be as String, because old code expect String
    const newVal = String(castValueByType(inputVal, inputType))

    if (charLimit) {
      return newVal.substring(0, this.props.charLimit)
    }

    return newVal
  }

  validate() {
    if (this.props.validator) {
      return this.props.validator(this.props.value)
    }
    throw new TypeError('React component is missing props: [validator]')
  }

  render() {
    const displayPrefix = this.props.prefix && !_.isNil(this.props.value)
    const displayValue = processDisplayValue(castValueByType(this.props.value, this.props.inputType, this.props.max))

    return (
      <InputWrapper
        customInputWrapper={this.props.customInputWrapper}
        disabled={this.props.disabled}
        className={!this.props.isValid && 'invalid-input'}
      >
        {this.props.label && (
          <InputLabel htmlFor={this.inputId} isValid={this.props.isValid} customInputLabel={this.props.customInputLabel}>
            {this.props.label}
          </InputLabel>
        )}
        <ControlWrapper>
          {displayPrefix && <Prefix customPrefix={this.props.customPrefix}>{this.props.prefix}</Prefix>}
          <Input
            id={this.inputId}
            name={this.props.field}
            value={displayValue}
            isValid={this.props.isValid}
            onChange={this.handleInputChange}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            customInput={this.props.customInput}
            placeholder={this.props.placeholder}
            autoComplete={this.props.disableAutoComplete && 'off'}
            disabled={this.props.disabled}
            fontSize={this.props.fontSize}
            data-test={this.props.dataTest}
            {...inputTypeProps[this.props.inputType].inputProps}
            {...(!_.isNil(this.props.max) ? { max: this.props.max } : {})}
          />
        </ControlWrapper>
      </InputWrapper>
    )
  }
}

const ControlWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const InputWrapper = styled.div`
  width: 160px;
  box-sizing: border-box;
  background-color: ${props => (props.disabled ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 1)')};
  ${props => props.customInputWrapper};
`

export const InputLabel = styled.label`
  display: inline-block;
  width: 100%;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 4px;
  box-sizing: inherit;
  color: #878787;
  ${props => props.customInputLabel} ${props => !props.isValid && 'color: red;'};
`

// TODO: remove important clauses when css class of site no longer overrules styled-components
const Input = styled.input`
  height: 36px;
  width: ${props => (props.displayPrefix ? '95%' : '100%')};
  padding: 0 ${props => props.inputPadding || 10}px;
  border-radius: 5px;
  border-width: 2px;
  font-size: ${props => props.fontSize || 18}px;
  border-style: solid;
  border-color: rgba(236, 236, 236, 1);
  background-color: ${props => (props.disabled ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 1)')} !important;
  color: #666666;
  font-family: Roboto;
  box-sizing: inherit;
  ${props => props.customInput} ${props => !props.isValid && 'border-color: red !important;'};
`

const Prefix = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  float: left;

  ${props => props.customPrefix};
`

TextInput.defaultProps = {
  inputType: inputTypes.DEFAULT,
  isValid: true,
  customInput: [],
  customInputLabel: [],
  customInputWrapper: [],
  customPrefix: [],
  allowEmpty: false,
}

export default TextInput
