import moment from 'moment-timezone'
import history from 'mgr/pages/shared/utils/History'
import * as ActionTypes from 'mgr/pages/single-venue/reporting/actions/ActionTypes'
import queryString from 'query-string'

const dateFormat = 'YYYY-MM-DD'
// Release date hardcoded to prevent users from searching for emails sent before release date
export const outgoingEmailsReleaseDate = '08-28-2019'

export const dateFilters = [
  {
    name: 'Custom',
    value: 'custom',
    convert: (startDate, endDate) => ({
      start_date: startDate.format(dateFormat),
      end_date: endDate.format(dateFormat),
    }),
  },
  {
    name: 'Yesterday',
    value: 'yesterday',
    convert: () => ({
      start_date: moment().add(-1, 'days').format(dateFormat),
      end_date: moment().add(-1, 'days').format(dateFormat),
    }),
  },
  {
    name: 'Last 3 days',
    value: 'last3Days',
    convert: () => ({
      start_date: moment().add(-2, 'days').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last 7 days',
    value: 'last7Days',
    convert: () => ({
      start_date: moment().add(-6, 'days').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last 30 days',
    value: 'last30Days',
    convert: () => ({
      start_date: moment().add(-29, 'days').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last 60 days',
    value: 'last60Days',
    convert: () => ({
      start_date: moment().add(-59, 'days').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'This month',
    value: 'thisMonth',
    convert: () => ({
      start_date: moment().startOf('month').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last month',
    value: 'lastMonth',
    convert: () => ({
      start_date: moment().add(-1, 'months').startOf('month').format(dateFormat),
      end_date: moment().add(-1, 'months').endOf('month').format(dateFormat),
    }),
  },
  {
    name: 'This year',
    value: 'thisYear',
    convert: () => ({
      start_date: moment().startOf('year').format(dateFormat),
      end_date: moment().format(dateFormat),
    }),
  },
  {
    name: 'Last year',
    value: 'lastYear',
    convert: () => ({
      start_date: moment().add(-1, 'year').startOf('year').format(dateFormat),
      end_date: moment().add(-1, 'year').endOf('year').format(dateFormat),
    }),
  },
  {
    name: 'All time',
    value: 'all',
    convert: () => ({}),
  },
]

const emailTypeNames = [
  'Client Conversations',
  'Credit Card Received Notification',
  'Daily Feedback Summary',
  'Weekly AI Feedback Summary',
  'Email Campaign',
  'Follower Arrival Alert',
  'Guestlist Confirmation',
  'Guestlist Request Submitted',
  'New Feedback Alert',
  'Order Feedback Request',
  'Request Accepted',
  'Request Added',
  'Request Assigned',
  'Request Canceled',
  'Request Denied',
  'Request Offer',
  'Request Offer Booked',
  'Request Offer Declined',
  'Request Received',
  'Request Submitted',
  'Reservation Cancellation',
  'Reservation Charge Notification',
  'Reservation Confirmation',
  'Reservation Feedback Request',
  'Reservation Paylink Request',
  'Reservation Reminder',
  'Reservation Summary',
  'Reservation Update Notification',
  'Resolution Report',
  'Special Occasions Summary',
]

const convertEmailTypeNamesToFilters = emailTypeNames => {
  const emailTypeFilters = [{ name: '', value: null }]
  emailTypeNames.map(emailTypeName =>
    emailTypeFilters.push({
      name: emailTypeName,
      value: emailTypeName.toUpperCase().replace(/ /g, '_'),
    })
  )
  return emailTypeFilters
}

export const emailTypeFilters = convertEmailTypeNamesToFilters(emailTypeNames)

export const getInitialFiltersState = history => {
  const parsedParams = queryString.parse(history.location.search)
  const defaultPageSize = 20
  const date = parsedParams.date || dateFilters.find(filter => filter.value === 'last30Days').value
  const urlDateFormat = 'YYYY-MM-DD'
  let startDate
  let endDate

  if (parsedParams.startDate && parsedParams.endDate) {
    startDate = moment(parsedParams.startDate, urlDateFormat)
    endDate = moment(parsedParams.endDate, urlDateFormat)
  } else {
    let selectedFilter = dateFilters.find(filter => filter.value === date)
    if (!selectedFilter) {
      selectedFilter = dateFilters.find(filter => filter.value === 'last30Days')
    }
    const dateObj = selectedFilter.convert()
    startDate = moment(dateObj.start_date)
    endDate = moment(dateObj.end_date)
  }

  return {
    date,
    startDate,
    endDate,
    search: parsedParams.search || '',
    page: parseInt(parsedParams.page, 10) || 0,
    pageSize: parseInt(parsedParams.pageSize, 10) || defaultPageSize,
    sortKey: parsedParams.sortKey || 'date',
    sortDirection: parsedParams.sortDirection || 'desc',
    emailType: parsedParams.emailType || null,
  }
}

const filtersReducer = (state = getInitialFiltersState(history), action) => {
  switch (action.type) {
    case ActionTypes.OUTGOING_EMAILS_DATE_FILTER_CHANGE:
      if (action.value !== 'custom' && action.value !== 'all') {
        const dateObj = dateFilters.find(filter => filter.value === action.value).convert()
        const startDate = moment(dateObj.start_date)
        const endDate = moment(dateObj.end_date)

        return {
          ...state,
          date: action.value,
          startDate,
          endDate,
        }
      }
      return {
        ...state,
        date: action.value,
      }

    case ActionTypes.OUTGOING_EMAILS_CUSTOM_RANGE_CHANGE:
      return {
        ...state,
        date: 'custom',
        [action.dateType]: action.dateVal,
      }
    case ActionTypes.OUTGOING_EMAILS_SEARCH_FILTER_CHANGE:
      return {
        ...state,
        search: action.value,
      }
    case ActionTypes.OUTGOING_EMAILS_EMAIL_TYPE_FILTER_CHANGE:
      return {
        ...state,
        emailType: action.value,
      }
    case ActionTypes.OUTGOING_EMAILS_PAGINATION_CHANGE:
      return {
        ...state,
        page: action.page,
        pageSize: action.pageSize,
      }
    default:
      return state
  }
}

export default filtersReducer
