import { VmsIcons } from '../../../common/VmsIcons'

export const ReservationStatuses = {
  BOOKED: 'NOT_RECONCILED',
  CONFIRMED: 'CONFIRMED',
  WRONG_NUMBER: 'WRONG_NUMBER',
  NO_ANSWER: 'NO_ANSWER',
  NO_SHOW: 'NO_SHOW',
  NO_ENTRY: 'NO_ENTRY',
  LEFT_MESSAGE: 'LEFT_MESSAGE',
  LATE: 'LATE',
  HOLD: 'HOLD',
  PRE_ARRIVED: 'PRE_ARRIVED',
  PRE_PARTIALLY_ARRIVED: 'PRE_PARTIALLY_ARRIVED',
  PARTIALLY_ARRIVED: 'PARTIALLY_ARRIVED',
  ARRIVED: 'ARRIVED',
  ORDER_PLACED: 'ORDER_PLACED',
  FIRST_COURSE: '1ST_COURSE',
  SECOND_COURSE: '2ND_COURSE',
  THIRD_COURSE: '3RD_COURSE',
  FOURTH_COURSE: '4TH_COURSE',
  DESSERT: 'DESSERT',
  CHECK_DROPPED: 'CHECK_DROPPED',
  PAID: 'PAID',
  BUS_TABLE: 'BUS_TABLE',
  CANCELED: 'CANCELED',
}

export function getIconForStatus(status, isLeft) {
  let statusIcon = VmsIcons.Booked
  switch (status) {
    // preservice
    case ReservationStatuses.CONFIRMED:
      statusIcon = VmsIcons.Confirmed
      break
    case ReservationStatuses.WRONG_NUMBER:
      statusIcon = VmsIcons.WrongNumber
      break
    case ReservationStatuses.NO_ANSWER:
      statusIcon = VmsIcons.NoAnswer
      break
    case ReservationStatuses.LEFT_MESSAGE:
      statusIcon = VmsIcons.LeftMessage
      break
    case ReservationStatuses.LATE:
      statusIcon = VmsIcons.Late
      break
    case ReservationStatuses.HOLD:
      statusIcon = VmsIcons.Hold
      break
    case ReservationStatuses.PRE_ARRIVED:
      statusIcon = VmsIcons.Arrived
      break
    case ReservationStatuses.PRE_PARTIALLY_ARRIVED:
      statusIcon = VmsIcons.PartiallyArrived
      break

    case ReservationStatuses.CANCELED:
      statusIcon = VmsIcons.Canceled
      break
    case ReservationStatuses.NO_SHOW:
      statusIcon = VmsIcons.NoShow
      break
    case ReservationStatuses.NO_ENTRY:
      statusIcon = VmsIcons.NoEntry
      break

    // in service
    case ReservationStatuses.PARTIALLY_ARRIVED:
      statusIcon = VmsIcons.PartiallySeated
      break
    case ReservationStatuses.ARRIVED:
      statusIcon = VmsIcons.Seated
      break
    case ReservationStatuses.ORDER_PLACED:
      statusIcon = VmsIcons.OrderPlaced
      break
    case ReservationStatuses.FIRST_COURSE:
      statusIcon = VmsIcons.FirstCourse
      break
    case ReservationStatuses.SECOND_COURSE:
      statusIcon = VmsIcons.SecondCourse
      break
    case ReservationStatuses.THIRD_COURSE:
      statusIcon = VmsIcons.ThirdCourse
      break
    case ReservationStatuses.FOURTH_COURSE:
      statusIcon = VmsIcons.FourthCourse
      break
    case ReservationStatuses.DESSERT:
      statusIcon = VmsIcons.Dessert
      break
    case ReservationStatuses.CHECK_DROPPED:
      statusIcon = VmsIcons.CheckDropped
      break
    case ReservationStatuses.PAID:
      statusIcon = VmsIcons.Paid
      break
    case ReservationStatuses.BUS_TABLE:
      statusIcon = VmsIcons.BusTable
      break
    default:
      break
  }

  if (isLeft) {
    statusIcon = VmsIcons.Left
  }

  return statusIcon
}

export const getIconColor = (statusesByDb, status) => {
  let backgroundColor = 'WHITE'
  const statusColor = (statusesByDb[status] || { color: '#092238' }).color.toUpperCase()
  if (statusColor === '#FFFFFF' || statusColor === 'WHITE') {
    backgroundColor = '#092238'
  }
  return { color: statusColor, background: backgroundColor }
}
