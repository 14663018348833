import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ViewActualActions from 'mgr/actualslideout/actions/ViewActualActions'
import Followers from 'mgr/actualslideout/components/view/Followers'
import { venueScopedBookedByNames } from 'mgr/actualslideout/selectors/ViewSelectors'

const mapStateToProps = state => ({
  followers: (state.viewResState.actual && state.viewResState.actual.followers) || {},
  bookedByNames: venueScopedBookedByNames(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onDeleteFollower: ViewActualActions.tryDeleteFollower,
      onAddFollower: ViewActualActions.tryAddFollower,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Followers)
