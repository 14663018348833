import _ from 'lodash'
import { useMemo, useState } from 'react'
import { Chart } from 'react-google-charts'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in MA-857
import { useLocales } from '@sevenrooms/core/locales'
import { useWindowSize } from '@sevenrooms/core/ui-kit/hooks'
import { Flex, Loader } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useCampaignActivityChart } from '../../hooks'
import chartNoData from '../../images/chartNoData.svg'
import { campaignCenterMessages } from '../../locales'
import type { CampaignPerformanceChartProps } from '../../typings'

const CHART_HEIGHT = 282

export function CampaignPerformanceChart({
  histogram,
  isFetching,
  error,
  messages,
  usePerformanceContext,
  availableStatistics,
}: CampaignPerformanceChartProps) {
  const { formatMessage } = useLocales()
  const { dateFilter, startDate, endDate } = usePerformanceContext()
  const { data, options } = useCampaignActivityChart({
    histogram,
    dateFilter,
    availableStatistics,
  })
  const size = useWindowSize()
  const [key, setKey] = useState('')

  // we should rerender chart on window resize
  useMemo(() => {
    setKey(size.join(','))
  }, [size])

  const renderContent = (content: JSX.Element) => (
    <ChartWrapper justifyContent="center" alignItems="center">
      {content}
    </ChartWrapper>
  )

  const renderNoDataImage = () => <img src={chartNoData} alt={formatMessage(campaignCenterMessages.noData)} />

  if (_.isEmpty(histogram) && (!startDate || !endDate)) {
    return renderContent(
      <NoDataWrapper>
        {renderNoDataImage()}

        <NoDataTextWrapper>
          <Text fontWeight="bold">{formatMessage(campaignCenterMessages.campaignPerformanceChartSelectDates)}</Text>
        </NoDataTextWrapper>
      </NoDataWrapper>
    )
  } else if (error) {
    return renderContent(
      <NoDataWrapper>
        {renderNoDataImage()}

        <NoDataTextWrapper>
          <Text fontWeight="bold">{formatMessage(messages.campaignPerformanceChartError)}</Text>
        </NoDataTextWrapper>
      </NoDataWrapper>
    )
  } else if (isFetching) {
    return renderContent(<Loader />)
  } else if (_.isEmpty(histogram)) {
    return renderContent(
      <NoDataWrapper>
        {renderNoDataImage()}

        <NoDataTextWrapper>
          <Text fontWeight="bold">{formatMessage(messages.campaignPerformanceChartNoData)}</Text>
        </NoDataTextWrapper>
      </NoDataWrapper>
    )
  }

  return renderContent(
    <ChartContainer>
      <Chart
        key={key}
        rootProps={{ 'data-id': 'email-stats-chart' }}
        chartType="ComboChart"
        options={options}
        data={data}
        width="100%"
        height={`${CHART_HEIGHT}px`}
      />
    </ChartContainer>
  )
}

const ChartWrapper = styled(Flex)`
  width: 100%;
  height: ${CHART_HEIGHT}px;
  position: relative;
`

const NoDataWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
`

const NoDataTextWrapper = styled.div`
  position: absolute;
  top: 40%;
  width: 100%;
`

const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  [data-id='email-stats-chart'] {
    & > div > div:nth-child(1) > div > svg > g:nth-child(3) > g:nth-child(5) > circle {
      stroke: #0abcc2;
      stroke-width: 1;
      fill: white;
    }
  }
`
