import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { CoreLocales } from '../core.locales'

export function useDurationOptions() {
  const { formatMessage } = useLocales()
  const interval = 15
  const max = 960

  return useMemo(
    () =>
      [...Array(max / interval).keys()].map(idx => {
        const totalMinutes = idx * interval + interval
        return {
          id: totalMinutes,
          label: formatMessage(CoreLocales.durationTimeFormat, getHoursAndMinutes(totalMinutes)),
        }
      }),
    [formatMessage]
  )
}
