import React from 'react'
import styled from 'styled-components'
import Media from './Media'

// See https://github.com/styled-components/styled-components/issues/439
// for why we can't just do styled.div
const Row = styled('div')`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: ${props => (props.sideMargin ? props.sideMargin : '-15px')};
  margin-right: ${props => (props.sideMargin ? props.sideMargin : '-15px')};

  ${Media.sm`
    margin-left: ${props => (props.sideMargin ? props.sideMargin : '-15px')};
    margin-right: ${props => (props.sideMargin ? props.sideMargin : '-15px')};
  `}

  ${Media.md`
    margin-left: ${props => (props.sideMargin ? props.sideMargin : '-15px')};
    margin-right: ${props => (props.sideMargin ? props.sideMargin : '-15px')};
  `}

  ${Media.lg`
    margin-left: ${props => (props.sideMargin ? props.sideMargin : '-15px')};
    margin-right: ${props => (props.sideMargin ? props.sideMargin : '-15px')};
  `}

  ${Media.xl`
    margin-left: ${props => (props.sideMargin ? props.sideMargin : '-15px')};
    margin-right: ${props => (props.sideMargin ? props.sideMargin : '-15px')};
  `}
`

export default Row
