import _ from 'lodash'
import moment from 'moment-timezone'
import Theme from 'mgr/layout/Theme'
import { insertIntolist } from 'mgr/pages/single-venue/marketing/utils/list-utils'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'
import { TIME_FORMATS } from 'svr/common/TimeUtil'
import { MarketingCampaignType } from '../../../shared/utils/Constants'

const computeEmailCampaignListParams = state => ({
  campaigns: state.emails.campaigns.campaigns,
  campaign_activity: state.emails.campaigns.campaign_activity,
  campaignIds: state.emails.filters.campaignIds,
  user: state.appState.user,
  venue: state.appState.venue,
})

// TODO implement send time
const computeEmailCampaignListValues = ({ campaigns = [], campaign_activity = {}, campaignIds = [], user, venue = {} }) => {
  const filteredCampaigns = campaigns.filter(cmp => campaignIds.includes(cmp.id))
  const formatted = filteredCampaigns.map(campaign => {
    const activity = campaign_activity[campaign.id] || {}
    campaign.view_status = campaign.status
    campaign.locale = venue.locale
    if (campaign.is_one_time && campaign.is_active) {
      campaign.view_status = 'PENDING'
    }
    if (campaign.is_active || campaign.is_draft) {
      const estimated_num_clients = _.get(campaign, [
        campaign.is_one_time ? 'one_time_send_time_config' : 'ongoing_send_time_config',
        'estimated_num_clients',
      ])
      activity.estimated_num_clients = `~ ${estimated_num_clients}`
    }
    activity.revenue = activity.order_revenue + activity.reservation_revenue
    return {
      ...campaign,
      ...activity,
      num_opens: activity.num_recipients && Math.round((activity.num_opens / activity.num_recipients) * 100 || 0),
      num_clicks: activity.num_recipients && Math.round((activity.num_clicks / activity.num_recipients) * 100 || 0),
    }
  })
  return {
    list: {
      draft: formatted.filter(campaign => (user.is_superuser ? campaign.is_draft : campaign.is_draft && !campaign.is_one_time)),
      active: formatted.filter(campaign => campaign.is_active),
      inactive: formatted.filter(campaign => campaign.is_inactive && !campaign.is_one_time),
      oneTime: formatted.filter(campaign => campaign.is_one_time && campaign.is_sent),
    },
  }
}

const getCampaignDataValues = (date, reservations, orders, clickRate, openRate, isOrdersIntegrationEnabled) => {
  let campaignDataValues = [
    date,
    reservations,
    getToolTipHTML(date, `Reservations: ${reservations}`),
    clickRate,
    getToolTipHTML(date, `Clicked: ${clickRate.toFixed(2) * 100}%`),
    openRate,
    getToolTipHTML(date, `Opened: ${openRate.toFixed(2) * 100}%`),
  ]

  if (isOrdersIntegrationEnabled) {
    campaignDataValues = insertIntolist(campaignDataValues, 3, orders)
    campaignDataValues = insertIntolist(campaignDataValues, 4, getToolTipHTML(date, `orders: ${orders}`))
  }

  return campaignDataValues
}

const getColumnTwo = (formatted, currencyCode, link, locale, isOrdersIntegrationEnabled) => {
  const columns = [
    {
      value: formatted.totals.num_reservations,
      label: 'Reservations',
      color: Theme.color.lightGreen,
      fontSize: Theme.fontSize.large,
      link,
    },
    {
      value: formatted.totals.num_covers,
      label: 'Covers',
      color: Theme.color.grey,
      fontSize: Theme.fontSize.subheader,
    },
    {
      value: new Intl.NumberFormat(locale.replace('_', '-'), {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(parseFloat(formatted.totals.revenue)),
      label: 'Revenue',
      color: Theme.color.grey,
      fontSize: Theme.fontSize.subheader,
    },
  ]

  if (isOrdersIntegrationEnabled) {
    return insertIntolist(columns, 1, {
      value: formatted.totals.num_orders,
      label: 'Orders',
      color: Theme.color.lightPink,
      fontSize: Theme.fontSize.large,
    })
  }

  return columns
}
export const selectFormattedEmailCampaignList = createShallowEqualSelector(computeEmailCampaignListParams, computeEmailCampaignListValues)

const computeEmailActivityReportTotalsParams = state => ({
  campaign_activity: state.emails.campaigns.campaign_activity,
  currencyCode: state.appState.venue.currencyCode,
  locale: state.appState.venue.locale,
  venue: state.appState.venue,
  filters: state.emails.filters,
  isOrdersIntegrationEnabled: state.appState.venueSettings.is_orders_integration_enabled,
})

const computeEmailActivityTotalsReportValues = ({
  campaign_activity = {},
  currencyCode,
  locale,
  venue,
  filters,
  isOrdersIntegrationEnabled,
}) => {
  const formatted = Object.keys(campaign_activity).reduce(
    (acc, campaign) => {
      const activity = campaign_activity[campaign]
      acc.totals.num_recipients += activity.num_recipients
      acc.totals.num_opens += activity.num_opens
      acc.totals.num_clicks += activity.num_clicks
      acc.totals.num_reservations += activity.num_reservations
      acc.totals.num_covers += activity.num_covers
      acc.totals.num_orders += activity.num_orders
      acc.totals.revenue += activity.reservation_revenue + activity.order_revenue

      return acc
    },
    {
      totals: {
        num_recipients: 0,
        num_opens: 0,
        num_clicks: 0,
        num_reservations: 0,
        num_covers: 0,
        num_orders: 0,
        revenue: 0,
      },
    }
  )

  const percentages = {
    num_opens: Math.round((formatted.totals.num_opens / formatted.totals.num_recipients) * 100 || 0),
    num_clicks: Math.round((formatted.totals.num_clicks / formatted.totals.num_recipients) * 100 || 0),
  }

  const startDate = filters.startDate && encodeURIComponent(filters.startDate.format('MM/DD/YYYY'))
  const endDate = filters.endDate && encodeURIComponent(filters.endDate.format('MM/DD/YYYY'))
  const link =
    filters.date === 'all'
      ? `${window.origin}/manager/${venue.urlKey}/search/reservations?venues=${venue.urlKey}&date_type=EC_SEND_DATE`
      : `${window.origin}/manager/${venue.urlKey}/search/reservations?venues=${venue.urlKey}&date_type=EC_SEND_DATE&start_date_localized=${startDate}&end_date_localized=${endDate}&start_date=${startDate}&end_date=${endDate}`

  return {
    config: {
      column1: [
        {
          value: formatted.totals.num_recipients,
          label: 'Recipients',
          color: Theme.color.teal,
          fontSize: Theme.fontSize.large,
        },
        {
          value: `${percentages.num_opens}%`,
          hoverValue: formatted.totals.num_opens,
          label: 'Opens',
          color: Theme.color.grey,
          fontSize: Theme.fontSize.subheader,
        },
        {
          value: `${percentages.num_clicks}%`,
          label: 'Clicks',
          hoverValue: formatted.totals.num_clicks,
          color: Theme.color.grey,
          fontSize: Theme.fontSize.subheader,
        },
      ],
      column2: getColumnTwo(formatted, currencyCode, link, locale, isOrdersIntegrationEnabled),
    },
  }
}

export const selectFormattedEmailCampaignTotalsActivityReport = createShallowEqualSelector(
  computeEmailActivityReportTotalsParams,
  computeEmailActivityTotalsReportValues
)

const computeEmailCampaignChartDataParams = state => ({
  histogram: state.emails.campaigns.histogram,
  isOrdersIntegrationEnabled: state.appState.venueSettings.is_orders_integration_enabled,
})

const getToolTipHTML = (label, value) =>
  `<div class="custom-tooltip">
    <div class="tooltip-label">${label}</div>
    <div class="tooltip-value">${value}</div>
   </div>`

const computeEmailCampaignChartDataValues = ({ histogram = [], isOrdersIntegrationEnabled }) =>
  histogram.map(row => {
    const clickRate = (row.num_recipients && row.num_clicks / row.num_recipients) || 0
    const openRate = (row.num_recipients && row.num_opens / row.num_recipients) || 0
    const date = moment(row.date).format('MMM D')
    const reservations = row.num_reservations
    const orders = row.num_orders

    return getCampaignDataValues(date, reservations, orders, clickRate, openRate, isOrdersIntegrationEnabled)
  })

export const selectFormattedEmailCampaignChartData = createShallowEqualSelector(
  computeEmailCampaignChartDataParams,
  computeEmailCampaignChartDataValues
)

const computeSelectedCampaign = state => ({
  campaign: state.emails.wizard.campaign,
})

const computeIsOneTimeCampaign = ({ campaign }) => campaign.campaign_type === MarketingCampaignType.ONE_TIME

export const selectIsOneTimeCampaign = createShallowEqualSelector(computeSelectedCampaign, computeIsOneTimeCampaign)

export const getTimeFormat = () => (globalInit.venueLocale === 'en_US' ? TIME_FORMATS.regular : TIME_FORMATS.military)
