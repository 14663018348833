import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { EmailCampaignTemplate } from '@sevenrooms/core/api'
import type { State } from 'mgr/pages/reducers/CombineReducer'

export interface EmailTemplateState {
  campaignTemplateToCopy: EmailCampaignTemplate | null
}

const initialState: EmailTemplateState = {
  campaignTemplateToCopy: null,
}

const emailTemplateSlice = createSlice({
  name: 'emailTemplate',
  initialState,
  reducers: {
    setCampaignTemplateToCopy: (state, action: PayloadAction<EmailCampaignTemplate | null>) => {
      state.campaignTemplateToCopy = action.payload
    },
  },
})

export const getCampaignTemplateToCopy = (state: State): EmailCampaignTemplate | null => state.emailCampaignTemplate.campaignTemplateToCopy
export const { reducer: emailCampaignTemplateReducer } = emailTemplateSlice
export const { setCampaignTemplateToCopy } = emailTemplateSlice.actions
