import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import ClientThumbnail from 'mgr/lib/components/ClientThumbnail'
import SourceBadge from 'mgr/lib/components/SourceBadge'
import Tags from 'mgr/lib/components/Tags'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import { Col, Row } from 'svr/lib/styled-bootstrap-grid'
import { CardBase, ViewCardTitle } from './ViewCommon'
import { FlexColumnContainer, FlexRowContainer } from './ViewLayout'

const LightText = styled.div`
  color: ${props => props.theme.navigationDark};
  font-size: 13px;
  position: relative;
`

const HeaderTitle = styled(ViewCardTitle)`
  text-transform: none;
  margin: 0;
`

const SourceName = styled.div`
  font-family: ${props => props.theme.fontFamily};
  font-size: 10px;
  font-style: oblique;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.theme.lightGrey};
  overflow: hidden;
  width: 150px;
`

const LoyaltyInfo = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  float: left;
  height: 22px;
  padding: 4px 8px !important;
  margin: 2px !important;
  border-radius: 11px;
  box-sizing: border-box;
  list-style: none;
`

const PhoneSection = ({ phone, sourceName, sourceCompany }) => (
  <FlexColumnContainer
    justifyContent="center"
    alignItems="center"
    style={{
      height: '100%',
      width: 'auto',
      textAlign: 'left',
      marginLeft: '10px',
    }}
  >
    <FlexRowContainer justifyContent="center" alignItems="flex-start">
      <LightText>
        <StyledVmsIconXS style={{ marginRight: '5px' }}>
          {sourceName && <SourceBadge badgeSize={18} top={-14} right={-10} />}
          {VmsIcons.PhoneLine}
        </StyledVmsIconXS>
      </LightText>

      <FlexColumnContainer>
        <LightText data-test="sr-client_phone">{phone}</LightText>
        {sourceName && (
          <div>
            <SourceName>{sourceName}</SourceName>
            <SourceName>{sourceCompany || ''}</SourceName>
          </div>
        )}
      </FlexColumnContainer>
    </FlexRowContainer>
  </FlexColumnContainer>
)

const CornerFlag = styled.span`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 32px 32px 0 0;
  border-color: ${props => props.theme.yellow} transparent transparent transparent;
  position: absolute;
`

const Background = styled.div`
  background-color: ${props => props.theme.background};
  margin-bottom: 4px;
`

export const IconifiedLink = styled.div`
  height: 24px;
  width: 24px;
  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
  font-size: 24px;
  line-height: 19px;
  text-align: center;
  margin-right: 8px !important;
  display: block;
  color: ${props => props.theme.mediumGrey} !important;

  &:link {
    color: ${props => props.theme.mediumGrey} !important;
  }

  &:hover {
    color: ${props => props.theme.primary} !important;
  }

  &:active {
    border-radius: 12px;
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.white} !important;
  }
`

export const eventNoOp = e => {
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
  return false
}
const ViewReservationHeader = ({
  name,
  initials,
  photoObj,
  phone,
  clientTags,
  isVip,
  onClick,
  sourceName,
  sourceCompany,
  canEditReservation,
  clientId,
  venueId,
  loyaltyId,
  loyaltyRank,
  loyaltyTier,
  ...rest
}) => {
  const phoneBody = phone && <PhoneSection sourceName={sourceName} sourceCompany={sourceCompany} phone={phone} />
  const vipFlag = isVip && <CornerFlag />
  const clientLink = `/manager/${venueId}/clients/profile/${clientId}?ref=sf_client_header_link`
  const loyaltyContents = _.compact([loyaltyId, loyaltyTier, loyaltyRank]).join(' · ')
  return (
    <Background>
      <Row>
        <Col xs={12} sm={8} md={9} style={{ paddingRight: 0 }}>
          {vipFlag}
          <CardBase
            onClick={onClick}
            canEditReservation={canEditReservation}
            style={{
              height: '100%',
              paddingLeft: '21px',
              paddingTop: '12px',
              paddingBottom: '12px',
              borderRight: '1px solid #DCDEE1',
            }}
          >
            <FlexRowContainer justifyContent="flex-start" alignItems="center" style={{ position: 'relative' }}>
              <ClientThumbnail margin="0" photoObj={photoObj} nameDisplay={name} initials={initials} />
              <FlexColumnContainer justifyContent="center">
                <HeaderTitle data-test="sr-client_name" style={{ paddingLeft: '11px' }}>
                  {name}
                </HeaderTitle>
                {loyaltyContents ? (
                  <FlexRowContainer>
                    <LoyaltyInfo>{loyaltyContents}</LoyaltyInfo>
                  </FlexRowContainer>
                ) : null}
                <Tags tags={clientTags} />
              </FlexColumnContainer>
            </FlexRowContainer>

            <a
              onClick={eventNoOp}
              href={clientLink}
              style={{
                height: '64px',
                top: 0,
                paddingTop: '8px',
                right: 0,
                position: 'absolute',
              }}
              target="_blank"
              rel="noreferrer"
            >
              <IconifiedLink>
                <StyledVmsIconXS>{VmsIcons.ExternalLink}</StyledVmsIconXS>
              </IconifiedLink>
            </a>
          </CardBase>
        </Col>

        <Col xs={12} sm={4} md={3} style={{ paddingLeft: 0 }}>
          <CardBase style={{ height: '100%' }}>{phoneBody}</CardBase>
        </Col>
      </Row>
    </Background>
  )
}

export default ViewReservationHeader
