import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { emailCampaignsApi } from '@sevenrooms/core/api'
import { EmailCampaignStatusEnum, OneTimeEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useHistory } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  HStack,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
  notify,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, useStoreSelector } from '@sevenrooms/mgr-core'
import { emailCenterMessages } from '../../locales'
import { setEmailCampaignTableRows, clearDeactivateEmailCampaignModalState, type DeactivateEmailCampaignModalState } from '../../store'
import type { EmailCampaignTableRow } from '../EmailCampaignTable'

export interface DeactivateEmailCampaignModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function DeactivateEmailCampaignModal({ closeHref }: DeactivateEmailCampaignModalProps) {
  const [updatedEmailCampaignTableRows, setUpdatedEmailCampaignTableRows] = useState<EmailCampaignTableRow[]>([])
  const deactivateEmailCampaignModalState: DeactivateEmailCampaignModalState = useStoreSelector(state => state.deactivateEmailCampaignModal)
  const { formatMessage } = useLocales()
  const history = useHistory()
  const dispatch = useDispatch()
  const [updateEmailCampaignsQuery, { isSuccess, isError, isLoading }] = emailCampaignsApi.useUpdateEmailCampaignsMutation()
  const emailCampaignTableRows: EmailCampaignTableRow[] = useStoreSelector(state => _.cloneDeep(state.emailCampaignTable.rows))
  const { venue } = useVenueContext()
  const { emailCampaignTableRowIndex } = deactivateEmailCampaignModalState || {}
  const emailCampaignTableRow = emailCampaignTableRows[emailCampaignTableRowIndex]

  useMemo(() => {
    // Close the modal if state is null. This will happen if someone navigates to this URL directly.
    if (!deactivateEmailCampaignModalState) {
      history.push(closeHref)
    } else if (isSuccess) {
      dispatch(setEmailCampaignTableRows(updatedEmailCampaignTableRows))
      dispatch(clearDeactivateEmailCampaignModalState())
      history.push(closeHref)
    } else if (isError) {
      notify({
        content: formatMessage(emailCenterMessages.deactivateEmailCampaignModalErrorMessage, {
          emailCampaignName: emailCampaignTableRow?.campaignName,
        }),
        type: 'error',
      })
    }
  }, [
    closeHref,
    dispatch,
    emailCampaignTableRow?.campaignName,
    formatMessage,
    history,
    isError,
    isSuccess,
    deactivateEmailCampaignModalState,
    updatedEmailCampaignTableRows,
  ])

  if (!(venue && deactivateEmailCampaignModalState && emailCampaignTableRow)) {
    return null
  }

  const titleText = formatMessage(emailCenterMessages.deactivateEmailCampaignModalDeactivateOneTitle, {
    emailCampaignName: emailCampaignTableRow.campaignName,
  })
  const bodyText = formatMessage(emailCenterMessages.deactivateEmailCampaignModalDeactivateOneBody, {
    emailCampaignName: emailCampaignTableRow.campaignName,
  })

  const handleConfirm = () => {
    if (emailCampaignTableRow === undefined) {
      return
    }

    emailCampaignTableRow.status = EmailCampaignStatusEnum.INACTIVE
    const updatedEmailCampaignTableRows = emailCampaignTableRows
    updatedEmailCampaignTableRows[emailCampaignTableRowIndex] = emailCampaignTableRow
    setUpdatedEmailCampaignTableRows(updatedEmailCampaignTableRows)
    updateEmailCampaignsQuery({
      emailCampaignIds: [emailCampaignTableRow.id],
      emailCampaignUpdate: { status: OneTimeEmailCampaignStatusEnum.INACTIVE },
      venueId: venue.id,
    })
  }

  const handleCancel = () => {
    dispatch(clearDeactivateEmailCampaignModalState())
    history.push(closeHref)
  }

  return (
    <Modal ariaLabel={titleText}>
      <ModalHeader onClose={handleCancel}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Icon name="VMSWeb-warning" color="error" />
            <Text color="error" fontWeight="bold">
              {formatMessage(commonMessages.warning)}
            </Text>
          </HStack>
          <ModalTitle title={titleText} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>{bodyText}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button data-test="toggle-email-campaign-status-modal-cancel-button" variant="secondary" onClick={handleCancel}>
            {formatMessage(emailCenterMessages.deactivateEmailCampaignModalCancel)}
          </Button>
          <Button
            data-test="toggle-email-campaign-status-modal-confirm-button"
            variant="primary-warning"
            type="submit"
            onClick={handleConfirm}
          >
            {isLoading ? '...' : formatMessage(emailCenterMessages.deactivateEmailCampaignModalConfirm)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
