import { add, differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'
import { useMemo } from 'react'
import styled from 'styled-components'
import { HStack } from '@sevenrooms/core/ui-kit/layout'

interface PaylinkBannerProps {
  autoCancelDatetime: string
}

export function PaylinkBanner({ autoCancelDatetime }: PaylinkBannerProps) {
  const formattedTimeRemaining = useMemo(() => {
    const autoCancelDate = new Date(autoCancelDatetime)
    let remaining = new Date(new Date().toISOString().slice(0, -1))

    const days = differenceInDays(autoCancelDate, remaining)
    remaining = add(remaining, { days })
    const hours = differenceInHours(autoCancelDate, remaining)
    remaining = add(remaining, { hours })
    const minutes = differenceInMinutes(autoCancelDate, remaining)

    return `${(days > 0 ? `${days}d ` : '') + (hours > 0 ? `${hours}hr ` : '') + (minutes > 0 ? `${minutes}m` : '')} remaining`
  }, [autoCancelDatetime])

  return (
    <StyledPaylinkBanner data-test="sr-paylink-banner">
      <HStack alignItems="center" justifyContent="space-between">
        <PaylinkBannerLabel>Autocancel Paylink</PaylinkBannerLabel>
        <PaylinkBannerTimeRemaining>{formattedTimeRemaining}</PaylinkBannerTimeRemaining>
      </HStack>
    </StyledPaylinkBanner>
  )
}

const StyledPaylinkBanner = styled.div`
  margin: 4px;
  padding: 7px 15px;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.theme.color.warningAccent};
  background-color: ${props => props.theme.color.warningBackground};
`

const PaylinkBannerTimeRemaining = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.secondaryText};
`

const PaylinkBannerLabel = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.black};
  font-weight: 600;
`
