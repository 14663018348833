import React from 'react'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { emailCenterMessages } from '../../locales'

export function StartOngoingFromScratchCard() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()

  if (!venue || !venueSettings) {
    return null
  }

  return (
    <SectionWrapper>
      <BaseSection>
        <StyledVStack
          ml="l"
          mr="l"
          mb="l"
          standardCampaignEnabled={venueSettings.pre_built_campaigns_enabled}
          customCampaignEnabled={venueSettings.is_custom_automated_emails_enabled}
          alignItems="center"
          justifyContent="space-between"
          minHeight="194px"
        >
          {venueSettings.is_custom_automated_emails_enabled ? (
            <VStack alignItems="center">
              <Header type="h3">{formatMessage(emailCenterMessages.emailCenterCustomCampaignsTitle)}</Header>
              <Description>{formatMessage(emailCenterMessages.emailCenterCustomCampaignsDescription)}</Description>
            </VStack>
          ) : (
            <VStack alignItems="center">
              <Header type="h3">{formatMessage(emailCenterMessages.emailCenterCustomCampaignsDisabledTitle)}</Header>
              <Description>{formatMessage(emailCenterMessages.emailCenterCustomCampaignsDisabledDescription)}</Description>
            </VStack>
          )}
          <Button
            data-test="start-from-scratch-button"
            disabled={!venueSettings.is_custom_automated_emails_enabled}
            variant="primary"
            onClick={() => {
              nav.push(routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromScratch, {
                params: { venueKey: venue.urlKey },
                queryMode: 'clear',
              })
            }}
          >
            {formatMessage(emailCenterMessages.emailCenterCreateFromScratch)}
          </Button>
        </StyledVStack>
      </BaseSection>
    </SectionWrapper>
  )
}

interface MarginTopMode {
  standardCampaignEnabled: boolean
  customCampaignEnabled: boolean
}

const StyledVStack = styled(VStack)<MarginTopMode>`
  margin-top: ${props => (props.standardCampaignEnabled && props.customCampaignEnabled ? '25px' : '23px')};
`

const SectionWrapper = styled.div`
  border: 1px solid #cccccc;
  box-sizing: border-box;
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: 4px;
  width: 315px;
  section {
    border: none;
  }
`

const Description = styled(Text)`
  padding: 12px 0px;
  text-align: center;
`
