import _ from 'lodash'
import React, { PureComponent } from 'react'
import ReactDom from 'react-dom'
import styled from 'styled-components'
import StyledComponentUtils, { ClearFix, makeOptionalAttr } from 'mgr/layout/StyledComponentUtils'
import StyleUtils from 'mgr/layout/StyleUtils'
import { VmsIcons, StyledVmsIconS, StyledVmsIconXS } from 'svr/common/VmsIcons'
// Anchor for scrollIntoView so that we don't drop the top margin upon scroll to BookStepWrapper
const BookStepScrollToAnchor = styled.div`
  height: 3px;
`

export const BookStepWrapper = styled.div`
  margin: 0 ${props => props.theme.gutter.half};
  border-radius: 6px;
  background-color: ${props => props.theme.background};
  position: relative;
  :hover {
    box-shadow: ${props => (props.isCollapsible || !props.isCollapsed) && '0 0 8px 0 rgba(0, 0, 0, 0.3)'};
  }
`
const BookStepHeader = styled.div`
  min-height: 76px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.double};
  ${props => (props.isCollapsible ? props.theme.whiteClickableRow : '')} ${props => props.theme.clearFix};
`

/* eslint-disable no-nested-ternary */
const BookStepHeaderIcon = styled(StyledVmsIconS)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 38px;
  height: 38px;
  box-sizing: border-box;
  border: 2px solid ${props => (props.isEmpty && props.isCollapsed ? props.theme.darkGrey : props.theme.navigationDark)};
  border-radius: 50%;
  color: ${props => (!props.isCollapsed ? props.theme.navigationDark : props.isEmpty ? props.theme.darkGrey : props.theme.white)};
  background-color: ${props => (!props.isEmpty && props.isCollapsed ? props.theme.navigationDark : 'transparent')};
  text-align: center;
  opacity: ${props => !props.isCollapsible && props.isCollapsed && '0.5'};
`

const BookStepHeaderContent = styled.div`
  padding: 0 ${props => props.theme.gutter.double};
  flex: 1;
`

const BookStepHeaderTitle = styled.div`
  color: ${props => props.theme.navigationDark};
  font-size: 20px;
  font-weight: 500;
  opacity: ${props => !props.isCollapsible && props.isCollapsed && '0.5'};
`

const BookStepHeaderRightContainer = styled.div`
  align-self: flex-start;
`

const BookStepHeaderTitleMiddle = styled(BookStepHeaderTitle)`
  line-height: 52px;
`

const BookStepHeaderSubTitle = styled.div`
  color: ${props => props.theme.navigationDark};
  font-size: 13px;
  font-weight: 500;
  margin-top: 6px;
`

const BookStepHeaderCollapseCaret = styled(StyledVmsIconXS)`
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  height: 21px;
  width: 21px;
  border: 1px solid ${props => props.theme.navigationDark};
  border-radius: 50%;
  margin: 27px 18px 0 0;
  transform: rotate(90deg);
  padding: 2px 0 0 3px;
`

const BookStepContents = styled.div`
  overflow: ${props => (props.isCollapsed ? 'hidden' : 'visible')};
  ${props => makeOptionalAttr('max-height', props.maxHeight)}
  transition: ${props => props.transition};
  padding-bottom: ${props => (props.isCollapsed ? '0px' : '10px')};
`

class BookStepComponent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { bookStepContentsHeight: '0px' }
    this.isCollapsing = false
    this.onToggleTransitionEnd = this.onToggleTransitionEnd.bind(this)
  }

  onToggleTransitionEnd() {
    const { scrollIntoView, onCompleteScrollIntoView, onCompleteToggleAnimation } = this.props
    if (scrollIntoView) {
      ReactDom.findDOMNode(this).scrollIntoView()
      onCompleteScrollIntoView()
    }
    onCompleteToggleAnimation()
  }

  componentWillReceiveProps(nextProps) {
    this.isCollapsing = nextProps.isCollapsed && !this.props.isCollapsed
  }

  updateBookStepContentsHeight() {
    const { isCollapsed } = this.props
    const { bookStepContentsHeight } = this.state
    // eslint-disable-next-line react/no-string-refs
    const { bookStepContentsInner } = this.refs
    // eslint-disable-next-line no-nested-ternary
    const computedBookStepContentsHeight = isCollapsed ? '0px' : bookStepContentsInner ? '100%' : '45px'
    if (bookStepContentsHeight !== computedBookStepContentsHeight) {
      this.setState({ bookStepContentsHeight: computedBookStepContentsHeight })
    }
  }

  componentWillUpdate() {
    this.updateBookStepContentsHeight()
  }

  componentDidMount() {
    this.updateBookStepContentsHeight()
  }

  componentDidUpdate() {
    const { isCollapsed, scrollIntoView, onCompleteScrollIntoView } = this.props
    this.updateBookStepContentsHeight()
    if (scrollIntoView && !this.isCollapsing) {
      ReactDom.findDOMNode(this).scrollIntoView()
      onCompleteScrollIntoView()
    }
  }

  render() {
    const {
      isCollapsed,
      isCollapsible,
      isEmpty,
      icon,
      iconLine,
      testId,
      title,
      subTitle,
      children,
      onToggle,
      headerLeft,
      headerRight,
      superHeaderSection,
    } = this.props
    const { bookStepContentsHeight } = this.state
    const showContentsHeader = (isCollapsed && !isEmpty) || !_.isEmpty(subTitle)
    const HeaderTitleStyle = showContentsHeader ? BookStepHeaderTitle : BookStepHeaderTitleMiddle
    const collapsingStyles = { maxHeight: bookStepContentsHeight }
    _.extend(collapsingStyles, StyleUtils.transition('max-height', '100ms'))
    return (
      <div data-test={testId}>
        <BookStepScrollToAnchor />
        <BookStepWrapper {...{ isCollapsible, isCollapsed }}>
          {superHeaderSection}
          <BookStepHeader onClick={isCollapsible ? onToggle : null} {...{ isCollapsible }}>
            {icon && (
              <BookStepHeaderIcon {...{ isEmpty, isCollapsed, isCollapsible }}>
                {isEmpty || !isCollapsed ? iconLine : icon}
              </BookStepHeaderIcon>
            )}
            <BookStepHeaderContent>
              {headerLeft || (
                <div>
                  <HeaderTitleStyle data-test="sr-section_header" {...{ isCollapsed, isCollapsible }}>
                    {title}
                  </HeaderTitleStyle>
                  {subTitle ? <BookStepHeaderSubTitle>{subTitle}</BookStepHeaderSubTitle> : null}
                </div>
              )}
            </BookStepHeaderContent>
            {headerRight && <BookStepHeaderRightContainer>{headerRight}</BookStepHeaderRightContainer>}
            {!isCollapsed && isCollapsible ? <BookStepHeaderCollapseCaret>{VmsIcons.Arrow}</BookStepHeaderCollapseCaret> : null}
          </BookStepHeader>
          <BookStepContents {...collapsingStyles} {...{ isCollapsed }} onTransitionEnd={this.onToggleTransitionEnd}>
            {/* eslint-disable-next-line react/no-string-refs */}
            <div ref="bookStepContentsInner">
              <ClearFix>{children}</ClearFix>
            </div>
          </BookStepContents>
        </BookStepWrapper>
      </div>
    )
  }
}

BookStepComponent.propTypes = {
  isCollapsed: React.PropTypes.bool,
  isCollapsible: React.PropTypes.bool,
  isEmpty: React.PropTypes.bool,
  testId: React.PropTypes.string,
  title: React.PropTypes.string.isRequired,
  subTitle: React.PropTypes.string,
  icon: React.PropTypes.string,
  iconLine: React.PropTypes.string,
  onToggle: React.PropTypes.func.isRequired,
  scrollIntoView: React.PropTypes.bool,
  onCompleteScrollIntoView: React.PropTypes.func.isRequired,
  onCompleteToggleAnimation: React.PropTypes.func.isRequired,
  headerLeft: React.PropTypes.object,
  headerRight: React.PropTypes.object,
  superHeaderSection: React.PropTypes.object,
}

BookStepComponent.defaultProps = {
  isCollapsed: true,
  isCollapsible: true,
  isEmpty: true,
  title: '',
  subTitle: null,
  icon: null,
  iconLine: null,
  scrollIntoView: false,
  onToggle: () => {},
  onCompleteScrollIntoView: () => {},
  onCompleteToggleAnimation: () => {},
}

export default BookStepComponent
