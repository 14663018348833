import { useState } from 'react'
import type { GuestFacingUpgradeInventory } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales, FormatService } from '@sevenrooms/core/locales'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { BasicButton, Counter } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  DividerLine,
  HStack,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  PreviewImage,
  VStack,
  Window,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { upgradesMessages } from './Upgrades.locales'
import type { UpgradesForm } from './Upgrades.zod'

export interface UpgradeProps extends GuestFacingUpgradeInventory {
  field: Field<UpgradesForm['categories'][string]['upgrades'][string]>
  currencyCode: string
  max?: number
}

export function Upgrade({ id, field, currencyCode, name, description, isPriceHidden, price, previewImageUrlKey, max }: UpgradeProps) {
  const { formatMessage } = useLocales()
  const [isActive, setIsActive] = useState(false)

  const value = useWatch(field)

  const priceLabel =
    isPriceHidden || formatMessage(upgradesMessages.pricePerEachLabel, { price: FormatService.formatCurrency(price, currencyCode) })

  const totalPrice = isPriceHidden || value === 0 || FormatService.formatCurrency(price * value, currencyCode)

  return (
    <>
      <HStack alignItems="center" height="60px" p="xs l" data-test={`${id}-upgrade`}>
        <VStack spacing="xxs" flex="auto">
          <HStack spacing="xs" alignItems="center">
            <Text fontWeight="bold">{name}</Text>
            <BasicButton aria-label={formatMessage(commonMessages.additionalInformation)} onClick={() => setIsActive(true)}>
              <Text color="primaryIcons" fontSize="l">
                <Icon name="VMSWeb-info" />
              </Text>
            </BasicButton>
            <Window active={isActive} setIsActive={setIsActive}>
              <Modal ariaLabel={name} data-test="upgrade-info-dialog">
                <ModalHeader onClose={() => setIsActive(false)}>
                  <VStack spacing="s">
                    <ModalTitle title={name} />
                    <Text>{priceLabel}</Text>
                  </VStack>
                </ModalHeader>
                <ModalBody>
                  <VStack spacing="m" mb="lm">
                    <Text>
                      <HTMLContent content={description} />
                    </Text>
                    {previewImageUrlKey && <PreviewImage maxWidth="200px" maxHeight="200px" alt="" src={previewImageUrlKey} />}
                  </VStack>
                </ModalBody>
              </Modal>
            </Window>
          </HStack>
          <Text textStyle="body1">{priceLabel}</Text>
        </VStack>
        {totalPrice && (
          <Box mr="lm">
            <Text fontWeight="bold">{totalPrice}</Text>
          </Box>
        )}
        <Box width="110px">
          <Counter fullWidth iconColor="primaryButton" max={max != null ? max + value : undefined} field={field} />
        </Box>
      </HStack>
      <DividerLine margin="none" hideLast />
    </>
  )
}
