import { type PropsWithChildren, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import AvailabilityTimeSlot from 'mgr/actualslideout/components/availability/AvailabilityTimeSlot'
import { LoadingSpinner } from 'mgr/layout/StyledComponentUtils'
import type { AvailabilityTimeslot, InternalAvailabilityTimeslot, MixedAvailabilityTimeslot } from '@sevenrooms/core/api'
import { TimeslotDefaults, type ShiftCategory } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { ErrorMessage } from '@sevenrooms/core/ui-kit/form'
import { Grid, type GridProps, VStack } from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme, type ThemeSpacing } from '@sevenrooms/core/ui-kit/theme'
import { Timeslot } from '@sevenrooms/core/ui-kit/vx-layout'
import { changeType } from '../../actions/BookAvailabilityActions'
import { useStoreSelector, type State } from '../../selectors/useStoreSelector'
import { AvailabilityLocales } from './Availability.locales'
import { mixTimeslot } from './createARTimeslotHelper'
import { MultiVenueAvailabilityDayEvents } from './MultiVenueAvailabilityDayEvents'
import { useGetInternalBookingExperiencesQuery } from './useGetInternalBookingExperiencesQuery'
import { useGetItemFromTimeslot } from './useGetItemFromTimeslot'
import {
  type MultiVenueAvailabilityTimeslotsResponse,
  useMultiVenueAvailabilityTimeslotsRequest,
} from './useMultiVenueAvailabilityTimeslotsRequest'
import type { AccessRulesTimeslotsProps } from './AccessRulesTimeslots'

export interface MultiVenueAccessRulesTimeslots extends Omit<AccessRulesTimeslotsProps, 'onChange | singleVenueId | timeSlotRange'> {
  searchVenues: State['bookAvailabilityState']['searchVenues']
  onSelectShiftTimeslot: (
    timeslot: InternalAvailabilityTimeslot,
    venue: State['bookAvailabilityState']['searchVenues'][number],
    isVenueChanged?: boolean
  ) => void
  onSelectARTimeslot: (
    timeslot: MixedAvailabilityTimeslot,
    isPreviousTime: boolean,
    isDirectlySelected: boolean,
    venue: State['bookAvailabilityState']['searchVenues'][number]
  ) => void
}

function MultiVenueAccessRulesTimeslotsComponent(props: MultiVenueAccessRulesTimeslots) {
  const { availabilityByVenue } = useStoreSelector(state => state.bookAvailabilityState)
  const { isFetching, availabilityARByVenue } = useMultiVenueAvailabilityTimeslotsRequest()
  const av = Object.values(availabilityByVenue ?? {})
  const isTimesLoading = av.some(({ isTimesLoading }) => isTimesLoading)
  const isTimesLoadError = av.some(({ isTimesLoadError }) => isTimesLoadError)

  if (isFetching || !availabilityARByVenue || isTimesLoading || !availabilityByVenue) {
    return <LoadingSpinner />
  } else if (isTimesLoadError) {
    return <ErrorMessage>Load Error</ErrorMessage>
  }
  return (
    <MultiVenueAccessRulesTimeslotsGrid
      {...props}
      availabilityByVenue={availabilityByVenue}
      availabilityARByVenue={availabilityARByVenue}
    />
  )
}

interface MultiVenueAccessRulesTimeslotsGridProps extends MultiVenueAccessRulesTimeslots {
  availabilityByVenue: State['bookAvailabilityState']['availabilityByVenue']
  availabilityARByVenue: MultiVenueAvailabilityTimeslotsResponse
}

function MultiVenueAccessRulesTimeslotsGrid({
  availabilityByVenue,
  availabilityARByVenue,
  searchVenues,
  selectedTimeslot,
  ...props
}: MultiVenueAccessRulesTimeslotsGridProps) {
  const fakeTimeslot = useMemo(() => {
    const accessPersistenceId = selectedTimeslot?.access_persistent_id
    if (selectedTimeslot == null || accessPersistenceId == null) {
      return undefined
    }
    for (const [venueId, timeslots] of Object.entries(availabilityARByVenue)) {
      const index = timeslots.findIndex(({ timeslot }) => timeslot.access_persistent_id === accessPersistenceId)
      const timeslot = timeslots[index]
      if (index !== -1 && timeslot != null) {
        return {
          venueId,
          index,
          fake: {
            timeslot: selectedTimeslot,
            category: timeslot.category,
          },
        }
      }
    }
    return undefined
  }, [selectedTimeslot, availabilityARByVenue])

  const uniqueTimes = useMemo(() => {
    const availableTimes = searchVenues
      .map(venue => availabilityByVenue[venue.id]?.availableTimes ?? [])
      .flat()
      .map(item => [TimeOnly.from(item.time_iso).toMinutes(), item.time_iso] as const)
      .sort(([a], [b]) => a - b)
      .map(([_iso, time]) => time)
    return [...new Set(availableTimes)]
  }, [availabilityByVenue, searchVenues])

  return (
    <VStack spacing="s">
      <TimslotSection>
        <TimeslotRow repeat={searchVenues.length}>
          {searchVenues.map(venue => (
            <div style={{ margin: '3px 5px' }} key={venue.id}>
              <MultiVenueAvailabilityDayEvents searchVenue={venue} isSelectedVenue={venue.id === selectedTimeslot?.venue_id} />
            </div>
          ))}
        </TimeslotRow>
      </TimslotSection>
      {uniqueTimes.map(timeIso => (
        <MultiVenueAccessRulesTimeslotsGridSingleTime
          {...props}
          key={timeIso}
          availabilityByVenue={availabilityByVenue}
          availabilityARByVenue={availabilityARByVenue}
          searchVenues={searchVenues}
          selectedTimeslot={selectedTimeslot}
          timeIso={timeIso}
          fakeTimeslot={fakeTimeslot?.fake?.timeslot?.time_iso === timeIso ? fakeTimeslot : undefined}
        />
      ))}
    </VStack>
  )
}

interface MultiVenueAccessRulesTimeslotsGridSingleTimeProps extends MultiVenueAccessRulesTimeslotsGridProps {
  timeIso: string
  fakeTimeslot?: {
    venueId: string
    index: number
    fake: {
      timeslot: AvailabilityTimeslot
      category: ShiftCategory
    }
  }
}

function MultiVenueAccessRulesTimeslotsGridSingleTime({
  availabilityByVenue,
  availabilityARByVenue,
  searchVenues,
  timeIso,
  fakeTimeslot,
  selectedTimeslot,
  previousSelectedTimeslot,
  hasChangedCriteriaFromLastSave,
  onSelectShiftTimeslot,
  onSelectARTimeslot,
}: MultiVenueAccessRulesTimeslotsGridSingleTimeProps) {
  const { formatMessage } = useLocales()
  const getItemFromTimeslot = useGetItemFromTimeslot()
  const { typeFilter } = useStoreSelector(state => state.bookAvailabilityState)
  const { allExperiences, selectedExperienceName } = useGetInternalBookingExperiencesQuery()

  const shiftTimes = useMemo(
    () =>
      searchVenues.map(
        venue => [venue, availabilityByVenue[venue.id]?.availableTimes?.filter(av => av.time_iso === timeIso)?.[0]] as const
      ),
    [availabilityByVenue, searchVenues, timeIso]
  )

  const selectedTimeslotVenueId = useMemo(
    () =>
      Object.entries(availabilityByVenue).find(([_venueId, av]) =>
        av.availableTimes?.some(timeslot => timeslot.shift_persistent_id === selectedTimeslot?.shift_persistent_id)
      )?.[0],
    [selectedTimeslot, availabilityByVenue]
  )

  const [availabilityARByVenueForTime, maxAR, keys] = useMemo(() => {
    const entries = searchVenues.map(venue => {
      const av = availabilityARByVenue[venue.id]?.filter(ar => ar.timeslot.time_iso === timeIso) ?? []
      const fake =
        fakeTimeslot != null &&
        fakeTimeslot.venueId === venue.id &&
        av.findIndex(ar => ar.timeslot.access_persistent_id === fakeTimeslot.fake.timeslot.access_persistent_id) === -1
          ? [fakeTimeslot.fake]
          : []

      const filteredAvailability = av.filter(ar => {
        const selectedTypeOption = selectedExperienceName || typeFilter
        if (selectedTypeOption == null || selectedTypeOption === TimeslotDefaults.ALL) {
          return true
        }
        const item = getItemFromTimeslot(ar.timeslot)
        const itemName = item.name || formatMessage(AvailabilityLocales.noTimeslotDescriptionTypeName)
        if (selectedTypeOption === itemName) {
          return true
        }
        return (
          allExperiences
            .filter(experience => experience.name === selectedTypeOption)
            .findIndex(experience => experience.id === ar.timeslot.experience_id) !== -1
        )
      })

      return [venue.id, filteredAvailability.concat(fake)] as const
    })
    const accessRulesForTime = Object.fromEntries(entries)
    const max = Math.max(...entries.map(([_v, ar]) => ar.length))
    const keys = [...Array(max).keys()].map(i =>
      searchVenues.map(venue => accessRulesForTime[venue.id]?.[i]?.timeslot?.access_persistent_id ?? 'nada').join('-')
    )
    return [accessRulesForTime, max, keys] as const
  }, [
    searchVenues,
    availabilityARByVenue,
    fakeTimeslot,
    timeIso,
    selectedExperienceName,
    typeFilter,
    getItemFromTimeslot,
    formatMessage,
    allExperiences,
  ])

  const isShiftSelected = useCallback(
    (timeslot: InternalAvailabilityTimeslot) =>
      Boolean(
        selectedTimeslot &&
          !selectedTimeslot.access_persistent_id &&
          selectedTimeslot.venue_id === timeslot.venue_id &&
          selectedTimeslot.sort_order === timeslot.sort_order
      ),
    [selectedTimeslot]
  )

  const isARSelected = useCallback(
    (timeslot: AvailabilityTimeslot) =>
      Boolean(
        selectedTimeslot &&
          selectedTimeslot.access_persistent_id &&
          selectedTimeslot.access_persistent_id === timeslot.access_persistent_id &&
          selectedTimeslot.sort_order === timeslot.sort_order
      ),
    [selectedTimeslot]
  )

  const onARSelect = useCallback(
    (timeslot: AvailabilityTimeslot, category: ShiftCategory, venue: State['bookAvailabilityState']['searchVenues'][number]) => {
      const isPreviousTime =
        !!previousSelectedTimeslot &&
        timeslot.real_datetime_of_slot === previousSelectedTimeslot.real_datetime_of_slot &&
        timeslot.access_persistent_id === previousSelectedTimeslot.access_persistent_id &&
        !hasChangedCriteriaFromLastSave

      const internalTimeslot = availabilityByVenue[venue.id]?.availableTimes?.find(t => t.sort_order === timeslot.sort_order)

      onSelectARTimeslot(mixTimeslot(timeslot, category, internalTimeslot), isPreviousTime, true, venue)
    },
    [availabilityByVenue, onSelectARTimeslot, previousSelectedTimeslot, hasChangedCriteriaFromLastSave]
  )

  return (
    <TimslotSection>
      <TimeslotRow repeat={searchVenues.length}>
        {shiftTimes.map(([venue, shift]) =>
          shift != null ? (
            <AvailabilityTimeSlot
              key={shift.shift_persistent_id}
              isCondensedFormat={searchVenues.length > 3}
              timeSlot={shift}
              venue={venue}
              isMultiVenue
              isSelected={isShiftSelected(shift)}
              onSelect={() => onSelectShiftTimeslot(shift, venue, venue.id !== selectedTimeslotVenueId)}
            />
          ) : (
            <div key={venue.id} />
          )
        )}
      </TimeslotRow>
      {[...Array(maxAR).keys()].map(i => (
        <TimeslotRow key={keys[i]} repeat={searchVenues.length} mt="xs">
          {searchVenues.map(venue => {
            const timeslotItem = availabilityARByVenueForTime[venue.id]?.[i]
            if (timeslotItem != null) {
              const { timeslot, category } = timeslotItem
              const item = getItemFromTimeslot(timeslot)
              return (
                <div style={{ margin: '3px 5px' }} key={item.id}>
                  <Timeslot
                    name={item.name}
                    selected={isARSelected(timeslot)}
                    category={timeslot.time}
                    onClick={() => onARSelect(timeslot, category, venue)}
                  />
                </div>
              )
            }
            return <div key={venue.id} />
          })}
        </TimeslotRow>
      ))}
    </TimslotSection>
  )
}

function TimeslotRow({ children, repeat, ...props }: PropsWithChildren<{ repeat: number; mt?: GridProps['mt'] }>) {
  return (
    <Grid
      gap="xs"
      gridTemplateColumns={`repeat(${repeat}, minmax(0, 1fr)) ${theme.timeslot.height}`}
      height={`${theme.timeslot.height}`}
      {...props}
    >
      {children}
    </Grid>
  )
}

function TimslotSection({ children }: PropsWithChildren<{}>) {
  return (
    <Grid pl="s" mb="xs" ml="s" gap={theme.timeslot.gap as ThemeSpacing}>
      {children}
    </Grid>
  )
}

export const MultiVenueAccessRulesTimeslots = connect(undefined, {
  changeType,
})(MultiVenueAccessRulesTimeslotsComponent)
