import { combineReducers } from 'redux'
import * as ActionTypes from 'mgr/pages/single-venue/reporting/actions/ActionTypes'

const initialState = {
  isLoading: false,
  isUploadingFile: false,
  hardAssignTables: false,
  ignoreLastUpdated: false,
}

const guestcenterImportReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GC_IMPORT_LOAD_ACTIVITY_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GC_IMPORT_LOAD_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.GC_IMPORT_LOAD_ACTIVITY_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.GC_IMPORT_UPLOAD_RESERVATIONS_START:
      return {
        ...state,
        isUploadingFile: true,
      }
    case ActionTypes.GC_IMPORT_UPLOAD_RESERVATIONS_SUCCESS:
      return {
        ...state,
        isUploadingFile: false,
      }
    case ActionTypes.GC_IMPORT_UPLOAD_RESERVATIONS_FAIL:
      return {
        ...state,
        isUploadingFile: false,
      }
    case ActionTypes.GC_IMPORT_CHANGE_HARD_ASSIGN_TABLES:
      return {
        ...state,
        hardAssignTables: action.value,
      }
    case ActionTypes.GC_IMPORT_CHANGE_IGNORE_LAST_UPDATED:
      return {
        ...state,
        ignoreLastUpdated: action.value,
      }
    default:
      return state
  }
}

export default guestcenterImportReducer
