import { useCallback } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components'
import type { VenueProfile, ExperiencesData } from '@sevenrooms/core/domain'
import { useFieldArray, useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Grid, Box, DividerLine, Flex, Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { defaultConfig } from '@sevenrooms/core/ui-kit/optional'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { FormEmailCampaignPreview, emailBuilderMessages, EmailEditor } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { emailCenterMessages } from '../../locales'
import type { EmailContentFormField } from './EmailContentForm.zod'

interface EmailContentProps {
  field: EmailContentFormField
  venueProfile: VenueProfile
  experiencesData: ExperiencesData
}

export function EmailContent({ field, venueProfile, experiencesData }: EmailContentProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { getValues } = useFormContext()

  const { fields: emailBodySectionsFieldArray } = useFieldArray(field, 'emailBodySections')

  const previewEditorConfig = getEditorConfig(getValues().emailPreview)
  const subjectEditorConfig = getEditorConfig(getValues().subjectLine)
  const emailSenderEditorConfig = getEditorConfig(getValues().emailSender)
  const signatureEditorConfig = getEditorConfig(getValues().signature)
  const signoffEditorConfig = getEditorConfig(getValues().signoff)
  const greetingEditorConfig = getEditorConfig(getValues().emailGreeting)
  const footerEditorConfig = getEditorConfig(getValues().footer)

  const renderEditor = useCallback(
    (editor: JSX.Element, isDisabled: boolean) => {
      if (isDisabled) {
        return (
          <Tooltip content={<div>{formatMessage(emailCenterMessages.sectionNotEditable)}</div>} displayAction="hover" alignment="left">
            {editor}
          </Tooltip>
        )
      }

      return editor
    },
    [formatMessage]
  )

  if (!venue) {
    return null
  }

  return (
    <BaseSection title={formatMessage(emailBuilderMessages.emailContentTitle)}>
      <Box p="lm">
        <Grid gridTemplateColumns="repeat(2, 448px)" gap="lm">
          <VStack spacing="m" pb="m" width="100%">
            <InputWrapper>
              <ContentSectionWrapper>
                <Label primary={formatMessage(emailBuilderMessages.senderVenue)} />
                <LimitedEditorWrapper disabled={emailSenderEditorConfig.disabled}>
                  {renderEditor(
                    <EmailEditor
                      field={field.prop('emailSender.value')}
                      isLimitedEditor
                      destination={routes.manager2.marketing.emailCenter.emails.emailBuilder}
                      config={emailSenderEditorConfig}
                      experiencesData={experiencesData}
                      venueProfile={venueProfile}
                      venue={venue}
                      data-test="emailSender"
                      disabled={emailSenderEditorConfig.disabled}
                    />,
                    emailSenderEditorConfig.disabled
                  )}
                </LimitedEditorWrapper>
              </ContentSectionWrapper>
            </InputWrapper>
            <InputWrapper>
              <ContentSectionWrapper>
                <Label primary={formatMessage(emailBuilderMessages.emailSubjectLineTitle)} />
                <LimitedEditorWrapper disabled={subjectEditorConfig.disabled}>
                  {renderEditor(
                    <EmailEditor
                      field={field.prop('subjectLine.value')}
                      isLimitedEditor
                      destination={routes.manager2.marketing.emailCenter.emails.emailBuilder}
                      config={subjectEditorConfig}
                      experiencesData={experiencesData}
                      venueProfile={venueProfile}
                      venue={venue}
                      data-test="subjectLine"
                      disabled={subjectEditorConfig.disabled}
                    />,
                    subjectEditorConfig.disabled
                  )}
                </LimitedEditorWrapper>
              </ContentSectionWrapper>
            </InputWrapper>
            <InputWrapper>
              <Label
                primary={formatMessage(emailBuilderMessages.emailPreviewTextTitle)}
                secondary={formatMessage(emailBuilderMessages.emailPreviewTextDescription)}
              >
                {previewEditorConfig.disabled ? (
                  <Tooltip
                    content={<div>{formatMessage(emailCenterMessages.sectionNotEditable)}</div>}
                    displayAction="hover"
                    alignment="left"
                  >
                    <FormInput field={field.prop('emailPreview.value')} autoComplete="none" disabled />
                  </Tooltip>
                ) : (
                  <FormInput field={field.prop('emailPreview.value')} autoComplete="none" />
                )}
              </Label>
            </InputWrapper>
          </VStack>
        </Grid>

        <DividerLine color="dividerLines" />

        <Grid gridTemplateColumns="repeat(2, 448px)" gap="lm">
          <VStack spacing="m" pt="l" alignItems="start">
            <InputWrapper>
              <ContentSectionWrapper>
                <Label primary={formatMessage(emailBuilderMessages.emailGreetingTitle)} />
                <EditorWrapper disabled={greetingEditorConfig.disabled}>
                  {renderEditor(
                    <EmailEditor
                      field={field.prop('emailGreeting.value')}
                      destination={routes.manager2.marketing.emailCenter.emails.emailBuilder}
                      config={greetingEditorConfig}
                      experiencesData={experiencesData}
                      venueProfile={venueProfile}
                      venue={venue}
                      data-test="emailGreeting"
                      disabled={greetingEditorConfig.disabled}
                    />,
                    greetingEditorConfig.disabled
                  )}
                </EditorWrapper>
              </ContentSectionWrapper>
            </InputWrapper>
            {emailBodySectionsFieldArray.map((emailBodyField, index) => {
              const emailBodySectionConfig = getEditorConfig({
                isEditable: getValues().emailBodySections[index]?.isEditable,
                maxLen: getValues().emailBodySections[index]?.maxLen ?? 0,
              })
              return (
                <InputWrapper key={emailBodyField.id}>
                  <ContentSectionWrapper>
                    <Flex justifyContent="space-between" mb="s">
                      <Text>
                        {formatMessage(emailBuilderMessages.emailBodySectionTitle)} {emailBodySectionsFieldArray.length > 1 && index + 1}
                      </Text>
                    </Flex>
                    <EditorWrapper disabled={emailBodySectionConfig.disabled}>
                      {renderEditor(
                        <EmailEditor
                          config={emailBodySectionConfig}
                          field={field.prop(`emailBodySections.${index}.value`)}
                          destination={routes.manager2.marketing.emailCenter.emails.emailBuilder}
                          experiencesData={experiencesData}
                          venueProfile={venueProfile}
                          venue={venue}
                          data-test={`emailBodySection-${index}`}
                          disabled={emailBodySectionConfig.disabled}
                        />,
                        emailBodySectionConfig.disabled
                      )}
                    </EditorWrapper>
                  </ContentSectionWrapper>
                </InputWrapper>
              )
            })}

            <InputWrapper>
              <ContentSectionWrapper>
                <Label primary={formatMessage(emailBuilderMessages.emailSignatureTitle)} />
                <EditorWrapper disabled={signatureEditorConfig.disabled}>
                  {renderEditor(
                    <EmailEditor
                      field={field.prop('signature.value')}
                      destination={routes.manager2.marketing.emailCenter.emails.emailBuilder}
                      config={signatureEditorConfig}
                      experiencesData={experiencesData}
                      venueProfile={venueProfile}
                      venue={venue}
                      data-test="signature"
                      disabled={signatureEditorConfig.disabled}
                    />,
                    signatureEditorConfig.disabled
                  )}
                </EditorWrapper>
              </ContentSectionWrapper>
            </InputWrapper>
            <InputWrapper>
              <ContentSectionWrapper>
                <Label primary={formatMessage(emailBuilderMessages.signoff)} />
                <EditorWrapper disabled={signoffEditorConfig.disabled}>
                  {renderEditor(
                    <EmailEditor
                      field={field.prop('signoff.value')}
                      destination={routes.manager2.marketing.emailCenter.emails.emailBuilder}
                      config={signoffEditorConfig}
                      experiencesData={experiencesData}
                      venueProfile={venueProfile}
                      venue={venue}
                      data-test="signoff"
                      disabled={signoffEditorConfig.disabled}
                    />,
                    signoffEditorConfig.disabled
                  )}
                </EditorWrapper>
              </ContentSectionWrapper>
            </InputWrapper>
            <InputWrapper>
              <ContentSectionWrapper>
                <Label primary={formatMessage(emailBuilderMessages.emailFooterTitle)} />
                <EditorWrapper disabled={footerEditorConfig.disabled}>
                  {renderEditor(
                    <EmailEditor
                      config={footerEditorConfig}
                      field={field.prop('footer.value')}
                      destination={routes.manager2.marketing.emailCenter.emails.emailBuilder}
                      experiencesData={experiencesData}
                      venueProfile={venueProfile}
                      venue={venue}
                      data-test="footer"
                      disabled={footerEditorConfig.disabled}
                    />,
                    footerEditorConfig.disabled
                  )}
                </EditorWrapper>
              </ContentSectionWrapper>
            </InputWrapper>
          </VStack>

          <VStack pt="l" spacing="m">
            <FormEmailCampaignPreview field={field} venue={venue} />
          </VStack>
        </Grid>
      </Box>
    </BaseSection>
  )
}

const getEditorConfig = ({ isEditable, maxLen }: { isEditable?: boolean; maxLen: number | null }) => ({
  ...defaultConfig,
  placeholderText: '',
  initOnClick: !isEditable,
  useClasses: true,
  width: 'auto',
  disabled: !isEditable,
  charCounterMax: maxLen ?? -1,
})

export interface WrapperProps extends React.DOMAttributes<HTMLElement> {
  disabled?: boolean
}

const EditorWrapper = styled.div<Omit<WrapperProps, 'as'>>`
  .fr-view {
    width: 100%;
    max-width: ${theme.sizes.fieldMaxWidth};
    padding: 12px 16px 12px 16px;
    ${props =>
      props.disabled &&
      css`
        background-color: ${theme.colors.secondaryBackground} !important;
        color: ${theme.colors.secondaryFont} !important;
      `}
    border-radius: 4px;
    box-sizing: border-box;
    border: ${`1px solid ${theme.colors.borders}`};
    max-height: 350px;
    overflow: auto;
  }
  p {
    margin-bottom: 0;
  }
  .fr-wrapper {
    border: 0 !important;
  }
  .fr-view table td,
  .fr-view table th {
    border: none;
  }
`

const LimitedEditorWrapper = styled.div<Omit<WrapperProps, 'as'>>`
  white-space: nowrap;

  .fr-view {
    width: 100%;
    max-width: ${theme.sizes.fieldMaxWidth};
    padding: 12px 16px 12px 16px;
    ${props =>
      props.disabled &&
      css`
        background-color: ${theme.colors.secondaryBackground} !important;
        color: ${theme.colors.secondaryFont} !important;
      `}
    border-radius: 4px;
    box-sizing: border-box;
    border: ${`1px solid ${theme.colors.borders}`};
    max-height: 350px;
    overflow: auto;
  }
  .fr-wrapper {
    border: 0 !important;
  }
  .fr-view::-webkit-scrollbar {
    display: none;
  }
`

const InputWrapper = styled.div`
  margin-bottom: 24px !important;
  width: 100%;
`

const ContentSectionWrapper = styled.div`
  font-family: ${theme.fonts.primary};
  font-size: ${theme.fontSizes.m};
  color: ${theme.colors.primaryFont};
`
