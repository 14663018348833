import flex from '@cybersource/flex-sdk-web'
import fetch from 'isomorphic-fetch'
import 'whatwg-fetch'
import money from 'money-math'
import { stripeCard } from 'svr/common/stripeCard'
import { AccountTypes } from 'svr/lib/Payments/Constants'
import { CardCodes } from 'svr/lib/Payments/CyberSource'
import { RSAEncrypt } from 'svr/lib/Payments/FreedomPay'
import { AESEncrypt, PipeFormat } from 'svr/lib/Payments/Network'
import { shift4TokenPromiseQueue } from 'svr/component-lib/Widget/Payments/Shift4/Shift4Form'
import { srGet, srPost } from '@sevenrooms/core/api'
// Compensates for our inconsistent server signals;
// They *should* be sending back response.error
// instead of response.payload.error, but fixing that
// will have to happen after the deprecation of old
// slideout and an audit.
const multiErrorHandler = (response, errHandler) => {
  if (!errHandler) {
    return null
  }

  const generalError = 'There was an error processing your request.'

  if (!response) {
    return generalError
  }
  if (response.errorMsg) {
    return response.errorMsg.payload.error
  }
  if (response.error) {
    return response.error
  }
  if (response.payload && response.payload.error) {
    return response.payload.error
  }
  if (response.payload && response.payload.message === 'Error') {
    return generalError
  }

  return null
}

const getCreditCardToken = (bookPaymentState, bookSettings, venue = null, commonPaymentState = null) => {
  switch (bookSettings.paymentSystem) {
    case AccountTypes.STRIPE:
      return getStripeToken(bookPaymentState, bookSettings, venue)
    case AccountTypes.FREEDOMPAY:
      return getFreedomPayToken(bookPaymentState)
    case AccountTypes.NETWORK:
      return getNetworkToken(bookPaymentState, bookSettings.paymentPublicToken)
    case AccountTypes.CYBERSOURCE_REDUX:
      return getCybersourceReduxToken(bookPaymentState, venue)
    case AccountTypes.CYBERSOURCE_3DS_REDUX:
      return getCybersourceReduxToken(bookPaymentState, venue)
    case AccountTypes.SAFERPAY:
      return getSaferpayToken(bookPaymentState)
    case AccountTypes.ADYEN:
      return getAdyenToken(bookPaymentState)
    case AccountTypes.SHIFT_4:
      return getShift4Token(commonPaymentState)
    default:
      return null
  }
}

const getAdyenToken = bookPaymentState => Promise.resolve({ data: bookPaymentState.cardData })

const getSaferpayToken = bookPaymentState => {
  if (bookPaymentState.cardToken) {
    return Promise.resolve({ token: bookPaymentState.cardToken })
  }
  return new Promise(resolve => {
    SaferpayFields.submit({
      onSuccess: evt => {
        resolve({ token: evt.token })
      },
      onError(evt) {
        // eslint-disable-next-line no-console
        console.log(evt)
        resolve({ token: null })
      },
    })
  })
}

const getNetworkToken = (bookPaymentState, publicToken) =>
  new Promise(resolve => {
    const aesEncryptor = new AESEncrypt(publicToken, '0123456789abcdef')
    const piper = new PipeFormat()

    const { cardHolderNumber, cardExpMonth, cardExpYear, cardCcv, cardHolderName, chargeTotal } = bookPaymentState
    const request = piper.buildRequest(cardHolderNumber, cardExpMonth, cardExpYear, cardCcv, cardHolderName, chargeTotal, 'SALE')
    const encryptedRequest = aesEncryptor.encrypt(request)

    resolve({ token: encryptedRequest })
  })

const getFreedomPayToken = bookPaymentState =>
  new Promise(resolve => {
    const encrypt = new RSAEncrypt(FREEDOM_PAY_PUBLIC_KEY)
    const encrypted = encrypt.freedomPayCard(
      bookPaymentState.cardHolderNumber,
      String(bookPaymentState.cardExpMonth),
      String(bookPaymentState.cardExpYear),
      bookPaymentState.cardCcv
    )
    resolve({ token: encrypted })
  })

const getCybersourceReduxToken = (bookPaymentState, venue) =>
  new Promise((resolve, reject) => {
    const url = `/booking/widget/${venue.id}/jwk`
    const { cardHolderNumber, cardExpYear, cardExpMonth } = bookPaymentState
    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(jwk => {
        const options = {
          kid: jwk.kid,
          keystore: jwk,
          encryptionType: 'rsaoaep256',
          cardInfo: {
            cardNumber: cardHolderNumber,
            cardType: CardCodes[stripeCard.cardType(cardHolderNumber).toUpperCase()],
            cardExpirationMonth: `0${cardExpMonth}`.substr(-2),
            cardExpirationYear: String(cardExpYear),
          },
          production: Pmp.Settings.IS_PRODUCTION,
        }
        flex.createToken(options, response => {
          if (response.error) {
            reject(response.error.message)
            return
          }
          // eslint-disable-next-line no-param-reassign
          response.expiryYear = String(cardExpYear)
          // eslint-disable-next-line no-param-reassign
          response.expiryMonth = String(cardExpMonth)
          resolve({ token: response.token, data: response })
        })
      })
      .catch(err => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('Please contact Support and check your merchant account credentials.')
      })
  })

const getShift4Token = commonPaymentState => {
  commonPaymentState.paymentEngine?.submit()

  return shift4TokenPromiseQueue.pop()
}

function getStripeSaveCardToken(venue, stripe, bookPaymentState, additionalData) {
  const url = `/booking/widget/${venue.id}/setup_intent`
  const formData = new FormData()
  formData.append('moto', true)

  return new Promise((resolve, reject) => {
    stripe
      .createPaymentMethod({
        type: 'card',
        card: bookPaymentState.stripeCardElement,
        billing_details: additionalData.billing_details,
      })
      .then(response => {
        const paymentMethodId = response?.paymentMethod?.id
        if (!paymentMethodId) {
          throw new Error('Cannot create payment method.')
        }

        return paymentMethodId
      })
      .then(pmId => {
        formData.append('payment_method', pmId)

        return fetch(url, {
          body: formData,
          method: 'POST',
          credentials: 'same-origin',
        })
      })
      .then(response => response.json())
      .then(response => {
        if (response.errors) {
          let messageStringFinal = ''
          response.errors.forEach(errorItem => {
            const messageString = errorItem.split(':')
            messageStringFinal += `* - ${messageString[1]}`
          })
          return { error: { message: messageStringFinal } }
        }

        return response
      })
      .then(response => {
        if (response.error) {
          reject(response.error.message)
        } else {
          resolve({ token: response.id })
        }
      })
  })
}

function getStripeChargeToken(venue, bookPaymentState, stripe, additionalData) {
  const url = `/booking/widget/${venue.id}/payment_intent`
  const formData = new FormData()
  formData.append('amount', bookPaymentState.chargeTotal)
  formData.append('source', 'SU5URVJOQUw=')
  formData.append('moto', true)

  return new Promise((resolve, reject) => {
    stripe
      .createPaymentMethod({
        type: 'card',
        card: bookPaymentState.stripeCardElement,
        billing_details: additionalData.billing_details,
      })
      .then(response => {
        const paymentMethodId = response?.paymentMethod?.id
        if (!paymentMethodId) {
          throw new Error('Cannot create payment method.')
        }

        return paymentMethodId
      })
      .then(pmId => {
        formData.append('payment_method', pmId)

        return fetch(url, {
          body: formData,
          method: 'POST',
          credentials: 'same-origin',
        })
      })
      .then(response => response.json())
      .then(response => {
        if (response.errors) {
          let messageStringFinal = ''
          response.errors.forEach(errorItem => {
            const messageString = errorItem.split(':')
            messageStringFinal += `* - ${messageString[1]}`
          })
          return { error: { message: messageStringFinal } }
        }

        return response
      })
      .then(response => {
        if (response.error) {
          reject(response.error.message)
        } else {
          resolve({ token: response.payload.id })
        }
      })
  })
}

// STRIPE
const getStripeToken = (bookPaymentState, bookSettings, venue) => {
  const stripe = bookPaymentState.stripeInstance
  const additionalData = {
    billing_details: bookPaymentState.cardHolderName,
  }

  if (bookPaymentState.takePaymentOrSave === 'save') {
    return getStripeSaveCardToken(venue, stripe, bookPaymentState, additionalData)
  }

  return getStripeChargeToken(venue, bookPaymentState, stripe, additionalData)
}

const fetchActualTransactions = (venueId, actualId, errHandler, cursor = null) =>
  srGet(
    `/manager/${venueId}/billing/entity_charges/${actualId}`,
    {
      rid: Math.floor(Math.random() * 100 + 1),
      cursor,
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    }
    const isResponseComplete = response && response.charges
    if (isResponseComplete) {
      return response
    }
  })

const sendOrderRefund = (venueId, clientId, chargeId, amount, reason, orderId, accountId, errHandler) =>
  srPost(
    `/api-yoa/payments/${venueId}/refund`,
    {
      venue_group_client_id: clientId,
      billing_history_id: chargeId,
      amount,
      reason,
      order_id: orderId,
      account: accountId,
    },
    { skipInterfaceError: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    } else {
      return response
    }
  })

const sendRefund = (venueId, clientId, actualId, chargeId, amount, reason, email, errHandler) =>
  srPost(
    `/manager/${venueId}/billing/${clientId}/refund`,
    {
      actual_id: actualId,
      charge_id: chargeId,
      amount,
      reason,
      notification_email: email,
    },
    { skipInterfaceError: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    } else {
      return response
    }
  })

const makeCharge = (
  venueId,
  clientId,
  actualId,
  cardId,
  token,
  data,
  cardHolderName,
  amount,
  baseAmount,
  tax,
  serviceCharge,
  gratuityCharge,
  notes,
  notificationEmail,
  errHandler,
  cardPhoneNumber,
  cardPhoneLocale
) =>
  srPost(
    `/manager/${venueId}/billing/${clientId}/charge`,
    {
      reservation_id: actualId,
      card_id: cardId || '',
      token: token || '',
      amount,
      base_amount: baseAmount,
      tax: tax || '',
      service_charge: serviceCharge && serviceCharge.replace ? money.floatToAmount(serviceCharge.replace(',', '.')) : serviceCharge,
      gratuity: gratuityCharge && gratuityCharge.replace ? money.floatToAmount(gratuityCharge.replace(',', '.')) : gratuityCharge,
      notes,
      cardholder_phone: cardPhoneNumber,
      cardholder_phone_locale: cardPhoneLocale,
      card_data: JSON.stringify({ ...data, card_holder_name: cardHolderName }),
      email: notificationEmail,
    },
    { skipInterfaceError: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    } else {
      return response
    }
  })

const saveCard = (venueId, actualId, name, email, token, errHandler, cardPhoneNumber, cardPhoneLocale, data = null) =>
  srPost(
    `/manager/${venueId}/billing/add_card_to_reservation`,
    {
      name,
      actual_id: actualId,
      token,
      email,
      card_data: JSON.stringify(data),
      cardholder_phone: cardPhoneNumber,
      cardholder_phone_locale: cardPhoneLocale,
    },
    { skipInterfaceError: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    } else {
      return response
    }
  })

const deleteCard = (venueId, actualId, cardId, errHandler) =>
  srPost(
    `/manager/${venueId}/billing/remove_card_from_reservation`,
    {
      actual_id: actualId,
      card_id: cardId,
    },
    { skipInterfaceError: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    } else {
      return response
    }
  })

const requestCard = (venueId, actualId, email, errHandler) =>
  srPost(
    `/manager/${venueId}/billing/request_card`,
    {
      actual_id: actualId,
      email,
    },
    { skipInterfaceError: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    } else {
      return response
    }
  })

const requestPayment = (
  venueId,
  actualId,
  email,
  description,
  amount,
  baseAmount,
  tax,
  serviceCharge,
  gratuityCharge,
  clientSelectGratuity,
  requireSelectGratuity,
  errHandler
) =>
  srPost(
    `/manager/${venueId}/billing/request_payment`,
    {
      actual_id: actualId,
      email,
      description,
      amount,
      base_amount: baseAmount,
      service_charge: serviceCharge && serviceCharge.replace ? money.floatToAmount(serviceCharge.replace(',', '.')) : serviceCharge,
      gratuity: gratuityCharge && gratuityCharge.replace ? money.floatToAmount(gratuityCharge.replace(',', '.')) : gratuityCharge,
      client_select_gratuity: clientSelectGratuity,
      require_select_gratuity: requireSelectGratuity,
      tax,
    },
    { skipInterfaceError: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    } else {
      return response
    }
  })

const deleteRequest = (venueId, historyId, { cancelActual, bookActual }, errHandler) =>
  srPost(
    `/manager/${venueId}/billing/remove_link`,
    {
      history_id: historyId,
      cancel_actual: cancelActual,
      book_actual: bookActual,
    },
    { skipInterfaceError: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    } else {
      return response
    }
  })

const sendNotification = (
  venueId,
  actualId,
  clientId,
  transactionId,
  historyId,
  email,
  amount,
  last4,
  brand,
  isRefund,
  isInfoRequest,
  orderId,
  errHandler
) =>
  srPost(
    `/manager/${venueId}/billing/notify`,
    {
      actual_id: actualId,
      client_id: clientId,
      transaction_id: transactionId,
      history_id: historyId,
      email,
      amount,
      brand,
      last4,
      is_refund: isRefund || '',
      is_info_request: isInfoRequest || '',
      order_id: orderId,
    },
    { skipInterfaceError: true }
    // eslint-disable-next-line consistent-return
  ).then(response => {
    const err = multiErrorHandler(response, errHandler)
    if (err) {
      errHandler(err)
    } else {
      return response
    }
  })

export default {
  getCreditCardToken,
  fetchActualTransactions,
  sendOrderRefund,
  sendRefund,
  makeCharge,
  saveCard,
  deleteCard,
  requestCard,
  requestPayment,
  sendNotification,
  deleteRequest,
}
