import React from 'react'
import styled from 'styled-components'
import { FlexColumnContainer, FlexRowContainer, FlexRowListContainer, ScrollableList } from 'mgr/actualslideout/components/view/ViewLayout'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import { AuthorBold, LogDevice, LogEntryText, LogEntryWrapper, LogTime, LogEntryMessageWrapper } from './MessageCommon'

const DayLabel = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.darkGrey};
  font-size: 11px !important;
  line-height: 26px;
  width: 100%;
  height: 26px;
  background-color: ${props => props.theme.lightGrey};
  font-family: ${props => props.theme.fontFamily};
`

const InverseIcon = styled.div`
  color: ${props => props.theme.darkGrey};
  border-radius: 14px;
  opacity: 0.5;
  margin-right: 3px;
`
const ActivityLogEntry = ({ logEntry }) => (
  <LogEntryMessageWrapper alignItems="flex-start" justifyContent="flex-start">
    <InverseIcon>
      <StyledVmsIconXS>{VmsIcons.Checked}</StyledVmsIconXS>
    </InverseIcon>
    <FlexColumnContainer>
      <FlexRowContainer>
        <AuthorBold>{logEntry.author_name}</AuthorBold>
        <LogDevice>{logEntry.device}</LogDevice>
        <LogTime>{logEntry.created_humanized}</LogTime>
      </FlexRowContainer>
      <LogEntryText>{logEntry.system_text}</LogEntryText>
    </FlexColumnContainer>
  </LogEntryMessageWrapper>
)

const DayDivider = ({ children }) => (
  <LogEntryWrapper>
    <DayLabel>{children}</DayLabel>
  </LogEntryWrapper>
)

const ActivityLogComponent = ({ logEntries }) => {
  const logEntryElements = logEntries.map(labelAndEntries => {
    const realLogEntries = labelAndEntries[1].map(entry => <ActivityLogEntry key={entry.id} logEntry={entry} />)
    const dayDivider = <DayDivider key={labelAndEntries[0]}>{labelAndEntries[0]}</DayDivider>
    realLogEntries.unshift(dayDivider)
    return realLogEntries
  })

  return <ScrollableList data-test="sr-activity-log">{logEntryElements}</ScrollableList>
}

export default ActivityLogComponent
