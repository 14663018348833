import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as SlideoutActions from 'mgr/actualslideout/actions/SlideoutActions'
import BookedByNames from 'mgr/actualslideout/components/view/BookedByNames'
import { venueScopedBookedByNames } from 'mgr/actualslideout/selectors/ViewSelectors'

const mapStateToProps = state => {
  const { actual, canEditReservation } = state.viewResState
  return {
    visibleActual: actual,
    selectedBookedByName: (actual || {}).bookedby_view,
    altBookedByNames: (actual || {}).bookedby_alt_view || [],
    canEditReservation,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      enterEditReservation: SlideoutActions.enterEditReservation,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookedByNames)
