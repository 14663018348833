import { connect } from 'react-redux'
import { deleteRequest, toggleRemovePaylinkModal } from 'mgr/actualslideout/actions/PaymentActions'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { PaylinkRemoveModalLocales } from './PaylinkRemoveModal.locales'

interface PaylinkRemoveModalProps {
  canOverridePayment: boolean
  paylinkRemoveModalTransactionId: string
  toggleRemovePaylinkModal: (value: string | null) => void
  deleteRequest: (id: string, props: { cancelActual: boolean | undefined; bookActual: boolean | undefined }) => void
}

function PaylinkRemoveModal({
  paylinkRemoveModalTransactionId,
  canOverridePayment,
  toggleRemovePaylinkModal,
  deleteRequest,
}: PaylinkRemoveModalProps) {
  const { formatMessage } = useLocales()
  const onClose = () => toggleRemovePaylinkModal(null)

  return (
    <Window active={!!paylinkRemoveModalTransactionId}>
      <Modal ariaLabel="Modal">
        <ModalHeader onClose={onClose}>
          <VStack spacing="s">
            <Status kind="warning">{formatMessage(commonMessages.warning)}</Status>
            <ModalTitle title={formatMessage(PaylinkRemoveModalLocales.title)} />
          </VStack>
        </ModalHeader>
        <ModalBody>
          <Text>{formatMessage(PaylinkRemoveModalLocales.body)}</Text>
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button variant="tertiary" onClick={onClose} data-test="sr-paylink-remove-modal-cancel">
              {formatMessage(PaylinkRemoveModalLocales.nevermind)}
            </Button>
            {canOverridePayment && (
              <Button
                variant="secondary"
                onClick={() => {
                  onClose()
                  deleteRequest(paylinkRemoveModalTransactionId, { bookActual: true, cancelActual: undefined })
                }}
                data-test="sr-paylink-remove-modal-delete-and-notify"
              >
                {formatMessage(PaylinkRemoveModalLocales.deleteAndBook)}
              </Button>
            )}
            <Button
              variant="primary"
              onClick={() => {
                onClose()
                deleteRequest(paylinkRemoveModalTransactionId, { bookActual: undefined, cancelActual: true })
              }}
              data-test="sr-paylink-remove-modal-delete"
            >
              {formatMessage(PaylinkRemoveModalLocales.deleteAndCancel)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}

interface State {
  // eslint-disable-next-line camelcase
  appState: { userDomain: { can_override_payment_requirement?: boolean } }
  bookPaymentState: { paylinkRemoveModalTransactionId: string }
}

const mapStateToProps = (state: State) => ({
  canOverridePayment: state.appState.userDomain.can_override_payment_requirement || false,
  paylinkRemoveModalTransactionId: state.bookPaymentState.paylinkRemoveModalTransactionId,
})

const mapDispatchToProps = {
  toggleRemovePaylinkModal,
  deleteRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaylinkRemoveModal)
