import React from 'react'
import styled from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { getStarColor } from 'mgr/lib/utils/Styles'
import { Icon } from '@sevenrooms/core/ui-kit/icons'

// TODO move the generic star component to component-lib
const StarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const SingleStarRowContainer = styled.div`
  display: flex;
  width: auto;
  margin-bottom: ${props => props.theme.gutter.standard};
  ${props => props.css};
`

const PercentagesContainer = styled.div`
  display: flex;
`

const StarsMainData = styled.div`
  font-size: ${props => props.fontSize};
  font-weight: bold;
  color: ${props => props.color};
`

const StarsLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: ${props => props.theme.gutter.double};
  color: ${props => props.theme.color.black};
`

const StarsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const OverallContainer = styled.div`
  display: flex;
`

const LabelContainer = styled.div`
  font-size: 14px;
  margin-right: ${props => props.theme.gutter.standard};
`

const SingleStarContainer = styled.div`
  width: 20px;
  color: ${props => props.color};
`
const ThumbContainer = styled.div`
  width: 90px;
`

// TODO swap out with icon
const SingleStar = ({ checked = false, color = 'black' }) => (
  <div>
    {checked ? (
      <SingleStarContainer color={color}>&#9733;</SingleStarContainer>
    ) : (
      <SingleStarContainer color={color}>&#9734;</SingleStarContainer>
    )}
  </div>
)

const ThumbIcon = ({ starsChecked = 0 }) => (
  <div>
    {[4, 5].includes(starsChecked) && (
      <ThumbContainer>
        Recommend <Icon name="VMSWeb-thumb-up" />
      </ThumbContainer>
    )}
    {[1, 2].includes(starsChecked) && (
      <ThumbContainer>
        Doesn’t recommend <Icon name="VMSWeb-thumb-down" />
      </ThumbContainer>
    )}
  </div>
)

export const SingleStarRow = ({ label = '', numStars = 0, starsChecked = 0, color = undefined, css = undefined }) => {
  const starColor = color || getStarColor(starsChecked)
  return (
    <SingleStarRowContainer css={css}>
      {label !== '' && <LabelContainer>{label}</LabelContainer>}
      {Array(numStars)
        .fill(0)
        .map((star, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <SingleStar color={starColor} key={idx} checked={starsChecked > idx} />
        ))}
    </SingleStarRowContainer>
  )
}

export const Thumb = ({ starsChecked = 0, css }) => (
  <SingleStarRowContainer css={css}>
    <ThumbIcon starsChecked={starsChecked} />
  </SingleStarRowContainer>
)

// TODO clean up color stuff
const ReviewStats = ({ label, mainData, starsList = null, fontSize = '14px', dynamic = false, color }) => {
  let mainColor

  if (dynamic) {
    if (parseFloat(mainData)) {
      mainColor = getStarColor(mainData)
    } else {
      mainColor = '#c2c6ca'
    }
  } else {
    mainColor = color
  }

  return (
    <StarsContainer>
      <StarsMainData color={mainColor} fontSize={fontSize}>
        {mainData}
      </StarsMainData>
      <StarsLabel>{label}</StarsLabel>
      {starsList && (
        <StarsListContainer>
          {starsList.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <SingleStarRow label={item.label} numStars={item.numStars} starsChecked={item.starsChecked} color={mainColor} key={idx} />
          ))}
        </StarsListContainer>
      )}
    </StarsContainer>
  )
}

// TODO make more dynamic
class StarsData extends React.PureComponent {
  isFacebookOnly(channels) {
    const channelNames = Object.keys(channels)
    return channelNames.length === 1 && channelNames.includes('facebook')
  }

  render() {
    const { overall, breakdown } = this.props
    const isFacebook = this.isFacebookOnly(breakdown.channels)

    return (
      <StarsContainer>
        <OverallContainer>
          <ReviewStats
            label="Overall Average"
            mainData={overall.average}
            dynamic
            fontSize="40px"
            starsList={
              !isFacebook && [
                {
                  label: overall.total,
                  numStars: 5,
                  starsChecked: Math.round(overall.average),
                },
              ]
            }
          />
        </OverallContainer>
        <PercentagesContainer>
          <ReviewStats
            label={isFacebook ? 'Recommend' : 'Positive'}
            mainData={`${breakdown.percentages.positive}%`}
            fontSize="30px"
            color={Theme.color.positive}
            starsList={
              !isFacebook && [
                {
                  label: breakdown.numStars['5'],
                  numStars: 5,
                  starsChecked: 5,
                },
                {
                  label: breakdown.numStars['4'],
                  numStars: 5,
                  starsChecked: 4,
                },
              ]
            }
          />
          {!isFacebook && (
            <ReviewStats
              label="Neutral"
              mainData={`${breakdown.percentages.neutral}%`}
              fontSize="30px"
              color={Theme.color.neutral}
              starsList={[
                {
                  label: breakdown.numStars['3'],
                  numStars: 5,
                  starsChecked: 3,
                },
              ]}
            />
          )}
          <ReviewStats
            label={isFacebook ? 'Doesn’t Recommend' : 'Negative'}
            mainData={`${breakdown.percentages.negative}%`}
            fontSize="30px"
            color={Theme.color.negative}
            starsList={
              !isFacebook && [
                {
                  label: breakdown.numStars['2'],
                  numStars: 5,
                  starsChecked: 2,
                },
                {
                  label: breakdown.numStars['1'],
                  numStars: 5,
                  starsChecked: 1,
                },
              ]
            }
          />
          {isFacebook && <StarsContainer />}
        </PercentagesContainer>
      </StarsContainer>
    )
  }
}

export default StarsData
