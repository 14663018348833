import _ from 'lodash'
import { getSaferpayInitialData, getAdyenInitialData } from 'mgr/actualslideout/services/PaymentServices'
import PaymentServices from 'mgr/lib/services/PaymentServices'
import { TransactionTypes, NotificationLevel } from 'mgr/lib/utils/Constants'
import { setClipboard } from 'mgr/lib/utils/UXUtils'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
import { SAFERPAY_SET_EMPTY_HOLDERNAME_STATUS } from './ActionTypes'
// eslint-disable-next-line import/no-cycle
import * as SlideoutActions from './SlideoutActions'
// eslint-disable-next-line import/no-cycle
import { refreshActual } from './ViewActualActions'

// Basic input actions
export const changeCardEntryOption = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CARD_ENTRY_OPTION,
  value,
})
export const changeCardHolderName = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CARD_HOLDER_NAME,
  value,
})
export const changeCardNumber = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CARD_HOLDER_NUMBER,
  value,
})
export const changeCardExpMonth = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CARD_EXP_MONTH,
  value,
})
export const changeCardExpYear = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CARD_EXP_YEAR,
  value,
})
export const changeCardCcv = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CARD_CCV,
  value,
})
export const changeTakePaymentOrSave = value => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.PAYMENT_CHANGE_TAKE_PAYMENT_OR_SAVE,
    value,
    accountId: state.bookState.selectedVenue.accountId,
    connectedSetupIntents: state.bookState.selectedVenue.connectedSetupIntents,
  })
}
export const changeChargeAmount = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CHARGE_AMOUNT,
  value,
})
export const changeChargeDescription = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CHARGE_DESCRIPTION,
  value,
})
export const changePaylinkAutoCancel = value => ({
  type: ActionTypes.PAYMENT_CHANGE_PAYLINK_AUTO_CANCEL,
  value,
})
export const changeChargeApplyTax = (value, taxGroups) => ({
  type: ActionTypes.PAYMENT_CHANGE_CHARGE_APPLY_TAX,
  value,
  taxGroups,
})
export const changeTaxGroupId = (value, taxGroups) => ({
  type: ActionTypes.PAYMENT_CHANGE_TAX_GROUP_ID,
  value,
  taxGroups,
})
export const changeApplyServiceCharge = value => ({
  type: ActionTypes.PAYMENT_CHANGE_APPLY_SERVICE_CHARGE,
  value,
})
export const changeServiceCharge = value => ({
  type: ActionTypes.PAYMENT_CHANGE_SERVICE_CHARGE,
  value,
})
export const changeApplyGratuityCharge = value => ({
  type: ActionTypes.PAYMENT_CHANGE_APPLY_GRATUITY_CHARGE,
  value,
})
export const changeGratuityCharge = value => ({
  type: ActionTypes.PAYMENT_CHANGE_GRATUITY_CHARGE,
  value,
})
export const changePaylinkGratuityTypes = value => ({
  type: ActionTypes.PAYMENT_CHANGE_PAYLINK_GRATUITY_TYPE,
  value,
})
export const changePaymentForm = value => ({
  type: ActionTypes.PAYMENT_CHANGE_PAYMENTS_FORM,
  value,
})
export const changeChargeSendNotification = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CHARGE_SEND_NOTIFICATION,
  value,
})
export const changeSelectCardOrNew = value => ({
  type: ActionTypes.PAYMENT_SELECT_CARD,
  value,
})
export const changeOverride = value => ({
  type: ActionTypes.PAYMENT_CHANGE_OVERRIDE,
  value,
})
export const changeUseGuestProfilePhoneNumber = value => ({
  type: ActionTypes.PAYMENT_CHANGE_USE_GUEST_PROFILE_PHONE_NUMBER,
  value,
})
export const changeCardPhoneNumber = value => ({
  type: ActionTypes.PAYMENT_CHANGE_PHONE_NUMBER,
  value,
})
export const changeCardCountryPhoneNumber = value => ({
  type: ActionTypes.PAYMENT_CHANGE_PHONE_COUNTRY,
  value,
})
export const changeRefundAmount = value => ({
  type: ActionTypes.REFUND_CHANGE_CHARGE_AMOUNT,
  value,
})
export const changeRefundType = value => ({
  type: ActionTypes.REFUND_CHANGE_TYPE,
  value,
})
export const changeRefundDescription = value => ({
  type: ActionTypes.REFUND_CHANGE_DESCRIPTION,
  value,
})
export const changeRefundNotification = value => ({
  type: ActionTypes.REFUND_TOGGLE_NOTIFICATION,
  value,
})
export const changeRefundGiftCard = value => ({
  type: ActionTypes.REFUND_TOGGLE_REFUND_GIFT_CARD,
  value,
})
export const changeNotificationEmail = value => ({
  type: ActionTypes.PAYMENT_CHANGE_CHARGE_NOTIFICATION_EMAIL,
  value,
})

export const chargeFormValidated = formErrors => ({
  type: ActionTypes.CHARGE_FORM_VALIDATED,
  formErrors,
})

export const changeUpgradesForm = (categories, isDirty, override) => ({
  type: ActionTypes.UPGRADES_CHANGE_FORM,
  categories,
  isDirty,
  override,
})

// View UI actions
export const changeChargeOrPaylink = value => ({
  type: ActionTypes.PAYMENT_SELECT_CHARGE_OR_PAYLINK,
  value,
})
export const toggleNotificationModal = (transaction, notificationType) => ({
  type: ActionTypes.PAYMENT_TOGGLE_MODAL_NOTIFICATION,
  transaction,
  notificationType,
})
export const togglePaymentDetail = value => ({
  type: ActionTypes.PAYMENT_TOGGLE_DETAIL,
  value,
})
export const toggleChargeModal = (value, canCharge, paylinkOnly) => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.PAYMENT_TOGGLE_MODAL_CHARGE,
    value,
    canCharge,
    paylinkOnly: paylinkOnly || !state.bookState.selectedVenue.bookSettings.isMotoEnabled,
    accountId: state.bookState.selectedVenue.accountId,
    connectedSetupIntents: state.bookState.selectedVenue.connectedSetupIntents,
  })
}
export const toggleChargeOnlyModal = card => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: ActionTypes.PAYMENT_TOGGLE_MODAL_CHARGE,
    card,
    restrict: 'chargeOnly',
    canCharge: true,
    accountId: state.bookState.selectedVenue.accountId,
    connectedSetupIntents: state.bookState.selectedVenue.connectedSetupIntents,
  })
}
export const setRefundId = transaction => ({
  type: ActionTypes.PAYMENT_SET_REFUND_ID,
  transaction,
})

// Processing actions
export const submitPaylink = value => ({
  type: ActionTypes.PAYMENT_PAYLINK_SUBMIT,
  value,
})
export const paylinkSuccess = value => ({
  type: ActionTypes.PAYMENT_PAYLINK_SUCCESS,
  value,
})
export const paylinkFail = value => ({
  type: ActionTypes.PAYMENT_PAYLINK_FAIL,
  value,
})

const getTransactionsBegin = () => ({
  type: ActionTypes.LOAD_TRANSACTIONS_START,
})
const getTransactionsSuccess = transactionResult => ({
  type: ActionTypes.LOAD_TRANSACTIONS_SUCCESS,
  transactionResult,
})
const getTransactionsFail = () => ({ type: ActionTypes.LOAD_TRANSACTIONS_FAIL })
const pushTransaction = transaction => ({
  type: ActionTypes.PUSH_PAYMENT_TRANSACTION,
  transaction,
})

export const tryGetTransactions =
  (venueId, actualId, ignoreCursor = false) =>
  (dispatch, getState) => {
    const state = getState()
    const cursor = ignoreCursor ? null : state.viewResState.transactionCursor

    dispatch(getTransactionsBegin())

    const errHandler = error =>
      dispatch(batchActions([getTransactionsFail(), SlideoutActions.showNotificationError(`Error getting transactions: ${error}`)]))

    return PaymentServices.fetchActualTransactions(venueId, actualId, errHandler, cursor).then(transactionResult =>
      dispatch(getTransactionsSuccess({ ...transactionResult, isFirstPage: cursor }))
    )
  }

const createTransaction = (data, type) => {
  switch (type) {
    case TransactionTypes.REQUEST_REFUND:
    case TransactionTypes.INTERNAL:
    case TransactionTypes.REFUND:
      const { displayData } = data
      return {
        transaction_type: type,
        transaction_id: data.charge_id,
        charged_formatted: displayData.charged_formatted,
        brand: displayData.brand,
        last_4: displayData.last_4,
        card_id: displayData.id,
        id: data.history_id,
        notes: displayData.notes,
        base_amount_formatted: displayData.base_amount_formatted,
        amount_formatted: displayData.amount_formatted,
        amount_decimal: displayData.amount_decimal,
        amount_remaining: data.amount,
        amount_remaining_decimal: displayData.amount_decimal,
        gratuity: displayData.gratuity,
        additional_fee: displayData.additional_fee,
        additional_fee_tax: displayData.additional_fee_tax,
        gratuity_formatted: displayData.gratuity_formatted,
        tax: displayData.tax,
        tax_decimal: displayData.tax_decimal,
        tax_formatted: displayData.tax_formatted,
        additional_fee_formatted: displayData.additional_fee_formatted,
        additional_fee_tax_formatted: displayData.additional_fee_tax_formatted,
        redemption_raw_json_data: displayData.redemption_raw_json_data,
        email: displayData.email,
      }
    case TransactionTypes.SAVED:
      if (!data.billing_history_id) {
        return {}
      }
      return {
        transaction_type: type,
        last_4: data.last_four,
        brand: data.card_type,
        charged_formatted: data.add_date,
        email: data?.email,
      }
    case TransactionTypes.REMOVED:
      return {
        transaction_type: type,
        last_4: data.resCardLast4,
        brand: data.resCardType,
        charged_formatted: data.delete_date,
      }
    case TransactionTypes.REQUEST:
      return {
        transaction_type: type,
        charged_formatted: data.charged_formatted,
        id: data.id,
        ndb_id: data.ndb_id,
        amount: data.amount_decimal,
        amount_formatted: data.amount_formatted,
        notes: data.notes,
        email: data.email,
      }
    case TransactionTypes.REQUEST_REMOVED:
      return {
        transaction_type: type,
        charged_formatted: data.delete_date,
      }
    default:
      return {}
  }
}

const refundBegin = () => ({ type: ActionTypes.PAYMENT_REFUND_START })
const refundSuccess = refundData => ({
  type: ActionTypes.PAYMENT_REFUND_SUCCESS,
  refundData,
})

const refundSuccessBanner = () =>
  SlideoutActions.showNotificationBanner({
    message: 'Refund successful',
    level: NotificationLevel.SUCCESS,
    visible: true,
  })

const refundFail = value => ({ type: ActionTypes.PAYMENT_REFUND_FAIL, value })

export const submitRefund = () => (dispatch, getState) => {
  dispatch(refundBegin())

  const state = getState()

  const venueId = state.bookState.selectedVenue.id
  const clientId = state.bookClientState.selectedClient.id
  const actualId = state.viewResState.actual.id

  const { refundingId, refundAmount, refundDescription, refundSendNotification } = state.bookPaymentState

  const transaction = _.filter(state.viewResState.transactions, t => t.id === refundingId)[0]

  const errHandler = error => {
    dispatch(
      batchActions([refundFail(), SlideoutActions.showNotificationError(error || 'Encountered an error while attempting to refund.')])
    )
  }

  return PaymentServices.sendRefund(
    venueId,
    clientId,
    actualId,
    transaction.transaction_id,
    refundAmount,
    refundDescription,
    state.bookPaymentState.notificationEmail,
    errHandler
  )
    .then(async result => {
      await dispatch(
        batchActions([
          refundSuccess(result.payload.refund_data),
          refundSuccessBanner(),
          pushTransaction(
            createTransaction(
              result.payload.refund_data,
              result.payload.refund_data.displayData.transaction_type || TransactionTypes.REFUND
            )
          ),
        ])
      )
      return result
    })
    .then(result => {
      if (!refundSendNotification) {
        return
      }
      dispatch(sendNotification(true, result.payload.refund_data.history_id))
    })
}

const addResCard = value => ({ type: ActionTypes.PAYMENT_ADD_RES_CARD, value })

const chargeBegin = () => ({ type: ActionTypes.PAYMENT_CHARGE_START })
const chargeSuccess = value => ({
  type: ActionTypes.PAYMENT_CHARGE_SUCCESS,
  value,
})
const chargeFail = value => ({ type: ActionTypes.PAYMENT_CHARGE_FAIL, value })
const chargeSuccessBanner = message =>
  SlideoutActions.showNotificationBanner({
    message,
    level: NotificationLevel.SUCCESS,
    visible: true,
  })

export const submitCharge = () =>
  function (dispatch, getState) {
    dispatch(chargeBegin())

    const state = getState()

    const {
      cardEntryOption,
      cardHolderName,
      chargeAmount,
      chargeTotal,
      chargeApplyTax,
      chargeTax,
      applyServiceCharge,
      serviceCharge,
      applyGratuityCharge,
      gratuityCharge,
      paylinkGratuityType,
      chargeDescription,
      chargeSendNotification,
      selectedCardId,
      takePaymentOrSave,
      notificationEmail,
      cardPhoneNumber,
      cardPhoneCountry,
      useGuestProfilePhoneNumber,
    } = state.bookPaymentState

    const venueId = state.bookState.selectedVenue.id
    const venue = state.bookState.selectedVenue
    const clientId = state.bookClientState.selectedClient.id
    const isEmailEditable = state.bookClientState.selectedClient.is_email_address_editable
    const actualId = state.viewResState.actual.id

    const deliveredEmail = isEmailEditable ? notificationEmail : ''
    const paylinkEmail = chargeSendNotification ? deliveredEmail : ''

    const errHandler = (error = 'Encountered an error while attempting to charge') => {
      dispatch(batchActions([chargeFail(), SlideoutActions.showNotificationError(error)]))
    }

    const submittedServiceCharge = applyServiceCharge ? serviceCharge : null
    const submittedGratuityCharge = applyGratuityCharge ? gratuityCharge : null
    const submittedTax = chargeApplyTax ? chargeTax : null
    const clientSelectGratuity = applyGratuityCharge && paylinkGratuityType !== 'gratuity_percentage'
    const requireSelectGratuity = applyGratuityCharge && paylinkGratuityType === 'require_client_select_charge'

    if (cardEntryOption === 'manual' && takePaymentOrSave === 'take') {
      return PaymentServices.getCreditCardToken(
        state.bookPaymentState,
        state.bookState.selectedVenue.bookSettings,
        state.bookState.selectedVenue,
        state.commonPayment
      )
        .then(
          response =>
            PaymentServices.makeCharge(
              venueId,
              clientId,
              actualId,
              null,
              response.token,
              response.data,
              cardHolderName,
              chargeTotal,
              chargeAmount,
              submittedTax,
              submittedServiceCharge,
              submittedGratuityCharge,
              chargeDescription,
              notificationEmail,
              errHandler,
              useGuestProfilePhoneNumber ? '' : cardPhoneNumber,
              cardPhoneCountry || venue.countryCode
            ),
          error => {
            errHandler(error)
          }
        )
        .then(
          result => {
            dispatch(
              batchActions([
                chargeSuccess(result),
                chargeSuccessBanner('Charge successful'),
                pushTransaction(
                  createTransaction(
                    {
                      ...result.payload.charge,
                      charge_id: result.payload.charge_id,
                    },
                    TransactionTypes.INTERNAL
                  )
                ),
              ])
            )
            return result
          },
          () => {}
        )
        .then(
          result => {
            if (!chargeSendNotification) {
              return
            }
            const chargeInfo = result.payload.charge
            PaymentServices.sendNotification(
              venueId,
              actualId,
              clientId,
              chargeInfo.charge_id,
              chargeInfo.history_id,
              deliveredEmail,
              chargeInfo.amount,
              chargeInfo.displayData.last_4,
              chargeInfo.displayData.brand,
              false,
              false,
              null,
              errHandler
            )
          },
          () => {}
        )
    } else if (cardEntryOption === 'manual' && takePaymentOrSave === 'save') {
      return PaymentServices.getCreditCardToken(
        state.bookPaymentState,
        state.bookState.selectedVenue.bookSettings,
        state.bookState.selectedVenue,
        state.commonPayment
      )
        .then(
          response =>
            PaymentServices.saveCard(
              venueId,
              actualId,
              cardHolderName,
              notificationEmail,
              response.token,
              errHandler,
              cardPhoneNumber,
              cardPhoneCountry || venue.countryCode,
              response.data
            ),

          error => {
            errHandler(error)
          },
          () => {}
        )
        .then(result =>
          dispatch(
            batchActions([
              chargeSuccess(result),
              addResCard(result.payload),
              chargeSuccessBanner('Card saved'),
              pushTransaction(createTransaction(result.payload, TransactionTypes.SAVED)),
            ])
          )
        )
    } else if (cardEntryOption === 'paylink' && takePaymentOrSave === 'take') {
      const paylinkGratuityCharge = clientSelectGratuity ? '' : submittedGratuityCharge
      return PaymentServices.requestPayment(
        venueId,
        actualId,
        paylinkEmail,
        chargeDescription,
        chargeTotal,
        chargeAmount,
        submittedTax,
        submittedServiceCharge,
        paylinkGratuityCharge,
        clientSelectGratuity,
        requireSelectGratuity,
        errHandler
      ).then(result =>
        dispatch(
          batchActions([
            chargeSuccess(result),
            chargeSuccessBanner('Payment request sent'),
            pushTransaction(createTransaction(result.payload.paylink_data, TransactionTypes.REQUEST)),
          ])
        )
      )
    } else if (cardEntryOption === 'paylink' && takePaymentOrSave === 'save') {
      return PaymentServices.requestCard(venueId, actualId, paylinkEmail, errHandler).then(result =>
        dispatch(
          batchActions([
            chargeSuccess(result),
            chargeSuccessBanner('Card request sent'),
            pushTransaction(createTransaction(result.payload.paylink_data, TransactionTypes.REQUEST)),
          ])
        )
      )
    } else if (selectedCardId) {
      return PaymentServices.makeCharge(
        venueId,
        clientId,
        actualId,
        selectedCardId,
        null,
        {},
        cardHolderName,
        chargeTotal,
        chargeAmount,
        submittedTax,
        submittedServiceCharge,
        submittedGratuityCharge,
        chargeDescription,
        notificationEmail,
        errHandler
      )
        .then(
          result => {
            dispatch(
              batchActions([
                chargeSuccess(result),
                chargeSuccessBanner('Charge successful'),
                pushTransaction(
                  createTransaction(
                    {
                      ...result.payload.charge,
                      charge_id: result.payload.charge_id,
                    },
                    TransactionTypes.INTERNAL
                  )
                ),
              ])
            )
            return result
          },
          () => {}
        )
        .then(
          result => {
            if (!chargeSendNotification) {
              return
            }
            const chargeInfo = result.payload.charge
            PaymentServices.sendNotification(
              venueId,
              actualId,
              clientId,
              chargeInfo.charge_id,
              chargeInfo.history_id,
              deliveredEmail,
              chargeInfo.amount,
              chargeInfo.displayData.last_4,
              chargeInfo.displayData.brand,
              false,
              false,
              null,
              errHandler
            )
          },
          () => {}
        )
    }
    errHandler()
    return null
  }

const deleteBegin = () => ({ type: ActionTypes.PAYMENT_DELETE_CARD_START })
const deleteSuccess = value => ({
  type: ActionTypes.PAYMENT_DELETE_CARD_SUCCESS,
  value,
})
const deleteFail = value => ({
  type: ActionTypes.PAYMENT_DELETE_CARD_FAIL,
  value,
})

const deleteSuccessBanner = () =>
  SlideoutActions.showNotificationBanner({
    message: 'Card deleted',
    level: NotificationLevel.SUCCESS,
    visible: true,
  })

const getDeletedCardData = (cardId, bookPaymentState) => {
  let deletedCardData = {}
  if (cardId === bookPaymentState.resCardId) {
    deletedCardData = {
      resCardId: bookPaymentState.resCardId,
      resCardLast4: bookPaymentState.resCardLast4,
      resCardType: bookPaymentState.resCardType,
    }
  }
  bookPaymentState.additionalReservationCards.forEach(reservationCard => {
    if (cardId === reservationCard.resCardId) {
      deletedCardData = {
        resCardId: reservationCard.resCardId,
        resCardLast4: reservationCard.resCardLast4,
        resCardType: reservationCard.resCardType,
      }
    }
  })
  return deletedCardData
}

export const deleteCard = cardId => (dispatch, getState) => {
  dispatch(deleteBegin())

  const state = getState()

  const venueId = state.bookState.selectedVenue.id
  const actualId = state.viewResState.actual.id

  const displayData = getDeletedCardData(cardId, state.bookPaymentState)

  const errHandler = error =>
    dispatch(batchActions([deleteFail(), SlideoutActions.showNotificationError('Encountered an error while attempting delete card')]))

  return PaymentServices.deleteCard(venueId, actualId, cardId, errHandler).then(result =>
    dispatch(
      batchActions([
        deleteSuccess(result.payload),
        deleteSuccessBanner(),
        pushTransaction(createTransaction({ ...displayData, ...result.payload }, TransactionTypes.REMOVED)),
      ])
    )
  )
}

const notificationBegin = () => ({
  type: ActionTypes.PAYMENT_NOTIFICATION_START,
})
const notificationSuccess = value => ({
  type: ActionTypes.PAYMENT_NOTIFICATION_SUCCESS,
  value,
})
const notificationFail = value => ({
  type: ActionTypes.PAYMENT_NOTIFICATION_FAIL,
  value,
})

const notificationSuccessBanner = () =>
  SlideoutActions.showNotificationBanner({
    message: 'Notification sent',
    level: NotificationLevel.SUCCESS,
    visible: true,
  })

export const showRefundFromWrongAccountNotification = () =>
  SlideoutActions.showNotificationBanner({
    message:
      'It looks like this charge was initiated from a different stripe account. If you wish to refund, please do so via your Stripe portal directly.',
    level: NotificationLevel.ERROR,
    visible: true,
  })

export const sendNotification = (isRefund, passHistoryId) => (dispatch, getState) => {
  dispatch(notificationBegin())

  const state = getState()

  const venueId = state.bookState.selectedVenue.id
  const clientId = state.bookClientState.selectedClient.id
  const isEmailEditable = state.bookClientState.selectedClient.is_email_address_editable
  const actualId = state.viewResState.actual.id
  const historyId = passHistoryId || state.bookPaymentState.notificationTransactionId
  const email = isEmailEditable ? state.bookPaymentState.notificationEmail : ''
  const isInfoRequest = state.bookPaymentState.notificationType === 'paylink'

  const transaction = _.filter(state.viewResState.transactions, t => t.id === historyId)[0]

  const { last_4, brand, amount, transaction_id } = transaction

  const errHandler = error =>
    dispatch(batchActions([notificationFail(), SlideoutActions.showNotificationError('Could not send notification')]))

  return PaymentServices.sendNotification(
    venueId,
    actualId,
    clientId,
    transaction_id,
    historyId,
    email,
    amount,
    last_4,
    brand,
    isRefund,
    isInfoRequest,
    null,
    errHandler
  ).then(result => dispatch(batchActions([notificationSuccess(result), notificationSuccessBanner()])))
}

const deleteRequestBegin = value => ({
  type: ActionTypes.PAYMENT_PAYLINK_DELETE_START,
  value,
})
const deleteRequestSuccess = value => ({
  type: ActionTypes.PAYMENT_PAYLINK_DELETE_SUCCESS,
  value,
})
const deleteRequestFail = value => ({
  type: ActionTypes.PAYMENT_PAYLINK_DELETE_FAIL,
  value,
})

const deleteRequestSuccessBanner = () =>
  SlideoutActions.showNotificationBanner({
    message: 'Paylink deleted',
    level: NotificationLevel.SUCCESS,
    visible: true,
  })

export const deleteRequest =
  (historyId, { cancelActual, bookActual } = {}) =>
  (dispatch, getState) => {
    dispatch(deleteRequestBegin(historyId))
    const state = getState()

    const venueId = state.bookState.selectedVenue.id
    const actualId = state.viewResState.actual.id

    const errHandler = error =>
      dispatch(batchActions([deleteRequestFail(), SlideoutActions.showNotificationError('Failed to delete request')]))

    return PaymentServices.deleteRequest(venueId, historyId, { cancelActual, bookActual }, errHandler)
      .then(result =>
        dispatch(
          batchActions([
            deleteRequestSuccess(historyId),
            deleteRequestSuccessBanner(),
            pushTransaction(createTransaction(result.payload, TransactionTypes.REQUEST_REMOVED)),
          ])
        )
      )
      .then(() => {
        Promise.all([dispatch(refreshActual())]).then(() => dispatch(tryGetTransactions(venueId, actualId, true)))
      })
  }

export const passStripeElement = element => ({
  type: ActionTypes.PASS_STRIPE_CARD_ELEMENT,
  element,
})

export const copyLink = link => {
  setClipboard(link)
  return SlideoutActions.showNotificationBanner({
    message: 'Link copied',
    level: NotificationLevel.SUCCESS,
    visible: true,
  })
}

export const setSaferpayInitialData = data => ({
  type: ActionTypes.SET_SAFERPAY_INITIAL_DATA,
  data,
})

export const fetchSaferpayInitialData = venueId => dispatch =>
  getSaferpayInitialData(venueId).then(result => dispatch(setSaferpayInitialData(result)))

export const setAdyenInitialData = data => ({
  type: ActionTypes.SET_ADYEN_INITIAL_DATA,
  data,
})

export const fetchAdyenInitialData = venueId => dispatch =>
  getAdyenInitialData(venueId).then(result => dispatch(setAdyenInitialData(result)))

export const saferpayOnValidate = event => dispatch => {
  dispatch({
    type: SAFERPAY_SET_EMPTY_HOLDERNAME_STATUS,
    isEmpty: event?.fieldType === 'holdername' && event?.reason === 'empty' && !event?.isValid,
  })
}

export const toggleRemovePaylinkModal = transactionId => ({
  type: ActionTypes.SHOW_REMOVE_PAYLINK_MODAL,
  transactionId,
})

export const restoreShiftCharges = shiftChargeDataAction => ({
  type: ActionTypes.RESTORE_SHIFT_CHARGES,
  ...shiftChargeDataAction,
})
