import { srGet, throwFetchError } from '@sevenrooms/core/api'

export const fetchSeatingAreaTables = (venueId, date, shiftPersistentId, includeLayout = false) => {
  const dateParam = date ? date.format('MM-DD-YYYY') : null
  const url = `/manager/${venueId}/data/seatingareastables`
  return srGet(
    url,
    {
      shift_persistent_id: shiftPersistentId,
      date: dateParam,
      include_layout: includeLayout ? 'y' : '',
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  )
    .then(throwFetchError)
    .then(response => convertSeatingAreasAndTables(response.payload.content))
}

const convertSeatingAreasAndTables = result => {
  const converted = {
    seatingAreaCodesToTables: result.new_seatingareacodes_to_tables,
    tableItems: result.all_table_inventory_items,
    tableCombos: result.table_combos,
    seatingAreas: result.venue_seating_areas,
    floorplanRooms: result.floorplan_rooms,
    tablePositionsByRoomId: result.room_id_to_table_positions,
    shapePositionsByRoomId: result.room_id_to_shape_positions,
    activeFloorplanLayoutConfig: result.active_floorplan_layout_config,
    floorplanLayoutConfigs: result.floorplan_layout_configs,
    seatingAreasByTableId: {},
    seatingAreasToTablesBySeatingAreaId: {},
    tableItemsByTableId: {},
    seatingAreasById: {},
    tableCombosById: {},
  }
  for (const seatingArea of result.venue_seating_areas) {
    converted.seatingAreasById[seatingArea.id] = seatingArea
  }
  for (const table of result.all_table_inventory_items) {
    converted.tableItemsByTableId[table.id] = table
  }
  for (const tableCombo of result.table_combos) {
    converted.tableCombosById[tableCombo.id] = tableCombo
  }
  for (const seatingArea of result.new_seatingareacodes_to_tables) {
    converted.seatingAreasToTablesBySeatingAreaId[seatingArea.id] = seatingArea
    for (const table of seatingArea.tables) {
      converted.seatingAreasByTableId[table.id] = seatingArea
    }
  }
  return converted
}

export default { fetchSeatingAreaTables }
