export interface Permission {
  label: string
  name: string
  subLabel?: string
  autoCheckBySelecting?: string[]
  autoUncheckByDeselecting?: string[]
  subPermissions?: Permission[]
  info?: string
}

export const BASIC_BOOKING_PERMISSIONS: Permission[] = [
  {
    label: 'Add/remove client tags from client profiles',
    name: 'f_tag_client_edit',
    subPermissions: [
      {
        label: 'Add/remove restricted client tags',
        name: 'f_tag_client_edit_restricted',
        autoCheckBySelecting: ['f_tag_client_edit'],
      },
    ],
  },
  {
    label: 'Book Guestlist',
    name: 'f_guestlist_access',
  },
  {
    label: 'Charge credit cards on reservations',
    name: 'f_can_charge',
  },
  {
    label: 'Refund credit cards on reservations',
    name: 'f_can_refund',
  },
  {
    label: 'View reservations booked by other users (read-only, without contact info)',
    name: 'f_all_reservations',
  },
  {
    label: 'Allow custom SMS messaging on Mobile App (preset messages always available)',
    name: 'f_send_custom_sms_on_mobile',
  },
  {
    label: 'Access Client Tab',
    name: 'f_access_client_tab',
    subPermissions: [
      {
        label: 'View clients associated with all venues in the Group',
        name: 'f_view_clients_across_venue_group',
        autoCheckBySelecting: ['f_access_client_tab'],
      },
      {
        label: 'Allow user to edit Loyalty settings',
        name: 'f_allow_edit_loyalty',
        autoCheckBySelecting: ['f_access_client_tab'],
      },
    ],
  },
  {
    label: 'Access Operations Tabs',
    name: 'f_access_operations_tab',
    subPermissions: [
      {
        label: 'Access Requests Tab',
        name: 'f_access_requests_tab',
        autoCheckBySelecting: ['f_access_operations_tab'],
      },
    ],
    autoUncheckByDeselecting: ['f_require_approval'],
  },
]

export const ADVANCED_BOOKING_PERMISSIONS: Permission[] = [
  {
    label: 'Add/Edit Blocks',
    name: 'f_can_edit_booking_block',
  },
  {
    label: 'Book on access rule table blocks',
    name: 'f_book_ot_rules',
  },
  {
    label: 'Overbook "no tables available"',
    name: 'f_book_no_tables',
  },
  {
    label: 'Overbook "only larger tables available"',
    name: 'f_book_larger_tables',
  },
  {
    label: 'Overbook "only smaller tables available"',
    name: 'f_book_smaller_tables',
  },
  {
    label: 'Overbook pacing limits',
    name: 'f_overbook_pacing',
  },
  {
    label: 'Overbook enforced shift party size limitations',
    name: 'f_overbook_enforced_shift_party_size',
  },
  {
    label: 'Override shift-required payment when booking',
    name: 'f_book_wo_payment',
  },
  {
    label: 'Override Maximum total covers for shift (Mobile only)',
    name: 'f_can_edit_maximum_total_covers_for_shift',
  },
  {
    label: 'Book outside of the shift internal booking limit',
    name: 'f_can_book_outside_of_the_shift_internal_booking_limit',
  },
  {
    label: "View all clients' contact info",
    name: 'f_view_all_contact_info',
  },
  {
    label: 'Private Notes',
    name: 'f_only_notes',
  },
]

export const ADDITIONAL_PERMISSIONS: Permission[] = [
  {
    label: 'Automatically make follower on all reservations',
    name: 'f_make_reservation_follower',
    autoCheckBySelecting: ['f_make_reservation_booker_follower'],
  },
  {
    label: 'Automatically make follower on reservations user creates',
    name: 'f_make_reservation_booker_follower',
  },
  {
    label: 'Require approval for new reservations',
    name: 'f_require_approval',
  },
  {
    label: 'Require manual input of Booked By on Mobile App',
    name: 'f_bookedby_manual_input_required',
  },
  {
    label: 'Auto-assign Requests',
    name: 'f_request_autoassign',
  },
  {
    label: 'Access Private Line',
    name: 'f_access_vip_chat',
  },
  {
    label: 'Write messages in Private Line',
    name: 'f_send_vip_chat',
  },
  {
    label: 'Access Revenue Management',
    name: 'f_access_insights_page',
  },
  {
    label: 'Access Private Events Tab',
    name: 'f_access_private_events_tab',
  },
  {
    label: 'Hide Administrative Actuals Report',
    name: 'f_hide_actuals_report',
  },
]

export const STANDARD_EMAIL_PERMISSIONS: Permission[] = [
  {
    label: 'Automated Feedback Summary',
    subLabel: 'Summary of past 24 hours feedback sent out automatically at scheduled times and beta weekly AI summary if applicable',
    name: 'receive_feedback_summary_email',
  },
  {
    label: 'Automated Reservation Summary',
    subLabel: 'Reservation summary sent out automatically at scheduled times',
    name: 'receive_daily_summary_email',
  },
  {
    label: 'External Reservation Alert',
    subLabel: 'Notifications for reservations booked by an external third party',
    name: 'receive_third_party_res_alert_email',
  },
  {
    label: 'Feedback Alert ',
    subLabel: 'Notification when new feedback comes in',
    name: 'receive_feedback_alert_email',
  },
  {
    label: "Notify me of check-in alerts for reservations that I'm following",
    subLabel: '',
    name: 'receive_follower_alert_email',
  },
  {
    label: "Notify me of new reservations I'm following",
    subLabel: '',
    name: 'receive_follower_new_res_alert_email',
  },
  {
    label: 'Internal Reservation Alert',
    subLabel: 'Notifications for reservations booked by your team',
    name: 'receive_internal_res_alert_email',
    subPermissions: [
      {
        label: 'Make user a follower on all request conversations',
        subLabel: '',
        name: 'f_make_request_follower',
      },
    ],
  },
  {
    label: 'Manual Reservation Summary',
    subLabel: 'Reservation summary sent out manually from web or on the iOS app',
    name: 'receive_ipad_summary_email',
  },
  {
    label: 'Membership Alerts',
    subLabel: 'Notification for expiring memberships',
    name: 'receive_membership_alerts_email',
  },
  {
    label: 'Mobile App Tally Results ',
    subLabel: 'Tally report sent out manually from iOS app',
    name: 'receive_ipad_tally_email',
  },
  {
    label: 'New Request Alert',
    subLabel: 'Notification when a brand new request comes in',
    name: 'receive_api_request_email',
  },
  {
    label: 'Notify user upon confirmation (applicable to concierge-only venues)',
    subLabel: '',
    name: 'receive_confirm_confirmation_email',
  },
  {
    label: 'Resolution Report',
    subLabel: 'List of reservations that need to be updated in third party system',
    name: 'receive_resolution_report_email',
  },
  {
    label: 'Revenue Management Summary',
    subLabel: 'Summary of all new Revenue Operation insights for your venue',
    name: 'receive_insights_summary_email',
  },
  {
    label: 'Special Occasions Summary',
    subLabel: 'Summary of all upcoming special occasions for your clients',
    name: 'receive_special_occasions_email',
  },
]

export const ADMINISTRATION_PERMISSIONS: Permission[] = [
  {
    label: 'Administrate Payment Gateway Integrations',
    name: 'f_payment_gateway_admin',
  },
  {
    label: 'Administrate General Settings',
    name: 'f_general_settings_admin',
  },
  {
    label: 'Administrate User Access',
    name: 'f_user_admin',
  },
  {
    label: 'Allow user to edit floorplan settings (Tables, Rooms, Layouts, Etc.)',
    name: 'f_has_floorplan_edit_permission',
  },
  {
    label: 'Manage client tags lists',
    name: 'f_client_tag_list_admin',
  },
  {
    label: 'Manage reservation tags lists',
    name: 'f_reservation_tag_list_admin',
  },
  {
    label: 'Manage Global Auto-tags',
    name: 'f_manage_global_auto_tags',
  },
  {
    label: 'Manage Room Invites',
    name: 'f_manage_room_invites',
  },
  {
    label: 'Venue Settings',
    name: 'f_venue_settings',
  },
  {
    label: 'Administrate Availability - Shifts and Access Rules',
    name: 'f_shifts_access_rules_admin',
  },
  {
    label: 'Administrate Availability - Blackout Dates and Daily Program',
    name: 'f_blackout_dates_daily_program_admin',
  },
  {
    label: 'Access Marketing Tools',
    name: 'f_access_marketing_tools',
    subPermissions: [
      {
        label: 'Create/Edit Email Campaigns with Global Tags',
        name: 'f_emails_with_global_tags',
        autoCheckBySelecting: ['f_access_marketing_tools'],
      },
    ],
  },
  {
    label: 'Administrate Widget Settings',
    name: 'f_widget_settings_admin',
  },
]

export const ADMINISTRATION_PERMISSIONS_AR_BULK_COPY_ENABLED: Permission[] = [
  ...ADMINISTRATION_PERMISSIONS,
  {
    label: 'Access Rule Bulk Copy',
    name: 'f_access_rule_bulk_copy',
  },
]

export const DATA_PERMISSIONS: Permission[] = [
  {
    label: 'View Reporting (Full)',
    name: 'f_view_reporting_full',
  },
  {
    label: 'View Individual Report',
    name: 'f_view_individual_reporting',
  },
  {
    label: 'Export Client Data',
    name: 'f_data_client_export',
  },
  {
    label: 'Export Actuals Data',
    name: 'f_data_actuals_export',
  },
  {
    label: 'Export User Access Data',
    name: 'f_data_user_export',
  },
]

export const ADVANCED_BOOKING_PERMISSIONS_FOR_IT_USER_ADMINISTRATOR: Permission[] = (() => {
  const advancedPermissionsForNonUserManagers = [...ADVANCED_BOOKING_PERMISSIONS]
  return advancedPermissionsForNonUserManagers.filter(
    permission => permission.name !== 'f_can_book_outside_of_the_shift_internal_booking_limit'
  )
})()

export const ADVANCED_BOOKING_PERMISSIONS_FOR_PROMOTERS: Permission[] = (() => {
  const advancedPermissionsForPromoters = [...ADVANCED_BOOKING_PERMISSIONS_FOR_IT_USER_ADMINISTRATOR]
  advancedPermissionsForPromoters.push({
    label: 'Book outside of the shift internal booking limit',
    name: 'f_can_book_outside_of_the_shift_internal_booking_limit',
  })
  return advancedPermissionsForPromoters
})()

export const DATA_PERMISSIONS_FOR_NON_USER_MANAGERS: Permission[] = (() => {
  const dataPermissionsForNonUserManagers = [...DATA_PERMISSIONS]
  dataPermissionsForNonUserManagers.splice(
    2,
    0,
    {
      label: 'View Client Spend Aggregates Data',
      name: 'f_view_client_spend_data',
    },
    {
      label: 'View POS Check Data',
      name: 'f_view_pos_check_data',
    },
    {
      label: 'View third party client database search',
      name: 'f_3p_client_db_search',
    }
  )
  return dataPermissionsForNonUserManagers
})()

export const DEPENDED_PERMISSIONS = {
  f_tag_client_edit_restricted: 'f_tag_client_edit',
  f_access_requests_tab: 'f_access_operations_tab',
  f_emails_with_global_tags: 'f_access_marketing_tools',
  f_view_clients_across_venue_group: 'f_access_client_tab',
  f_allow_edit_loyalty: 'f_allow_edit_loyalty',
}

export const DEFAULT_PERMISSIONS_PER_PRIVILEGE_LEVEL = {
  SUPERUSER: {
    f_general_settings_admin: true,
    f_access_rule_bulk_copy: true,
    f_view_client_spend_data: true,
    f_view_pos_check_data: true,
    f_access_client_tab: true,
    f_view_clients_across_venue_group: true,
    f_allow_edit_loyalty: true,
    f_access_marketing_tools: true,
    f_access_private_events_tab: true,
    f_emails_with_global_tags: true,
    f_access_operations_tab: true,
    f_access_requests_tab: true,
    f_widget_settings_admin: true,
    f_shifts_access_rules_admin: true,
    f_blackout_dates_daily_program_admin: true,
    f_can_charge: true,
    f_can_refund: true,
    f_overbook_enforced_shift_party_size: true,
    f_can_edit_maximum_total_covers_for_shift: true,
    f_can_book_outside_of_the_shift_internal_booking_limit: true,
    f_access_vip_chat: true,
    f_send_vip_chat: true,
    f_manage_global_auto_tags: true,
    f_only_notes: true,
  },
  MANAGER: {
    f_general_settings_admin: true,
    f_payment_gateway_admin: true,
    f_send_custom_sms_on_mobile: true,
    f_view_client_spend_data: true,
    f_view_pos_check_data: true,
    f_view_reporting_full: true,
    f_reservation_tag_list_admin: true,
    f_manage_global_auto_tags: true,
    f_client_tag_list_admin: true,
    f_access_client_tab: true,
    f_view_clients_across_venue_group: true,
    f_allow_edit_loyalty: false,
    f_access_marketing_tools: true,
    f_access_private_events_tab: true,
    f_emails_with_global_tags: true,
    f_access_operations_tab: true,
    f_access_requests_tab: true,
    f_widget_settings_admin: true,
    f_shifts_access_rules_admin: true,
    f_blackout_dates_daily_program_admin: true,
    f_overbook_enforced_shift_party_size: true,
    f_can_edit_maximum_total_covers_for_shift: true,
    f_can_book_outside_of_the_shift_internal_booking_limit: false,
    f_only_notes: false,
  },
  SUBMANAGER: {
    f_general_settings_admin: false,
    f_send_custom_sms_on_mobile: true,
    f_view_client_spend_data: true,
    f_view_pos_check_data: true,
    f_view_reporting_full: false,
    f_reservation_tag_list_admin: false,
    f_manage_global_auto_tags: false,
    f_client_tag_list_admin: false,
    f_access_client_tab: true,
    f_view_clients_across_venue_group: true,
    f_allow_edit_loyalty: false,
    f_access_marketing_tools: false,
    f_access_private_events_tab: true,
    f_emails_with_global_tags: false,
    f_access_operations_tab: true,
    f_access_requests_tab: true,
    f_widget_settings_admin: false,
    f_shifts_access_rules_admin: false,
    f_blackout_dates_daily_program_admin: false,
    f_overbook_enforced_shift_party_size: false,
    f_can_edit_maximum_total_covers_for_shift: false,
    f_can_book_outside_of_the_shift_internal_booking_limit: false,
    f_only_notes: false,
  },
  BASIC: {
    f_general_settings_admin: false,
    f_send_custom_sms_on_mobile: true,
    f_view_client_spend_data: true,
    f_view_pos_check_data: true,
    f_view_reporting_full: false,
    f_access_client_tab: true,
    f_view_clients_across_venue_group: true,
    f_allow_edit_loyalty: false,
    f_access_marketing_tools: false,
    f_access_private_events_tab: false,
    f_emails_with_global_tags: false,
    f_access_operations_tab: true,
    f_access_requests_tab: true,
    f_widget_settings_admin: false,
    f_shifts_access_rules_admin: false,
    f_blackout_dates_daily_program_admin: false,
    f_overbook_enforced_shift_party_size: false,
    f_can_edit_maximum_total_covers_for_shift: false,
    f_can_book_outside_of_the_shift_internal_booking_limit: false,
    f_make_reservation_booker_follower: false,
    f_only_notes: false,
  },
  ACTUALS: {
    f_general_settings_admin: false,
    f_view_client_spend_data: true,
    f_view_pos_check_data: true,
    f_view_reporting_full: false,
    f_access_client_tab: true,
    f_view_clients_across_venue_group: true,
    f_allow_edit_loyalty: false,
    f_access_operations_tab: true,
    f_access_requests_tab: true,
    f_widget_settings_admin: false,
    f_overbook_enforced_shift_party_size: false,
    f_only_notes: false,
  },
  FLOORPLANEDITOR: {},
  USERMANAGER: {
    f_general_settings_admin: false,
    f_view_client_spend_data: false,
    f_view_pos_check_data: false,
    f_view_reporting_full: false,
    f_access_client_tab: false,
    f_view_clients_across_venue_group: false,
    f_allow_edit_loyalty: false,
    f_access_marketing_tools: false,
    f_access_private_events_tab: false,
    f_emails_with_global_tags: false,
    f_access_operations_tab: false,
    f_access_requests_tab: false,
    f_widget_settings_admin: false,
    f_shifts_access_rules_admin: false,
    f_blackout_dates_daily_program_admin: false,
    f_overbook_enforced_shift_party_size: false,
    f_can_edit_maximum_total_covers_for_shift: false,
    f_make_reservation_booker_follower: false,
  },
  NO_LOGIN: {},
}
