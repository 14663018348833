import { useMemo, useCallback, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { LoadingSpinner } from 'mgr/layout/StyledComponentUtils'
import {
  addMultiVenueAvailabilityTimeslot,
  type AvailabilityTimeslot,
  type MixedAvailabilityTimeslot,
  type TimeslotsByType,
} from '@sevenrooms/core/api'
import { type MultiVenueAvailabilityTimeslotRequest, TimeslotDefaults } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import {
  type TimeslotItemsRow,
  TimeslotsGrid,
  type SelectedItem,
  type TimeslotItem,
  type TimeslotColumn,
} from '@sevenrooms/core/ui-kit/vx-layout'
import { changeType } from '../../actions/BookAvailabilityActions'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { AvailabilityLocales } from './Availability.locales'
import { initAvailabilityTimeslotFromAccessRule, mixTimeslot } from './createARTimeslotHelper'
import { getShiftTimeslots } from './helpers'
import { useGetInternalBookingExperiencesQuery } from './useGetInternalBookingExperiencesQuery'
import { useGetItemFromTimeslot } from './useGetItemFromTimeslot'
import {
  useMultiVenueAvailabilityQueryParamsSelector,
  useMultiVenueAvailabilityTimeslotsRequest,
} from './useMultiVenueAvailabilityTimeslotsRequest'

export interface AccessRulesTimeslotsProps {
  singleVenueId: string
  timeSlotRange: number[]
  selectedTimeslot?: MixedAvailabilityTimeslot
  previousSelectedTimeslot?: MixedAvailabilityTimeslot
  hasChangedCriteriaFromLastSave: boolean
  onChange: (timeslot: MixedAvailabilityTimeslot, isPreviousTime: boolean, isDirectlySelected: boolean) => void
  changeType: (typeFilter: string) => void
}

interface SortOrderTime {
  order: number
  time: string
}

function AccessRulesTimeslotsComponent({
  singleVenueId,
  timeSlotRange,
  selectedTimeslot,
  previousSelectedTimeslot,
  hasChangedCriteriaFromLastSave,
  onChange,
  changeType,
}: AccessRulesTimeslotsProps) {
  const { formatMessage } = useLocales()
  const dispatch = useDispatch()
  const getItemFromTimeslot = useGetItemFromTimeslot()
  const availabilityTimeslotsRequestParams = useMultiVenueAvailabilityQueryParamsSelector()
  const { typeFilter, availabilityByVenue, shiftPersistentId, previousSelectedTimeSlot } = useStoreSelector(
    state => state.bookAvailabilityState
  )
  const { availabilityChargeData } = useStoreSelector(state => state.bookPaymentState)
  const { availabilityARByVenue, isFetching } = useMultiVenueAvailabilityTimeslotsRequest()

  const { allExperiences: experiences, isFetching: isExperiencesFetching, selectedExperienceName } = useGetInternalBookingExperiencesQuery()

  const { timeslotsByType, shiftTimeslots, category, gridItems } = useMemo(() => {
    const availability = (availabilityARByVenue ?? {})[singleVenueId ?? -1]
    const { timeslots: shiftTimeslots, category } = getShiftTimeslots(availability, shiftPersistentId)

    const timeslotsByType: TimeslotsByType = {}
    shiftTimeslots?.forEach(timeslot => {
      const item = getItemFromTimeslot(timeslot)
      const itemName = item.name || formatMessage(AvailabilityLocales.noTimeslotDescriptionTypeName)
      if (timeslotsByType[itemName]) {
        timeslotsByType[itemName]?.push(timeslot)
      } else {
        timeslotsByType[itemName] = [timeslot]
      }
    })
    let columns: TimeslotColumn[] = []
    let times: SortOrderTime[] = []
    let gridItems: TimeslotItemsRow[] = []
    if (timeslotsByType && !isExperiencesFetching && availabilityARByVenue && Object.keys(availabilityARByVenue).length) {
      const selectedTypeOption = selectedExperienceName || typeFilter
      if (selectedTypeOption && selectedTypeOption !== TimeslotDefaults.ALL) {
        const experienceNames = new Set<string>([selectedTypeOption])
        const selectedExperiences = experiences.filter(experience => experience.name === selectedTypeOption)
        if (selectedExperiences.length > 0) {
          shiftTimeslots?.forEach(timeslot => {
            if (selectedExperiences.findIndex(exp => exp.id === timeslot.experience_id) !== -1) {
              const item = getItemFromTimeslot(timeslot)
              experienceNames.add(item.name || formatMessage(AvailabilityLocales.noTimeslotDescriptionTypeName))
            }
          })
        }
        experienceNames.forEach((experienceName: string) => {
          if (experienceName in timeslotsByType) {
            columns.push(
              getColumn(
                timeslotsByType[experienceName] ?? [],
                experienceName,
                hasChangedCriteriaFromLastSave,
                getItemFromTimeslot,
                selectedTimeslot
              )
            )
          }
        })
        if (columns.length === 0) {
          changeType(TimeslotDefaults.ALL)
        }
      } else {
        columns = Object.entries(timeslotsByType).map(([type, timeslots]) =>
          getColumn(timeslots, type, hasChangedCriteriaFromLastSave, getItemFromTimeslot, selectedTimeslot)
        )
      }
      times = Object.values(timeslotsByType)
        .flat()
        .filter((timeslot, index, timeslots) => timeslots.findIndex(t => t.time === timeslot.time) === index)
        .map(timeslot => ({ order: timeslot.sort_order, time: timeslot.time ?? '' }))
      if (selectedTimeslot && !hasChangedCriteriaFromLastSave) {
        times.push({ order: selectedTimeslot.sort_order, time: selectedTimeslot.time })
      }
    }
    if (columns.length !== 0) {
      gridItems = buildGrid(columns, timeSlotRange, times)
    }
    return { timeslotsByType, shiftTimeslots, category, gridItems }
  }, [
    availabilityARByVenue,
    singleVenueId,
    shiftPersistentId,
    isExperiencesFetching,
    getItemFromTimeslot,
    formatMessage,
    selectedExperienceName,
    typeFilter,
    selectedTimeslot,
    hasChangedCriteriaFromLastSave,
    experiences,
    changeType,
    timeSlotRange,
  ])

  useEffect(() => {
    if (!category || !shiftTimeslots || !previousSelectedTimeSlot) {
      return
    }
    const foundTimeslot = shiftTimeslots?.find(
      t =>
        t.access_persistent_id === previousSelectedTimeSlot?.access_persistent_id && t.sort_order === previousSelectedTimeSlot?.sort_order
    )
    const internalTimeslot = availabilityByVenue[singleVenueId]?.availableTimes?.find(
      t => t.sort_order === previousSelectedTimeSlot.sort_order
    )
    if (
      !foundTimeslot &&
      internalTimeslot &&
      previousSelectedTimeSlot.access_persistent_id &&
      !previousSelectedTimeSlot.real_datetime_of_slot &&
      internalTimeslot.status === 'available'
    ) {
      const initedTimeslot = initAvailabilityTimeslotFromAccessRule(
        previousSelectedTimeSlot,
        internalTimeslot,
        availabilityChargeData ?? undefined
      )
      dispatch(
        addMultiVenueAvailabilityTimeslot(
          availabilityTimeslotsRequestParams as MultiVenueAvailabilityTimeslotRequest,
          singleVenueId,
          initedTimeslot
        )
      )
    }
  }, [
    shiftTimeslots,
    availabilityTimeslotsRequestParams,
    singleVenueId,
    previousSelectedTimeSlot,
    dispatch,
    category,
    availabilityChargeData,
    availabilityByVenue,
  ])

  const selectedItem = useMemo(
    () =>
      selectedTimeslot?.access_persistent_id
        ? { categoryId: selectedTimeslot.sort_order, item: getItemFromTimeslot(selectedTimeslot) }
        : undefined,
    [getItemFromTimeslot, selectedTimeslot]
  )

  const onSelect = useCallback(
    (selectedItem: SelectedItem) => {
      const selectedItemName = selectedItem.item.name || formatMessage(AvailabilityLocales.noTimeslotDescriptionTypeName)
      const selectedTimeslot = timeslotsByType?.[selectedItemName]?.find(
        timeslot => timeslot.sort_order === selectedItem.categoryId && getItemFromTimeslot(timeslot).id === selectedItem.item.id
      )
      if (selectedTimeslot && category) {
        const isPreviousTime =
          !!previousSelectedTimeslot &&
          selectedTimeslot.real_datetime_of_slot === previousSelectedTimeslot.real_datetime_of_slot &&
          selectedTimeslot.access_persistent_id === previousSelectedTimeslot.access_persistent_id &&
          !hasChangedCriteriaFromLastSave

        const availableTimes = availabilityByVenue[singleVenueId]?.availableTimes
        const internalTimeslot = availableTimes?.find(t => t.sort_order === selectedTimeslot?.sort_order)

        onChange(mixTimeslot(selectedTimeslot, category, internalTimeslot), isPreviousTime, true)
      }
    },
    [
      timeslotsByType,
      category,
      previousSelectedTimeslot,
      availabilityByVenue,
      singleVenueId,
      onChange,
      hasChangedCriteriaFromLastSave,
      formatMessage,
      getItemFromTimeslot,
    ]
  )

  return isFetching ? <LoadingSpinner /> : <TimeslotsGrid items={gridItems} selected={selectedItem} onSelect={onSelect} />
}

function getColumn(
  timeslots: AvailabilityTimeslot[],
  typeFilter: string,
  hasChangedCriteriaFromLastSave: boolean,
  getItemFromTimeslot: (timeslot: AvailabilityTimeslot) => TimeslotItem,
  selectedTimeslot?: MixedAvailabilityTimeslot
) {
  let items = timeslots.map(timeslot => getItemFromTimeslot(timeslot))
  if (selectedTimeslot && !hasChangedCriteriaFromLastSave) {
    const item = getItemFromTimeslot(selectedTimeslot)
    if (item.id === typeFilter && !items.find(existingItem => existingItem.sortOrder === item.sortOrder)) {
      items = [...items, item]
      items.sort((a, b) => (a?.sortOrder ?? 0) - (b?.sortOrder ?? 0))
    }
  }
  const firstIndex = items[0] ? items[0]?.sortOrder : 0
  const lastIndex = items[items.length - 1] ? items[items.length - 1]?.sortOrder : 0
  return { firstIndex: firstIndex ?? 0, lastIndex: lastIndex ?? 0, items, name: typeFilter }
}

function buildGrid(columns: TimeslotColumn[], timeSlotRange: number[], times: SortOrderTime[]) {
  // Combine columns that don't have overlapping timeslots
  const packedColumns: TimeslotColumn[][] = []
  columns.forEach(column => {
    let found = false
    packedColumns.forEach(packedColumn => {
      if (!found && !packedColumn.some(existing => column.firstIndex <= existing.lastIndex && column.lastIndex >= existing.firstIndex)) {
        packedColumn.push(column)
        found = true
      }
    })
    if (!found) {
      packedColumns.push([column])
    }
  })

  // Flatten the combined columns and fill in missing timeslots
  const filledColumns = packedColumns.map(packedColumn => {
    const existingItems = packedColumn.reduce((acc, column) => acc.concat(column.items), [] as TimeslotItem[])
    return timeSlotRange.map(order => {
      const item = existingItems.find(item => item.sortOrder === order)
      return item ?? { id: order, name: null }
    })
  })

  // Swap the columns <-> rows and tie each row to a sort order and label
  const transposed = filledColumns[0]?.map((_, i) => filledColumns.map(row => row[i])) ?? []
  const test = transposed.map((items, i) => {
    const sortOrder = timeSlotRange[i] ?? 0
    return { id: sortOrder, name: times.find(time => time.order === sortOrder)?.time ?? '', items } as TimeslotItemsRow
  })
  return test
}

export const AccessRulesTimeslots = connect(undefined, {
  changeType,
})(AccessRulesTimeslotsComponent)
