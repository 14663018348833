import React from 'react'
import styled from 'styled-components'
import {
  StyledVmsIconXXS,
  StyledVmsIconXS,
  StyledVmsIconS,
  StyledVmsIconM,
  StyledVmsIconL,
  StyledVmsBadgeIconXXS,
  StyledVmsBadgeIconXS,
  StyledVmsBadgeIconS,
  StyledVmsBadgeIconM,
  StyledVmsBadgeIconL,
  StyledVmsBadgeIcon,
  StyledVmsIconBase,
} from 'svr/common/VmsIcons'

const BadgedIconComponentFactory =
  (IconClass, BadgeClass) =>
  ({ badgeIcon, displayIcon }) =>
    (
      <IconClass>
        {displayIcon}
        <BadgeClass>{badgeIcon}</BadgeClass>
      </IconClass>
    )

export const BadgedVmsIconXXS = BadgedIconComponentFactory(StyledVmsIconXXS, StyledVmsBadgeIconXXS)

export const BadgedVmsIconXS = BadgedIconComponentFactory(StyledVmsIconXS, StyledVmsBadgeIconXS)

export const BadgedVmsIconS = BadgedIconComponentFactory(StyledVmsIconS, StyledVmsBadgeIconS)

export const BadgedVmsIconM = BadgedIconComponentFactory(StyledVmsIconM, StyledVmsBadgeIconM)

export const BadgedVmsIconL = BadgedIconComponentFactory(StyledVmsIconL, StyledVmsBadgeIconL)

export const buildCustomBadgedVMSIcon = (iconExtension, badgeExtension) =>
  BadgedIconComponentFactory(
    styled(StyledVmsIconBase)`
      ${iconExtension};
    `,
    styled(StyledVmsBadgeIcon)`
      ${badgeExtension};
    `
  )
