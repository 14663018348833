import _ from 'lodash'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { type Destination, useNavigation, type AtomType } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalHeader, ModalFooter, ModalActions, ModalTitle, type ModalHeaderProps, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'
import { campaignBuilderMessages } from '@sevenrooms/marketing'
import { type Venue, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'

interface NoOfferProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  venue: Venue
  isDirty?: boolean
  setGoToOffers?: Function
  confirmationModalHref?: Destination<
    string,
    {
      venueKey: AtomType<'string', 'params', false>
    },
    'surface'
  >
}

export function NoOffer({ confirmationModalHref, closeHref, venue, isDirty, setGoToOffers = _.noop }: NoOfferProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueSettings } = useVenueSettingsContext()
  const goToOffers = () => {
    if (isDirty && confirmationModalHref) {
      nav.closeSurface(closeHref)
      setGoToOffers()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nav.push(confirmationModalHref, { params: { venueKey: venue.urlKey } })
    } else {
      nav.closeSurface(closeHref)
      if (venueSettings?.is_thefork_integration_enabled) {
        window.location.assign(
          nav
            .href(routes.manager2.marketing.offers, {
              params: { venueKey: venue.urlKey },
            })
            .split('?')[0] || ''
        )
      } else {
        window.location.assign(
          nav
            .href(routes.manager2.marketing.offers, {
              params: { venueKey: venue.urlKey },
            })
            .split('?')[0] || ''
        )
      }
    }
  }
  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <Status kind="warning">{formatMessage(commonMessages.warning)}</Status>
          <ModalTitle title={formatMessage(campaignBuilderMessages.noOfferModalSubTitle)} />
        </VStack>
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button
            variant="secondary"
            onClick={() => {
              nav.closeSurface(closeHref)
            }}
            data-test="cancel-button"
          >
            {formatMessage(campaignBuilderMessages.modalCancelButton)}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              goToOffers()
            }}
            data-test="add-offers-button"
          >
            {formatMessage(campaignBuilderMessages.modalAddOfferButton)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
