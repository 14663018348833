import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import styled from 'styled-components'
import LazyImage from 'mgr/lib/components/LazyImage'
import { TagElement } from 'mgr/lib/components/Tags'
import { getContrastingColor } from 'svr/common/Color'

const ExternalUserLabel = styled.span`
  color: #347baf;
  font-size: 16px;
`

const ClientRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.gutter.double} 0;
  border-top: 1px solid ${props => props.theme.mediumGrey};
  border-bottom: 1px solid ${props => props.theme.mediumGrey};
  margin-bottom: -1px;
  ${props => props.theme.whiteClickableRow};
`

const VipTag = styled.span`
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  display: inline;
  border-top-left-radius: 8px;
  position: absolute;
  transform: translateY(-8px);

  :after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    border-width: 16px 16px 0 0;
    border-style: solid;
    border-color: #f8e81c transparent;
  }
`

const ThemedLazyImage = styled(LazyImage)`
  margin: 0 auto;
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background-color: #051729;
  text-transform: uppercase;
`

const ClientInitials = styled.div`
  margin: 0 auto;
  color: #7e98b1;
  text-align: center;
  background-color: ${props => props.theme.navigationDark};
  font-size: 22px;
  text-transform: uppercase;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 23px;
`

const maxDisplayWidth = '400px'

const ResultTextCol = styled.div`
  font-weight: 500;
  height: 22px;
  line-height: 22px;
  max-width: ${maxDisplayWidth};
  color: ${props => props.theme.navigationDark};
  margin-top: 2px;
  margin-bottom: 2px;
  ${props => props.theme.ellipsis};
`

const NameCol = styled(ResultTextCol)`
  font-size: 20px;
`

const TagCol = styled.div`
  max-width: ${maxDisplayWidth};
`

const ContactCol = styled(ResultTextCol)`
  color: ${props => props.theme.navigationDark};
  font-size: 13px;
  font-weight: 500;
`

const NotesCol = styled(ResultTextCol)`
  font-size: 14px;
  font-weight: 400;
  font-style: oblique;
  color: ${props => props.theme.darkGrey};
`

const StatsContainer = styled.div`
  height: 100%;
  display: ${props => (props.showForMobile ? 'none' : 'flex')};
  @media (max-width: ${props => props.theme.screenSizes.thin}) {
    display: ${props => (props.showForMobile ? 'flex' : 'none')};
    width: 100%;
    float: left;
    margin-top: 10px;
    height: 50px;
  }

  ${props =>
    props.isNarrowFormat
      ? 'justify-content: space-between;'
      : `& > div:first-child {
        border-right: 1px solid ${props.theme.lightGrey};
      }`};
`

const StatsColumn = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 0 ${props => props.theme.gutter.half};
  ${props => props.isNarrowFormat && 'justify-content: space-between'};
  & > div {
    flex-direction: ${props => (props.isNarrowFormat ? 'column' : 'row')};
  }
`

const StatsItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isNarrowFormat ? 'flex-start' : 'center')};
`

const StatsValue = styled.div`
  font-size: 16px;
  font-weight: 500;
  max-width: 80px;
  flex: 1;
  text-align: right;
  color: ${props => props.theme.charcoal};
  order: ${props => (props.isNarrowFormat ? 2 : 1)};
  ${props => props.theme.ellipsis};
`

// give this a width so we can align the labels
const StatsLabel = styled.div`
  width: ${props => props.customWidth};
  box-sizing: border-box;
  font-size: 11px;
  font-weight: 500;
  color: ${props => props.theme.darkGrey};
  text-transform: uppercase;
  padding: 0 0 0 ${props => (props.isNarrowFormat ? 0 : props.theme.gutter.standard)};
  order: ${props => (props.isNarrowFormat ? 1 : 2)};
  ${props => props.theme.ellipsis};
`

const ClientBodyDisplayContainer = styled.div`
  flex: 1;
  padding-left: ${props => props.theme.gutter.double};
`

const ClientImageContentsContainer = styled.div`
  display: flex;
`

const StatsItem = ({ label, value, isNarrowFormat, customWidth }) => (
  <StatsItemContainer isNarrowFormat={isNarrowFormat}>
    <StatsLabel customWidth={customWidth} isNarrowFormat={isNarrowFormat}>
      {label}
    </StatsLabel>
    <StatsValue isNarrowFormat={isNarrowFormat}>{value}</StatsValue>
  </StatsItemContainer>
)

export const ClientBodyDisplay = ({ client }) => {
  let externalUserLabelContent = null
  const contactContents = _.compact([client.phone_number_formatted, client.email_address, client.company]).join(' · ')

  const loyaltyContents = _.compact([client.loyalty_id, client.loyalty_tier, client.loyalty_rank]).join(' · ')
  const notesContents = client.notes ? `"${client.notes}` : null

  const tagsContents = (client.tags_display || []).map((elem, idx) => {
    const key = `${elem.tag_group_id}##${elem.tag_name}`
    return <TagElement key={key} tagObj={elem} />
  })

  if (client.external_user_label) {
    externalUserLabelContent = <ExternalUserLabel>{client.external_user_label}</ExternalUserLabel>
  }

  return (
    <div>
      {client && client.name_display && (
        <NameCol>
          {client.name_display} {externalUserLabelContent}
        </NameCol>
      )}

      {contactContents && <ContactCol>{contactContents}</ContactCol>}
      {loyaltyContents && <ContactCol>{loyaltyContents}</ContactCol>}
      {notesContents && <NotesCol>{notesContents}</NotesCol>}
      {!_.isEmpty(tagsContents) && <TagCol>{tagsContents}</TagCol>}
    </div>
  )
}

export const ClientStatsDisplay = ({ client, isNarrowFormat, showForMobile, canViewClientSpendData }) => {
  const isNewClient = _.isEmpty(client.id)
  const placeholder = '\u2014'
  const column1 = [
    {
      label: 'net spend',
      value: isNewClient ? placeholder : client.total_spend_formatted_no_decimals,
    },
    {
      label: 'avg/visit',
      value: isNewClient ? placeholder : client.total_spend_per_visit_formatted,
    },
    {
      label: 'avg/cover',
      value: isNewClient ? placeholder : client.total_spend_per_cover_formatted,
    },
  ]
  const column2 = [
    {
      label: pluralize('visits', client.total_visits),
      value: isNewClient ? placeholder : (client.total_visits || 0).toLocaleString(),
    },
    {
      label: pluralize('cancels', client.total_visits),
      value: isNewClient ? placeholder : (client.total_cancellations || 0).toLocaleString(),
    },
    {
      label: pluralize('no shows', client.total_visits),
      value: isNewClient ? placeholder : (client.total_noshows || 0).toLocaleString(),
    },
  ]

  return (
    <StatsContainer isNarrowFormat={isNarrowFormat} showForMobile={showForMobile}>
      {canViewClientSpendData && (
        <StatsColumn isNarrowFormat={isNarrowFormat} hasRightBorder>
          {column1.map(item => (
            <StatsItem key={item.label} isNarrowFormat={isNarrowFormat} customWidth="74px" {...item} />
          ))}
        </StatsColumn>
      )}
      <StatsColumn isNarrowFormat={isNarrowFormat}>
        {column2.map(item => (
          <StatsItem key={item.label} isNarrowFormat={isNarrowFormat} customWidth="70px" {...item} />
        ))}
      </StatsColumn>
    </StatsContainer>
  )
}

ClientStatsDisplay.propTypes = {
  client: React.PropTypes.object.isRequired,
  isNarrowFormat: React.PropTypes.bool,
}

ClientStatsDisplay.defaultProps = {
  isNarrowFormat: false,
}

const ClientLookupResult = ({ testId, client, isNarrowFormat, onClick, canViewClientSpendData }) => {
  let clientImageContents
  const imageSizes = ['small', 'medium', 'large']
  const imageSrcs = []
  let vipFlagContents = null

  if (client.is_vip) {
    vipFlagContents = <VipTag />
  }
  imageSizes.forEach(elem => {
    if (client.photos && client.photos[elem]) {
      imageSrcs.push(client.photos[elem])
    }
  })

  if (client.photos) {
    clientImageContents = <ThemedLazyImage src={imageSrcs} alt={client.name_display} />
  } else {
    clientImageContents = <ClientInitials>{client.initials}</ClientInitials>
  }

  return (
    <ClientRow data-test={testId} onClick={onClick}>
      {vipFlagContents}
      <ClientImageContentsContainer>{clientImageContents}</ClientImageContentsContainer>
      <ClientBodyDisplayContainer>
        <ClientBodyDisplay client={client} isNarrowFormat={isNarrowFormat} />
        <ClientStatsDisplay client={client} isNarrowFormat={isNarrowFormat} showForMobile canViewClientSpendData={canViewClientSpendData} />
      </ClientBodyDisplayContainer>
      <ClientStatsDisplay
        client={client}
        isNarrowFormat={isNarrowFormat}
        showForMobile={false}
        canViewClientSpendData={canViewClientSpendData}
      />
    </ClientRow>
  )
}

ClientLookupResult.defaultProps = {
  onClick: () => {},
}

ClientLookupResult.propTypes = {
  testId: React.PropTypes.string,
  client: React.PropTypes.object.isRequired,
  onClick: React.PropTypes.func.isRequired,
  canViewClientSpendData: React.PropTypes.bool,
}

export default ClientLookupResult
