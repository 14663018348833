import { defineMessages } from '@sevenrooms/core/locales'

export const upgradesMessages = defineMessages({
  title: {
    id: 'title',
    defaultMessage: 'Upgrades for this booking',
  },
  titlePreselected: {
    id: 'titlePreselected',
    defaultMessage: 'Previously selected Upgrades',
  },
  subTitlePreselected: {
    id: 'titlePreselected',
    defaultMessage: 'The following upgrades are not standard for the new time slot and may have already been paid for.',
  },
  overrideLabel: {
    id: 'overrideLabel',
    defaultMessage: 'Override: do not require standard upgrades for this reservation',
  },
  mustSelectLabel: {
    id: 'mustSelectLabel',
    defaultMessage: 'Must select {quantity}',
  },
  mustSelectAtLeastLabel: {
    id: 'mustSelectAtLeastLabel',
    defaultMessage: 'Must select at least {quantity}',
  },
  pricePerEachLabel: {
    id: 'pricePerEachLabel',
    defaultMessage: '{price}/each',
  },
  errorRequiredUpgrades: {
    id: 'errorRequiredUpgrades',
    defaultMessage: 'Please select required upgrades',
  },
} as const)
