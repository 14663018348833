import React, { Component } from 'react'
import DropdownArrowsPicker from './DropdownArrowsPicker'
import _ from 'lodash'

const SeatingAreaDropDown = ({
  seatingAreaId,
  seatingAreas,
  includeDefaultOption,
  includeAllOption,
  onChangeSeatingArea,
  placeholder,
  width,
  style,
  showNavigation,
  disabled,
  isLightTheme,
}) => {
  const choices = seatingAreas.map(area => ({ name: area.name, value: area.id, selectValue: area }))
  let value = seatingAreaId
  if (includeAllOption) {
    choices.unshift({ name: 'Any', value: '_any_' })
    if (_.isEmpty(seatingAreaId)) {
      value = '_any_'
    }
  }
  if (includeDefaultOption) {
    choices.unshift({ name: 'Default', value: '_default_' })
    if (_.isEmpty(seatingAreaId)) {
      value = '_default_'
    }
  }
  return (
    <DropdownArrowsPicker
      key="SEATING AREA"
      name="SEATING AREA"
      onChangeHandler={onChangeSeatingArea}
      style={{
        float: 'left',
        width,
        ...style,
      }}
      {...{
        choices,
        value,
        placeholder,
        showNavigation,
        disabled,
        isLightTheme,
      }}
    />
  )
}

SeatingAreaDropDown.defaultProps = {
  showNavigation: false,
  disabled: false,
  isLightTheme: true,
  width: 185,
  style: {},
  onChangeSeatingArea: () => {},
  includeAllOption: false,
  includeDefaultOption: false,
}

SeatingAreaDropDown.propTypes = {
  seatingAreaId: React.PropTypes.string,
  seatingAreas: React.PropTypes.array.isRequired,
  includeAllOption: React.PropTypes.bool.isRequired,
  includeDefaultOption: React.PropTypes.bool.isRequired,
  showNavigation: React.PropTypes.bool.isRequired,
  placeholder: React.PropTypes.string,
  disabled: React.PropTypes.bool.isRequired,
  isLightTheme: React.PropTypes.bool.isRequired,
  width: React.PropTypes.number.isRequired,
  style: React.PropTypes.object.isRequired,
  onChangeSeatingArea: React.PropTypes.func.isRequired,
}

export default SeatingAreaDropDown
