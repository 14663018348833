import { ExperienceType, ExperienceStatus, ExperienceVisibility } from 'mgr/pages/shared/utils/Constants'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'

const { venueSettings } = globalInit
const isSizzleSuperhero = venueSettings.sizzle_superheroes_only && globalInit.user.is_superhero
const sizzlePackageEnabled = venueSettings.is_full_sizzle_package || venueSettings.is_sizzle_standalone
const isSizzleEnabled = isSizzleSuperhero || sizzlePackageEnabled
const isTheForkIntegrationEnabled = globalInit.userDomain.is_thefork_integration_enabled
const showTheForkOfferOnly = !isSizzleEnabled && isTheForkIntegrationEnabled

const theForkOriginalState = {
  // TheFork Offer properties
  thefork_name: '',
  thefork_price: '',
  thefork_start_date: null,
  thefork_end_date: null,
  thefork_active: true,
  thefork_early_preview: false,
  thefork_early_preview_date: null,
  thefork_menu: [
    {
      menu_course: { id: 'STARTER', label: 'Starter' },
      custom_menu_title: '',
      items: [''],
    },
  ],
}

const theForkState = JSON.parse(JSON.stringify(theForkOriginalState))

const initialState = {
  showTheForkOfferOnly,
  isVisible: false,
  current: 0,
  processing: false,
  stepValidator: () => true,
  complete: false,
  photoId: null,
  cropAspect: null,
  maxImages: 6,
  deactivatingExperience: false,
  isDeactivateModalVisible: false,
  deletingExperience: false,
  isDeleteModalVisible: false,
  oldStatus: null,
  experience: {
    id: null,
    name: '',
    status: ExperienceStatus.DRAFT,
    offer_type: showTheForkOfferOnly ? ExperienceType.THEFORK : ExperienceType.EXPERIENCE,
    title: '',
    description: '',
    price_description: '',
    default_party_size: '2',
    menu_blob_id: null,
    menu_filename: '',
    hero_image: { id: 'hero', raw_url_key: null },
    image_list: [
      { id: 'exp_img_1', raw_url_key: null },
      { id: 'exp_img_2', raw_url_key: null },
      { id: 'exp_img_3', raw_url_key: null },
      { id: 'exp_img_4', raw_url_key: null },
      { id: 'exp_img_5', raw_url_key: null },
      { id: 'exp_img_6', raw_url_key: null },
    ],
    template_id: 'default',
    landing_url_key: '',
    visibility: ExperienceVisibility.PUBLIC,
    hide_on_directory: false,

    ...theForkState,
  },
  themes: [
    {
      id: 'default',
      name: 'Theme 1',
      image: 'theme_1.png',
      defaultTitle: venue => `Come join us at ${venue.name}!`,
    },
    {
      id: 'twentytwenty',
      name: 'Theme 2',
      image: 'theme_2.png',
      defaultTitle: () => 'Description',
    },
  ],
  lastExperience: {},
}

const experienceWizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_EXPERIENCE_WIZARD_STEP:
      return { ...state, current: action.index }
    case ActionTypes.TOGGLE_EXPERIENCE_WIZARD:
      const isVisible = !state.isVisible
      const newTheForkState = JSON.parse(JSON.stringify(theForkOriginalState))
      state.experience = { ...state.experience, ...newTheForkState }
      return isVisible
        ? {
            ...state,
            isVisible: true,
          }
        : {
            ...state,
            ...initialState,
            isVisible: false,
            lastExperience: state.experience,
          }
    case ActionTypes.UPDATE_EXPERIENCE_WIZARD_ATTRIBUTE:
      const experienceUpdate = { ...state.experience }
      experienceUpdate[action.attribute] = action.value
      return {
        ...state,
        experience: experienceUpdate,
      }
    case ActionTypes.UPDATE_EXPERIENCE_THEFORK_MENU_COURSE:
      const theForkExperienceUpdate = { ...state.experience }
      theForkExperienceUpdate.thefork_menu[action.idx].menu_course = action.menuCourse
      return {
        ...state,
        experience: theForkExperienceUpdate,
      }
    case ActionTypes.UPDATE_EXPERIENCE_THEFORK_CUSTOM_MENU_TITLE:
      const theForkCustomExperienceUpdate = { ...state.experience }
      theForkCustomExperienceUpdate.thefork_menu[action.idx].custom_menu_title = action.value
      return {
        ...state,
        experience: theForkCustomExperienceUpdate,
      }
    case ActionTypes.UPDATE_EXPERIENCE_THEFORK_MENU_ITEM:
      const theForkMenuItemExperienceUpdate = { ...state.experience }
      theForkMenuItemExperienceUpdate.thefork_menu[action.menuCourseIdx].items[action.menuItemIdx] = action.value
      return {
        ...state,
        experience: theForkMenuItemExperienceUpdate,
      }
    case ActionTypes.ADD_EXPERIENCE_THEFORK_MENU_COURSE:
      const theForkAddMenuCourseExperienceUpdate = { ...state.experience }
      theForkAddMenuCourseExperienceUpdate.thefork_menu.push({
        menu_course: { id: 'STARTER', label: 'Starter' },
        custom_menu_title: '',
        items: [''],
      })
      return {
        ...state,
        experience: theForkAddMenuCourseExperienceUpdate,
      }
    case ActionTypes.REMOVE_EXPERIENCE_THEFORK_MENU_COURSE:
      const theForkRemoveMenuCourseExperienceUpdate = { ...state.experience }
      theForkRemoveMenuCourseExperienceUpdate.thefork_menu.splice(action.menuCourseIdx, 1)
      return {
        ...state,
        experience: theForkRemoveMenuCourseExperienceUpdate,
      }
    case ActionTypes.REMOVE_EXPERIENCE_THEFORK_MENU_ITEM:
      const theForkRemoveMenuItemExperienceUpdate = { ...state.experience }
      theForkRemoveMenuItemExperienceUpdate.thefork_menu[action.menuCourseIdx].items.splice(action.itemIdx, 1)
      return {
        ...state,
        experience: theForkRemoveMenuItemExperienceUpdate,
      }
    case ActionTypes.ADD_EXPERIENCE_THEFORK_MENU_ITEM:
      const theForkAddMenuItemExperienceUpdate = { ...state.experience }
      theForkAddMenuItemExperienceUpdate.thefork_menu[action.menuCourseIdx].items.push('')
      return {
        ...state,
        experience: theForkAddMenuItemExperienceUpdate,
      }
    case ActionTypes.UPLOAD_MENU_SUCCESS:
      const fileUpdate = {
        ...state.experience,
        menu_blob_id: action.file.blobKey,
        menu_filename: action.file.fileName,
      }
      return {
        ...state,
        experience: fileUpdate,
      }
    case ActionTypes.DELETE_MENU_SUCCESS:
      const fileRemove = {
        ...state.experience,
        menu_blob_id: initialState.experience.menu_blob_id,
        menu_filename: initialState.experience.menu_filename,
      }
      return {
        ...state,
        experience: fileRemove,
      }
    case ActionTypes.SET_EXPERIENCE_WIZARD_VALIDATOR:
      return {
        ...state,
        stepValidator: action.validator,
      }
    case ActionTypes.SET_EXPERIENCE_WIZARD_COMPLETE:
      return {
        ...state,
        complete: true,
      }

    case ActionTypes.DELETE_EXPERIENCE_START:
      return {
        ...state,
        processing: true,
        isDeleteModalVisible: false,
      }

    case ActionTypes.DELETE_EXPERIENCE_FAIL:
      return { ...state, processing: false }

    case ActionTypes.SET_EXPERIENCE_ACTIVE:
      return {
        ...state,
        oldStatus: state.experience.status,
        experience: {
          ...state.experience,
          status: ExperienceStatus.ACTIVE,
        },
      }

    case ActionTypes.SAVE_EXPERIENCE_START:
      return {
        ...state,
        processing: true,
        oldStatus: state.experience.status,
      }

    case ActionTypes.SAVE_EXPERIENCE_SUCCESS:
      if (action.publish) {
        return {
          ...state,
          ...initialState,
        }
      }

      return {
        ...state,
        processing: false,
        oldStatus: null,
        experience: {
          ...state.experience,
          id: action.id,
        },
      }

    case ActionTypes.SAVE_EXPERIENCE_FAIL:
      return {
        ...state,
        processing: false,
        oldStatus: null,
        experience: {
          ...state.experience,
          status: state.oldStatus,
        },
      }

    case ActionTypes.DELETE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        ...initialState,
      }

    case ActionTypes.DEACTIVATE_EXPERIENCE_START:
      return {
        ...state,
        processing: true,
        isDeactivateModalVisible: false,
        experience: {
          ...state.experience,
          status: ExperienceStatus.INACTIVE,
        },
      }

    case ActionTypes.DEACTIVATE_EXPERIENCE_FAIL:
      return {
        ...state,
        processing: false,
        experience: {
          ...state.experience,
          status: ExperienceStatus.ACTIVE,
        },
      }

    case ActionTypes.DEACTIVATE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        processing: false,
      }

    case ActionTypes.LOAD_EXPERIENCE_TO_WIZARD:
      const loadImages = initialState.experience.image_list.map((image, index) => ({
        ...image,
        ...action.experience.image_list[index],
      }))
      const heroImage = {
        ...initialState.experience.hero_image,
        ...action.experience.hero_image,
      }
      const theforkEarlyPreview = !!action.experience.thefork_early_preview_date
      const theforkEarlyPreviewDate = action.experience.thefork_early_preview_date
        ? moment(action.experience.thefork_early_preview_date)
        : null
      const theforkStartDate = action.experience.thefork_start_date ? moment(action.experience.thefork_start_date) : null
      const theforkEndDate = action.experience.thefork_end_date ? moment(action.experience.thefork_end_date) : null
      return {
        ...initialState,
        isVisible: true,
        current: action.experience.status === ExperienceStatus.DRAFT ? 0 : 4,
        experience: {
          ...action.experience,
          image_list: loadImages,
          hero_image: heroImage,
          thefork_start_date: theforkStartDate,
          thefork_end_date: theforkEndDate,
          thefork_early_preview: theforkEarlyPreview,
          thefork_early_preview_date: theforkEarlyPreviewDate,
        },
      }

    case ActionTypes.UPLOAD_EXPERIENCE_PHOTO_SUCCESS:
      const photoUpdate = {
        id: action.photoId,
        crop_info: null,
        raw_url_key: action.uploadData.url_key,
        aspect: action.aspect,
      }

      if (action.photoId === 'hero') {
        return {
          ...state,
          cropAspect: action.aspect,
          experience: {
            ...state.experience,
            hero_image: photoUpdate,
          },
        }
      }
      const photosListUpdate = state.experience.image_list.map(item => {
        if (item.id !== action.photoId) {
          return item
        }
        return { ...item, ...photoUpdate }
      })

      return {
        ...state,
        cropAspect: action.aspect,
        experience: {
          ...state.experience,
          image_list: photosListUpdate,
        },
      }
    case ActionTypes.CROPPING:
      return {
        ...state,
        photoId: action.photoId,
      }
    case ActionTypes.CROP_PHOTO:
      if (action.coords.height < 1 || action.coords.width < 1) {
        return state
      }
      if (action.photoId === 'hero') {
        return {
          ...state,
          experience: {
            ...state.experience,
            hero_image: {
              ...state.experience.hero_image,
              crop_info: action.coords,
            },
          },
        }
      }

      const photosCropUpdate = state.experience.image_list.map(item => {
        if (item.id !== action.photoId) {
          return item
        }
        return { ...item, crop_info: action.coords }
      })

      return {
        ...state,
        experience: {
          ...state.experience,
          image_list: photosCropUpdate,
        },
      }
    case ActionTypes.EDIT_PHOTO:
      return {
        ...state,
        photoId: action.photoId,
        cropAspect: action.aspect,
      }
    case ActionTypes.DELETE_PHOTO:
      if (action.photoId === 'hero') {
        return {
          ...state,
          experience: {
            ...state.experience,
            hero_image: {},
          },
        }
      }
      const deletePhotoUpdate = state.experience.image_list.map(item => {
        if (item.id !== action.photoId) {
          return item
        }
        return { id: item.id, url: null }
      })

      return {
        ...state,
        experience: {
          ...state.experience,
          image_list: deletePhotoUpdate,
        },
      }
    case ActionTypes.TOGGLE_DEACTIVATE_EXPERIENCE:
      return {
        ...state,
        isDeactivateModalVisible: !state.isDeactivateModalVisible,
      }
    case ActionTypes.TOGGLE_DELETE_EXPERIENCE:
      return {
        ...state,
        isDeleteModalVisible: !state.isDeleteModalVisible,
      }

    default:
      return state
  }
}

export default experienceWizardReducer
