import * as React from 'react'
import { SettingsPageLayout, type SettingsPageLayoutProps } from '@sevenrooms/core/ui-kit/layout'
import { useUserContext, useVenueContext } from '@sevenrooms/mgr-core'

export interface SettingsPageProps extends SettingsPageLayoutProps {}

export function SettingsPage({
  children,
  showSideNavigation,
  sideNavigationItems,
  calendar,
  hideVenueName,
  hideSearch,
}: React.PropsWithChildren<SettingsPageProps>) {
  const { venue } = useVenueContext()
  const user = useUserContext()

  return (
    <SettingsPageLayout
      showSideNavigation={showSideNavigation}
      sideNavigationItems={sideNavigationItems}
      calendar={calendar}
      hideVenueName={hideVenueName}
      hideSearch={hideSearch}
      venueInternalDisplayName={venue?.internalDisplayName}
      userId={user?.id}
    >
      {children}
    </SettingsPageLayout>
  )
}
