import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { type AvailabilityTimeslot, useGetMultiVenueAvailabilityTimeslotQuery } from '@sevenrooms/core/api'
import type { ISODate, MultiVenueAvailabilityTimeslotRequest, ShiftCategory } from '@sevenrooms/core/domain'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { updateDuration } from '../../actions/BookAvailabilityActions'
import { useStoreSelector } from '../../selectors/useStoreSelector'

export interface MultiVenueAvailabilityTimeslotsResponse {
  [k: string]: {
    timeslot: AvailabilityTimeslot
    category: ShiftCategory
  }[]
}

export function useMultiVenueAvailabilityTimeslotsRequest() {
  const dispatch = useDispatch()
  const { previousSelectedTimeSlot } = useStoreSelector(state => state.bookAvailabilityState)
  const requestParams = useMultiVenueAvailabilityQueryParamsSelector()
  const { data: availabilityEntries, isFetching } = useGetMultiVenueAvailabilityTimeslotQuery(requestParams)

  const availabilityARByVenue = useMemo(() => {
    const availabilityItems = Object.entries(availabilityEntries ?? []).map(
      ([venueId, availability]) =>
        [
          venueId,
          availability
            .map(({ timeslots, category }) =>
              timeslots.map(timeslot => ({
                timeslot,
                category,
              }))
            )
            .flat(),
        ] as const
    )
    return Object.fromEntries(availabilityItems)
  }, [availabilityEntries])

  useEffect(() => {
    const timeslots = Object.entries(availabilityARByVenue)
      .map(([_venueId, availability]) => availability)
      .flat()
      .map(({ timeslot }) => timeslot)
    const foundTimeslot = timeslots.find(
      t =>
        t.access_persistent_id === previousSelectedTimeSlot?.access_persistent_id && t.sort_order === previousSelectedTimeSlot?.sort_order
    )
    dispatch(updateDuration(foundTimeslot, timeslots && timeslots.some(t => t.access_rule_durations_enabled)))
  }, [previousSelectedTimeSlot, dispatch, availabilityARByVenue])

  if (isFetching) {
    return {
      availabilityARByVenue: undefined,
      isFetching,
      requestParams,
    }
  }

  return {
    availabilityARByVenue,
    isFetching,
  }
}

export function useMultiVenueAvailabilityQueryParamsSelector() {
  const {
    showAccessRules,
    date: dateMoment,
    partySize,
    duration,
    usingDefaultDuration,
    seatingAreaId,
    audienceId,
    actual,
    bufferMins,
    searchVenues,
  } = useStoreSelector(state => state.bookAvailabilityState)
  const {
    venue: { venueGroupId },
  } = useVenueContext()
  const venueIds = useMemo(() => (searchVenues ?? []).map(v => v.id), [searchVenues])
  const actualId = actual?.id
  const date = dateMoment.format('YYYY-MM-DD') as ISODate
  const { isConsumerFeatureEnabled, isConsumerTagsEnabled } = useStoreSelector(state => state.bookState)
  const { selectedClient } = useStoreSelector(state => state.bookClientState)
  const clientTags = useMemo(() => selectedClient?.client_tags ?? [], [selectedClient?.client_tags])

  return useMemo(
    () =>
      showAccessRules && venueIds.length > 0 && audienceId
        ? ({
            venueGroupId,
            venueIds,
            date,
            partySize: partySize ?? 0,
            duration: usingDefaultDuration ? null : duration,
            seatingArea: seatingAreaId,
            channel: audienceId,
            actualId,
            buffer: bufferMins,
            clientTags:
              isConsumerTagsEnabled && isConsumerFeatureEnabled && clientTags.length ? clientTags.map(ct => ct.tagHash) : undefined,
          } as MultiVenueAvailabilityTimeslotRequest)
        : skipToken,
    [
      showAccessRules,
      venueIds,
      audienceId,
      venueGroupId,
      date,
      partySize,
      duration,
      usingDefaultDuration,
      seatingAreaId,
      actualId,
      bufferMins,
      isConsumerTagsEnabled,
      isConsumerFeatureEnabled,
      clientTags,
    ]
  )
}
