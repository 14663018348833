import { useLocales } from '@sevenrooms/core/locales'
import { startOfMonth, startOfYear, subDays, subMonths, endOfMonth } from '@sevenrooms/core/timepiece'
import { campaignCenterMessages, type CampaignDateFilters, type CampaignPerformanceFiltersOption } from '@sevenrooms/marketing'

export function useDateFilters(): { [U in CampaignDateFilters]: CampaignPerformanceFiltersOption } {
  const { formatMessage } = useLocales()
  return {
    custom: {
      id: 'custom',
      label: formatMessage(campaignCenterMessages.customLabel),
      convert: () => ({
        startDate: subDays(new Date(), 29),
        endDate: new Date(),
      }),
    },
    yesterday: {
      id: 'yesterday',
      label: formatMessage(campaignCenterMessages.yesterdayLabel),
      convert: () => ({
        startDate: subDays(new Date(), 1),
        endDate: subDays(new Date(), 1),
      }),
    },
    last7Days: {
      id: 'last7Days',
      label: formatMessage(campaignCenterMessages.last7DaysLabel),
      convert: () => ({
        startDate: subDays(new Date(), 6),
        endDate: new Date(),
      }),
    },
    last30Days: {
      id: 'last30Days',
      label: formatMessage(campaignCenterMessages.last30DaysLabel),
      convert: () => ({
        startDate: subDays(new Date(), 29),
        endDate: new Date(),
      }),
    },
    last60Days: {
      id: 'last60Days',
      label: formatMessage(campaignCenterMessages.last60DaysLabel),
      convert: () => ({
        startDate: subDays(new Date(), 59),
        endDate: new Date(),
      }),
    },
    thisMonth: {
      id: 'thisMonth',
      label: formatMessage(campaignCenterMessages.thisMonthLabel),
      convert: () => ({
        startDate: startOfMonth(new Date()),
        endDate: new Date(),
      }),
    },
    lastMonth: {
      id: 'lastMonth',
      label: formatMessage(campaignCenterMessages.lastMonthLabel),
      convert: () => ({
        startDate: startOfMonth(subMonths(new Date(), 1)),
        endDate: endOfMonth(subMonths(new Date(), 1)),
      }),
    },
    thisYear: {
      id: 'thisYear',
      label: formatMessage(campaignCenterMessages.thisYearLabel),
      convert: () => ({
        startDate: startOfYear(new Date()),
        endDate: new Date(),
      }),
    },
    all: {
      id: 'all',
      label: formatMessage(campaignCenterMessages.allLabel),
      convert: () => ({}),
    },
  }
}
