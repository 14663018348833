import _ from 'lodash'
import { EMPTY_ARRAY, EMPTY_DICT } from './Constants'

export const getAvailabilityByTableId = (gridAvailabilityMap, tableId) => {
  if (!tableId) {
    return EMPTY_DICT
  }
  try {
    return gridAvailabilityMap[tableId] || EMPTY_DICT
  } catch (err) {}
  return EMPTY_DICT
}

export const getProblemDescription = (tableDict, short = true) => {
  let description = ''
  if (!tableDict || Object.keys(tableDict).length === 0) {
    return description
  }
  if (tableDict.tooSmall) {
    description = short ? 'Table is too small' : 'This table is too small for this reservation'
  } else if (tableDict.tooLarge) {
    description = short ? 'Table is too large' : 'This table is too large for this reservation'
  } else if (tableDict.isLocked) {
    description = short ? 'Locked table conflict' : 'There is a conflicting reservation that is locked to this table'
  } else if (tableDict.primaryBlock?.isBlocked) {
    description = short ? 'Block conflict' : 'There is a block conflict on this table'
  } else if (tableDict.primaryRule?.isHold) {
    description = short ? 'Held access rule conflict' : 'There is an access rule conflict on this table'
  } else if (tableDict.willCauseProblems) {
    description = short ? 'Will cause problem reservations' : 'Moving this reservation is not possible without creating a problem'
  }
  return description
}

const createTableDict = () => ({
  id: null,
  name: null,
  partySizeRange: null,
  isLocked: false,
  primaryBlock: {
    id: null,
    name: null,
    canOverbook: false,
    isBlocked: false,
  },
  primaryRule: {
    id: null,
    name: null,
    isHold: false,
  },
  tooLarge: false,
  tooSmall: false,
  willCauseProblems: false,
  recommended: false,
  recommendedCombos: [],
  notRecommendedCombos: [],
})

const getAvailabilityForSortOrder = (actualStartSortOrder, availableTimes) => {
  const availability = _.find(availableTimes || [], {
    sort_order: actualStartSortOrder,
  })
  if (!_.isNil(availability)) {
    return availability
  }
  // If in simple move mode, no server calculated availability exists
  // Rely on local data instead (tables + ressies)
  // TODO: a full, local availability calculation
  return {
    useSimpleMoveMode: true,
    blocked: [],
    held: [],
    table_size_too_large: [],
    table_size_too_small: [],
    recommended: [],
  }
}

export const getGridAvailabilityMap = (tableItems, gridActualsMapByShift, availableTimes, actual) => {
  const actualStartSortOrder = actual.arrival_time_sort_order
  const availability = getAvailabilityForSortOrder(actualStartSortOrder, availableTimes)
  const availabilityByTableId = {}

  // populate sizing on all tables first
  for (const table of tableItems) {
    const tableId = table.id
    if (!(tableId in availabilityByTableId)) {
      availabilityByTableId[tableId] = createTableDict()
    }
    availabilityByTableId[tableId].id = table.id
    availabilityByTableId[tableId].name = table.item_code
    availabilityByTableId[tableId].partySizeRange = table.party_size_range_display
    if (actual.max_guests || actual.max_guests == 0) {
      if (actual.max_guests > table.party_size_max) {
        availabilityByTableId[tableId].tooSmall = true
      } else if (actual.max_guests < table.party_size_min) {
        availabilityByTableId[tableId].tooBig = true
      }
    }
  }

  // overlap with locked table
  const usedOrdersByTableId = gridActualsMapByShift[actual.shift_persistent_id]
  for (const tableId in usedOrdersByTableId) {
    const usedOrders = usedOrdersByTableId[tableId]._usedOrders
    if (!usedOrders) {
      continue // _UNASSIGNED, _maxResOrders
    }
    if (!(tableId in availabilityByTableId)) {
      availabilityByTableId[tableId] = createTableDict()
    }
    const durationOrder = (actual.duration + (actual.buffer_mins || 0)) / 15
    let isLocked = false
    for (const order of _.range(actualStartSortOrder, actualStartSortOrder + durationOrder)) {
      if (!isLocked) {
        isLocked = order in usedOrders
      }
    }
    availabilityByTableId[tableId].isLocked = isLocked
  }

  // Blocks
  for (const blockedTable of availability.blocked) {
    const tableId = blockedTable.id
    const block = blockedTable.blocks[0]
    if (!(tableId in availabilityByTableId)) {
      availabilityByTableId[tableId] = createTableDict()
    }
    availabilityByTableId[tableId].id = tableId
    availabilityByTableId[tableId].name = blockedTable.name
    availabilityByTableId[tableId].partySizeRange = blockedTable.party_size_range
    availabilityByTableId[tableId].primaryBlock.id = block.id
    availabilityByTableId[tableId].primaryBlock.name = block.name
    availabilityByTableId[tableId].primaryBlock.canOverbook = blockedTable.has_permissions
    availabilityByTableId[tableId].primaryBlock.isBlocked = blockedTable.is_blocked
  }
  // Rules
  for (const ruleTable of availability.held) {
    const tableId = ruleTable.table_id
    if (!(tableId in availabilityByTableId)) {
      availabilityByTableId[tableId] = createTableDict()
    }
    availabilityByTableId[tableId].id = tableId
    availabilityByTableId[tableId].name = ruleTable.name
    availabilityByTableId[tableId].partySizeRange = ruleTable.party_size_range
    availabilityByTableId[tableId].primaryRule.id = ruleTable.id
    availabilityByTableId[tableId].primaryRule.name = ruleTable.hold_name
    availabilityByTableId[tableId].primaryRule.isHold = ruleTable.is_hold
  }
  // Table size too large if no auto assign res exists
  for (const tableTooLarge of availability.table_size_too_large) {
    const tableId = tableTooLarge.id
    if (!(tableId in availabilityByTableId)) {
      availabilityByTableId[tableId] = createTableDict()
    }
    availabilityByTableId[tableId].id = tableId
    availabilityByTableId[tableId].name = tableTooLarge.name
    availabilityByTableId[tableId].partySizeRange = tableTooLarge.party_size_range
    availabilityByTableId[tableId].tooLarge = true
  }
  // Table size too small if no auto assign res exists
  for (const tableTooSmall of availability.table_size_too_small) {
    const tableId = tableTooSmall.id
    if (!(tableId in availabilityByTableId)) {
      availabilityByTableId[tableId] = createTableDict()
    }
    availabilityByTableId[tableId].id = tableId
    availabilityByTableId[tableId].name = tableTooSmall.name
    availabilityByTableId[tableId].partySizeRange = tableTooSmall.party_size_range
    availabilityByTableId[tableId].tooSmall = true
  }
  // Recommended tables
  for (const table of availability.recommended) {
    const tableId = table.id
    if (!(tableId in availabilityByTableId)) {
      availabilityByTableId[tableId] = createTableDict()
    }
    availabilityByTableId[tableId].id = tableId
    availabilityByTableId[tableId].name = table.name
    availabilityByTableId[tableId].partySizeRange = table.party_size_range
    availabilityByTableId[tableId].recommended = true
  }
  // disperse combos to tables
  for (const potentialComboId in availabilityByTableId) {
    // combos have commas in the table ID
    const tableIds = potentialComboId.split(',')
    if (tableIds.length > 1) {
      const combo = availabilityByTableId[potentialComboId]
      for (const tableId of tableIds) {
        const table = availabilityByTableId[tableId]
        if (combo.recommended) {
          table.recommendedCombos.push(combo)
        } else if (combo.tooLarge || combo.tooSmall) {
          table.notRecommendedCombos.push(combo)
        }
      }
    }
  }

  if (!availability.useSimpleMoveMode) {
    // assign unlocked ressies to be recommended if the table fits
    for (const tableId in availabilityByTableId) {
      const avail = availabilityByTableId[tableId]
      if (
        avail.isLocked ||
        avail.tooSmall ||
        avail.tooLarge ||
        avail.primaryBlock?.isBlocked ||
        avail.primaryRule?.isHold ||
        avail.recommended
      ) {
        continue
      }
      avail.willCauseProblems = true
    }
  }

  return availabilityByTableId
}
