import { UploadField } from '@navjobs/upload'
import React from 'react'
import styled from 'styled-components'
import { NoDecorationListItem, LegacyPaddedUl } from 'mgr/actualslideout/components/view/ViewCommon'
import { FlexColumnContainer, FlexRowContainer } from 'mgr/actualslideout/components/view/ViewLayout'
import { getBlobSrc } from 'mgr/lib/utils/Blob'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'

const AttachmentListEntry = styled(NoDecorationListItem)`
  color: ${props => props.theme.primary};
  font-size: 15px;
  font-weight: 900;
  line-height: 15px;
  list-style: none;
  width: 100%;
`

const AttachmentLink = styled.a`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.darkGrey};
  font-size: 12px;
  ${props => props.theme.ellipsis};
`

const LengthLimitedDiv = styled.div`
  width: ${props => props.width};
  display: inline-block;
  ${props => props.theme.ellipsis};
`
const RemoveAttachmentLink = styled.a`
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.theme.error};
  font-size: 12px;
  width: 10%;
`

const AttachmentList = ({ attachments, onAddAttachment, onDeleteAttachment }) => {
  const attachmentLinks = attachments.map(attachment => (
    <AttachmentListEntry key={attachment.blobKey}>
      <LengthLimitedDiv width="87%">
        <AttachmentLink href={getBlobSrc(attachment.urlKey)} target="_blank">
          {attachment.fileName}
        </AttachmentLink>
      </LengthLimitedDiv>
      <LengthLimitedDiv width="13%">
        <RemoveAttachmentLink onClick={() => onDeleteAttachment(attachment.blobKey)}>
          <StyledVmsIconXS>{VmsIcons.Trash}</StyledVmsIconXS>
        </RemoveAttachmentLink>
      </LengthLimitedDiv>
    </AttachmentListEntry>
  ))

  return (
    <div>
      <UploadField onFiles={onAddAttachment}>
        <AttachmentLink>
          <StyledVmsIconXS style={{ marginRight: '2px' }}>{VmsIcons.Attachment}</StyledVmsIconXS>
          <strong>Add</strong>
          Attachment
        </AttachmentLink>
      </UploadField>
      <LegacyPaddedUl>{attachmentLinks}</LegacyPaddedUl>
    </div>
  )
}

export default AttachmentList
