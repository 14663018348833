import _ from 'lodash'
import * as ActionTypes from 'mgr/actualslideout/actions/ActionTypes'
import { genericTagToDisplayTag } from 'mgr/lib/components/GenericTagsDropDown'

const initialState = {
  searchTerms: '',
  sourceLookupResults: [],
  isSourceLookupLoading: false,
  isSourceLookupLoadError: false,
  isSourceHydrateLoading: false,
  isSourceHydrateLoadError: false,
  selectedSource: null,
  sourceHistory: [],
  sourceTagGroupsByVenue: {},
}

const resetSourceLookupNewState = (isLoading, isError) => ({
  sourceLookupResults: [],
  isSourceLookupLoading: isLoading,
  isSourceLookupLoadError: isError,
})
const resetSourceHydrateNewState = (isLoading, isError) => ({
  isSourceHydrateLoading: isLoading,
  isSourceHydrateLoadError: isError,
})

const bookSourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ENTER_ADD_RESERVATION:
      return { ...state, ...initialState }
    case ActionTypes.COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS: {
      // View/edit
      const { actual } = action
      const selectedSource = actual.source_client
      // Map from display tags to generic tags dropdown control
      if (_.isObject(selectedSource)) {
        selectedSource.client_tags = selectedSource.tags_display.map(genericTagToDisplayTag)
      }
      return { ...state, ...initialState, selectedSource }
    }
    case ActionTypes.BOOK_SOURCE_CHANGE_SEARCH_TERMS: {
      return {
        ...state,
        searchTerms: action.searchTerms,
      }
    }
    case ActionTypes.BOOK_SOURCE_GET_SOURCE_HYDRATED_START: {
      const [isLoading, isError] = [true, false]
      return { ...state, ...resetSourceHydrateNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_SOURCE_GET_SOURCE_HYDRATED_FAIL: {
      const [isLoading, isError] = [false, true]
      return { ...state, ...resetSourceHydrateNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_SOURCE_GET_SOURCE_HYDRATED_SUCCESS: {
      const [isLoading, isError] = [false, false]
      return { ...state, ...resetSourceHydrateNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_SOURCE_CHANGE_SELECTED_SOURCE: {
      const selectedSource = _.isObject(action.selectedSource) ? { ...action.selectedSource } : null
      if (selectedSource) {
        // Map from display tags to generic tags dropdown control
        selectedSource.client_tags = selectedSource.tags_display.map(genericTagToDisplayTag)
      }
      const sourceHistory = action.clientHistory || []
      return { ...state, selectedSource, sourceHistory }
    }
    case ActionTypes.BOOK_SOURCE_GET_SOURCE_LOOKUP_START: {
      const [isLoading, isError] = [true, false]
      return { ...state, ...resetSourceLookupNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_SOURCE_GET_SOURCE_LOOKUP_FAIL: {
      const [isLoading, isError] = [false, true]
      return { ...state, ...resetSourceLookupNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_SOURCE_GET_SOURCE_LOOKUP_SUCCESS: {
      return {
        ...state,
        sourceLookupResults: action.clientResults,
        isSourceLookupLoading: false,
        isSourceLookupLoadError: false,
      }
    }
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_SUCCESS: {
      const { venueId, tagGroups } = action
      const newState = { ...state }
      newState.sourceTagGroupsByVenue = { ...state.sourceTagGroupsByVenue }
      newState.sourceTagGroupsByVenue[venueId] = tagGroups
      return newState
    }
    case ActionTypes.BOOK_SOURCE_CHANGE_SOURCE_FIELD: {
      const selectedSource = {
        ...state.selectedSource,
        [action.field]: action.value,
      }
      selectedSource.name_display = _.compact([selectedSource.salutation, selectedSource.first_name, selectedSource.last_name]).join(' ')
      return { ...state, selectedSource }
    }
    case ActionTypes.BOOK_SOURCE_CHANGE_CUSTOM_UNINDEXED_FIELD: {
      const selectedSource = {
        ...state.selectedSource,
        custom_unindexed: {
          ...state.selectedSource.custom_unindexed,
          [action.system_name]: action.value,
        },
      }
      return { ...state, selectedSource }
    }
    case ActionTypes.BOOK_SOURCE_CHANGE_SOURCE_TAGS: {
      const selectedSource = { ...state.selectedSource }
      selectedSource.client_tags = action.value
      // Map from generic tags dropdown control to display tags
      selectedSource.tags_display = action.value.map(({ tagName, tagHash, tagGroup }) => {
        const tag_name = tagName
        const tag_group_id = tagGroup.id
        const tag_color = tagGroup.color_hex
        return { tag_group_id, tag_name, tag_color }
      })
      return { ...state, selectedSource }
    }
    case ActionTypes.BOOK_SOURCE_TOGGLE_SHOW_MORE_SOURCE_FIELDS:
      return { ...state, showMoreSourceFields: !state.showMoreSourceFields }
    default:
      return state
  }
}

export default bookSourceReducer
