import Theme from 'mgr/layout/Theme'

export const sumPx = (...args) => `${args.reduce((accum, current) => (accum += parseFloat(current)), 0)}px`

export const getStarColor = numStars => {
  if (numStars > 3) {
    return Theme.color.positive
  } else if (numStars > 2 && numStars < 4) {
    return Theme.color.neutral
  }
  return Theme.color.negative
}
