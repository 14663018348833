import type { GuestFacingCategoryUpgrade } from '@sevenrooms/core/domain'
import { useWatch, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { Category } from './Category'
import { upgradesMessages } from './Upgrades.locales'
import type { UpgradesForm } from './Upgrades.zod'

export interface UpgradesProps {
  field: Field<UpgradesForm>
  currencyCode: string
  partySize: number | undefined
  categories: GuestFacingCategoryUpgrade[]
  categoriesPreselected: GuestFacingCategoryUpgrade[]
  hasRequiredCategories: boolean
}

export function Upgrades({ field, currencyCode, partySize, categories, categoriesPreselected, hasRequiredCategories }: UpgradesProps) {
  const { formatMessage } = useLocales()

  const overrideValue = useWatch(field.prop('override'))

  return (
    <VStack spacing="m">
      {categoriesPreselected.length > 0 && (
        <VStack borderRadius="s" borderColor="warning">
          <VStack backgroundColor="warningBackground" p="sm m" spacing="xs">
            <Text fontSize="l" fontWeight="bold">
              {formatMessage(upgradesMessages.titlePreselected)}
            </Text>
            <Text>{formatMessage(upgradesMessages.subTitlePreselected)}</Text>
          </VStack>
          {categoriesPreselected.map(category => (
            <Category
              key={category.id}
              field={field.prop(`categoriesPreselected.${category.id}`)}
              category={category}
              currencyCode={currencyCode}
              partySize={partySize}
              isOverride
            />
          ))}
        </VStack>
      )}
      {hasRequiredCategories && (
        <Box>
          <Checkbox field={field.prop('override')}>{formatMessage(upgradesMessages.overrideLabel)}</Checkbox>
        </Box>
      )}
      {categories.length > 0 && (
        <VStack borderRadius="s" borderColor="borders">
          <Box backgroundColor="dividerLines" p="sm m">
            <Text fontSize="l" fontWeight="bold">
              {formatMessage(upgradesMessages.title)}
            </Text>
          </Box>
          {categories.map(category => (
            <Category
              key={category.id}
              field={field.prop(`categories.${category.id}`)}
              category={category}
              currencyCode={currencyCode}
              partySize={partySize}
              isOverride={overrideValue}
            />
          ))}
        </VStack>
      )}
    </VStack>
  )
}
