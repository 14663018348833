import { useMemo } from 'react'
import { UpgradesMaxQuantities, UpgradesMinQuantities } from '@sevenrooms/core/api'
import type { GuestFacingCategoryUpgrade } from '@sevenrooms/core/domain'
import { z } from '@sevenrooms/core/form'

export type UpgradesForm = z.infer<ReturnType<typeof useUpgradesForm>>

export const useUpgradesForm = () => {
  const categoryForm = useCategoryForm()
  return useMemo(
    () =>
      z.object({
        override: z.boolean(),
        categoriesPreselected: z.record(categoryForm),
        categories: z.record(
          categoryForm.superRefine(({ minType, minAmount, upgrades }, ctx) => {
            const upgradesSum = Object.values(upgrades ?? {}).reduce((acc, val) => acc + val, 0)
            if (minType !== 'NONE_REQUIRED' && upgradesSum < minAmount) {
              ctx.addIssue({ code: z.ZodIssueCode.custom })
            }
          })
        ),
      }),
    [categoryForm]
  )
}

const useCategoryForm = () =>
  useMemo(
    () =>
      z.object({
        minType: z.enum(UpgradesMinQuantities),
        minAmount: z.number(),
        maxType: z.enum(UpgradesMaxQuantities),
        maxAmount: z.number(),
        upgrades: z.record(z.number().nonnegative().default(0)),
      }),
    []
  )

interface getDefaultValuesParams {
  partySize?: number
  categories: GuestFacingCategoryUpgrade[]
  categoriesPreselected?: GuestFacingCategoryUpgrade[]
  inventoryPreselected?: Record<string, { quantity: number }>
  override?: boolean
}

export function useDefaultValues({ partySize, categories, categoriesPreselected, inventoryPreselected, override }: getDefaultValuesParams) {
  return useMemo(
    () => ({
      override: override ?? false,
      categories: Object.fromEntries(fromCategoriesToForm({ partySize, categories, inventoryPreselected }) ?? []),
      categoriesPreselected: Object.fromEntries(
        fromCategoriesToForm({ partySize, categories: categoriesPreselected ?? [], inventoryPreselected }) ?? []
      ),
    }),
    [categories, categoriesPreselected, inventoryPreselected, override, partySize]
  )
}

function fromCategoriesToForm({ partySize, categories, inventoryPreselected = {} }: getDefaultValuesParams) {
  return categories.map(({ category, upgrades }) => [
    category.id,
    {
      minType: category.minQuantityType ?? 'NONE_REQUIRED',
      minAmount: category.minQuantityType === 'NUMBER_OF_GUESTS' ? partySize : category.minQuantityInt ?? 0,
      maxType: category.maxQuantityType ?? 'NONE_REQUIRED',
      maxAmount: category.maxQuantityType === 'NUMBER_OF_GUESTS' ? partySize : category.maxQuantityInt ?? 0,
      upgrades: Object.fromEntries(
        upgrades.map(upgrade => {
          const quantity = inventoryPreselected[upgrade.id]?.quantity ?? 0
          return [upgrade.id, quantity]
        })
      ),
    },
  ])
}
