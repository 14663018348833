import type { GuestFacingUpgrade, GuestFacingUpgradeCategory, ReservationWidget } from '@sevenrooms/core/domain'
import type { PaymentForm } from '@sevenrooms/mgr-reservation-slideout/Payment'
import { type Transaction, TransactionStatus, type UpgradeInventoryTransaction } from '../../reducers/BookPaymentSlice.types'

interface InitPreviousPaymentParams {
  allTransactions: Transaction[]
  upgrades: GuestFacingUpgrade
  taxGroups: ReservationWidget.TaxGroup[]
  oldPartySize: number | null
}

export function initPreviousPayment({
  allTransactions,
  upgrades,
  taxGroups,
  oldPartySize,
}: InitPreviousPaymentParams): PaymentForm | undefined {
  const categories: PaymentForm['categories'] = {}
  const categoriesBundled: PaymentForm['categoriesBundled'] = {}
  let amount = 0

  const transaction = allTransactions?.find(
    t =>
      t.upsell_items &&
      (t.transaction_type === 'transaction_type_internal' ||
        t.transaction_type === 'transaction_type_web' ||
        t.transaction_type === 'transaction_type_3d_secure_succeed' ||
        t.transaction_type === 'transaction_type_request_info' ||
        t.transaction_type === 'transaction_type_request_removed')
  )

  if (transaction) {
    let clientSelectGratuityCharge = transaction.client_select_gratuity ? Number(transaction.gratuity) : null
    const taxId = transaction.reservation_tax_group_id ?? taxGroups.find(group => group.taxRate === transaction.reservation_tax)?.id
    allTransactions.forEach((t, index) => {
      if (
        !t.is_adhoc &&
        (t.is_charge ||
          t.transaction_type === 'transaction_type_request_info' ||
          t.transaction_type === 'transaction_type_request_removed') &&
        t.status === TransactionStatus.SUCCEEDED
      ) {
        amount += t.transaction_type === 'transaction_type_request_removed' && index !== 0 ? 0 : t.reservation_base_price
      }
    })
    const charges: PaymentForm['charges'] = {
      gratuity: Number(transaction.gratuity),
      gratuityClientSelect: transaction.client_select_gratuity,
      requireGratuityCharge: transaction.require_select_gratuity,
      service: Number(transaction.service_charge),
      taxId: taxId ?? null,
      applyGratuity: !!transaction.gratuity,
      applyService: !!transaction.service_charge,
      applyTax: !!taxId,
    }
    const selectedUpgrades = Object.entries(transaction.upsell_items?.selected_upsells ?? {})
    const bundledUpgrades = Object.entries(transaction.upsell_items?.automatic_upsells ?? {})
    let isAnyCategoryGratuityClientSelect = false
    let isAnyCategoryRequiredGratuitycharge = false
    upgrades.categories.forEach(category => {
      const upgradesFiltered = selectedUpgrades.filter(([, upgrade]) => upgrade.category_id === category.id)
      const resultCategory = getCategoryForPreviousPayment({ partySize: null, upgrades: upgradesFiltered, category })
      if (resultCategory) {
        categories[category.id] = resultCategory
        if (clientSelectGratuityCharge === null && resultCategory.charges.gratuityClientSelect) {
          clientSelectGratuityCharge = resultCategory.charges.gratuity
        }
        isAnyCategoryGratuityClientSelect = isAnyCategoryGratuityClientSelect || resultCategory.charges.gratuityClientSelect
        isAnyCategoryRequiredGratuitycharge = isAnyCategoryRequiredGratuitycharge || resultCategory.charges.requireGratuityCharge
      }
      const bundledUpgradesFiltered = bundledUpgrades.filter(([, upgrade]) => upgrade.category_id === category.id)
      const resultCategoryBundled = getCategoryForPreviousPayment({ upgrades: bundledUpgradesFiltered, category, partySize: oldPartySize })
      if (resultCategoryBundled) {
        categoriesBundled[category.id] = resultCategoryBundled
        if (clientSelectGratuityCharge === null && resultCategoryBundled.charges.gratuityClientSelect) {
          clientSelectGratuityCharge = resultCategoryBundled.charges.gratuity
        }
        isAnyCategoryGratuityClientSelect = isAnyCategoryGratuityClientSelect || resultCategoryBundled.charges.gratuityClientSelect
        isAnyCategoryRequiredGratuitycharge = isAnyCategoryRequiredGratuitycharge || resultCategoryBundled.charges.requireGratuityCharge
      }
    })
    const clientSelectGratuity: PaymentForm['clientSelectGratuity'] = {
      gratuity: clientSelectGratuityCharge,
      gratuityClientSelect: transaction.client_select_gratuity || isAnyCategoryGratuityClientSelect,
      requireGratuityCharge: transaction.require_select_gratuity || isAnyCategoryRequiredGratuitycharge,
      applyGratuity: !!transaction.gratuity,
    }

    return { amount, charges, categories, categoriesBundled, clientSelectGratuity }
  }

  return undefined
}

export function initPreviousFulfiledPayment({
  allTransactions,
  upgrades,
  taxGroups,
  oldPartySize,
}: InitPreviousPaymentParams): PaymentForm | undefined {
  const categories: PaymentForm['categories'] = {}
  const categoriesBundled: PaymentForm['categoriesBundled'] = {}
  let amount = 0

  const transaction = allTransactions?.find(
    t =>
      t.upsell_items &&
      (t.transaction_type === 'transaction_type_internal' ||
        t.transaction_type === 'transaction_type_web' ||
        t.transaction_type === 'transaction_type_3d_secure_succeed')
  )

  if (transaction) {
    let clientSelectGratuityCharge = transaction.client_select_gratuity ? Number(transaction.gratuity) : null
    const taxId = transaction.reservation_tax_group_id ?? taxGroups.find(group => group.taxRate === transaction.reservation_tax)?.id
    amount = allTransactions
      .filter(
        t =>
          !t.is_adhoc && (t.is_charge || t.transaction_type === 'transaction_type_request_info') && t.status === TransactionStatus.SUCCEEDED
      )
      .reduce((acc, t) => acc + t.reservation_base_price, 0)
    const charges: PaymentForm['charges'] = {
      gratuity: Number(transaction.gratuity),
      gratuityClientSelect: transaction.client_select_gratuity,
      requireGratuityCharge: transaction.require_select_gratuity,
      service: Number(transaction.service_charge),
      taxId: taxId ?? null,
      applyGratuity: !!transaction.gratuity,
      applyService: !!transaction.service_charge,
      applyTax: !!taxId,
    }
    const selectedUpgrades = Object.entries(transaction.upsell_items?.selected_upsells ?? {})
    const bundledUpgrades = Object.entries(transaction.upsell_items?.automatic_upsells ?? {})
    let isAnyCategoryGratuityClientSelect = false
    let isAnyCategoryRequiredGratuitycharge = false
    upgrades.categories.forEach(category => {
      const upgradesFiltered = selectedUpgrades.filter(([, upgrade]) => upgrade.category_id === category.id)
      const resultCategory = getCategoryForPreviousPayment({ partySize: null, upgrades: upgradesFiltered, category })
      if (resultCategory) {
        categories[category.id] = resultCategory
        if (clientSelectGratuityCharge === null && resultCategory.charges.gratuityClientSelect) {
          clientSelectGratuityCharge = resultCategory.charges.gratuity
        }
        isAnyCategoryGratuityClientSelect = isAnyCategoryGratuityClientSelect || resultCategory.charges.gratuityClientSelect
        isAnyCategoryRequiredGratuitycharge = isAnyCategoryRequiredGratuitycharge || resultCategory.charges.requireGratuityCharge
      }
      const bundledUpgradesFiltered = bundledUpgrades.filter(([, upgrade]) => upgrade.category_id === category.id)
      const resultCategoryBundled = getCategoryForPreviousPayment({ upgrades: bundledUpgradesFiltered, category, partySize: oldPartySize })
      if (resultCategoryBundled) {
        categoriesBundled[category.id] = resultCategoryBundled
        if (clientSelectGratuityCharge === null && resultCategoryBundled.charges.gratuityClientSelect) {
          clientSelectGratuityCharge = resultCategoryBundled.charges.gratuity
        }
        isAnyCategoryGratuityClientSelect = isAnyCategoryGratuityClientSelect || resultCategoryBundled.charges.gratuityClientSelect
        isAnyCategoryRequiredGratuitycharge = isAnyCategoryRequiredGratuitycharge || resultCategoryBundled.charges.requireGratuityCharge
      }
    })
    const clientSelectGratuity: PaymentForm['clientSelectGratuity'] = {
      gratuity: clientSelectGratuityCharge,
      gratuityClientSelect: transaction.client_select_gratuity || isAnyCategoryGratuityClientSelect,
      requireGratuityCharge: transaction.require_select_gratuity || isAnyCategoryRequiredGratuitycharge,
      applyGratuity: !!transaction.gratuity,
    }

    return { amount, charges, categories, categoriesBundled, clientSelectGratuity }
  }

  return undefined
}

interface GetCategoryForPreviousPaymentParams {
  upgrades: [string, UpgradeInventoryTransaction][]
  category: GuestFacingUpgradeCategory
  partySize: number | null
}

function getCategoryForPreviousPayment({ upgrades, category, partySize }: GetCategoryForPreviousPaymentParams) {
  return upgrades[0]
    ? {
        name: category.name,
        upgrades: Object.fromEntries(
          upgrades.map(([id, upgrade]) => {
            const count =
              upgrade.quantity_equal_type === 'PARTY_SIZE' && partySize ? partySize : upgrade?.quantity ?? upgrade?.quantity_num ?? 0
            return [id, { name: upgrade.name, count, amount: upgrade.price * count }]
          })
        ),
        charges: {
          gratuity: upgrades[0][1].gratuity,
          service: upgrades[0][1].service_charge_percentage,
          taxId: upgrades[0][1].tax_group_id,
          applyGratuity: !!upgrades[0][1].gratuity,
          applyService: !!upgrades[0][1].service_charge_percentage,
          applyTax: !!upgrades[0][1].tax_group_id,
          gratuityClientSelect: category.gratuityChargeType === 'CLIENT_GRATUITY',
          requireGratuityCharge: category.requireGratuityCharge,
        },
      }
    : undefined
}
