import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import styled from 'styled-components'
import { SingleStarRow, Thumb } from 'mgr/pages/single-venue/marketing/components/guest-satisfaction/StarsData'
import { SourceBar, RatingBar, NumRating, StarSlide, ReviewInfo, ReviewContent } from 'mgr/pages/single-venue/marketing/components/Review'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { Icon } from '@sevenrooms/core/ui-kit/icons'

const CardReviewArea = styled.div`
  background: #ffffff;
  height: 100%;
  overflow: scroll;
  margin: 0 -15px;
`
const CardReviewContent = styled(ReviewContent)`
  padding-bottom: 0;
`

const ToggleLink = styled.div`
  color: ${props => props.theme.primary};
  cursor: pointer;
  font-size: 0.7em;
  font-weight: bold;
  margin: 10px 0 0;
  text-align: center;
  text-transform: uppercase;
`

export class ReviewCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      needsToggle: false,
      expanded: false,
    }
    this.toggleExpand = this.toggleExpand.bind(this)
    this.handleReflow = this.handleReflow.bind(this)

    this.clampedText = (
      <LinesEllipsis text={props.review.notes || ''} maxLine={6} ellipsis=" ..." onReflow={this.handleReflow} trimRight basedOn="words" />
    )
  }

  toggleExpand() {
    this.setState(state => ({ expanded: !state.expanded }))
  }

  handleReflow(reflow) {
    this.setState(state => ({ needsToggle: reflow.clamped }))
  }

  render() {
    const { review, venueName, unlink } = this.props

    const { author, rating, review_site: reviewSite, notes: reviewText } = review
    const reviewDate = moment(review.date).format('ddd, MMM DD')

    return (
      <CardReviewArea>
        <SourceBar reviewSite={reviewSite} isLinked unlink={() => unlink(review.id)} />
        {reviewSite === 'facebook' ? (
          <RatingBar>
            <StarSlide>
              <Thumb starsChecked={rating} />
            </StarSlide>
          </RatingBar>
        ) : (
          <RatingBar>
            <StarSlide>
              <NumRating>{rating} of 5</NumRating>
              <SingleStarRow numStars={5} starsChecked={rating} />
            </StarSlide>
          </RatingBar>
        )}

        <ReviewInfo>
          Review for {venueName} submitted on {reviewDate} by {author}
        </ReviewInfo>
        <CardReviewContent>
          {this.state.expanded ? (
            <>
              {reviewText}
              {review.reply && (
                <Box ml="m" mt="m" backgroundColor="infoBackground" p="m">
                  <Text fontSize="xs" color="description">
                    <Icon name="VMSWeb-reply" />
                    Replied on {moment(review.reply.update_time).format('ddd, MMM DD')}
                  </Text>
                  <Box data-test="google-review-reply">
                    <Text textStyle="body2">{review.reply.comment}</Text>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            this.clampedText
          )}
          {this.state.needsToggle || review.reply?.comment ? (
            <ToggleLink onClick={this.toggleExpand}>Show {this.state.expanded ? 'less' : 'more'}</ToggleLink>
          ) : null}
        </CardReviewContent>
      </CardReviewArea>
    )
  }
}
