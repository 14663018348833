import { defineMessages } from '@sevenrooms/core/locales'

export const ActualSlideoutLocales = defineMessages({
  missingEmailConfirmationTitle: {
    id: 'actual.missingEmailConfirmationTitle',
    defaultMessage: 'Client Email Missing',
  },
  missingEmailConfirmationBody: {
    id: 'actual.missingEmailConfirmationBody',
    defaultMessage:
      'Without an email address, the Paylink cannot be sent automatically. Please add a Client Email or send the Paylink separately.',
  },
  missingEmailConfirmationSubmit: {
    id: 'actual.missingEmailConfirmationSubmit',
    defaultMessage: 'Book Without Email',
  },
} as const)
