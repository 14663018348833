export const LOAD_REVIEWS_START = 'LOAD_REVIEWS_START'
export const LOAD_REVIEWS_SUCCESS = 'LOAD_REVIEWS_SUCCESS'
export const LOAD_REVIEWS_FAIL = 'LOAD_REVIEWS_FAIL'
export const LOAD_MORE_REVIEWS = 'LOAD_MORE_REVIEWS'
export const TRIGGER_GUEST_SATISFACTION_EXPORT = 'TRIGGER_GUEST_SATISFACTION_EXPORT'
export const TRIGGER_GUEST_SATISFACTION_EXPORT_SUCCESS = 'TRIGGER_GUEST_SATISFACTION_EXPORT_SUCCESS'
export const TRIGGER_GUEST_SATISFACTION_EXPORT_FAIL = 'TRIGGER_GUEST_SATISFACTION_EXPORT_FAIL'

export const FILTER_REVIEWS = 'FILTER_REVIEWS'
export const OPEN_REVIEW = 'OPEN_REVIEW'
export const OPEN_LINKING_SEARCH = 'OPEN_LINKING_SEARCH'
export const LINK_REVIEW = 'LINK_REVIEW'
export const UNLINK_REVIEW = 'UNLINK_REVIEW'
export const SET_REVIEW_REPLY = 'ADD_REVIEW_REPLY'

export const DATE_FILTER_CHANGE = 'DATE_FILTER_CHANGE'
export const PLATFORM_FILTER_CHANGE = 'PLATFORM_FILTER_CHANGE'
export const RATING_FILTER_CHANGE = 'RATING_FILTER_CHANGE'
export const FEEDBACK_TYPE_FILTER_CHANGE = 'FEEDBACK_TYPE_FILTER_CHANGE'
export const SEARCH_FILTER_CHANGE = 'SEARCH_FILTER_CHANGE'
export const REVIEWS_PAGINATION_CHANGE = 'REVIEWS_PAGINATION_CHANGE'
export const REVIEWS_SORT_CHANGE = 'REVIEWS_SORT_CHANGE'
export const CUSTOM_RANGE_CHANGE = 'CUSTOM_RANGE_CHANGE'

export const LOAD_AUTOTAGS_LIST_START = 'LOAD_AUTOTAGS_LIST_START'
export const LOAD_AUTOTAGS_LIST_SUCCESS = 'LOAD_AUTOTAGS_LIST_SUCCESS'
export const LOAD_AUTOTAGS_ACTIVITY_REPORT_START = 'LOAD_AUTOTAGS_ACTIVITY_REPORT_START'
export const LOAD_AUTOTAGS_ACTIVITY_REPORT_SUCCESS = 'LOAD_AUTOTAGS_ACTIVITY_REPORT_SUCCESS'
export const LOAD_AUTOTAGS_START = 'LOAD_AUTOTAGS_START'
export const LOAD_AUTOTAGS_SUCCESS = 'LOAD_AUTOTAGS_SUCCESS'

export const LOAD_AUTOTAG_START = 'LOAD_AUTOTAG_START'
export const LOAD_AUTOTAG_FAIL = 'LOAD_AUTOTAG_FAIL'
export const LOAD_AUTOTAG_SUCCESS = 'LOAD_AUTOTAG_SUCCESS'

export const LOAD_CUSTOM_AUTOTAGS_OBJECTS_START = 'LOAD_CUSTOM_AUTOTAGS_OBJECTS_START'
export const LOAD_CUSTOM_AUTOTAGS_OBJECTS_SUCCESS = 'LOAD_CUSTOM_AUTOTAGS_OBJECTS_SUCCESS'

export const OPEN_AUTOTAG = 'OPEN_AUTOTAG'

export const SAVE_AUTOTAG_START = 'SAVE_TAG_START'
export const SAVE_AUTOTAG_SUCCESS = 'SAVE_TAG_SUCCESS'
export const SAVE_AUTOTAG_FAIL = 'SAVE_TAG_FAIL'

export const DELETE_AUTOTAG_START = 'DELETE_TAG_START'
export const DELETE_AUTOTAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_AUTOTAG_FAIL = 'DELETE_TAG_FAIL'

export const UPDATE_AUTOTAG_CONFIG = 'UPDATE_AUTOTAG_CONFIG'

export const TOGGLE_TAG_ACTIVE = 'TOGGLE_TAG_ACTIVE'
export const TOGGLE_TIER_ACTIVE = 'TOGGLE_TIER_ACTIVE'
export const SELECT_TIER = 'SELECT_TIER'
export const UPDATE_TIER = 'UPDATE_TIER'
export const UPDATE_TIER_ATTRIBUTE = 'UPDATE_TIER_ATTRIBUTE'
export const UPDATE_TIER_CUSTOM_CONFIG = 'UPDATE_TIER_CUSTOM_CONFIG'
export const REMOVE_TIER = 'REMOVE_TIER'
export const ADD_TIER = 'ADD_TIER'
export const TOGGLE_DELETE_WARNING = 'TIER_DELETE_WARNING'
export const SET_TIER_ERROR = 'SET_TIER_ERROR'
export const REMOVE_INITIAL_TIER = 'REMOVE_INITIAL_TIER'
export const UPDATE_LOCKED_TIER_ATTRIBUTES = 'UPDATE_LOCKED_TIER_ATTRIBUTES'
export const UPDATE_AUTOTAG_PERKS_BY_TAG = 'UPDATE_AUTOTAG_PERKS_BY_TAG'
export const UPDATE_AUTOTAG_NOTES_BY_TAG = 'UPDATE_AUTOTAG_NOTES_BY_TAG'

export const UPDATE_EMAIL_WIZARD_STEP = 'UPDATE_EMAIL_WIZARD_STEP'
export const TOGGLE_EMAIL_WIZARD = 'TOGGLE_EMAIL_WIZARD'
export const SET_EMAIL_WIZARD_TEST_EMAILS = 'SET_EMAIL_WIZARD_TEST_EMAILS'
export const SEND_TEST_EMAIL_START = 'SEND_TEST_EMAIL_START'
export const SEND_TEST_EMAIL_SUCCESS = 'SEND_TEST_EMAIL_SUCCESS'
export const SEND_TEST_EMAIL_FAIL = 'SEND_TEST_EMAIL_FAIL'

export const TOGGLE_DEACTIVATE_CAMPAIGN = 'TOGGLE_DEACTIVATE_CAMPAIGN'
export const DEACTIVATE_CAMPAIGN_START = 'DEACTIVATE_CAMPAIGN_START'
export const DEACTIVATE_CAMPAIGN_SUCCESS = 'DEACTIVATE_CAMPAIGN_SUCCESS'
export const DEACTIVATE_CAMPAIGN_FAIL = 'DEACTIVATE_CAMPAIGN_FAIL'

export const TOGGLE_DELETE_CAMPAIGN = 'TOGGLE_DELETE_CAMPAIGN'
export const DELETE_CAMPAIGN_START = 'DELETE_CAMPAIGN_START'
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS'
export const DELETE_CAMPAIGN_FAIL = 'DELETE_CAMPAIGN_FAIL'

export const SAVE_EMAIL_CAMPAIGN_START = 'SAVE_EMAIL_CAMPAIGN_START'
export const SAVE_EMAIL_CAMPAIGN_SUCCESS = 'SAVE_EMAIL_CAMPAIGN_SUCCESS'
export const SAVE_EMAIL_CAMPAIGN_FAIL = 'SAVE_EMAIL_CAMPAIGN_FAIL '
export const SAVE_AND_SCHEDULE_EMAIL_CAMPAIGN_START = 'SAVE_AND_SCHEDULE_EMAIL_CAMPAIGN_START'
export const SAVE_AND_SCHEDULE_EMAIL_CAMPAIGN_SUCCESS = 'SAVE_AND_SCHEDULE_EMAIL_CAMPAIGN_SUCCESS'
export const UPDATE_WIZARD_CAMPAIGN_ATTRIBUTE = 'UPDATE_WIZARD_CAMPAIGN_ATTRIBUTE'
export const SET_EMAIL_WIZARD_VALIDATOR = 'SET_EMAIL_WIZARD_VALIDATOR'
export const SET_EMAIL_WIZARD_COMPLETE = 'SET_EMAIL_WIZARD_COMPLETE'
export const TOGGLE_EMAIL_CONFIRMATION_MODAL = 'TOGGLE_EMAIL_CONFIRMATION_MODAL'

export const LOAD_EMAIL_CENTER_START = 'LOAD_EMAIL_CENTER_START'
export const CAMPAIGN_IDS_FILTER_CHANGE = 'CAMPAIGN_IDS_FILTER_CHANGE'
export const AUTO_TAGS_FULL_LIST_SUCCESS = 'AUTO_TAGS_FULL_LIST_SUCCESS'
export const LOAD_EMAIL_CAMPAIGN_ACTIVITY_START = 'LOAD_EMAIL_CAMPAIGN_ACTIVITY_START'
export const LOAD_EMAIL_CAMPAIGN_ACTIVITY_SUCCESS = 'LOAD_EMAIL_CAMPAIGN_ACTIVITY_SUCCESS'
export const LOAD_EMAIL_CAMPAIGN_ACTIVITY_FAIL = 'LOAD_EMAIL_CAMPAIGN_ACTIVITY_FAIL'
export const LOAD_EMAILS_START = 'LOAD_EMAILS_START'
export const LOAD_EMAILS_SUCCESS = 'LOAD_EMAILS_SUCCESS'
export const LOAD_EMAILS_FAIL = 'LOAD_EMAILS_FAIL'
export const LOAD_EMAIL_CAMPAIGNS_START = 'LOAD_EMAIL_CAMPAIGNS_START'
export const LOAD_EMAIL_CAMPAIGNS_SUCCESS = 'LOAD_EMAIL_CAMPAIGNS_SUCCESS'
export const LOAD_CAMPAIGN_TO_WIZARD = 'LOAD_CAMPAIGN_TO_WIZARD'

export const CAMPAIGN_SORT_CHANGE = 'CAMPAIGN_SORT_CHANGE'

export const LOAD_EXPERIENCES_START = 'LOAD_EXPERIENCES_START'
export const LOAD_EXPERIENCES_SUCCESS = 'LOAD_EXPERIENCES_SUCCESS'
export const LOAD_EXPERIENCES_FAIL = 'LOAD_EXPERIENCES_FAIL'
export const TOGGLE_EXPERIENCE_LINK_MODAL = 'TOGGLE_EXPERIENCE_LINK_MODAL'

export const UPDATE_EXPERIENCE_WIZARD_STEP = 'UPDATE_EXPERIENCE_WIZARD_STEP'
export const LOAD_EXPERIENCE_TO_WIZARD = 'LOAD_EXPERIENCE_TO_WIZARD'
export const TOGGLE_EXPERIENCE_WIZARD = 'TOGGLE_EXPERIENCE_WIZARD'
export const UPDATE_EXPERIENCE_WIZARD_ATTRIBUTE = 'UPDATE_EXPERIENCE_WIZARD_ATTRIBUTE'
export const UPDATE_EXPERIENCE_THEFORK_MENU_COURSE = 'UPDATE_EXPERIENCE_THEFORK_MENU_COURSE'
export const UPDATE_EXPERIENCE_THEFORK_CUSTOM_MENU_TITLE = 'UPDATE_EXPERIENCE_THEFORK_CUSTOM_MENU_TITLE'
export const UPDATE_EXPERIENCE_THEFORK_MENU_ITEM = 'UPDATE_EXPERIENCE_THEFORK_MENU_ITEM'
export const ADD_EXPERIENCE_THEFORK_MENU_COURSE = 'ADD_EXPERIENCE_THEFORK_MENU_COURSE'
export const REMOVE_EXPERIENCE_THEFORK_MENU_COURSE = 'REMOVE_EXPERIENCE_THEFORK_MENU_COURSE'
export const ADD_EXPERIENCE_THEFORK_MENU_ITEM = 'ADD_EXPERIENCE_THEFORK_MENU_ITEM'
export const REMOVE_EXPERIENCE_THEFORK_MENU_ITEM = 'REMOVE_EXPERIENCE_THEFORK_MENU_ITEM'
export const SET_EXPERIENCE_WIZARD_VALIDATOR = 'SET_EXPERIENCE_WIZARD_VALIDATOR'
export const SET_EXPERIENCE_WIZARD_COMPLETE = 'SET_EXPERIENCE_WIZARD_COMPLETE'
export const SET_EXPERIENCE_ACTIVE = 'SET_EXPERIENCE_ACTIVE'
export const SAVE_EXPERIENCE_START = 'SAVE_EXPERIENCE_START'
export const SAVE_EXPERIENCE_SUCCESS = 'SAVE_EXPERIENCE_SUCCESS'
export const SAVE_EXPERIENCE_FAIL = 'SAVE_EXPERIENCE_FAIL'
export const TOGGLE_DEACTIVATE_EXPERIENCE = 'TOGGLE_DEACTIVATE_EXPERIENCE'
export const DEACTIVATE_EXPERIENCE_START = 'DEACTIVATE_EXPERIENCE_START'
export const DEACTIVATE_EXPERIENCE_SUCCESS = 'DEACTIVATE_EXPERIENCE_SUCCESS'
export const DEACTIVATE_EXPERIENCE_FAIL = 'DEACTIVATE_EXPERIENCE_FAIL'
export const TOGGLE_DELETE_EXPERIENCE = 'TOGGLE_DELETE_EXPERIENCE'
export const DELETE_EXPERIENCE_START = 'DELETE_EXPERIENCE_START'
export const DELETE_EXPERIENCE_SUCCESS = 'DELETE_EXPERIENCE_SUCCESS'
export const DELETE_EXPERIENCE_FAIL = 'DELETE_EXPERIENCE_FAIL'
export const UPLOAD_MENU_SUCCESS = 'UPLOAD_MENU_SUCCESS'
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS'
export const UPLOAD_EXPERIENCE_PHOTO_SUCCESS = 'UPLOAD_EXPERIENCE_PHOTO_SUCCESS'
export const CROPPING = 'CROPPING'
export const CROP_PHOTO = 'CROP_PHOTO'
export const EDIT_PHOTO = 'EDIT_PHOTO'
export const DELETE_PHOTO = 'DELETE_PHOTO'
export const SELECT_EXPERIENCE_TO_LINK = 'SELECT_EXPERIENCE_TO_LINK'
export const TOGGLE_EMOJI_PICKER = 'TOGGLE_EMOJI_PICKER'
export const UPDATE_EMAIL_SUBJECT_CURSOR = 'UPDATE_EMAIL_SUBJECT_CURSOR'
export const TOGGLE_CUSTOM_AUTOTAG_SLIDEOUT = 'TOGGLE_CUSTOM_AUTOTAG_SLIDEOUT'
export const SAVE_CUSTOM_AUTOTAG_START = 'SAVE_CUSTOM_AUTOTAG_START'
export const SET_ONE_TIME_CAMPAIGN_PARAMS = 'SET_ONE_TIME_CAMPAIGN_PARAMS'

export const LOAD_LANDING_PAGE_DATA_START = 'LOAD_LANDING_PAGE_DATA_START'
export const LOAD_LANDING_PAGE_DATA_SUCCESS = 'LOAD_LANDING_PAGE_DATA_SUCCESS'
export const SAVE_LANDING_PAGE_DATA_START = 'SAVE_LANDING_PAGE_DATA_START'
export const SAVE_LANDING_PAGE_DATA_SUCCESS = 'SAVE_LANDING_PAGE_DATA_SUCCESS'
export const SAVE_LANDING_PAGE_DATA_FAIL = 'SAVE_LANDING_PAGE_DATA_FAIL'
export const LANDING_PAGE_ADD_BUTTON = 'LANDING_PAGE_ADD_BUTTON'
export const TOGGLE_LANDING_PAGE_SLIDEOUT = 'TOGGLE_LANDING_PAGE_SLIDEOUT'
export const TOGGLE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT = 'TOGGLE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT'
export const TOGGLE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT_FROM_RADIO_BUTTON = 'TOGGLE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT_FROM_RADIO_BUTTON'
export const UPDATE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT = 'UPDATE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT'
export const UPDATE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT_AND_TOGGLE = 'UPDATE_LANDING_PAGE_HEADER_TEXT_SLIDEOUT_AND_TOGGLE'
export const TOGGLE_SETTING = 'TOGGLE_SETTING'
export const REMOVE_LANDING_PAGE_BUTTON = 'REMOVE_LANDING_PAGE_BUTTON'
export const TOGGLE_LANDING_PAGE_SLIDEOUT_EDIT_BUTTON = 'TOGGLE_LANDING_PAGE_SLIDEOUT_EDIT_BUTTON'
export const EDIT_LANDING_PAGE_BUTTON = 'EDIT_LANDING_PAGE_BUTTON'
export const REORDER_LANDING_PAGE_BUTTONS = 'REORDER_LANDING_PAGE_BUTTONS'
export const TOGGLE_AUTOTAG_DEACTIVATE_WARNING = 'TOGGLE_AUTOTAG_DEACTIVATE_WARNING'
export const UPDATE_AUTOTAG_DEACTIVATE_FUNCTION = 'UPDATE_AUTOTAG_DEACTIVATE_FUNCTION'
export const UPDATE_AUTO_TAG_CAMPAIGNS = 'UPDATE_AUTO_TAG_CAMPAIGNS'
export const LOAD_VENUE_PERKS_SUCCESS = 'LOAD_VENUE_PERKS_SUCCESS'
