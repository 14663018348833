import React from 'react'
import {
  AddTextWithBoldPart,
  Card,
  CardBase,
  CardDetailFactory,
  CardDetailRowContainer,
  CardDivider,
  CardSection,
  IconArea,
  StandardFieldText,
  ViewCardTitleSmall,
  ViewField,
} from 'mgr/actualslideout/components/view/ViewCommon'
import ClientThumbnail from 'mgr/lib/components/ClientThumbnail'
import { MailLink, TelephoneLink } from 'mgr/lib/components/Link'
import Tags from 'mgr/lib/components/Tags'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import { FlexColumnContainer, FlexRowContainer } from './ViewLayout'

const AddPhone = () => <AddTextWithBoldPart boldText="Add" regularText="phone" />
const ViewPhone = ({ value }) => <StandardFieldText phone={value}>{value}</StandardFieldText>
const PhoneComponent = CardDetailFactory(AddPhone, ViewPhone)

const AddEmail = () => <AddTextWithBoldPart boldText="Add" regularText="email" />
const ViewEmail = ({ value }) => <StandardFieldText emailAddress={value}>{value}</StandardFieldText>
const EmailComponent = CardDetailFactory(AddEmail, ViewEmail)

export const SourceSubCard = ({ sourceName, sourceInitials, sourcePhoto, sourceTags, sourcePhone, sourceEmail, editCallback }) => (
  <ViewField>
    <FlexRowContainer alignItems="flex-start" onClick={editCallback}>
      <ClientThumbnail
        showSourceBadge
        size={34}
        margin="0 20px 0 0"
        photoObj={sourcePhoto}
        nameDisplay={sourceName}
        initials={sourceInitials}
      />

      <FlexColumnContainer>
        <ViewField>
          <StandardFieldText style={{ fontWeight: 500 }}>{sourceName}</StandardFieldText>
        </ViewField>
        <Tags tags={sourceTags} />
        {sourcePhone ? (
          <ViewField>
            <PhoneComponent icon={VmsIcons.PhoneLine} value={sourcePhone} />
          </ViewField>
        ) : null}
        {sourceEmail ? (
          <ViewField>
            <EmailComponent icon={VmsIcons.EmailLine} value={sourceEmail} />
          </ViewField>
        ) : null}
      </FlexColumnContainer>
    </FlexRowContainer>
  </ViewField>
)

export const ClientSubCard = ({ phone, email, editCallback }) => (
  <div onClick={editCallback}>
    <ViewField>
      <ViewCardTitleSmall>contact</ViewCardTitleSmall>
    </ViewField>
    <ViewField>
      <PhoneComponent icon={VmsIcons.PhoneLine} value={phone} label="Phone" />
    </ViewField>
    <ViewField>
      <EmailComponent icon={VmsIcons.EmailLine} value={email} label="Email" />
    </ViewField>
  </div>
)
