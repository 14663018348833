import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalHeader, ModalTitle, ModalFooter, ModalActions, type ModalHeaderProps, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'
import { campaignBuilderMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'

export interface CancelEmailEditProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onConfirm: () => void
  onCancel: () => void
  isOneTimeEmail?: boolean
  goToOffers?: boolean
  isFromTemplate?: boolean
}

export function CancelEmailEditModal({ closeHref, isOneTimeEmail, isFromTemplate, onConfirm, onCancel, goToOffers }: CancelEmailEditProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()

  if (!venue) {
    return null
  }

  let redirectUrl: string
  if (goToOffers) {
    redirectUrl = nav.href(routes.manager2.marketing.offers, { params: { venueKey: venue.urlKey } }).split('?')[0] || ''
  } else if (isOneTimeEmail) {
    if (isFromTemplate) {
      redirectUrl =
        nav
          .href(routes.manager2.marketing.oneTimeEmailCenter.emailCampaignTemplates, { params: { venueKey: venue.urlKey } })
          .split('?')[0] || ''
    } else {
      redirectUrl = nav.href(routes.manager2.marketing.oneTimeEmailCenter, { params: { venueKey: venue.urlKey } }).split('?')[0] || ''
    }
  } else {
    redirectUrl = nav.href(routes.manager2.marketing.emailCenter.emails, { params: { venueKey: venue.urlKey } }).split('?')[0] || ''
  }
  return (
    <Modal ariaLabel={formatMessage(campaignBuilderMessages.cancelEditWarning)}>
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <Status kind="warning">{formatMessage(commonMessages.unsavedChanges)}</Status>
          <ModalTitle title={formatMessage(campaignBuilderMessages.cancelEditWarning)} />
        </VStack>
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button
            variant="secondary"
            onClick={() => {
              onCancel()
              nav.closeSurface(closeHref)
            }}
            data-test="back-to-editing-button"
          >
            {formatMessage(campaignBuilderMessages.backToEditing)}
          </Button>
          <Button
            variant="primary-warning"
            onClick={() => {
              onConfirm()
              nav.closeSurface(closeHref)
              window.location.assign(redirectUrl)
            }}
            data-test="exit-without-saving-button"
          >
            {formatMessage(campaignBuilderMessages.exitWithoutSaving)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
