import _ from 'lodash'
import { combineReducers } from 'redux'
import * as ActionTypes from 'mgr/pages/single-venue/marketing/actions/ActionTypes'
import filtersReducer from 'mgr/pages/single-venue/marketing/reducers/Filters'
import tagEditReducer from 'mgr/pages/single-venue/marketing/reducers/TagEdit'

const initialState = {
  list: [],
  histogram: [],
  lastUpdated: null,
  rebuildState: null,
  tagActivity: {},
  isLoading: false,
  autotagsList: [],
  clientTagsList: [],
  customAutotag: {},
}

const autotagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_AUTOTAGS_START:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.LOAD_AUTOTAGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case ActionTypes.LOAD_AUTOTAGS_LIST_SUCCESS:
      return {
        ...state,
        list: action.autotags,
        rebuildState: action.rebuildState,
      }
    case ActionTypes.LOAD_AUTOTAGS_ACTIVITY_REPORT_SUCCESS:
      return {
        ...state,
        histogram: action.data ? action.data.histogram : [],
        lastUpdated: action.data ? action.data.last_updated_dt : null,
        tagActivity: action.data ? action.data.tag_activity : {},
      }
    case ActionTypes.AUTO_TAGS_FULL_LIST_SUCCESS:
      return {
        ...state,
        autotagsList: action.autoTags,
        clientTagsList: action.clientTags,
      }
    case ActionTypes.LOAD_CUSTOM_AUTOTAGS_OBJECTS_SUCCESS:
      return {
        ...state,
        customAutotag: action.data,
      }
    case ActionTypes.SAVE_AUTOTAG_SUCCESS:
      const newCustomAutotagConfig = action.tag
      const custom_autotag_configs = _.mapValues(state.customAutotag.custom_autotag_configs, config =>
        config.config_type === newCustomAutotagConfig.config_type ? newCustomAutotagConfig : config
      )
      return {
        ...state,
        customAutotag: {
          ...state.customAutotag,
          custom_autotag_configs,
        },
      }
    default:
      return state
  }
}

export default combineReducers({
  editor: tagEditReducer,
  data: autotagsReducer,
  filters: filtersReducer,
})
