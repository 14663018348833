/**
 * Created by howardgrimberg on 7/12/17.
 */
import { connect } from 'react-redux'
import * as SlideoutActions from 'mgr/actualslideout/actions/SlideoutActions'
import SlideoutComponent from 'mgr/lib/components/Slideout'

const mapStateToProps = state => ({
  isSlideoutOpen: state.slideoutState.isSlideoutOpen,
  maxWidth: '796px',
  zIndex: state.viewResState.linkingReview ? 200000 : null, // to stack on top of new slideout
})

const mapDispatchToProps = dispatch => ({
  onCloseHandler: () => dispatch(SlideoutActions.verifyNoEditAndCloseSlideout()),
})

const ReservationSlideout = connect(mapStateToProps, mapDispatchToProps)(SlideoutComponent)

export default ReservationSlideout
