import { useCallback, type MouseEvent } from 'react'
import styled from 'styled-components'
import type { MixedAvailabilityTimeslot } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { AvailabilityLocales } from '../../../components/availability/Availability.locales'
import { useGetItemFromTimeslot } from '../../../components/availability/useGetItemFromTimeslot'

interface AvailabilityTimeslotExperienceButtonProps {
  timeslot: MixedAvailabilityTimeslot
  setIsActive: (isActive: boolean) => void
}

export function AvailabilityTimeslotExperienceButton({ timeslot, setIsActive }: AvailabilityTimeslotExperienceButtonProps) {
  const { formatMessage } = useLocales()
  const getItemFromTimeslot = useGetItemFromTimeslot()
  const title = getItemFromTimeslot(timeslot)?.name || formatMessage(AvailabilityLocales.noTimeslotDescriptionTitle)

  const toggle = useCallback(
    (e: MouseEvent, state: boolean) => {
      e.stopPropagation()
      setIsActive(state)
    },
    [setIsActive]
  )

  return (
    <StyledButton variant="tertiary" noPadding onClick={e => toggle(e, true)} data-test="availability-timeslot-experience-button">
      {title}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  color: #092238 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
`
