import { createSlice } from '@reduxjs/toolkit'

export interface DeactivateEmailCampaignModalState {
  emailCampaignTableRowIndex: number
}

const deactivateEmailCampaignModalSlice = createSlice({
  name: 'deactivateEmailCampaignModal',
  initialState: null,
  reducers: {
    setDeactivateEmailCampaignModalState: (_, param) => ({ ...param.payload }),
    clearDeactivateEmailCampaignModalState: () => null,
  },
})

const { actions } = deactivateEmailCampaignModalSlice
export const { setDeactivateEmailCampaignModalState, clearDeactivateEmailCampaignModalState } = actions
export const { reducer: deactivateEmailCampaignModalReducer } = deactivateEmailCampaignModalSlice
