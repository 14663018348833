import _ from 'lodash'
import ReviewServices from 'mgr/lib/services/ReviewServices'
import { NotificationLevel } from 'mgr/lib/utils/Constants'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
import * as SlideoutActions from './SlideoutActions'

const linkOpSuccessBanner = message =>
  SlideoutActions.showNotificationBanner({
    message,
    level: NotificationLevel.SUCCESS,
    visible: true,
  })

const linkReviewStart = () => ({ type: ActionTypes.LINK_REVIEW_START })
const linkReviewFail = () => ({ type: ActionTypes.LINK_REVIEW_FAIL })
const linkReviewSuccess = review => ({
  type: ActionTypes.LINK_REVIEW_SUCCESS,
  review,
})

const tryLinkReview = () => (dispatch, getState) => {
  dispatch(linkReviewStart())

  const { viewResState } = getState()
  const venueId = viewResState.actual.venue_id
  const actualId = viewResState.actual.id
  const reviewId = viewResState.linkingReview

  const errHandler = error =>
    dispatch(batchActions([linkReviewFail(), SlideoutActions.showNotificationError(`Error linking review: ${error}`)]))

  return ReviewServices.linkReview(venueId, actualId, reviewId, errHandler).then(review => {
    SvrManager.Reviews.linkReviewDispatch(review)
    dispatch(batchActions([linkReviewSuccess(review), linkOpSuccessBanner('Review Linked to Reservation')]))
    document.getElementById('review-card').scrollIntoView(true)

    window.metric.track('GuestSatisfaction.LinkedReview')
  })
}

const unlinkReviewStart = () => ({ type: ActionTypes.UNLINK_REVIEW_START })
const unlinkReviewFail = () => ({ type: ActionTypes.UNLINK_REVIEW_FAIL })
const unlinkReviewSuccess = review => ({
  type: ActionTypes.UNLINK_REVIEW_SUCCESS,
  review,
})

const tryUnlinkReview = reviewId => (dispatch, getState) => {
  dispatch(unlinkReviewStart())

  const { viewResState } = getState()
  const venueId = viewResState.actual.venue_id
  const actualId = viewResState.actual.id

  const errHandler = error =>
    dispatch(batchActions([unlinkReviewFail(), SlideoutActions.showNotificationError(`Error unlinking review: ${error}`)]))

  return ReviewServices.unlinkReview(venueId, actualId, reviewId, errHandler).then(review => {
    SvrManager.Reviews.unlinkReviewDispatch(review)
    dispatch(batchActions([unlinkReviewSuccess(review), linkOpSuccessBanner('Review Unlinked')]))
  })
}

const getReviewsStart = () => ({ type: ActionTypes.GET_REVIEWS_START })
const getReviewsFail = () => ({ type: ActionTypes.GET_REVIEWS_FAIL })
const getReviewsSuccess = reviews => ({
  type: ActionTypes.GET_REVIEWS_SUCCESS,
  reviews,
})

const tryGetLinkedReviews = () => (dispatch, getState) => {
  dispatch(getReviewsStart())
  const { viewResState } = getState()
  const actualId = viewResState.actual.id
  const venueId = viewResState.actual.venue_id

  const errHandler = error =>
    dispatch(batchActions([getReviewsFail(), SlideoutActions.showNotificationError(`Error loading reviews: ${error}`)]))

  return ReviewServices.fetchLinkedReviews(actualId, venueId, errHandler).then(reviews => {
    dispatch(getReviewsSuccess(reviews))

    // Need to get state again because scrollOnLoad()
    // gets fired after tryGetLinkedReviews()
    const { scrollOnLoad } = getState().viewResState
    if (scrollOnLoad) {
      document.getElementById('review-card').scrollIntoView(true)
    }
  })
}
const setToLinking = reviewId => ({
  type: ActionTypes.ENABLE_LINKING,
  id: reviewId,
})

const scrollOnLoad = () => ({
  type: ActionTypes.SCROLL_ON_LOAD,
})

export { tryLinkReview, tryUnlinkReview, tryGetLinkedReviews, setToLinking, scrollOnLoad }
