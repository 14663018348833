export const EMPTY_DICT = {}
export const EMPTY_ARRAY = []

export const dropDownTypes = {
  ADD_BLOCK: 'ADD_BLOCK',
  EDIT_BLOCK: 'EDIT_BLOCK',
  EDIT_ACCESS_RULE: 'EDIT_ACCESS_RULE',
}

export const AutotagConfigType = {
  REVIEWER: 'REVIEWER',
  SPEND_GLOBAL: 'SPEND_GLOBAL',
  SPEND_LOCAL: 'SPEND_LOCAL',
  VISITS_GLOBAL: 'VISITS_GLOBAL',
  VISITS_LOCAL: 'VISITS_LOCAL',
  FIRST_TIMER: 'FIRST_TIMER',
  FIRST_TIMER_GLOBAL: 'FIRST_TIMER_GLOBAL',
  WE_MISS_YOU: 'WE_MISS_YOU',
  WE_MISS_YOU_GLOBAL: 'WE_MISS_YOU_GLOBAL',
  BIRTHDAY_LOCAL: 'BIRTHDAY_LOCAL',
  BIRTHDAY: 'BIRTHDAY',
  ANNIVERSARY_LOCAL: 'ANNIVERSARY_LOCAL',
  ANNIVERSARY: 'ANNIVERSARY',
  ALL_GUESTS: 'ALL_GUESTS',
  SUBSCRIBED_GUESTS: 'SUBSCRIBED_GUESTS',
  ALL_GUESTS_GLOBAL: 'ALL_GUESTS_GLOBAL',
  BOOKED_BY_THIRD_PARTY: 'BOOKED_BY_THIRD_PARTY',
  MISSED_VISITS: 'MISSED_VISITS',
  UPCOMING_RESERVATIONS: 'UPCOMING_RESERVATIONS',
  REFERRER: 'REFERRER',
  RESERVATION_REQUESTS: 'RESERVATION_REQUESTS',
  VISITS_LOCAL_MARKETING_SEGMENTATION: 'VISITS_LOCAL_MARKETING_SEGMENTATION',
  CUSTOM_LOCAL: 'CUSTOM_LOCAL',
  CUSTOM_GLOBAL: 'CUSTOM_GLOBAL',
  CUSTOM_LOCAL_MARKETING_SEGMENTATION: 'CUSTOM_LOCAL_MARKETING_SEGMENTATION',
  CUSTOM_GLOBAL_MARKETING_SEGMENTATION: 'CUSTOM_GLOBAL_MARKETING_SEGMENTATION',
  _TAG_GROUP_NAMES: {
    REVIEWER: 'Reviewer',
    SPEND_GLOBAL: 'Group Spend',
    SPEND_LOCAL: 'Local Spend',
    VISITS_GLOBAL: 'Group Visits',
    VISITS_LOCAL: 'Local Visits',
    FIRST_TIMER: 'First Timer',
    FIRST_TIMER_GLOBAL: 'Group First Timer',
    WE_MISS_YOU: 'We Miss You',
    WE_MISS_YOU_GLOBAL: 'Group We Miss You',
    BIRTHDAY_LOCAL: 'Birthday Local',
    BIRTHDAY: 'Birthday',
    ANNIVERSARY_LOCAL: 'Anniversary Local',
    ANNIVERSARY: 'Anniversary',
    ALL_GUESTS: 'ALL_GUESTS',
    SUBSCRIBED_GUESTS: 'SUBSCRIBED_GUESTS',
    ALL_GUESTS_GLOBAL: 'Group All Guests',
    BOOKED_BY_THIRD_PARTY: 'Booked by Third-Party',
    MISSED_VISITS: 'Missed Visits',
    UPCOMING_RESERVATIONS: 'Upcoming Reservations',
    REFERRER: 'Referrer',
    RESERVATION_REQUESTS: 'Reservation Requests',
    VISITS_LOCAL_MARKETING_SEGMENTATION: 'Visits - Marketing Segmentation',
    CUSTOM_LOCAL: 'Custom Local',
    CUSTOM_GLOBAL: 'Custom Global',
    CUSTOM_LOCAL_MARKETING_SEGMENTATION: 'Custom Local Marketing Segmentation',
    CUSTOM_GLOBAL_MARKETING_SEGMENTATION: 'Custom Global Marketing Segmentation',
  },
}

export const AutotagTierType = {
  // Reviewer
  POSITIVE_REVIEW: 'POSITIVE_REVIEW',
  NEGATIVE_REVIEW: 'NEGATIVE_REVIEW',
  POSITIVE_FEEDBACK: 'POSITIVE_FEEDBACK',
  NEGATIVE_FEEDBACK: 'NEGATIVE_FEEDBACK',
  GROUP_REVIEW: 'GROUP_REVIEW',
  GROUP_FEEDBACK: 'GROUP_FEEDBACK',
  // Spend tiers
  SPEND_GLOBAL_TIER: 'SPEND_GLOBAL_TIER',
  SPEND_LOCAL_TIER: 'SPEND_LOCAL_TIER',
  // Visit tiers
  VISITS_GLOBAL_TIER: 'VISITS_GLOBAL_TIER',
  VISITS_LOCAL_TIER: 'VISITS_LOCAL_TIER',
  // First timer local
  FIRST_TIMER: 'FIRST_TIMER',
  FIRST_TIMER_COMPLETED: 'FIRST_TIMER_COMPLETED',
  // First timer global
  FIRST_TIMER_GLOBAL: 'FIRST_TIMER_GLOBAL',
  FIRST_TIMER_COMPLETED_GLOBAL: 'FIRST_TIMER_COMPLETED_GLOBAL',
  // We miss you
  WE_MISS_YOU: 'WE_MISS_YOU',
  WELCOME_BACK: 'WELCOME_BACK',
  WELCOME_BACK_COMPLETED: 'WELCOME_BACK_COMPLETED',
  // We miss you global
  WE_MISS_YOU_GLOBAL: 'WE_MISS_YOU_GLOBAL',
  WELCOME_BACK_GLOBAL: 'WELCOME_BACK_GLOBAL',
  WELCOME_BACK_COMPLETED_GLOBAL: 'WELCOME_BACK_COMPLETED_GLOBAL',
  // Birthday local
  BIRTHDAY_LOCAL: 'BIRTHDAY_LOCAL',
  CELEBRATED_BIRTHDAY_LOCAL: 'CELEBRATED_BIRTHDAY_LOCAL',
  // Birthday global
  BIRTHDAY: 'BIRTHDAY',
  CELEBRATED_BIRTHDAY: 'CELEBRATED_BIRTHDAY',
  // Anniversary local
  ANNIVERSARY_LOCAL: 'ANNIVERSARY_LOCAL',
  CELEBRATED_ANNIVERSARY_LOCAL: 'CELEBRATED_ANNIVERSARY_LOCAL',
  // Anniversary global
  ANNIVERSARY: 'ANNIVERSARY',
  CELEBRATED_ANNIVERSARY: 'CELEBRATED_ANNIVERSARY',
  // Marketing Opt-In
  ALL_GUESTS: 'ALL_GUESTS',
  SUBSCRIBED_GUESTS: 'SUBSCRIBED_GUESTS',
  ALL_GUESTS_GLOBAL: 'ALL_GUESTS_GLOBAL',
  MARKETING_OPT_INS_GLOBAL: 'MARKETING_OPT_INS_GLOBAL',
  // Booked by Third-Party
  BOOKED_THROUGH_OPENTABLE: 'BOOKED_THROUGH_OPENTABLE',
  // Missed Visits
  NO_SHOW: 'NO_SHOW',
  WAITLIST_ABANDON: 'WAITLIST_ABANDON',
  CANCELLATION: 'CANCELLATION',
  // Upcoming Reservations
  UPCOMING_RESERVATION_IN_30_DAYS: 'UPCOMING_RESERVATION_IN_30_DAYS',
  // Referrer
  REFERRER_TIER: 'REFERRER_TIER',
  // Reservation Requests
  RESERVATION_REQUESTS: 'RESERVATION_REQUESTS',
  // Visits - Marketing Segmentation
  VISITS_LOCAL_MARKETING_SEGMENTATION_TIER: 'VISITS_LOCAL_MARKETING_SEGMENTATION_TIER',
  // Custom
  CUSTOM: 'CUSTOM',
}

export const ConfigTierMap = {
  REVIEWER: [
    AutotagTierType.POSITIVE_REVIEW,
    AutotagTierType.NEGATIVE_REVIEW,
    AutotagTierType.POSITIVE_FEEDBACK,
    AutotagTierType.NEGATIVE_FEEDBACK,
    AutotagTierType.GROUP_REVIEW,
    AutotagTierType.GROUP_FEEDBACK,
  ],
  SPEND_GLOBAL: [AutotagTierType.SPEND_GLOBAL_TIER],
  SPEND_LOCAL: [AutotagTierType.SPEND_LOCAL_TIER],
  VISITS_GLOBAL: [AutotagTierType.VISITS_GLOBAL_TIER],
  VISITS_LOCAL: [AutotagTierType.VISITS_LOCAL_TIER],
  FIRST_TIMER: [AutotagTierType.FIRST_TIMER, AutotagTierType.FIRST_TIMER_COMPLETED],
  FIRST_TIMER_GLOBAL: [AutotagTierType.FIRST_TIMER_GLOBAL, AutotagTierType.FIRST_TIMER_COMPLETED_GLOBAL],
  WE_MISS_YOU: [AutotagTierType.WE_MISS_YOU, AutotagTierType.WELCOME_BACK, AutotagTierType.WELCOME_BACK_COMPLETED],
  WE_MISS_YOU_GLOBAL: [
    AutotagTierType.WE_MISS_YOU_GLOBAL,
    AutotagTierType.WELCOME_BACK_GLOBAL,
    AutotagTierType.WELCOME_BACK_COMPLETED_GLOBAL,
  ],
  BIRTHDAY_LOCAL: [AutotagTierType.BIRTHDAY_LOCAL, AutotagTierType.CELEBRATED_BIRTHDAY_LOCAL],
  BIRTHDAY: [AutotagTierType.BIRTHDAY, AutotagTierType.CELEBRATED_BIRTHDAY],
  ANNIVERSARY_LOCAL: [AutotagTierType.ANNIVERSARY_LOCAL, AutotagTierType.CELEBRATED_ANNIVERSARY_LOCAL],
  ANNIVERSARY: [AutotagTierType.ANNIVERSARY, AutotagTierType.CELEBRATED_ANNIVERSARY],
  ALL_GUESTS: [AutotagTierType.ALL_GUESTS, AutotagTierType.SUBSCRIBED_GUESTS],
  SUBSCRIBED_GUESTS: [AutotagTierType.SUBSCRIBED_GUESTS],
  ALL_GUESTS_GLOBAL: [AutotagTierType.ALL_GUESTS_GLOBAL, AutotagTierType.MARKETING_OPT_INS_GLOBAL],
  BOOKED_BY_THIRD_PARTY: [AutotagTierType.BOOKED_THROUGH_OPENTABLE],
  MISSED_VISITS: [AutotagTierType.NO_SHOW, AutotagTierType.WAITLIST_ABANDON, AutotagTierType.CANCELLATION],
  UPCOMING_RESERVATIONS: [AutotagTierType.UPCOMING_RESERVATION_IN_30_DAYS],
  REFERRER: [AutotagTierType.REFERRER_TIER],
  RESERVATION_REQUESTS: [AutotagTierType.RESERVATION_REQUESTS],
  VISITS_LOCAL_MARKETING_SEGMENTATION: [AutotagTierType.VISITS_LOCAL_MARKETING_SEGMENTATION_TIER],
  CUSTOM: [AutotagTierType.CUSTOM],
}

export const TierAttributes = {
  MIN_VISITS: 'MIN_VISITS',
  MIN_VENUES: 'MIN_VENUES',
  SPEND_TYPE: 'SPEND_TYPE',
  SPEND_AMOUNT: 'SPEND_AMOUNT',
  PAST_MONTHS_MIN: 'PAST_MONTHS_MIN',
  PAST_MONTHS_MAX: 'PAST_MONTHS_MAX',
  DAY_DELTA: 'DAY_DELTA',
  REMOVE_AFTER_DAYS: 'REMOVE_AFTER_DAYS',
  SPEND_CURRENCY: 'SPEND_CURRENCY',
  MIN_REFERRALS: 'MIN_REFERRALS',
  RESERVATION_REQUEST_SOURCE: 'RESERVATION_REQUEST_SOURCE',
  MIN_RESERVATION_REQUEST_PARTY_SIZE: 'MIN_RESERVATION_REQUEST_PARTY_SIZE',
  PAST_DAYS_MAX: 'PAST_DAYS_MAX',
  MIN_RESERVATION_REQUESTS: 'MIN_RESERVATION_REQUESTS',
}

export const TagDescriptions = {
  SPEND_LOCAL: `
    This tag will be automatically applied
    when a guest has reached a certain threshold
    for total spend at this location.
  `,
  SPEND_GLOBAL: `
    This tag will be automatically applied
    when a guest has reached a certain threshold
    for total spend across all of your locations.
  `,
  VISITS_LOCAL: `
    Set-up multiple tiers to make sure you are
    getting the most information about your guests.
  `,
  VISITS_GLOBAL: `
    Set-up multiple tiers to make sure you are
    getting the most information about your guests.
  `,
  REVIEWER: `
    This tag will automatically appear on a guest
    profile when a review is linked or feedback
    submitted.
  `,
  FIRST_TIMER: `
    This tag will be automatically applied when
    it is the guest's first time visiting your
    location.
  `,
  FIRST_TIMER_GLOBAL: `
    This tag will be automatically applied when
    it is the guest's first time visiting a location
    within the group.
  `,
  WE_MISS_YOU: `
    This tag will be automatically applied when a
    certain amount of time has passed since the
    guest's last visit to your specific venue.
  `,
  WE_MISS_YOU_GLOBAL: `
    This tag will be automatically applied when a
    certain amount of time has passed since the
    guest's last visit to any venue in the group.
  `,
  BIRTHDAY_LOCAL: `
    This tag will automatically be applied when
    the client's birthday is within a certain time
    window.
  `,
  BIRTHDAY: `
    This tag will be automatically applied when
    the client's birthday is within a certain time
    window.
  `,
  ANNIVERSARY_LOCAL: `
    This tag will be automatically applied when
    the client's anniversary is within a certain time
    window.
  `,
  ANNIVERSARY: `
    This tag will be automatically applied when
    the client's anniversary is within a certain time
    window.
  `,
  ALL_GUESTS: `
    This tag will be automatically applied to guests
    who are eligible to receive venue marketing emails.
  `,
  SUBSCRIBED_GUESTS: `
    This tag will be automatically applied to guests
    who are eligible to receive venue marketing emails.
  `,
  ALL_GUESTS_GLOBAL: `
    This tag will be automatically applied to guests
    who are eligible to receive group marketing emails.
  `,
  MARKETING_OPT_INS_GLOBAL: `
    This tag will be automatically applied to guests
    who are eligible to receive group marketing emails.
  `,
  BOOKED_BY_THIRD_PARTY: `
    This tag will be automatically applied to guests
    who booked through a third-party app.
  `,
  MISSED_VISITS: `
    This tag will be automatically applied to guests
    who missed their visit.
  `,
  UPCOMING_RESERVATIONS: `
    This tag will be automatically applied to guests
    who have an upcoming reservation.
    `,
  REFERRER: `
    This tag will be automatically applied when a guest
    has generated a certain threshold of new guest referrals.
    <b>A new guest is a guest that has never dined in at any
    venue in the group before.</b>
  `,
  RESERVATION_REQUESTS: `
    This tag will be automatically applied to guests
    who have made a certain number of reservation requests
    which did not result in a completed reservation.
  `,
  VISITS_LOCAL_MARKETING_SEGMENTATION: `
    This tag will be automatically applied to guests who have
    a certain number of completed reservations.
  `,
}

export const MarketingCampaignType = {
  ONGOING: 'ONGOING',
  ONE_TIME: 'ONE_TIME',
}

export const MarketingCampaignScheduleType = {
  ABSOLUTE: 'ABSOLUTE',
  RELATIVE: 'RELATIVE',
  SCHEDULED: 'SCHEDULED',
}

export const EmailCampaignStatus = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  SENT: 'SENT',
}

export const SendTimeDelayType = {
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
}

export const ExperienceType = {
  EXPERIENCE: 'EXPERIENCE',
  EVENT: 'EVENT',
  THEFORK: 'THEFORK',
  PDR: 'PRIVATE_DINING_ROOM',
}

export const ExperienceStatus = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const ExperienceVisibility = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
}

export const VenueGroupNavSections = {
  PODS: 'PODS',
  TAGS: 'TAGS',
  USERS: 'USERS',
  REPORTING: 'REPORTING',
}

export const VenueGroupSubNavSections = {
  CLIENT_TAGS: 'CLIENT_TAGS',
  RESERVATION_TAGS: 'RESERVATION_TAGS',
  USER_ACCOUNTS: 'USER_ACCOUNTS',
  ROLES: 'ROLES',
}

export const AutotagTimeFrame = {
  WHOLE_DINING_HISTORY: 'WHOLE_DINING_HISTORY',
  TRAILING_NUMBER_OF_MONTHS: 'TRAILING_NUMBER_OF_MONTHS',
  CALENDAR_YEAR: 'CALENDAR_YEAR',
}

export const AutotagConfigAttributes = {
  TIME_FRAME: 'time_frame',
  TRAILING_MONTHS: 'trailing_months',
}

export const TRAILING_NUMBER_OF_MONTHS = 12

export const ReservationRequestSources = {
  SEVENROOMS: 'SEVENROOMS',
  BOOKIE: 'BOOKIE',
}
