import _ from 'lodash'
import type { EmailCampaign } from '@sevenrooms/core/api'
import type { GenericTagGroup } from '@sevenrooms/core/domain'
import type { MultiSelectTagOptionsForm, TagOption } from '@sevenrooms/core/ui-kit/form'

export function packEmailBodySections(
  emailBodySections: {
    value: string | null
    maxLen: number | null
    isEditable: boolean
  }[]
) {
  const bodySections = emailBodySections ? emailBodySections.map(section => section.value) : []
  const bodySectionsText = bodySections.join('<br/><br/>')
  return { bodySections, bodySectionsText }
}

/**
 * This regex strips out all html tags and short codes
 */
export function getCharacterCount(s: string): number {
  return s.replace(/<[^>]+>|&lt;(?!&gt;).+&gt;/gm, '').length
}

export function getPlainTextFromHtml(htmlString: string | null) {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = htmlString || ''
  const plainText = tempElement.innerText
  // Running tests element.innerText returns undefined, we need to use textContent
  const plainContent = tempElement.textContent
  tempElement.remove()
  return (plainText ?? plainContent).trim()
}

export function getImageFromHtml(htmlString: string | undefined) {
  const tempElement = document.createElement('div')
  tempElement.innerHTML = htmlString || ''
  const images = tempElement.getElementsByTagName('img')
  tempElement.remove()
  return images
}
export function extractAutoTagIds(tagOptions?: MultiSelectTagOptionsForm | null): Array<string> {
  if (!tagOptions) {
    return []
  }
  return tagOptions.map(tag => tag.id)
}

export function getAutoTagOptionsFromIds(ids?: string[], clientTagGroups?: GenericTagGroup[]): TagOption[] {
  return ids
    ? ids.map(id => {
        const [, categoryId, , tag] = id.split('##')
        const label = clientTagGroups ? getAutoTagDisplayName(categoryId as string, tag as string, clientTagGroups) : tag
        return { id, label: label as string, categoryId: categoryId as string }
      })
    : []
}

const getAutoTagDisplayName = (categoryId: string, tag: string, clientTagGroups: GenericTagGroup[]) => {
  const tagGroup = clientTagGroups.find(tagGroup => tagGroup.id === categoryId)
  return tagGroup?.tagNameDisplays?.[tag] || tag
}

export function getAdminAutoTagOptionsFromIds(ids?: string[]): TagOption[] {
  return ids
    ? ids.map(id => {
        const [categoryId, , label] = id.split('##')
        return { id, label: label as string, categoryId: categoryId as string }
      })
    : []
}

/**
 * Currently, EmailCampaign has both emailBody and emailBodyParts attributes.
 * The emailBody section is used for 'From Scratch' and 'One Time' campaigns, while emailBodyParts is
 * for 'From Template' campaigns. In this method, we're preferring emailBodyParts to build the body
 * string to account for the situation where a user duplicates a 'From Template' campaign, which
 * makes the duplication a 'From Scratch' campaign.
 *
 * As the emailBuilders converge, we should deprecate the emailBody attribute entirely
 * and just use emailBodyParts. We can either leave code in to handle old campaigns that still
 * use emailBody or do a mass conversion to convert the contents of emailBody to emailBodyParts.
 */
export function getEmailBody({ emailCampaign, disableWrap }: { emailCampaign?: EmailCampaign; disableWrap?: boolean }): string {
  let emailBody = ''
  if (emailCampaign) {
    const { greeting, emailBodyParts, signature, signoff, footer, emailBody: emailCampaignBody } = emailCampaign
    emailBody = _.filter([greeting, emailBodyParts?.join('<br>'), signature, signoff, footer]).join('<br>') || emailCampaignBody || ''
  }

  if (emailBody !== '' && !/^<div[\s\S]*>[\s\S]*<\/div>$/.test(emailBody) && !disableWrap) {
    emailBody = `<div>${emailBody}</div>`
  }
  return emailBody
}

export function encodeShortCode(text: string) {
  return text
    .replace(new RegExp('<first_name>', 'g'), '&lt;first_name&gt;')
    .replace(new RegExp('<last_name>', 'g'), '&lt;last_name&gt;')
    .replace(new RegExp('<salutation>', 'g'), '&lt;salutation&gt;')
    .replace(new RegExp('<client_first_name>', 'g'), '&lt;client_first_name&gt;')
    .replace(new RegExp('<client_last_name>', 'g'), '&lt;client_last_name&gt;')
    .replace(new RegExp('<client_salutation>', 'g'), '&lt;client_salutation&gt;')
    .replace(new RegExp('<sender_first_name>', 'g'), '&lt;sender_first_name&gt;')
    .replace(new RegExp('<sender_last_name>', 'g'), '&lt;sender_last_name&gt;')
    .replace(new RegExp('<sender_email>', 'g'), '&lt;sender_email&gt;')
    .replace(new RegExp('<sender_role>', 'g'), '&lt;sender_role&gt;')
    .replace(new RegExp('<venue_name>', 'g'), '&lt;venue_name&gt;')
    .replace(new RegExp('<venue_address>', 'g'), '&lt;venue_address&gt;')
    .replace(new RegExp('<venue_city>', 'g'), '&lt;venue_city&gt;')
    .replace(new RegExp('<venue_state>', 'g'), '&lt;venue_state&gt;')
    .replace(new RegExp('<venue_country>', 'g'), '&lt;venue_country&gt;')
    .replace(new RegExp('<venue_website>', 'g'), '&lt;venue_website&gt;')
    .replace(new RegExp('<venue_phone>', 'g'), '&lt;venue_phone&gt;')
    .replace(new RegExp('<venue_postal_code>', 'g'), '&lt;venue_postal_code&gt;')
    .replace(new RegExp('<menu_link>', 'g'), '&lt;menu_link&gt;')
    .replace(new RegExp('<reservation_widget_link>', 'g'), '&lt;reservation_widget_link&gt;')
}
