import { srGet, srPost, srUpload, throwFetchError } from '@sevenrooms/core/api'

const fetchAttachments = (venueId, conversationId) =>
  srGet(
    `/manager/${venueId}/attachments/${conversationId}`,
    {
      rid: Math.floor(Math.random() * 100 + 1),
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  )
    .then(throwFetchError)
    .then(response => response.attachments)
// [{"attachment_group_key": "ahZzfnNldmVucm9vbXMtc2VjdXJlLXFhch8LEhJzcl9BdHRhY2htZW50R3JvdXAYgIDgzLjciAsM", "content_type": "application/macbinary",
// "created": "09/27/17", "blob_key": "AMIfv94M_YvkgmP4YknZsE_KkTxypNIQbENA8nqPhsxlv_VAMMBUD-PMcRBc3AD6Gn7cmAkYY9SU4mOGK-iPXtp6iIXrCO_ZpkW7wveaOAZ3W91arTyfnWefSHk5Wxry01AF2-KC1lzJJyCYjuvg1VGoltywBTsMfY3MfSmpiDGUoEBng7uIchM",
// "author_id": "ahZzfnNldmVucm9vbXMtc2VjdXJlLXFhchULEghnYWVfVXNlchiAgKD81-ugCgw",
// "is_internal": false, "author_name": "Howard Grimberg", "filename": "testfile.bin"}]
const registerAttachmentsToAttachmentGroup = (attachmentGroupKey, isInternal, attachmentData, errHandler) =>
  srPost(`/attachment/${attachmentGroupKey}/register/${isInternal}`, {
    attachment_data: JSON.stringify(attachmentData),
  })
    .then(response => {
      if (!response.success && errHandler) {
        errHandler(response.error_message || 'Could not register attachments')
      }
      return response.success
    })
    .catch(err => errHandler(err))

const deleteAttachment = (attachmentGroupKey, blobKey) => srPost(`/attachment/${attachmentGroupKey}/remove`, { blob_key: blobKey })
const uploadFile = file =>
  srPost('/upload-url', { rurl: Math.random() * 100 + 1 }, { convertToGetParams: true })
    .then(throwFetchError)
    .then(response => srUpload(response.upload_url, file))
    .then(throwFetchError)
export default {
  fetchAttachments,
  uploadFile,
  registerAttachmentsToAttachmentGroup,
  deleteAttachment,
}
