import React from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { ActualPaymentLocales } from './ActualPayment.locales'
import { ActualPaymentTestId } from './ActualPayment.testIds'

interface PaylinkConfirmationModalProps {
  showConfirmPaylinkModal: boolean
  onClose: () => void
  onSubmitChargeHandler: () => void
}

function PaylinkConfirmationModal({ showConfirmPaylinkModal, onClose, onSubmitChargeHandler }: PaylinkConfirmationModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Window active={showConfirmPaylinkModal} data-test={ActualPaymentTestId.paylinkConfirmationModal}>
      <Modal ariaLabel="Modal">
        <ModalHeader onClose={onClose}>
          <VStack spacing="s">
            <Status kind="warning">{formatMessage(commonMessages.warning)}</Status>
            <ModalTitle title={formatMessage(ActualPaymentLocales.paylinkConfirmationTitle)} />
          </VStack>
        </ModalHeader>
        <ModalBody>
          <Text>{formatMessage(ActualPaymentLocales.paylinkConfirmationBody)}</Text>
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button variant="tertiary" onClick={onClose} data-test={ActualPaymentTestId.paylinkConfirmationCancel}>
              {formatMessage(ActualPaymentLocales.paylinkConfirmationCancel)}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onClose()
                onSubmitChargeHandler()
              }}
              data-test={ActualPaymentTestId.paylinkConfirmationSubmit}
            >
              {formatMessage(ActualPaymentLocales.paylinkConfirmationSubmit)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}

export default PaylinkConfirmationModal
