import PropTypes from 'prop-types'
import React, { Component } from 'react'
import styled from 'styled-components'

const Switch = styled.div`
  box-sizing: border-box;
  border: 2px solid ${props => (props.on ? props.theme.primary : props.theme.mediumGrey)};
  width: 48px;
  height: 28px;
  border-radius: 14px;
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
  display: inline-block;
  margin-right: 8px;
  background: ${props => (props.on ? props.theme.primary : props.theme.mediumGrey)};
`

const SwitchToggle = styled.div`
  width: 24px;
  height: 24px;
  left: ${props => (props.on ? '20px' : '0')};
  border-radius: 12px;
  background: ${props => props.theme.white};
  position: relative;
  transition: left 0.2s ease-in-out;
`

const SwitchLabel = styled.label`
  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
  line-height: 28px;
  vertical-align: top;
  font-size: 15px;
  color: ${props => (props.enabled ? props.theme.navigationDark : '#999')};
`

const CheckboxToggle = ({ testId, name, label, on, enabled, onChange, children, style, id, ...rest }) => {
  const onClick = e => {
    e.preventDefault()
    if (enabled) {
      onChange(!on)
    }
  }

  return (
    <div {...rest} {...{ style }} data-test={testId}>
      {name ? <input type="hidden" value="{ on ? '1' : '0' }" name="{ name }" /> : null}
      <Switch id={id} {...{ on, onClick, enabled }} data-test="sr-switch">
        <SwitchToggle data-test="sr-switch-toggle" {...{ on }}>
          {children}
        </SwitchToggle>
      </Switch>
      <SwitchLabel data-test="sr-switch-label" {...{ onClick, enabled }}>
        {label}
      </SwitchLabel>
    </div>
  )
}

CheckboxToggle.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  on: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  id: PropTypes.string,
}

CheckboxToggle.defaultProps = {
  on: false,
  onChange: () => {},
  enabled: true,
  id: '',
}

export default CheckboxToggle
