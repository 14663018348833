import React from 'react'
import styled from 'styled-components'
import CardInputForm from 'mgr/actualslideout/components/payment/CardInputForm'
import PaylinkConfirmationModal from 'mgr/actualslideout/components/payment/PaylinkConfirmationModal'
import { ModalContainer, ModalShadow, ModalHeader, ModalClose, PulsatingDots, ActionButton } from 'mgr/layout/StyledComponentUtils'

const FormWrap = styled.div`
  margin-top: -42px;
  max-height: inherit;
  overflow: scroll;
  padding: 42px 5px 20px;
`

const ModalChargeContainer = styled(ModalContainer)`
  margin-left: -278px;
  max-height: 80vh;
  overflow: hidden;
  width: 606px;
`

const ChargeForm = ({
  actual,
  actions,
  currencySymbol,
  paylinkOnly,
  formErrors,
  validateFieldMap,
  selectedClient,
  cardDetails,
  chargeDetails,
  resCardDetails,
  paymentRules,
  isModalSubmitting,
  chargeOnlyModal,
  validateFields,
  onSubmitChargeHandler,
  stripeInstance,
  venueTimezone,
  selectedTimeSlot,
  venue,
}) => {
  const [showConfirmPaylinkModal, setShowConfirmPaylinkModal] = React.useState(false)
  const title = paylinkOnly ? 'Collect Payment via Paylink' : 'Credit Card'

  const onSubmit = () => {
    if (actual.paylink_auto_cancel_datetime && chargeDetails.cardEntryOption === 'paylink') {
      const errors = validateFields()
      if (!Object.keys(errors).length) {
        setShowConfirmPaylinkModal(true)
      }
    } else {
      onSubmitChargeHandler()
    }
  }

  return (
    <div>
      <ModalChargeContainer>
        <ModalHeader style={{ zIndex: 2 }}>
          {title}
          <ModalClose onClick={() => actions.toggleChargeModal(false)} />
        </ModalHeader>
        <FormWrap>
          <CardInputForm
            chargeDetails={chargeDetails}
            cardDetails={cardDetails}
            resCardDetails={resCardDetails}
            selectedClient={selectedClient}
            paymentRules={paymentRules}
            currencySymbol={currencySymbol}
            paylinkOnly={paylinkOnly}
            formErrors={formErrors}
            validateFieldMap={validateFieldMap}
            actions={actions}
            isChargeModal
            chargeOnlyModal={chargeOnlyModal}
            stripeInstance={stripeInstance}
            venueTimezone={venueTimezone}
            selectedTimeSlot={selectedTimeSlot}
            venue={venue}
          />
          <div style={{ margin: '0 10px' }}>
            <ActionButton data-test="sr-button-submit" style={{ width: '100%' }} onClick={onSubmit} disabled={isModalSubmitting}>
              {isModalSubmitting ? <PulsatingDots /> : 'Submit'}
            </ActionButton>
          </div>
        </FormWrap>
      </ModalChargeContainer>
      <PaylinkConfirmationModal
        showConfirmPaylinkModal={showConfirmPaylinkModal}
        onClose={() => setShowConfirmPaylinkModal(false)}
        onSubmitChargeHandler={onSubmitChargeHandler}
      />
      <ModalShadow onClick={() => actions.toggleChargeModal(false)} />
    </div>
  )
}

export default ChargeForm
