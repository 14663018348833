import React from 'react'
import styled from 'styled-components'
import { AddTextWithBoldPart, CardDetailRowContainer, CardDivider } from 'mgr/actualslideout/components/view/ViewCommon'
import { PulsatingDotsRed } from 'mgr/layout/StyledComponentUtils'
import { sumPx } from 'mgr/lib/utils/Styles'
import { VmsIcons, StyledVmsIconXS, VmsIconSizes } from 'svr/common/VmsIcons'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Text } from '@sevenrooms/core/ui-kit/typography'

const CreditCardInfo = styled.div`
  position: relative;
`

const ViewCreditCardContainer = styled.div`
  padding-bottom: 8px;
`

export const BreakTitle = styled.div`
  background-color: #e0e2e6;
  color: #858e95;
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.double};
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
`

const Button = styled.div`
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 200;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 48.5%;
`

export const ActionButton = styled(Button)`
  background: #347baf;
  color: #ffffff;
`

export const LightButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #aaaaaa;
  color: #9a9b9c;
  line-height: 28px;
  margin-top: 1px;
  transition: 0.3s background;
  &:hover {
    background: #e8e9e9;
  }
`

export const DeleteButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #f3356b;
  color: #f3356b;
  line-height: 28px;
  width: 20%;
`

export const CreditCardView = styled.div`
  border-radius: 6px;
  background-color: ${props => props.theme.background};
  margin: ${props => props.theme.gutter.half};
`

export const Transactions = styled.div`
  border-radius: 6px;
  background-color: ${props => props.theme.background};
  padding: ${props => props.theme.gutter.double};
  position: relative;
`

export const DetailList = styled.div`
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
`

export const DetailRow = styled.div`
  display: flex;
  align-content: space-between;
  background-color: #f6f6f6;
  color: #858e95;
  font-size: 13px;
  font-weight: 100;
  border-top: ${props => (props.borderTop ? '1px solid #ccc' : 'none')};
  padding: ${props => props.theme.gutter.standard} ${props => props.theme.gutter.double};
`

const DetailLabel = styled.div`
  flex: 1;
`

export const PaylinkUrl = styled(DetailRow)`
  border-radius: 4px;
  margin-bottom: ${props => props.theme.gutter.standard}
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DetailItem = ({ label, amount }) => (
  <DetailRow>
    <DetailLabel>{label}</DetailLabel>
    <div>{amount}</div>
  </DetailRow>
)

const AddCardAction = styled.div`
  cursor: pointer;
  margin-bottom: ${props => props.theme.gutter.standard};
  transition: 0.3s opacity;
  &:hover {
    opacity: 0.7;
  }
`

const ShownCard = styled.div`
  display: flex;
  height: 43px;
  justify-content: space-between;
  align-items: center;
  background: #ebedf0;
  border-radius: 2px;
  color: #858e95;
  font-size: 15px;
  font-weight: 200;
  line-height: 36px;
  text-transform: ${props => (props.brand === 'iDEAL' ? '' : 'capitalize')};
  margin: ${props => props.theme.gutter.standard} 0 ${props => props.theme.gutter.half} 0;
  padding: 0 0 0 ${props => props.theme.gutter.double};
`

const SmallTitle = styled.div`
  color: #858e95;
  font-size: 11px;
  text-transform: uppercase;
`

const CardDetailsAndActions = styled.div`
  margin-bottom: ${props => props.theme.gutter.standard};
  margin-left: ${props => sumPx(VmsIconSizes.xsmall.fontSize, props.theme.gutter.double)};
`

const CreditCardIconXS = styled(StyledVmsIconXS)`
  margin-right: ${props => props.theme.gutter.double};
`

const AddCreditCard = ({ toggleChargeModal, canCharge, paylinkOnly }) => (
  <CardDetailRowContainer alignItems="flex-start">
    <AddCardAction onClick={() => toggleChargeModal(true, canCharge, paylinkOnly)}>
      <CreditCardIconXS>{VmsIcons.CreditCard}</CreditCardIconXS>
      <AddTextWithBoldPart testId="sr-button-add_credit_card" boldText="Add" regularText="credit card or charge" />
    </AddCardAction>
  </CardDetailRowContainer>
)

const ViewCreditCardRow = ({ resCard, hasAdditionalCards, chargeCard, deleteCard }) => (
  <>
    <ShownCard data-test="sr-label-credit_card" brand={resCard.resCardType}>
      {resCard.resCardType} ....{resCard.resCardLast4}
      {hasAdditionalCards && (
        <Menu alignment="bottomRight">
          <MenuItem onClick={() => chargeCard(resCard)}>
            <Text color="linkColor">Charge Card</Text>
          </MenuItem>
          <MenuItem onClick={() => deleteCard(resCard.resCardId)}>
            <Text color="error">Delete</Text>
          </MenuItem>
        </Menu>
      )}
    </ShownCard>
  </>
)

const ViewCreditCard = ({ resCard, chargeCard, deleteCard, canCharge, additionalReservationCards, selectedTimeSlot, resActual }) => {
  const hasAdditionalCards = additionalReservationCards.length > 0
  const hasCancellationCharge = resActual && resActual.has_cancellation_charge
  const showCancellationFeeText = hasAdditionalCards && hasCancellationCharge
  return (
    <CreditCardInfo>
      <SmallTitle>
        <CreditCardIconXS>{VmsIcons.CreditCard}</CreditCardIconXS>
        Credit Card
      </SmallTitle>

      <CardDetailsAndActions>
        <ViewCreditCardRow
          key={resCard.resCardId}
          resCard={resCard}
          hasAdditionalCards={hasAdditionalCards}
          chargeCard={chargeCard}
          deleteCard={deleteCard}
        />
        {showCancellationFeeText && (
          <Text fontStyle="italic" color="description" fontSize="s">
            Cancellation Fee would be charged to {resCard.resCardType} ....{resCard.resCardLast4}
          </Text>
        )}

        {additionalReservationCards.map(resCard => (
          <ViewCreditCardRow
            key={resCard.resCardId}
            resCard={resCard}
            hasAdditionalCards={hasAdditionalCards}
            chargeCard={chargeCard}
            deleteCard={deleteCard}
          />
        ))}
        {!hasAdditionalCards && (
          <>
            <DeleteButton onClick={() => deleteCard(resCard.resCardId)}>
              {resCard.isProcessingDelete ? <PulsatingDotsRed /> : 'Delete'}
            </DeleteButton>
            {canCharge ? (
              <ActionButton style={{ width: '77.5%', marginLeft: '2.5%' }} onClick={() => chargeCard(resCard)}>
                Charge Card
              </ActionButton>
            ) : null}
          </>
        )}
      </CardDetailsAndActions>
    </CreditCardInfo>
  )
}

export const ChargeActions = ({ resCard, actions, canCharge, paylinkOnly, additionalReservationCards, selectedTimeSlot, resActual }) => {
  const cardDividerMarginTop = additionalReservationCards.length > 0 ? '16px' : '8px'
  const cardDividerMargin = `${cardDividerMarginTop} 0 8px -16px !important`
  return (
    <>
      {resCard.resCardId && (
        <ViewCreditCardContainer>
          <ViewCreditCard
            resCard={resCard}
            canCharge={canCharge}
            chargeCard={actions.toggleChargeOnlyModal}
            deleteCard={actions.deleteCard}
            additionalReservationCards={additionalReservationCards}
            selectedTimeSlot={selectedTimeSlot}
            resActual={resActual}
          />
          <CardDivider margin={cardDividerMargin} />
        </ViewCreditCardContainer>
      )}

      <AddCreditCard toggleChargeModal={actions.toggleChargeModal} canCharge={canCharge} paylinkOnly={paylinkOnly} />
    </>
  )
}
