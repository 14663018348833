import { createApi, fetchBaseQuery, type FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { VenueSettingsService } from '@sevenrooms/core/api'
import type { EmailTemplate, VenueClassEnum, VenueSettings, VenueSettingsOptions } from '@sevenrooms/core/domain'
import type { Dirtied } from '@sevenrooms/core/form'

export const venueSettingsApi = createApi({
  reducerPath: 'settings.venueSettings',
  baseQuery: fetchBaseQuery({ baseUrl: '/api-yoa/' }),
  tagTypes: ['VenueSettings', 'EmailTemplate'],
  endpoints: builder => ({
    getVenueSettings: builder.query<{ class: VenueClassEnum; fields: VenueSettings; options: VenueSettingsOptions }, { venueId: string }>({
      providesTags: ['VenueSettings'],
      async queryFn(args) {
        try {
          return {
            data: await VenueSettingsService.getVenueSettings(args.venueId),
          }
        } catch (e) {
          return { error: e as FetchBaseQueryError }
        }
      },
    }),
    saveVenueSettings: builder.mutation<void, { venueId: string; formData: VenueSettings; dirtyFields: Dirtied<VenueSettings> }>({
      invalidatesTags: () => ['VenueSettings'],
      async queryFn(args) {
        try {
          return await VenueSettingsService.saveVenueSettings(args.venueId, args.formData, args.dirtyFields)
        } catch (e) {
          return { error: e as FetchBaseQueryError }
        }
      },
    }),
    getEmailTemplate: builder.query<EmailTemplate | undefined, { venueId: string }>({
      providesTags: ['EmailTemplate'],
      async queryFn(args) {
        try {
          return {
            data: await VenueSettingsService.getEmailTemplate(args.venueId),
          }
        } catch (e) {
          return { error: e as FetchBaseQueryError }
        }
      },
    }),
  }),
})

export const { useGetVenueSettingsQuery, useSaveVenueSettingsMutation, useGetEmailTemplateQuery } = venueSettingsApi
