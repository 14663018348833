import moment from 'moment-timezone'
import React from 'react'
import styled from 'styled-components'
import DateDropDown from 'mgr/lib/components/DateDropDown'

const DateRangePickerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const To = styled.div`
  font-size: 18px;
  color: ${props => props.theme.color.grey};
  font-style: italic;
  font-weight: bold;
  padding: 0 ${props => props.theme.gutter.standard};
`

const customStyle = { float: 'none', width: 250 }

class DateRangePicker extends React.PureComponent {
  render() {
    const { startDate, endDate, minEndDate, locale = 'en_US', onDateChange, hasMinDate, customMinDate, maxDate } = this.props

    return (
      <DateRangePickerContainer>
        <DateDropDown
          name="Start date"
          date={startDate}
          hasMinDate={hasMinDate}
          customMinDate={customMinDate}
          locale={locale}
          useOutsideLabel={false}
          style={{ ...customStyle, marginRight: '0px' }}
          onChangeDate={value => {
            onDateChange('startDate', value)
          }}
          isLightTheme
          maxDate={maxDate}
        />
        <To>to</To>
        <DateDropDown
          name="End date"
          date={endDate}
          hasMinDate={hasMinDate}
          customMinDate={customMinDate}
          locale={locale}
          useOutsideLabel={false}
          style={customStyle}
          onChangeDate={value => {
            onDateChange('endDate', value)
          }}
          isLightTheme
          maxDate={maxDate}
          minEndDate={minEndDate}
        />
      </DateRangePickerContainer>
    )
  }
}

export default DateRangePicker
