import type { MixedAvailabilityTimeslot } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text, Anchor } from '@sevenrooms/core/ui-kit/typography'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { AvailabilityLocales } from '../../../components/availability/Availability.locales'
import { useGetItemFromTimeslot } from '../../../components/availability/useGetItemFromTimeslot'
import { keepOrRemoveARPopupLocales } from './KeepOrRemoveARPopup.locales'

interface KeepOrRemoveARPopupProps {
  selectedTimeSlot?: MixedAvailabilityTimeslot
  isActive: boolean
  setIsActive: (isActive: boolean) => void
  onRemove: () => void
  onKeep: () => void
}

export function KeepOrRemoveARPopup({ selectedTimeSlot, isActive, setIsActive, onRemove, onKeep }: KeepOrRemoveARPopupProps) {
  const { formatMessage } = useLocales()
  const permissions = useNavigationPermissionsContext()
  const { venueUrlKey } = useAppContext()
  const getItemFromTimeslot = useGetItemFromTimeslot()

  const name = selectedTimeSlot
    ? getItemFromTimeslot(selectedTimeSlot)?.name ?? formatMessage(AvailabilityLocales.noTimeslotDescriptionTitle)
    : ''

  const onClose = () => setIsActive(false)

  return (
    <Window active={isActive} setIsActive={setIsActive}>
      <Modal ariaLabel="Experience" data-test="availability-keep-or-remove-accessrule-dialog">
        <ModalHeader onClose={onClose}>
          <ModalTitle title={formatMessage(keepOrRemoveARPopupLocales.title)} />
        </ModalHeader>
        <ModalBody>
          <VStack spacing="m">
            <Text>{formatMessage(keepOrRemoveARPopupLocales.body, { name: name ? <Text fontWeight="bold">{name}</Text> : 'NONE' })}</Text>
            {permissions &&
              !permissions.isOnboardingSpecialist &&
              (permissions.canManageVenueSettings || permissions.canAdministrateGeneralSettings) && (
                <Text>
                  <Anchor
                    href={`/manager2/${venueUrlKey}/settings/venue?activeTab=reservations`}
                    target="_blank"
                    data-test="availability-keep-or-remove-accessrule-disable-link"
                  >
                    {formatMessage(keepOrRemoveARPopupLocales.disableLink)} <Icon name="VMSWeb-open-in-new" size="sm" />
                  </Anchor>
                </Text>
              )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ModalActions>
            <Button variant="tertiary" onClick={onClose} data-test="button-cancel">
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onRemove()
                onClose()
              }}
              data-test="button-remove"
            >
              {formatMessage(keepOrRemoveARPopupLocales.remove)}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onKeep()
                onClose()
              }}
              data-test="button-keep"
            >
              {formatMessage(keepOrRemoveARPopupLocales.keep)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}
