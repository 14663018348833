import React from 'react'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { emailCenterMessages } from '../../locales'

export function RecommendedLabel() {
  const { formatMessage } = useLocales()

  return (
    <RecommendedLabelStyle>
      <StatusLabel variant="success">{formatMessage(emailCenterMessages.emailCenterRecommended)}</StatusLabel>
    </RecommendedLabelStyle>
  )
}

const RecommendedLabelStyle = styled(Flex)`
  margin-top: -11px !important;
`
