// /* @flow */
import PropTypes from 'prop-types'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { passDataAttrs } from 'svr/common/passDataAttrs'

/* type Props = {
  onClick: () => mixed,
  children: React.Node,
  disabled: boolean,
  className: string,
  customButton: Array<string | (() => mixed)>,
} */

const SKINS = {
  DEFAULT: 'DEFAULT',
  DANGER: 'DANGER',
  GHOST: 'GHOST',
  VMS_PRIMARY: 'VMS_PRIMARY',
}

const SKINS_STYLES = {
  [SKINS.DEFAULT]: css`
    color: #fff;
    background-color: rgba(106, 156, 238, 1);
    border-color: rgba(106, 156, 238, 1);
  `,
  [SKINS.DANGER]: css`
    color: #fff;
    background-color: #f44a6e;
    border-color: #f44a6e;
  `,
  [SKINS.GHOST]: css`
    color: #347baf;
    background-color: #fff;
    border-color: #347baf;
  `,
  [SKINS.VMS_PRIMARY]: css`
    color: #fff;
    background-color: #347baf;
    border-color: #347baf;
  `,
}

const Button = styled.button`
  height: 45px;
  min-width: 130px;
  padding: 0 10px;
  border: 1px solid;
  outline: none;
  border-radius: 5px;
  line-height: 45px;
  font-weight: 200;
  font-size: 15px;
  font-family: Roboto;
  text-align: center;
  box-sizing: border-box;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  :hover {
    opacity: 0.8;
  }
  :active {
    ${props => (props.disabled ? '' : 'opacity: 1;')};
  }
  ${props => (props.disabled ? 'opacity: 0.8;' : '')};
  ${props => props.customButton};
  ${props => SKINS_STYLES[props.skin]};
`

const StandardButton = ({ onClick, disabled, customButton, children, skin, ...props } /* : Props */) => {
  const passProps = passDataAttrs(props)

  return (
    <Button onClick={onClick} customButton={customButton} disabled={disabled} {...passProps} skin={skin}>
      {children}
    </Button>
  )
}

StandardButton.SKINS = SKINS

StandardButton.defaultProps = {
  customButton: [],
  disabled: false,
  skin: SKINS.DEFAULT,
}

StandardButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  skin: PropTypes.oneOf(Object.keys(SKINS)),
}

export default StandardButton
