import moment from 'moment'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { timesByMinuteDuration } from '../../../../application/site/static/app/common/TimeUtil.jsx'
import type { PropsWithChildren } from 'react'

export const HOURS = [
  { id: '0', label: '0' },
  { id: '0.5', label: '0.5' },
].concat(
  Array.from({ length: 23 }, (_v, k) => ({
    id: String(k + 1),
    label: String(k + 1),
  }))
)

export function getTimeChoices(startTime?: Date, endTime?: Date): { label: string; id: string }[] {
  return timesByMinuteDuration(15, startTime ? moment(startTime) : undefined, endTime ? moment(endTime) : undefined).map(
    (ts: moment.Moment) => ({
      label: ts.format('h:mm A'),
      id: ts.format('HH:mm:ss'),
    })
  )
}

export const TIMES_CHOICES = getTimeChoices()

export function SelectContainer({ children }: PropsWithChildren<{}>) {
  return (
    <Box ml="lm" mt="s" mb="s">
      {children}
    </Box>
  )
}

export function TextWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <HStack height="42px" display="inline-flex" alignItems="center">
      {children}
    </HStack>
  )
}
