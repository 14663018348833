import { srGet, srPost } from '@sevenrooms/core/api'

const linkReview = (venueId, actualId, reviewId, errHandler) => {
  const path = `/api-yoa/reviews/${reviewId}/link`
  return srPost(
    path,
    {
      actual_id: actualId,
      venue: venueId,
      rid: Math.floor(Math.random() * 100 + 1),
    },
    true
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
      throw 'Failed to link'
    }

    return (response.data && response.data.review) || {}
  })
}

const unlinkReview = (venueId, actualId, reviewId, errHandler) => {
  const path = `/api-yoa/reviews/${reviewId}/unlink`
  return srPost(
    path,
    {
      rid: Math.floor(Math.random() * 100 + 1),
      venue: venueId,
    },
    { convertToGetParams: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
      throw 'Failed to unlink'
    }

    return (response.data && response.data.review) || {}
  })
}

const fetchLinkedReviews = (actualId, venueId, errHandler) => {
  const path = `/api-yoa/actual_reviews/${actualId}`

  return srGet(
    path,
    {
      venue: venueId,
      rid: Math.floor(Math.random() * 100 + 1),
    },
    { convertToGetParams: true }
  ).then(response => {
    if (response.error && errHandler) {
      errHandler(response.errorMsg)
    }
    return (response.data && response.data.reviews) || {}
  })
}

// TODO discuss error handling
export const fetchReviews = params =>
  srGet(`/api-yoa/reviews`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).catch(e => {
    console.error('An unexpected error occurred fetching reviews', e)
    return e
  })

export const triggerGuestSatisfactionExport = params =>
  srPost(`/api-yoa/reviews/export`, params, {
    skipInterfaceError: true,
    skipLoadClickBlocker: true,
  }).catch(e => {
    console.error('An unexpected error occurred triggering the export', e)
    return e
  })

export default {
  linkReview,
  unlinkReview,
  fetchLinkedReviews,
}
