import moment from 'moment-timezone'
import money from 'money-math'
import { getVenueLocalTime } from 'svr/common/TimeUtil'
import { useStoreSelector } from '../../selectors/useStoreSelector'

export function useChangedReservationInfoWithPaylinkFlow() {
  const { partySize, actual, selectedTimeSlot } = useStoreSelector(state => state.bookAvailabilityState)
  const {
    venueTimezone,
    outstandingPaylink,
    outstandingPaylinkTransaction,
    override,
    chargeAmount,
    chargeTotal,
    internalUpsellsEnabled,
    availabilityChargeData,
    cardRequired,
  } = useStoreSelector(state => state.bookPaymentState)
  const { isDirty: isUpgradesChanged } = useStoreSelector(state => state.upgradesState)

  const isOutstandingPaylinkFlow =
    outstandingPaylink &&
    outstandingPaylinkTransaction &&
    actual &&
    selectedTimeSlot &&
    !override &&
    new Date(getVenueLocalTime(venueTimezone) as never) < new Date(outstandingPaylink)

  const changes = {
    partySize: false,
    dateTime: false,
    charges: false,
  }
  if (isOutstandingPaylinkFlow) {
    const amount = internalUpsellsEnabled ? chargeTotal : chargeAmount
    const initialAmount = internalUpsellsEnabled
      ? money.floatToAmount(outstandingPaylinkTransaction.amount_decimal ?? 0)
      : money.floatToAmount(outstandingPaylinkTransaction.base_amount_decimal ?? 0)
    const initialPaymentRule =
      outstandingPaylinkTransaction.transaction_type === 'transaction_type_request_info' && money.isZero(initialAmount ?? '')
        ? 'save_for_later'
        : 'advanced_payment'
    changes.partySize = actual.total_guests !== partySize
    changes.dateTime = !!moment(actual.date_arrival_time_dt_sync_dt_formatted).diff(moment(selectedTimeSlot.time_iso))
    changes.charges =
      isUpgradesChanged ||
      outstandingPaylinkTransaction.is_shift_required !== cardRequired ||
      (!money.isZero(amount ?? '') &&
        ((availabilityChargeData?.paymentRule !== null && initialPaymentRule !== availabilityChargeData?.paymentRule) ||
          !money.isEqual(initialAmount, amount)))
  }
  return [isOutstandingPaylinkFlow && !changes.charges, changes]
}
