import camelcaseKeys from 'camelcase-keys'
import { VenuePrivilegeLevelEnum, ProductProvisionPackageTypeEnum } from '@sevenrooms/core/domain'
import { useStoreSelector } from '../store'
import type { UserDomain, VenueSettings } from './useAppContext'

export interface NavigationPermissions {
  canViewReservations: boolean
  canViewGrid: boolean
  canViewGuestlist: boolean
  canViewRequests: boolean
  canViewClients: boolean
  canViewThirdPartyClientDbSearch: boolean
  canViewReporting: boolean
  canViewReportingFull: boolean
  canViewMarketing: boolean
  canViewInbox: boolean
  canViewEvents: boolean
  canViewSizzle: boolean
  canAdministrateGeneralSettings: boolean
  canAdministratePaymentGateways: boolean
  canAccessPrivateEvents: boolean
  canAdministrateClientAudiences: boolean
  canAccessClientTab: boolean
  canAdministrateWidgetSettings: boolean
  canManageUserConfig: boolean
  canManageUserAccountsCrm: boolean
  canAdministrateShiftsAccessRules: boolean
  canAdministrateBlackoutDatesDailyProgram: boolean
  canAccessSettingsNav: boolean
  canAccessMarketingTools: boolean
  canManageVenueSettings: boolean
  canManageRestrictedSettings: boolean
  canManageBookingChannels: boolean
  canManageTagList: boolean
  canManageClientTagList: boolean
  canManageReservationTagList: boolean
  canManageMembershipGroups: boolean
  canAccessOperationsTab: boolean
  canViewSearch: boolean
  canAccessFlatfileImports: boolean
  isSuperUser: boolean
  isManagerUser: boolean
  isSuperhero: boolean
  isOnboardingSpecialist: boolean
  canViewOutgoingEmails: boolean
  canEditFloorplanLayout: boolean
  isOrdersIntegrationEnabled: boolean
  isSevenroomsOrderingEnabled: boolean
  isReservationSmsEnabled: boolean
  isOrderingSmsEnabled: boolean
  isRemindersSmsEnabled: boolean
  showOrderMenuSms: boolean
  redirectToGeneralSettings: boolean
  redirectToTagsList: boolean
  redirectToShifts: boolean
  redirectToDailyProgram: boolean
  redirectToReservationWidget: boolean
  redirectToFloorplan: boolean
  isEmailMarketingEnabled: boolean
  isSmsMarketingEnabled: boolean
  isWaitlistWidgetEnabled: boolean
  isWaitlistSMSEnabled: boolean
  isDiningWidgetEnabled: boolean
  isEventWidgetEnabled: boolean
  isLandingPageEnabled: boolean
  isLoyaltyAndPerksEnabled: boolean
  isLoyaltyProgramEnabled: boolean
}

export function useNavigationPermissionsContext() {
  const venueSettings: VenueSettings | undefined = camelcaseKeys(
    useStoreSelector(state => state?.appState?.venueSettings),
    {
      deep: true,
      stopPaths: [''],
    }
  )
  const userDomain: UserDomain | undefined = camelcaseKeys(
    useStoreSelector(state => state?.appState?.userDomain),
    {
      deep: true,
      stopPaths: [''],
    }
  )

  if (venueSettings === undefined || userDomain === undefined) {
    return undefined
  }

  const isBasicUser = userDomain.venuePrivilegeLevel === VenuePrivilegeLevelEnum.BASIC
  const isSubManagerUser = userDomain.venuePrivilegeLevel === VenuePrivilegeLevelEnum.SUBMANAGER
  const isManagerUser = userDomain.venuePrivilegeLevel === VenuePrivilegeLevelEnum.MANAGER
  const isUserManagerUser = userDomain.venuePrivilegeLevel === VenuePrivilegeLevelEnum.USERMANAGER
  const isSuperUser = userDomain.venuePrivilegeLevel === VenuePrivilegeLevelEnum.SUPERUSER
  const isOnboardingSpecialist = userDomain.venuePrivilegeLevel === VenuePrivilegeLevelEnum.FLOORPLANEDITOR
  const venueManagement = isSubManagerUser || isBasicUser || isSuperUser || isManagerUser
  const venueWidgetSettingsEnabled =
    venueSettings.waitlistWidgetEnabled ||
    venueSettings.diningWidgetEnabled ||
    venueSettings.eventWidgetEnabled ||
    venueSettings.landingPageEnabled ||
    venueSettings.batchEmailsEnabled
  // make sure this matches appropriately with
  // application/site/static/app/manager/lib/utils/PermissionsUtils.jsx
  const canViewInbox = venueSettings.isConsumerFeatureEnabled && venueSettings.vipChatEnabled && userDomain.canAccessVipChat
  const canAccessFlatfileImports =
    userDomain?.env !== 'PRODUCTION_EU' &&
    (userDomain.isSuperhero || !!((isManagerUser || isSuperUser) && venueSettings.isFlatfileImportEnabled))

  return {
    canViewReservations: venueManagement && userDomain.canAccessOperationsTab,
    canViewGrid: venueSettings.gridPageEnabled && !isOnboardingSpecialist && !isUserManagerUser && userDomain.canAccessOperationsTab,
    canViewGuestlist:
      venueSettings.guestlistEnabled && userDomain.hasGuestlistAccess && !isUserManagerUser && userDomain.canAccessOperationsTab,
    canViewRequests: venueManagement && userDomain.canAccessRequestsTab,
    canViewClients: venueManagement && userDomain.canAccessClientTab,
    canViewThirdPartyClientDbSearch: venueManagement && userDomain.canViewThirdPartyClientDbSearch,
    canViewReporting: venueManagement,
    canViewReportingFull: userDomain.canViewReportingFull,
    canViewActuals: userDomain.canViewActuals,
    legacyReportsAccess: userDomain.legacyReportsAccess,
    canViewMarketing: venueSettings.marketingTabEnabled && userDomain.canAccessMarketingTools,
    canViewEvents: venueSettings.marketingTabEnabled && venueSettings.eventWidgetEnabled && venueManagement,
    canViewInbox,
    canViewSizzle: venueSettings.isSizzleEnabled && venueManagement,
    canAdministrateGeneralSettings: userDomain.canAdministrateGeneralSettings,
    canAdministratePaymentGateways: userDomain.canAdministratePaymentGateways,
    canAdministrateClientAudiences: venueSettings.apiAudienceManagement,
    canAccessClientTab: userDomain.canAccessClientTab,
    canAccessPrivateEvents: userDomain.canAccessPrivateEventsTab,
    canAdministrateWidgetSettings: venueWidgetSettingsEnabled && userDomain.canAdministrateWidgetSettings,
    canManageUserConfig: userDomain.canManageUserConfig,
    canManageUserAccountsCrm: userDomain.canManageUserAccountsCrm,
    canAdministrateShiftsAccessRules: userDomain.canAdministrateShiftsAccessRules,
    canAdministrateBlackoutDatesDailyProgram: userDomain.canAdministrateBlackoutDatesDailyProgram,
    canAccessSettingsNav: userDomain.canAccessSettingsNav,
    canAccessMarketingTools: userDomain.canAccessMarketingTools,
    canManageVenueSettings: userDomain.canManageVenueSettings,
    canManageRestrictedSettings: userDomain.canManageRestrictedSettings,
    canManageBookingChannels: userDomain.canManageBookingChannels,
    canManageTagList: userDomain.canManageTagList && venueManagement,
    canManageClientTagList: userDomain.canManageClientTagList && venueManagement,
    canManageReservationTagList: userDomain.canManageReservationTagList && venueManagement,
    canManageMembershipGroups: venueSettings.membershipEnabled && userDomain.canManageMembershipGroups && venueManagement,
    canAccessOperationsTab: userDomain.canAccessOperationsTab,
    canAccessVipChat: userDomain.canAccessVipChat,
    canSendVipChat: userDomain.canSendVipChat,
    canViewSearch: venueManagement,
    canAccessFlatfileImports,
    isSuperhero: userDomain.isSuperhero,
    isSuperUser,
    isManagerUser,
    isOnboardingSpecialist,
    canViewOutgoingEmails: userDomain.canViewOutgoingEmails,
    canEditFloorplanLayout: userDomain.canEditFloorplanLayout && !isOnboardingSpecialist,
    isOrdersIntegrationEnabled: venueSettings.isOrdersIntegrationEnabled,
    isSevenroomsOrderingEnabled: venueSettings.isSevenroomsOrderingEnabled,
    isReservationSmsEnabled: venueSettings.reservationsSmsEnabled,
    isOrderingSmsEnabled: venueSettings.orderingSmsEnabled,
    isRemindersSmsEnabled: venueSettings.remindersSmsEnabled,
    showOrderMenuSms: venueSettings.showOrderMenuSms,
    redirectToGeneralSettings: userDomain.canAdministrateGeneralSettings,
    redirectToTagsList: userDomain.canManageTagList,
    redirectToShifts: userDomain.canAdministrateShiftsAccessRules,
    redirectToDailyProgram: userDomain.canAdministrateBlackoutDatesDailyProgram,
    redirectToReservationWidget: userDomain.canAdministrateWidgetSettings,
    redirectToFloorplan: userDomain.canEditFloorplanLayout,
    isEmailMarketingEnabled: venueSettings.batchEmailsEnabled,
    isSmsMarketingEnabled: venueSettings.isSmsMarketingEnabled,
    isWaitlistWidgetEnabled: venueSettings.waitlistWidgetEnabled,
    isWaitlistSMSEnabled: venueSettings.waitlistSmsEnabled,
    isDiningWidgetEnabled: venueSettings.diningWidgetEnabled,
    isEventWidgetEnabled: venueSettings.eventWidgetEnabled,
    isLandingPageEnabled: venueSettings.landingPageEnabled,
    isLoyaltyAndPerksEnabled: venueSettings.isLoyaltyAndPerksEnabled,
    isStandaloneMarketing:
      venueSettings.productProvisionPackage === ProductProvisionPackageTypeEnum.MARKETING_ALPHA ||
      venueSettings.productProvisionPackage === ProductProvisionPackageTypeEnum.MARKETING_BETA,
    isLoyaltyProgramEnabled: venueSettings.isLoyaltyProgramEnabled,
  }
}
