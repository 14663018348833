import React from 'react'
import styled from 'styled-components'
import { Icon, Icons } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'

const MessageEntryLoader = styled.div<{ width?: string }>`
  width: ${props => props.width ?? '100%'};

  background-color: ${props => props.theme.white};
  font-family: ${props => props.theme.fontFamily};
  font-size: 15px;
  color: ${props => props.theme.darkGrey};
  resize: none;
  text-align: center;
  height: 100%;
`

const LoaderText = styled.div`
  margin-bottom: ${props => props.theme.spacing.sm};
`

export const BackwriterMessageLoader = ({ width }: { width?: string }) => (
  <MessageEntryLoader width={width}>
    <VStack justifyContent="center" alignItems="center" height="100%">
      <LoaderText>Generating your response</LoaderText>
      <Icon name={Icons['VMSWeb-7r-logo']} size="lg" />
    </VStack>
  </MessageEntryLoader>
)
